import React from 'react';
import ApiOrdersSupplier from '../../../api/api-orders-supplier';
import { CollectAction, OrderForSupplierDTO, OrderStatus } from '../../../api/model-orders';
import ButtonsPanel from '../../Shared/buttons-panel';
import FormattedDetailsTable, { Line } from '../../Shared/formatted-details-table';
import { Card, Col, Row } from '../../style';
import ViewHelper from '../../view-helper';
import AddCollectionInfo from '../add-collection-info';
import OrderFiles from '../order-files';
import OrderLogs from '../order-logs';
import OrderMap from '../order-map';
import OrderMetadataForm from '../order-metadata-form';

interface Props {
    order: OrderForSupplierDTO;
    invalidate: () => void;
}

const ALLOWED_STATUSES_FOR_COLLECT_INFO = [OrderStatus.COLLECTION, OrderStatus.QA_FAILED, OrderStatus.QA_SOAR];

const SupplierOrderDetails = (props: Props) => {
    const { order } = props;

    return (
        <React.Fragment>
            <Row>
                <Col md={12}>
                    <Card>
                        <FormattedDetailsTable label={'Order specifications'}>
                            <Line label={'Order type'} value={order.productOption + ''} />
                            <Line label={'Archives ID'} value={order.archives?.join(', ')} />
                            <Line label={'Requested Date'} value={ViewHelper.formatDateFromISO(order.requestedDate)} />
                            <Line label={'Delivery type'} value={order.deliveryFormat} />
                            <Line label={'Delivery type desc'} value={order.deliveryFormatNote} />
                        </FormattedDetailsTable>
                    </Card>
                </Col>

                <Col md={6}>
                    <Card>
                        <FormattedDetailsTable label={'Customer Info'}>
                            <Line label={'Company'} value={order.company} />
                            <Line label={'Country'} value={order.countryCode} />
                            <Line label={'Industry'} value={order.industry} />
                        </FormattedDetailsTable>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <FormattedDetailsTable label={'AOI Info'}>
                            <Line label={'AOI Area'} value={ViewHelper.formatAreaInKm(order.areaInKm)} />
                            {order.archiveSelectedAreaInKm && (
                                <Line
                                    label={'Covered area'}
                                    value={ViewHelper.formatAreaInKm(order.archiveSelectedAreaInKm)}
                                />
                            )}
                            <Line label={'AOI countries'} value={order.aoiCountries?.join(', ')} />
                            <Line label={'AOI countries'} value={order.aoiCountriesCn?.join(', ')} />
                        </FormattedDetailsTable>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        <OrderMap
                            supplier={order.supplierCode}
                            product={order.productCode}
                            geometryWKT={order.geometryWKT}
                            orderId={order.id}
                            selectedArchives={order.archives?.map((i) => i + '')}
                            files={order.files}
                            base64Kml={order.base64Kml}
                            orderNumber={order.orderNumber}
                            downloadButtons
                            isSuperviewBoxSelectActive={false}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        <OrderLogs orderId={order.id} logs={order.logs} title="Collection Information" />
                        {ALLOWED_STATUSES_FOR_COLLECT_INFO.includes(order.status) && (
                            <ButtonsPanel>
                                <AddCollectionInfo
                                    order={order}
                                    updateLog={(
                                        _actionType: CollectAction,
                                        _noteContent: string,
                                        _noteFilename: string,
                                        _date: string
                                    ) => {
                                        return ApiOrdersSupplier.collectInfoLog(
                                            order.id,
                                            _actionType,
                                            _noteContent,
                                            _noteFilename,
                                            _date
                                        );
                                    }}
                                    invalidate={() => props.invalidate()}
                                />
                            </ButtonsPanel>
                        )}
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Card>
                        <OrderFiles order={order} invalidate={props.invalidate} />
                    </Card>
                </Col>
            </Row>

            {order?.files?.length > 0 && order.metadata && (
                <Row>
                    <Col md={12}>
                        <Card>
                            <OrderMetadataForm
                                orderId={order.id}
                                status={order.status}
                                metadata={order.metadata}
                                invalidate={props.invalidate}
                            />
                        </Card>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default SupplierOrderDetails;
