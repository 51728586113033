import { push } from 'connected-react-router';
import React from 'react';
import styled from 'styled-components';
import { WMSLayer } from '../../../../api/api-wms';
import store from '../../../../store/store';
import { Button } from '../../../style';

interface WMSLayerVisibilityNavigationButtonsProps {
    wmsLayer: WMSLayer;
    nextLayer?: WMSLayer;
}

const WMSLayerVisibilityNavigationButtons = (props: WMSLayerVisibilityNavigationButtonsProps) => {
    const handleClickNextLayer = () => {
        if (props.nextLayer) {
            store.dispatch(push(`/wms/${props.nextLayer.serverId}/${props.nextLayer.id}`));
        }
    };

    const handleClickBackToList = () => {
        store.dispatch(push(`/wms/${props.wmsLayer.serverId}`));
    };

    return (
        <React.Fragment>
            <ButtonRightAligned onClick={() => handleClickNextLayer()}>
                Next layer in server <i className="fa fa-arrow-right" />
            </ButtonRightAligned>
            <ButtonRightAligned onClick={() => handleClickBackToList()}>
                <i className="fa fa-arrow-left" /> Back to list
            </ButtonRightAligned>
        </React.Fragment>
    );
};

export default WMSLayerVisibilityNavigationButtons;

const ButtonRightAligned = styled(Button)`
    float: right;
`;
