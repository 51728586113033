import { endOfDay, startOfYear, subDays, subMonths } from 'date-fns';
import { startOfDay, startOfMonth } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { ValueType } from 'rsuite/esm/DateRangePicker/types';
import { DatesFromTo, ReportInterval } from '../../../../api/model-api';
import { Input } from '../../../style';
import DateRangeModal from './date-range-modal';
const moment = require('moment');

interface Props {
    onChange: (dates: DatesFromTo) => void;
    fromTo?: DatesFromTo;
    selectedDate?: string;
    interval?: ReportInterval;
    disabled?: boolean;
}

const nowEndOfDay = endOfDay(new Date());
const nowStartOfDay = startOfDay(new Date());

export const ALL_TIME_DAYS: DatesFromTo = { from: startOfDay(new Date(1970, 1, 1)), to: nowEndOfDay };
export const LAST_7_DAYS: DatesFromTo = { from: subDays(nowStartOfDay, 6), to: nowEndOfDay };
export const LAST_14_DAYS: DatesFromTo = { from: subDays(nowStartOfDay, 13), to: nowEndOfDay };
export const LAST_30_DAYS: DatesFromTo = { from: subDays(nowStartOfDay, 29), to: nowEndOfDay };
export const LAST_90_DAYS: DatesFromTo = { from: subDays(nowStartOfDay, 89), to: nowEndOfDay };
export const LAST_180_DAYS: DatesFromTo = { from: subDays(nowStartOfDay, 179), to: nowEndOfDay };
export const LAST_365_DAYS: DatesFromTo = { from: subDays(nowStartOfDay, 364), to: nowEndOfDay };

export const THIS_MONTH: DatesFromTo = { from: startOfMonth(nowStartOfDay), to: nowEndOfDay };
export const LAST_MONTH: DatesFromTo = {
    from: subMonths(startOfMonth(nowStartOfDay), 1),
    to: subDays(startOfMonth(nowEndOfDay), 1),
};
export const LAST_3_MONTHS: DatesFromTo = { from: subMonths(startOfMonth(nowStartOfDay), 3), to: nowEndOfDay };
export const LAST_6_MONTHS: DatesFromTo = { from: subMonths(startOfMonth(nowStartOfDay), 6), to: nowEndOfDay };
export const LAST_12_MONTHS: DatesFromTo = { from: subMonths(startOfMonth(nowStartOfDay), 12), to: nowEndOfDay };
export const LAST_24_MONTHS: DatesFromTo = { from: subMonths(startOfMonth(nowStartOfDay), 24), to: nowEndOfDay };
export const THIS_YEAR: DatesFromTo = { from: startOfYear(nowStartOfDay), to: nowEndOfDay };
export const LAST_YEAR: DatesFromTo = {
    from: subMonths(startOfYear(nowStartOfDay), 12),
    to: subDays(startOfYear(nowEndOfDay), 1),
};

export const MONTHLY_DATE_RANGES = {
    ALL_TIME_DAYS: 'All',
    THIS_MONTH: 'This month',
    LAST_MONTH: 'Last month',
    LAST_3_MONTHS: 'Last 3 months',
    LAST_6_MONTHS: 'Last 6 months',
    LAST_12_MONTHS: 'Last 12 months',
    LAST_24_MONTHS: 'Last 24 months',
    THIS_YEAR: 'This year',
    LAST_YEAR: 'Last year',
    CUSTOM_RANGE: 'Custom Range',
};

export const DEFAULT_DATE_RANGES = {
    ALL_TIME_DAYS: 'All',
    LAST_7_DAYS: 'Last 7 days',
    LAST_14_DAYS: 'Last 14 days',
    LAST_30_DAYS: 'Last 30 days',
    LAST_90_DAYS: 'Last 90 days',
    LAST_180_DAYS: 'Last 180 days',
    LAST_365_DAYS: 'Last 365 days',
    THIS_MONTH: 'This month',
    LAST_MONTH: 'Last month',
    LAST_3_MONTHS: 'Last 3 months',
    LAST_6_MONTHS: 'Last 6 months',
    LAST_12_MONTHS: 'Last 12 months',
    THIS_YEAR: 'This year',
    LAST_YEAR: 'Last year',
    CUSTOM_RANGE: 'Custom Range',
};

const ALL_LABELS = {
    ...DEFAULT_DATE_RANGES,
    ...MONTHLY_DATE_RANGES,
};

const InputDateRange = (props: Props) => {
    const [selected, setSelected] = useState(props.selectedDate ? props.selectedDate : '');
    const [openDateRangeModal, setOpenDateRangeModal] = useState<boolean>(false);

    const getMonthRanges = () => {
        const startDate = moment('2018-12-01');
        const currentDate = moment(new Date()).format('YYYY-MM-DD');

        const dates = [];
        const endDate = moment(currentDate).subtract(1, 'month');

        while (startDate < endDate) {
            startDate.add(1, 'month');
            const month: { dateStr: string; dateRange: DatesFromTo } = {
                dateStr: startDate.format('MMM YYYY').toUpperCase(),
                dateRange: {
                    from: new Date(startDate.format('YYYY-MM-DD')),
                    to: new Date(moment(startDate).endOf('month').format('YYYY-MM-DD')),
                },
            };
            dates.push(month);
        }
        return dates;
    };

    const handleChangeOption = (option: string) => {
        const monthRange = getMonthRanges().find((t) => t.dateStr === option);

        if (monthRange) {
            setSelected(option);
            props.onChange(monthRange.dateRange);
            return;
        }

        switch (option) {
            case ALL_LABELS.ALL_TIME_DAYS:
                props.onChange({ ...ALL_TIME_DAYS });
                break;
            case ALL_LABELS.LAST_7_DAYS:
                props.onChange({ ...LAST_7_DAYS });
                break;
            case ALL_LABELS.LAST_14_DAYS:
                props.onChange({ ...LAST_14_DAYS });
                break;
            case ALL_LABELS.LAST_30_DAYS:
                props.onChange({ ...LAST_30_DAYS });
                break;
            case ALL_LABELS.LAST_90_DAYS:
                props.onChange({ ...LAST_90_DAYS });
                break;
            case ALL_LABELS.LAST_180_DAYS:
                props.onChange({ ...LAST_180_DAYS });
                break;
            case ALL_LABELS.LAST_365_DAYS:
                props.onChange({ ...LAST_365_DAYS });
                break;
            case ALL_LABELS.THIS_MONTH:
                props.onChange({ ...THIS_MONTH });
                break;
            case ALL_LABELS.LAST_MONTH:
                props.onChange({ ...LAST_MONTH });
                break;
            case ALL_LABELS.LAST_3_MONTHS:
                props.onChange({ ...LAST_3_MONTHS });
                break;
            case ALL_LABELS.LAST_6_MONTHS:
                props.onChange({ ...LAST_6_MONTHS });
                break;
            case ALL_LABELS.LAST_12_MONTHS:
                props.onChange({ ...LAST_12_MONTHS });
                break;
            case ALL_LABELS.LAST_24_MONTHS:
                props.onChange({ ...LAST_24_MONTHS });
                break;
            case ALL_LABELS.THIS_YEAR:
                props.onChange({ ...THIS_YEAR });
                break;
            case ALL_LABELS.LAST_YEAR:
                props.onChange({ ...LAST_YEAR });
                break;
            case ALL_LABELS.CUSTOM_RANGE:
                setOpenDateRangeModal(true);
                break;

            default:
                break;
        }
        setSelected(option);
    };

    const onDateChange = (value: ValueType) => {
        if (value && value.length === 2) {
            props.onChange({ from: startOfDay(value[0]), to: endOfDay(value[1]) });
        }
    };

    useEffect(() => {
        if (
            props.interval === 'MONTH' &&
            (selected === ALL_LABELS.LAST_7_DAYS ||
                selected === ALL_LABELS.LAST_14_DAYS ||
                selected === ALL_LABELS.LAST_30_DAYS ||
                selected === ALL_LABELS.LAST_90_DAYS)
        ) {
            setSelected(ALL_LABELS.LAST_3_MONTHS);
            props.onChange({ ...LAST_3_MONTHS });
        } else if (props.interval === 'MONTH' && selected === ALL_LABELS.LAST_365_DAYS) {
            setSelected(ALL_LABELS.LAST_12_MONTHS);
            props.onChange({ ...LAST_12_MONTHS });
        } else if (props.interval === 'DAY' && selected === ALL_LABELS.LAST_24_MONTHS) {
            setSelected(ALL_LABELS.LAST_12_MONTHS);
            props.onChange({ ...LAST_12_MONTHS });
        }
    }, [props.interval, selected, setSelected, props.onChange, props]);

    return (
        <React.Fragment>
            <Input
                disabled={props.disabled}
                value={selected}
                onChange={(e) => {
                    e.preventDefault();
                    handleChangeOption(e.target.value);
                }}
                type="select"
                name="date-range"
            >
                {Object.keys(props.interval === ReportInterval.MONTH ? MONTHLY_DATE_RANGES : DEFAULT_DATE_RANGES).map(
                    (key) => (
                        <option key={key} value={ALL_LABELS[key]}>
                            {ALL_LABELS[key]}
                        </option>
                    )
                )}

                {props.interval === ReportInterval.DAY &&
                    getMonthRanges()
                        .reverse()
                        .map((t) => {
                            return (
                                <option key={t.dateStr} value={t.dateStr}>
                                    {t.dateStr}
                                </option>
                            );
                        })}
            </Input>
            <DateRangeModal
                onDateChange={onDateChange}
                fromTo={props.fromTo}
                openDatePicker={openDateRangeModal}
                setOpenDatePicker={setOpenDateRangeModal}
            />
        </React.Fragment>
    );
};

export default InputDateRange;
