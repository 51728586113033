import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiTms, { TMSLayer, TMSServer } from '../../../../api/api-tms';
import { WMSLayer } from '../../../../api/api-wms';
import store from '../../../../store/store';
import { Col, Divider, Row, Spinner, Subtitle, Table, TableWrapper } from '../../../style';
import TMSLayerAdd from '../Layer/tms-layer-add';

interface TMSServerLayersProps {
    tms: TMSServer;
    onSaveLayer: () => void;
}

const TMSServerLayers = (props: TMSServerLayersProps) => {
    const [isLoadingLayers, setIsLoadingLayers] = useState<boolean>(false);
    const [activeLayers, setActiveLayers] = useState<TMSLayer[]>([]);
    const [inactiveLayers, setInactiveLayers] = useState<TMSLayer[]>([]);

    useEffect(() => {
        setIsLoadingLayers(true);
        ApiTms.getTMSLayers(props.tms.id)
            .then((layers) => {
                setInactiveLayers(layers.filter((t) => t.public === false));
                setActiveLayers(layers.filter((t) => t.public === true));
            })
            .catch((err) => {
                toast.error('Failed to get wms layer', err);
            })
            .finally(() => {
                setIsLoadingLayers(false);
            });
    }, [props.tms]);

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            headerStyle: () => {
                return {
                    width: '40px',
                };
            },
        },
        {
            dataField: 'title',
            text: 'Layer Name',
            sort: false,
            formatter: (_, row: WMSLayer) => {
                return row.titleOvr || row.title;
            },
        },
        {
            dataField: 'public',
            text: 'Available in Soar',
            headerStyle: () => {
                return {
                    width: '140px',
                };
            },
            formatter: (_, row: TMSLayer) => {
                if (row.public) {
                    return <Checkmark className="fa fa-check" />;
                } else {
                    return <React.Fragment />;
                }
            },
        },
    ];

    const onClickRow = {
        onClick: (_e, row) => {
            store.dispatch(push(`/tms/${props.tms.id}/${row.id}`));
        },
        onAuxClick: (_e, row) => {
            const win = window.open(`/tms/${props.tms.id}/${row.id}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    if (isLoadingLayers) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <TMSLayerAdd tmsServerId={props.tms.id} />
                </Col>
            </Row>
            {activeLayers.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Layers available in Soar ({activeLayers.length} total)</Subtitle>
                    <ToolkitProvider
                        keyField="id"
                        data={activeLayers}
                        columns={columns}
                        filterFactory={filterFactory()}
                        search={{ searchFormatted: true }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table {...props.baseProps} rowEvents={onClickRow} />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}

            {activeLayers.length > 0 && inactiveLayers.length > 0 ? <Divider /> : null}

            {inactiveLayers.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Layers not available in Soar ({inactiveLayers.length} total)</Subtitle>
                    <ToolkitProvider
                        keyField="id"
                        data={inactiveLayers}
                        columns={columns}
                        filterFactory={filterFactory()}
                        search={{ searchFormatted: true }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table {...props.baseProps} rowEvents={onClickRow} />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default TMSServerLayers;

const Checkmark = styled.i`
    color: green;
`;
