import config from '../config';
import Api from './api';
import { CommentDTO, ReportedCommentDTO, Review } from './model';

export default class ApiComments extends Api {
    public static searchComments(
        userId?: string,
        listingId?: number,
        includeDeleted?: boolean,
        offset?: number,
        limit?: number
    ): Promise<CommentDTO[]> {
        const params = {
            userId,
            listingId,
            includeDeleted,
            offset,
            limit,
        };
        return this.axios
            .get(`/v1/admin/comment`, { params })
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getComment(id: string): Promise<CommentDTO> {
        return this.axios
            .get(`/v1/admin/comment/${id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getReportedComments(review?: Review, reviewerId?: string): Promise<ReportedCommentDTO[]> {
        const params = {
            review,
            reviewer: reviewerId,
        };
        return this.axios
            .get(`/v1/admin/comment/report`, { params })
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static deleteReportedComment(id: string): Promise<ReportedCommentDTO> {
        return this.axios
            .post(`/v1/admin/comment/report/${id}/approve`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static ignoreReportedComment(id: string): Promise<ReportedCommentDTO> {
        return this.axios
            .post(`/v1/admin/comment/report/${id}/reject`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static reportComment(commentId: string, reason: string, message: string): Promise<ReportedCommentDTO> {
        const headers = {
            Subdomain: config.CLIENT_DOMAIN,
        };
        const body = {
            reason: reason,
            message: message,
        };
        return this.axios.post(`/v1/comments/${commentId}/report`, body, { headers: headers }).then((res) => {
            return res.data;
        });
    }
}
