import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ArchiveDTO, CustomerDTO, OrderForAdminDTO, ProductDTO, SupplierDTO } from '../../api/model-orders';
import NewOrderAOI from './new-order-aoi';
import NewOrderCustomer from './new-order-customer';
import NewOrderPreview from './new-order-preview';
import NewOrderSupplier from './new-order-supplier';
import NewOrderComplete from './new-order-complete';
import { Card, Col, Container, Row, Title } from '../style';

export enum OrderWorkflow {
    selectProduct,
    selectAOI,
    customerDetails,
    previewOrder,
    completeOrder,
}

interface IOrderWorkflowDetail {
    [key: number]: { title: string };
}
const OrderWorkflowDetail: IOrderWorkflowDetail = {
    [OrderWorkflow.selectProduct]: { title: 'Select Product' },
    [OrderWorkflow.selectAOI]: { title: 'Select AOI' },
    [OrderWorkflow.customerDetails]: { title: 'Enter Customer Details' },
    [OrderWorkflow.previewOrder]: { title: 'Preview Order' },
    [OrderWorkflow.completeOrder]: { title: 'Complete' },
};

const NewOrder = () => {
    const [workflow, setWorkflow] = useState<OrderWorkflow>(OrderWorkflow.selectProduct);
    const [supplier, setSupplier] = useState<SupplierDTO | undefined>(undefined);
    const [product, setProduct] = useState<ProductDTO | undefined>(undefined);
    const [geometryWKT, setGeometryWKT] = useState<string | undefined>(undefined);
    const [selectedArchives, setSelectedArchives] = useState<ArchiveDTO[]>([]);
    const [customer, setCustomer] = useState<CustomerDTO | undefined>(undefined);
    const [orders, setOrders] = useState<OrderForAdminDTO[] | undefined>(undefined);

    switch (workflow) {
        case OrderWorkflow.selectProduct:
            return (
                <OrderContainer workflow={workflow} setWorkflow={setWorkflow}>
                    <NewOrderSupplier
                        handleSelectSupplierAndProduct={(supplier, product) => {
                            setSupplier(supplier);
                            setProduct(product);
                            setWorkflow(OrderWorkflow.selectAOI);
                        }}
                        selectedSupplier={supplier}
                        selectedProduct={product}
                    />
                </OrderContainer>
            );

        case OrderWorkflow.selectAOI:
            return (
                <OrderContainer workflow={workflow} setWorkflow={setWorkflow}>
                    <NewOrderAOI
                        supplier={supplier}
                        product={product}
                        onSelectArchives={(archives) => {
                            setSelectedArchives(archives);
                            setWorkflow(OrderWorkflow.customerDetails);
                        }}
                        geometryWKT={geometryWKT}
                        handleSelectAOI={setGeometryWKT}
                        selectedArchives={selectedArchives}
                        onBack={() => setWorkflow(OrderWorkflow.selectProduct)}
                    />
                </OrderContainer>
            );

        case OrderWorkflow.customerDetails:
            return (
                <OrderContainer workflow={workflow} setWorkflow={setWorkflow}>
                    <NewOrderCustomer
                        customer={customer}
                        onSubmit={(value) => {
                            setCustomer(value);
                            setWorkflow(OrderWorkflow.previewOrder);
                        }}
                        onBack={() => setWorkflow(OrderWorkflow.selectAOI)}
                    />
                </OrderContainer>
            );

        case OrderWorkflow.previewOrder:
            return (
                <OrderContainer workflow={workflow} setWorkflow={setWorkflow}>
                    <NewOrderPreview
                        supplier={supplier}
                        product={product}
                        customer={customer}
                        archives={selectedArchives}
                        geometryWKT={geometryWKT}
                        onSubmit={(order) => {
                            setOrders(order);
                            setWorkflow(OrderWorkflow.completeOrder);
                        }}
                        onBack={() => setWorkflow(OrderWorkflow.customerDetails)}
                    />
                </OrderContainer>
            );
        case OrderWorkflow.completeOrder:
            return (
                <OrderContainer workflow={workflow}>
                    <NewOrderComplete orders={orders} />
                </OrderContainer>
            );
    }

    return <React.Fragment />;
};

export default NewOrder;

interface OrderContainerProps {
    workflow: OrderWorkflow;
    setWorkflow?: (workflow: OrderWorkflow) => void;
    children: React.ReactNode;
}

const OrderContainer = (props: OrderContainerProps) => {
    const { workflow, setWorkflow } = props;
    const [doneWorkflow, setDoneWorkflow] = useState(OrderWorkflow.selectProduct);

    useEffect(() => {
        if (workflow > doneWorkflow) {
            setDoneWorkflow(workflow);
        }
    }, [workflow, doneWorkflow]);

    return (
        <Container>
            <Title>Create New Custom Order</Title>
            <Card>
                <StepsContainer>
                    {Object.keys(OrderWorkflowDetail).map((key) => {
                        const wf = Number(key);
                        return (
                            <StepTitle
                                workflow={wf}
                                current={workflow}
                                done={doneWorkflow}
                                navigateToWorkflow={setWorkflow}
                            >
                                {OrderWorkflowDetail[wf].title}
                            </StepTitle>
                        );
                    })}
                </StepsContainer>
            </Card>
            <Card>
                <Row>
                    <Col>
                        <OrderTitle>{OrderWorkflowDetail[workflow].title}</OrderTitle>
                    </Col>
                </Row>
                {props.children}
            </Card>
        </Container>
    );
};

export const FormRow = (props: { children: React.ReactNode }) => {
    return (
        <Row>
            <Col xs={12} md={{ size: 6, offset: 3 }}>
                {props.children}
            </Col>
        </Row>
    );
};

const StepTitle = (props: {
    workflow: OrderWorkflow;
    current: OrderWorkflow;
    done: OrderWorkflow;
    navigateToWorkflow?: (workflow: OrderWorkflow) => void;
    children: React.ReactNode;
}) => {
    return (
        <StepItem
            active={props.current === props.workflow || props.done > props.workflow}
            onClick={() => {
                if (
                    props.done >= props.workflow &&
                    props.navigateToWorkflow &&
                    props.workflow !== OrderWorkflow.completeOrder
                ) {
                    props.navigateToWorkflow(props.workflow);
                }
            }}
        >
            {props.children}
            {(props.done > props.workflow || props.current === OrderWorkflow.completeOrder) && (
                <i className="fa fa-check" />
            )}
        </StepItem>
    );
};

interface ItemProps {
    active: boolean;
}

const StepsContainer = styled.div`
    width: auto;
    display: flex;
    margin: 8px 30px;
`;

const StepItem = styled.div<ItemProps>`
    flex: 1;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 2px;
    color: #848c94;

    ${(props) =>
        props.active &&
        css`
            color: rgba(0, 0, 0, 0.87);
            text-decoration: underline;
            font-weight: bold;
            cursor: pointer;
            background-color: #eed926;
        `}
`;

export const OrderTitle = styled(Title)`
    margin: 15px 0px 15px 30px;
`;
