import L, { LatLngBounds } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { FeatureGroup, LayersControl, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import * as Wellknown from 'wellknown';
import { ArchiveDTO, ProductDTO } from '../../api/model-orders';
import Config from '../../config';
import GeoUtil from '../../lib/geo-util';
import ErrorPanel from '../Shared/error-panel';
import { Button, Input } from '../style';
import LeafletMap from '../MapView/leaflet-map';
import AOISelect from '../MapView/Annotations/SatelliteAOI/aoi-select';
import DistortableOverlay from '../MapView/Annotations/DistortableImageOverlay/DistortableOverlay';
import LeafletBaseMaps from '../Shared/leaflet-basemaps';

const { BaseLayer, Overlay } = LayersControl;

interface Props {
    height?: number;
    product: ProductDTO;
    handleSelectAOI?: (string) => void;
    onError?: (string) => void;
    selectedArchives?: ArchiveDTO[];
    geometryWKT?: string;
}

const SelectAOI: React.FC<Props> = (props) => {
    const satelliteAOIControlRef = useRef<any>();

    const { selectedArchives, height = 500, product } = props;

    const [isSuperviewBoxSelectActive, setSuperviewBoxSelectActive] = useState<boolean>(false);
    const [geometryWKT, setGeometryWKT] = useState<string | undefined>(undefined);
    const [locationLayer, setLocationLayer] = useState<any>();
    const [error, setError] = useState<string>(undefined);

    const mapRef = useRef<any>(undefined);

    useEffect(
        () => () => {
            if (satelliteAOIControlRef.current) {
                satelliteAOIControlRef.current.removeAll();
            }
        },
        []
    );

    useEffect(() => {
        setGeometryWKT(props.geometryWKT);
    }, [props.geometryWKT]);

    const setAOIError = (err?: string) => {
        setError(err);
        if (props.onError) {
            props.onError(err);
        }
    };

    useEffect(() => {
        if (isSuperviewBoxSelectActive && satelliteAOIControlRef.current) {
            const inf = 99999999;
            satelliteAOIControlRef.current.start({
                maxArea: product.maxAreaInKm,
                minArea: product.minAreaInKm,
                minWidth: 1,
                minHeight: 1,
                maxWidth: inf,
                maxHeight: inf,
            });
        } else if (!isSuperviewBoxSelectActive && satelliteAOIControlRef.current) {
            satelliteAOIControlRef.current.removeAll();
        }
    }, [isSuperviewBoxSelectActive, product]);

    useEffect(() => {
        const leafletMap = mapRef.current;
        if (locationLayer) {
            leafletMap.removeLayer(locationLayer);
        }
        if (geometryWKT && mapRef && mapRef && !isSuperviewBoxSelectActive) {
            const geoJson = Wellknown.parse(geometryWKT);
            const layer = L.geoJSON(geoJson, { style: { fillColor: '#000000', fillOpacity: 0 } }).addTo(leafletMap);
            setLocationLayer(layer);
            leafletMap.fitBounds(layer.getBounds());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geometryWKT, mapRef]);

    const onClearButton = () => {
        setGeometryWKT(undefined);
        setSuperviewBoxSelectActive(false);
        setAOIError(undefined);
    };

    const loadKML = async (e) => {
        setSuperviewBoxSelectActive(false);
        const files: any[] = e.target.files;
        if (files.length > 0) {
            const filename = files[0].name;
            const filetext = await files[0].text();
            try {
                const geojson = GeoUtil.parseFile(filename, filetext);
                const wkt = GeoUtil.geojsonToWkt(geojson);
                setGeometryWKT(wkt);
                if (props.handleSelectAOI) {
                    props.handleSelectAOI(wkt);
                }
            } catch (err) {
                alert('Error');
            }
        }
    };

    const wktFromBBOX = (bbox?: LatLngBounds): string | undefined => {
        if (!bbox) {
            return undefined;
        }
        const coords = [
            [bbox.getNorthEast(), bbox.getNorthWest(), bbox.getSouthWest(), bbox.getSouthEast(), bbox.getNorthEast()],
        ].map((ring) => ring.map((point) => [point.lng, point.lat]));
        const geoJSON = {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: coords,
            },
        };
        const parsedGeometry = JSON.parse(JSON.stringify(geoJSON.geometry));
        return Wellknown.stringify(parsedGeometry);
    };

    return (
        <>
            <MapDiv height={height} hasOAIControl={props.handleSelectAOI ? true : false}>
                <LeafletMap mapRef={mapRef} fullscreenControl>
                    {props.handleSelectAOI && (
                        <div className="leaflet-top leaflet-left">
                            <div className="leaflet-control leaflet-bar">
                                <FlexDiv>
                                    <Input
                                        type="file"
                                        id="kmlId"
                                        name="kml"
                                        label="Load AOI from KML file"
                                        onInput={(e) => loadKML(e)}
                                    />
                                    <AOIButton
                                        disabled={isSuperviewBoxSelectActive}
                                        onClick={() => setSuperviewBoxSelectActive(true)}
                                    >
                                        Draw AOI
                                    </AOIButton>
                                    <AOIButton
                                        disabled={!geometryWKT && !isSuperviewBoxSelectActive}
                                        color="danger"
                                        onClick={onClearButton}
                                    >
                                        Clear AOI
                                    </AOIButton>
                                </FlexDiv>
                            </div>
                        </div>
                    )}

                    <LayersControl position="topright">
                        <LeafletBaseMaps />
                        {isSuperviewBoxSelectActive ? (
                            <AOISelect
                                isActive={isSuperviewBoxSelectActive}
                                onAOISelected={(aoi: LatLngBounds) => {
                                    if (aoi) {
                                        setGeometryWKT(wktFromBBOX(aoi));
                                        const areaKM = GeoUtil.area(wktFromBBOX(aoi)) / 1000000;

                                        setAOIError(undefined);
                                        if (areaKM > product.maxAreaInKm) {
                                            setAOIError('Error: Area too large');
                                            return;
                                        }
                                        if (areaKM < product.minAreaInKm) {
                                            setAOIError('Error: Area too small');
                                            return;
                                        }

                                        if (props.handleSelectAOI) {
                                            props.handleSelectAOI(wktFromBBOX(aoi));
                                        }
                                    } else {
                                        setSuperviewBoxSelectActive(false);
                                    }
                                }}
                                onAOIReset={onClearButton}
                                aoiParameters={{
                                    maxArea: product.maxAreaInKm,
                                    minArea: product.minAreaInKm,
                                    minWidth: 1,
                                    minHeight: 1,
                                    maxWidth: 99999999,
                                    maxHeight: 99999999,
                                }}
                            />
                        ) : null}
                        {selectedArchives && (
                            <Overlay checked={true} name={`Archive scenes`}>
                                <FeatureGroup>
                                    {selectedArchives.reverse().map((scene, idx) => {
                                        const corners = GeoUtil.distortablePolygonFromPolygonWKT(scene.geometryWKT);
                                        return (
                                            <DistortableOverlay
                                                key={`${scene}-${idx}`}
                                                corners={corners}
                                                dataURL={scene.previewUrl}
                                            />
                                        );
                                    })}
                                </FeatureGroup>
                            </Overlay>
                        )}
                    </LayersControl>
                </LeafletMap>
            </MapDiv>
            {error && <ErrorPanel>{error}</ErrorPanel>}
        </>
    );
};

export default SelectAOI;

interface MapDivProps {
    height: number;
    hasOAIControl: boolean;
}

const MapDiv = styled.div<MapDivProps>`
    height: ${(props) => (props.height ? props.height : 450)}px;
    & #satellite-aoi-button {
        display: none;
    }

    .leaflet-control-zoom.leaflet-bar.leaflet-control {
        margin-top: ${(props) => (props.hasOAIControl ? 60 : 10)}px;
    }
`;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AOIButton = styled(Button)`
    width: 150px;
    margin-left: 10px;
`;
