import React from 'react';
import { useSelector } from 'react-redux';
import { selectSoarReport } from '../../../store/ReportsStore/selectors';
import { Card, Col, Container, Row, Spinner, Statistic, Subtitle, Title } from '../../style';
import ViewHelper from '../../view-helper';
import UserBarChart from './user-bar-chart';

const UserReport = () => {
    const soarReport = useSelector(selectSoarReport);

    if (!soarReport)
        return (
            <Container>
                <Title>User Report</Title>
                <Card height="290px">
                    <Spinner text="Loading User Report" />
                </Card>
                <Card height="132px">
                    <Spinner text="Loading User Report" />
                </Card>
                <Card height="600px">
                    <Spinner text="Loading User Report" />
                </Card>
            </Container>
        );

    const { soar, usersByDay } = soarReport;
    const sortedIntervals = usersByDay.intervals.sort((a, b) => (a.label > b.label ? 1 : -1));
    const usersThisMonth = sortedIntervals
        .slice(0, 30) // take first 30 elements
        .reduce((acc, curr) => acc + curr.total, 0);
    return (
        <Container>
            <Title>User Report</Title>
            <Row>
                <Col xs={12} md={3}>
                    <Card title="Total number of registered Users">
                        <Subtitle>Registered Users</Subtitle>
                        <Statistic>{usersByDay.total.toLocaleString()}</Statistic>
                    </Card>
                </Col>

                <Col xs={12} md={3}>
                    <Card title="Total new users added in last 30 days">
                        <Subtitle>New users last 30 days</Subtitle>
                        <Statistic>{usersThisMonth.toLocaleString()}</Statistic>
                    </Card>
                </Col>

                <Col xs={12} md={3}>
                    <Card title="Total number of approved maps">
                        <Subtitle>Maps Approved</Subtitle>
                        <Statistic>{`${soar.maps.toLocaleString()} (${ViewHelper.formatBytes(
                            soar.mapsSize
                        )})`}</Statistic>
                        {/* <SubtitleHint>from {userWithApprovedMap} unique users</SubtitleHint> */}
                    </Card>
                </Col>

                <Col xs={12} md={3}>
                    <Card title="Total number of rejected maps">
                        <Subtitle>Maps Rejected</Subtitle>
                        <Statistic>{`${soar.mapsRejected.toLocaleString()} (${ViewHelper.formatBytes(
                            soar.mapsSizeRejected
                        )})`}</Statistic>
                        {/* <SubtitleHint>from {userWithRejectedMap} unique users</SubtitleHint> */}
                    </Card>
                </Col>
            </Row>
            <UserBarChart />
            {/* <UserData /> */}
        </Container>
    );
};

export default UserReport;
