import Api from './api';
import { ListingDTO } from './model';

export default class ApiPlatform extends Api {
    public static getFeaturedListings(type: string): Promise<ListingDTO[]> {
        return this.axios.get(`/v1/admin/listings/search?featured=${type}`).then((res) => {
            return res.data.listings;
        });
    }
}
