import React, { useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { ContactSubjectType, ContactUsDTO } from '../../api/model';
import ViewHelper from '../view-helper';
import ContactUsQuery from './contact-us-query';
import { Table, TableWrapper } from '../style';
import styled from 'styled-components';
import config from '../../config';

interface ContactUsProps {
    items: ContactUsDTO[];
    itemHandled: (id: string) => void;
}

const ContactUsTable = (props: ContactUsProps) => {
    const [expanded, setExpanded] = useState<string[]>([]);
    const items = props.items
        .filter((item) => item.subject !== ContactSubjectType.PROBLEM_WITH_LISTING)
        .sort((d1, d2) => d2.createdAt - d1.createdAt);
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell) => ViewHelper.formatDateTimeFromISO(cell),
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'countryCode',
            text: 'Country',
            sort: true,
        },
        {
            dataField: 'subject',
            text: 'Subject',
            sort: true,
        },
        {
            dataField: 'message',
            text: 'Message',
            sort: false,
        },
        {
            dataField: 'handledBy',
            text: 'Handled',
            formatter: (cell) =>
                cell ? (
                    <HandledByCheck
                        title={cell}
                        href={`${config.CLIENT_URL}/profile/${cell}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fa fa-check" />
                    </HandledByCheck>
                ) : (
                    <div></div>
                ),
        },
    ];

    const handleOnExpand = (row, isExpand, _rowIndex, _e) => {
        if (isExpand) {
            setExpanded([...expanded, row.id]);
        } else {
            setExpanded(expanded.filter((x) => x !== row.id));
        }
    };

    const expandRow = {
        renderer: (row, _rowIndex) => {
            const cu: ContactUsDTO = row;
            return <ContactUsQuery item={cu} itemHandled={(id: string) => props.itemHandled(id)} />;
        },
        expanded: expanded,
        onExpand: handleOnExpand,
    };
    return (
        <ToolkitProvider keyField="id" data={items} columns={columns}>
            {(props) => (
                <TableWrapper>
                    <Table {...props.baseProps} pagination={paginationFactory()} expandRow={expandRow} />
                </TableWrapper>
            )}
        </ToolkitProvider>
    );
};

export default ContactUsTable;

const HandledByCheck = styled.a`
    i.fa {
        color: #eed926;
    }
`;
