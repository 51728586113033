import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiAdmin from '../../api/api-admin';
import { FileInfoDTO } from '../../api/model';
import FormattedDetailsTable, { Line } from '../Shared/formatted-details-table';
import { Card, Spinner } from '../style';
import ViewHelper from '../view-helper';
import ImageryDetails from './imagery-details';

const TileEngineShitFolder = () => {
    const [files, setFiles] = useState<FileInfoDTO[]>([]);
    const [selected, setSelected] = useState<string>(undefined);

    const refresh = async () => {
        const list = await ApiAdmin.getFilesInFolder('shit');
        setFiles(list);
    };

    useEffect(() => {
        refresh();
    }, []);

    if (selected) {
        return <ImageryDetails onBackClick={() => setSelected(undefined)} filepath={selected} />;
    }

    if (!selected && !files) {
        return (
            <Card height="600px">
                <Spinner title="loading" />
            </Card>
        );
    }

    return (
        <Card>
            <FormattedDetailsTable label={''}>
                {files
                    .filter((f) => !f.ossKey.endsWith('.vrt'))
                    .map((f) => (
                        <Line
                            key={f.ossKey}
                            label={ViewHelper.formatDateFromISO(f.created)}
                            value={
                                <TileEngineDetails>
                                    <div>{ViewHelper.formatFilename(f.ossKey)} </div>
                                    <span>
                                        {ViewHelper.formatBytes(f.size)}
                                        <a onClick={() => setSelected(f.ossKey)}>select</a>
                                    </span>
                                </TileEngineDetails>
                            }
                        />
                    ))}
            </FormattedDetailsTable>
        </Card>
    );
};
export default TileEngineShitFolder;

const TileEngineDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65vw;
    word-break: break-word;

    span {
        display: flex;
        flex-direction: row;
        width: 110px;
        justify-content: space-between;

        a {
            color: #eed926;
        }
    }
`;
