import React from 'react';
import styled from 'styled-components';
import { Input } from '../style';

interface Props {
    title?: string;
    onChange: (opacity: number) => void;
    opacity: number;
}

const OpacitySlider = (props: Props) => {
    const { title, opacity, onChange } = props;
    return (
        <SliderContainer>
            <SliderText>{title || 'Transparency'}:</SliderText>

            <SliderInputContainer>
                <SliderValue>{(opacity * 100).toFixed()}%</SliderValue>
                <Slider
                    type="range"
                    value={opacity * 100}
                    step={0.01}
                    onChange={(e) => onChange(parseInt(e.target.value) / 100)}
                />
            </SliderInputContainer>
        </SliderContainer>
    );
};

export default OpacitySlider;

const SliderContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 0px;
`;

const SliderText = styled.p`
    font-size: 16px;
    color: #848c94;
    margin: 0;
    padding: 0;
    min-width: 200px;
`;

const SliderValue = styled.p`
    vertical-align: center;
    font-size: 16px;
    min-width: 50px;
    margin: 0;
    padding: 0;
    margin-left: 8px;
`;

const SliderInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 100%;
`;

const Slider = styled<any>(Input)`
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    outline: none;
    border-radius: 5px;
    align-self: center;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 100%;
        cursor: pointer;

        &:active,
        &:focus {
            -webkit-appearance: none;
            outline: none !important;
            background: #eed926 !important;
            border: none !important;
        }
    }

    &:active,
    &:focus {
        appearance: none;
        -webkit-appearance: none;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
        outline: none !important;
        background: #eed926 !important;
    }
`;
