import Api from './api';
import { STACCatalogFullDTO, STACItemFullDTO } from './api-stac';
import { WMSLayer } from './api-wms';
import { ListingDTO, ListingFileType, ListingType, Review, UploadCredentialsDTO } from './model';

export interface ManagedUserDTO {
    userId: string;
    bio: string;
    longDescription: string;
    avatarUrl: string;
    reputable: boolean;
    linkedinUrl: string;
    twitterUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    websiteUrl: string;
    businessName: string;
    updatedUtc: string;
}

export interface ListingCount {
    listingType: string;
    count: number;
}

export interface ManagedProfileDTO {
    user: ManagedUserDTO;
    listingsCount: ListingCount[];
}

export interface AttachedListingDTO {
    id: number;
    listingType: string;
    reviewSoar: Review;
    title: string;
    processing?: boolean;
}

export interface AttachedWmsServerDTO {
    id: number;
    url: string;
    standard: string;
    title: string;
    layersCount: number;
    listings: number[];
}

export interface AttachedSTACCatalogDTO {
    id: number;
    title: string;
    catalogsCount: number;
    itemsCount: number;
    listings: number[];
}

export interface ManagedProfileFullDTO {
    user: ManagedUserDTO;
    listings: AttachedListingDTO[];
    wmsServers: AttachedWmsServerDTO[];
    wmsOrphanedListings: AttachedListingDTO[];
    stacCatalogs: AttachedSTACCatalogDTO[];
}

export interface ManagedProfileBody {
    businessName: string;
    tagline?: string;
    twitterUrl?: string;
    facebookUrl?: string;
    instagramUrl?: string;
    websiteUrl?: string;
    linkedinUrl?: string;
    longDescription?: string;
    avatarBase64?: string;
}

export interface ListingMetadata {
    title?: string;
    description?: string;
    tags?: string[];
    categories?: string[];
    seoTitle?: string;
    license?: string;
}

export interface TemporalExtent {
    startUtc: string;
    endUtc: string;
}

export interface CreateAttachedListing {
    listingType: ListingType;
    metadata: ListingMetadata;
    url?: string;
    mosaic?: boolean;
    filename?: string;
}

export interface CreatedListingDTO {
    id: number;
    listing: ListingDTO;
    uploadDetails: UploadCredentialsDTO;
}

export interface UpdateAttachedListing {
    metadata: ListingMetadata;
    geometryWKT: string;
    temporalExtent?: TemporalExtent;
    adminNotes?: string;
    tms?: boolean;
    minZoom?: number;
    maxZoom?: number;
    previewBase64?: string;
    srs?: string;
}

export interface ManagedProfileListingFullDTO {
    listing: ListingDTO;
    wmsLayer?: WMSLayer;
    stacCatalog?: STACCatalogFullDTO;
    stacItem?: STACItemFullDTO;
}

export default class ApiManagedProfiles extends Api {
    public static getAllProfiles(): Promise<ManagedProfileDTO[]> {
        return this.axios.get('/v1/admin/managed-profiles').then((res) => res.data.list);
    }

    public static createProfile(name: string): Promise<ManagedProfileFullDTO> {
        const body: ManagedProfileBody = { businessName: name };
        return this.axios.post('/v1/admin/managed-profiles', body).then((res) => res.data);
    }

    public static getProfile(userId: string): Promise<ManagedProfileFullDTO> {
        return this.axios.get(`/v1/admin/managed-profiles/${userId}`).then((res) => res.data);
    }

    public static updateProfile(userId: string, body: ManagedProfileBody): Promise<ManagedProfileFullDTO> {
        return this.axios.put(`/v1/admin/managed-profiles/${userId}`, body).then((res) => res.data);
    }

    public static createListing(userId: string, body: CreateAttachedListing): Promise<CreatedListingDTO> {
        return this.axios.post(`/v1/admin/managed-profiles/${userId}/listings`, body).then((res) => res.data);
    }

    public static getListing(userId: string, listingId: number): Promise<ManagedProfileListingFullDTO> {
        return this.axios.get(`/v1/admin/managed-profiles/${userId}/listings/${listingId}`).then((res) => res.data);
    }

    public static updateListing(
        userId: string,
        listingId: number,
        body: UpdateAttachedListing
    ): Promise<ManagedProfileListingFullDTO> {
        return this.axios
            .put(`/v1/admin/managed-profiles/${userId}/listings/${listingId}`, body)
            .then((res) => res.data);
    }

    public static updateListingVisibility(
        userId: string,
        listingId: number,
        visible: boolean
    ): Promise<ManagedProfileListingFullDTO> {
        return this.axios
            .put(`/v1/admin/managed-profiles/${userId}/listings/${listingId}/visibility`, { visible })
            .then((res) => res.data);
    }

    public static createListingFileAndGetUploadCredentials(
        userId: string,
        listingId: number
    ): Promise<UploadCredentialsDTO> {
        const body = { type: ListingFileType.LEGEND };
        return this.axios.post(`/v1/admin/managed-profiles/${userId}/listings/${listingId}/files`, body).then((res) => {
            return res.data;
        });
    }

    public static deleteListingFile(userId: string, listingId: number, fileId: number): Promise<void> {
        return this.axios.delete(`/v1/admin/managed-profiles/${userId}/listings/${listingId}/files/${fileId}`);
    }
}
