import React from 'react';
import { useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ApiVouchers from '../../api/api-vouchers';
import { VoucherDTO } from '../../api/model';
import ErrorPanel from '../Shared/error-panel';
import { Button, Card, Container, Panel, Spinner, Table, TableWrapper, Title } from '../style';
import ViewHelper from '../view-helper';
import CreateVoucherModal from './create-voucher-modal';
import DeleteVoucherModal from './delete-voucher-modal';
import styled from 'styled-components';
import ManageVoucherModal from './manage-voucher-modal';

const Vouchers = () => {
    const [vouchers, setVouchers] = useState<VoucherDTO[]>(undefined);
    const [error, setError] = useState<string>(undefined);
    const [code, setCode] = useState<string>(undefined);
    const [isVoucherDetailsOpen, setIsVoucherDetailsOpen] = useState<boolean>(false);
    const [voucher, setVoucher] = useState();

    useEffect(() => {
        fetchVouchers();
    }, []);

    const handleDisplayVoucherDetails = (voucher: any) => {
        setVoucher(voucher);
        setIsVoucherDetailsOpen(true);
    };

    const onVoucherCreated = (voucher: VoucherDTO) => {
        setVouchers([...vouchers, voucher]);
    };

    const fetchVouchers = async () => {
        try {
            const result = await ApiVouchers.getVouchers();
            setVouchers(result);
        } catch (err) {
            setError(err.toString());
        }
    };

    const openDeleteVoucher = (code: string) => {
        setCode(code);
    };

    const onDeleteCancel = () => {
        setCode(undefined);
    };

    const onVoucherDelete = (code: string) => {
        setVouchers(vouchers.filter((v) => v.code !== code));
        setCode(undefined);
    };

    if (error) {
        return (
            <Container>
                <Card>
                    <ErrorPanel>{error}</ErrorPanel>
                </Card>
            </Container>
        );
    }

    if (!vouchers)
        return (
            <Container>
                <Title>Vouchers</Title>
                <Card height="585px">
                    <Spinner />
                </Card>
            </Container>
        );

    const rowEvents = {
        onClick: (_e, row: any, _rowIndex) => {
            handleDisplayVoucherDetails(row);
        },
    };

    const items = vouchers.sort((d1, d2) => d2.createdAt - d1.createdAt);
    const columns = [
        {
            dataField: 'code',
            text: 'Code',
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true,
        },
        {
            dataField: 'amount',
            text: 'Discount',
            sort: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: (cell: any, row: VoucherDTO) =>
                row.percentage && row.percentage > 0 ? `${row.percentage}%` : ViewHelper.formatUSDPriceFromCents(cell),
        },
        {
            dataField: 'tasksId',
            text: 'Total used',
            sort: true,
            formatter: (cell) => (cell ? cell.length : <></>),
        },
        {
            dataField: 'usageLimit',
            text: 'Usage Limit',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell) => ViewHelper.formatDate(cell),
        },
        {
            dataField: 'validFrom',
            text: 'Valid From',
            sort: true,
            formatter: (cell) => ViewHelper.formatDate(cell),
        },
        {
            dataField: 'validTo',
            text: 'Valid To',
            sort: true,
            formatter: (cell) => ViewHelper.formatDate(cell),
        },
        {
            dataField: 'tasksId',
            text: 'Used on tasks',
            hidden: true,
            formatter: (cell) => (cell ? cell.map((task) => `${task},`) : ''),
            csvFormatter: (cell) => (cell ? cell.map((task) => `${task},`) : ''),
        },
        {
            dataField: 'userMultiple',
            text: 'Multiple Usage',
            hidden: true,
        },
        {
            id: 'delete',
            dataField: 'deleteTask',
            isDummyField: true,
            text: '',
            headerStyle: () => {
                return { width: '100px' };
            },
            formatter: (cell, row: VoucherDTO) =>
                row.tasksId && row.tasksId.length > 0 ? (
                    <React.Fragment></React.Fragment>
                ) : (
                    <DeleteVoucherButton
                        color="danger"
                        size="sm"
                        onClick={(e: { preventDefault: () => void; stopPropagation: () => void }) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openDeleteVoucher(row.code);
                        }}
                    >
                        Delete
                    </DeleteVoucherButton>
                ),
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <Button onClick={handleClick}>
                <i className="fa fa-download" />
                &nbsp;Export as CSV
            </Button>
        );
    };

    return (
        <React.Fragment>
            <Container>
                <Title>Vouchers</Title>
                <Card>
                    <CreateVoucherModal onVoucherCreated={onVoucherCreated} />
                    <ToolkitProvider keyField="code" data={items} columns={columns}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table
                                        {...props.baseProps}
                                        pagination={paginationFactory()}
                                        rowEvents={rowEvents}
                                    />
                                </TableWrapper>
                                <Panel>
                                    <MyExportCSV {...props.csvProps}>Export CSV</MyExportCSV>
                                </Panel>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </Card>
                <DeleteVoucherModal code={code} onCancel={onDeleteCancel} onVoucherDeleted={onVoucherDelete} />
            </Container>
            <ManageVoucherModal voucher={voucher} isOpen={isVoucherDetailsOpen} setIsOpen={setIsVoucherDetailsOpen} />
        </React.Fragment>
    );
};

export default Vouchers;

const DeleteVoucherButton = styled(Button)`
    z-index: 99999;
    float: right;
`;
