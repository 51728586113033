import Api from './api';
import {
    ReportedListingDTO
} from './model';

export default class ApiReportedListings extends Api {

    public static getReportedListings(includeReviewed: boolean): Promise<ReportedListingDTO[]> {
        const params = {
            review: includeReviewed ? undefined : 'PENDING'
        };
        return this.axios.get('/v1/admin/reported-listings', { params: params }).then((res) => {
            return res.data.list;
        });
    }

    public static ignoreReport(reportId: number): Promise<ReportedListingDTO> {
        return this.axios.post(`/v1/admin/reported-listings/${reportId}/ignore`)
            .then(res => res.data);
    }

    public static deleteMap(reportId: number): Promise<ReportedListingDTO> {
        return this.axios.post(`/v1/admin/reported-listings/${reportId}/delete-map`)
            .then(res => res.data);
    }

    public static notifyOwner(reportId: number): Promise<ReportedListingDTO> {
        return this.axios.post(`/v1/admin/reported-listings/${reportId}/notify-owner`)
            .then(res => res.data);
    }

}
