import React from 'react';
import { Review } from '../../../api/model';
import { FormGroup, Input } from '../../style';

interface Props {
    onChange: (review: Review) => void;
    value: Review;
    hidePending?: boolean;
}

const InputReview = (props: Props) => {
    return (
        <FormGroup>
            <Input
                title="Listing Type"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value as any)}
                type="select"
            >
                <option value={''}>Not selected</option>
                <option value={Review.APPROVED}>Approved</option>
                <option value={Review.REJECTED}>Rejected/deleted</option>
                {!props.hidePending && <option value={Review.PENDING}>Pending</option>}
            </Input>
        </FormGroup>
    );
};

export default InputReview;
