import React, { useEffect, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { AnalyticsReportDTO, AnalyticsReportProductDTO } from '../../../api/model';
import ReportTable from '../report-table';
import ViewHelper from '../../view-helper';
import InputProvider from '../../Shared/Filters/input-provider';
import { ProductName, Provider } from '../../../api/model-api';
import { useDispatch, useSelector } from 'react-redux';
import { selectAnalyticsMonthlyReports } from '../../../store/ReportsStore/selectors';
import { actionRefreshAnalyticsMonthlyReport } from '../../../store/ReportsStore/actions';
import { Card, Col, FormGroup, Input, Label, Row, Spinner, Statistic, Subtitle } from '../../style';

const TABLE_COLUMNS_COUNTRIES: ColumnDescription[] = [
    {
        dataField: 'label',
        text: 'Country',
        sort: true,
    },
    {
        dataField: 'total',
        text: 'Total',
        sort: true,
    },
];

interface TableItem {
    label: string;
    total: number;
}

const filterProducts = (
    products?: AnalyticsReportProductDTO[],
    provider?: Provider,
    product?: ProductName,
    action?: string
): number => {
    if (!products) return 0;
    const filterProvider = provider?.toString();
    const filterProduct = ViewHelper.ANALYTICS_PRODUCT_NAMES[product];
    return products
        .filter((p) => (filterProvider ? p.providerName === filterProvider : true))
        .filter((p) => (filterProduct ? p.name === filterProduct : true))
        .map((p) =>
            Object.keys(p.actions)
                .filter((key) => (action?.length > 0 ? key === action : true))
                .map((key) => p.actions[key] as number)
        )
        .reduce((accumulator, value) => accumulator.concat(value), [])
        .reduce((acc, b) => acc + b, 0);
};

const SearchedCountries = () => {
    const dispatch = useDispatch();
    const reports = useSelector(selectAnalyticsMonthlyReports);

    const [selected, setSelected] = useState<AnalyticsReportDTO>(undefined);
    const [month, setMonth] = useState('');
    const [filter, setFilter] = useState<{ provider?: Provider; product?: ProductName; action?: string }>({});
    const [countries, setCountries] = useState<TableItem[]>([]);

    useEffect(() => {
        if (reports === undefined) {
            dispatch(actionRefreshAnalyticsMonthlyReport());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (reports) {
            setMonth(reports[0].dateLabel);
        }
    }, [reports]);

    useEffect(() => {
        if (reports && month) {
            setSelected(reports.find((r) => r.dateLabel === month));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month]);

    useEffect(() => {
        if (selected) {
            setCountries(
                selected.countries.map((c) => {
                    const r: TableItem = {
                        label: c.countryCode,
                        total: filterProducts(c.products, filter.provider, filter.product, filter.action),
                    };
                    return r;
                })
            );
        } else {
            setCountries([]);
        }
    }, [selected, filter]);

    const onProviderChange = (provider?: Provider, product?: ProductName) => {
        setFilter({ ...filter, provider, product });
    };

    return (
        <React.Fragment>
            {!reports ? (
                <Card height="120px">
                    <Spinner text="Loading Filters" />
                </Card>
            ) : (
                <Card>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="month-select">Filter by Month</Label>
                                <Input
                                    title="Select month"
                                    value={month}
                                    onChange={(e) => setMonth(e.target.value)}
                                    type="select"
                                    name="month"
                                >
                                    {reports.map((r) => (
                                        <option key={r.dateLabel} value={r.dateLabel}>
                                            {r.dateLabel}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputProvider
                                    provider={filter.provider}
                                    product={filter.product}
                                    onChange={onProviderChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
            <Row>
                <Col>
                    <Card title="Total number of active countries">
                        <Subtitle>Active countries</Subtitle>
                        <Statistic>
                            {!countries ? (
                                <Spinner />
                            ) : countries.length === 0 ? (
                                '0'
                            ) : (
                                countries.filter((c) => c.total > 0).length.toLocaleString()
                            )}
                        </Statistic>
                    </Card>
                </Col>
                <Col>
                    <Card title="Total number of countries searched by users">
                        <Subtitle>Total</Subtitle>
                        <Statistic>
                            {!selected?.products ? (
                                <Spinner />
                            ) : (
                                filterProducts(
                                    selected?.products,
                                    filter?.provider,
                                    filter?.product,
                                    filter?.action
                                ).toLocaleString()
                            )}
                        </Statistic>
                    </Card>
                </Col>
            </Row>
            {!countries && !reports ? (
                <Card height="800px">
                    <Spinner text="Loading Active Countries Table" />;
                </Card>
            ) : (
                <Card>
                    <ReportTable
                        items={countries}
                        columns={TABLE_COLUMNS_COUNTRIES}
                        keyField="label"
                        defaultSorted={[{ dataField: 'total', order: 'desc' }]}
                        csvExport
                    />
                </Card>
            )}
        </React.Fragment>
    );
};

export default SearchedCountries;
