import React, { useEffect, useState } from 'react';
import { DeliveryFormat } from '../../api/model-orders';
import ErrorPanel from '../Shared/error-panel';
import IconButton from '../Shared/icon-button';
import SoarModal from '../Shared/soar-modal';
import { Button, Col, ConfirmModalFooter, FormGroup, Input, Label } from '../style';

interface Props {
    format: string;
    note?: string;
    onUpdate: (type: string, description?: string) => void;
}

const EditDeliveryFormatModal = (props: Props) => {
    const [modal, setModal] = useState(false);
    const [format, setFormat] = useState('');
    const [note, setNote] = useState('');
    const [error, setError] = useState<string>(undefined);

    const resetByProps = () => {
        setFormat(props.format);
        if (!props.note) {
            updateDescription(props.note);
        } else {
            setNote(props.note);
        }
    };

    const updateDescription = (type: string) => {
        switch (type) {
            case DeliveryFormat.NCC:
                setNote('natural colour composite');
                break;
            case DeliveryFormat.RAW:
                setNote('raw (unprocessed) data');
                break;
            case DeliveryFormat.ST:
                setNote('stereo');
                break;
            case DeliveryFormat.PANMS:
                setNote('pan + multispectral');
                break;
            case DeliveryFormat.MS:
                setNote('multispectral imagery 4 bands');
                break;
            default:
                setNote('');
                break;
        }
    };

    useEffect(() => {
        updateDescription(format);
    }, [format]);

    useEffect(() => {
        setFormat(props.format);
        setNote(props.note || '');
    }, [props.format, props.note]);

    const toggle = () => {
        resetByProps();
        setModal(!modal);
    };

    useEffect(() => {
        if (error) {
            setError(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [note]);

    const updateDeliverableType = () => {
        if (format === 'OTHER' && note?.length < 2) {
            setError('Missing description for type OTHER');
            return;
        }
        props.onUpdate(format, note);
        toggle();
    };

    const modalTitle = 'Edit deliverable type';
    return (
        <React.Fragment>
            <IconButton title={modalTitle} onClick={() => setModal(true)} faIcon="fa-edit" />
            <SoarModal title={modalTitle} isOpen={modal} onClosed={() => setModal(false)} width="600px">
                <FormGroup>
                    <Label for="value" xs={12}>
                        Select new type
                    </Label>
                    <Col xs={12}>
                        <Input
                            value={format}
                            type="select"
                            name="select"
                            id="exampleSelect"
                            onChange={(e) => setFormat(e.target.value)}
                        >
                            {Object.keys(DeliveryFormat).map((v) => (
                                <option key={v}>{v}</option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="value" xs={12}>
                        Additional description
                    </Label>
                    <Col xs={12}>
                        <Input
                            invalid={error?.length > 0}
                            disabled={format !== DeliveryFormat.OTHER}
                            value={note}
                            type="text"
                            onChange={(e) => setNote(e.target.value)}
                        />
                        {error && <ErrorPanel>{error}</ErrorPanel>}
                    </Col>
                </FormGroup>
                <ConfirmModalFooter margin="5px 0px">
                    <Button onClick={updateDeliverableType}>Confirm</Button>
                    <Button color="warning" onClick={() => setModal(false)}>
                        Cancel
                    </Button>
                </ConfirmModalFooter>
            </SoarModal>
        </React.Fragment>
    );
};

export default EditDeliveryFormatModal;
