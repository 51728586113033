import React from 'react';
import { FormGroup, Label } from '../style';
import { ReviewRejectionCategory, ReviewRejectionReason } from '../../api/model';
import CheckOptions, { CheckOption } from '../Shared/check-options';

const RejectReasons: ReviewRejectionReason[] = [
    {
        category: ReviewRejectionCategory.NEEDS_BETTER_DESCRIPTION,
        reason: 'Your map needs a better description.',
        feedback:
            "Your map lacks a proper description and/or tags. Adding a clear description will allow your map to become more discoverable and help users better understand what they're seeing. For example, where is the image from originally? Was it taken by a satellite or from a plane / drone? For more information see our blog here: https://about.soar.earth/rejection-templates/enhancing-your-map-with-descriptions-and-tags.html ",
        blogLink: 'https://about.soar.earth/rejection-templates/enhancing-your-map-with-descriptions-and-tags.html',
    },
    {
        category: ReviewRejectionCategory.POOR_IMAGE_QUALITY,
        reason: "Your map doesn't meet our quality standards.",
        feedback:
            "The quality of your submitted map has been graded as poor. This could be for a variety of factors such as low resolution, pixelation, color balance issues, blurriness, lack of context or labels etc. For more information on how to make your map meet Soar's quality standards please see our blog here: https://about.soar.earth/rejection-templates/improving-your-map-quality",
        blogLink: 'https://about.soar.earth/rejection-templates/improving-your-map-quality',
    },
    {
        category: ReviewRejectionCategory.WRONG_LOCATION,
        reason: 'Your map is poorly geolocated.',
        feedback:
            "Your map doesn't contain the correct geolocation data to allow it to appear in the correct location on the Soar atlas. For more info see our blog here: https://about.soar.earth/rejection-templates/ensuring-your-map-appears-correctly-on-soar.html",
        blogLink: 'https://about.soar.earth/rejection-templates/ensuring-your-map-appears-correctly-on-soar.html',
    },
    {
        category: ReviewRejectionCategory.POTENTIAL_COPYRIGHT_ISSUE,
        reason: 'Your map contains a potential copyright issue.',
        feedback:
            'We suspect your map may contain data owned by another user and/or business, company, or organisation. For more information please see our blog here: https://about.soar.earth/rejection-templates/ensuring-data-ownership-and-or-credit.html',
        blogLink: 'https://about.soar.earth/rejection-templates/ensuring-data-ownership-and-or-credit.html',
    },
    {
        category: ReviewRejectionCategory.NOT_MAP_IMAGE,
        reason: 'Your upload does not contain a map.',
        feedback:
            "Your submission does not fit the definition of a map image that can be uploaded to the Soar's atlas. This includes images captured by drones, like .JPG non-georeferenced and/or at an oblique angle. Soar only accepts top down map images to allow for a better mapping experience. For more information see our blog here: https://about.soar.earth/rejection-templates/choosing-the-right-maps-to-upload-to-soar.html",
        blogLink: 'https://about.soar.earth/rejection-templates/choosing-the-right-maps-to-upload-to-soar.html',
    },
    {
        category: ReviewRejectionCategory.UPLOAD_ERROR,
        reason: 'Upload error.',
        feedback:
            'Unfortunately your map ran into an error while uploading. This may happen if the upload page is closed before the upload is completed. Please also check your internet connection and try again. For more help please see our blog here: https://about.soar.earth/rejection-templates/troubleshooting-map-upload-errors',
        blogLink: 'https://about.soar.earth/rejection-templates/troubleshooting-map-upload-errors',
    },
    {
        category: ReviewRejectionCategory.INCORRECT_VIEWABLE_FORMAT,
        reason: 'Your map is not in the correct viewable format.',
        feedback:
            "The Soar atlas is designed to be viewed by non-mapping experts, for this reason we only accept maps and imagery in 8-bit RGB format. This way, more people can see your content and interact with it. But don't worry, the conversion to RGB format is very easy using common image processing and mapping software. Here is a link to find out more: https://about.soar.earth/blog-pages/acceptable-image-formats-for-uploading-your-maps-onto-soar",
        blogLink: 'https://about.soar.earth/blog-pages/acceptable-image-formats-for-uploading-your-maps-onto-soar',
    },
    {
        category: ReviewRejectionCategory.OTHER,
        reason: 'Other',
        feedback: '',
    },
];

interface Props {
    onRejectionReasonUpdated: (reasons: ReviewRejectionReason[]) => void;
}

const RejectReason = (props: Props) => {
    const handleRejectionReasons = (reasons, feedback) => {
        if (reasons.find((rejectReason) => rejectReason.category === ReviewRejectionCategory.OTHER)) {
            props.onRejectionReasonUpdated(
                reasons.map((rejectReason) => {
                    if (rejectReason.category === ReviewRejectionCategory.OTHER) {
                        return {
                            category: rejectReason.category,
                            reason: rejectReason.reason,
                            feedback: feedback,
                        } as ReviewRejectionReason;
                    }
                    return rejectReason;
                })
            );
        } else {
            props.onRejectionReasonUpdated(reasons);
        }
    };

    return (
        <React.Fragment>
            <FormGroup>
                <Label for="feedback">Rejection Reason</Label>
                <CheckOptions
                    options={RejectReasons.map(
                        (rejectReason) =>
                        ({
                            ...rejectReason,
                            value: rejectReason.category,
                            title: rejectReason.reason,
                            description: rejectReason.feedback,
                        } as CheckOption)
                    )}
                    onChange={(reasons, feedback) => handleRejectionReasons(reasons, feedback)}
                />
            </FormGroup>
        </React.Fragment>
    );
};

export default RejectReason;
