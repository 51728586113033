import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import './box-zoom-control.css';

const BoxZoomControl = createControlComponent(() => {
    return new L.Control.BoxZoomControl({
        position: 'topleft',
    });
});

export default BoxZoomControl;

// TODO: Overhaul to make it look and feel like the other annotation tools
L.Control.BoxZoomControl = L.Control.extend({
    _isActive: false,
    _startLatLng: null,
    _drawPolygon: null,
    _boxZoomButton: null,
    _mouseMoved: false,

    initialize: function (options) {
        this.options.position = options.position;
    },
    onAdd: function () {
        const boxZoomButton = L.DomUtil.create('button');
        boxZoomButton.setAttribute('id', 'box-zoom-button');
        boxZoomButton.classList.add('box-zoom-button');
        this._boxZoomButton = boxZoomButton;

        boxZoomButton.onclick = () => {
            if (this._isActive) {
                this._stop();
            } else {
                this._start();
            }
        };
        
        return boxZoomButton;
    },
    _start: function () {
        const map = this._map;
        map.dragging.disable();
        L.DomUtil.addClass(map.getContainer(), 'leaflet-crosshair');
        this._isActive = true;
        this._mouseMoved = false;
        this._startLatLng = null;

        L.DomEvent.on(map, 'mousedown', this._handleMouseDown, this);
        L.DomEvent.on(map, 'mousemove', this._handleMouseMove, this);
        L.DomEvent.on(map, 'mouseup', this._handleMouseUp, this);
    },
    _stop: function () {
        const map = this._map;
        map.dragging.enable();
        const boxZoomButton = this._boxZoomButton;
        boxZoomButton.setAttribute('style', '');
        L.DomUtil.removeClass(map.getContainer(), 'leaflet-crosshair');

        L.DomEvent.off(map, 'mousedown', this._handleMouseDown, this);
        L.DomEvent.off(map, 'mousemove', this._handleMouseMove, this);
        L.DomEvent.off(map, 'mouseup', this._handleMouseUp, this);

        this._isActive = false;
        this._mouseMoved = false;
        this._startLatLng = null;

        if (this._drawPolygon) {
            map.removeLayer(this._drawPolygon);
            this._drawPolygon = null;
        }
    },
    _handleMouseDown: function (e) {
        this._startLatLng = e.latlng;
    },
    _handleMouseMove: function (e) {
        if (!this._startLatLng || !e.latlng) {
            return;
        }

        e.originalEvent.preventDefault();
        this._mouseMoved = true;

        const ne = this._startLatLng;
        const nw = new L.LatLng(this._startLatLng.lat, e.latlng.lng);
        const sw = e.latlng;
        const se = new L.LatLng(e.latlng.lat, this._startLatLng.lng);

        if (!this._drawPolygon) {
            this._drawPolygon = new L.Polygon([ne, nw, sw, se]);
            this._map.addLayer(this._drawPolygon);
        } else {
            this._drawPolygon.setLatLngs([ne, nw, sw, se]);
        }
    },
    _handleMouseUp: function (e) {
        const map = this._map;
        if ((!this._mouseMoved && this._isActive) || (!this._startLatLng || !this._isActive)) {
            this._stop();
            return;
        }

        const ne = this._startLatLng;
        const sw = e.latlng;

        const bounds = L.latLngBounds([ne, sw]);
        map.fitBounds(bounds, { animate: false });
        this._stop();
    },
});

L.control.boxZoomControl = (opts) => {
    return new L.Control.BoxZoomControl({ ...opts });
};
