import { ReportedListingDTO } from '../../api/model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

export interface IReduxReportedListingsStore {
    loading: boolean;
    reportedListings?: ReportedListingDTO[];
    includeReviewed: boolean;
    error?: string;
}

const initialState: IReduxReportedListingsStore = {
    loading: false,
    includeReviewed: false
};

export default function (state: IReduxReportedListingsStore = initialState, action: Action): IReduxReportedListingsStore {
    switch (action.type) {
        case at.APP_REPORTED_LISTINGS_LOADING:
            return { ...state, loading: action.payload };

        case at.APP_REPORTED_LISTINGS_UPDATED:
            return { ...state, reportedListings: action.payload.listings, includeReviewed: action.payload.includeReviewed, error: undefined };

        case at.APP_REPORTED_LISTINGS_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
}
