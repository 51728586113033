import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiComments from '../../../api/api-comments';
import { ReportedCommentDTO, Review } from '../../../api/model';
import { DatesFromTo } from '../../../api/model-api';
import { actionRefreshReportedComments, actionUpdateReportedComments } from '../../../store/AppStore/actions';
import { selectReportedCommentsLoading, selectReportedComments } from '../../../store/AppStore/selectors';
import InputDateRange from '../../Shared/Filters/DateRange/input-date-range';
import { Card, Col, FormGroup, Input, Label, Row, Spinner } from '../../style';
import ReportedTable from './reported-table';

interface Props {
    onUserClick: (userEmail: string) => void;
}

const Reported = (props: Props) => {
    const dispatch = useDispatch();
    const data = useSelector(selectReportedComments);
    const loading = useSelector(selectReportedCommentsLoading);
    const [filtered, setFiltered] = useState<ReportedCommentDTO[]>([]);
    const [userSearch, setUserSearch] = useState<string>('');
    const [reporterSearch, setReporterSearch] = useState<string>('');
    const [dates, setDates] = useState<DatesFromTo>(undefined);
    const [reviewFilter, setReviewFilter] = useState<Review>(Review.PENDING);

    useEffect(() => {
        if (data) {
            const from = dates?.from ? dates.from.getTime() / 1000 : undefined;
            const to = dates?.to ? dates.to.getTime() / 1000 : undefined;
            const fil = data
                .filter((cr) =>
                    reporterSearch?.length > 0
                        ? cr.userEmail?.toLocaleLowerCase().includes(reporterSearch) ||
                          cr.userName?.toLocaleLowerCase().includes(reporterSearch)
                        : true
                )
                .filter((cr) =>
                    userSearch?.length > 0
                        ? cr?.comment?.userEmail?.toLocaleLowerCase().includes(userSearch) ||
                          cr?.comment?.userName?.toLocaleLowerCase().includes(userSearch)
                        : true
                )
                .filter((d) => (from ? d.createdAt >= from : true))
                .filter((d) => (to ? d.createdAt < to : true));
            setFiltered(fil);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, userSearch, reporterSearch, dates]);

    const onDeleteClick = async (reportId: string) => {
        await ApiComments.deleteReportedComment(reportId);
        dispatch(actionUpdateReportedComments(data.filter((r) => r.id !== reportId)));
    };

    const onIgnoreClick = async (reportId: string) => {
        await ApiComments.ignoreReportedComment(reportId);
        dispatch(actionUpdateReportedComments(data.filter((r) => r.id !== reportId)));
    };

    const onReviewChange = async (e) => {
        dispatch(actionRefreshReportedComments(e.target.value));
        setReviewFilter(e.target.value);
    };

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Dates</Label>
                            <InputDateRange fromTo={dates} onChange={setDates} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="user-search">Search by User</Label>
                            <Input
                                title="Search by user"
                                placeholder="Search by user"
                                value={userSearch}
                                onChange={(e) => setUserSearch(e.target.value?.toLocaleLowerCase())}
                                name="user search"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="reporterSearch">Search by Reporter</Label>
                            <Input
                                title="Search by reporter"
                                placeholder="Search by reporter"
                                value={reporterSearch}
                                onChange={(e) => setReporterSearch(e.target.value?.toLocaleLowerCase())}
                                name="reporter search"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="reviewFilter">Filter by status</Label>
                            <Input
                                value={reviewFilter}
                                onChange={(e) => {
                                    onReviewChange(e);
                                }}
                                type="select"
                                name="review-range"
                            >
                                {Object.keys(Review).map((key) => (
                                    <option value={Review[key]}>{`${Review[key][0].toUpperCase()}${Review[key]
                                        .slice(1)
                                        .toLowerCase()}`}</option>
                                ))}
                                <option value={''}>All</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {loading && (
                <Card height="600px">
                    <Spinner title={'Fetching data'} />
                </Card>
            )}
            {!loading && (
                <Card>
                    <ReportedTable
                        items={filtered}
                        onDeleteClick={onDeleteClick}
                        onIgnoreClick={onIgnoreClick}
                        onUserClick={props.onUserClick}
                    />
                </Card>
            )}
        </React.Fragment>
    );
};
export default Reported;
