import React, { useState } from 'react';
import UploadSingleFile from '../Shared/upload-single-file';
import ErrorPanel from '../Shared/error-panel';
import { CollectAction, OrderDTO } from '../../api/model-orders';
import { Button, FormGroup, Input, Label, Panel } from '../style';
import SoarModal from '../Shared/soar-modal';
import styled from 'styled-components';
import { UploadCredentialsDTO } from '../../api/model';

const DESCRIPTION_REQUIRED = [CollectAction.INFO, CollectAction.FAILED];

interface Props {
    order: OrderDTO;
    updateLog: (
        actionType: CollectAction,
        content: string,
        fileName: string,
        date: string
    ) => Promise<UploadCredentialsDTO>;
    invalidate: () => void;
}

const AddCollectionInfo = (props: Props) => {
    const [addNote, setAddNote] = useState(false);
    const [pending, setPending] = useState(false);
    const [noteContent, setNoteContent] = useState<string>(null);
    const [noteFilename, setNoteFilename] = useState<string>(null);
    const [actionType, setActionType] = useState<CollectAction>();
    const [date, setDate] = useState<string>();
    const [error, setError] = useState<string>();
    const [uploadCredentials, setUploadCredentials] = useState<UploadCredentialsDTO | undefined>(undefined);

    const createNote = () => {
        if (DESCRIPTION_REQUIRED.includes(actionType) && !noteContent) {
            setError('Description required');
            return;
        }
        setPending(true);
        props.updateLog(actionType, noteContent, noteFilename, date).then((credentials) => {
            setUploadCredentials(credentials);
            if (!credentials) {
                complete();
            }
        });
    };

    const toggle = () => {
        setAddNote(false);
        setPending(false);
        setError(null);
        setDate(undefined);
        setActionType(null);
        setNoteFilename(null);
        setNoteContent(null);
        setUploadCredentials(undefined);
    };
    const complete = () => {
        toggle();
        props.invalidate();
    };

    const action = (type: CollectAction) => {
        setAddNote(true);
        setActionType(type);
    };

    const description = () => {
        switch (actionType) {
            case CollectAction.SUCCESS:
                return 'Collection successful';
            case CollectAction.FAILED:
                return 'Collection failed';
            case CollectAction.SCHEDULED:
                return 'Schedule collection';
            case CollectAction.INFO:
                return 'Add other info';
        }
    };

    return (
        <>
            <Button color="success" onClick={() => action(CollectAction.SUCCESS)}>
                Collection successful
            </Button>
            <Button color="success" onClick={() => action(CollectAction.FAILED)}>
                Collection failed
            </Button>
            <Button color="success" onClick={() => action(CollectAction.SCHEDULED)}>
                Schedule collection
            </Button>
            <Button color="success" onClick={() => action(CollectAction.INFO)}>
                Add other info
            </Button>
            <SoarModal title={description()} isOpen={addNote} onClosed={() => toggle()} width="600px">
                <FormGroup>
                    <Label for="fileFormat">Description</Label>
                    <Input type="text" name="note" id="note" onChange={(e) => setNoteContent(e.target.value)} />
                </FormGroup>
                {actionType !== CollectAction.INFO && (
                    <FormGroup>
                        <Label for="fileFormat">Date</Label>
                        <Input type="date" name="schedule" id="schedule" onChange={(e) => setDate(e.target.value)} />
                    </FormGroup>
                )}
                {!!error && <ErrorPanel>{error}</ErrorPanel>}
                <UploadSingleFile
                    uploadCredentials={uploadCredentials}
                    onFileSelected={(file: File) => setNoteFilename(file.name)}
                    onUploadFinish={complete}
                />
                <CollectionInfoPanel>
                    <Button disabled={pending} onClick={createNote}>
                        Submit
                    </Button>
                    <Button color="danger" onClick={() => toggle()}>
                        Cancel
                    </Button>
                </CollectionInfoPanel>
            </SoarModal>
        </>
    );
};

export default AddCollectionInfo;

const CollectionInfoPanel = styled(Panel)`
    margin: 30px 0px 0px 0px;
`;
