import React, { useEffect, useState } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import styled from 'styled-components';
import { OrderForAdminDTO, OrderStatus } from '../../../api/model-orders';
import { Button, Table, TableWrapper } from '../../style';
import ViewHelper from '../../view-helper';

interface Props {
    items: OrderForAdminDTO[];
}

const ManageOrdersTable = (props: Props) => {
    const [orders, setOrders] = useState(props.items);

    useEffect(() => {
        setOrders(props.items);
    }, [props.items]);

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'supplierCode',
            text: 'Supplier',
            sort: false,
            hidden: true,
        },
        {
            dataField: 'orderNumber',
            text: 'Order',
            sort: false,
        },
        {
            text: 'Status',
            dataField: 'status',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell) => ViewHelper.formatDate(cell),
            csvFormatter: (cell) => ViewHelper.formatDateWithTime(cell),
        },
        {
            dataField: 'productOption',
            formatter: (cell) => ViewHelper.PRODUCT_OPTION_LABELS[cell],
            text: 'Type',
        },
        {
            text: 'Format',
            dataField: 'deliveryFormat',
            formatter: (cell) => ViewHelper.DELIVERY_FORMATS_LABELS[cell],
            hidden: true,
        },
        {
            dataField: 'delayInSeconds',
            text: 'Delay',
            sort: false,
            hidden: true,
            formatter: (cell) => (cell && cell > 0 ? ViewHelper.formatDuration(cell * 1000) : ''),
            csvExport: false,
        },
        {
            dataField: 'areaInKm',
            text: 'Area',
            sort: true,
            formatter: (cell, row: OrderForAdminDTO) => `${row.archiveSelectedAreaInKm || row.areaInKm}km²`,
        },
        {
            dataField: 'amount',
            text: 'Total',
            sort: true,
            formatter: (_cell, row: OrderForAdminDTO) =>
                ViewHelper.formatPriceFromCents(row.payment.amount, row.payment.currency),
            csvFormatter: (cell) => ViewHelper.priceFromCents(cell),
        },
        {
            dataField: 'paidByVoucher',
            text: 'Discount',
            csvText: 'Discount',
            hidden: true,
            formatter: (cell, row: OrderForAdminDTO) =>
                cell ? (
                    <span title={row.payment.voucher}>{`${ViewHelper.formatPriceFromCents(
                        row.payment.paidByVoucher,
                        row.payment.currency
                    )}`}</span>
                ) : (
                    ''
                ),
            csvFormatter: (cell) => ViewHelper.priceFromCents(cell),
        },
        {
            dataField: 'paidByStripe',
            text: 'Paid',
            sort: true,
            formatter: (_cell, row: OrderForAdminDTO) => (ViewHelper.isOrderFullyPaid(row.payment) ? 'YES' : 'NO'),
            csvFormatter: (cell) => ViewHelper.priceFromCents(cell),
        },
        {
            dataField: 'paidByOther',
            hidden: true,
            text: 'Paid by other',
            sort: true,
            csvFormatter: (cell) => ViewHelper.priceFromCents(cell),
        },
        {
            dataField: 'currency',
            hidden: true,
            text: 'Currency',
        },
        {
            dataField: 'currencyRate',
            hidden: true,
            text: 'Currency Rate',
            csvFormatter: (cell) => (cell ? cell : ''),
        },
        {
            dataField: 'logs',
            hidden: true,
            text: 'Approved by provider',
            csvFormatter: (_cell, row: OrderForAdminDTO) => {
                const log = row.logs?.find((l) => l.status === OrderStatus.COLLECTION);
                if (log) {
                    return ViewHelper.formatDateTimeFromISO(log.created);
                }
                return '';
            },
        },
        {
            dataField: 'logs',
            hidden: true,
            text: 'Delivered',
            csvFormatter: (_cell, row: OrderForAdminDTO) => {
                const log = row.logs?.find((l) => l.status === OrderStatus.DELIVERED);
                if (log) {
                    return ViewHelper.formatDateTimeFromISO(log.created);
                }
                return '';
            },
        },
        {
            dataField: 'logs',
            hidden: true,
            text: 'Rejected',
            csvFormatter: (_cell, row: OrderForAdminDTO) => {
                const log = row.logs?.find((l) => l.status === OrderStatus.REJECTED);
                if (log) {
                    return ViewHelper.formatDateTimeFromISO(log.created);
                }
                return '';
            },
        },
    ];

    const rowEvent = {
        onClick: (_e, row) => {
            window.open(`/orders/${row.id}`, '_blank');
        },
    };

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <Button onClick={handleClick}>
                <i className="fa fa-download" />
                &nbsp;Export CSV
            </Button>
        );
    };

    return (
        <ToolkitProvider
            keyField="id"
            data={orders}
            columns={columns}
            search={{ searchFormatted: true }}
            exportCSV={{
                fileName: 'soar-satellite-orders.csv',
            }}
        >
            {(props) => (
                <React.Fragment>
                    <ManageUserOrdersTableWrapper>
                        <Table {...props.baseProps} rowEvents={rowEvent} filter={filterFactory()} />
                    </ManageUserOrdersTableWrapper>

                    <Panel>
                        <MyExportCSV {...props.csvProps}>Export CSV</MyExportCSV>
                    </Panel>
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
};

export default ManageOrdersTable;

const ManageUserOrdersTableWrapper = styled(TableWrapper)`
    td:nth-child(2),
    th:nth-child(2) {
        overflow-wrap: anywhere;
    }
`;

const Panel = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    & button {
        :not(:first-child) {
            margin-left: 10px;
        }
    }
    & div {
        margin: 5px;
    }
    & a {
        margin: 5px;
    }
`;
