import React from 'react';
import { Card, InvisibleCard, Spinner, Subtitle, SubtitleHint } from '../style';
import FeaturedAddItem from './featured-add-item';
import FeaturedList from './featured-list';
import styled from 'styled-components';
import { ListingDTO } from '../../api/model';

interface Props {
    featuredMaps: ListingDTO[];
    type: string;
    name: string;
    count: number;
    isLoading: boolean;
}

const FeaturedSelection = (props: Props) => {
    return (
        <React.Fragment>
            <Card key={props.name}>
                <FeaturedControl>
                    <SubtitleHint>{`Add up to ${props.count} maps to ${props.name}`}</SubtitleHint>
                    <FeaturedAddItem name={props.name} type={props.type} count={props.count} />
                </FeaturedControl>
            </Card>
            {!props.isLoading && props.featuredMaps && props.featuredMaps?.length === 0 ? (
                <Card>
                    <Subtitle>{`The ${props.name} featured does not have any featured maps yet, You can add ${props.count}.`}</Subtitle>
                </Card>
            ) : null}
            {props.isLoading ? (
                <Card height={'64px'}>
                    <Spinner />
                </Card>
            ) : null}
            {!props.isLoading && props.featuredMaps && props.featuredMaps.length > 0 ? (
                <FeaturedListContainer>
                    <FeaturedList featuredItems={props.featuredMaps} type={props.type} />
                </FeaturedListContainer>
            ) : null}
        </React.Fragment>
    );
};

export default FeaturedSelection;

const FeaturedControl = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px -8px 5px 0px;
`;

const FeaturedListContainer = styled(InvisibleCard)`
    padding: 0px;
`;
