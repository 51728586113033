import React, { useEffect, useState } from 'react';
import { LogType, OrderDTO, OrderStatus } from '../../api/model-orders';
import Clock from './clock';

interface Props {
    order: OrderDTO;
}

const END_STATUSES = [OrderStatus.DELETED, OrderStatus.DELIVERED, OrderStatus.REJECTED];

const OrderClock = (props: Props) => {
    const [values, setValues] = useState<any>(undefined);

    useEffect(() => {
        if (END_STATUSES.includes(props.order.status)) {
            const lastLog = props.order.logs.filter((l) => l.type === LogType.STATUS).pop();
            if (lastLog && END_STATUSES.includes(lastLog.status)) {
                setValues({ start: props.order.created, end: lastLog.created });
            }
        } else {
            setValues({ start: props.order.created });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (values) {
        return <Clock start={values?.start} end={values?.end} />;
    } else {
        return <></>;
    }
};

export default OrderClock;
