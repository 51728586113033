import React, { useState } from 'react';
import ApiOrders from '../../api/api-orders';
import { CollectAction, LogType, OrderDTO, OrderStatus } from '../../api/model-orders';
import ButtonsPanel from '../Shared/buttons-panel';
import ErrorPanel from '../Shared/error-panel';
import SoarModal from '../Shared/soar-modal';
import UploadSingleFile from '../Shared/upload-single-file';
import { Button, FormGroup, Input, Label, Panel, Row } from '../style';
import AddCollectionInfo from './add-collection-info';
import OrderLogs from './order-logs';
import styled from 'styled-components';
import { UploadCredentialsDTO } from '../../api/model';

const STATUSES_FOR_UPLOAD = [OrderStatus.COLLECTION, OrderStatus.QA_SOAR, OrderStatus.QA_FAILED];
const LOGS = [
    LogType.NOTE,
    LogType.COLLECT_FAILED,
    LogType.COLLECT_INFO,
    LogType.COLLECT_SCHEDULED,
    LogType.COLLECT_SUCCESS,
    LogType.NOTE_PROVIDER,
    LogType.NOTE_REJECTED,
    LogType.QA_INFO,
];

interface Props {
    order: OrderDTO;
    invalidate: () => void;
}

const OrderNotes = (props: Props) => {
    const { order } = props;

    const [addNote, setAddNote] = useState(false);
    const [pending, setPending] = useState(false);
    const [noteContent, setNoteContent] = useState<string>(null);
    const [noteType, setNoteType] = useState<LogType>(LogType.NOTE);
    const [noteFilename, setNoteFilename] = useState<string>(null);
    const [uploadCredentials, setUploadCredentials] = useState<UploadCredentialsDTO | undefined>(undefined);
    const [error, setError] = useState<string>();

    const createNote = () => {
        if (!noteContent) {
            setError('Description required');
            return;
        }
        setPending(true);
        ApiOrders.createNote(order.id, noteContent, noteFilename, noteType).then((credentials) => {
            setUploadCredentials(credentials);
            if (!credentials) {
                complete();
            }
        });
    };

    const toggle = () => {
        setAddNote(false);
        setPending(false);
        setNoteContent(null);
        setNoteFilename(null);
        setUploadCredentials(undefined);
        setError(null);
    };
    const complete = () => {
        setError(null);
        setPending(false);
        setAddNote(false);
        setNoteType(LogType.NOTE);
        props.invalidate();
    };

    return (
        <React.Fragment>
            <OrderLogs
                orderId={order.id}
                logs={order.logs.filter((l) => LOGS.includes(l.type))}
                isAdmin
                title="Notes"
            />
            <ButtonsPanel>
                {STATUSES_FOR_UPLOAD.includes(order.status) && (
                    <AddCollectionInfo
                        order={order}
                        updateLog={(
                            _actionType: CollectAction,
                            _noteContent: string,
                            _noteFilename: string,
                            _date: string
                        ) => {
                            return ApiOrders.collectInfoLog(order.id, _actionType, _noteContent, _noteFilename, _date);
                        }}
                        invalidate={() => props.invalidate()}
                    />
                )}
                <Button onClick={() => setAddNote(true)}>Add note</Button>
            </ButtonsPanel>
            <Row>
                <SoarModal title="Create Note" isOpen={addNote} onClosed={() => toggle()} width="600px">
                    <FormGroup>
                        <Label for="fileFormat">Type:</Label>
                        <Input
                            title="Note type"
                            value={noteType}
                            onChange={(e) => setNoteType(e.target.value as any)}
                            type="select"
                            name="note_type"
                        >
                            <option value={LogType.NOTE}>Internal</option>
                            <option value={LogType.NOTE_PROVIDER}>Provider</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="fileFormat">Content:</Label>
                        <Input type="text" name="note" id="note" onChange={(e) => setNoteContent(e.target.value)} />
                    </FormGroup>
                    {error && <ErrorPanel>{error}</ErrorPanel>}
                    <UploadSingleFile
                        uploadCredentials={uploadCredentials}
                        onFileSelected={(file: File) => setNoteFilename(file.name)}
                        onUploadFinish={complete}
                    />
                    <ConfirmNotesContainer>
                        <Button disabled={pending} onClick={createNote}>
                            Submit
                        </Button>
                        <Button color="warning" onClick={() => toggle()}>
                            Cancel
                        </Button>
                    </ConfirmNotesContainer>
                </SoarModal>
            </Row>
        </React.Fragment>
    );
};

export default OrderNotes;

const ConfirmNotesContainer = styled(Panel)`
    margin-top: 30px;
`;
