import ApiAdmin from '../../api/api-admin';
import ApiComments from '../../api/api-comments';
import ApiPlatform from '../../api/api-featured';
import ApiListings from '../../api/api-listings';
import ApiOrders from '../../api/api-orders';
import ApiUser from '../../api/api-user';
import { ReportedCommentDTO, Review, UserDTO } from '../../api/model';
import { default as Config, default as config } from '../../config';
import { actionTypes as at } from './constants';

export const saveUserProfileAction = (user?: UserDTO) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.APP_SAVE_USER_PROFILE,
            payload: user,
        });
    };
};

export const fetchUserProfileAction = () => {
    return async (dispatch, getState) => {
        try {
            const user = await ApiUser.getMyUserProfile();
            dispatch({
                type: at.APP_SAVE_USER_PROFILE,
                payload: user,
            });
        } catch (err) {
            dispatch({
                type: at.APP_SAVE_USER_PROFILE,
                payload: undefined,
            });
        }
    };
};

export const actionRefreshFeatured = (type: string) => {
    return async (dispatch, getState) => {
        try {
            const listings = await ApiPlatform.getFeaturedListings(type);
            dispatch({
                type: at.APP_FEATURED_UPDATED,
                payload: listings,
            });
        } catch (err) {
            dispatch({
                type: at.APP_FEATURED_ERROR,
                payload: err,
            });
        }
    };
};

export const actionUpdateFeatured = (listingId: number, type: string, featured: string[], addingFeatured: boolean) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.APP_FEATURED_UPDATED,
                payload: undefined,
            });
            let typeArray = [];
            if (addingFeatured) {
                typeArray = [...featured, type];
            } else {
                typeArray = featured.filter((feature) => feature !== type);
            }
            await ApiAdmin.updateFeatured(listingId, typeArray);

            const listings = await ApiPlatform.getFeaturedListings(type);
            dispatch({
                type: at.APP_FEATURED_UPDATED,
                payload: listings,
            });
        } catch (err) {
            dispatch({
                type: at.APP_FEATURED_ERROR,
                payload: err,
            });
        }
    };
};

export const actionClearFeatured = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.APP_FEATURED_UPDATED,
                payload: [],
            });
        } catch (err) {
            dispatch({
                type: at.APP_FEATURED_ERROR,
                payload: err,
            });
        }
    };
};

export const actionUpdateContactUs = (includeHandled: boolean) => {
    return async (dispatch, getState) => {
        dispatch({ type: at.APP_CONTACT_US_LOADING, payload: true });
        try {
            const contactUsInquiries = await ApiAdmin.getContactUs(includeHandled);
            dispatch({ type: at.APP_CONTACT_US_UPDATED, payload: contactUsInquiries });
        } catch (err) {
            dispatch({ type: at.APP_CONTACT_US_ERROR, payload: err });
        } finally {
            dispatch({ type: at.APP_CONTACT_US_LOADING, payload: false });
        }
    };
};

export const actionUpdateSupermapQueue = (listingIdFrom?: number) => {
    return async (dispatch, getState) => {
        dispatch({ type: at.APP_SUPERMAP_QUEUE_LOADING, payload: true });
        try {
            let supermapQueue = await ApiListings.getListings(
                Config.SUPER_MAP_PROJECT_ID,
                listingIdFrom,
                'PENDING',
                config.UPLOAD_QUEUE_SIZE
            );
            if (supermapQueue.total > 0 && listingIdFrom && supermapQueue.listings.length === 0) {
                supermapQueue = await ApiListings.getListings(
                    Config.SUPER_MAP_PROJECT_ID,
                    undefined,
                    'PENDING',
                    config.UPLOAD_QUEUE_SIZE
                );
            }
            dispatch({ type: at.APP_SUPERMAP_QUEUE_UPDATED, payload: supermapQueue });
        } catch (err) {
            dispatch({ type: at.APP_SUPERMAP_QUEUE_ERROR, payload: err });
        } finally {
            dispatch({ type: at.APP_SUPERMAP_QUEUE_LOADING, payload: false });
        }
    };
};

export const actionUpdateOrders = () => {
    return async (dispatch, getState) => {
        dispatch({ type: at.APP_ORDERS_LOADING, payload: true });
        try {
            const orders = await ApiOrders.getOrders();
            dispatch({ type: at.APP_ORDERS_UPDATED, payload: orders });
        } catch (err) {
            console.log(err);
            dispatch({ type: at.APP_ORDERS_ERROR, payload: err });
        } finally {
            dispatch({ type: at.APP_ORDERS_LOADING, payload: false });
        }
    };
};

export const actionRefreshReportedComments = (commentStatus: Review) => {
    return async (dispatch, getState) => {
        dispatch({ type: at.APP_REPORTED_COMMENTS_LOADING, payload: true });
        try {
            const reported = await ApiComments.getReportedComments(commentStatus);
            dispatch(actionUpdateReportedComments(reported));
        } catch (err) {
            console.log(err);
            dispatch({ type: at.APP_REPORTED_COMMENTS_ERROR, payload: err });
        } finally {
            dispatch({ type: at.APP_REPORTED_COMMENTS_LOADING, payload: false });
        }
    };
};

export const actionUpdateReportedComments = (reported: ReportedCommentDTO[]) => {
    return { type: at.APP_REPORTED_COMMENTS_UPDATED, payload: reported };
};