import * as Sentry from '@sentry/browser';
import { Base64 } from 'js-base64';
import L, { LatLngBounds } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { FeatureGroup, LayersControl, TileLayer } from 'react-leaflet';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Wellknown from 'wellknown';
import ApiOrders from '../../api/api-orders';
import { ArchiveDTO, OrderFileDTO } from '../../api/model-orders';
import Config from '../../config';
import GeoUtil from '../../lib/geo-util';
import IconButton from '../Shared/icon-button';
import OpacitySlider from '../Shared/opacity-slider';
import { Button, FormGroup, Input, Label, Spinner } from '../style';
import ViewHelper from '../view-helper';
import ArchiveTable from './archive-table';
import LeafletMap from '../MapView/leaflet-map';
import DistortableOverlay from '../MapView/Annotations/DistortableImageOverlay/DistortableOverlay';
import AOISelect from '../MapView/Annotations/SatelliteAOI/aoi-select';
import LeafletBaseMaps from '../Shared/leaflet-basemaps';

const { BaseLayer, Overlay } = LayersControl;

interface OrderFileMap {
    id: number;
    tileUrl: string;
    opacity: number;
    title: string;
}

interface Props {
    supplier: string;
    product: string;
    orderId?: number;
    geometryWKT?: string;
    orderNumber?: string;
    base64Kml?: string;
    files?: OrderFileDTO[];
    isSuperviewBoxSelectActive?: boolean;
    selectSuperviewAOI?: (bbox: LatLngBounds) => void;
    downloadButtons?: boolean;
    height?: number;

    selectedArchives?: string[];
    onSceneSelectionChange?: (scenes: string[]) => void;
}

const OrderMap = (props: Props) => {
    const satelliteAOIControlRef = useRef<any>();

    const { supplier, product, geometryWKT, selectedArchives, orderNumber, base64Kml, files, downloadButtons, height } =
        props;

    const [archives, setArchives] = useState<ArchiveDTO[]>([]);
    const [searching, setSearching] = useState(false);
    const [editScenes, setEditScenes] = useState(false);
    const [selectedArchivesTemp, setSelectedArchiveTemp] = useState<string[]>();

    const [locationLayer, setLocationLayer] = useState<any>();
    const [orderMaps, setOrderMaps] = useState<OrderFileMap[]>([]);

    const mapRef = useRef(null);

    useEffect(() => {
        if (files?.length > 0) {
            const mapped = files
                .filter((f) => f.tileUrl?.length > 0)
                .map(
                    (f) =>
                        ({
                            id: f.id,
                            tileUrl: f.tileUrl,
                            opacity: 1,
                            title: ViewHelper.formatFilename(f.fileName),
                        } as OrderFileMap)
                );
            setOrderMaps(mapped);
        } else {
            setOrderMaps([]);
        }
    }, [files]);

    useEffect(() => {
        const refreshArchives = async () => {
            setSearching(true);
            try {
                const list = await ApiOrders.searchArchive(supplier, product, geometryWKT);
                setArchives(list);
                if (list.length == 0) {
                    toast.warn('No results for selected AOI and product combination');
                }
            } catch (err) {
                toast.error('Error during fetching archives scenes');
                console.error(err);
            } finally {
                setSearching(false);
            }
        };
        if (supplier && geometryWKT && ViewHelper.isArchive(product)) {
            refreshArchives();
        }
    }, [supplier, product, geometryWKT, selectedArchives]);

    useEffect(
        () => () => {
            if (satelliteAOIControlRef.current) {
                satelliteAOIControlRef.current.removeAll();
            }
        },
        []
    );

    useEffect(() => {
        if (!props.isSuperviewBoxSelectActive && satelliteAOIControlRef.current) {
            satelliteAOIControlRef.current.removeAll();
        }
    }, [props.isSuperviewBoxSelectActive]);

    useEffect(() => {
        if (props.isSuperviewBoxSelectActive && satelliteAOIControlRef.current) {
            const inf = 99999999;
            satelliteAOIControlRef.current.start({
                maxArea: inf,
                minArea: 25,
                minWidth: 1,
                minHeight: 1,
                maxWidth: inf,
                maxHeight: inf,
            });
        }
    }, [props.isSuperviewBoxSelectActive]);

    useEffect(() => {
        if (mapRef?.current) {
            const leafletMap = mapRef.current;
            if (locationLayer) {
                leafletMap.removeLayer(locationLayer);
            }
            if (geometryWKT && mapRef && mapRef.current && !props.isSuperviewBoxSelectActive) {
                const geoJson = Wellknown.parse(geometryWKT);
                const layer = L.geoJSON(geoJson, { style: { fillColor: '#000000', fillOpacity: 0 } }).addTo(leafletMap);
                setLocationLayer(layer);
                leafletMap.fitBounds(layer.getBounds());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geometryWKT, mapRef?.current]);

    const downloadShp = () => {
        try {
            GeoUtil.downloadAoiAsShapefile(geometryWKT, orderNumber);
        } catch (err) {
            Sentry.captureException(err);
        }
    };

    const downloadKml = () => {
        const element = document.createElement('a');
        const kml = Base64.decode(base64Kml);
        const file = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });
        element.href = URL.createObjectURL(file);
        element.download = 'AOI_' + orderNumber + '.kml';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    const onSceneSelectionChange = (scenes: string[]) => {
        props.onSceneSelectionChange(scenes);
        setSelectedArchiveTemp(scenes);
    };

    const onConfirmChange = () => {
        props.onSceneSelectionChange(selectedArchivesTemp);
        setEditScenes(false);
        setSelectedArchiveTemp(undefined);
    };

    const onDiscardChange = () => {
        setEditScenes(false);
        setSelectedArchiveTemp(undefined);
    };

    const onOpacityUpdate = (id: number, opacity: number) => {
        setOrderMaps(
            orderMaps.map((m) => {
                if (m.id == id) {
                    m.opacity = opacity;
                }
                return m;
            })
        );
    };

    return (
        <>
            <MapDiv height={height || 500}>
                <LeafletMap mapRef={mapRef}>
                    <LayersControl position="topright">
                        <LeafletBaseMaps />
                        {props.isSuperviewBoxSelectActive && (
                            <AOISelect
                                isActive={props.isSuperviewBoxSelectActive}
                                onAOISelected={(aoi: LatLngBounds) => {
                                    if (props.selectSuperviewAOI) {
                                        props.selectSuperviewAOI(aoi);
                                    }
                                }}
                                onAOIReset={() => {
                                    if (props.selectSuperviewAOI) {
                                        props.selectSuperviewAOI(null);
                                    }
                                }}
                                aoiParameters={{
                                    maxArea: 99999999,
                                    minArea: 25,
                                    minWidth: 1,
                                    minHeight: 1,
                                    maxWidth: 99999999,
                                    maxHeight: 99999999,
                                }}
                            />
                        )}

                        {archives && (selectedArchivesTemp || selectedArchives) && (
                            <Overlay checked={editScenes || orderMaps.length == 0} name={`Archive scenes`}>
                                <FeatureGroup>
                                    {archives
                                        .filter((a) => (selectedArchivesTemp || selectedArchives).includes(a.id))
                                        .reverse()
                                        .map((scene) => {
                                            const points = GeoUtil.polygonFromPolygonWKT(scene.geometryWKT);
                                            const bounds = [points[2], points[3], points[1], points[0]];
                                            return (
                                                <DistortableOverlay
                                                    key={scene.id}
                                                    dataURL={scene.previewUrl}
                                                    corners={bounds}
                                                />
                                            );
                                        })}
                                </FeatureGroup>
                            </Overlay>
                        )}
                        {orderMaps.map((m) => (
                            <Overlay name={m.title} checked={!editScenes}>
                                <TileLayer tms={true} attribution="" url={m.tileUrl} opacity={m.opacity} />
                            </Overlay>
                        ))}
                    </LayersControl>
                </LeafletMap>
            </MapDiv>
            {orderMaps.map((m) => (
                <FormGroup>
                    <OpacitySlider
                        title={m.title}
                        opacity={m.opacity}
                        onChange={(opacity) => onOpacityUpdate(m.id, opacity)}
                    />
                </FormGroup>
            ))}
            {ViewHelper.isArchive(product) && (
                <ArchiveLayers>
                    <FormGroup>
                        <Label>Scenes ID</Label>
                        <SceneInput>
                            {searching && (
                                <ArchiveSearching>
                                    <Spinner text="Searching for existing data in archive" />
                                </ArchiveSearching>
                            )}
                            {!searching && archives?.length === 0 && (
                                <ArchiveError>No archive for selected AOI and product</ArchiveError>
                            )}
                            {!searching && archives?.length > 0 && (
                                <React.Fragment>
                                    <Input
                                        disabled
                                        value={selectedArchives ? selectedArchives.join(',') : 'Add Archives'}
                                    />
                                    {props.onSceneSelectionChange && (
                                        <>
                                            {!editScenes && (
                                                <IconButton
                                                    title={'Edit'}
                                                    onClick={() => setEditScenes(!editScenes)}
                                                    faIcon="fa-chevron-down"
                                                />
                                            )}
                                            {editScenes && (
                                                <IconButton
                                                    title={'Confirm'}
                                                    onClick={onConfirmChange}
                                                    faIcon="fa-check"
                                                />
                                            )}
                                            {editScenes && (
                                                <IconButton
                                                    title={'Cancel'}
                                                    onClick={onDiscardChange}
                                                    faIcon="fa-times-circle"
                                                />
                                            )}
                                        </>
                                    )}
                                </React.Fragment>
                            )}
                        </SceneInput>
                    </FormGroup>

                    {editScenes && (
                        <ArchiveTable
                            items={archives}
                            selected={selectedArchives}
                            onSceneSelectionChange={onSceneSelectionChange}
                        />
                    )}
                </ArchiveLayers>
            )}

            {downloadButtons && (
                <DownloadButtonContainer>
                    <Button color="link" onClick={downloadShp}>
                        Download AOI SHP
                    </Button>
                    {base64Kml && (
                        <Button color="link" onClick={downloadKml}>
                            Download AOI KML
                        </Button>
                    )}
                </DownloadButtonContainer>
            )}
        </>
    );
};

export default OrderMap;

interface MapDivProps {
    height?: number;
}

const MapDiv = styled.div<MapDivProps>`
    height: ${(props) => (props.height ? props.height : 450)}px;
    & #satellite-aoi-button {
        display: none;
    }
`;

const SceneInput = styled.div`
    display: flex;
`;

const ArchiveSearching = styled.div`
    width: 100%;
    text-align: center;
    height: 38px;
`;

const ArchiveError = styled.div`
    width: 100%;
    text-align: center;
    color: #dc3545;
    font-size: 20px;
`;

const ArchiveLayers = styled.div`
    margin-top: 10px;
`;

const DownloadButtonContainer = styled.div`
    text-align: center;
    margin-top: 10px;
`;
