import Api from './api';

export interface TrackerDTO {
    name: string;
    created: Date;
    code: string;
    link: string;
}

export default class ApiTracker extends Api {
    public static createTracker(trackerName: string): Promise<TrackerDTO> {
        return this.axios
            .post(`/v1/trackers`, { name: trackerName, url: 'https://soar.earth' })
            .then((res) => {
                return this.map(res.data);
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getTrackers(): Promise<TrackerDTO[]> {
        return this.axios
            .get(`/v1/trackers?includeStats=false`)
            .then((res) => {
                return res.data.list.map((t) => this.map(t));
            })
            .catch((err) => {
                throw err;
            });
    }

    private static map(t): TrackerDTO {
        const tracker: TrackerDTO = {
            created: new Date(t.createdAt * 1000),
            name: t.name,
            code: t.code,
            link: t.link,
        };

        return tracker;
    }
}
