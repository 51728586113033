import Api from './api';
import {
    DeleteListingsRes,
    DownloadLinkDTO,
    ListingDTO,
    ListingType,
    Metadata,
    ReviewItemDTO,
    ReviewsDTO,
    SponsorListingsDTO
} from './model';

export default class ApiListings extends Api {
    public static getListings(
        projectId: number,
        listingIdFrom?: number,
        review?: string,
        limit?: number,
        full?: boolean
    ): Promise<SponsorListingsDTO> {
        const params = {
            projectId,
            limit: limit || 10,
            listingId: listingIdFrom,
            review,
            full,
        };
        return this.axios.get('/v1/admin/listings', { params: params }).then((res) => {
            return res.data;
        });
    }

    public static getListing(listingId: number): Promise<ListingDTO> {
        return this.axios.get(`/v1/admin/listings/${listingId}`).then((res) => {
            return res.data;
        });
    }

    public static getDownloadLink(listingId: number): Promise<DownloadLinkDTO> {
        return this.axios.get(`/v1/admin/listings/${listingId}/download`).then((res) => {
            return res.data;
        });
    }

    public static deleteListing(listingId: number): Promise<DeleteListingsRes> {
        return this.axios.delete(`/v1/admin/listings/${listingId}`).then((res) => {
            return res.data.body;
        });
    }

    public static updateListing(
        listingId: number,
        title: string,
        description: string,
        seoTitle: string,
        tags: string[],
        categories: string[],
        license: string,
        metadata?: Metadata
    ): Promise<boolean> {
        const data = {
            title,
            description,
            seoTitle,
            tags,
            categories,
            license,
            metadata: metadata && metadata,
        };
        return this.axios
            .put(`/v1/admin/listings/${listingId}`, data)
            .then((_res) => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    public static updateDistoredGeometry(listingId: number, distoredGeometry: string): Promise<boolean> {
        const data = {
            distoredGeometry,
        };
        return this.axios
            .put(`/v1/admin/listings/${listingId}/distoredGeometry`, data)
            .then((_res) => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    public static reviewListings(reviews: ReviewItemDTO[]): Promise<ReviewsDTO> {
        const data = {
            reviews: reviews,
        };
        return this.axios.post('/v1/admin/listings/review', data).then((res) => {
            return res.data;
        });
    }

    public static reviewListing(review: ReviewItemDTO): Promise<ReviewItemDTO> {
        return this.axios.post(`/v1/admin/listings/${review.id}/review`, review).then((res) => {
            return res.data;
        });
    }

    public static listingsSearch(
        category: string,
        keywords: string,
        email: string,
        review: string,
        listingType: ListingType,
        from: number,
        to: number,
        offset: number,
        limit: number
    ): Promise<ListingDTO[]> {
        const params = {
            category: category?.length > 0 ? category : undefined,
            keywords: keywords?.length > 0 ? keywords : undefined,
            email: email?.length > 0 ? email : undefined,
            review,
            listingType,
            from: from ? Math.ceil(from) : undefined,
            to: to ? Math.ceil(to) : undefined,
            offset,
            limit,
        };
        return this.axios.get('/v1/admin/listings/search', { params: params }).then((res) => {
            return res.data.listings;
        });
    }

    public static getAdminListingsForUser(email: string): Promise<ListingDTO[]> {
        const params = {
            email: email,
        };
        return this.axios.get('/v1/admin/listings/search', { params: params }).then((res) => {
            return res.data.listings;
        });
    }

}
