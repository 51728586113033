import React from 'react';
import { ListingType } from '../../../api/model';
import { FormGroup, Input } from '../../style';

interface Props {
    onChange: (interval: ListingType) => void;
    value: ListingType;
}

const InputListingType = (props: Props) => {
    return (
        <FormGroup>
            <Input
                title="Listing Type"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value as any)}
                type="select"
            >
                <option value={''}>Not selected</option>
                <option value={ListingType.IMAGE}>Drone Images</option>
                <option value={ListingType.TILE_LAYER}>Maps</option>
            </Input>
        </FormGroup>
    );
};

export default InputListingType;
