import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import config from '../../../config';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import ViewHelper from '../../view-helper';
import { Button, ButtonWrapper, Panel, Table, TableWrapper } from '../../style';

interface SearchTableViewProps {
    listings: ListingDTO[];
}

const ManageUploadsTable = (props: SearchTableViewProps) => {
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'listingType',
            text: 'Image Type',
            sort: true,
            formatter: (cell) => ViewHelper.replaceTileLayerTextWithMap(cell),
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            formatter: (cell) => <span>{cell}</span>,
        },
        {
            dataField: 'totalComments',
            text: 'Comments',
            sort: true,
        },
        {
            dataField: 'totalLikes',
            text: 'Likes',
            sort: true,
        },
        {
            dataField: 'categories',
            text: 'Categories',
            hidden: true,
        },
        {
            dataField: 'review',
            text: 'Review',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell) => ViewHelper.formatDateWithTime(cell),
        },
        {
            dataField: 'id',
            text: 'Map Link',
            sort: true,
            formatter: (cell, row) => (
                <React.Fragment>
                    {row.review === 'APPROVED' && (
                        <a
                            href={`https://${config.SUPER_MAP_DOMAIN}/browse/${cell}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {cell}
                        </a>
                    )}
                </React.Fragment>
            ),
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <ButtonWrapper>
                <Button onClick={handleClick}>Export CSV</Button>
            </ButtonWrapper>
        );
    };

    if (!props.listings) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <ToolkitProvider
                keyField="id"
                data={props.listings}
                columns={columns}
                exportCSV={{
                    fileName: `soar_users_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
                }}
            >
                {(props) => (
                    <>
                        <SearchTableWrapper>
                            <Table {...props.baseProps} />
                        </SearchTableWrapper>
                        <Panel>
                            <MyExportCSV {...props.csvProps} />
                        </Panel>
                    </>
                )}
            </ToolkitProvider>
        </React.Fragment>
    );
};

export default ManageUploadsTable;

const SearchTableWrapper = styled(TableWrapper)`
    td:nth-child(2) {
        max-width: 200px;
        overflow-wrap: anywhere;
    }
`;
