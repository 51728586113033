import './index.css';

import * as React from 'react';

interface SpanDotsProps {
    text: string;
}

const DotsText = (props: SpanDotsProps) => {
    return <span className="dotdotdot">{props.text}</span>;
};

export default DotsText;
