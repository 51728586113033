import React, { useState } from 'react';
import config from '../../config';
import InputCountry from '../Orders/input-country';
import InputIndustry from '../Orders/input-industry';
import { CustomerDTO } from '../../api/model-orders';
import AutocompleteEmailInput from '../Shared/autocomplete-email-input';
import { FormRow } from './new-order';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Row } from '../style';
import styled from 'styled-components';
import { toast } from 'react-toastify';

interface Props {
    customer?: CustomerDTO;
    onSubmit: (customer: CustomerDTO) => void;
    onBack: () => void;
}

interface IValidState {
    valid: boolean;
    message?: string;
}
const initialValidState: IValidState = {
    valid: true,
};

interface IFormState {
    email: IValidState;
    countryCode: IValidState;
    industry: IValidState;
    company: IValidState;
    subdomain: IValidState;
}

const initialFormState: IFormState = {
    email: initialValidState,
    countryCode: initialValidState,
    industry: initialValidState,
    company: initialValidState,
    subdomain: initialValidState,
};

const NewOrderCustomer = (props: Props) => {
    const [email, setEmail] = useState<string | undefined>(props.customer?.email);
    const [countryCode, setCountryCode] = useState<string | undefined>(props.customer?.countryCode);
    const [industry, setIndustry] = useState<string | undefined>(props.customer?.industry);
    const [company, setCompany] = useState<string | undefined>(props.customer?.company);
    const [subdomain, setSubdomain] = useState<string>(props.customer?.subdomain || config.SUPER_MAP_DOMAIN);
    const [deliveryFormatNote, setDeliveryFormatNote] = useState<string | undefined>(
        props.customer?.deliveryFormatNote
    );

    const [formState, setFormState] = useState<IFormState>(initialFormState);

    const onSubmit = () => {
        let state = initialFormState;
        if (!email) {
            state = { ...state, email: { valid: false } };
        }
        if (!countryCode) {
            state = { ...state, countryCode: { valid: false } };
        }
        if (!industry || industry === 'Select your industry') {
            state = { ...state, industry: { valid: false } };
        }
        if (!company) {
            state = { ...state, company: { valid: false } };
        }
        if (!subdomain) {
            state = { ...state, subdomain: { valid: false } };
        }

        setFormState(state);
        if (state !== initialFormState) {
            toast.error('Form is incomplete!');
            return;
        }

        const customer: CustomerDTO = {
            email,
            countryCode,
            industry,
            company,
            subdomain,
            deliveryFormatNote,
        };
        props.onSubmit(customer);
    };

    return (
        <FormRow>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <AutocompleteEmailInput
                            onChange={(value) => setEmail(value)}
                            isInvalid={!formState.email.valid}
                            defaultValue={email}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="subdomain">Subdomain</Label>
                        <Input
                            type="text"
                            name="subdomain"
                            id="subdomain"
                            value={subdomain}
                            onChange={(e) => setSubdomain(e.target.value)}
                            invalid={!formState.subdomain.valid}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="company">Company</Label>
                        <Input
                            type="text"
                            name="company"
                            id="company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            invalid={!formState.company.valid}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="industry">Industry</Label>
                        <InputIndustry value={industry} onChange={setIndustry} invalid={!formState.industry.valid} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="country">Country</Label>
                        <InputCountry
                            countryCode={countryCode}
                            onChange={setCountryCode}
                            invalid={!formState.countryCode.valid}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="deliverableType">Delivery Format Additional Note</Label>
                        <Input
                            type="text"
                            name="deliveryFormatNote"
                            id="deliveryFormatNoteId"
                            value={deliveryFormatNote}
                            onChange={(e) => setDeliveryFormatNote(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <NewOrderButtonGroup>
                    <NewOrderButton onClick={props.onBack}>
                        <span>
                            <i className="fa fa-chevron-left" />
                        </span>
                        <span>Back</span>
                    </NewOrderButton>
                    <NewOrderButton onClick={onSubmit}>
                        <span>Next</span>
                        <span>
                            <i className="fa fa-chevron-right" />
                        </span>
                    </NewOrderButton>
                </NewOrderButtonGroup>
            </Row>
        </FormRow>
    );
};

export default NewOrderCustomer;

const NewOrderButtonGroup = styled(ButtonGroup)`
    width: auto;
    margin: auto;
`;

const NewOrderButton = styled(Button)`
    margin: 35px auto;
    padding: 5px 25px;

    & span:not(:first-child) {
        margin-left: 7px;
        color: rgba(0, 0, 0, 0.87);
    }
`;
