import React, { useCallback, useEffect, useState } from 'react';
import ApiSTAC, { STACItemFullDTO } from '../../../../api/api-stac';
import { Button, ButtonPanel, Card, Col, Container, ErrorMessage, Label, Row, Spinner, Title } from '../../../style';
import { push } from 'connected-react-router';
import store from '../../../../store/store';
import MapServicesDetailsTable from '../../MapServicesSharedUI/map-services-details-table';
import MapServicesLayerPreviewMap from '../../MapServicesSharedUI/Layer/map-services-layer-preview-map';
import StacCatalogEditDetails from './stac-catalog-edit-details';
import StacItemNavigation from './stac-item-navigation';

const StacItem = (props) => {
    const catalogId = props.match.params.catalogId;
    const itemId = props.match.params.itemId;
    const [stacItem, setStacItem] = useState<STACItemFullDTO | undefined>();
    const [stacItemError, setStacItemError] = useState<Error | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getSTACCItem = useCallback(
        async (itemId: number) => {
            setIsLoading(true);
            try {
                const stacItem = await ApiSTAC.getSTACCatalogItem(catalogId, itemId);
                setStacItem(stacItem);
            } catch (err) {
                setStacItemError(err);
            } finally {
                setIsLoading(false);
            }
        },
        [catalogId]
    );

    useEffect(() => {
        getSTACCItem(itemId);
    }, [catalogId, getSTACCItem, itemId]);

    // useEffect(() => {
    //     const handleParentCatalog = async () => {
    //         if (stacItem?.catalogId) {
    //             const parentCatalog = await ApiSTAC.getSTACCatalogItems(stacItem?.catalogId);
    //             setStacSiblings(parentCatalog as any);
    //         }
    //     };

    //     if (stacItem) {
    //         handleParentCatalog();
    //     }
    // }, [stacItem]);

    if ((!stacItem && !stacItemError) || isLoading) {
        return (
            <Container>
                <Spinner />
            </Container>
        );
    }

    if (stacItemError) {
        return (
            <Container>
                <Title>Manage STAC Item</Title>
                <Row>
                    <Col>
                        <Card>
                            <ErrorMessage>{stacItemError?.message || 'Something has gone wrong!'}</ErrorMessage>
                            <br />
                            <Label>Please contact the admin developers</Label>
                            <ButtonPanel>
                                <Button onClick={() => store.dispatch(push(`/stac`))}>BACK TO SAFETY</Button>
                            </ButtonPanel>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container>
            <Title>Manage STAC Item</Title>
            <React.Fragment>
                <Row>
                    <Col md={12}>
                        <Card>
                            <StacItemNavigation
                                item={stacItem}
                                onStacItemVisibilityUpdated={(id: number) => getSTACCItem(id)}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <StacCatalogEditDetails
                                item={stacItem}
                                catalogId={catalogId}
                                onSaveStac={() => {
                                    setStacItem(undefined);
                                    getSTACCItem(itemId);
                                }}
                            />
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Card>
                            {stacItem?.map ? (
                                <MapServicesLayerPreviewMap layer={stacItem} isStac={true} fullWidth />
                            ) : (
                                <ErrorMessage>No map available</ErrorMessage>
                            )}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <MapServicesDetailsTable title={'Full server details'} data={stacItem} />
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        </Container>
    );
};

export default StacItem;
