import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { DomainReportDTO } from '../../api/model';
import ConfirmModal from '../Shared/confirm-modal';
import { Button, Divider, Panel, Subtitle, Table, TableWrapper } from '../style';
import ViewHelper from '../view-helper';

interface Props {
    items: DomainReportDTO[];
    onDeleteClick: (domainId: number) => void;
}

const SubdomainsTable = (props: Props) => {
    const items = props.items.sort((d1, d2) => d2.createdAt - d1.createdAt);
    const columns = [
        {
            dataField: 'name',
            text: 'Subdomain',
            sort: true,
        },
        {
            dataField: 'owner',
            text: 'Email',
            hidden: true,
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell) => ViewHelper.formatDate(cell),
            csvFormatter: (cell) => ViewHelper.formatDate(cell),
        },
        {
            dataField: 'lastActivity',
            text: 'Last activity',
            sort: true,
            formatter: (cell) => ViewHelper.formatDate(cell),
            csvFormatter: (cell) => ViewHelper.formatDate(cell),
        },
        {
            dataField: 'tileConsumption',
            text: 'Tile consumption',
            sort: true,
        },
        {
            dataField: 'maps',
            text: 'Maps',
            sort: true,
        },
        {
            dataField: 'mapsSize',
            text: 'Storage',
            sort: true,
            formatter: (cell) => ViewHelper.formatBytes(cell),
            csvFormatter: (cell) => ViewHelper.formatBytes(cell),
        },
        {
            dataField: 'mapsArea',
            text: 'Area',
            sort: true,
            formatter: (cell) => ViewHelper.formatArea(cell),
            csvFormatter: (cell) => ViewHelper.formatArea(cell),
        },
        {
            dataField: 'mapsSizeRejected',
            text: 'Deleted',
            hidden: true,
            csvFormatter: (cell) => ViewHelper.formatBytes(cell),
        },
        {
            dataField: 'domainId',
            text: 'Action',
            formatter: (cell, row: DomainReportDTO) => (
                <ConfirmModal
                    title={'Delete'}
                    message={
                        <React.Fragment>
                            <p>{`Are you sure to delete domain ${row.name}`}</p>
                            <p>{`Owner: ${row.owner} `}</p>
                            <p>
                                {row.lastActivity
                                    ? ` Last activity: ${ViewHelper.formatDateWithTimeShort(row.lastActivity)}`
                                    : ''}
                            </p>
                        </React.Fragment>
                    }
                    color={'danger'}
                    size={'sm'}
                    onConfirm={() => props.onDeleteClick(row.domainId)}
                />
            ),
        },
    ];

    // This is my custom csv export component
    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return <Button onClick={handleClick}>EXPORT CSV</Button>;
    };

    const filename = 'subdomains_report_' + ViewHelper.formatDateWithTime(new Date().getTime() / 1000) + '.csv';

    return (
        <React.Fragment>
            <Subtitle>Active Soar+ Accounts</Subtitle>
            <Divider />
            <ToolkitProvider
                keyField="name"
                data={items}
                columns={columns}
                exportCSV={{
                    fileName: filename,
                }}
            >
                {(props) => (
                    <>
                        <TableWrapper>
                            <Table {...props.baseProps} pagination={paginationFactory()} />
                        </TableWrapper>
                        <Panel>
                            <MyExportCSV {...props.csvProps} />
                        </Panel>
                    </>
                )}
            </ToolkitProvider>
        </React.Fragment>
    );
};

export default SubdomainsTable;
