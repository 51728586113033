import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    children: ReactNode;
}

const ButtonsPanel = (props: Props) => {
    return <Panel>{props.children}</Panel>;
};

export default ButtonsPanel;

const Panel = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    & button {
        margin: 5px;
    }
    & div {
        margin: 5px;
    }
    & a {
        margin: 5px;
    }
    @media (max-width: 769px) {
        flex-direction: column;
    }
`;
