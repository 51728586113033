import * as React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoaderProps {
    text?: string;
}

const Loader = ({ text }: LoaderProps) => {
    const color = '#eed926';
    return (
        <Container>
            {text ? <LoaderText>{text}</LoaderText> : null}
            <SVGContainer viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                <Hex1
                    d="M26.5 0.866025C27.4282 0.330127 28.5718 0.330127 29.5 0.866025L39.4904 6.63397C40.4186 7.16987 40.9904 8.16025 40.9904 9.23205V20.7679C40.9904 21.8397 40.4186 22.8301 39.4904 23.366L29.5 29.134C28.5718 29.6699 27.4282 29.6699 26.5 29.134L16.5096 23.366C15.5814 22.8301 15.0096 21.8397 15.0096 20.7679V9.23205C15.0096 8.16025 15.5814 7.16987 16.5096 6.63397L26.5 0.866025Z"
                    fill={color}
                    data-index="1"
                />

                <Hex2
                    d="M56.5 0.866025C57.4282 0.330127 58.5718 0.330127 59.5 0.866025L69.4904 6.63397C70.4186 7.16987 70.9904 8.16025 70.9904 9.23205V20.7679C70.9904 21.8397 70.4186 22.8301 69.4904 23.366L59.5 29.134C58.5718 29.6699 57.4282 29.6699 56.5 29.134L46.5096 23.366C45.5814 22.8301 45.0096 21.8397 45.0096 20.7679V9.23205C45.0096 8.16025 45.5814 7.16987 46.5096 6.63397L56.5 0.866025Z"
                    fill={color}
                    data-index="2"
                />

                <Hex6
                    d="M11.5 26.866C12.4282 26.3301 13.5718 26.3301 14.5 26.866L24.4904 32.634C25.4186 33.1699 25.9904 34.1603 25.9904 35.2321V46.7679C25.9904 47.8397 25.4186 48.8301 24.4904 49.366L14.5 55.134C13.5718 55.6699 12.4282 55.6699 11.5 55.134L1.50962 49.366C0.581416 48.8301 0.00961876 47.8397 0.00961876 46.7679V35.2321C0.00961876 34.1603 0.581416 33.1699 1.50962 32.634L11.5 26.866Z"
                    fill={color}
                    data-index="6"
                />

                <Hex7
                    d="M41.5 26.866C42.4282 26.3301 43.5718 26.3301 44.5 26.866L54.4904 32.634C55.4186 33.1699 55.9904 34.1603 55.9904 35.2321V46.7679C55.9904 47.8397 55.4186 48.8301 54.4904 49.366L44.5 55.134C43.5718 55.6699 42.4282 55.6699 41.5 55.134L31.5096 49.366C30.5814 48.8301 30.0096 47.8397 30.0096 46.7679V35.2321C30.0096 34.1603 30.5814 33.1699 31.5096 32.634L41.5 26.866Z"
                    fill={color}
                    data-index="7"
                />

                <Hex3
                    d="M71.5 26.866C72.4282 26.3301 73.5718 26.3301 74.5 26.866L84.4904 32.634C85.4186 33.1699 85.9904 34.1603 85.9904 35.2321V46.7679C85.9904 47.8397 85.4186 48.8301 84.4904 49.366L74.5 55.134C73.5718 55.6699 72.4282 55.6699 71.5 55.134L61.5096 49.366C60.5814 48.8301 60.0096 47.8397 60.0096 46.7679V35.2321C60.0096 34.1603 60.5814 33.1699 61.5096 32.634L71.5 26.866Z"
                    fill={color}
                    data-index="3"
                />

                <Hex5
                    d="M26.5 52.866C27.4282 52.3301 28.5718 52.3301 29.5 52.866L39.4904 58.634C40.4186 59.1699 40.9904 60.1603 40.9904 61.2321V72.7679C40.9904 73.8397 40.4186 74.8301 39.4904 75.366L29.5 81.134C28.5718 81.6699 27.4282 81.6699 26.5 81.134L16.5096 75.366C15.5814 74.8301 15.0096 73.8397 15.0096 72.7679V61.2321C15.0096 60.1603 15.5814 59.1699 16.5096 58.634L26.5 52.866Z"
                    fill={color}
                    data-index="5"
                />

                <Hex4
                    d="M56.5 52.866C57.4282 52.3301 58.5718 52.3301 59.5 52.866L69.4904 58.634C70.4186 59.1699 70.9904 60.1603 70.9904 61.2321V72.7679C70.9904 73.8397 70.4186 74.8301 69.4904 75.366L59.5 81.134C58.5718 81.6699 57.4282 81.6699 56.5 81.134L46.5096 75.366C45.5814 74.8301 45.0096 73.8397 45.0096 72.7679V61.2321C45.0096 60.1603 45.5814 59.1699 46.5096 58.634L56.5 52.866Z"
                    fill={color}
                    data-index="4"
                />
            </SVGContainer>
        </Container>
    );
};

export default Loader;

const Container = styled.div`
    display: grid;
    place-items: center;
    margin-top: 3%;
`;

const LoaderText = styled.h3`
    color: rgba(255, 255, 255, 0.67);
    margin-bottom: 20px;
`;

const SVGContainer = styled.svg`
    width: 10%;
`;

const growIn = keyframes`
    0%,
    100% {
      opacity: 0;
      transform: scale(0.1, 0.1);
    }
    20%,
    70% {
      opacity: 1;
      transform: scale(1, 1);
    }
`;

const Hex1 = styled.path`
    position: relative;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 300ms;
    transform-origin: 50% 50%;
    animation-name: ${growIn};
    -webkit-animation-delay: 300ms;
`;

const Hex2 = styled.path`
    position: relative;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 400ms;
    transform-origin: 50% 50%;
    animation-name: ${growIn};
    -webkit-animation-delay: 400ms;
`;
const Hex3 = styled.path`
    position: relative;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 500ms;
    transform-origin: 50% 50%;
    animation-name: ${growIn};
    -webkit-animation-delay: 500ms;
`;
const Hex4 = styled.path`
    position: relative;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 600ms;
    transform-origin: 50% 50%;
    animation-name: ${growIn};
    -webkit-animation-delay: 600ms;
`;
const Hex5 = styled.path`
    position: relative;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 700ms;
    transform-origin: 50% 50%;
    animation-name: ${growIn};
    -webkit-animation-delay: 700ms;
`;
const Hex6 = styled.path`
    position: relative;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 800ms;
    transform-origin: 50% 50%;
    animation-name: ${growIn};
    -webkit-animation-delay: 800ms;
`;
const Hex7 = styled.path`
    position: relative;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 900ms;
    transform-origin: 50% 50%;
    animation-name: ${growIn};
    -webkit-animation-delay: 900ms;
`;
