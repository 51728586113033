import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiTms, { TMSServer } from '../../../../api/api-tms';
import store from '../../../../store/store';
import { Button, ButtonGroup, Card, Col, Container, ErrorMessage, Row, Spinner, Title } from '../../../style';
import TMSServerEditDetails from './tms-server-edit-details';
import TMSServerAvatar from './tms-server-avatar';
import TMSServerLayers from './tms-server-layers';
import TMSDeleteAction from './tms-server-delete-action';
import MapServicesDetailsTable from '../../MapServicesSharedUI/map-services-details-table';

const TMSServerPage = (props) => {
    const id: number = props.match.params.id;
    const [tms, setTms] = useState<TMSServer | undefined>();
    const [tmsError, setTmsError] = useState<Error | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getTMSServer = async (id: number) => {
        setIsLoading(true);
        try {
            const tmsServer = await ApiTms.getTMSServer(id);
            setTms(tmsServer);
        } catch (err) {
            setTmsError(err);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getTMSServer(id);
    }, [id]);

    if ((!tms && !tmsError) || isLoading) {
        return (
            <Container>
                <Spinner />
            </Container>
        );
    }

    if (tmsError) {
        return (
            <Container>
                <Title>Manage TMS Server</Title>
                <Row>
                    <Col>
                        <Card>
                            <ErrorMessage>{tmsError?.message || 'Something has gone wrong!'}</ErrorMessage>
                            <br />
                            <ButtonPanel>
                                <Button onClick={() => store.dispatch(push(`/tms`))}>BACK TO SAFETY</Button>
                                <TMSDeleteAction tms={props.tms} invalidateServer={() => store.dispatch(push('/tms'))}>
                                    <Button color="danger">DELETE SERVER AND ALL LAYERS</Button>
                                </TMSDeleteAction>
                            </ButtonPanel>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container>
            <Title>Manage TMS Server</Title>
            <React.Fragment>
                <Row>
                    <Col md={8}>
                        <Card>
                            <TMSServerEditDetails
                                tms={tms}
                                onSaveTMS={() => {
                                    setTms(undefined);
                                    getTMSServer(id);
                                }}
                            />
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card>
                            <TMSServerAvatar
                                tms={tms}
                                onSaveAvatar={() => {
                                    setTms(undefined);
                                    getTMSServer(id);
                                }}
                            />
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Card>
                        <TMSServerLayers
                            tms={tms}
                            onSaveLayer={() => {
                                setTms(undefined);
                                getTMSServer(id);
                            }}
                        />
                    </Card>
                </Row>
                <Row>
                    <Card>
                        <MapServicesDetailsTable title={'Full server details'} data={tms} />
                    </Card>
                </Row>
            </React.Fragment>
        </Container>
    );
};

export default TMSServerPage;

const ButtonPanel = styled(ButtonGroup)`
    max-width: 500px;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0pc auto;
`;
