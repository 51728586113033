import React, { useState, useEffect } from 'react';
import ApiSupplier from '../../api/api-supplier';
import { SupplierDTO, ProductDTO } from '../../api/model-orders';
import NewSupplierOrderSelectSupplier from './new-order-select-supplier';
import NewSupplierOrderSelectProduct from './new-order-select-product';

import { FormRow } from './new-order';
import ViewHelper from '../view-helper';
import Loader from '../Shared/Loader/Loader';
import FormattedDetailsTable, { Line } from '../Shared/formatted-details-table';
import { Button } from '../style';
import styled from 'styled-components';

interface NewOrderSupplierProps {
    handleSelectSupplierAndProduct: (supplier: SupplierDTO, product: ProductDTO) => void;
    selectedSupplier?: SupplierDTO;
    selectedProduct?: ProductDTO;
}

const NewOrderSupplier = (props: NewOrderSupplierProps) => {
    const [suppliers, setSuppliers] = useState<SupplierDTO[]>(undefined);
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierDTO | undefined>(props.selectedSupplier);
    const [selectedProduct, setSelectedProduct] = useState<ProductDTO | undefined>(props.selectedProduct);

    useEffect(() => {
        ApiSupplier.getSuppliers().then((res) => {
            setSuppliers(res);
        });
    }, []);

    return (
        <React.Fragment>
            <FormRow>
                {!suppliers ? (
                    <Loader text="Loading Suppliers" />
                ) : (
                    <NewSupplierOrderSelectSupplier
                        suppliers={suppliers}
                        selectedSupplier={selectedSupplier}
                        handleSelectSupplier={(res) => {
                            setSelectedSupplier(res);
                            setSelectedProduct(undefined);
                        }}
                    />
                )}
            </FormRow>

            {selectedSupplier && (
                <FormRow>
                    <NewSupplierOrderSelectProduct
                        products={selectedSupplier.products}
                        handleSelectProduct={(res) => setSelectedProduct(res)}
                        selectedProduct={selectedProduct}
                    />
                </FormRow>
            )}

            {selectedSupplier && selectedProduct && (
                <React.Fragment>
                    <FormattedDetailsTable label="Product Details">
                        <Line label="Description" value={selectedProduct.description} />
                        <Line label="Format" value={`${selectedProduct.format}`} />
                        <Line label="Option" value={selectedProduct.option} />
                        <Line label="Resolution (cm)" value={`${selectedProduct.resolutionInCm}cm`} />
                        <Line
                            label="Price per km²"
                            value={ViewHelper.formatPriceFromCents(selectedProduct.pricePerKm)}
                        />
                        <Line label="Max area (km)" value={ViewHelper.formatAreaInKm(selectedProduct.maxAreaInKm)} />
                        <Line label="Min area (km)" value={ViewHelper.formatAreaInKm(selectedProduct.minAreaInKm)} />
                        <Line label="Restricted countries" value={selectedProduct.restrictedCountries.join(', ')} />
                    </FormattedDetailsTable>

                    <NewOrderButton
                        onClick={() => {
                            props.handleSelectSupplierAndProduct(selectedSupplier, selectedProduct);
                        }}
                    >
                        <span>Next</span>
                        <span>
                            <i className="fa fa-chevron-right" />
                        </span>
                    </NewOrderButton>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default NewOrderSupplier;

const NewOrderButton = styled(Button)`
    margin: 35px auto;
    padding: 5px 25px;

    & span:not(:first-child) {
        margin-left: 7px;
        color: rgba(0, 0, 0, 0.87);
    }
`;
