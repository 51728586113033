import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectContactUs, selectContactUsLoading } from '../../store/AppStore/selectors';
import { actionUpdateContactUs } from '../../store/AppStore/actions';
import styled from 'styled-components';
import ApiAdmin from '../../api/api-admin';
import ContactUsTable from './contact-us-table';
import { Card, Container, FormGroup, Input, Label, Spinner, Title } from '../style';

const ContactUs = () => {
    const [includeHandled, setIncludeHandled] = useState(false);

    const dispatch = useDispatch();
    const items = useSelector(selectContactUs);
    const isLoading = useSelector(selectContactUsLoading);

    useEffect(() => {
        dispatch(actionUpdateContactUs(includeHandled));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [includeHandled]);

    const itemHandled = (id: string) => {
        ApiAdmin.handleContactUs(id).then((_res) => {
            dispatch(actionUpdateContactUs(includeHandled));
        });
    };

    if (isLoading && !items)
        return (
            <Container>
                <Title>Manage Enquiries</Title>
                <Card height="530px">
                    <Spinner />
                </Card>
            </Container>
        );

    return (
        <Container>
            <Title>Manage Enquiries</Title>
            <Card>
                <CheckBoxFormGroup>
                    <Input
                        type="checkbox"
                        checked={includeHandled}
                        onChange={() => setIncludeHandled(!includeHandled)}
                        name="check"
                        id="exampleCheck"
                    />
                    <Label for="exampleCheck" check>
                        Included handled enquiries
                    </Label>
                </CheckBoxFormGroup>
                {!isLoading && !items ? <Card>All enquiries have been handled</Card> : null}
                {items && <ContactUsTable items={items} itemHandled={itemHandled}></ContactUsTable>}
            </Card>
        </Container>
    );
};

export default ContactUs;

const CheckBoxFormGroup = styled(FormGroup)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 200px;
`;
