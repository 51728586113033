import React, { useEffect, useState } from 'react';
import TagInputField from '../../../Shared/tag-input-field';
import { Button, Col, FormGroup, Input, Label, Row, Subtitle } from '../../../style';
import ApiSTAC, { STACCatalogFullDTO, STACItemFullDTO } from '../../../../api/api-stac';
import CategoriesInput from '../../../Shared/categories-input-field';
import ViewHelper from '../../../view-helper';
import { toast } from 'react-toastify';
import MapServicesLayerPreviewImage from '../../MapServicesSharedUI/Layer/map-services-layer-preview-image';
import { OssUploader } from '../../../../api/oss-uploader';

interface STACCatalogEditDetailsProps {
    stac?: STACCatalogFullDTO;
    item?: STACItemFullDTO;
    catalogId?: number;
    onSaveStac: () => void;
}

const StacCatalogEditDetails = (props: STACCatalogEditDetailsProps) => {
    const [stacTitle, setStacTitle] = useState<string | undefined>();
    const [stacDescription, setStacDescription] = useState<string | undefined>();
    const [stacKeywords, setStacKeywords] = useState<string[]>([]);
    const [stacCategories, setStacCategories] = useState<string[]>([]);
    const [stacAdminNotes, setStacAdminNotes] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [license, setLicense] = useState<string | undefined>();
    const [base64Preview, setBase64Preview] = useState<string | undefined>(undefined);
    const [isUploadingPreview, setIsUploadingPreview] = useState(false);

    useEffect(() => {
        if (props.stac) {
            setStacTitle(props.stac.titleOvr || props.stac.title);
            setStacDescription(props.stac.descriptionOvr || props.stac.description);
            setStacKeywords(props.stac.keywordsOvr || props.stac.keywords || []);
            setStacCategories(props.stac.categories || []);
            setStacAdminNotes(props.stac.adminNotes);
            setLicense(props.stac.licenseOvr || props.stac.license);
            setBase64Preview(undefined);
        }
        if (props.item) {
            setStacTitle(props.item.title);
            setStacDescription(props.item.description);
            setStacKeywords(props.item.keywords || []);
            setStacCategories(props.item.categories || []);
            setStacAdminNotes(props.item.adminNotes);
            setLicense(props.item.license);
            setBase64Preview(undefined);
        }
    }, [props.stac, props.item]);

    const handleSaveStacServer = () => {
        setIsLoading(true);
        ApiSTAC.updateSTACCatalog(props.stac.id, {
            titleOvr: stacTitle,
            descriptionOvr: stacDescription,
            keywordsOvr: stacKeywords,
            categories: stacCategories,
            adminNotes: stacAdminNotes,
            licenseOvr: license,
        })
            .then((_) => {
                props.onSaveStac();
                toast.success(
                    `${props.stac.titleOvr || props.stac.title || props.stac.id} has been updated successfully`
                );
            })
            .catch((error) => {
                alert(`Error saving STAC catalog: ${error.message}`);
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSaveStacItem = () => {
        setIsLoading(true);
        ApiSTAC.updateSTACCatalogItem(props.catalogId, props.item.id, {
            title: stacTitle,
            description: stacDescription,
            keywords: stacKeywords,
            categories: stacCategories,
            adminNotes: stacAdminNotes,
            license: license,
        })
            .then((_) => {
                props.onSaveStac();
                toast.success(`${props.item.title || props.item.id} has been updated successfully`);
            })
            .catch((error) => {
                alert(`Error saving STAC item: ${error.message}`);
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleValidateFileType = (file: File) => {
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        return validTypes.includes(file.type);
    };

    const handleSelectPreviewImage = async (file: File) => {
        if (!handleValidateFileType(file)) {
            alert('Please use a supported image type (jpg or png)');
            return;
        }

        setIsUploadingPreview(true);
        try {
            const credentials = await ApiSTAC.getSTACCatalogPreviewUploadCredentials(props.stac.id);
            const uploader = new OssUploader(credentials);
            await uploader.multipartUpload(file, file.name, () => {}); // eslint-disable-line @typescript-eslint/no-empty-function

            alert('Preview image uploaded');
            // getTMSLayer();
        } catch (err) {
            // setTmsLayerError(err);
        } finally {
            setIsUploadingPreview(false);
        }
    };

    const detailsName = props.stac ? 'Catalog' : 'Item';

    return (
        <React.Fragment>
            <Subtitle>{`${detailsName} details`}</Subtitle>
            <React.Fragment>
                <FormGroup>
                    <Label for="title">Title</Label>
                    <Input
                        disabled={isLoading}
                        type="text"
                        value={stacTitle}
                        placeholder={`Title for the ${detailsName}`}
                        onChange={(e) => setStacTitle(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                        disabled={isLoading}
                        type="textarea"
                        rows={3}
                        value={stacDescription}
                        placeholder={`Description for the ${detailsName}`}
                        onChange={(e) => setStacDescription(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="categories">Categories</Label>
                    <CategoriesInput
                        disabled={isLoading}
                        values={stacCategories}
                        onChange={(categories) => setStacCategories(categories)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="keywords">Keywords (helps with search)</Label>
                    <TagInputField
                        disabled={isLoading}
                        onTagInput={(tags) => setStacKeywords(tags)}
                        tags={stacKeywords}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="adminNotes">Admin Notes</Label>
                    <Input
                        disabled={isLoading}
                        type="textarea"
                        rows={3}
                        value={stacAdminNotes}
                        onChange={(e) => setStacAdminNotes(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="license">License (default license for public layers)</Label>
                    <Input
                        value={license}
                        onChange={(e) => {
                            setLicense(e.target.value);
                        }}
                        type="select"
                        name="license"
                    >
                        {!license && <option>Choose license</option>}
                        {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                            <option value={value}>{value}</option>
                        ))}
                    </Input>
                </FormGroup>

                {props.stac?.mosaic && (
                    <FormGroup>
                        <Label for="preview">
                            {`Preview image ${
                                base64Preview || props.stac.previewUrl ? ': Click preview to change' : ''
                            }`}
                        </Label>
                        <MapServicesLayerPreviewImage
                            isUploadingPreview={isUploadingPreview}
                            previewUrl={props.stac.previewUrl}
                            base64Preview={base64Preview}
                            onSelectPreviewImage={(file) => handleSelectPreviewImage(file)}
                        />
                    </FormGroup>
                )}

                <Row>
                    <Col>
                        <Button onClick={props.stac ? handleSaveStacServer : handleSaveStacItem}>Save Changes</Button>
                        {/* <STACDeleteAction stac={props.stac} invalidateCatalog={() => store.dispatch(push('/stac'))}>
                            <Button color="danger">Delete Catalog</Button>
                        </STACDeleteAction> */}
                    </Col>
                </Row>
            </React.Fragment>
        </React.Fragment>
    );
};

export default StacCatalogEditDetails;
