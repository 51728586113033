import config from '../config';
import Api from './api';
import { ContactUsDTO, DmtTaskDTO, FileInfoDTO, ListingDTO, UserDTO } from './model';

export interface FeaturedCategory {
    value: string;
    label: string;
    count?: number;
}

export const featuredCategories: FeaturedCategory[] = [
    { value: 'best', label: 'Hottest', count: config.FEATURED_HOTTEST_MAP_COUNT },
    { value: 'news', label: 'Current Events', count: config.FEATURED_NEWS_MAP_COUNT },
];

export default class ApiAdmin extends Api {
    public static getUserById(userId: string): Promise<UserDTO> {
        return this.axios.get(`/v1/users/${userId}`).then((res) => {
            return res.data;
        });
    }

    static updateFeatured(listingId: number, type: string[]): Promise<ListingDTO> {
        return this.axios.put(`/v1/admin/listings/${listingId}/featured`, { featured: type }).then((res) => {
            return res.data;
        });
    }

    public static getContactUs(includeHandled: boolean): Promise<ContactUsDTO[]> {
        const params = {
            handled: includeHandled,
        };
        return this.axios.get(`/v1/admin/contact-us`, { params }).then((res) => {
            return res.data.list;
        });
    }

    public static getContactUsBySubject(subject: string): Promise<ContactUsDTO[]> {
        return this.axios.get(`/v1/admin/contact-us/subject?subject=${subject}`).then((res) => {
            return res.data.list;
        });
    }

    public static handleContactUs(id: string): Promise<ContactUsDTO> {
        return this.axios.post(`/v1/admin/contact-us/${id}/handle`).then((res) => {
            return res.data;
        });
    }

    public static dmtGetTasks(): Promise<DmtTaskDTO[]> {
        return this.axios.get('/v1/admin/dmt?uploading=true').then((res) => {
            return res.data.items;
        });
    }

    public static dmtGetTask(id: number): Promise<DmtTaskDTO> {
        return this.axios.get(`/v1/admin/dmt/${id}`).then((res) => {
            return res.data;
        });
    }

    public static dmtRefund(id: number): Promise<DmtTaskDTO> {
        return this.axios.post(`/v1/admin/dmt/${id}/refund`).then((res) => {
            return res.data;
        });
    }

    public static dmtApprove(id: number): Promise<DmtTaskDTO> {
        return this.axios.post(`/v1/admin/dmt/${id}/approve`).then((res) => {
            return res.data;
        });
    }

    static deleteSubdomain(subdomainId: number) {
        return this.axios.delete(`/v1/admin/domain/${subdomainId}`).then((res) => {
            return res.data.body;
        });
    }

    public static createSubdomain(email, domain): Promise<boolean> {
        return this.axios.post('/v1/admin/domain/create', { email, domain }).then((res) => {
            return res.data;
        });
    }

    public static getJWTToken(filename: string, geometryWKT: string, minZoom: number): Promise<string> {
        const params = {
            filename: filename,
            geometry: geometryWKT,
            minZoom: minZoom,
        };
        return this.axios.get(`/v1/admin/tile/token`, { params: params }).then((res) => {
            return res.data.idToken;
        });
    }

    public static getFilesInFolder(path: string): Promise<FileInfoDTO[]> {
        const params = {
            path: path,
        };
        return this.axios.get(`/v1/admin/tile/folder`, { params: params }).then((res) => {
            return res.data.list;
        });
    }
}
