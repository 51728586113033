import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

export type STACChildren = {
    [catalog: number]: number[];
};

export interface ExternalMapServicesStoreState {
    stacChildren: STACChildren;
    stacItems: STACChildren;
}

const initialState: ExternalMapServicesStoreState = {
    stacChildren: {},
    stacItems: {},
};

export default function (
    state: ExternalMapServicesStoreState = initialState,
    action: Action
): ExternalMapServicesStoreState {
    switch (action.type) {
        case at.EMS_ADD_UPDATE_STAC_CHILDREN: {
            const copy = { ...state.stacChildren };
            copy[action.payload.catalog] = action.payload.children;
            return { ...state, stacChildren: copy };
        }
        case at.EMS_ADD_UPDATE_STAC_ITEMS: {
            const copy = { ...state.stacItems };
            copy[action.payload.catalog] = action.payload.items;
            return { ...state, stacItems: copy };
        }
        default:
            return state;
    }
}
