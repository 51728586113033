import Api from './api';
import { Voucher, VoucherDTO } from './model';

export default class ApiVouchers extends Api {
    public static getVouchers(): Promise<VoucherDTO[]> {
        return this.axios
            .get(`/v1/voucher`)
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static createVoucher(voucher: Voucher): Promise<VoucherDTO> {
        return this.axios
            .post(`/v1/voucher`, voucher)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static deleteVoucher(code: string): Promise<void> {
        return this.axios
            .delete(`/v1/voucher/${code}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }
}
