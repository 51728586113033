import React, { useState } from 'react';
import { GdalMetadata } from '../../api/api-tile-engine';
import { Button, ErrorMessage } from '../style';
import SoarModal from './soar-modal';

interface Props {
    info?: GdalMetadata;
    error?: string;
}

const GdalInfo = (props: Props) => {
    const { info, error } = props;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <React.Fragment>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {info && <Button onClick={toggle}>Show gdal info</Button>}
            <SoarModal title={'Gdal Information'} width="90vw" isOpen={modal} onClosed={() => setModal(false)}>
                <pre>{info ? JSON.stringify(info, null, 2) : ''}</pre>
                <Button onClick={() => setModal(false)}>Close</Button>
            </SoarModal>
        </React.Fragment>
    );
};

export default GdalInfo;
