import React, { useEffect, useRef, useState } from 'react';
import ApiAnalytics from '../../../../api/api-analytics';
import { AnalyticsFilter, AnalyticsItemDTO } from '../../../../api/model';
import { DatesFromTo, ProductName, Provider } from '../../../../api/model-api';
import InputDateRange from '../../../Shared/Filters/DateRange/input-date-range';
import InputProvider from '../../../Shared/Filters/input-provider';
import { Card, Col, FormGroup, Input, Label, Row, Spinner, Subtitle } from '../../../style';
import Pagination from '../../pagination';
import DataTable from './data-table';

const Data = () => {
    const [filter, setFilter] = useState<AnalyticsFilter>({ offset: 0, limit: 25, email: '' });
    const [data, setData] = useState<AnalyticsItemDTO[]>([]);
    const [total, setTotal] = useState<number>(undefined);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>('');
    const messageRef = useRef(null);

    useEffect(() => {
        const refresh = async () => {
            setLoading(true);
            try {
                const res = await ApiAnalytics.apiGetAnalytics(filter);
                setData(res.list);
                setTotal(res.total);
                if (messageRef.current) {
                    messageRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start',
                    });
                }
            } catch (err) {
                //setError(err.toString());
            } finally {
                setLoading(false);
            }
        };
        refresh();
    }, [filter]);

    const onOffsetChange = (offset: number) => {
        setFilter({ ...filter, offset: offset });
    };

    const onPageSizeChange = (value: number) => {
        setFilter({ ...filter, offset: 0, limit: value });
    };

    const onProviderChange = (provider?: Provider, satellite?: ProductName) => {
        const copy: AnalyticsFilter = { ...filter };
        copy.provider = provider;
        copy.satellite = satellite;
        setFilter(copy);
    };

    const onDatesChange = (dates: DatesFromTo) => {
        setFilter({ ...filter, dates: dates });
    };

    const onSearchClick = () => {
        setFilter({ ...filter, email: email });
    };

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange fromTo={filter.dates} onChange={onDatesChange} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <InputProvider
                                provider={filter.provider}
                                product={filter.satellite}
                                onChange={onProviderChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="provider">Search by Email</Label>
                            <Input
                                onBlur={onSearchClick}
                                title="Search by email"
                                placeholder="Search by email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                name="email"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {loading ? (
                <Card height="800px">
                    <Spinner text="Loading Analytic Data" />
                </Card>
            ) : (
                <Card>
                    <Subtitle ref={messageRef}>
                        There are <span>{total ? total.toLocaleString() : 0}</span> results for given filter
                    </Subtitle>
                    <DataTable items={data} />
                    <Pagination
                        total={total}
                        offset={filter.offset}
                        limit={filter.limit}
                        onOffsetChange={onOffsetChange}
                        onLimitChange={onPageSizeChange}
                    />
                </Card>
            )}
        </React.Fragment>
    );
};
export default Data;
