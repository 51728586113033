import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'rsuite';
import ApiUser from '../../api/api-user';

interface Props {
    defaultValue?: string;
    isInvalid?: boolean;
    onChange: (email: string) => void;
}

const AutocompleteEmailInput = (props: Props) => {
    const [email, setEmail] = useState<string | undefined>(props.defaultValue);
    const [emailList, setEmailList] = React.useState([]);

    useEffect(() => {
        const getEmailList = async () => {
            try {
                const res = await ApiUser.searchUsers(email, undefined, 0, 10);
                const list = res.map((d) => d.email);
                setEmailList(list);
            } catch (err) {
                alert(err);
            }
        };

        if (email && email.length > 0) {
            getEmailList();
        } else {
            setEmailList([]);
        }
    }, [email]);

    useEffect(() => {
        props.onChange(email);
    }, [email, props]);

    return (
        <AutoComplete
            style={{ padding: '0px' }}
            id="customer-email"
            data={emailList}
            onChange={(value) => setEmail(value)}
            className={`form-control ${props.isInvalid ? 'is-invalid' : ''}`}
            defaultValue={email}
        />
    );
};

export default AutocompleteEmailInput;
