import Api from './api';
import { UploadCredentialsDTO } from './model';
import {
    CollectAction,
    DownloadLinkDTO,
    LogType,
    OrderMetadataDTO,
    OrderCalculatedPriceDTO,
    OrderStatus,
    UpdateOrderBody,
    ArchiveDTO,
    OrderForAdminDTO,
    FileType,
} from './model-orders';

export default class ApiOrders extends Api {
    public static searchArchive(supplier: string, product: string, geometryWKT: string): Promise<ArchiveDTO[]> {
        const params = {
            supplier,
            product,
            wkt: geometryWKT,
            dateFrom: '2018-05-01',
        };
        return this.axios.get('/v1/archive/search', { params }).then((res) => {
            const list: ArchiveDTO[] = res.data.list;
            return list.sort((one, two) => (one.date > two.date ? -1 : 1));
        });
    }

    public static getCalculatedPrice(
        supplier: string,
        product: string,
        geometry: string,
        countryCode: string,
        archives: string[],
        pricePerKm: number,
        additionalTax: number,
        discount: number
    ): Promise<OrderCalculatedPriceDTO> {
        const params = {
            supplier,
            product,
            geometry,
            countryCode,
            archives: archives?.join(','),
            pricePerKm,
            additionalTax,
            discount,
        };

        return this.axios.get('/v1/admin/orders/calculate_price', { params: params }).then((res) => {
            return res.data;
        });
    }

    static createOrder(
        supplier: string,
        product: string,
        formatNote: string,
        geometry: string,
        email: string,
        subdomain: string,
        company: string,
        industry: string,
        countryCode: string,
        archives?: string[],
        discount?: number,
        pricePerKm?: number,
        additionalTax?: number,
        requestedDate?: string
    ): Promise<OrderForAdminDTO[]> {
        const data = {
            supplier,
            product,
            formatNote,
            geometry,
            email,
            subdomain,
            company,
            industry,
            countryCode,
            archives,
            requestedDate,
            discount: discount > 0 ? discount : undefined,
            pricePerKm,
            additionalTax: additionalTax > 0 ? additionalTax : undefined,
        };
        return this.axios.post('/v1/admin/orders', data).then((res) => {
            return res.data.list;
        });
    }

    public static getOrders(): Promise<OrderForAdminDTO[]> {
        return this.axios.get('/v1/admin/orders').then((res) => {
            return res.data.list;
        });
    }

    public static updateOrder(data: UpdateOrderBody) {
        return this.axios.put(`/v1/admin/orders/${data.id}`, data).then((res) => {
            return res.data;
        });
    }

    public static updateOrderStatus(id: number, status: OrderStatus, note?: string): Promise<OrderForAdminDTO> {
        return this.axios.put(`/v1/admin/orders/${id}/status`, { status, note }).then((res) => {
            return res.data;
        });
    }

    public static confirmOrder(id: number, createInvoice: boolean): Promise<OrderForAdminDTO> {
        return this.axios
            .put(`/v1/admin/orders/${id}/status`, { status: OrderStatus.REVIEW_SOAR, createInvoice })
            .then((res) => {
                return res.data;
            });
    }

    static refundOrder(id: number): Promise<OrderForAdminDTO> {
        return this.axios.put(`/v1/admin/orders/${id}/refund`).then((res) => {
            return res.data;
        });
    }

    public static getOrderUploadCredentials(id: number): Promise<UploadCredentialsDTO> {
        return this.axios.get(`/v1/admin/orders/${id}/upload`).then((res) => {
            return res.data;
        });
    }

    public static createNote(
        orderId: number,
        content: string,
        fileName: string,
        type: LogType
    ): Promise<UploadCredentialsDTO> {
        return this.axios.post(`/v1/admin/orders/${orderId}/note`, { content, fileName, type }).then((res) => {
            return res.data.uploadDetails;
        });
    }

    public static getOrderDownloadLinkFile(id: number, fileId: number): Promise<DownloadLinkDTO> {
        return this.axios.get(`/v1/admin/orders/${id}/files/${fileId}/download`).then((res) => {
            return res.data;
        });
    }

    public static getOrderDownloadLinkLog(id: number, logId: number): Promise<DownloadLinkDTO> {
        return this.axios.get(`/v1/admin/orders/${id}/logs/${logId}/download`).then((res) => {
            return res.data;
        });
    }

    public static deleteOrderFile(id: number, fileId: number): Promise<OrderForAdminDTO> {
        return this.axios.delete(`/v1/admin/orders/${id}/files/${fileId}`).then((res) => {
            return res.data;
        });
    }

    public static hideOrderFile(id: number, fileId: number): Promise<OrderForAdminDTO> {
        return this.axios.put(`/v1/admin/orders/${id}/files/${fileId}`, { type: FileType.HIDDEN }).then((res) => {
            return res.data;
        });
    }

    public static updateOrderFile(id: number, fileId: number, title: string): Promise<OrderForAdminDTO> {
        return this.axios.put(`/v1/admin/orders/${id}/files/${fileId}`, { title: title }).then((res) => {
            return res.data;
        });
    }

    public static getOrderById(id: number): Promise<OrderForAdminDTO> {
        return this.axios.get(`/v1/admin/orders/${id}`).then((res) => {
            return res.data;
        });
    }

    public static updateMetadata(id: number, metadata: OrderMetadataDTO): Promise<OrderForAdminDTO> {
        return this.axios
            .post(`/v1/admin/orders/${id}/metadata`, metadata)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static collectInfoLog(
        id: number,
        actionType: CollectAction,
        content: string,
        fileName: string,
        date: string
    ): Promise<UploadCredentialsDTO> {
        const data = {
            actionType,
            content,
            fileName,
            date,
        };
        return this.axios
            .post(`/v1/admin/orders/${id}/info`, data)
            .then((res) => {
                return res.data.uploadDetails;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static convertFileToListing(id: number, fileId: number): Promise<OrderForAdminDTO> {
        return this.axios.post(`/v1/admin/orders/${id}/files/${fileId}/listing`, {}).then((res) => {
            return res.data;
        });
    }
}
