import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import store from '../../../../store/store';
import { Button, ButtonPanel, Card, Col, Container, ErrorMessage, Row, Spinner, Title } from '../../../style';
import MapServicesDetailsTable from '../../MapServicesSharedUI/map-services-details-table';
import StacCatalogChildren from './stac-catalog-children';
import ApiSTAC, { STACCatalogDTO, STACCatalogFullDTO, STACItemDTO } from '../../../../api/api-stac';
import STACDeleteAction from './stac-catalog-delete-action';
import StacCatalogPreview from './stac-catalog-preview';
import StacCatalogEditDetails from './stac-catalog-edit-details';
import { useDispatch } from 'react-redux';
import MapServicesLayerPreviewMap from '../../MapServicesSharedUI/Layer/map-services-layer-preview-map';
import StacCatalogItems from './stac-catalog-items';
import { OssUploader } from '../../../../api/oss-uploader';
import StacCatalogNavigation from './stac-catalog-navigation';

const StacCatalog = (props) => {
    const id: number = props.match.params.id;
    const [stacCatalog, setStacCatalog] = useState<STACCatalogFullDTO | undefined>();
    const [stacError, setSTACError] = useState<Error | undefined>(undefined);
    const [isUploadingPreview, setIsUploadingPreview] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const getSTACCatalog = async (id: number) => {
        setIsLoading(true);
        try {
            const stacCatalog = await ApiSTAC.getSTACCatalog(id);
            setStacCatalog(stacCatalog);
        } catch (err) {
            setSTACError(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGeneratePreview = async (base64Preview: string) => {
        setIsUploadingPreview(true);
        try {
            // Convert file from base54 encoded string to a File object
            const filePromise = await fetch(base64Preview);
            const fileBlob = await filePromise.blob();
            const filename = `${stacCatalog.id}-${Date.now().toString()}.png`;
            const file = new File([fileBlob], filename, { type: 'image/png' });

            // Upload file to OSS bucket
            const uploadCredentials = await ApiSTAC.getSTACCatalogPreviewUploadCredentials(stacCatalog.id);
            const uploader = new OssUploader(uploadCredentials);
            await uploader.multipartUpload(file, file.name, (_) => {}); // eslint-disable-line @typescript-eslint/no-empty-function

            alert('Preview image uploaded');
            setTimeout(() => {
                setStacCatalog(undefined);
                getSTACCatalog(id);
            }, 2000);
        } catch (err) {
            setSTACError(err);
        } finally {
            setIsUploadingPreview(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getSTACCatalog(id);
    }, [id]);

    if ((!stacCatalog && !stacError) || isLoading) {
        return (
            <Container>
                <Spinner />
            </Container>
        );
    }

    if (stacError) {
        return (
            <Container>
                <Title>Manage STAC Catalog</Title>
                <Row>
                    <Col>
                        <Card>
                            <ErrorMessage>{stacError?.message || 'Something has gone wrong!'}</ErrorMessage>
                            <br />
                            <ButtonPanel>
                                <Button onClick={() => store.dispatch(push(`/stac`))}>BACK TO SAFETY</Button>
                                <STACDeleteAction
                                    stac={props.stac}
                                    invalidateCatalog={() => store.dispatch(push('/stac'))}
                                >
                                    <Button color="danger">DELETE CATALOG AND ALL CHILDREN</Button>
                                </STACDeleteAction>
                            </ButtonPanel>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container>
            <Title>Manage STAC Catalog</Title>
            <React.Fragment>
                <Row>
                    <Col md={12}>
                        <Card>
                            <StacCatalogNavigation
                                catalog={stacCatalog}
                                // catalogs={stacSiblings}
                                onStacCatalogItemVisibilityUpdated={(id: number) => getSTACCatalog(id)}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={stacCatalog.mosaic ? 5 : 8}>
                        <Card>
                            <StacCatalogEditDetails
                                stac={stacCatalog}
                                onSaveStac={() => {
                                    setStacCatalog(undefined);
                                    getSTACCatalog(id);
                                }}
                            />
                        </Card>
                    </Col>

                    <Col md={stacCatalog.mosaic ? 7 : 4}>
                        <Card>
                            {stacCatalog.mosaic && (
                                <MapServicesLayerPreviewMap
                                    layer={stacCatalog}
                                    onGeneratePreview={(base64: string) => {
                                        handleGeneratePreview(base64);
                                    }}
                                    onUsePreviewBoundingBox={() => ''}
                                    isStac={true}
                                    fullWidth
                                />
                            )}
                            {(stacCatalog.userId?.length > 0 || stacCatalog.rootId == 0) && (
                                <React.Fragment>
                                    {isUploadingPreview ? (
                                        <Spinner />
                                    ) : (
                                        <StacCatalogPreview
                                            stac={stacCatalog}
                                            onSaveAvatar={() => {
                                                setStacCatalog(undefined);
                                                getSTACCatalog(id);
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </Card>
                    </Col>
                </Row>

                {!stacCatalog.mosaic && (
                    <Row>
                        <Col md={12}>
                            <Card>
                                {stacCatalog.totalItems > 0 ? (
                                    <StacCatalogItems stac={stacCatalog} />
                                ) : (
                                    <StacCatalogChildren stac={stacCatalog} />
                                )}
                            </Card>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={12}>
                        <Card>
                            <MapServicesDetailsTable title={'Full server details'} data={stacCatalog} />
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        </Container>
    );
};

export default StacCatalog;
