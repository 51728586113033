import { formatDistance } from 'date-fns';
import React from 'react';
import { ReportedCommentDTO } from '../../../api/model';
import config from '../../../config';
import FormattedDetailsTable, { Line } from '../../Shared/formatted-details-table';
import ViewHelper from '../../view-helper';

interface Props {
    report: ReportedCommentDTO;
}

const ReportedDetails = (props: Props) => {
    const { report } = props;
    const comment = report.comment;
    return (
        <React.Fragment>
            <FormattedDetailsTable label="Report details">
                <Line
                    label="Reported at"
                    value={`${ViewHelper.formatDateWithTime(report.createdAt)} (${formatDistance(
                        new Date(report.createdAt * 1000),
                        new Date(),
                        { addSuffix: true }
                    )})`}
                />
                <Line label="Reported by" value={report.userName} />
                <Line label="Email" value={report.userEmail} />
                <Line label="Reason" value={report.reason} />
                <Line label="Message" value={report.message} />
            </FormattedDetailsTable>
            <FormattedDetailsTable label="Comment details">
                <Line
                    label="Created at"
                    value={`${ViewHelper.formatDateWithTime(comment.createdAt)} (${formatDistance(
                        new Date(comment.createdAt * 1000),
                        new Date(),
                        { addSuffix: true }
                    )})`}
                />
                <Line
                    label="Updated at"
                    value={`${ViewHelper.formatDateWithTime(comment.updatedAt)} (${formatDistance(
                        new Date(comment.updatedAt * 1000),
                        new Date(),
                        { addSuffix: true }
                    )})`}
                />
                <Line label="Created by" value={comment.userName} />
                <Line label="Email" value={comment.userEmail} />
                <Line label="Text" value={comment.text} />
                <Line
                    label="Listing tile"
                    value={
                        <a
                            href={`${config.CLIENT_URL}/maps/${comment.listingId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {comment.listingTitle}
                        </a>
                    }
                />
            </FormattedDetailsTable>
        </React.Fragment>
    );
};

export default ReportedDetails;
