import * as React from 'react';
import { useState } from 'react';
import ApiVouchers from '../../api/api-vouchers';
import ErrorPanel from '../Shared/error-panel';
import SoarModal from '../Shared/soar-modal';
import { Button, ConfirmButtonContainer, ConfirmModalFooter, ConfirmModalText } from '../style';

interface Props {
    code?: string;
    onVoucherDeleted: (code: string) => void;
    onCancel: () => void;
}

const DeleteVoucherModal = (props: Props) => {
    const { code } = props;
    const [voucherError, setVoucherError] = useState<string>(undefined);

    const togglDeleteVoucher = () => {
        props.onCancel();
    };

    const deleteVoucher = async () => {
        try {
            await ApiVouchers.deleteVoucher(code);
            props.onVoucherDeleted(code);
            togglDeleteVoucher();
        } catch (err) {
            setVoucherError(err.toString());
        }
    };

    return (
        <SoarModal title={'Delete Voucher'} isOpen={code !== undefined} onClosed={togglDeleteVoucher}>
            <ConfirmModalText>
                <div>Are you sure to delete voucher {code}</div>
                {voucherError && <ErrorPanel>{voucherError}</ErrorPanel>}
            </ConfirmModalText>
            <ConfirmModalFooter>
                <ConfirmButtonContainer>
                    <Button color="danger" onClick={deleteVoucher}>
                        Delete
                    </Button>
                    <Button color="warning" onClick={togglDeleteVoucher}>
                        Cancel
                    </Button>
                </ConfirmButtonContainer>
            </ConfirmModalFooter>
        </SoarModal>
    );
};

export default DeleteVoucherModal;
