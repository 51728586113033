import { useWindowWidth } from '@react-hook/window-size';
import React, { useEffect, useState } from 'react';
import ApiOrdersSupplier from '../../../api/api-orders-supplier';
import { DatesFromTo } from '../../../api/model-api';
import { OrderForSupplierDTO, OrderStatus, ProductOption } from '../../../api/model-orders';
import ErrorPanel from '../../Shared/error-panel';
import InputDateRange from '../../Shared/Filters/DateRange/input-date-range';
import { Card, Col, Container, FormGroup, Input, Label, Row, Spinner, Title } from '../../style';
import ViewHelper from '../../view-helper';
import SupplierOrderCard from './supplier-order-card';
import SupplierOrdersTable from './supplier-orders-table';

interface OrderSearch extends OrderForSupplierDTO {
    search: string;
    createdAt: number;
}

const allOrderStatus = [
    OrderStatus.DRAFT,
    OrderStatus.REVIEW_SOAR,
    OrderStatus.REVIEW_SUPPLIER,
    OrderStatus.REJECTED,
    OrderStatus.COLLECTION,
    OrderStatus.PROCESSING,
    OrderStatus.QA_SOAR,
    OrderStatus.QA_FAILED,
    OrderStatus.DELIVERED,
    OrderStatus.DELETED,
];

const OrderStatusThatRequireAttention = [
    OrderStatus.REVIEW_SUPPLIER,
    OrderStatus.COLLECTION,
    OrderStatus.PROCESSING,
    OrderStatus.QA_FAILED,
];

const SupplierOrders = () => {
    const windowWidth = useWindowWidth();

    const [rawOrders, setRawOrders] = useState<OrderSearch[]>([]);
    const [filteredOrders, setFilteredOrders] = useState<OrderForSupplierDTO[]>([]);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [dates, setDates] = useState<DatesFromTo>(undefined);
    const [search, setSearch] = useState<string>('');
    const [productOption, setProductOption] = useState<ProductOption>(undefined);
    const [ordersLoading, setOrdersLoading] = useState<boolean>(false);
    const [statusFilters, setStatusFilters] = useState<OrderStatus[]>(OrderStatusThatRequireAttention);
    const [statusFilterValue, setStatusFilterValue] = useState('attention');

    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        return () => {
            window.removeEventListener('focus', onFocus);
        };
    });

    const onFocus = () => {
        fetchOrders();
    };

    const fetchOrders = () => {
        setOrdersLoading(true);
        ApiOrdersSupplier.getOrders()
            .then((res) => {
                setError(undefined);
                const orders: OrderSearch[] = res.map((t) => {
                    const s: OrderSearch = t as OrderSearch;
                    s.search = `id:${t.id} order:${t.orderNumber.toLocaleLowerCase()}}`;
                    return s;
                });
                setRawOrders(orders);
            })
            .catch((err) => {
                setError(err);
                setRawOrders([]);
            })
            .finally(() => {
                setOrdersLoading(false);
            });
    };

    useEffect(() => {
        let filtered: OrderForSupplierDTO[] = [];
        if (rawOrders) {
            const from = dates?.from ? dates.from.getTime() / 1000 : undefined;
            const to = dates?.to ? dates.to.getTime() / 1000 : undefined;

            filtered = rawOrders
                .filter((t) => (from ? t.createdAt >= from : true))
                .filter((t) => (to ? t.createdAt < to : true))
                .filter((t) => statusFilters.includes(t.status))
                .filter((t) => (productOption ? t.productOption === productOption : true))
                .filter((t) => (search?.length > 0 ? t.search.includes(search.toLocaleLowerCase()) : true));
        }
        setFilteredOrders(filtered);
    }, [rawOrders, statusFilters, productOption, search, dates]);

    if (!rawOrders && !error) return <Spinner text="Loading Satellite Orders" />;

    const mobile = windowWidth < 768;

    if (error) {
        return (
            <Container>
                <Card>
                    <ErrorPanel>{error.message}</ErrorPanel>
                </Card>
            </Container>
        );
    }

    return (
        <Container>
            <Title>Satellite Orders {ordersLoading ? <i className="fa fa-spinner fa-spin" /> : null}</Title>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange fromTo={dates} onChange={setDates} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="text-search">Filter by Text</Label>
                            <Input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                type="text"
                                name="search"
                                placeholder="Enter search"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="order-type">Filter by Type</Label>
                            <Input
                                value={productOption}
                                onChange={(e) => setProductOption(e.target.value as any)}
                                type="select"
                                name="order-type"
                            >
                                <option value={''}>Filter by order type (all)</option>
                                {Object.keys(ViewHelper.PRODUCT_OPTION_LABELS).map((key) => (
                                    <option value={key}>{ViewHelper.PRODUCT_OPTION_LABELS[key]}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="status">Filter by Status</Label>
                            <Input
                                value={statusFilterValue}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setStatusFilterValue(value);
                                    if (value === 'attention') {
                                        setStatusFilters(OrderStatusThatRequireAttention);
                                    } else if (value === 'all') {
                                        setStatusFilters(allOrderStatus);
                                    } else {
                                        setStatusFilters([value]);
                                    }
                                }}
                                type="select"
                                name="order-status"
                            >
                                <option value={'all'}>Filter by status (all)</option>
                                <option value={'attention'}>Requires attention</option>
                                {Object.keys(ViewHelper.STATUS_LABELS_SUPPLIER).map((key) => (
                                    <option value={key}>{ViewHelper.STATUS_LABELS_SUPPLIER[key]}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {!mobile && filteredOrders && (
                <Card>
                    <SupplierOrdersTable items={filteredOrders} />
                </Card>
            )}
            {mobile &&
                filteredOrders &&
                filteredOrders.map((t) => (
                    <Card>
                        <SupplierOrderCard key={t.id} order={t} />
                    </Card>
                ))}
        </Container>
    );
};

export default SupplierOrders;
