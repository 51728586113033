import React from 'react';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { OrderStatus } from '../../api/model-orders';
import ViewHelper from '../view-helper';

interface Props {
    status: OrderStatus;
}

const OrderStatusBadge: React.FC<Props> = (props: Props) => {
    const { status } = props;

    return <StatusBadge badgeColor={ViewHelper.STATUS_COLORS[status]}>{ViewHelper.STATUS_LABELS[status]}</StatusBadge>;
};

export default OrderStatusBadge;

interface BadgeProps {
    badgeColor?: string;
    textColor?: string;
}

const StatusBadge = styled<BadgeProps>(Badge)`
    align-self: center;
    color: ${(props) => (props.textColor ? props.textColor : 'white')};
    background-color: ${(props) => `${props.badgeColor}!important`};
    padding: 4px 11px 3px 11px;
    border-radius: 16px;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
`;
