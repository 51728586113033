import { Button, Col, FormGroup, Input, Label } from '../../../style';
import SoarModal from '../../../Shared/soar-modal';
import { ConfirmButtonContainer, ConfirmModalFooter, SubtitleHint } from '../../../style';
import React, { useState } from 'react';
import { WMSLayer } from '../../../../api/api-wms';

interface Props {
    disabled: boolean;
    onConfirm: (listingId: number) => Promise<WMSLayer>;
    afterConfirm: (listingId: number) => void;
}

const AttachMapModal = (props: Props) => {
    const { disabled } = props;
    const [modal, setModal] = useState(false);
    const [listingId, setListingId] = useState<number | undefined>(undefined);

    const closeModal = () => {
        setModal(false);
    };

    const onConfirm = () => {
        if (listingId === undefined) {
            console.log('Button should have been disabled here; no map ID');
            return;
        }
        props
            .onConfirm(listingId)
            .then((listing) => {
                console.debug('Listing attached to WMS layer:', listing);
                closeModal();
                props.afterConfirm(listingId);
            })
            .catch((err) => {
                console.debug(`Failed to attach listing ${listingId} to WMS layer`, err);
                alert(err.message);
            });
    };

    return (
        <>
            <Button disabled={disabled} onClick={() => setModal(true)}>
                Attach existing map
            </Button>
            <SoarModal title="Attach existing map" isOpen={modal} onClosed={closeModal} width="600px">
                <FormGroup>
                    <Label for="listingId" xs={12}>
                        Enter map ID
                    </Label>
                    <Col xs={12}>
                        <Input
                            type="number"
                            name="value"
                            id="listingId"
                            value={listingId}
                            onChange={(e) => setListingId(e.target.value)}
                        />
                        <SubtitleHint>The map should be generated by a previous WMS layer</SubtitleHint>
                    </Col>
                </FormGroup>
                <ConfirmModalFooter margin="0px 15px 0px 0px">
                    <ConfirmButtonContainer>
                        <Button onClick={onConfirm}>Confirm</Button>
                        <Button color="warning" onClick={() => setModal(false)}>
                            Cancel
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>
        </>
    );
};

export default AttachMapModal;
