import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiComments from '../../../api/api-comments';
import ApiUser from '../../../api/api-user';
import { ReportedCommentDTO, UserForAdminDTO, UserRole } from '../../../api/model';
import ErrorPanel from '../../Shared/error-panel';
import {
    Button,
    ButtonWrapper,
    Card,
    Col,
    Container,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner,
    Subtitle,
    Title,
} from '../../style';
import ModeratorActivityTable from './moderator-activity-table';
import ModeratorsTable from './moderators-table';

interface Props {
    onUserClick: (userEmail: string) => void;
}

const Moderators = (props: Props) => {
    const [data, setData] = useState<UserForAdminDTO[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>(undefined);

    const [selected, setSelected] = useState<string>(undefined);
    const [activities, setActivities] = useState<ReportedCommentDTO[]>([]);
    const [newModeratorEmail, setNewModeratorEmail] = useState('');

    const refresh = async () => {
        setLoading(true);
        setError(undefined);
        try {
            const res = await ApiUser.searchUsers('', UserRole.COMMENTS, 0, 1000000);
            setData(res);
        } catch (err) {
            setError(err.toString());
        } finally {
            setLoading(false);
        }
    };

    const refreshActivity = async () => {
        setLoading(true);
        setError(undefined);
        try {
            const res = await ApiComments.getReportedComments(undefined, selected);
            setActivities(res);
        } catch (err) {
            setError(err.toString());
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selected) {
            refreshActivity();
        } else {
            setActivities([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRevoke = (userId: string) => {
        ApiUser.removeUserRole(userId, UserRole.COMMENTS)
            .then((_) => {
                alert('The user is no longer a moderator');
                refresh();
            })
            .catch((err) => {
                alert(err);
            });
    };

    const handleAddNewModerator = () => {
        // Basic check for email address
        if (!newModeratorEmail?.includes('@' && '.')) {
            toast.error('Please enter a valid email address');
            return;
        }

        ApiUser.getUserByEmail(newModeratorEmail)
            .then((user) => {
                ApiUser.assignUserRole(user.id, UserRole.COMMENTS)
                    .then((_) => {
                        alert(`The user: ${user.name} is now a moderator.`);
                        refresh();
                    })
                    .catch((err) => {
                        alert(err);
                    });
            })
            .catch((err) => {
                alert(err);
            });
    };

    if (error) {
        <Card>
            <ErrorPanel>{error}</ErrorPanel>
        </Card>;
    }

    return (
        <Container>
            <Title>Manage Moderators</Title>
            {loading ? (
                <React.Fragment>
                    <Card height="104px">
                        <Spinner text={'Fetching Data'} />
                    </Card>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Card>
                        <Row>
                            <Col md={6}>
                                <Label for="email">Add new Moderator</Label>
                                <InputGroup>
                                    <Input
                                        name="email"
                                        type="text"
                                        placeholder="Enter email of the new moderator"
                                        value={newModeratorEmail}
                                        onChange={(e) => setNewModeratorEmail(e.target.value)}
                                    />
                                    <Button onClick={() => handleAddNewModerator()}>Add new Moderator</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Subtitle>Moderators Table</Subtitle>
                        {selected ? (
                            <React.Fragment>
                                <ButtonWrapper>
                                    <Button onClick={() => setSelected(undefined)}>Back</Button>
                                </ButtonWrapper>
                                <ModeratorActivityTable items={activities} onUserClick={props.onUserClick} />
                            </React.Fragment>
                        ) : (
                            <ModeratorsTable items={data} onRevoke={onRevoke} />
                        )}
                    </Card>
                </React.Fragment>
            )}
        </Container>
    );
};
export default Moderators;
