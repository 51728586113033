import React from 'react';
import { LatLngBounds } from 'leaflet';
import SatelliteAOIControl from './satellite-aoi-control';
import { AOIParams, satelliteProviderAOIText } from './satellite-aoi-util';

interface AOISelectProps {
    isActive: boolean;
    onAOISelected: (aoi: LatLngBounds) => void;
    onAOIReset: () => void;
    aoiParameters: AOIParams;
}

const AOISelect = (props: AOISelectProps) => {
    const isValidAOI = (aoi: LatLngBounds): boolean => {
        if (
            aoi.getNorthEast().lat !== 0 &&
            aoi.getNorthEast().lng !== 0 &&
            aoi.getSouthWest().lat !== 0 &&
            aoi.getSouthWest().lng !== 0
        ) {
            return true;
        }
        return false;
    };

    const handleAOIChange = (aoi: LatLngBounds) => {
        if (isValidAOI(aoi)) {
            props.onAOISelected(aoi);
        } else if (!aoi) {
            props.onAOIReset();
        }
    };

    const handleAOIStyle = (aoi: LatLngBounds) => {
        return satelliteProviderAOIText(props.aoiParameters, aoi);
    };

    return (
        <SatelliteAOIControl
            onAOIChange={handleAOIChange}
            aoiParamaters={props.aoiParameters}
            aoiDisplayStyle={handleAOIStyle}
            removeAOI={!props.isActive}
        />
    );
};

export default AOISelect;
