import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiWms, { WMSLayer } from '../../../../api/api-wms';
import { Button } from '../../../style';
import WMSLayerVisibilityNavigationButtons from './wms-layer-visibility-navigation-buttons';
import AttachMapModal from './wms-layer-attach-map-modal';
import config from '../../../../config';

interface WMSLayerVisibilityProps {
    wmsLayer: WMSLayer;
    onWMSLayerVisibilityUpdated: () => void;
}

const WMSLayerVisibility = (props: WMSLayerVisibilityProps) => {
    const [loading, setLoading] = useState(false);
    const [nextLayer, setNextLayer] = useState<WMSLayer | undefined>(undefined);

    useEffect(() => {
        ApiWms.getWMSLayers(props.wmsLayer.serverId).then((wmsLayers) => {
            const thisLayer = wmsLayers.findIndex((t) => t.id === props.wmsLayer.id);
            if (wmsLayers.length > thisLayer) {
                setNextLayer(wmsLayers[thisLayer + 1]);
            }
        });
    }, [props.wmsLayer]);

    const handleMakeLayerAvailable = () => {
        if (!props.wmsLayer.previewUrl) {
            alert('You must as a preview before making the wms publicly available');
            return;
        }

        setLoading(true);
        ApiWms.updateWMSLayerVisibility(props.wmsLayer.serverId, props.wmsLayer.id, true)
            .then((_) => {
                props.onWMSLayerVisibilityUpdated();
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleMakeLayerUnavailable = () => {
        setLoading(true);
        ApiWms.updateWMSLayerVisibility(props.wmsLayer.serverId, props.wmsLayer.id, false)
            .then((_) => {
                props.onWMSLayerVisibilityUpdated();
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const attachMap = (listingId: number) => {
        return ApiWms.attachExistingMap(props.wmsLayer.serverId, props.wmsLayer.id, listingId);
    };

    const handleOpenInSoar = () => {
        window.open(`${config.CLIENT_URL}/maps/${props.wmsLayer.listingId}`);
    };

    if (props.wmsLayer.public) {
        return (
            <Container>
                <AvailableText>This layer is currently available on Soar</AvailableText>
                <Button disabled={loading} onClick={() => handleMakeLayerUnavailable()}>
                    Turn it off
                </Button>
                <Button color="link" onClick={() => handleOpenInSoar()}>
                    Open in Soar
                </Button>
                <WMSLayerVisibilityNavigationButtons wmsLayer={props.wmsLayer} nextLayer={nextLayer} />
            </Container>
        );
    } else {
        return (
            <Container>
                <NotAvailableText>This layer is not available on Soar</NotAvailableText>
                <Button disabled={loading} onClick={() => handleMakeLayerAvailable()}>
                    Turn it on
                </Button>
                {props.wmsLayer.listingId === undefined && (
                    <AttachMapModal
                        disabled={loading}
                        onConfirm={attachMap}
                        afterConfirm={props.onWMSLayerVisibilityUpdated}
                    />
                )}
                <WMSLayerVisibilityNavigationButtons wmsLayer={props.wmsLayer} nextLayer={nextLayer} />
            </Container>
        );
    }
};

export default WMSLayerVisibility;

const Container = styled.div`
    padding-top: 8px;
    display: inline-block;
`;

const Text = styled.span`
    font-weight: bolder;
    margin-right: 20px;
    font-size: 1.2rem;
    vertical-align: sub;
`;

const AvailableText = styled(Text)`
    color: #58a758;
`;

const NotAvailableText = styled(Text)`
    color: #fa4646;
`;
