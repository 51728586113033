import { toast } from 'react-toastify';
import { SoarApiError } from '../api/api';

export default class NotificationUtil {
    static showSuccessMessage(message: string) {
        toast.success(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    static showErrorMessage(message: string) {
        toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    static showErrorMessages(messages: string[]) {
        messages.forEach((message) => this.showErrorMessage(message));
    }

    static showErrorNotificationApi(error: SoarApiError) {
        this.showErrorMessage(error.message);
    }
}
