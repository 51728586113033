import React from 'react';
import DotsText from '../Shared/DotsText';
import Technobabble from './technobabble';
import styled, { keyframes } from 'styled-components';

interface SynthGridProps {
    isLoading?: boolean;
    loadingText?: string;
    children: React.ReactNode;
}

const SynthGrid = (props: SynthGridProps) => {
    return (
        <React.Fragment>
            <SynthGridContainer>
                <SynthGridAnimation />
                <SynthGridOpacity />
                {props.isLoading ? (
                    <SynthGridLoading>
                        <DotsText text={props.loadingText ? props.loadingText : 'Loading Analytics'} />
                        <Technobabble />
                    </SynthGridLoading>
                ) : (
                    <SynthGridContentContainer>{props.children}</SynthGridContentContainer>
                )}
            </SynthGridContainer>
        </React.Fragment>
    );
};

export default SynthGrid;

const SynthGridContainer = styled.div`
    overflow: hidden !important;
    position: fixed;
    left: 0;
`;

const MovingGrid = keyframes`
    0%{
        transform:perspective(50vh) rotateX(70deg) translateZ(10px) translateY(-var(--grid-size));
    }
    100%{
        transform:perspective(50vh) rotateX(70deg) translateZ(10px) translateY(var(--grid-size));
    }
`;

const SynthGridAnimation = styled.div`
    background-color: #191a1a !important;
    height: 100vh !important;
    position: relative;

    ::before {
        --grid-color: #00a2ff;
        --grid-size: 60px;
        --grid-blur: 0px;
        background: #191a1a;
        overflow: hidden !important;
        content: '';
        bottom: 0%;
        left: 0;
        position: absolute;
        overflow: visible;
        margin-left: -50%;
        width: 200%;
        height: 100%;
        background-position-y: 0px;
        background-image: repeating-linear-gradient(
                90deg,
                var(--grid-color) 0%,
                transparent calc(1px + var(--grid-blur)),
                transparent var(--grid-size),
                var(--grid-color) calc(var(--grid-size) + 1px + var(--grid-blur))
            ),
            repeating-linear-gradient(
                180deg,
                var(--grid-color) 0%,
                transparent calc(1px + var(--grid-blur)),
                transparent var(--grid-size),
                var(--grid-color) calc(var(--grid-size) + 1px + var(--grid-blur))
            );
        transform: perspective(50vh) rotateX(70deg) translateZ(10px);
        animation: ${MovingGrid} 1s infinite linear;
    }
`;

const SynthGridOpacity = styled.div`
    position: relative;
    background: #191a1a;
    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 0) 14%, #191a1a 48%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 0) 14%, #191a1a 48%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 0) 14%, #191a1a 48%);
    height: 85vh;
    width: 100vw;
    z-index: 100;
    top: -100vh;
    overflow: hidden;
`;

const SynthGridLoading = styled.div`
    display: flex;
    flex-direction: column;

    .dotdotdot {
        position: relative;
        z-index: 4000;
        top: -160vh;
        margin: 0 auto;
        display: block;
        color: #00a2ff;
        font-size: 5rem;
        text-shadow: 1px 1px 10px #00a2ff, 1px 1px 10px #00a2ff;
    }
`;

const SynthGridContentContainer = styled.div`
    position: fixed;
    top: 76px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: calc(100vh - 72px);
    max-width: 100vw;
`;
