import React, { useEffect, useState } from 'react';
import countryList from 'react-select-country-list';
import { Input } from 'reactstrap';

interface Props {
    countryCode?: string;
    onChange: (countryCode: string, countryName: string) => void;
    invalid?: boolean;
}

interface Country {
    code: string;
    name: string;
}

const InputCountry: React.FC<Props> = (props) => {
    const { countryCode, onChange, invalid } = props;
    const [countries, setCountries] = useState<Country[]>([]);

    useEffect(() => {
        const rawCountries = countryList().getData();
        const countries = rawCountries
            .filter((k) => k.label !== 'Antarctica')
            .map((c) => {
                const country: Country = {
                    code: c.value,
                    name: c.label,
                };
                return country;
            });
        setCountries(countries);
    }, []);

    const handleChange = (code: string) => {
        const name = countries.find((c) => c.code === code)?.name;
        onChange(code, name);
    };

    return (
        <Input value={countryCode || ''} type="select" onChange={(e) => handleChange(e.target.value)} invalid={invalid}>
            <option key="" value="">
                Select your country
            </option>
            {countries.map((country) => (
                <option key={country.code} value={country.code}>
                    {country.name}
                </option>
            ))}
        </Input>
    );
};

export default InputCountry;
