import { addDays, formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ApiReports from '../../../api/api-reports';
import { DrawProjectReportDTO } from '../../../api/model';
import { DatesFromTo } from '../../../api/model-api';
import InputDateRange, { ALL_TIME_DAYS } from '../../Shared/Filters/DateRange/input-date-range';
import { Card, Col, Container, ErrorMessage, FormGroup, Input, Label, Row, Spinner, Title } from '../../style';
import DrawProjectsTable from './draw-projects-table';

type ProjectAccessability = 'all' | 'public' | 'private';

const projectAccessabilityFilter = (project: DrawProjectReportDTO, accessability: ProjectAccessability) => {
    if (accessability === 'public') return project.public;
    if (accessability === 'private') return !project.public;
    return true;
};

const DrawProjects = () => {
    const [error, setError] = useState<string>(undefined);
    const [projects, setProjects] = useState<DrawProjectReportDTO[]>(undefined);
    const [filteredProjects, setFilteredProjects] = useState<DrawProjectReportDTO[]>([]);
    const [dates, setDates] = useState<DatesFromTo>(ALL_TIME_DAYS);
    const [email, setEmail] = useState<string>('');
    const [projectAccessability, setProjectAccessability] = useState<ProjectAccessability>('all');
    const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);

    useEffect(() => {
        const refresh = async () => {
            try {
                const projects = await ApiReports.getDrawProjectsReports();
                setProjects(projects);
            } catch (e) {
                setError(e.message);
            }
        };
        refresh();
    }, []);

    useEffect(() => {
        if (!projects) {
            return;
        }
        const dateFrom = formatISO(dates.from, { representation: 'date' });
        const dateTo = formatISO(addDays(dates.to, 1), { representation: 'date' });
        const filtered = projects.filter((project) => {
            return (
                project.createdAt >= dateFrom &&
                project.createdAt <= dateTo &&
                project.email.includes(email) &&
                projectAccessabilityFilter(project, projectAccessability) &&
                (includeDeleted ? true : !project.deletedAt)
            );
        });
        setFilteredProjects(filtered);
    }, [dates, projects, email, projectAccessability, includeDeleted]);

    const onProjectAccessabilityChange = (e) => {
        setProjectAccessability(e.target.value);
    };

    const onIncludeDeletedChange = (e) => {
        setIncludeDeleted(e.target.value === 'true');
    };

    const loading = !projects && !error;

    if (error) {
        return (
            <Card height="552px">
                <ErrorMessage>{error}</ErrorMessage>
            </Card>
        );
    }

    return (
        <Container>
            <Title>Draw Projects Report</Title>
            <Card>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange fromTo={dates} onChange={setDates} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="provider">Search by Email</Label>
                            <Input
                                title="Search by email"
                                placeholder="Search by email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                name="email"
                            />
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="frequency">Filter by accessability</Label>
                            <Input
                                disabled={loading}
                                value={projectAccessability}
                                onChange={onProjectAccessabilityChange}
                                type="select"
                                name="cumulative"
                            >
                                <option value={'all'}>All</option>
                                <option value={'public'}>Public</option>
                                <option value={'private'}>Private</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="frequency">Include deleted</Label>
                            <Input
                                disabled={loading}
                                value={includeDeleted}
                                onChange={onIncludeDeletedChange}
                                type="select"
                                name="cumulative"
                            >
                                <option value={'true'}>Yes</option>
                                <option value={'false'}>No</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {loading ? <Spinner /> : <DrawProjectsTable projects={filteredProjects} />}
        </Container>
    );
};

export default DrawProjects;
