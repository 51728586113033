import React, { useEffect, useState } from 'react';
import ApiTms, { TMSServer } from '../../../../api/api-tms';
import TagInputField from '../../../Shared/tag-input-field';
import { Button, Col, FormGroup, Input, Label, Row, Subtitle } from '../../../style';
import store from '../../../../store/store';
import { push } from 'connected-react-router';
import TMSDeleteAction from './tms-server-delete-action';

interface TMSServerEditDetailsProps {
    tms: TMSServer;
    onSaveTMS: () => void;
}

const TMSServerEditDetails = (props: TMSServerEditDetailsProps) => {
    const [tmsName, setTmsTitle] = useState<string | undefined>();
    const [tmsDescription, setTmsDescription] = useState<string | undefined>();
    const [tmsKeywords, setTmsKeywords] = useState<string[]>([]);
    const [tmsAdminNotes, setTmsAdminNotes] = useState<string | undefined>();

    useEffect(() => {
        setTmsTitle(props.tms.name);
        setTmsDescription(props.tms.description);
        setTmsKeywords(props.tms.keywords);
        setTmsAdminNotes(props.tms.adminNotes);
    }, [props.tms]);

    const handleSaveTMSServer = () => {
        ApiTms.updateTMSServerDetails(props.tms.id, tmsName, tmsDescription, tmsKeywords, tmsAdminNotes).then((_) => {
            props.onSaveTMS();
        });
    };

    return (
        <React.Fragment>
            <Subtitle>Server details</Subtitle>
            <React.Fragment>
                <FormGroup>
                    <Label for="title">Profile Name</Label>
                    <Input
                        type="text"
                        value={tmsName}
                        placeholder="Title for the TMS Server"
                        onChange={(e) => setTmsTitle(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                        type="textarea"
                        rows={3}
                        value={tmsDescription}
                        placeholder="Description for the TMS Server"
                        onChange={(e) => setTmsDescription(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="keywords">Keywords (helps with search)</Label>
                    <TagInputField onTagInput={(tags) => setTmsKeywords(tags)} tags={tmsKeywords} />
                </FormGroup>

                <FormGroup>
                    <Label for="adminNotes">Admin Notes</Label>
                    <Input
                        type="textarea"
                        rows={3}
                        value={tmsAdminNotes}
                        onChange={(e) => setTmsAdminNotes(e.target.value)}
                    />
                </FormGroup>

                <Row>
                    <Col>
                        <Button onClick={handleSaveTMSServer}>Save Changes</Button>
                        <TMSDeleteAction tms={props.tms} invalidateServer={() => store.dispatch(push('/tms'))}>
                            <Button color="danger">Delete Server</Button>
                        </TMSDeleteAction>
                    </Col>
                </Row>
            </React.Fragment>
        </React.Fragment>
    );
};

export default TMSServerEditDetails;
