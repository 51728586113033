import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

interface CopyBoxProps {
	children: ReactNode;
	onCopyMessage?: string;
}

const CopyBox = (props: CopyBoxProps) => {
	const handleCopy = (e) => {
		e.preventDefault();
		e.stopPropagation();

		try {
			navigator.clipboard.writeText(props.children.toString());
			toast.dark(props.onCopyMessage ?? 'Copied to clipboard');
		} catch (e) {
			console.error('Copy Error: ', { e });
		}
	};

	return (
		<CopyBoxContainer>
			<CopyBoxInput
				type="text"
				readOnly={true}
				value={props.children}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			/>
			<CopyBoxButton>
				<CopyBoxButtonIcon src={`/assets/icon-copy.png`} alt={`Copy`} onClick={(e) => handleCopy(e)} />
			</CopyBoxButton>
		</CopyBoxContainer>
	);
};

export default CopyBox;

const CopyBoxContainer = styled.div`
	border: 1px solid #fff;
	border-radius: 4px;
	overflow: hidden;
	display: flex;
`;
const CopyBoxInput = styled.input`
	background: transparent;
	padding: 4px 8px;
	border: none;
	font-size: 80%;
	flex: 1;

	&:focus {
		outline: none;
	}
`;
const CopyBoxButton = styled.button`
	padding: 3px;
	text-align: center;
	background: transparent;
`;
const CopyBoxButtonIcon = styled.img`
	width: 18px;
	height: 18px;
	filter: invert(1);
`;
