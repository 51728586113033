import React, { useState } from 'react';
import ImageryDetails from './imagery-details';
import TileEngineShitFolder from './tile-engine-shit-folder';
import styled from 'styled-components';
import { Button, Card, Container, Title } from '../style';
import { UserRole } from '../../api/model';
import { selectUser } from '../../store/AppStore/selectors';
import { useSelector } from 'react-redux';

export enum DashboardType {
    ShitFolder,
    CustomFile,
}

const TileEngineAdmin = () => {
    const [dashboard, setDashboard] = useState<DashboardType>(DashboardType.CustomFile);
    const user = useSelector(selectUser);
    const accessTileAdmin = user?.roles.includes(UserRole.TILE_ADMIN);

    if (!user || !accessTileAdmin) {
        return <React.Fragment />;
    }

    return (
        <Container>
            <Title>Tile Engine Admin</Title>
            <Card>
                <ButtonContainer>
                    <Button
                        active={dashboard === DashboardType.ShitFolder}
                        onClick={() => setDashboard(DashboardType.CustomFile)}
                    >
                        Custom filepath
                    </Button>
                    <Button
                        active={dashboard === DashboardType.ShitFolder}
                        onClick={() => setDashboard(DashboardType.ShitFolder)}
                    >
                        Shit folder
                    </Button>
                </ButtonContainer>
            </Card>
            <React.Fragment>
                {dashboard === DashboardType.CustomFile ? <ImageryDetails /> : null}
                {dashboard === DashboardType.ShitFolder ? <TileEngineShitFolder /> : null}
            </React.Fragment>
        </Container>
    );
};
export default TileEngineAdmin;

const ButtonContainer = styled.div`
    text-align: center;
    margin: 8px;
`;
