import React, { useState } from 'react';
import styled from 'styled-components';
import ApiOrders from '../../api/api-orders';
import ApiOrdersSupplier from '../../api/api-orders-supplier';
import { OrderDTO, OrderStatus } from '../../api/model-orders';
import ButtonsPanel from '../Shared/buttons-panel';
import FormattedDetailsTable from '../Shared/formatted-details-table';
import SoarModal from '../Shared/soar-modal';
import UploadSingleFile from '../Shared/upload-single-file';
import { Button, Panel, Spinner } from '../style';
import ViewHelper from '../view-helper';
import OrderFileRow from './order-file-row';
import { UploadCredentialsDTO } from '../../api/model';

const UPLOAD_ALLOWED = [OrderStatus.COLLECTION, OrderStatus.QA_SOAR, OrderStatus.QA_FAILED, OrderStatus.DELIVERED];
const FILE_SIZE_1_TB = 1099511627776;

interface Props {
    order: OrderDTO;
    isAdmin?: boolean;
    invalidate: () => void;
}

const OrderFiles = (props: Props) => {
    const { order, isAdmin } = props;
    const { id, files } = order;
    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadCredentials, setUploadCredentials] = useState<UploadCredentialsDTO>(undefined);

    const onUploadFinish = async () => {
        setIsProcessing(true);
        let updated: OrderDTO;
        if (isAdmin) {
            updated = await ApiOrders.getOrderById(id);
        } else {
            updated = await ApiOrdersSupplier.getOrderById(id);
        }
        while (updated.files.length === order.files.length) {
            await ViewHelper.sleep(1000);
            if (isAdmin) {
                updated = await ApiOrders.getOrderById(id);
            } else {
                updated = await ApiOrdersSupplier.getOrderById(id);
            }
        }
        setUploadCredentials(undefined);
        setIsProcessing(false);
        props.invalidate();
    };

    const onUploadClick = async () => {
        setIsProcessing(true);
        let cred: UploadCredentialsDTO;
        if (isAdmin) {
            cred = await ApiOrders.getOrderUploadCredentials(order.id);
        } else {
            cred = await ApiOrdersSupplier.getUploadCredentials(order.id);
        }
        setUploadCredentials(cred);
        setIsProcessing(false);
    };

    return (
        <React.Fragment>
            <FormattedDetailsTable label={'Files'}>
                {order &&
                    files
                        ?.sort((a, b) => b.created && b.created.localeCompare(a.created))
                        .map((tf) => (
                            <OrderFileRow
                                key={id}
                                orderFile={tf}
                                orderId={id}
                                invalidateOrder={props.invalidate}
                                isAdmin={isAdmin}
                                isSoarOrder={order?.domain?.split('.').length > 2 ? true : false}
                            />
                        ))}
            </FormattedDetailsTable>
            {UPLOAD_ALLOWED.includes(order.status) && (
                <ButtonsPanel>
                    <Button color="success" disabled={isProcessing} onClick={onUploadClick}>
                        Upload file
                    </Button>
                </ButtonsPanel>
            )}
            <SoarModal
                title="Upload file"
                isOpen={uploadCredentials !== undefined}
                onClosed={() => setUploadCredentials(undefined)}
                width="600px"
            >
                <UploadSingleFile
                    uploadCredentials={uploadCredentials}
                    onUploadFinish={onUploadFinish}
                    maxFileLimit={FILE_SIZE_1_TB}
                />
                {isProcessing && (
                    <DivSpinner>
                        <Spinner title="Processing uploaded file" />
                    </DivSpinner>
                )}
                <CollectionInfoPanel>
                    <Button disabled={isProcessing} color="danger" onClick={() => setUploadCredentials(undefined)}>
                        Cancel
                    </Button>
                </CollectionInfoPanel>
            </SoarModal>
        </React.Fragment>
    );
};

export default OrderFiles;

const DivSpinner = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: center;
`;

const CollectionInfoPanel = styled(Panel)`
    margin: 30px 0px 0px 0px;
`;
