import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import ApiAdmin from '../../api/api-admin';
import { DmtTaskDTO } from '../../api/model';
import DotsText from '../Shared/DotsText';
import ErrorPanel from '../Shared/error-panel';
import DmtTask from './dmt-task';
import './dmt-task-single-page.css';

interface Props {
    match: any;
}

const DmtTaskSinglePage: React.FC<Props> = (props: Props) => {
    const [task, setTask] = useState<DmtTaskDTO>(null);
    const [error, setError] = useState<string>(undefined);
    const id: number = props.match.params.id;

    useEffect(() => {
        fetchTask();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchTask = async () => {
        try {
            const task = await ApiAdmin.dmtGetTask(id);
            setTask(task);
        } catch (err) {
            setError(err.toString());
        }
    };

    if (error)
        return (
            <Container>
                <ErrorPanel>{error}</ErrorPanel>
            </Container>
        );
    if (!task)
        return (
            <Container>
                <DotsText text={'Fetching task from server'} />
            </Container>
        );

    return (
        <Container className="task-single-page">
            <h5>{task.orderNumber}</h5>
            <DmtTask task={task} refreshTasks={() => fetchTask()} />
        </Container>
    );
};

export default DmtTaskSinglePage;
