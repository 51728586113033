import React from 'react';
import { AnalyticsItemDTO, UserDTO } from '../../../api/model';
import { Col, Label, Row, Spinner } from '../../style';
import styled from 'styled-components';
import ViewHelper from '../../view-helper';
import UserManagementCard from './user-management-card';
import Config from '../../../config';
import countryList from 'react-select-country-list';

interface Props {
    user: UserDTO;
    lastRecordedInteraction?: AnalyticsItemDTO;
}

const ManageDetails = (props: Props) => {
    const { user, lastRecordedInteraction } = props;
    if (!user) {
        return <Spinner />;
    }

    const handleViewProfile = () => {
        window.open(`${Config.CLIENT_URL}/profile/${user.userId}`, '_blank');
    };

    return (
        <React.Fragment>
            <UserManagementCard>
                <Row>
                    {user.avatarUrl && <Avatar src={user.avatarUrl} alt={user.name} />}
                    <Col>
                        <UserEmail as={'a'} onClick={handleViewProfile}>
                            Email: {user.email}
                        </UserEmail>
                        <UserInformation>Created at: {ViewHelper.formatDateWithTime(user.createdAt)}</UserInformation>
                        {lastRecordedInteraction ? (
                            <React.Fragment>
                                <UserInformation>
                                    Last interaction:{' '}
                                    {lastRecordedInteraction.createdAt
                                        ? ViewHelper.formatDateWithTime(lastRecordedInteraction.createdAt)
                                        : 'No interactions recorded yet'}
                                </UserInformation>
                                <UserInformation>
                                    Last action:{' '}
                                    {lastRecordedInteraction.action
                                        ? lastRecordedInteraction.action
                                        : 'No action recorded yet'}
                                </UserInformation>
                                {lastRecordedInteraction.countryCode ? (
                                    <UserInformation>
                                        Origin: {countryList().getLabel(lastRecordedInteraction.countryCode)} (
                                        {countryList().native().getLabel(lastRecordedInteraction.countryCode)})
                                    </UserInformation>
                                ) : null}
                            </React.Fragment>
                        ) : null}
                    </Col>
                </Row>
            </UserManagementCard>
        </React.Fragment>
    );
};

export default ManageDetails;

const Avatar = styled.img`
    width: 130px;
    margin-right: 10px;
`;

interface UserInformationProps {
    pre?: boolean;
}

const UserEmail = styled(Label)`
    width: 100%;
    color: #eed926;
    cursor: pointer;
    text-decoration: underline;
    font-size: 20px;

    :hover {
        color: #eed926;
    }
`;

const UserInformation = styled(Label)<UserInformationProps>`
    width: 100%;
    white-space: ${(props) => (props.pre ? 'pre' : 'inherit')};
    color: white;
    word-break: break-all;

    .a {
        color: #eed926;
        text-decoration: underline;
        :hover {
            color: #eed926;
        }
    }
`;
