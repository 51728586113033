import React, { useState } from 'react';
import Chart from 'react-google-charts';
import FormattedDetailsTable, { Line } from '../../Shared/formatted-details-table';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from '../../style';
import ViewHelper from '../../view-helper';
import ManageOrdersTable from './manage-orders-table';

interface Props {
    orders: any[];
}

const ManageViewOrders = (props: Props) => {
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const groupBy = (xs, f) => {
        return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    };

    const add = (a, b) => {
        return a + b;
    };

    const sumAmountSpentInUsd = (provider) => {
        const totalAsUsd = provider.map((item) => {
            if (item.amountUsd) {
                return item.amountUsd;
            }
            return item.amount;
        });
        const accumulateTotal = totalAsUsd.reduce(add, 0);
        return accumulateTotal;
    };

    const totalOrdersMade = props.orders?.length;
    const firstOrderPlaced = props.orders?.length && props.orders[props.orders.length - 1].created; //TODO check this as createdAt on object and in API but not model and removed 5 months ago
    const lastOrderPlaced = props.orders?.length && props.orders[0].created;
    const ordersBySupplierCode = props.orders?.length && groupBy(props.orders, (order) => order.supplierCode);
    const effectiveAreaInKm = props.orders?.length && props.orders.reduce((a, b) => a + b.effectiveAreaInKm, 0);
    const chartData = [
        ['Supplier', 'Orders'],
        ['SKYMAP50', ordersBySupplierCode['SKYM50']?.length],
        ['CGSTL', ordersBySupplierCode['CGSTL']?.length],
        ['21AT', ordersBySupplierCode['21AT']?.length],
    ];

    return (
        <React.Fragment>
            <FormattedDetailsTable>
                <Line
                    label={'Orders Made'}
                    value={
                        <React.Fragment>
                            {`${totalOrdersMade.toLocaleString()}`}
                            <span>${(sumAmountSpentInUsd(props.orders) / 100).toLocaleString()}</span>
                        </React.Fragment>
                    }
                />
                {ordersBySupplierCode['SKYM50'] && (
                    <Line
                        label={'SKYM50'}
                        value={
                            <React.Fragment>
                                {`${ordersBySupplierCode['SKYM50'].length.toLocaleString()}`}
                                <span>
                                    ${(sumAmountSpentInUsd(ordersBySupplierCode['SKYM50']) / 100).toLocaleString()}
                                </span>
                            </React.Fragment>
                        }
                    />
                )}
                {ordersBySupplierCode['CGSTL'] && (
                    <Line
                        label={'CGSTL'}
                        value={
                            <React.Fragment>
                                {`${ordersBySupplierCode['CGSTL'].length.toLocaleString()}`}
                                <span>
                                    ${(sumAmountSpentInUsd(ordersBySupplierCode['CGSTL']) / 100).toLocaleString()}
                                </span>
                            </React.Fragment>
                        }
                    />
                )}
                {ordersBySupplierCode['21AT'] && (
                    <Line
                        label={'21AT'}
                        value={
                            <React.Fragment>
                                {`${ordersBySupplierCode['21AT'].length.toLocaleString()}`}
                                <span>
                                    ${(sumAmountSpentInUsd(ordersBySupplierCode['21AT']) / 100).toLocaleString()}
                                </span>
                            </React.Fragment>
                        }
                    />
                )}
            </FormattedDetailsTable>
            {ordersBySupplierCode && chartData ? (
                <Chart
                    style={{
                        width: '100%',
                    }}
                    width={'100%'}
                    height={'100%'}
                    chartType="PieChart"
                    data={chartData}
                    options={{
                        backgroundColor: 'transparent',
                        legend: {
                            position: 'labeled',
                            labeledValueText: 'both',
                            textStyle: {
                                color: 'white',
                            },
                        },
                        textStyle: {
                            color: 'white',
                        },
                        chartArea: { width: '80%' },
                    }}
                />
            ) : null}
            <FormattedDetailsTable>
                <Line label={'First Order Placed'} value={ViewHelper.formatDateTimeFromISO(firstOrderPlaced)} />
                <Line label={'Most Recent Order'} value={ViewHelper.formatDateTimeFromISO(lastOrderPlaced)} />
                <Line label={'Total Area Ordered'} value={ViewHelper.formatAreaInKm(effectiveAreaInKm)} />
            </FormattedDetailsTable>
            {/* @ts-ignore */}
            <Accordion flush open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId="1">Order History</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <ManageOrdersTable items={props.orders} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </React.Fragment>
    );
};

export default ManageViewOrders;
