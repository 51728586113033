import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { ListingDTO } from '../../api/model';
import config from '../../config';
import { Button, ButtonWrapper, Card, Panel, Row, Subtitle, Table, TableWrapper } from '../style';
import ViewHelper from '../view-helper';
import styled from 'styled-components';

interface SearchTableViewProps {
    listings: ListingDTO[];
}

const SearchTableView = (props: SearchTableViewProps) => {
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            formatter: (cell) => <span>{cell}</span>,
        },
        {
            dataField: 'userName',
            text: 'User',
            sort: true,
        },
        {
            dataField: 'userEmail',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'categories',
            text: 'Categories',
            hidden: true,
        },
        {
            dataField: 'review',
            text: 'Review',
            hidden: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell) => ViewHelper.formatDateWithTime(cell),
        },
        {
            dataField: 'id',
            text: 'Map Link',
            sort: true,
            formatter: (cell, row) => (
                <React.Fragment>
                    {row.review === 'APPROVED' && (
                        <a
                            href={`https://${config.SUPER_MAP_DOMAIN}/browse/${cell}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {cell}
                        </a>
                    )}
                </React.Fragment>
            ),
        },
        {
            dataField: 'userId',
            text: 'User Profile',
            sort: true,
            formatter: (cell) => (
                <a
                    href={`https://${config.SUPER_MAP_DOMAIN}/profile/${cell}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Link
                </a>
            ),
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <ButtonWrapper>
                <Button onClick={handleClick}>EXPORT CSV</Button>
            </ButtonWrapper>
        );
    };

    if (!props.listings) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <Row>
                <Card title="Total number of results by filter">
                    <Subtitle>{`Total Results ${props.listings.length.toLocaleString()}`}</Subtitle>
                </Card>
            </Row>
            <Card>
                <ToolkitProvider
                    keyField="id"
                    data={props.listings}
                    columns={columns}
                    exportCSV={{
                        fileName: `soar_users_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
                    }}
                >
                    {(props) => (
                        <>
                            <SearchTableWrapper>
                                <Table {...props.baseProps} />
                            </SearchTableWrapper>
                            <Panel>
                                <MyExportCSV {...props.csvProps} />
                            </Panel>
                        </>
                    )}
                </ToolkitProvider>
            </Card>
        </React.Fragment>
    );
};

export default SearchTableView;

const SearchTableWrapper = styled(TableWrapper)`
    td:first-child {
        max-width: 300px;
    }
`;
