import Api from './api';
import { UploadCredentialsDTO } from './model';

export interface TMSServer {
    id: number;
    userId: string;
    name: string;
    description: string;
    keywords: string[];
    adminNotes: string;
    createdBy: string;
    created: number;
    updated: number;
    avatarUrl: string;
    totalLayers: number;
    publicLayers: number;
}

export interface TMSLayer {
    id: number;
    serverId: number;
    url: string;
    boundingBoxWKT: string;
    tms: boolean;
    title: string;
    description: string;
    categories: string[];
    keywords: string[];
    license: string;
    previewUrl: string;
    legendUrl: string;
    createdBy: string;
    created: number;
    listingId: number;
    public: boolean;
    adminNotes?: string;
    maxZoomLevel: number;
}

export default class ApiTms extends Api {
    // Server
    public static getTMSServers(): Promise<TMSServer[]> {
        return this.axios.get('/v1/admin/tms').then((res) => res.data);
    }

    public static getTMSServer(id: number): Promise<TMSServer> {
        return this.axios.get(`/v1/admin/tms/${id}`).then((res) => res.data);
    }

    public static createTMSServer(name: string): Promise<TMSServer> {
        return this.axios.post('/v1/admin/tms', { name }).then((res) => res.data);
    }

    public static updateTMSServerDetails(
        id: number,
        name: string,
        description: string,
        keywords: string[],
        adminNotes: string
    ): Promise<boolean> {
        return this.axios.put(`/v1/admin/tms/${id}`, { name, description, keywords, adminNotes }).then((res) => {
            return res.data;
        });
    }

    public static getTMSServerAvatarUploadCredentials(serverId: number): Promise<UploadCredentialsDTO> {
        return this.axios.get(`/v1/admin/tms/${serverId}/avatar`).then((res) => {
            return res.data;
        });
    }

    public static deleteTMSServer(id: number): Promise<boolean> {
        return this.axios.delete(`/v1/admin/tms/${id}`).then((res) => {
            return res.data;
        });
    }

    // Layer
    public static getTMSLayers(id: number): Promise<TMSLayer[]> {
        return this.axios.get(`/v1/admin/tms/${id}/layers`).then((res) => res.data);
    }

    public static getTMSLayer(serverId: number, layerId: number): Promise<TMSLayer> {
        return this.axios.get(`/v1/admin/tms/${serverId}/layers/${layerId}`).then((res) => res.data as TMSLayer);
    }

    public static addTMSLayer(serverId: number, url: string): Promise<TMSLayer> {
        return this.axios.post(`/v1/admin/tms/${serverId}/layers`, { url }).then((res) => res.data);
    }

    public static updateTMSLayerDetails(
        serverId: number,
        layerId: number,
        categories: string[],
        title?: string,
        description?: string,
        keywords?: string[],
        legendUrl?: string,
        boundingBoxWKT?: string,
        adminNotes?: string,
        layerUrl?: string,
        tms?: boolean,
        license?: string,
        maxZoomLevel?: number
    ): Promise<boolean> {
        const body = {
            categories,
            title,
            description,
            keywords,
            legendUrl,
            boundingBoxWKT,
            adminNotes,
            url: layerUrl,
            tms,
            license,
            maxZoomLevel,
        };

        return this.axios.put(`/v1/admin/tms/${serverId}/layers/${layerId}`, body).then((res) => {
            return res.data;
        });
    }

    public static getTMSLayerPreviewUploadCredentials(
        serverId: number,
        layerId: number
    ): Promise<UploadCredentialsDTO> {
        return this.axios.get(`v1/admin/tms/${serverId}/layers/${layerId}/preview`).then((res) => {
            return res.data;
        });
    }

    public static addTMSLayerLegend(serverId, layerId, legendCustomUrl): Promise<boolean> {
        const params = {
            legendUrl: legendCustomUrl,
        };

        return this.axios.put(`v1/admin/tms/${serverId}/layers/${layerId}`, params).then((res) => {
            return res.data;
        });
    }

    public static getTMSLegendUploadCredentials(serverId: number, layerId: number): Promise<UploadCredentialsDTO> {
        return this.axios.get(`v1/admin/tms/${serverId}/layers/${layerId}/legend`).then((res) => {
            return res.data;
        });
    }

    public static updateTMSLayerVisibility(serverId: number, layerId: number, isVisible: boolean): Promise<boolean> {
        const body = {
            public: isVisible,
        };
        return this.axios.put(`/v1/admin/tms/${serverId}/layers/${layerId}/visibility`, body).then((res) => {
            return res.data;
        });
    }

    public static deleteTMSLayer(serverId: number, layerId: number): Promise<boolean> {
        return this.axios.delete(`/v1/admin/tms/${serverId}/layers/${layerId}`).then((res) => {
            return res.data;
        });
    }

    public static deleteTMSLayerLegend(serverId: number, layerId: number): Promise<boolean> {
        return this.axios.delete(`v1/admin/tms/${serverId}/layers/${layerId}/legend`).then((res) => {
            return res.data;
        });
    }
}
