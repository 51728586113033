import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import ApiOrdersSupplier from '../../../api/api-orders-supplier';
import { FileType, OrderForSupplierDTO, OrderStatus } from '../../../api/model-orders';
import ButtonsPanel from '../../Shared/buttons-panel';
import ErrorPanel from '../../Shared/error-panel';
import SoarModal from '../../Shared/soar-modal';
import { Button, Col, ConfirmModalFooter, FormGroup, Input, Label, Row, Spinner } from '../../style';

interface Props {
    order: OrderForSupplierDTO;
    onOrderUpdated: (order: OrderForSupplierDTO) => void;
    invalidate: () => void;
}

const UPDATE_ALLOWED = [OrderStatus.COLLECTION, OrderStatus.QA_FAILED];

const SupplierControls = (props: Props) => {
    const { order, onOrderUpdated } = props;
    const [isProcessing, setIsProcessing] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [note, setNote] = useState('');
    const [error, setError] = useState<string>(undefined);

    const approveOrder = async () => {
        setIsProcessing(true);
        try {
            const updated = await ApiOrdersSupplier.approveOrder(order.id);
            onOrderUpdated(updated);
        } catch (err) {
            Sentry.captureException(err);
            setError(err.toString());
        }
        setIsProcessing(false);
    };

    const handleRejectOrder = async () => {
        setIsProcessing(true);
        try {
            const updated = await ApiOrdersSupplier.rejectOrder(order.id, note);
            onOrderUpdated(updated);
            setShowReject(false);
        } catch (err) {
            Sentry.captureException(err);
            setError(err.toString());
        }
        setIsProcessing(false);
    };

    const handleOrderCompleted = async () => {
        if (order.files.filter((f) => f.type === FileType.MAP || f.type == FileType.OTHER).length === 0) {
            setError('File not uploaded');
            return;
        }
        if (order.files.filter((f) => f.type === FileType.METADATA).length === 0) {
            setError('Metadata not updated');
            return;
        }
        setIsProcessing(true);
        try {
            const updated = await ApiOrdersSupplier.orderCompleted(order.id);
            onOrderUpdated(updated);
        } catch (err) {
            Sentry.captureException(err);
            setError(err.toString());
        }
        setIsProcessing(false);
    };

    if (error)
        return (
            <Row>
                <ErrorPanel>{error}</ErrorPanel>
            </Row>
        );

    if (order.status === OrderStatus.REVIEW_SUPPLIER) {
        return (
            <Row>
                <Col md={12}>
                    <ButtonsPanel>
                        <Button disabled={isProcessing} onClick={approveOrder}>
                            Approve
                        </Button>
                        <Button disabled={isProcessing} color="danger" onClick={() => setShowReject(true)}>
                            Reject
                        </Button>
                    </ButtonsPanel>
                </Col>
                <SoarModal isOpen={showReject} title="Reject order" onClosed={() => setShowReject(false)} width="600px">
                    <Row>
                        <FormGroup>
                            <Label for="note">Rejection Note</Label>
                            <Input
                                value={note}
                                type="textarea"
                                name="note"
                                id="note"
                                placeholder="Leave a message to the user about why this was rejected..."
                                onChange={(e) => setNote(e.target.value)}
                                rows={6}
                            />
                        </FormGroup>
                    </Row>

                    <ConfirmModalFooter>
                        <Button disabled={isProcessing} onClick={() => setShowReject(false)}>
                            Cancel
                        </Button>
                        <Button disabled={isProcessing} color="danger" onClick={handleRejectOrder}>
                            Reject Order
                        </Button>
                    </ConfirmModalFooter>
                </SoarModal>
            </Row>
        );
    }

    const fileUploaded = order.files?.length > 0;

    return (
        <React.Fragment>
            <Row>
                <Col md={12}>
                    <ButtonsPanel>
                        {UPDATE_ALLOWED.includes(order.status) && fileUploaded && (
                            <>
                                <Button disabled={isProcessing} onClick={handleOrderCompleted}>
                                    Completed
                                </Button>
                            </>
                        )}
                    </ButtonsPanel>
                </Col>
            </Row>
            {isProcessing && <Spinner title="Processing" />}
        </React.Fragment>
    );
};

export default SupplierControls;
