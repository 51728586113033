import UserHelper from '../lib/user-helper';
import Api from './api';
import { DomainPermissionDTO, ProjectPermissionDTO, UserDTO, UserForAdminDTO, UserRole } from './model';

export default class ApiUser extends Api {
    public static login(email: string, password: string): Promise<string> {
        return this.axios
            .post('/v1/user/login', {
                email: email,
                password: password,
            })
            .then((res) => {
                return res.data.idToken;
            });
    }

    public static getMyUserProfile(): Promise<UserDTO> {
        const cookieToken = UserHelper.validateAndGetIdToken();
        if (!cookieToken) {
            return undefined;
        }

        return this.axios
            .get('/v1/user')
            .then((res) => {
                const profile: UserDTO = res.data;

                let domains: DomainPermissionDTO[] = [];
                if (res.data.domains) {
                    domains = res.data.domains.map((d) => {
                        const dp: DomainPermissionDTO = {
                            domain: d.domain,
                            permission: this.apiPermissionsToEnum(d.permission.toString()),
                        };
                        return dp;
                    });
                }
                if (res.data.domains) {
                    profile.projects = res.data.projects.map((d) => {
                        const pp: ProjectPermissionDTO = {
                            id: d.domain,
                            name: d.name,
                            permission: this.apiPermissionsToEnum(d.permission.toString()),
                            ftpUserName: d.ftpUserName,
                        };
                        return pp;
                    });
                } else {
                    profile.projects = [];
                }
                profile.domains = domains;
                return profile;
            })
            .catch((err) => {
                console.log('Fetching user object failed');
                console.log(err);
                UserHelper.resetIdToken();
                throw err;
            });
    }

    public static getUserById(userId: string): Promise<UserDTO> {
        return this.axios.get(`/v1/admin/users/${userId}`).then((res) => {
            return res.data;
        });
    }

    public static deleteUser(userId: string): Promise<UserDTO> {
        return this.axios.delete(`/v1/admin/users/${userId}`).then((res) => {
            return res.data;
        });
    }

    public static searchUsers(
        email: string,
        role: UserRole,
        offset: number,
        limit: number
    ): Promise<UserForAdminDTO[]> {
        const params = {
            email,
            role,
            offset,
            limit,
        };
        return this.axios.get('/v1/admin/users/search', { params }).then((res) => {
            return res.data.list;
        });
    }

    public static getUserByEmail(email: string): Promise<UserForAdminDTO> {
        const params = { email };
        return this.axios.get('/v1/admin/users/search', { params }).then((res) => {
            if (res.data.list.length === 0) {
                throw new Error('No user matches that email');
            } else {
                return res.data.list[0];
            }
        });
    }

    public static getUsers(offset?: number, limit?: number, role?: UserRole): Promise<UserForAdminDTO[]> {
        const params = {
            offset,
            limit,
            role,
        };
        return this.axios.get('/v1/admin/users/search', { params }).then((res) => {
            return res.data.list;
        });
    }

    public static removeUserRole(userId: string, role: UserRole): Promise<boolean> {
        return this.axios.delete(`/v1/admin/users/${userId}/role`, { params: { role } }).then((_res) => {
            return true;
        });
    }

    public static assignUserRole(userId: string, role: UserRole): Promise<boolean> {
        return this.axios.put(`/v1/admin/users/${userId}/role`, {}, { params: { role } }).then((_res) => {
            return true;
        });
    }

    public static makeReputable(userId: string): Promise<boolean> {
        return this.axios.put(`/v1/admin/users/${userId}/reputable`).then((_res) => {
            return true;
        });
    }

    public static unmakeReputable(userId: string): Promise<boolean> {
        return this.axios.delete(`/v1/admin/users/${userId}/reputable`).then((_res) => {
            return true;
        });
    }

    public static saveUserPrivileges(userId: string, heroUser: boolean, communityModerator: boolean): Promise<boolean> {
        return this.axios
            .put(`/v1/admin/users/${userId}/privilege`, {
                reputable: heroUser,
                moderator: communityModerator,
            })
            .then((_res) => true);
    }
}
