import React from 'react';
import styled from 'styled-components';
import { AnalyticsItemDTO } from '../../../../api/model';
import SingleFeatureMap from '../../../Shared/single-feature-map';

interface Props {
    item: AnalyticsItemDTO;
}

const Details = (props: Props) => {
    return (
        <Wrapper>
            <SingleFeatureMap locationWKT={props.item.geometryWKT} product={props.item.product} fillOpacity={0.2} />
        </Wrapper>
    );
};

export default Details;

const Wrapper = styled.div`
    padding: 10px;
    display: flex;
    .single-feature-map {
        height: 400px;
        width: 100%;
    }
`;
