import React, { useEffect, useState } from 'react';
import { ManagedProfileBody, ManagedUserDTO } from '../../../../api/api-managed-profiles';
import { MarkdownTextArea } from '../../../Shared/markdown-textarea';
import { Col, FormGroup, Input, Label, Row } from '../../../style';
import ProfileAvatarEdit from './profile-avatar-edit';

interface ProfileEditDetailsProps {
    user: ManagedUserDTO;
    onChange: (updatedUser: ManagedProfileBody) => void;
}

const ProfileEditDetails = ({ user, onChange }: ProfileEditDetailsProps) => {
    const [name, setName] = useState<string>(user.businessName);
    const [tagline, setTagline] = useState<string | undefined>(user.bio);
    const [description, setDescription] = useState<string | undefined>(user.longDescription);
    const [facebook, setFacebook] = useState<string | undefined>(user.facebookUrl);
    const [twitter, setTwitter] = useState<string | undefined>(user.twitterUrl);
    const [instagram, setInstagram] = useState<string | undefined>(user.instagramUrl);
    const [linkedin, setLinkedin] = useState<string | undefined>(user.linkedinUrl);
    const [website, setWebsite] = useState<string | undefined>(user.websiteUrl);
    const [userAvatar, setUserAvatar] = useState<string>();

    useEffect(() => {
        const body: ManagedProfileBody = {
            businessName: name,
            tagline: tagline,
            longDescription: description,
            facebookUrl: facebook,
            twitterUrl: twitter,
            instagramUrl: instagram,
            linkedinUrl: linkedin,
            websiteUrl: website,
            avatarBase64: userAvatar,
        };
        onChange(body);
    }, [onChange, name, tagline, description, facebook, twitter, instagram, linkedin, website, userAvatar]);

    return (
        <React.Fragment>
            <Row>
                <Col xs={9}>
                    <Row>
                        <Col xs={4}>
                            <FormGroup>
                                <Label for="title">Profile Name</Label>
                                <Input
                                    type="text"
                                    value={name}
                                    placeholder="Enter profile name"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={8}>
                            <FormGroup>
                                <Label for="title">Tagline</Label>
                                <Input
                                    type="text"
                                    value={tagline}
                                    placeholder="Enter profile tagline"
                                    onChange={(e) => {
                                        setTagline(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <MarkdownTextArea
                                    value={description}
                                    setInputValue={(value) => {
                                        setDescription(value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>

                <Col xs={3}>
                    <ProfileAvatarEdit
                        avatarUrl={user.avatarUrl ?? '/assets/default-avatar.svg'}
                        updatedAvatar={userAvatar}
                        handleUploadProfilePicture={(avatarBase64: string) => {
                            setUserAvatar(avatarBase64);
                        }}
                    />
                </Col>
                <Col xs={4}>
                    <FormGroup>
                        <Label for="title">LinkedIn</Label>
                        <Input
                            type="text"
                            value={linkedin}
                            placeholder="Enter LinkedIn link"
                            onChange={(e) => {
                                setLinkedin(e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup>
                        <Label for="title">Instagram</Label>
                        <Input
                            type="text"
                            value={instagram}
                            placeholder="Enter Instagram link"
                            onChange={(e) => {
                                setInstagram(e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup>
                        <Label for="title">X (formerly Twitter)</Label>
                        <Input
                            type="text"
                            value={twitter}
                            placeholder="Enter X (formerly Twitter) link"
                            onChange={(e) => {
                                setTwitter(e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup>
                        <Label for="title">Facebook</Label>
                        <Input
                            type="text"
                            value={facebook}
                            placeholder="Enter Facebook link"
                            onChange={(e) => {
                                setFacebook(e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup>
                        <Label for="title">Website</Label>
                        <Input
                            type="text"
                            value={website}
                            placeholder="Enter Website link"
                            onChange={(e) => {
                                setWebsite(e.target.value);
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ProfileEditDetails;
