import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { WMSServer } from '../../../../api/api-wms';
import store from '../../../../store/store';
import { Button, Col, FormGroup, Input, Label, Row, Subtitle } from '../../../style';
import ViewHelper from '../../../view-helper';
import WMSDeleteAction from './wms-delete-action';

interface WMSServerEditDetailsProps {
    wms: WMSServer;
    onSaveWMSDetails: (title: string, license: string, adminNotes: string) => void;
}

const WMSServerEditDetails = (props: WMSServerEditDetailsProps) => {
    const [wmsAdminNotes, setWmsAdminNotes] = useState<string>('');
    const [license, setLicense] = useState<string>('');
    const [wmsTitle, setWmsTitle] = useState<string>('');

    useEffect(() => {
        setWmsTitle(props.wms.titleOvr || props.wms.title || '');
        setWmsAdminNotes(props.wms.adminNotes || '');
        setLicense(props.wms.license || '');
    }, [props.wms]);

    return (
        <React.Fragment>
            <Subtitle>Server details</Subtitle>
            <React.Fragment>
                <FormGroup>
                    <Label for="title">WMS server url</Label>
                    <Input disabled type="text" value={props.wms.url} />
                </FormGroup>

                <FormGroup>
                    <Label for="title">Server Title - only for internal usage</Label>
                    <Input
                        type="text"
                        value={wmsTitle}
                        placeholder="Title for the WMS Server"
                        onChange={(e) => setWmsTitle(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="adminNotes">Admin Notes</Label>
                    <Input
                        type="textarea"
                        rows={3}
                        value={wmsAdminNotes}
                        onChange={(e) => setWmsAdminNotes(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="license">License (default license for public layers)</Label>
                    <Input
                        value={license}
                        onChange={(e) => {
                            setLicense(e.target.value);
                        }}
                        type="select"
                        name="license"
                    >
                        <option key="">Choose license</option>
                        {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </Input>
                </FormGroup>

                <Row>
                    <Col>
                        <Button onClick={() => store.dispatch(push(`/profiles/${props.wms.userId}`))}>
                            Back to profile
                        </Button>
                        <Button
                            onClick={() => {
                                props.onSaveWMSDetails(wmsTitle, license, wmsAdminNotes);
                            }}
                        >
                            Save Changes
                        </Button>
                        <WMSDeleteAction wms={props.wms} invalidateServer={() => store.dispatch(push('/wms'))}>
                            <Button color="danger">Delete Server</Button>
                        </WMSDeleteAction>
                    </Col>
                </Row>
            </React.Fragment>
        </React.Fragment>
    );
};

export default WMSServerEditDetails;
