import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { ArchiveDTO } from '../../api/model-orders';
import ViewHelper from '../view-helper';

interface Props {
    items: ArchiveDTO[];
    selected: string[];
    onSceneSelectionChange: (scenes: string[]) => void;
}

const key = '_archive-scenes-table';

const ArchiveTable = (props: Props) => {
    const { items, selected } = props;

    const [archives, setArchives] = useState<ArchiveDTO[]>([]);

    useEffect(() => {
        const mapped = items?.map((a) => {
            if (!selected) {
                return a;
            }
            a.selected = selected?.includes(a.id);
            return a;
        });
        setArchives(mapped);
    }, [items, selected]);

    const onDragEnd = (e) => {
        if (e.reason !== 'DROP') return;
        if (!e.destination) return;
        const sceneId = e.draggableId.replace(key, '');
        const cswResultIndex = archives.findIndex((t) => String(t.id) === sceneId);
        if (cswResultIndex === -1) return;
        const cswResult = archives[cswResultIndex];
        const destinationIndex = e.destination.index;

        const reordered = archives;
        reordered.splice(cswResultIndex, 1);
        reordered.splice(destinationIndex, 0, cswResult);
        setArchives(reordered);
        updateScenes(reordered);
    };

    const updateSelected = (checked, index) => {
        const results = archives;
        results[index].selected = checked;
        setArchives(results);
        updateScenes(results);
    };

    const updateScenes = (results: ArchiveDTO[]) => {
        const scenes: string[] = [];
        results.forEach((result) => {
            if (result.selected) {
                scenes.push(result.id);
            }
        });
        props.onSceneSelectionChange(scenes);
    };

    return (
        <Col>
            <HeaderDiv>
                <Item width="25px"></Item>
                <Item width="200px">Date</Item>
                <Item width="200px">Scene ID</Item>
                <Item width="60px">Cloud</Item>
                <Item width="60px">Res</Item>
                <Item width="90px">Roll angle</Item>
                <Item>Description</Item>
            </HeaderDiv>
            <ArchiveResults>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, _snapshot) => (
                            <div ref={provided.innerRef}>
                                {archives.map((item, index) => (
                                    <Draggable key={item.id + key} draggableId={item.id + key} index={index}>
                                        {(provided, _snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <RowDiv>
                                                    <Item width="25px">
                                                        <input
                                                            checked={item.selected}
                                                            type="checkbox"
                                                            onChange={(e) => updateSelected(e.target.checked, index)}
                                                        />
                                                    </Item>
                                                    <Item width="200px">
                                                        {ViewHelper.formatDateTimeFromISO(item.date)}
                                                    </Item>
                                                    <Item
                                                        width="200px"
                                                        title={item.id}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(item.id);
                                                        }}
                                                    >
                                                        {item.id}
                                                    </Item>
                                                    <Item width="60px">{item.cloud}%</Item>
                                                    <Item width="60px">{item.resolutionInCm}cm</Item>
                                                    <Item width="90px">{item.rollAngle}%</Item>
                                                    <Item>{item.description}</Item>
                                                </RowDiv>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </ArchiveResults>
        </Col>
    );
};

export default ArchiveTable;

const HeaderDiv = styled.div`
    padding: 10px 0px 5px 0px;
    font-size: 16px;
    color: #848c94;
    display: flex;
    font-weight: 600;
`;

const RowDiv = styled.div`
    display: flex;
`;

interface ItemProps {
    width?: string;
}

const Item = styled.div<ItemProps>`
    padding: 5px;
    width: ${(props) => (props.width ? props.width : 'auto')};
    color: #848c94;

    &:nth-child(3),
    &:nth-child(7) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const ArchiveResults = styled.div`
    max-height: 600px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #343a40;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #888;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
        margin-top: 2px;
        margin-bottom: 0;
    }
`;
