import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiOrders from '../../api/api-orders';
import { ArchiveDTO } from '../../api/model-orders';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Label, Spinner } from '../style';
import ViewHelper from '../view-helper';

interface Props {
    supplier: string;
    product: string;
    geometryWKT?: string;
    selectedArchives?: ArchiveDTO[];

    onSceneSelectionChange?: (scenes: string[]) => void;
    onSelectArchives?: (archives: ArchiveDTO[]) => void;
}

const key = '_archive-scenes-table';

const SelectAOIArchives: React.FC<Props> = (props) => {
    const { supplier, product, geometryWKT, onSelectArchives } = props;

    const [archives, setArchives] = useState<ArchiveDTO[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>(
        props.selectedArchives ? props.selectedArchives.map((a) => a.id) : []
    );
    const [selectedArchives, setSelectedArchives] = useState<ArchiveDTO[]>(props.selectedArchives || []);

    const [searching, setSearching] = useState(false);

    useEffect(() => {
        const refreshArchives = async () => {
            setSearching(true);
            try {
                const list = await ApiOrders.searchArchive(supplier, product, geometryWKT);
                setArchives(list);
                if (list.length == 0) {
                    toast.warn('No results for selected AOI and product combination');
                }
            } catch (err) {
                toast.error('Error during fetching archives scenes');
                console.error(err);
            } finally {
                setSearching(false);
            }
        };
        if (supplier && geometryWKT) {
            refreshArchives();
        }
    }, [supplier, product, geometryWKT]);

    useEffect(() => {
        setSelectedArchives(archives.filter((a) => selectedIds.includes(a.id)));
    }, [archives, selectedIds]);

    useEffect(() => {
        if (onSelectArchives) {
            onSelectArchives(selectedArchives);
        }
    }, [selectedArchives, onSelectArchives]);

    const onDragEnd = (e) => {
        if (e.reason !== 'DROP') return;
        if (!e.destination) return;
        const sceneId = e.draggableId.replace(key, '');
        const cswResultIndex = archives.findIndex((t) => String(t.id) === sceneId);
        if (cswResultIndex === -1) return;
        const cswResult = archives[cswResultIndex];
        const destinationIndex = e.destination.index;

        const reordered = archives;
        reordered.splice(cswResultIndex, 1);
        reordered.splice(destinationIndex, 0, cswResult);
    };

    const updateSelected = (checked: boolean, id: string) => {
        if (checked) {
            setSelectedIds([...selectedIds, id]);
        } else {
            setSelectedIds(selectedIds.filter((v) => v !== id));
        }
    };

    return (
        <>
            <FlexDiv>
                {searching && (
                    <ArchivesInfo>
                        <Spinner />
                    </ArchivesInfo>
                )}
                {!searching && archives.length === 0 && (
                    <ArchivesInfo>No data in archive for selected AOI and product</ArchivesInfo>
                )}
            </FlexDiv>

            {archives.length > 0 && (
                <>
                    <TableDetailsLabel>Scenes ID</TableDetailsLabel>
                    <Col>
                        <TableDetailsHeader>
                            <Item></Item>
                            <Item>Date</Item>
                            <Item>Scene ID</Item>
                            <Item>Cloud</Item>
                            <Item>Res</Item>
                            <Item>Roll angle</Item>
                            <Item>Description</Item>
                        </TableDetailsHeader>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, _snapshot) => (
                                    <div ref={provided.innerRef}>
                                        {archives.map((item, index) => (
                                            <Draggable key={item.id + key} draggableId={item.id + key} index={index}>
                                                {(provided, _snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <RowDiv>
                                                            <Item>
                                                                <input
                                                                    value={item.id}
                                                                    checked={selectedIds.includes(item.id)}
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        updateSelected(e.target.checked, e.target.value)
                                                                    }
                                                                />
                                                            </Item>
                                                            <Item>{ViewHelper.formatDateTimeFromISO(item.date)}</Item>
                                                            <Item
                                                                title={item.id}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(item.id);
                                                                }}
                                                            >
                                                                {item.id}
                                                            </Item>
                                                            <Item>{item.cloud}%</Item>
                                                            <Item>{item.resolutionInCm}cm</Item>
                                                            <Item>{item.rollAngle}%</Item>
                                                            <Item>{item.description}</Item>
                                                        </RowDiv>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Col>
                </>
            )}
        </>
    );
};

export default SelectAOIArchives;

const FlexDiv = styled.div`
    display: flex;
`;

const ArchivesInfo = styled.div`
    padding: 10px;
    width: 100%;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    margin-top: 10px;
`;

const TableDetailsLabel = styled(Label)`
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 16px;
    color: #eed926;
`;

const TableDetailsHeader = styled.div`
    display: flex;
    font-weight: 600;
`;

const RowDiv = styled.div`
    display: flex;
    border: 1px solid rgba(116, 116, 116, 0.3);
    padding: 5px 0px;

    &:hover {
        color: white;
        background: rgba(0, 0, 0, 0.35);
    }
`;

const Item = styled.div`
    color: #848c94;
    padding: 5px;
    &:nth-child(1) {
        width: 25px;
    }
    &:nth-child(2) {
        width: 200px;
    }
    &:nth-child(3) {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &:nth-child(4) {
        width: 60px;
        text-align: right;
    }
    &:nth-child(5) {
        width: 60px;
        text-align: right;
    }
    &:nth-child(6) {
        width: 80px;
        text-align: right;
    }
    &:nth-child(7) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
