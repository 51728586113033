import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiReports from '../../../api/api-reports';
import { HashTagsDTO } from '../../../api/model';
import { DatesFromTo } from '../../../api/model-api';
import InputDateRange from '../../Shared/Filters/DateRange/input-date-range';
import { Card, Col, Label, Row, FormGroup, Input, Subtitle, Title, Container, Statistic, Spinner } from '../../style';
import HashtagTable from './hashtag-table';

const Hashtags = () => {
    const [data, setData] = useState<HashTagsDTO[]>([]);
    const [filtered, setFiltered] = useState<HashTagsDTO[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState<DatesFromTo>(undefined);

    const refresh = async () => {
        setLoading(true);
        try {
            const res = await ApiReports.getHashTagsListReport();
            setData(res);
        } catch (err) {
            toast.error('Error fetching hashtags', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    const handleFilterHashtagsByDate = async (dates?: DatesFromTo) => {
        try {
            const res = await ApiReports.getHashTagsListReport(dates);
            setData(res);
        } catch (err) {
            toast.error('Error fetching hashtags', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (dates?.from && dates?.to) {
            handleFilterHashtagsByDate(dates);
        } else {
            handleFilterHashtagsByDate();
        }
    }, [dates]);

    useEffect(() => {
        const filteredData = data.filter((d) =>
            searchTerm?.length > 0 ? d.tag?.toLocaleLowerCase().includes(searchTerm) : true
        );
        setFiltered(filteredData);
    }, [data, searchTerm]);

    if (loading) {
        return (
            <Container>
                <Title>Hashtag Report</Title>
                <Card height="105px">
                    <Spinner />
                </Card>
                <Card height="104px">
                    <Spinner />
                </Card>
                <Card height="595px">
                    <Spinner />
                </Card>
            </Container>
        );
    }

    const HashtagOverview = (props) => {
        const { data } = props;
        const hashtagCount = data.length;
        const hashtagSum = data.reduce((accumulator, hashtag) => accumulator + hashtag.count, 0);

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Card title="Total number of unique hashtags">
                            <Subtitle>Unique Hashtags</Subtitle>
                            <Statistic>{hashtagCount.toLocaleString()}</Statistic>
                        </Card>
                    </Col>
                    <Col>
                        <Card title="Total number of hashtags">
                            <Subtitle>Total Hashtags </Subtitle>
                            <Statistic>{hashtagSum.toLocaleString()}</Statistic>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    return (
        <Container>
            <Title>Hashtag Report</Title>
            <HashtagOverview data={data} />
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange fromTo={dates} onChange={setDates} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="hashtag-filter">Search by Hashtag</Label>
                            <Input
                                title="Search by hashtag"
                                placeholder="Enter hashtag"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value?.toLocaleLowerCase())}
                                type="text"
                                name="hashtag"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            <Card>
                <HashtagTable items={filtered} />
            </Card>
        </Container>
    );
};
export default Hashtags;
