import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import ApiReports from '../../../api/api-reports';
import { ListingType, ReportListingsDTO } from '../../../api/model';
import { DatesFromTo, ReportInterval } from '../../../api/model-api';
import InputDateRange, { DEFAULT_DATE_RANGES, LAST_365_DAYS } from '../../Shared/Filters/DateRange/input-date-range';
import InputListingType from '../../Shared/Filters/input-listing-type';
import { Card, ChartTitle, Col, FormGroup, Label, Row, Spinner } from '../../style';

const UploadsPieChart = () => {
    const [type, setType] = useState<ListingType>(ListingType.TILE_LAYER);
    const [report, setReport] = useState<ReportListingsDTO>(undefined);
    const [searches, setSearches] = useState<any[]>([]);
    const [dates, setDates] = useState<DatesFromTo>(LAST_365_DAYS);

    const refresh = async () => {
        try {
            const res = await ApiReports.getListingsReportByInterval(ReportInterval.DAY);
            setReport(res);
        } catch (err) {
            // setError(err.toString());
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if (report) {
            const data = [];
            let accApproved = 0;
            let accRejected = 0;
            let accPending = 0;
            const dateFrom = dates?.from ? formatISO(dates.from, { representation: 'date' }) : undefined;
            const dateTo = dates?.to ? formatISO(dates.to, { representation: 'date' }) : undefined;
            report.intervals
                .filter((v) => (dateFrom && dateTo ? v.label >= dateFrom && v.label < dateTo : true))
                .forEach((value) => {
                    if (!type || type === ListingType.TILE_LAYER) {
                        accApproved += value.mapApproved;
                        accRejected += value.mapRejected;
                        accPending += value.mapPending;
                    }
                    if (!type || type === ListingType.IMAGE) {
                        accApproved += value.imageApproved;
                        accRejected += value.imageRejected;
                        accPending += value.imagePending;
                    }
                });

            data.push(['Review', 'Total']);
            data.push(['Approved', accApproved]);
            data.push(['Rejected / Deleted', accRejected]);
            data.push(['Pending', accPending]);
            setSearches(data);
        }
    }, [report, dates, type]);

    if (!searches || !report) {
        return (
            <React.Fragment>
                <Card height="104px">
                    <Spinner />
                </Card>
                <Card height="664px">
                    <Spinner />
                </Card>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="type">Filter by Type</Label>
                            <InputListingType value={type} onChange={setType} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter By Date</Label>
                            <InputDateRange
                                fromTo={dates}
                                onChange={setDates}
                                selectedDate={DEFAULT_DATE_RANGES.LAST_365_DAYS}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            <Card>
                <ChartTitle>Uploads by Percentage</ChartTitle>
                <Chart
                    style={{
                        width: '100%',
                    }}
                    width={'100%'}
                    height={'600px'}
                    chartType="PieChart"
                    data={searches}
                    options={{
                        backgroundColor: 'transparent',
                        legend: {
                            textStyle: {
                                color: 'white',
                            },
                        },
                        chartArea: { width: '80%' },
                        colors: ['green', 'red', 'orange'],
                    }}
                />
            </Card>
        </React.Fragment>
    );
};

export default UploadsPieChart;
