import Api from './api';
import {
    ReportDTO,
    MainReportDTO,
    SoarReportDTO,
    SubdomainsReportDTO,
    UserReportDTO,
    UsersReportDTO,
    ReportListingsDTO,
    ListingCommentsDTO,
    ReportUploadersDTO,
    MonthlyReportCFDTO,
    DailyReportCFDTO,
    DailyReportCFFilter,
    HashTagsDTO,
    DrawProjectReportDTO,
} from './model';
import { DatesFromTo, ReportInterval, UserReportFilter } from './model-api';

export default class ApiReports extends Api {
    public static getReportIntervals(inteval: string): Promise<MainReportDTO> {
        return this.axios
            .get(`/v1/admin/reports?interval=${inteval}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getMainReport(): Promise<MainReportDTO> {
        return this.axios
            .get(`/v1/admin/reports`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getSubdomainsReport(): Promise<SubdomainsReportDTO> {
        return this.axios
            .get(`/v1/admin/reports/subdomains`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getSoarReport(): Promise<SoarReportDTO> {
        return this.axios
            .get(`/v1/admin/reports/soar`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    private static sanitazeNumber(value?: number): number {
        return value ? value : 0;
    }

    public static getUsersReport(_filter: UserReportFilter): Promise<UsersReportDTO> {
        return this.axios
            .get(`/v1/admin/reports/users`)
            .then((res) => {
                const dto: UsersReportDTO = res.data;
                const users = res.data.list.map((u: UserReportDTO) => {
                    return ApiReports.mapUserReportDTO(u);
                });
                dto.list = users;
                return dto;
            })
            .catch((err) => {
                throw err;
            });
    }

    private static mapUserReportDTO(u: UserReportDTO) {
        const user: UserReportDTO = {
            id: u.id,
            email: u.email,
            name: u.name,
            createdAt: u.createdAt,
            created: u.createdAt ? new Date(u.createdAt * 1000) : undefined,
            ethAddress: u.ethAddress,
            maps: this.sanitazeNumber(u.maps),
            mapsRejected: this.sanitazeNumber(u.mapsRejected),
            mapsArea: this.sanitazeNumber(u.mapsArea),
            mapsAreaRejected: this.sanitazeNumber(u.mapsAreaRejected),
            mapsSize: this.sanitazeNumber(u.mapsSize),
            mapsSizeRejected: this.sanitazeNumber(u.mapsSizeRejected),
        };
        return user;
    }

    public static getUsersReportByInterval(interval: ReportInterval): Promise<ReportDTO> {
        return this.axios
            .get(`/v1/admin/reports/users/interval?interval=${interval}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getListingsReportByInterval(interval: ReportInterval): Promise<ReportListingsDTO> {
        return this.axios
            .get(`/v1/admin/reports/listings/interval?interval=${interval}`)
            .then((res) => {
                const report: ReportListingsDTO = res.data;
                report.intervals.forEach((interval) => {
                    if (!interval.imageApproved) interval.imageApproved = 0;
                    if (!interval.imagePending) interval.imagePending = 0;
                    if (!interval.imageRejected) interval.imageRejected = 0;
                    if (!interval.mapApproved) interval.mapApproved = 0;
                    if (!interval.mapPending) interval.mapPending = 0;
                    if (!interval.mapRejected) interval.mapRejected = 0;
                });
                return report;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getUploadersReportByInterval(interval: ReportInterval): Promise<ReportUploadersDTO> {
        return this.axios
            .get(`/v1/admin/reports/uploaders/interval?interval=${interval}`)
            .then((res) => {
                const report: ReportUploadersDTO = res.data;
                report.intervals.forEach((interval) => {
                    if (!interval.image) interval.image = 0;
                    if (!interval.imageNew) interval.imageNew = 0;
                    if (!interval.imageApproved) interval.imageApproved = 0;
                    if (!interval.imageApprovedNew) interval.imageApprovedNew = 0;
                    if (!interval.imageRejected) interval.imageRejected = 0;
                    if (!interval.imageRejectedNew) interval.imageRejectedNew = 0;
                    if (!interval.map) interval.map = 0;
                    if (!interval.mapNew) interval.mapNew = 0;
                    if (!interval.mapApproved) interval.mapApproved = 0;
                    if (!interval.mapApprovedNew) interval.mapApprovedNew = 0;
                    if (!interval.mapRejected) interval.mapRejected = 0;
                    if (!interval.mapRejectedNew) interval.mapRejectedNew = 0;
                    if (!interval.total) interval.total = 0;
                });
                return report;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getCommentsReportByInterval(interval: ReportInterval): Promise<ReportDTO> {
        return this.axios
            .get(`/v1/admin/reports/comments/interval?interval=${interval}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getCommentsByListings(): Promise<ListingCommentsDTO[]> {
        return this.axios
            .get(`/v1/admin/reports/comments/listings`)
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getTilesReportByInterval(interval: ReportInterval): Promise<ReportDTO> {
        return this.axios
            .get(`/v1/admin/reports/tiles/interval?interval=${interval}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getCFMonthlyReport(): Promise<MonthlyReportCFDTO[]> {
        return this.axios
            .get(`/v1/admin/reports/cloudflare/monthly`)
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getCFDailyReport(filter?: DailyReportCFFilter): Promise<DailyReportCFDTO[]> {
        const params = {
            timeFrom: filter?.dates?.from ? Math.ceil(filter.dates.from.getTime() / 1000) : undefined,
            timeTo: filter?.dates?.to ? Math.ceil(filter.dates.to.getTime() / 1000) : undefined,
        };
        return this.axios
            .get(`/v1/admin/reports/cloudflare/daily`, { params: params })
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getHashTagsListReport(dates?: DatesFromTo): Promise<HashTagsDTO[]> {
        const params = {
            timeFrom: dates?.from ? Math.ceil(dates.from.getTime() / 1000) : undefined,
            timeTo: dates?.to ? Math.ceil(dates.to.getTime() / 1000) : undefined,
        };
        return this.axios
            .get(`/v1/admin/reports/listings/tags`, { params: params ? params : '' })
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getDrawProjectsReports(): Promise<DrawProjectReportDTO[]> {
        return this.axios
            .get(`/v1/admin/reports/map-draw/projects`)
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }
}
