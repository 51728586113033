import React, { useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { AnalyticsItemDTO } from '../../../../api/model';
import GeoUtil from '../../../../lib/geo-util';
import ViewHelper from '../../../view-helper';
import Details from './details';
import { Table, TableWrapper } from '../../../style';

interface Props {
    items: AnalyticsItemDTO[];
}

const DataTable = (props: Props) => {
    const { items } = props;
    const [selected, setSelected] = useState<AnalyticsItemDTO | null>(null);

    const [expanded, setExpanded] = useState<string[]>([]);
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            formatter: (cell) => ViewHelper.formatDateWithTime(cell),
        },
        {
            dataField: 'email',
            text: 'Email',
            formatter: (cell) => (cell ? cell : 'Not Registered'),
        },
        {
            dataField: 'ipAddress',
            text: 'IP Address',
            formatter: (_cell, row: AnalyticsItemDTO) => `${row.ipAddress} (${row.countryCode})`,
        },
        {
            dataField: 'geometryWKT',
            text: 'AOI',
            formatter: (cell) => ViewHelper.formatArea(GeoUtil.area(cell), true),
        },
        {
            dataField: 'provider',
            text: 'Supplier',
            formatter: (_cell, row: AnalyticsItemDTO) => `${row.provider}`,
        },
        {
            dataField: 'satellite',
            text: 'Data Accessed',
            formatter: (_cell, row: AnalyticsItemDTO) => `${row.satellite}${row.layers ? ', ' + row.layers : ''}`,
        },
        {
            dataField: 'action',
            text: 'Action',
            formatter: (cell, row: AnalyticsItemDTO) => ViewHelper.ANALYTICS_ACTION_LABELS[row.action] || 'Not defined',
        },
    ];

    const handleOnExpand = (row, isExpand, _rowIndex, _e) => {
        if (isExpand) {
            setExpanded([...expanded, row.id]);
        } else {
            setExpanded(expanded.filter((x) => x !== row.id));
        }
    };

    const expandRow = {
        renderer: (row, _rowIndex) => {
            const cu: AnalyticsItemDTO = row;
            return <Details item={cu} />;
        },
        expanded: expanded,
        onExpand: handleOnExpand,
    };
    return (
        <React.Fragment>
            <ToolkitProvider keyField="id" data={items} columns={columns}>
                {(props) => (
                    <TableWrapper>
                        <Table {...props.baseProps} expandRow={expandRow} />
                    </TableWrapper>
                )}
            </ToolkitProvider>
        </React.Fragment>
    );
};

export default DataTable;
