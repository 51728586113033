import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ApiTms, { TMSServer } from '../../../api/api-tms';
import { Card, Container, Divider, Spinner, Subtitle, Table, TableWrapper, Title } from '../../style';
import store from '../../../store/store';
import { push } from 'connected-react-router';
import TMSActions from './Server/tms-actions';

const TMS = () => {
    const [tmsServers, setTmsServers] = useState<TMSServer[]>([]);

    useEffect(() => {
        loadTMSServers();
    }, []);

    const loadTMSServers = () => {
        setTmsServers([]);
        ApiTms.getTMSServers().then((res) => {
            setTmsServers(res.sort((a, b) => a.id - b.id));
        });
    };

    const rowEventHandlers = {
        onClick: (e, row) => {
            const target = e.target as HTMLElement;
            if (target.classList.contains('dropdown') || target.tagName === 'BUTTON' || target.tagName === 'I') {
                // Don't open new route when clicking the action button
            } else {
                store.dispatch(push(`/tms/${row.id}`));
            }
        },
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: () => {
                return {
                    width: '40px',
                };
            },
        },
        {
            dataField: 'name',
            text: 'Server Name',
            srot: true,
            formatter: (_cell, row: TMSServer) => {
                return row.name;
            },
        },
        {
            dataField: 'publicLayers',
            text: 'Public Layers',
            headerStyle: () => {
                return {
                    width: '120px',
                };
            },
        },
        {
            dataField: 'totalLayers',
            text: 'Total Layers',
            headerStyle: () => {
                return {
                    width: '120px',
                };
            },
        },
        {
            dataField: 'id',
            text: 'Actions',
            headerStyle: () => {
                return {
                    width: '70px',
                };
            },
            formatter: (cell) => {
                const layer = tmsServers.find((t) => t.id === cell);
                return <TMSActions tmsServer={layer} invalidateServer={() => loadTMSServers()} />;
            },
        },
    ];

    return (
        <Container>
            <Title>Manage TMS (External Tile Layers)</Title>

            <Divider />

            {!tmsServers || tmsServers.length === 0 ? (
                <Card>
                    <Subtitle>Manage an existing TMS server</Subtitle>
                    <Spinner />
                </Card>
            ) : null}

            {tmsServers && tmsServers.length > 0 ? (
                <Card>
                    <Subtitle>Manage an existing TMS server</Subtitle>

                    <ToolkitProvider
                        keyField="id"
                        data={tmsServers}
                        columns={columns}
                        search={{ searchFormatted: true }}
                        exportCSV={{
                            fileName: 'soar-satellite-orders.csv',
                        }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table {...props.baseProps} rowEvents={rowEventHandlers} />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </Card>
            ) : null}
        </Container>
    );
};

export default TMS;
