import { ContactUsDTO, ListingDTO, ReportedCommentDTO, UserDTO } from '../../api/model';
import { OrderForAdminDTO } from '../../api/model-orders';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

export interface IReduxAppStore {
    user?: UserDTO;
    loggedIn?: boolean;
    featured?: ListingDTO[];
    featuredError?: string;

    contactUsLoading: boolean;
    contactUsEnquiries?: ContactUsDTO[];

    supermapQueueLoading: boolean;
    supermapQueue?: ListingDTO[];
    supermapQueueLength?: number;

    ordersLoading: boolean;
    orders?: OrderForAdminDTO[];
    ordersError?: string;

    reportedCommentsLoading: boolean;
    reportedComments?: ReportedCommentDTO[];
    reportedCommentsError?: string;
}

const initialState: IReduxAppStore = {
    user: undefined,
    loggedIn: undefined,
    contactUsLoading: false,
    supermapQueueLoading: false,
    ordersLoading: false,
    reportedCommentsLoading: false,
};

export default function (state: IReduxAppStore = initialState, action: Action): IReduxAppStore {
    switch (action.type) {
        case at.APP_SAVE_USER_PROFILE:
            return { ...state, user: action.payload, loggedIn: action.payload !== undefined };
        case at.APP_FEATURED_UPDATED:
            return { ...state, featured: action.payload, featuredError: undefined };
        case at.APP_FEATURED_ERROR:
            return { ...state, featuredError: action.payload, featured: undefined };

        case at.APP_CONTACT_US_LOADING:
            return { ...state, contactUsLoading: action.payload };

        case at.APP_CONTACT_US_UPDATED:
            return { ...state, contactUsEnquiries: action.payload };

        case at.APP_SUPERMAP_QUEUE_LOADING:
            return { ...state, supermapQueueLoading: action.payload };

        case at.APP_SUPERMAP_QUEUE_UPDATED:
            return {
                ...state,
                supermapQueue: action.payload.listings,
                supermapQueueLength: action.payload.total,
            };

        case at.APP_ORDERS_LOADING:
            return { ...state, ordersLoading: action.payload };

        case at.APP_ORDERS_UPDATED:
            return { ...state, orders: action.payload };

        case at.APP_REPORTED_COMMENTS_LOADING:
            return { ...state, reportedCommentsLoading: action.payload };

        case at.APP_REPORTED_COMMENTS_UPDATED:
            return { ...state, reportedComments: action.payload };

        case at.APP_REPORTED_COMMENTS_ERROR:
            return { ...state, reportedCommentsError: action.payload };
        default:
            return state;
    }
}
