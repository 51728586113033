import React, { useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiComments from '../../../api/api-comments';

interface ReportCommentDialogProps {
    commentId: string;
    handleCloseReportCommentDialog: (deletedComment?: string) => void;
}

const ReportCommentDialog = (props: ReportCommentDialogProps) => {
    const [reportReason, setReportReason] = useState<string>('Spam');
    const [message, setMessage] = useState<string>('');
    const [processing, setProcessing] = useState(false);

    const handleReportComment = () => {
        setProcessing(true);
        ApiComments.reportComment(props.commentId, reportReason, message)
            .then((_res) => {
                setProcessing(false);
                props.handleCloseReportCommentDialog(props.commentId);
            })
            .catch((_err) => {
                setProcessing(false);
                toast.dark('There was a problem reporting the comment.  Please try again later');
                props.handleCloseReportCommentDialog();
            });
    };

    return (
        <ReportModal isOpen={true} toggle={() => props.handleCloseReportCommentDialog()}>
            <ReportModalHeader toggle={() => props.handleCloseReportCommentDialog()}>
                <i className="fa fa-exclamation-circle icon mr-2" />
                <span>Report this comment</span>
            </ReportModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Please select the reason for reporting this comment</Label>
                    <Input
                        data-testid="select-reason"
                        type="select"
                        value={reportReason}
                        onChange={(value) => setReportReason(value.target.value)}
                    >
                        <option value="Spam">Spam</option>
                        <option value="Hate">Hate</option>
                        <option value="Misinformation">Misinformation</option>
                        <option value="Harassment">Harassment</option>
                        <option value="Threatening Violence">Threatening violence</option>
                        <option value="Copyright violation">Copyright violation</option>
                        <option value="Violation of Soar.Earth Terms">Violation of Soar.Earth T&C's</option>
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label>Tell us more (optional)</Label>
                    <MessageTextArea
                        aria-label="message"
                        type="textarea"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <ReportSubmitButton disabled={processing} onClick={() => props.handleCloseReportCommentDialog()}>
                    CANCEL
                </ReportSubmitButton>
                <ReportSubmitButtonCancel disabled={processing} onClick={handleReportComment}>
                    REPORT
                </ReportSubmitButtonCancel>
            </ModalFooter>
        </ReportModal>
    );
};

export default ReportCommentDialog;

const ReportModal = styled(Modal)`
    margin-top: 100px;
    max-width: 450px;

    span {
        font-size: 20px;
        text-align: center;
        color: white;
    }

    .modal-header {
        border-bottom: 1px solid rgb(255, 255, 255, 0.3);
    }

    .modal-content {
        background-color: rgb(0, 0, 0, 0.8) !important;
        border: 1px solid rgb(255, 255, 255, 0.3);
        border-radius: 6px;
    }

    .modal-body {
        color: rgba(255, 255, 255, 0.87);
        font-size: 16px;
        padding-bottom: 0px;
    }

    .modal-footer {
        border-top: none !important;
        padding: 0px;
    }

    label {
        color: rgba(255, 255, 255, 0.87);
        width: 100%;
    }

    input {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgb(255 255 255 / 0.3) !important;

        :focus {
            background-color: transparent;
            border-color: rgba(238 227 8 / 0.6);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
        }

        ::placeholder {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    select {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgb(255 255 255 / 0.3);

        :focus {
            background-color: transparent;
            border-color: rgba(255, 255, 255, 0.3);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
        }
    }

    option {
        background-color: rgba(0, 0, 0, 0.85);
        color: rgba(255, 255, 255, 0.6);
        font-size: 15px;

        :hover {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }

    textarea {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgb(255 255 255 / 0.3);

        :focus {
            background-color: transparent;
            border-color: rgba(238 227 8 / 0.6);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
            color: rgba(255, 255, 255, 0.6);
        }
    }
`;

const ReportModalHeader = styled(ModalHeader)`
    background-color: rgb(0, 0, 0, 0.6) !important;
    color: white;

    i {
        color: red;
        font-size: 30px;
    }
`;

const ReportSubmitButton = styled.button`
    margin: 12px 10px 12px 10px;
    padding: 5px 20px;
    font-size: 18px;
    border-radius: 4px;
    background-color: black !important;
    color: white !important;
    -webkit-text-fill-color: white;
    font-size: 16px !important;
    border: 1px solid white !important;

    &:hover {
        opacity: 0.6;
    }
`;

const ReportSubmitButtonCancel = styled.button`
    margin: 12px 10px 12px 10px;
    padding: 5px 20px;
    font-size: 16px;
    background-color: red !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    -webkit-text-fill-color: black;
    border-radius: 4px;

    &:hover {
        opacity: 0.6;
    }
`;

const MessageTextArea = styled(Input)`
    height: 100px !important;
`;
