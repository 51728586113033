import React from 'react';
import { ListingDTO } from '../../api/model';
import { Col, Row } from '../style';
import filesize from 'filesize';
import Config from '../../config';
import styled from 'styled-components';

interface Props {
    listing: ListingDTO;
}

const ListingPreviewNotSupported = (props: Props) => {
    const { listing } = props;
    const created = new Date(listing.createdAt * 1000);
    return (
        <Row>
            <ListingNotSupportedCol>
                <h3>NOT SUPPORTED</h3>
                <p>
                    <span>ERROR: </span>
                    {listing.error}{' '}
                </p>
                <p>
                    <span>Filename: </span>
                    {listing.filename}{' '}
                </p>
                <p>
                    <span>File size: </span>
                    {listing.filesize && filesize(listing.filesize)}
                </p>
                <p>
                    <span>Uploaded: </span>
                    {created.toLocaleDateString() + ' ' + created.toLocaleTimeString()}
                </p>
                <p>
                    <span>Email: </span>
                    <a
                        target="_blank"
                        href={`${Config.CLIENT_URL}/profile/${listing.userId}`}
                        rel="noopener noreferrer"
                    >
                        {listing.userEmail}
                    </a>
                </p>
                <p>
                    <span>Name: </span>
                    {listing.userName}
                </p>
            </ListingNotSupportedCol>
        </Row>
    );
};

export default ListingPreviewNotSupported;

const ListingNotSupportedCol = styled(Col)`
    margin: 10px 0px 15px 0px;

    h3 {
        color: #dc3545;
    }

    p,
    span {
        color: rgba(255, 255, 255, 0.87);
    }

    a {
        color: #eed926;
    }
`;
