import { UploadCredentialsDTO } from './model';

const OSS = require('ali-oss');

export class OssUploader {
    private readonly client;
    private readonly path;
    private readonly ossKey;

    constructor(credentials: UploadCredentialsDTO) {
        const options = {
            accessKeyId: credentials.stsCredentials.accessKeyId,
            accessKeySecret: credentials.stsCredentials.accessSecretKey,
            stsToken: credentials.stsCredentials.securityToken,
            bucket: credentials.bucketName,
            region: credentials.ossRegion,
            timeout: 900000,
            useFetch: true,
        };
        this.client = new OSS(options);
        this.path = credentials.path;
        this.ossKey = credentials.filename;
    }

    public uploadFileToStorage(file: File, filename: string): Promise<any> {
        return this.client
            .put(filename, file)
            .then((_) => {
                return true;
            })
            .catch((err) => {
                throw err;
            });
    }

    public async multipartUpload(file: File, key: string, progress: (p: number) => void): Promise<any> {
        const ossKey = this.ossKey ? this.ossKey : this.path + '/' + key;
        const res = await this.client.multipartUpload(ossKey, file, {
            progress: function (p, _cpt, _res) {
                progress(Math.floor(p * 100));
            },
        });
        return res;
    }
}
