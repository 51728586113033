import { useWindowWidth } from '@react-hook/window-size';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatesFromTo } from '../../api/model-api';
import { OrderForAdminDTO, OrderStatus, ProductOption } from '../../api/model-orders';
import { actionUpdateOrders } from '../../store/AppStore/actions';
import { selectIsLoggedIn, selectOrders, selectOrdersLoading } from '../../store/AppStore/selectors';
import InputDateRange from '../Shared/Filters/DateRange/input-date-range';
import { Card, Container, Input, Label, Title, FormGroup, Row, Col } from '../style';
import ViewHelper from '../view-helper';
import OrderCard from './order-card';
import OrdersTable from './orders-table';

interface OrderSearch extends OrderForAdminDTO {
    search: string;
    createdAt: number;
}

const allOrderStatus = [
    OrderStatus.DRAFT,
    OrderStatus.REVIEW_SOAR,
    OrderStatus.REVIEW_SUPPLIER,
    OrderStatus.REJECTED,
    OrderStatus.COLLECTION,
    OrderStatus.PROCESSING,
    OrderStatus.QA_SOAR,
    OrderStatus.QA_FAILED,
    OrderStatus.DELIVERED,
    OrderStatus.DELETED,
];
const requireAttention = [OrderStatus.DRAFT, OrderStatus.REVIEW_SOAR, OrderStatus.QA_SOAR];

const activeOrders = [
    OrderStatus.DRAFT,
    OrderStatus.REVIEW_SOAR,
    OrderStatus.REVIEW_SUPPLIER,
    OrderStatus.COLLECTION,
    OrderStatus.PROCESSING,
    OrderStatus.QA_SOAR,
    OrderStatus.QA_FAILED,
];

const Orders = () => {
    const orders = useSelector(selectOrders);
    const ordersLoading = useSelector(selectOrdersLoading);
    const loggedIn = useSelector(selectIsLoggedIn);
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch();

    const [rawOrders, setRawOrders] = useState<OrderSearch[]>(undefined);
    const [filteredOrders, setFilteredOrders] = useState<OrderForAdminDTO[]>(orders);
    const [dates, setDates] = useState<DatesFromTo>(undefined);
    const [subdomainOptions, setSubdomainOptions] = useState<string[]>([]);
    const [search, setSearch] = useState<string>('');
    const [productOption, setProductOption] = useState<ProductOption>(undefined);
    const [subdomain, setSubdomain] = useState<string>();

    const [statusFilters, setStatusFilters] = useState<OrderStatus[]>(activeOrders);
    const [statusFilterValue, setStatusFilterValue] = useState('active');

    useEffect(() => {
        if (orders) {
            const orderSearch = orders.map((t) => {
                const order = t as OrderSearch;
                order.search = `id:${
                    t.id
                } order:${t.orderNumber.toLocaleLowerCase()} email:${t.userEmail.toLocaleLowerCase()}`;
                return order;
            });
            setRawOrders(orderSearch);

            const subdomains = Array.from(new Set(orders.map((order) => order.domain)));
            setSubdomainOptions(subdomains);
        }
    }, [orders]);

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        return () => {
            window.removeEventListener('focus', onFocus);
        };
    });

    const onFocus = () => {
        dispatch(actionUpdateOrders());
    };

    useEffect(() => {
        let filtered: OrderForAdminDTO[] = [];
        if (rawOrders) {
            const from = dates?.from ? dates.from.getTime() / 1000 : undefined;
            const to = dates?.to ? dates.to.getTime() / 1000 : undefined;
            filtered = rawOrders
                .filter((t) => (from ? t.createdAt >= from : true))
                .filter((t) => (to ? t.createdAt < to : true))
                .filter((t) => statusFilters.includes(t.status))
                .filter((t) => (productOption ? t.productOption === productOption : true))
                .filter((t) => (search?.length > 0 ? t.search.includes(search.toLocaleLowerCase()) : true))
                .filter((t) => (subdomain?.length > 0 ? t.domain === subdomain : true));
        }
        setFilteredOrders(filtered);
    }, [rawOrders, statusFilters, productOption, search, dates, subdomain]);

    if (!loggedIn) return <React.Fragment />;

    const mobile = windowWidth < 768;
    return (
        <React.Fragment>
            <Container>
                <Title>Satellite Orders {ordersLoading ? <i className="fa fa-spinner fa-spin" /> : null}</Title>

                <Card>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="date-range">Filter by Date</Label>
                                <InputDateRange fromTo={dates} onChange={setDates} />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for="text">Filter by Text</Label>
                                <Input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    type="text"
                                    name="search"
                                    placeholder="Search by text"
                                />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for="order-subdomain">Filter by Subdomain</Label>
                                <Input
                                    value={subdomain}
                                    onChange={(e) => setSubdomain(e.target.value as string)}
                                    type="select"
                                    name="order-subdomain"
                                >
                                    <option value={''} key={''}>
                                        Filter by subdomain (all)
                                    </option>
                                    {subdomainOptions.map((option) => (
                                        <option value={option} key={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for="order-type">Filter by Type</Label>
                                <Input
                                    value={productOption}
                                    onChange={(e) => setProductOption(e.target.value as any)}
                                    type="select"
                                    name="order-type"
                                >
                                    <option value={''}>Filter by order type (all)</option>
                                    {Object.keys(ViewHelper.PRODUCT_OPTION_LABELS).map((key) => (
                                        <option value={key}>{ViewHelper.PRODUCT_OPTION_LABELS[key]}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for="order-status">Filter by Status</Label>
                                <Input
                                    value={statusFilterValue}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setStatusFilterValue(value);
                                        if (value === 'attention') {
                                            setStatusFilters(requireAttention);
                                        } else if (value === 'active') {
                                            setStatusFilters(activeOrders);
                                        } else if (value === 'all') {
                                            setStatusFilters(allOrderStatus);
                                        } else {
                                            setStatusFilters([value]);
                                        }
                                    }}
                                    type="select"
                                    name="order-status"
                                >
                                    <option value={'all'}>Filter by status (all)</option>
                                    <option value={'attention'}>Requires attention</option>
                                    <option value={'active'}>Active orders</option>

                                    {Object.keys(ViewHelper.STATUS_LABELS).map((key) => (
                                        <option value={key}>{ViewHelper.STATUS_LABELS[key]}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>

                <Card>
                    <Row>
                        {!mobile && filteredOrders && <OrdersTable items={filteredOrders} />}
                        {mobile && filteredOrders && filteredOrders.map((t) => <OrderCard key={t.id} order={t} />)}
                    </Row>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default Orders;
