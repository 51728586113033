import React from 'react';
import Masonry from 'react-masonry-css';
import { ListingDTO } from '../../api/model';
import FeaturedPreviewCard from './featured-preview-card';
import styled from 'styled-components';

interface Props {
    featuredItems: ListingDTO[];
    type: string;
}

const FeaturedList = (props: Props) => {
    const breakpointColumnsObj = {
        default: 2,
        1400: 2,
    };

    return (
        <FeaturedMasonry breakpointCols={breakpointColumnsObj} columnClassName="masonry-grid_column">
            {props.featuredItems.map((listing, index) => {
                return <FeaturedPreviewCard key={`featured-${index}`} listing={listing} type={props.type} />;
            })}
        </FeaturedMasonry>
    );
};

export default FeaturedList;

const FeaturedMasonry = styled(Masonry)`
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
`;
