import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import ApiAnalytics from '../../api/api-analytics';
import { UserRole } from '../../api/model';
import { selectUser } from '../../store/AppStore/selectors';
import { selectCloudflareMonthlyReport, selectMainReport, selectSoarReport } from '../../store/ReportsStore/selectors';
import CumulativeUsersChartBar from '../Reports/Graphs/cumulative-user-bar-chart';
import DotsText from '../Shared/DotsText';
import { Card, Col, Container, InvisibleCard, Row, Statistic, Subtitle as StyledSubtitle, Title } from '../style';
import ViewHelper from '../view-helper';
import MapChart from './CloroplethMapChart/map-chart';

const Dashboard = () => {
    const user = useSelector(selectUser);
    const mainReport = useSelector(selectMainReport);
    const soarReport = useSelector(selectSoarReport);
    const cloudflareReport = useSelector(selectCloudflareMonthlyReport);
    const [totalMapInteractions, setTotalMapInteractions] = useState<number>(-1); // To differentiate loading vs redacted

    const totalTraffic = useCallback(() => {
        if (!cloudflareReport || cloudflareReport.length === 0) return <Spinner />;
        const traffic = cloudflareReport.reduce((accumulator, report) => {
            return accumulator + report.sum.bytes;
        }, 0);
        return <>{ViewHelper.formatBytes(traffic)}</>;
    }, [cloudflareReport]);

    const totalMaps = useCallback(() => {
        if (!mainReport) return <Spinner />;
        const maps = mainReport.soar.maps;
        const size = mainReport.soar.mapsSize;
        return (
            <>
                {maps.toLocaleString()} ({ViewHelper.formatBytes(size)})
            </>
        );
    }, [mainReport]);

    const totalUsers = useCallback(() => {
        if (!mainReport) return <Spinner />;
        return <>{mainReport.users.toLocaleString()}</>;
    }, [mainReport]);

    const totalCoverage = useCallback(() => {
        if (!mainReport) return <Spinner />;
        return ViewHelper.formatArea(mainReport.soar.mapsArea, true);
    }, [mainReport]);

    const totalInteractions = useCallback(() => {
        if (totalMapInteractions === -1) return <Spinner />;
        if (totalMapInteractions === 0) return <>Redacted</>;
        return <>{totalMapInteractions.toLocaleString()}</>;
    }, [totalMapInteractions]);

    useEffect(() => {
        const handleGetMapInteractions = async () => {
            if (!user?.roles.includes(UserRole.ANALYTICS)) {
                setTotalMapInteractions(0);
                return;
            }

            if (user?.roles.includes(UserRole.ANALYTICS)) {
                await ApiAnalytics.apiGetAnalytics({ offset: 0, limit: 25, email: '' })
                    .then((res) => {
                        setTotalMapInteractions(res.total);
                    })
                    .catch((_err) => {
                        setTotalMapInteractions(0);
                    });
            }
        };

        handleGetMapInteractions();
    }, [user]);

    return (
        <Container>
            <Row>
                <Title>Dashboard</Title>
            </Row>
            <Row>
                <Col>
                    <Card title="Total number of users">
                        <Subtitle>Users</Subtitle>
                        <Statistic>{totalUsers()}</Statistic>
                    </Card>
                </Col>

                <Col>
                    <Card title="Total number of maps">
                        <Subtitle>Maps</Subtitle>
                        <Statistic>{totalMaps()}</Statistic>
                    </Card>
                </Col>

                <Col>
                    <Card title="Total map coverage">
                        <Subtitle>Coverage</Subtitle>
                        <Statistic>{totalCoverage()}</Statistic>
                    </Card>
                </Col>

                <Col>
                    <Card title="Total interactions (number of times a map or satellite is accessed)">
                        <Subtitle>Interactions</Subtitle>
                        <Statistic>{totalInteractions()}</Statistic>
                    </Card>
                </Col>

                <Col>
                    <Card title="Total traffic (through cloudflare)">
                        <Subtitle>Traffic</Subtitle>
                        <Statistic>{totalTraffic()}</Statistic>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Card>
                    <Subtitle>User Growth</Subtitle>
                    {soarReport ? (
                        <CumulativeUsersChartBar report={soarReport} backgroundColor="transparent" height="500px" />
                    ) : (
                        <DotsText text="Loading" />
                    )}
                </Card>
            </Row>
            <Row>
                <InvisibleCard>
                    <Subtitle>Traffic Source</Subtitle>
                    <MapChart />
                </InvisibleCard>
            </Row>
        </Container>
    );
};

export default Dashboard;

const Subtitle = styled(StyledSubtitle)`
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
`;
