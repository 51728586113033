import React from 'react';
import SoarModal from '../../soar-modal';
import { DateRangePicker } from 'rsuite';
import { useWindowWidth } from '@react-hook/window-size';
import './date-range-styles.css';

import styled from 'styled-components';

interface DateRangeModalProps {
    onDateChange: (value: any) => void;
    fromTo: any;
    openDatePicker;
    setOpenDatePicker: (openDatePicker: boolean) => void;
}

const DateRangeModal = (props: DateRangeModalProps) => {
    const windowWidth = useWindowWidth();
    return (
        <>
            <DateRangePickerModal
                width="600px"
                title={'Custom Date Range'}
                isOpen={props.openDatePicker}
                onClosed={() => props.setOpenDatePicker(false)}
            >
                <DateRangePickerContainer id="date-picker-container">
                    <DateRangePicker
                        showOneCalendar={windowWidth < 600}
                        // @ts-ignore
                        value={props.dates}
                        block
                        isoWeek
                        open={true}
                        onOk={() => props.setOpenDatePicker(false)}
                        onChange={props.onDateChange}
                        container={() => document.getElementById('date-picker-container')}
                    />
                </DateRangePickerContainer>
            </DateRangePickerModal>
        </>
    );
};

export default DateRangeModal;

const DateRangePickerModal = styled(SoarModal)`
    height: 340px;
`;

const DateRangePickerContainer = styled.div`
    height: 340px;
`;
