import React from 'react';
import Select from 'react-select';

export interface TemporalCategory {
    value: string;
    label: string;
}

export const findTemporalCategory = (categories?: string[]): string | undefined => {
    return categories?.find((c) => temporalCategoryOptions.findIndex((pc) => pc.value == c.toLowerCase()) >= 0);
};

export const temporalCategoryOptions: TemporalCategory[] = [
    { value: '', label: 'Not Applicable' },
    { value: 'near-real-time', label: 'Near Real Time' },
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'within-monthly', label: 'Within Monthly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'seasonal', label: 'Seasonal' },
    { value: 'yearly', label: 'Yearly' },
];

interface Props {
    value?: string;
    onChange: (temporal: string) => void;
    disabled?: boolean;
}

const mapExisting = (category?: string): TemporalCategory => {
    return temporalCategoryOptions.find((c) => c.value === category?.toLowerCase());
};

const TemporalInput = ({ value, onChange, disabled }: Props) => {
    const onValueChange = (selectedCategory) => {
        console.log('selectedCategory: ', selectedCategory);
        onChange(selectedCategory.value);
    };

    return (
        <Select
            disabled={disabled}
            name="temporal-category"
            options={temporalCategoryOptions}
            value={mapExisting(value)}
            onChange={onValueChange}
        />
    );
};

export default TemporalInput;
