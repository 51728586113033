import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FormGroup, Label, TableWrapper } from '../style';

interface FormattedDetailsTableProps {
    label?: string | ReactElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any;
}

const FormattedDetailsTable = (props: FormattedDetailsTableProps) => {
    return (
        <FormGroup>
            <Label>{props.label}</Label>
            <OrderTableWrapper>
                <table className="table table-bordered">
                    <tbody>{props.children}</tbody>
                </table>
            </OrderTableWrapper>
        </FormGroup>
    );
};

export default FormattedDetailsTable;

export const OrderTableWrapper = styled(TableWrapper)`
    padding: 2px;

    .table tbody tr:hover {
        th,
        td {
            cursor: default;
            color: #848c94;
            background: rgba(0, 0, 0, 0);
        }
    }
`;

export const Line = (props: {
    label: string | ReactElement;
    value?: string | ReactElement;
    options?: ReactElement;
}) => {
    const { value, label, options } = props;
    if (value === undefined || value === null || value?.toString().length === 0) {
        return <React.Fragment />;
    }
    return (
        <tr>
            <LineItemTh width="30%">{label}</LineItemTh>
            <LineItemTd>{value}</LineItemTd>
            {options && <LineItemTd>{options}</LineItemTd>}
        </tr>
    );
};

const LineItemTh = styled.th`
    line-break: anywhere;
    span {
        float: right;
    }
`;

const LineItemTd = styled.td`
    line-break: anywhere;
    span {
        float: right;
    }
`;
