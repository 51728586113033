import area from '@turf/area';
import distance from '@turf/distance';
import { Polygon } from '@turf/helpers';
import intersect from '@turf/intersect';
import { LatLng, LatLngBounds, LatLngExpression } from 'leaflet';
import * as Wellknown from 'wellknown';
import shpwrite from 'shp-write';
const L = require('leaflet');
const moment = require('moment-timezone');
const omnivore = require('leaflet-omnivore');

export default class GeoUtil {
    static datestampForHimawair(): string {
        const now = new Date();
        const date = moment.tz(now, 'Europe/Dublin').subtract(30, 'minutes').format('YYYYMMDDHHmm');
        const dateStr = String(Math.floor(date / 10) * 10);
        return dateStr;
    }

    // https://gist.github.com/springmeyer/871897
    static latLngToEPSG3857(latLng: LatLng): [number, number] {
        const x = (latLng.lng * 20037508.34) / 180;
        let y = Math.log(Math.tan(((90 + latLng.lat) * Math.PI) / 360)) / (Math.PI / 180);
        y = (y * 20037508.34) / 180;
        return [x, y];
    }

    static EPSG3857ToLatLng(x: number, y: number): LatLng {
        const lng = (180 * x) / 20037508.34;
        const lat = (Math.atan(Math.exp((y * Math.PI) / 20037508.34)) * 360) / Math.PI - 90;
        return L.latLng(lat, lng);
    }

    static positionFromWKT(wkt: any): LatLng | LatLngBounds | undefined {
        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (parsedWKT.type === 'Polygon') {
                const north = parsedWKT.coordinates[0][1][1];
                const east = parsedWKT.coordinates[0][1][0];
                const south = parsedWKT.coordinates[0][3][1];
                const west = parsedWKT.coordinates[0][3][0];

                const northEast = new LatLng(north, east);
                const southWest = new LatLng(south, west);
                return new LatLngBounds(northEast, southWest);
            } else if (parsedWKT.type === 'Point') {
                return new LatLng(parsedWKT.coordinates[1], parsedWKT.coordinates[0]);
            }
            return undefined;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    static latLngFromWKT(wkt: any): LatLng {
        try {
            console.log('Parsing: ' + wkt);

            const parsedWKT: any = Wellknown.parse(wkt);
            if (!parsedWKT) {
                return new LatLng(0, 0);
            }
            return new LatLng(parsedWKT.coordinates[1], parsedWKT.coordinates[0]);
        } catch (e) {
            return new LatLng(0, 0);
        }
    }

    static polygonFromPolygonWKT(wkt: any): LatLng[] {
        const parsedWKT: any = Wellknown.parse(wkt);
        if (parsedWKT && parsedWKT.coordinates) {
            return parsedWKT.coordinates[0].map((coord) => {
                return new LatLng(coord[1], coord[0]);
            });
        }
        return [];
    }

    static distortablePolygonFromPolygonWKT(wkt: any): LatLng[] {
        const parsedWKT: any = Wellknown.parse(wkt);
        if (parsedWKT && parsedWKT.coordinates) {
            const corners = parsedWKT.coordinates[0].map((coord) => new LatLng(coord[1], coord[0]));
            return [corners[0], corners[1], corners[3], corners[2]];
        }
        return [];
    }

    static latLngsFromPolygonWKT(wkt: any): LatLng[] {
        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            const coords = parsedWKT.coordinates[0].map((t) => {
                return new LatLng(t[1], t[0]);
            });
            return coords;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    static latLngBoundsFromPolygonWKT(wkt: any): LatLngBounds {
        try {
            const parsedWKT: any = Wellknown.parse(wkt);
            if (!parsedWKT) {
                return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
            }
            const north = parsedWKT.coordinates[0][1][1];
            const east = parsedWKT.coordinates[0][1][0];
            const south = parsedWKT.coordinates[0][3][1];
            const west = parsedWKT.coordinates[0][3][0];

            const northEast = new LatLng(north, east);
            const southWest = new LatLng(south, west);
            return new LatLngBounds(northEast, southWest);
        } catch (e) {
            console.log('Error parsing polygon WKT for bounding box');
            console.error(e);
            return new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
        }
    }

    static wktToGeoJson(wkt: string): any {
        const parsedWKT: any = Wellknown.parse(wkt);
        return parsedWKT;
    }

    static geojsonToWkt(geoJson: L.GeoJSON): string {
        const s: any = geoJson.toGeoJSON();
        const parsedWKT: any = Wellknown.stringify(s.features[0]);
        return parsedWKT;
    }

    static downloadAoiAsShapefile = (wkt: string, name: string) => {
        const geometry = GeoUtil.wktToGeoJson(wkt);
        const obj = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: geometry,
                },
            ],
        };
        const options = {
            folder: name,
            types: {
                polygon: 'aoi',
            },
        };
        // NOTE - shp-write was broken with the version bump where process is undefined
        // fix from https://github.com/mapbox/shp-write/issues/77
        (window as any).process = {
            browser: true,
        };
        shpwrite.download(obj, options);
    };

    static latLngBoundsToGeoJSON(latLngBounds: LatLngBounds): any {
        const coords = [
            latLngBounds.getNorthEast(),
            latLngBounds.getNorthWest(),
            latLngBounds.getSouthWest(),
            latLngBounds.getSouthEast(),
        ];
        const polygon = new L.polygon(coords);
        return polygon.toGeoJSON();
    }

    static latLngsToGeoJSON(latLngs: LatLng[]): any {
        const polygon = new L.polygon(latLngs);
        return polygon.toGeoJSON();
    }

    static latLngBoundsToWKT(latLngBounds: LatLngBounds): string {
        const coords = [
            latLngBounds.getNorthEast(),
            latLngBounds.getNorthWest(),
            latLngBounds.getSouthWest(),
            latLngBounds.getSouthEast(),
        ];
        const polygon = new L.polygon(coords);
        return Wellknown.stringify(polygon.toGeoJSON());
    }

    static latLngListsToWKT(latLngs: LatLng[]): string {
        const polygon = new L.polygon(latLngs);
        return Wellknown.stringify(polygon.toGeoJSON());
    }

    static widthKilometers(latLngBounds: LatLngBounds): number {
        const ne = latLngBounds.getNorthEast();
        const nw = latLngBounds.getNorthWest();
        return distance([ne.lng, ne.lat], [nw.lng, nw.lat]);
    }

    static heightKilometers(latLngBounds: LatLngBounds): number {
        const ne = latLngBounds.getNorthEast();
        const se = latLngBounds.getSouthEast();
        return distance([ne.lng, ne.lat], [se.lng, se.lat]);
    }

    static area(geometryWKT: string): number {
        if (geometryWKT) {
            const polygonGeoJSON = this.wktToGeoJson(geometryWKT);
            const areaSqm = area(polygonGeoJSON);
            return areaSqm;
        }
        return 0;
    }

    static intersection(poly1: LatLng[], poly2: LatLng[]) {
        //const geo1 = this.latLngsToGeoJSON(poly1);
        //const geo2 = this.latLngsToGeoJSON(poly2);

        const geo1 = poly1.map((p) => {
            return [p.lat, p.lng];
        });

        const geo2 = poly2.map((p) => {
            return [p.lat, p.lng];
        });

        const p1: Polygon = {
            type: 'Polygon',
            coordinates: [geo1],
        };

        const p2: Polygon = {
            type: 'Polygon',
            coordinates: [geo2],
        };

        console.log(p1);
        console.log(p2);

        try {
            const intersection = intersect(p1, p2);
            console.log(intersection);
        } catch (err) {
            console.log(err);
        }
    }

    static polygonAsLatLngExpression(bounds: LatLngBounds): LatLngExpression[] {
        const latlngs = [];
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getEast() });
        latlngs.push({ lat: bounds.getSouth(), lng: bounds.getWest() });
        latlngs.push({ lat: bounds.getNorth(), lng: bounds.getWest() });
        return latlngs;
    }

    static gpsLongitudeDMSToDecimalDegrees(gpsLongitudeDMS: any, longitudeRef: string) {
        if (!gpsLongitudeDMS) {
            return undefined;
        }
        const direction = longitudeRef === 'E' ? 1 : -1;
        return (
            direction *
            (parseFloat(gpsLongitudeDMS[0]) +
                parseFloat(gpsLongitudeDMS[1]) / 60 +
                parseFloat(gpsLongitudeDMS[2]) / 3600)
        );
    }

    static gpsLatitudeDMSToDecimalDegrees(gpsLatitudeDMS: any, latitudeRef: string) {
        if (!gpsLatitudeDMS) {
            return undefined;
        }
        const direction = latitudeRef === 'N' ? 1 : -1;
        return (
            direction *
            (parseFloat(gpsLatitudeDMS[0]) + parseFloat(gpsLatitudeDMS[1]) / 60 + parseFloat(gpsLatitudeDMS[2]) / 3600)
        );
    }

    static boundingBoxFromExifData(
        focalLengthIn35mmFilm: number,
        altitude: number,
        centerLatLng: LatLng,
        gimbalYawDegree: number,
        pixelXDimension: number,
        pixelYDimension: number
    ) {
        if (
            isNaN(focalLengthIn35mmFilm) ||
            isNaN(altitude) ||
            isNaN(gimbalYawDegree) ||
            isNaN(pixelXDimension) ||
            isNaN(pixelYDimension) ||
            altitude < 0
        )
            return undefined;

        const diagonalDistance = (altitude * 35) / focalLengthIn35mmFilm;

        const metersPerPixel =
            diagonalDistance / Math.sqrt(Math.pow(pixelXDimension, 2) + Math.pow(pixelYDimension, 2));

        const distanceHorizontal = metersPerPixel * pixelXDimension;
        const distanceVertical = metersPerPixel * pixelYDimension;

        const rotation = ((Math.PI * -gimbalYawDegree) / 180) % (2 * Math.PI);
        const corner_1 = this.rotationMatrix([-distanceHorizontal / 2, distanceVertical / 2], rotation);
        const corner_2 = this.rotationMatrix([distanceHorizontal / 2, distanceVertical / 2], rotation);
        const corner_3 = this.rotationMatrix([distanceHorizontal / 2, -distanceVertical / 2], rotation);
        const corner_4 = this.rotationMatrix([-distanceHorizontal / 2, -distanceVertical / 2], rotation);

        return [
            this.pairLocalToGlobal(corner_1, centerLatLng),
            this.pairLocalToGlobal(corner_2, centerLatLng),
            this.pairLocalToGlobal(corner_3, centerLatLng),
            this.pairLocalToGlobal(corner_4, centerLatLng),
            this.pairLocalToGlobal(corner_1, centerLatLng),
        ];
    }

    static pairLocalToGlobal(pair: number[], transform: LatLng) {
        const latDirection = pair[0] / Math.abs(pair[0]) + 1;
        const lngDirection = pair[1] / Math.abs(pair[1]) + 1;
        const lat = this.distanceToLatLng(pair[0], transform, latDirection ? 270 : 90)[0];
        const lng = this.distanceToLatLng(pair[1], transform, lngDirection ? 180 : 0)[1];
        return [lat, lng];
    }

    static distanceToLatLng(_distance: number, startPoint: LatLng, bearing: number) {
        const R = 6356752;
        const bearing_radians = (Math.PI * bearing) / 180;
        const start_lat = (Math.PI * startPoint.lat) / 180;
        const start_lng = (Math.PI * startPoint.lng) / 180;
        const distance = Math.abs(_distance);
        const end_lat = Math.asin(
            Math.sin(start_lat) * Math.cos(distance / R) +
                Math.cos(start_lat) * Math.sin(distance / R) * Math.cos(bearing_radians)
        );
        const end_lng =
            start_lng +
            Math.atan2(
                Math.sin(bearing_radians) * Math.sin(distance / R) * Math.cos(start_lat),
                Math.cos(distance / R) - Math.sin(start_lat) * Math.sin(end_lat)
            );

        return [(180 * end_lng) / Math.PI, (180 * end_lat) / Math.PI];
    }

    static rotationMatrix(pair: number[], theta: number) {
        return [
            pair[0] * Math.cos(theta) - pair[1] * Math.sin(theta),
            pair[0] * Math.sin(theta) + pair[1] * Math.cos(theta),
        ];
    }

    static parseFile(fileName, fileText): L.GeoJSON {
        const nameComponents = fileName.split('.');
        const extension = nameComponents[nameComponents.length - 1].toLowerCase();
        let data;
        switch (extension) {
            case 'kml':
                data = omnivore.kml.parse(fileText);
                break;
            case 'csv':
                data = omnivore.csv.parse(fileText);
                break;
            case 'gpx':
                data = omnivore.gpx.parse(fileText);
                break;
            case 'wkt':
                data = omnivore.geojson.parse(fileText);
                break;
            case 'geojson':
                data = omnivore.geojson.parse(fileText);
                break;
            case 'topojson':
                data = omnivore.topojson.parse(fileText);
                break;
            case 'txt':
                data = omnivore.polyline.parse(fileText);
                break;
            default:
                return null;
        }
        if (data) {
            return data;
        }
        return null;
    }

    static getTMSTileXY(lat: number, lng: number, zoom: number): { x: number; y: number } {
        const xtile = Math.floor(((lng + 180) / 360) * (1 << zoom));
        let ytile = Math.floor(
            ((1 - Math.log(Math.tan(GeoUtil.toRad(lat)) + 1 / Math.cos(GeoUtil.toRad(lat))) / Math.PI) / 2) *
                (1 << zoom)
        );
        //converting to tms tile coordinate
        ytile = Math.pow(2, zoom) - ytile - 1;
        return { x: xtile, y: ytile };
    }

    /** Converts numeric degrees to radians */
    static toRad(value: number) {
        return (value * Math.PI) / 180;
    }
}
