import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiListings from '../../../api/api-listings';
import ApiUser from '../../../api/api-user';
import { AnalyticsDTO, ListingDTO, UserDTO } from '../../../api/model';
import { OrderForAdminDTO } from '../../../api/model-orders';
import { selectOrders } from '../../../store/AppStore/selectors';
import SoarModal from '../../Shared/soar-modal';
import ManageDetails from './manage-details';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import UserManagementCard from './user-management-card';
import ManageViewOrders from './manage-view-orders';
import ManageViewListings from './manage-view-listings';
import ManageUserDetailsAdmin from './manage-user-details-admin';
import ApiAnalytics from '../../../api/api-analytics';
import ManageUserAnalyticsAdmin from './manage-user-analytics-admin';
import { Col, Row, Spinner, Statistic, Subtitle } from '../../style';
import ViewHelper from '../../view-helper';

interface Props {
    user: any;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    onDeleted: (userId: string) => void;
}

const ManageUserModal = (props: Props) => {
    const [userDetails, setUserDetails] = useState<UserDTO | undefined>(undefined);
    const [userListings, setUserListings] = useState<ListingDTO[] | undefined>(undefined);
    const [userAnalytics, setUserAnalytics] = useState<AnalyticsDTO | undefined>(undefined);
    const [userOrders, setUserOrders] = useState<OrderForAdminDTO[] | undefined>(undefined);
    const [loadingAnalytics, setLoadingAnalytics] = useState<boolean>(false);

    // For displaying admin controls if user has role to do so
    const [elevatedAdminUser] = useState<boolean>(true);
    const orders = useSelector(selectOrders);

    const breakpointColumnsObj = {
        default: 2,
        1400: 1,
    };

    useEffect(() => {
        if (orders && props.user?.id) {
            setUserOrders(orders.filter((order) => order.userId === props.user.id));
        }
    }, [orders, props.user?.id]);

    useEffect(() => {
        const handleFetchingListingDetails = async (email) => {
            await ApiListings.getAdminListingsForUser(email)
                .then((listings) => setUserListings(listings))
                .catch((error) => console.log('getting user listings error:', error));
        };

        if (props.user?.email) {
            handleFetchingListingDetails(props.user?.email);
        }

        const handleFetchingUserDetails = async (id) => {
            await ApiUser.getUserById(id)
                .then((user) => setUserDetails(user))
                .catch((error) => console.log('getting user by id error:', error));
        };

        if (props.user?.id) {
            handleFetchingUserDetails(props.user?.id);
        }

        if (elevatedAdminUser) {
            const handleFetchingUserAnalytics = async (email) => {
                setLoadingAnalytics(true);
                await ApiAnalytics.apiGetUserAnalytics(email)
                    .then((data) => setUserAnalytics(data))
                    .catch((error) => console.log('getting user by id error:', error))
                    .finally(() => {
                        setLoadingAnalytics(false);
                    });
            };

            if (props.user?.email) {
                handleFetchingUserAnalytics(props.user?.email);
            }
        }
    }, [elevatedAdminUser, props.user?.email, props.user?.id]);

    if (!props.user) {
        return <React.Fragment />;
    }

    const lastRecordedInteraction = userAnalytics?.list?.length && userAnalytics.list[0];

    return (
        <SoarModal
            width="98vw"
            title={props.user.name ? props.user.name : props.user.email}
            isOpen={props.isOpen}
            onClosed={() => props.setIsOpen(false)}
        >
            <ManageDetails user={userDetails} lastRecordedInteraction={lastRecordedInteraction} />
            {elevatedAdminUser ? <ManageUserDetailsAdmin user={props.user} onDeleted={props.onDeleted} /> : null}
            {elevatedAdminUser && userAnalytics?.list.length ? (
                <React.Fragment>
                    {loadingAnalytics ? (
                        <UserManagementCard>
                            <Spinner />
                        </UserManagementCard>
                    ) : (
                        <ManageUserAnalyticsAdmin userAnalytics={userAnalytics} />
                    )}
                </React.Fragment>
            ) : null}
            <Row>
                {userOrders?.length ? (
                    <Col>
                        <UserManagementCard>
                            <Subtitle>Total Orders</Subtitle>
                            <Statistic>{userOrders?.length.toLocaleString()}</Statistic>
                        </UserManagementCard>
                    </Col>
                ) : null}
                {userOrders?.length ? (
                    <Col>
                        <UserManagementCard>
                            <Subtitle>Area ordered</Subtitle>
                            <Statistic>
                                {ViewHelper.formatAreaInKm(userOrders?.reduce((a, b) => a + b.effectiveAreaInKm, 0))}
                            </Statistic>
                        </UserManagementCard>
                    </Col>
                ) : null}
                {userListings?.length ? (
                    <Col>
                        <UserManagementCard>
                            <Subtitle>Uploads</Subtitle>
                            <Statistic>{userListings?.length.toLocaleString()}</Statistic>
                        </UserManagementCard>
                    </Col>
                ) : null}
            </Row>
            <MasonryComponent breakpointCols={breakpointColumnsObj} columnClassName="masonry-grid_column">
                {userOrders?.length > 0 ? (
                    <UserManagementCard title="Orders">
                        <ManageViewOrders orders={userOrders} />
                    </UserManagementCard>
                ) : null}
                {userListings?.length ? (
                    <UserManagementCard title="Listings">
                        <ManageViewListings listings={userListings} />
                    </UserManagementCard>
                ) : null}
            </MasonryComponent>
        </SoarModal>
    );
};

export default ManageUserModal;

const MasonryComponent = styled(Masonry)`
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;

    .masonry-grid_column {
        //padding-left: 10px; /* gutter size */
    }
`;
