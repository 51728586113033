import Api from './api';
import { SupplierDTO } from './model-orders';

export default class ApiSupplier extends Api {
    public static getSuppliers(): Promise<SupplierDTO[]> {
        return this.axios.get(`v1/suppliers`).then((res) => {
            return res.data.list;
        });
    }
}
