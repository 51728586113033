import React, { ReactElement, useState } from 'react';
import { Button, ConfirmButtonContainer, ConfirmModalFooter, ConfirmModalText } from '../style';
import SoarModal from './soar-modal';

interface Props {
    title: string;
    message: string | ReactElement;
    onConfirm: () => void;
    color?: string;
    size?: string;
}

const ConfirmModal = (props: Props) => {
    const [modal, setModal] = useState(false);

    const toggle = (e) => {
        e.stopPropagation();
        setModal(!modal);
    };
    const { color, title, message, size } = props;
    return (
        <>
            <Button size={size || ''} color={color || ''} onClick={toggle}>
                {title}
            </Button>
            <SoarModal isOpen={modal} title={props.title} onClosed={() => setModal(false)} width="400px">
                <ConfirmModalText>{message}</ConfirmModalText>
                <ConfirmModalFooter>
                    <ConfirmButtonContainer>
                        <Button color={color || ''} onClick={props.onConfirm}>
                            {title}
                        </Button>
                        <Button color="warning" onClick={() => setModal(false)}>
                            Cancel
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>
        </>
    );
};

export default ConfirmModal;
