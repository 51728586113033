import React, { useEffect, useRef, useState } from 'react';
import ApiComments from '../../../api/api-comments';
import { CommentDTO } from '../../../api/model';
import { DatesFromTo } from '../../../api/model-api';
import InputDateRange from '../../Shared/Filters/DateRange/input-date-range';
import { Card, Col, FormGroup, Input, Label, Row, Spinner } from '../../style';
import ReportCommentDialog from '../Shared/report-comment-dialog';
import CommentsTable from './comments-table';

interface Props {
    selectedUser?: string;
}

const Overview = (props: Props) => {
    const [data, setData] = useState<CommentDTO[]>([]);
    const [filtered, setFiltered] = useState<CommentDTO[]>([]);
    const [userSearch, setUserSearch] = useState<string>('');
    const [dates, setDates] = useState<DatesFromTo>(undefined);
    const [loading, setLoading] = useState(false);
    const messageRef = useRef(null);
    const [commentIdToDelete, setCommentIdToDelete] = useState<string>(undefined);

    const refresh = async () => {
        setLoading(true);
        try {
            const res = await ApiComments.searchComments();
            setData(res);
            if (messageRef.current) {
                messageRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
            }
        } catch (err) {
            //setError(err.toString());
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if (props.selectedUser) {
            setUserSearch(props.selectedUser);
        }
    }, [props.selectedUser]);

    useEffect(() => {
        const from = dates?.from ? dates.from.getTime() / 1000 : undefined;
        const to = dates?.to ? dates.to.getTime() / 1000 : undefined;
        const fil = data
            .filter((cr) =>
                userSearch?.length > 0
                    ? cr.userEmail?.toLocaleLowerCase().includes(userSearch) ||
                      cr.userName?.toLocaleLowerCase().includes(userSearch)
                    : true
            )
            .filter((d) => (from ? d.createdAt >= from : true))
            .filter((d) => (to ? d.createdAt < to : true));
        setFiltered(fil);
    }, [data, userSearch, dates]);

    const onDeleteClick = async (commentId: string) => {
        setCommentIdToDelete(commentId);
    };

    const handleCloseDeleteCommentDialog = (deletedCommentId?: string) => {
        if (deletedCommentId) {
            setData(data.filter((c) => c.id !== deletedCommentId));
        }
        setCommentIdToDelete(undefined);
    };

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Dates</Label>
                            <InputDateRange fromTo={dates} onChange={setDates} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by name</Label>
                            <Input
                                title="Search by user"
                                placeholder="Search by user"
                                value={userSearch}
                                onChange={(e) => setUserSearch(e.target.value?.toLocaleLowerCase())}
                                name="user search"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {loading && (
                <Card height="600px">
                    <Spinner title={'Fetching data'} />
                </Card>
            )}
            {!loading && (
                <Card>
                    <CommentsTable
                        items={filtered}
                        onDeleteClick={onDeleteClick}
                        onUserClick={(search) => setUserSearch(search)}
                    />
                </Card>
            )}

            {commentIdToDelete && (
                <ReportCommentDialog
                    commentId={commentIdToDelete}
                    handleCloseReportCommentDialog={handleCloseDeleteCommentDialog}
                />
            )}
        </React.Fragment>
    );
};
export default Overview;
