import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ApiManagedProfiles, { ManagedProfileDTO } from '../../../api/api-managed-profiles';
import NotificationUtil from '../../../lib/notification-util';
import store from '../../../store/store';
import TextInputWithClear from '../../Shared/text-input-with-clear';
import { Card, Container, ErrorMessage, FormGroup, Spinner, Table, TableWrapper, Title } from '../../style';
import ProfileAddUpdateDialog from './Profile/profile-add-update-dialog';

const Profiles = () => {
    const [profiles, setProfiles] = useState<ManagedProfileDTO[]>([]);
    const [textFilter, setTextFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [profilesFiltered, setProfilesFiltered] = useState<ManagedProfileDTO[]>([]);

    useEffect(() => {
        loadProfiles();
    }, []);

    useEffect(() => {
        const textFilterRegEx = new RegExp(textFilter, 'img');
        setProfilesFiltered(
            profiles.filter(
                (profile) => textFilterRegEx.test(profile.user.businessName) || textFilterRegEx.test(profile.user.bio)
            )
        );
    }, [textFilter, profiles]);

    const loadProfiles = async () => {
        setProfiles([]);
        try {
            setLoading(true);
            const list = await ApiManagedProfiles.getAllProfiles();
            setProfiles(list);
        } catch (err) {
            NotificationUtil.showErrorNotificationApi(err);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const rowEventHandlers = {
        onClick: (e, row: ManagedProfileDTO) => {
            const target = e.target as HTMLElement;
            if (target.classList.contains('dropdown') || target.tagName === 'BUTTON' || target.tagName === 'I') {
                // Don't open new route when clicking the action button
            } else {
                store.dispatch(push(`/profiles/${row.user.userId}`));
            }
        },
    };

    const columns = [
        {
            dataField: 'user.businessName',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'publicLayers',
            text: 'Public Layers',
            sort: true,
            headerStyle: () => {
                return {
                    width: '120px',
                };
            },
        },
    ];

    return (
        <Container>
            <Title>Managed profiles</Title>

            <ProfileAddUpdateDialog />

            {loading ? (
                <Card>
                    <Spinner />
                </Card>
            ) : null}

            {error && (
                <Card>
                    <ErrorMessage>{error?.message || 'Something has gone wrong!'}</ErrorMessage>
                </Card>
            )}

            {profiles.length > 0 ? (
                <Card>
                    <FormGroup>
                        <TextInputWithClear
                            value={textFilter}
                            onChange={setTextFilter}
                            placeholder="Search by name or tagline"
                        />
                    </FormGroup>

                    <ToolkitProvider
                        keyField="user.userId"
                        data={profilesFiltered}
                        columns={columns}
                        search={{ searchFormatted: true }}
                        exportCSV={{
                            fileName: 'soar-satellite-orders.csv',
                        }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table {...props.baseProps} rowEvents={rowEventHandlers} />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </Card>
            ) : null}
        </Container>
    );
};

export default Profiles;
