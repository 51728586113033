export type SupplierCode = 'SKYM50' | 'CGSTL' | '21AT' | 'SIIS' | 'UNKNOWN';

export enum SupplierProduct {
    SKYM50_ARCHIVE = 'ARC',
    CGSTL_ARCHIVE = 'ARC',
}

export enum CollectAction {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    SCHEDULED = 'SCHEDULED',
    INFO = 'INFO',
}

export enum DeliveryFormat {
    NCC = 'NCC',
    MS = 'MS',
    RAW = 'RAW',
    ST = 'ST',
    PANMS = 'PANMS',
    OTHER = 'OTHER',
}

export type ProductOption = 'ARCHIVE' | 'STANDARD' | 'PRIORITY' | 'EMERGENCY' | 'MONITORING' | 'CUSTOM' | 'UNKNOWN';

export type SelectionOption = 'NORMAL' | 'RADIAL';

export enum FileType {
    METADATA = 'METADATA',
    MAP = 'MAP',
    OTHER = 'OTHER',
    HIDDEN = 'HIDDEN',
    UNKNOWN = 'UNKNOWN',
}

export enum OrderStatus {
    DRAFT = 'DRAFT',
    REVIEW_SOAR = 'REVIEW_SOAR',
    REVIEW_SUPPLIER = 'REVIEW_SUPPLIER',
    REJECTED = 'REJECTED',
    COLLECTION = 'COLLECTION',
    PROCESSING = 'PROCESSING',
    QA_SOAR = 'QA_SOAR',
    QA_FAILED = 'QA_FAILED',
    DELIVERED = 'DELIVERED',
    DELETED = 'DELETED',
}

export enum LogType {
    NOTE = 'NOTE',
    NOTE_REJECTED = 'NOTE_REJECTED',
    NOTE_PROVIDER = 'NOTE_PROVIDER',
    COLLECT_FAILED = 'COLLECT_FAILED',
    COLLECT_SUCCESS = 'COLLECT_SUCCESS',
    COLLECT_SCHEDULED = 'COLLECT_SCHEDULED',
    COLLECT_INFO = 'COLLECT_INFO',
    QA_INFO = 'QA_INFO',
    STATUS = 'STATUS',
    SYSTEM = 'SYSTEM',
}

export interface OrderFileDTO {
    id: number;
    fileSize: number;
    created: string;
    createdBy: string;
    minZoom: number;
    fileName: string;
    gdalError: string;
    gdalInfo: string;
    contentType: string;
    type: FileType;
    listingId?: number;
    title?: string;
    description?: string;
    gdalGeometryWKT?: string;
    tileUrl?: string;
}

// TODO update DTO when documentation available DP 9/8/2022
export interface OrderLogDTO {
    id: number;
    created: string;
    date: string;
    createdBy: string;
    message: string;
    filename?: string;
    attachedFile: OrderFileDTO;
    status: OrderStatus;
    previousStatus: OrderStatus;
    type: LogType;
}

export interface ArchiveSceneDTO {
    sceneId: string;
    acquisition: string;
    nadirAngle: number;
}

export interface OrderMetadataDTO {
    imageColour?: string;
    scenes: ArchiveSceneDTO[];
    deliveredInKm?: number;
    cloud?: number;
    projection?: string;
    datum: string;
    pixelResolution?: string;
}

export interface OrderPaymentDTO {
    pricePerKm: number;
    currency: string;
    amount: number;
    tax: number;
    paidByStripe: number;
    paidByVoucher: number;
    paidByOtherMethod: number;
    additionalTax: number;
    paidAt: string;
    refundedAt: string;
    invoiceUrl: string;
    receiptUrl: string;
    paymentCaptured: boolean;
    paymentAuthorized: boolean;
    payment: boolean;
    voucher: string;
    currencyRate: string;
    amountUsd: number;
    pricePerKmUsd: number;
    paidByStripeUsd: number;
    paidByVoucherUsd: number;
    paidByOtherMethodUsd: number;
    taxUsd: number;
}

// TODO looks like it may have changed a little compared to response
export interface OrderDTO {
    id: number;
    orderNumber: string;
    status: OrderStatus;
    supplierCode: SupplierCode;
    productId: number;
    productCode: string;
    productOption: ProductOption;
    deliveryFormat: DeliveryFormat;
    countryCode: string;
    areaInKm: number;
    archiveSelectedAreaInKm?: number;
    delayInSeconds: number;
    requestedDate: string;
    created: string;
    dueBy: string;
    deliveryFormatNote: string;
    geometryWKT: string;
    company: string;
    industry: string;
    metadata?: OrderMetadataDTO;
    aoiCountries: string[];
    archives: string[];
    domain?: string;
    domainId?: number;

    //order details
    logs: OrderLogDTO[];
    files: OrderFileDTO[];
    base64Kml: string;
}

export interface OrderForSupplierDTO extends OrderDTO {
    aoiCountriesCn: string[];
}

export interface OrderForAdminDTO extends OrderDTO {
    payment: OrderPaymentDTO;
    userId: string;
    userEmail: string;
    userName: string;
    domain: string;
    domainId: number;
    effectiveAreaInKm?: number;
}

export interface OrderCalculatedPriceDTO {
    areaInKm: number;
    effectiveAreaInKm: number;
    pricePerKm: number;
    addtionalTax?: number;
    minAreaInKm: number;
    totalPrice: number;
    totalTax?: number;
    currency?: string;
    maxAreaInKm: number;
    voucher?: string;
    voucherValue?: number;
    voucherValueUsed?: number;
}

export interface UpdateOrderBody {
    id: number;
    deliveryFormat?: string;
    deliveryFormatNote?: string;
    invoiceUrl?: string;
    archives?: string[];
    paidByOtherMethod?: number;
}

export interface DownloadLinkDTO {
    presignedUrl: string;
    filename: string;
}

export interface ArchiveDTO {
    selected?: boolean;
    id: string;
    supplier: string;
    product: string;
    satellite: string;
    geometryWKT: string;
    date: string;
    cloud: number;
    rollAngle?: string;
    previewUrl: string;
    resolutionInCm?: number;
    description?: string;
}

export interface ProductDTO {
    aoiSelectionStrategy: SelectionOption;
    code: string;
    description: string;
    maxAreaInKm: number;
    minAreaInKm: number;
    minLengthOrWidthInKm: number;
    name: string;
    option: ProductOption;
    pricePerKm: number;
    resolutionInCm: number;
    restrictedCountries: string[];
    supplierCode: SupplierCode;
    format: string;
}

export interface SupplierDTO {
    code: SupplierCode;
    description: string;
    instruments: [];
    name: string;
    userId: string;
    products: ProductDTO[];
}

export interface CustomerDTO {
    email: string;
    countryCode: string;
    industry: string;
    company: string;
    subdomain: string;
    deliveryFormatNote: string;
}
