import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import store from '../../../../store/store';
import {
    Button,
    Divider,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner,
    Subtitle,
    Table,
    TableWrapper,
} from '../../../style';
import ApiSTAC, { STACCatalogFullDTO, STACItemDTO } from '../../../../api/api-stac';
import { useDispatch } from 'react-redux';
import { actionAddUpdateSTACItems } from '../../../../store/ExternalMapServicesStore/actions';

interface StacCatalogItemChildrenProps {
    stac: STACCatalogFullDTO;
}

const StacCatalogItems = (props: StacCatalogItemChildrenProps) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [items, setItems] = useState<STACItemDTO[]>([]);
    const [activeItems, setActiveItems] = useState<STACItemDTO[]>([]);
    const [inactiveItems, setInactiveItems] = useState<STACItemDTO[]>([]);
    const [filteredActiveItems, setFilteredActiveItems] = useState<STACItemDTO[]>([]);
    const [filteredInactiveItems, setFilteredInactiveItems] = useState<STACItemDTO[]>([]);
    const [titleFilter, setTitleFilter] = useState<string>('');

    useEffect(() => {
        if (props.stac) {
            setIsLoading(true);
            ApiSTAC.getSTACCatalogItems(props.stac.id).then((items) => {
                setItems(items);
                setIsLoading(false);
            });
        }
    }, [props.stac]);

    useEffect(() => {
        setInactiveItems(items.filter((t) => t.public === false));
        setActiveItems(items.filter((t) => t.public === true));
        setFilteredActiveItems(items.filter((t) => t.public === true));
        setFilteredInactiveItems(items.filter((t) => t.public === false));
    }, [items]);

    useEffect(() => {
        dispatch(
            actionAddUpdateSTACItems(
                props.stac.id,
                items.map((t) => t.id)
            )
        );
    }, [items, props.stac, dispatch]);

    useEffect(() => {
        const filterCatalogs = (catalogs: STACItemDTO[]) => {
            if (!titleFilter || titleFilter === 'Select Filter') {
                return catalogs;
            }
            const lowerCaseFilter = titleFilter.toLowerCase();
            return catalogs.filter((catalog) => catalog.title?.toLowerCase().includes(lowerCaseFilter));
        };

        setFilteredActiveItems(filterCatalogs(activeItems));
        setFilteredInactiveItems(filterCatalogs(inactiveItems));
    }, [activeItems, inactiveItems, titleFilter]);

    const sortableHeader = (column) => {
        return (
            <React.Fragment>
                {column.text}
                <SortIcon className="fa fa-sort" />
            </React.Fragment>
        );
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerFormatter: sortableHeader,
            headerStyle: () => {
                return {
                    width: '40px',
                };
            },
        },
        {
            dataField: 'title',
            text: 'Item title',
            sort: true,
            headerFormatter: sortableHeader,
            formatter: (_, row: STACItemDTO) => {
                return row.title;
            },
        },
        {
            dataField: 'map',
            text: 'Map',
            sort: true,
            headerFormatter: sortableHeader,
            formatter: (_, row: STACItemDTO) => {
                if (row.map) {
                    return <Checkmark className="fa fa-check" />;
                } else {
                    return <React.Fragment />;
                }
            },
        },
        {
            dataField: 'public',
            text: 'Available in Soar',
            headerStyle: () => {
                return {
                    width: '140px',
                };
            },
            formatter: (_, row: STACItemDTO) => {
                if (row.public) {
                    return <Checkmark className="fa fa-check" />;
                } else {
                    return <React.Fragment />;
                }
            },
        },
    ];

    const prepareCatalogChildLink = (child: STACItemDTO): string => {
        return `/stac/${props.stac.id}/${child.id}`;
    };

    const onClickRow = {
        onClick: (_e, row: STACItemDTO) => {
            if (row) store.dispatch(push(prepareCatalogChildLink(row)));
        },
        onAuxClick: (_e, row: STACItemDTO) => {
            const win = window.open(prepareCatalogChildLink(row), '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <Row>
                <FormGroup>
                    <Label>Filter by Title</Label>
                    <InputGroup>
                        <Input
                            value={titleFilter}
                            placeholder="Search by title..."
                            onChange={(e) => setTitleFilter(e.target.value)}
                        />
                        {titleFilter && (
                            <ClearFilter color="link" onClick={() => setTitleFilter('')}>
                                <i className="fa fa-times" />
                            </ClearFilter>
                        )}
                    </InputGroup>
                </FormGroup>
            </Row>
            {activeItems.length > 0 ? (
                <React.Fragment>
                    <Subtitle>
                        Catalog items available in Soar ({activeItems.length} total, displaying{' '}
                        {filteredActiveItems.length})
                    </Subtitle>
                    <ToolkitProvider
                        keyField="id"
                        data={filteredActiveItems}
                        columns={columns}
                        filterFactory={filterFactory()}
                        search={{ searchFormatted: true }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table {...props.baseProps} rowEvents={onClickRow} />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}

            {activeItems.length > 0 && inactiveItems.length > 0 ? <Divider /> : null}

            {inactiveItems.length > 0 ? (
                <React.Fragment>
                    <Subtitle>
                        Catalog items not available in Soar ({inactiveItems.length} total), displaying{' '}
                        {filteredInactiveItems.length}
                    </Subtitle>
                    <ToolkitProvider
                        keyField="id"
                        data={filteredInactiveItems}
                        columns={columns}
                        filterFactory={filterFactory()}
                        search={{ searchFormatted: true }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table {...props.baseProps} rowEvents={onClickRow} />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default StacCatalogItems;

const Checkmark = styled.i`
    color: green;
`;

const ClearFilter = styled(Button)`
    background-color: white !important;
    border: none !important;
    color: black;

    :hover {
        background-color: white !important;
        border: none !important;
        box-shadow: none !important;
    }

    i {
        color: black;
    }
`;

const SortIcon = styled.i`
    margin-left: 5px;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.87);
`;
