import * as Sentry from '@sentry/browser';
import React from 'react';
import styled from 'styled-components';
import ApiOrders from '../../api/api-orders';
import ApiOrdersSupplier from '../../api/api-orders-supplier';
import { DownloadLinkDTO, FileType, OrderFileDTO } from '../../api/model-orders';
import IconButton from '../Shared/icon-button';
import { Spinner } from '../style';
import ViewHelper from '../view-helper';

const TYPES_TO_BE_HIDDEN = [FileType.MAP, FileType.OTHER, FileType.UNKNOWN];

interface Props {
    orderId: number;
    orderFile: OrderFileDTO;
    isAdmin?: boolean;
    isSoarOrder?: boolean;
    invalidateOrder: () => void;
}

const OrderFileRow = (props: Props) => {
    const { orderFile, orderId, isAdmin } = props;
    const [isProcessing, setIsProcessing] = React.useState(false);

    const downloadFile = async () => {
        setIsProcessing(true);
        try {
            let link: DownloadLinkDTO;
            if (isAdmin) {
                link = await ApiOrders.getOrderDownloadLinkFile(orderId, orderFile.id);
            } else {
                link = await ApiOrdersSupplier.getDownloadLinkFile(orderId, orderFile.id);
            }
            window.open(link.presignedUrl, '_blank').focus();
        } catch (err) {
            Sentry.captureException(err.toString());
        } finally {
            setIsProcessing(false);
        }
    };

    const hideFile = async () => {
        setIsProcessing(true);
        try {
            await ApiOrders.hideOrderFile(orderId, orderFile.id);
            props.invalidateOrder();
        } catch (err) {
            // setError(err)
        } finally {
            setIsProcessing(false);
        }
    };

    const handleConvertToListing = async () => {
        setIsProcessing(true);
        try {
            await ApiOrders.convertFileToListing(orderId, orderFile.id);
            props.invalidateOrder();
        } catch (err) {
            // setError(err)
        } finally {
            setIsProcessing(false);
        }
    };

    const actionButtons = () => (
        <ActionsDiv>
            <IconButton faIcon="fa-download" onClick={() => downloadFile()} />
            {isAdmin && TYPES_TO_BE_HIDDEN.includes(orderFile.type) && (
                <IconButton
                    faIcon="fa-minus-circle"
                    color="red"
                    title="Hide file for user"
                    onClick={() => hideFile()}
                />
            )}
            {isAdmin && orderFile.gdalGeometryWKT && props.isSoarOrder && (
                <IconButton faIcon="fa-map" color="green" title="Create map" onClick={() => handleConvertToListing()} />
            )}
        </ActionsDiv>
    );

    return (
        <LogTable key={orderFile.id}>
            <LogTableItem title={ViewHelper.formatDateTimeFromISO(orderFile.created)}>
                {ViewHelper.formatDateFromISO(orderFile.created)}
            </LogTableItem>
            <LogTableItem>{ViewHelper.formatFilename(orderFile.fileName)}</LogTableItem>
            <LogTableItem>{ViewHelper.formatBytes(orderFile.fileSize)}</LogTableItem>
            <LogTableItem>
                {orderFile.type}
                <span>{isProcessing ? <Spinner /> : actionButtons()}</span>
            </LogTableItem>
        </LogTable>
    );
};

export default OrderFileRow;

interface LogTableItemProps {
    width: string;
}

const LogTable = styled.tr`
    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

const LogTableItem = styled.td<LogTableItemProps>`
    max-width: ${(props) => (props.width ? props.width : 'auto')};
    overflow-wrap: anywhere;

    span {
        float: right;
    }
`;

const ActionsDiv = styled.div`
    width: 82px;
    display: flex;
    justify-content: flex-end;
`;
