import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiAdmin from '../../api/api-admin';
import ApiListings from '../../api/api-listings';
import { ContactUsDTO, ListingDTO, UserDTO } from '../../api/model';
import ListingDetails from '../Shared/listing-details';
import SingleFeatureMap from '../Shared/single-feature-map';
import { Button, ButtonGroup, Col, Container, Label, Row } from '../style';

interface Props {
    item: ContactUsDTO;
    itemHandled: (id: string) => void;
}

const ContactUsQuery = (props: Props) => {
    const { item } = props;
    const [handledByUser, setHandledByUser] = React.useState<UserDTO>();
    const [listing, setListing] = useState<ListingDTO>(undefined);

    useEffect(() => {
        if (item.listingId > 0) {
            ApiListings.getListing(item.listingId)
                .then((res) => {
                    res.userEmail = res.userEmail || item.email;
                    res.userName = res.userName || item.name;
                    setListing(res);
                })
                .catch((_err) => console.error);
        }
    }, [item]);

    useEffect(() => {
        if (props.itemHandled && item.handledBy) {
            ApiAdmin.getUserById(item.handledBy)
                .then((_handledByUser: UserDTO) => {
                    setHandledByUser(_handledByUser);
                })
                .catch((_err) => console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.itemHandled]);

    return (
        <Container>
            <Row>
                <Col md={item.geometryWKT ? 6 : 12}>
                    <>
                        <Row>
                            <Col xs={2}>
                                <Label>Name:</Label>
                            </Col>
                            <Col xs={10}>{item.name}</Col>
                        </Row>

                        {item.industry && (
                            <Row>
                                <Col xs={2}>
                                    <Label>Industry:</Label>
                                </Col>
                                <Col xs={10}>{item.industry}</Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={2}>
                                <Label>Message:</Label>
                            </Col>
                            <Col xs={10}>{item.message}</Col>
                        </Row>

                        {item.countryCode && (
                            <Row>
                                <Col xs={2}>
                                    <Label>Country:</Label>
                                </Col>
                                <Col xs={10}>{item.countryCode}</Col>
                            </Row>
                        )}
                    </>
                </Col>
                {item.geometryWKT?.length > 0 && (
                    <Col md={6}>
                        <MapContainer>
                            <SingleFeatureMap
                                locationWKT={item.geometryWKT}
                                base64Kml={item.base64Kml}
                                downloadButtons={true}
                            />
                        </MapContainer>
                    </Col>
                )}
            </Row>

            {listing && (
                <Row>
                    <Col xs={12}>
                        <ListingDetails listing={listing} />
                    </Col>
                </Row>
            )}

            <Row>
                <Col xs={12}>
                    <ButtonPanel>
                        {handledByUser && (
                            <Label>
                                Handled by: {handledByUser.name} ({handledByUser.email})
                            </Label>
                        )}
                        {!item.handledAt && !handledByUser && (
                            <Button color="primary" onClick={() => props.itemHandled(props.item.id)}>
                                Assign this enquiry to me
                            </Button>
                        )}
                    </ButtonPanel>
                </Col>
            </Row>
        </Container>
    );
};

export default ContactUsQuery;

const ButtonPanel = styled(ButtonGroup)`
    margin-top: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const MapContainer = styled.div`
    height: 220px;
    .single-feature-map {
        height: 200px;
    }
`;
