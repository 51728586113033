import Api from './api';
import { UploadCredentialsDTO } from './model';
import { CollectAction, DownloadLinkDTO, OrderForSupplierDTO, OrderMetadataDTO } from './model-orders';

export default class ApiOrdersSupplier extends Api {
    public static getOrders(): Promise<OrderForSupplierDTO[]> {
        return this.axios
            .get(`/v1/supplier/orders`)
            .then((res) => {
                return res.data.list;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getOrderById(id: number): Promise<OrderForSupplierDTO> {
        return this.axios
            .get(`/v1/supplier/orders/${id}`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static rejectOrder(id: number, note: string): Promise<OrderForSupplierDTO> {
        return this.axios
            .post(`/v1/supplier/orders/${id}/reject`, { note })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static orderCompleted(id: number): Promise<OrderForSupplierDTO> {
        return this.axios
            .post(`/v1/supplier/orders/${id}/qa`, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static approveOrder(id: number): Promise<OrderForSupplierDTO> {
        return this.axios
            .post(`/v1/supplier/orders/${id}/approve`, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getDownloadLinkLog(id: number, logId: number): Promise<DownloadLinkDTO> {
        return this.axios
            .get(`/v1/supplier/orders/${id}/logs/${logId}download`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getDownloadLinkFile(id: number, fileId: number): Promise<DownloadLinkDTO> {
        return this.axios
            .get(`/v1/supplier/orders/${id}/files/${fileId}/download`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static collectInfoLog(
        id: number,
        actionType: CollectAction,
        content?: string,
        fileName?: string,
        date?: string
    ): Promise<UploadCredentialsDTO> {
        const data = {
            actionType,
            content,
            fileName,
            date,
        };
        return this.axios
            .post(`/v1/supplier/orders/${id}/info`, data)
            .then((res) => {
                return res.data.uploadDetails;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static getUploadCredentials(id: number): Promise<UploadCredentialsDTO> {
        return this.axios
            .get(`/v1/supplier/orders/${id}/upload`)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static updateMetadata(id: number, metadata: OrderMetadataDTO): Promise<OrderForSupplierDTO> {
        return this.axios
            .post(`/v1/supplier/orders/${id}/metadata`, metadata)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    }
}
