import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { multiSelectFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { DmtTaskDTO } from '../../api/model';
import ViewHelper from '../view-helper';
import DmtTask from './dmt-task';

interface Props {
    items: DmtTaskDTO[];
    refreshTasks: () => void;
}

const selectOptions = {
    uploading: 'Uploading',
    processing: 'Processing',
    quality_check: 'Awaiting QA',
    completed: 'Completed',
    error: 'Error',
    refunded: 'Refunded',
};

const TasksTable: React.FC<Props> = (props) => {
    const { items } = props;
    console.log('tasks-table: ', items);
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            style: { width: '140px' },
            formatter: (cell) => ViewHelper.formatDate(cell),
        },
        {
            dataField: 'orderNumber',
            text: 'Order',
            sort: true,
            filter: textFilter(),
            formatter: (cell, row: DmtTaskDTO) => (
                <a href={`/dmt/${row.id}`} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
        },
        {
            text: 'Status',
            dataField: 'status',
            formatter: (cell) => selectOptions[cell],
            style: { width: '250px' },
            filter: multiSelectFilter({
                options: selectOptions,
                defaultValue: ['processing', 'quality_check'],
            }),
        },
        {
            dataField: 'userEmail',
            text: 'Email',
            sort: true,
            filter: textFilter(),
            formatter: (_cell, row: DmtTaskDTO) => row.userEmail,
        },
        {
            dataField: 'totalImages',
            text: 'Total',
            sort: true,
            formatter: (_cell, row: DmtTaskDTO) => <>{row.metadata?.totalImages}</>,
        },
        {
            dataField: 'totalPrice',
            text: 'Paid',
            sort: true,
            formatter: (_cell, row: DmtTaskDTO) => ViewHelper.formatPriceFromCents(row.totalPrice, row.currency),
        },
        {
            dataField: 'voucher',
            text: 'Voucher',
            formatter: (cell, row: DmtTaskDTO) =>
                cell ? `${row.voucher} [${ViewHelper.formatUSDPriceFromCents(row.voucherValueUsed)}]` : '',
            filter: textFilter(),
        },
    ];

    const expandRow = {
        renderer: (t: DmtTaskDTO, _rowIndex) => {
            return <DmtTask key={`task_${t.id}`} task={t} refreshTasks={props.refreshTasks} />;
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
    };

    const paginationOptions = {
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: 'All',
                value: items.length,
            },
        ],
    };

    return (
        <div className="task-table">
            <ToolkitProvider keyField="id" data={items} columns={columns}>
                {(props) => (
                    <div className="table">
                        <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory(paginationOptions)}
                            expandRow={expandRow}
                            filter={filterFactory()}
                        />
                    </div>
                )}
            </ToolkitProvider>
        </div>
    );
};

export default TasksTable;
