import React, { useState } from 'react';
import ApiOrders from '../../api/api-orders';
import { FileType, OrderForAdminDTO, OrderStatus } from '../../api/model-orders';
import ButtonsPanel from '../Shared/buttons-panel';
import ErrorPanel from '../Shared/error-panel';
import ViewHelper from '../view-helper';
import styled from 'styled-components';
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu as StyledDropdownMenu,
    DropdownToggle as StyledDropdownToggle,
    FormGroup,
    Input,
    Label,
    Panel,
    Row,
    Spinner,
    UncontrolledDropdown as StyledUncontrolledDropdown,
} from '../style';
import SoarModal from '../Shared/soar-modal';

interface OrderControlsProps {
    order: OrderForAdminDTO;
    invalidate: () => void;
}

const STATUSES_FOR_UPLOAD = [OrderStatus.COLLECTION, OrderStatus.QA_SOAR, OrderStatus.QA_FAILED];

const OrderControls = (props: OrderControlsProps) => {
    const { order } = props;
    const { payment } = order;

    const [showConfirm, setShowConfirm] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [showApprove, setShowApprove] = useState(false);
    const [showDeliver, setShowDeliver] = useState(false);
    const [showQAFailed, setShowQAFailed] = useState(false);

    const [note, setNote] = useState('');
    const [noteForProvider, setNoteForProvider] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [showRejectedModal, setShowRejectedModal] = useState(false);
    const [showSubmittedModal, setShowSubmittedModal] = useState(false);
    const [showDeliveredModal, setShowDeliveredModal] = useState(false);
    const [createInvoice, setCreateInvoice] = useState(true);

    const handleClickedReject = () => {
        setShowReject(true);
    };

    const handleClickedApprove = () => {
        setShowApprove(true);
    };

    const handleConfirm = async () => {
        setIsProcessing(true);
        try {
            await ApiOrders.confirmOrder(order.id, createInvoice);
            setShowConfirm(false);
            props.invalidate();
        } catch (err) {
            setError(err);
        }
        setIsProcessing(false);
    };

    const handleReject = async () => {
        if (note?.length < 1) {
            alert('Rejection note is required');
            return;
        }
        setIsProcessing(true);
        try {
            await ApiOrders.updateOrderStatus(order.id, OrderStatus.REJECTED, note);
            setShowRejectedModal(true);
            setShowReject(false);
            props.invalidate();
        } catch (err) {
            setError(err);
        }
        setIsProcessing(false);
    };

    const handleSubmitToSupplier = async () => {
        setIsProcessing(true);
        try {
            await ApiOrders.updateOrderStatus(order.id, OrderStatus.REVIEW_SUPPLIER, noteForProvider);
            setShowSubmittedModal(true);
            setShowApprove(false);
        } catch (err) {
            setError(err);
        }
        setIsProcessing(false);
    };

    const handleQAFailed = async () => {
        setIsProcessing(true);
        try {
            await ApiOrders.updateOrderStatus(order.id, OrderStatus.QA_FAILED, noteForProvider);
            setShowQAFailed(false);
            props.invalidate();
        } catch (err) {
            setError(err);
        }
        setIsProcessing(false);
    };

    const deliverToClient = async () => {
        setIsProcessing(true);
        try {
            await ApiOrders.updateOrderStatus(order.id, OrderStatus.DELIVERED, note);
            let updated = await ApiOrders.getOrderById(order.id);
            while (updated.status !== OrderStatus.DELIVERED) {
                await sleep(2000);
                updated = await ApiOrders.getOrderById(order.id);
            }
            setShowDeliveredModal(true);
        } catch (err) {
            setError(err);
        }
        setIsProcessing(false);
        setShowDeliver(false);
    };

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const updateStatus = async (status: OrderStatus) => {
        if (status === OrderStatus.REJECTED) {
            setShowReject(true);
        } else if (status === OrderStatus.DELIVERED) {
            setShowDeliver(true);
        } else if (status === OrderStatus.QA_FAILED) {
            setShowQAFailed(true);
        } else {
            try {
                if (props.order.id) {
                    await ApiOrders.updateOrderStatus(props.order.id, status);
                    props.invalidate();
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    if (error) {
        console.log(error);
        setError(undefined);
    }

    const formatPrice = (price: number, priceUsd: number): string => {
        let result = ViewHelper.formatPriceFromCents(price, payment.currency);
        if (payment.currency !== 'USD') {
            result = `${result} (${ViewHelper.formatUSDPriceFromCents(priceUsd)})`;
        }
        return result;
    };

    const readyForDelivery =
        STATUSES_FOR_UPLOAD.includes(order.status) &&
        order.files?.length > 1 &&
        order.files.filter((f) => f.type === FileType.METADATA).length > 0 &&
        order.files.filter((f) => f.type === FileType.MAP || f.type == FileType.OTHER).length > 0;
    return (
        <>
            <Row>
                <Col>
                    <ButtonsPanel>
                        {order.status === OrderStatus.DRAFT && (
                            <Button onClick={() => setShowConfirm(true)}>Confirm created order</Button>
                        )}
                        {order.status === OrderStatus.REVIEW_SOAR && (
                            <>
                                <Button onClick={handleClickedApprove}>Approve</Button>
                                <Button color="danger" onClick={handleClickedReject}>
                                    Reject
                                </Button>
                            </>
                        )}
                        {order.status === OrderStatus.QA_SOAR && (
                            <Button color="danger" onClick={() => setShowQAFailed(true)}>
                                QA failed
                            </Button>
                        )}
                        {readyForDelivery && (
                            <Button onClick={() => setShowDeliver(true)}>Deliver file to Client</Button>
                        )}
                    </ButtonsPanel>
                    {order.status !== OrderStatus.DRAFT && (
                        <ButtonsPanel>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>Force status change</DropdownToggle>
                                <DropdownMenu dark>
                                    {props.order.logs &&
                                        Object.keys(OrderStatus)
                                            .filter((status) => status !== OrderStatus.DELETED)
                                            .map((status: OrderStatus, index: number) => {
                                                return (
                                                    <DropdownItem
                                                        key={`status-buton-${index}`}
                                                        onClick={() => updateStatus(status)}
                                                    >
                                                        {ViewHelper.STATUS_LABELS[status]}
                                                    </DropdownItem>
                                                );
                                            })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ButtonsPanel>
                    )}
                </Col>
            </Row>
            {isProcessing && <Spinner title="Processing" />}
            {/* Show confirm dialog */}
            <SoarModal
                title="Confirm order and display order to user"
                width="600px"
                isOpen={showConfirm}
                onClosed={() => setShowConfirm(false)}
            >
                <ItemRow>
                    <Label for="create-invoice">Create Invoice</Label>
                    <CheckboxInvoice
                        type="checkbox"
                        checked={createInvoice}
                        onChange={() => setCreateInvoice(!createInvoice)}
                    />
                </ItemRow>

                <Panel>
                    <Button color="warning" onClick={() => setShowConfirm(false)}>
                        Back
                    </Button>
                    <Button onClick={handleConfirm}>Confirm order</Button>
                </Panel>
            </SoarModal>
            {/* Show approve dialog */}
            <SoarModal
                title="Approve order and send to supplier"
                isOpen={showApprove}
                width="600px"
                onClosed={() => setShowApprove(false)}
            >
                <FormGroup>
                    <Label for="note">Note for supplier</Label>
                    <Input
                        value={noteForProvider}
                        type="textarea"
                        name="noteForProvider"
                        id="noteFroProvider"
                        placeholder="Leave a message for this order ie(custom requirements)"
                        onChange={(e) => setNoteForProvider(e.target.value)}
                        rows={6}
                    />
                </FormGroup>
                <Panel>
                    <Button color="warning" onClick={() => setShowApprove(false)}>
                        Back
                    </Button>
                    <Button onClick={handleSubmitToSupplier}>Submit Order To Supplier</Button>
                </Panel>
            </SoarModal>
            {/* Show QA failed */}
            <SoarModal title="QA Failed" width="600px" isOpen={showQAFailed} onClosed={() => setShowQAFailed(false)}>
                <FormGroup>
                    <Label for="note">Add note why QA failed</Label>
                    <Input
                        value={noteForProvider}
                        type="textarea"
                        name="note"
                        id="note"
                        placeholder="Why did it fail QA and been returned to the provider?"
                        onChange={(e) => setNoteForProvider(e.target.value)}
                        rows={6}
                    />
                </FormGroup>
                <Panel>
                    <Button onClick={() => setShowQAFailed(false)}>Back</Button>
                    <Button color="danger" onClick={handleQAFailed}>
                        Return back to provider
                    </Button>
                </Panel>
            </SoarModal>
            {/* Show deliver dialog */}
            <SoarModal
                title="Deliver file to Client"
                width="600px"
                isOpen={showDeliver}
                onClosed={() => setShowDeliver(false)}
            >
                <Col>
                    {!readyForDelivery && (
                        <ErrorPanel>{error ? error.message : 'Metadata or deliverable file is missing'}</ErrorPanel>
                    )}
                    <FormGroup>
                        <Label for="note">Leave Note (Optional)</Label>
                        <Input
                            value={note}
                            type="textarea"
                            name="note"
                            id="note"
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </FormGroup>
                </Col>

                <Panel>
                    <Button color="warning" onClick={() => setShowDeliver(false)}>
                        Back
                    </Button>
                    <Button onClick={deliverToClient}>Deliver imagery!</Button>
                </Panel>
            </SoarModal>
            {/* Show reject dialog */}
            <SoarModal title="Reject order" width="600px" isOpen={showReject} onClosed={() => setShowReject(false)}>
                <Col>
                    {payment.paidByStripe > 0 && !payment.paymentCaptured && (
                        <ErrorPanel>
                            Uncaptured payment {formatPrice(payment.paidByStripe, payment.paidByStripeUsd)} will be
                            refunded.
                        </ErrorPanel>
                    )}
                    <FormGroup>
                        <Label for="note">Rejection Note</Label>
                        <Input
                            value={note}
                            type="textarea"
                            name="note"
                            id="note"
                            placeholder="Notify user why this order was rejected"
                            onChange={(e) => setNote(e.target.value)}
                            rows={9}
                        />
                    </FormGroup>
                </Col>

                <Panel>
                    <Button color="warning" onClick={() => setShowReject(false)}>
                        Back
                    </Button>
                    <Button color="danger" onClick={handleReject}>
                        Reject & notify
                    </Button>
                </Panel>
            </SoarModal>
            {/* Show delivered dialog */}
            <SoarModal
                title="Order delivered to client"
                isOpen={showDeliveredModal}
                onClosed={() => setShowDeliveredModal(false)}
                width="600px"
            >
                <OrderNotice>The client has received an email informing them the order is complete.</OrderNotice>
                <Panel>
                    <Button
                        onClick={() => {
                            setShowDeliveredModal(false);
                            props.invalidate();
                        }}
                    >
                        Ok
                    </Button>
                </Panel>
            </SoarModal>
            {/* Show submitting dialog */}
            <SoarModal
                title="Order Submitted to Supplier"
                width="600px"
                isOpen={showSubmittedModal}
                onClosed={() => setShowSubmittedModal(false)}
            >
                <OrderNotice>An email has been sent to Supplier</OrderNotice>
                <Panel>
                    <Button
                        onClick={() => {
                            setShowSubmittedModal(false);
                            props.invalidate();
                        }}
                    >
                        Ok
                    </Button>
                </Panel>
            </SoarModal>

            <SoarModal
                title="Order rejected"
                width="600px"
                isOpen={showRejectedModal}
                onClosed={() => setShowRejectedModal(false)}
            >
                <OrderNotice>The order was rejected and the users payment was refunded</OrderNotice>
                <Panel>
                    <Button
                        onClick={() => {
                            setShowRejectedModal(false);
                            props.invalidate();
                        }}
                    >
                        Ok
                    </Button>
                </Panel>
            </SoarModal>
        </>
    );
};

export default OrderControls;

const ItemRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;

    .form-label {
        margin-bottom: 0px;
        font-size: 16px;
        color: white;
    }
`;

const CheckboxInvoice = styled(Input)`
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    margin-left: 10px;
`;

const UncontrolledDropdown = styled(StyledUncontrolledDropdown)`
    margin-top: 8px;
    padding: 0 8px;
    cursor: pointer;
`;

const DropdownMenu = styled(StyledDropdownMenu)`
    margin: 5px 0px !important;
    width: 88%;
`;

const DropdownToggle = styled(StyledDropdownToggle)`
    :focus {
        box-shadow: none !important;
    }
`;

const OrderNotice = styled.div`
    color: white;
    font-size: 16px;
    padding: 10px 0px;
`;
