import { push } from 'connected-react-router';
import React, { useState } from 'react';
import ApiManagedProfiles from '../../../../api/api-managed-profiles';
import store from '../../../../store/store';
import { Button, Col, Form, Input, InputGroup, InvisibleCard } from '../../../style';

const ProfileAddUpdateDialog = () => {
    const [name, setName] = useState<string>('');

    const onSubmit = async (e) => {
        e.preventDefault();

        const profile = await ApiManagedProfiles.createProfile(name);
        store.dispatch(push(`/profiles/${profile.user.userId}`));
    };

    return (
        <InvisibleCard>
            <Col lg={6} md={10} sm={12}>
                <Form onSubmit={onSubmit}>
                    <InputGroup>
                        <Input
                            type="text"
                            placeholder="Enter name for new profile"
                            required={true}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Button type="submit">Create new profile</Button>
                    </InputGroup>
                </Form>
            </Col>
        </InvisibleCard>
    );
};

export default ProfileAddUpdateDialog;
