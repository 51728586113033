import React from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { OrderForSupplierDTO } from '../../../api/model-orders';
import { Button, Table, TableWrapper } from '../../style';
import ViewHelper from '../../view-helper';

interface Props {
    items: OrderForSupplierDTO[];
}

const SupplierOrdersTable = (props: Props) => {
    const { items } = props;

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'orderNumber',
            text: 'Order',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            style: { width: '140px' },
            formatter: (cell) => ViewHelper.formatDate(cell),
            csvFormatter: (cell) => ViewHelper.formatDateWithTime(cell),
        },
        {
            dataField: 'productOption',
            formatter: (cell) => ViewHelper.PRODUCT_OPTION_LABELS[cell],
            text: 'Type',
        },
        {
            text: 'Deliverable',
            dataField: 'deliverableType',
            formatter: (cell) => ViewHelper.DELIVERY_FORMATS_LABELS[cell],
            hidden: true,
        },
        {
            text: 'Status',
            dataField: 'status',
            formatter: (cell) => ViewHelper.STATUS_LABELS[cell],
        },
        {
            dataField: 'delayInSeconds',
            text: 'Delay',
            sort: true,
            formatter: (cell) => (cell && cell > 0 ? ViewHelper.formatDuration(cell * 1000) : ''),
            csvExport: false,
        },
        {
            dataField: 'effectiveAreaInKm',
            text: 'Area',
            sort: true,
            formatter: (cell) => `${cell}km²`,
        },
    ];

    const rowEvent = {
        onClick: (_e, row, _rowIndex) => {
            window.open(`/supplier/orders/${row.id}`, '_blank');
        },
    };

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <Button color="primary" onClick={handleClick}>
                <i className="fa fa-download" />
                &nbsp;Export as CSV
            </Button>
        );
    };
    return (
        <ToolkitProvider
            keyField="id"
            data={items}
            columns={columns}
            search={{ searchFormatted: true }}
            exportCSV={{
                fileName: 'soar-satellite-orders.csv',
            }}
        >
            {(props) => (
                <React.Fragment>
                    <TableWrapper>
                        <Table {...props.baseProps} rowEvents={rowEvent} filter={filterFactory()} />
                    </TableWrapper>
                    <Panel>
                        <MyExportCSV {...props.csvProps}>Export CSV</MyExportCSV>
                    </Panel>
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
};

export default SupplierOrdersTable;

const Panel = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    & button {
        :not(:first-child) {
            margin-left: 10px;
        }
    }
    & div {
        margin: 5px;
    }
    & a {
        margin: 5px;
    }
`;
