import React from 'react';
import styled from 'styled-components';
import { OrderForSupplierDTO } from '../../../api/model-orders';
import { Label } from '../../style';
import ViewHelper from '../../view-helper';
import OrderStatusBadge from '../order-status-badge';

interface Props {
    order: OrderForSupplierDTO;
}

const SupplierOrderCard = (props: Props) => {
    const onClick = () => {
        window.open(`/orders/${props.order.id}`, '_blank');
    };

    return (
        <div onClick={onClick}>
            <SupplierCardDetails>
                <span>ID: {props.order.id}</span>
                <span>Type: {props.order.deliveryFormat}</span>
                <OrderStatusBadge status={props.order.status} />
            </SupplierCardDetails>
            <SupplierCardDetails>O/N: {props.order.orderNumber}</SupplierCardDetails>
            <SupplierCardDetails>
                <span>
                    {ViewHelper.formatSupplierAndProductOption(props.order.supplierCode, props.order.productOption)}
                </span>
                {ViewHelper.formatDateFromISO(props.order.created)}
            </SupplierCardDetails>
        </div>
    );
};

export default SupplierOrderCard;

const SupplierCardDetails = styled(Label)`
    line-height: 35px;
    font-size: 14px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
