import Axios, { AxiosInstance } from 'axios';

import Config from '../config';
import UserHelper from '../lib/user-helper';
import { Permission } from './model';

export interface SoarApiError extends Error {
    name: string;
    code?: string;
    message: string;
    type?: string;
    requestId?: string;
}

export default class Api {
    static axios: AxiosInstance;

    public static _initialize() {
        this.axios = Axios.create({
            baseURL: Config.API_URL,
        });
        // Add a response interceptor
        this.axios.interceptors.response.use(
            function (response) {
                if (response.headers['warning']) {
                    console.error(`API WARNING:[${response.config.url}]: ${response.headers['warning']}`);
                }
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            async function (error) {
                const err: SoarApiError = {
                    name: 'SoarApiError',
                    message: 'Unknown error',
                };
                console.error('Interceptor:', error.config);
                error.config.retries = error.config.retries || {
                    count: 0,
                };
                if (error.response) {
                    const statusCode = error.response.status;

                    if (statusCode === 401) {
                        if (error.config.retries.count < 1) {
                            const updatedToken = await UserHelper.refreshAuth();

                            error.config.retries.count++;

                            Api.axios.defaults.headers.common.Authorization = `Bearer ${updatedToken}`;

                            return Api.axios.request(error.config);
                        } else {
                            window.location.reload();
                        }
                    } else {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if (error.response.data) {
                            err.message = `${error.response.data.error}`;
                            err.code = error.response.data.code;
                            err.type = error.response.data.type;
                            err.requestId = error.response.data.requestId;
                        } else {
                            err.message = `${statusCode}: Unknown error`;
                        }
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    err.message = 'No response from server';
                } else {
                    // Something happened in setting up the request that triggered an Error
                    err.message = 'Error: ' + error.message;
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                console.error('Interceptor:', err);

                return Promise.reject(err);
            }
        );

        this.axios.interceptors.request.use(
            function (config) {
                if (config.baseURL === Config.API_URL) {
                    if (!config.headers.Subdomain) {
                        config.headers.Subdomain = Api.getSubdomain();
                    }
                    const idToken = UserHelper.validateAndGetIdToken();
                    if (idToken) {
                        config.headers.Authorization = `Bearer ${idToken}`;
                    }
                }
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );
    }

    static apiPermissionsToEnum(permission: string) {
        switch (permission) {
            case 'READ':
                return Permission.Read;
            case 'WRITE':
                return Permission.Write;
            case 'REVIEW':
                return Permission.Review;
            case 'ADMIN':
                return Permission.Admin;
            case 'OWNER':
                return Permission.Owner;
            default:
                return Permission.Read;
        }
    }

    static getSubdomain(): string {
        if (
            !process.env.NODE_ENV ||
            process.env.NODE_ENV === 'development' ||
            process.env.REACT_APP_ENVIRONMENT === 'test'
        ) {
            return 'admin.soar-test.earth';
        } else {
            return 'admin.soar.earth';
        }
    }
}

Api._initialize();
