import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import UploadDropzone from '../../../Shared/Upload/upload-dropzone';
import { Button, Spinner } from '../../../style';

interface TMSServerAvatarEditProps {
    tmsId: number;
    avatarUrl?: string;
    handleUploadProfilePicture: (files: File[]) => void;
}

const TMSServerAvatarEdit = (props: TMSServerAvatarEditProps) => {
    const [image, setImage] = useState<any>('');
    const [updatedAvatarUrl, setUpdatedAvatarUrl] = useState<any>();
    const [cropper, setCropper] = useState<Cropper>();
    const [isUploading, setIsUploading] = useState<boolean>(false);

    useEffect(() => {
        if (props.avatarUrl) {
            setImage(props.avatarUrl);
        }
    }, [props.avatarUrl]);

    const handleFileDropped = (files: File[]) => {
        const error = 'Could not load file. We currently only support .JPG and .PNG files';

        if (files.length < 1) {
            toast.error(error);
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setUpdatedAvatarUrl(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
    };

    const handleUpdateAvatar = async () => {
        if (typeof cropper !== 'undefined') {
            setIsUploading(true);
            const avatarDataUrl = await cropper.getCroppedCanvas().toDataURL();
            fetch(avatarDataUrl)
                .then((res) => res.blob())
                .then((blob) => {
                    const file = new File([blob], props.tmsId.toString());
                    props.handleUploadProfilePicture([file]);
                });
        } else {
            toast.error('Upload unsuccessful. Please try again');
            return;
        }
    };

    return (
        <React.Fragment>
            <AvatarPreviewContainer id="upload-container">
                {image && !updatedAvatarUrl ? (
                    <UploadDropzone onSelectFiles={(file) => handleFileDropped(file)}>
                        <AvatarPreviewImage img src={image} />
                    </UploadDropzone>
                ) : null}
            </AvatarPreviewContainer>
            {image && updatedAvatarUrl ? (
                <React.Fragment>
                    <Cropper
                        style={{ height: 400, width: '100%' }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={updatedAvatarUrl}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                    <React.Fragment>{!updatedAvatarUrl ? <AvatarPreviewImage img src={image} /> : null}</React.Fragment>
                    <AvatarPreviewButtonContainer>
                        <Button onClick={handleUpdateAvatar}>{isUploading ? <Spinner /> : 'Finish cropping'}</Button>
                    </AvatarPreviewButtonContainer>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default TMSServerAvatarEdit;

const AvatarPreviewContainer = styled.div`
    cursor: default;

    :after {
        content: 'Choose file or drag it here';
        color: #fff;
        position: absolute;
        top: 20px;
        background: rgba(0, 0, 0, 0.67);
        border-radius: 500px;
        width: 297px;
        height: 297px;
        opacity: 0;
        padding: 135px 0px 0px 39px;
        font-size: 18px;
        margin: 10px 0px 0px 135px;
        cursor: pointer;
    }

    :hover {
        cursor: pointer;
    }

    :hover:after {
        opacity: 1;
    }

    .choose-file {
        padding: 12px 0px !important;
    }

    .choose-file input {
        pointer-events: auto !important;
        z-index: 99999;
        cursor: pointer;
    }
`;

const AvatarPreviewImage = styled.img`
    width: 300px !important;
    height: 300px;
    padding: 0px !important;
    border-radius: 500px;
    display: block;
    margin: 0 auto;
    border: 2px solid #eed926;
`;

const AvatarPreviewButtonContainer = styled.div`
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    margin: 15px;
`;
