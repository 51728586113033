import React from 'react';
import { Input } from '../style';

interface Props {
    value?: string;
    onChange: (value: string) => void;
    invalid?: boolean;
}

export enum Industry {
    Undefined = 'Select your industry',
    Mining = 'Mining / Oil / Gas',
    Agriculture = 'Agriculture',
    RealEstate = 'Real Estate',
    Security = 'Security / Defense',
    News = 'Media / News',
    Government = 'Government',
    Other = 'Other',
}

const InputIndustry = (props: Props) => {
    const { value, onChange, invalid } = props;

    return (
        <Input type="select" value={value || ''} onChange={(event) => onChange(event.target.value)} invalid={invalid}>
            <option key="">Select your industry</option>
            <option key="Mining / Oil / Gas">Mining / Oil / Gas</option>
            <option key="Agriculture">Agriculture</option>
            <option key="Real Estate">Real Estate</option>
            <option key="Security / Defense">Security / Defense</option>
            <option key="Media / News">Media / News</option>
            <option key="Government">Government</option>
            <option key="Other">Other</option>
        </Input>
    );
};

export default InputIndustry;
