import React, { useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { UserForAdminDTO } from '../../api/model';
import ViewHelper from '../view-helper';
import { Table, TableWrapper } from '../style';
import ManageUserModal from './ManageUser/manage-user-modal';
import styled from 'styled-components';

interface Props {
    items: UserForAdminDTO[];
    onUpdated: (user: UserForAdminDTO) => void;
    onDeleted: (userId: string) => void;
}

const ManageUsersTable = (props: Props) => {
    const [isUserDetailsOpen, setIsUserDetailsOpen] = useState<boolean>(false);
    const [user, setUser] = useState<UserForAdminDTO | undefined>(undefined);

    const handleDisplayUserDetails = (user: UserForAdminDTO) => {
        setUser(user);
        setIsUserDetailsOpen(true);
    };

    useEffect(() => {
        setIsUserDetailsOpen(false);
    }, [props.items]);

    const checkWMSUser = (cell, row) => {
        if (cell === row.id) {
            return 'WMS Server';
        }
        return cell;
    };
    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'email',
            text: 'Email',
            formatter: (cell, row) => checkWMSUser(cell, row),
            sort: true,
        },
        {
            dataField: 'reputable',
            text: 'Reputable',
            sort: true,
            formatter: (cell: boolean) => (cell ? <ReputableCheck className="fa fa-check" aria-hidden="true" /> : ''),
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell: number) => ViewHelper.formatDate(cell),
        },
    ];

    const rowEvents = {
        onClick: (_e, row: UserForAdminDTO, _rowIndex) => {
            handleDisplayUserDetails(row);
        },
    };

    const RESULTS_PER_PAGE = 10;

    const paginationOptions = {
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '50',
                value: 50,
            },
            {
                text: 'All',
                value: props.items.length,
            },
        ],
    };

    return (
        <React.Fragment>
            <ToolkitProvider
                keyField="id"
                data={props.items}
                columns={columns}
                exportCSV={{
                    fileName: `soar_users_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
                    onlyExportFiltered: true,
                    exportAll: false,
                }}
            >
                {(props) => (
                    <>
                        <TableWrapper>
                            <Table
                                {...props.baseProps}
                                pagination={paginationFactory(paginationOptions)}
                                rowEvents={rowEvents}
                                sizePerPage={RESULTS_PER_PAGE}
                            />
                        </TableWrapper>
                    </>
                )}
            </ToolkitProvider>
            <ManageUserModal
                user={user}
                isOpen={isUserDetailsOpen}
                setIsOpen={setIsUserDetailsOpen}
                onDeleted={props.onDeleted}
            />
        </React.Fragment>
    );
};

export default ManageUsersTable;

const ReputableCheck = styled.i`
    color: #eed926;
    width: 100%;
    text-align: center;
`;
