import React, { useState } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import SoarModal from '../../../Shared/soar-modal';
import { ConfirmModalText, ConfirmModalFooter, ConfirmButtonContainer } from '../../../style';

interface Props {
    handleConfirmDeleteLegend: () => void;
}

const MapServicesLayerDeleteLegend = (props: Props) => {
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

    const handleDeleteLayer = () => {
        setShowDeleteConfirmationModal(false);
        props.handleConfirmDeleteLegend();
    };

    return (
        <React.Fragment>
            <LegendDeleteIcon
                className={`fa fa-trash`}
                onClick={() => setShowDeleteConfirmationModal(!showDeleteConfirmationModal)}
                title="Delete Legend"
            />
            <SoarModal
                title={'Delete Legend'}
                isOpen={showDeleteConfirmationModal}
                onClosed={() => setShowDeleteConfirmationModal(false)}
                width="600px"
            >
                <ConfirmModalText>
                    You are about to delete this legend, once deleted it will no longer be available!
                </ConfirmModalText>
                <ConfirmModalFooter>
                    <ConfirmButtonContainer>
                        <Button color="warning" onClick={() => setShowDeleteConfirmationModal(false)}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={handleDeleteLayer}>
                            Delete
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>
        </React.Fragment>
    );
};

export default MapServicesLayerDeleteLegend;

const LegendDeleteIcon = styled.i`
    color: #dc3545;
    font-size: 20px;
    cursor: pointer;
    vertical-align: 1px;
`;
