import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListingDTO, ReviewItemDTO } from '../../api/model';
import ReviewActions from './review-actions';
import ListingDetails from '../Shared/listing-details';
import { actionUpdateSupermapQueue } from '../../store/AppStore/actions';
import {
    selectSupermapQueue,
    selectSupermapQueueLength,
    selectSupermapQueueLoading,
} from '../../store/AppStore/selectors';
import { Card, Container, Spinner, Subtitle, Title } from '../style';

const SingleReview = () => {
    const dispatch = useDispatch();

    const listingsInQueue = useSelector(selectSupermapQueue);
    const numberOfListingsInQueue = useSelector(selectSupermapQueueLength);
    const isLoading = useSelector(selectSupermapQueueLoading);

    const [listing, setListing] = useState<ListingDTO>(undefined);

    const onReviewResult = (review: ReviewItemDTO) => {
        if (!review.error) {
            dispatch(actionUpdateSupermapQueue(listing.id));
        }
    };

    useEffect(() => {
        if (listingsInQueue?.length > 0) {
            setListing(listingsInQueue[0]);
        } else {
            setListing(undefined);
        }
    }, [listingsInQueue]);

    const skip = () => {
        dispatch(actionUpdateSupermapQueue(listing.id));
    };

    if (!listingsInQueue && isLoading) {
        return (
            <Container>
                <Spinner text="Super-map Queue Loading" />
            </Container>
        );
    }

    if (!listing && !isLoading) {
        return (
            <Container>
                <Card>
                    <Title>Queue is empty</Title>
                    <Subtitle>There is nothing that needs to be reviewed</Subtitle>
                </Card>
            </Container>
        );
    }

    if (listing && isLoading) {
        return (
            <Container>
                <Spinner text="Submitting Review" />
            </Container>
        );
    }

    if (!listing) {
        return <React.Fragment />;
    }

    return (
        <Container>
            <Title>{`Super-Map Queue (total: ${numberOfListingsInQueue})`}</Title>
            <Card>
                <ListingDetails listing={listing} />
                <ReviewActions listing={listing} onReviewResult={onReviewResult} handleSkipped={skip} />
            </Card>
        </Container>
    );
};

export default SingleReview;
