import { useEffect, useState } from 'react';
import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLeafletContext,
    useLayerLifecycle,
} from '@react-leaflet/core';
import L, { LatLng, LatLngBounds, LeafletMouseEvent, PolylineOptions } from 'leaflet';
import { MarkerProps } from 'react-leaflet';
import './satellite-aoi-control.css';
import turfArea from '@turf/area';
import turfDistance from '@turf/distance';
import { Point } from '@turf/helpers';
import { polygonForBounds } from './satellite-aoi-util';

interface AOIControlProps {
    boundingBox: LatLngBounds;
    onAOIChange: (bounds: LatLngBounds) => void;
    aoiParamaters?: {
        maxArea: number;
        minArea: number;
        minWidth: number;
        minHeight: number;
        maxWidth: number;
        maxHeight: number;
    };
    removeAOI: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    aoiDisplayStyle: (bounds: LatLngBounds) => any;
}

export enum ErrorEnum {
    TooSmall = 'aoi-too-small',
    TooBig = 'aoi-too-big',
    TooWide = 'aoi-too-wide',
    TooHigh = 'aoi-too-high',
    TooShort = 'aoi-too-short',
    TooThin = 'aoi-too-thin',
}

type ColorScheme = 'blue' | 'green' | 'yellow' | 'orange' | 'red';
const createCornerIcon = (color: ColorScheme = 'blue') => {
    return new L.DivIcon({
        className: `annotation-tool-corner-marker ${color}`,
        iconAnchor: new L.Point(6, 6),
    });
};

const squareOptions: PolylineOptions = {
    smoothFactor: 1.0,
    noClip: false,
    stroke: true,
    color: 'blue',
    weight: 3,
    opacity: 1.0,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: true,
    fillColor: 'blue',
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: false,
};

const createCornerDragHandle = (position: LatLng, context: LeafletContextInterface) => {
    const handle = new L.DivIcon({
        className: `annotation-tool-corner-handle`,
        iconAnchor: new L.Point(8, 8),
    });
    const element = createElementObject<L.Marker, MarkerProps>(
        new L.Marker(position, { draggable: true, icon: handle }),
        context
    );
    return element;
};

const createCornerMarker = (position: LatLng, context: LeafletContextInterface) => {
    const icon = createCornerIcon();
    const element = createElementObject<L.Marker, MarkerProps>(new L.Marker(position, { icon: icon }), context);
    return element;
};

const createAreaMarker = (position: LatLng, context: LeafletContextInterface) => {
    return createElementObject<L.Marker, MarkerProps>(new L.Marker(position, { icon: new L.DivIcon({}) }), context);
};

const createDistanceMarker = (position: LatLng, context: LeafletContextInterface) => {
    const element = createElementObject<L.Marker, MarkerProps>(
        new L.Marker(position, { icon: new L.DivIcon({}) }),
        context
    );
    return element;
};

const createControl = (props: AOIControlProps, context: LeafletContextInterface) => {
    const emptyLatLngBounds = new L.LatLngBounds(new L.LatLng(0, 0), new L.LatLng(0, 0));
    const squareElement = createElementObject<L.Rectangle, AOIControlProps>(
        new L.Rectangle(emptyLatLngBounds, squareOptions),
        context
    );

    //marker just for display and icon to change if error in aoi
    const northWestCornerMarker = createCornerMarker(props.boundingBox.getNorthWest(), context);
    const southEastCornerMarker = createCornerMarker(props.boundingBox.getSouthEast(), context);

    //actual marker being dragged
    const northWestCornerHandle = createCornerDragHandle(props.boundingBox.getNorthWest(), context);
    const southEastCornerHandle = createCornerDragHandle(props.boundingBox.getSouthEast(), context);

    const horizontalDistanceMarker = createDistanceMarker(props.boundingBox.getNorthEast(), context);
    const verticalDistanceMarker = createDistanceMarker(props.boundingBox.getNorthEast(), context);
    const areaMarker = createAreaMarker(props.boundingBox.getCenter(), context);

    const lineLengthAsPixels = (map: L.Map, fromLatLng: L.LatLng, toLatLng: L.LatLng): number => {
        const from = map.latLngToLayerPoint(fromLatLng);
        const to = map.latLngToLayerPoint(toLatLng);
        const lineLengthAsPixels = from.distanceTo(to);
        return lineLengthAsPixels;
    };

    const updateHorizontalDistanceMarker = (bounds: LatLngBounds) => {
        if (horizontalDistanceMarker.instance.options && horizontalDistanceMarker.instance.options.icon) {
            const position = new LatLng(bounds.getNorth(), (bounds.getEast() + bounds.getWest()) / 2);
            const from: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getNorth()] };
            const to: Point = { type: 'Point', coordinates: [bounds.getWest(), bounds.getNorth()] };
            const distance = turfDistance(from, to, { units: 'kilometers' }).toFixed(2).toLocaleString();
            const horizontalDistanceInPixels = lineLengthAsPixels(
                context.map,
                bounds.getNorthEast(),
                bounds.getNorthWest()
            );
            const distanceText = `<span>${distance}km</span>`;
            const canvas = document.createElement('canvas');
            const c = canvas.getContext('2d');
            const distanceTextWidth = c?.measureText(distanceText).width || 0;
            const showHorizontalText = horizontalDistanceInPixels > distanceTextWidth * 0.75; // We allow for some overlap before hiding the horizontal text
            horizontalDistanceMarker.instance.setLatLng(position);
            horizontalDistanceMarker.instance.setIcon(
                new L.DivIcon({
                    className: 'annotation-tool-horizontal-distance-marker',
                    html: showHorizontalText ? distanceText : '',
                    iconAnchor: new L.Point(30, 30),
                })
            );
        }
    };

    const updateVerticalDistanceMarker = (bounds: LatLngBounds) => {
        if (verticalDistanceMarker.instance.options && verticalDistanceMarker.instance.options.icon) {
            const position = new LatLng((bounds.getNorth() + bounds.getSouth()) / 2, bounds.getEast());
            const from: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getNorth()] };
            const to: Point = { type: 'Point', coordinates: [bounds.getEast(), bounds.getSouth()] };
            const distance = turfDistance(from, to, { units: 'kilometers' }).toFixed(2).toLocaleString();
            const verticalDistanceInPixels = lineLengthAsPixels(
                context.map,
                bounds.getNorthEast(),
                bounds.getSouthEast()
            );
            const distanceText = `<span>${distance}km</span>`;
            const canvas = document.createElement('canvas');
            const c = canvas.getContext('2d');
            const distanceTextWidth = c?.measureText(distanceText).width || 0;
            const showVerticalText = verticalDistanceInPixels > distanceTextWidth * 0.75; // We allow for some overlap before hiding the vertical text
            verticalDistanceMarker.instance.setLatLng(position);
            verticalDistanceMarker.instance.setIcon(
                new L.DivIcon({
                    className: 'annotation-tool-vertical-distance-marker',
                    html: showVerticalText ? distanceText : '',
                    iconAnchor: new L.Point(14, 20),
                })
            );
        }
    };

    const updateAreaMarker = (bounds: LatLngBounds) => {
        if (areaMarker.instance.options && areaMarker.instance.options.icon) {
            const aoiColorScheme = props.aoiDisplayStyle ? props.aoiDisplayStyle(bounds) : undefined;
            const aoiErrorMessage = aoiColorScheme?.text ? aoiColorScheme.text : '';
            const position = bounds.getCenter();
            const geoJson = new L.Polygon(polygonForBounds(bounds)).toGeoJSON();
            const area = turfArea(geoJson);
            const areaKm2 = (area / 1000 / 1000).toFixed(2).toLocaleString();

            const horizontalDistanceInPixels = lineLengthAsPixels(
                context.map,
                bounds.getNorthEast(),
                bounds.getNorthWest()
            );

            // Create a fake canvas to measure the text width
            const areaText = `<span>Area: ${areaKm2}km²</span>`;
            const canvas = document.createElement('canvas');
            const c = canvas.getContext('2d');
            const areaTextWidth = c?.measureText(areaText).width || 0;
            const showAreaText = horizontalDistanceInPixels > areaTextWidth / 8; // We allow for some overlap before hiding the area in km2 text

            areaMarker.instance.setLatLng(position);
            areaMarker.instance.setIcon(
                new L.DivIcon({
                    iconSize: new L.Point(270, 100),
                    className: 'annotation-tool-area-marker',
                    html: `<span>${aoiErrorMessage ? `${aoiErrorMessage}</br>` : ''}${
                        showAreaText ? `Area: ${areaKm2} km²` : ''
                    }</span>`,
                    iconAnchor: new L.Point(130, 20),
                })
            );
        }
    };

    const updateAOIStyle = (bounds: LatLngBounds) => {
        const aoiColorScheme = props.aoiDisplayStyle && props.aoiDisplayStyle(bounds);
        const color = aoiColorScheme?.color ? aoiColorScheme.color : 'blue';
        if (aoiColorScheme) {
            squareElement.instance.setStyle({ color: color, fillColor: color });

            northWestCornerMarker.instance.setIcon(createCornerIcon(color));
            southEastCornerMarker.instance.setIcon(createCornerIcon(color));
        }
    };

    const updateMarkersPosition = (newBounds: LatLngBounds) => {
        northWestCornerMarker.instance.setLatLng(newBounds.getNorthWest());
        southEastCornerMarker.instance.setLatLng(newBounds.getSouthEast());

        northWestCornerHandle.instance.setLatLng(newBounds.getNorthWest());
        southEastCornerHandle.instance.setLatLng(newBounds.getSouthEast());

        squareElement.instance.setBounds(newBounds);
        updateHorizontalDistanceMarker(newBounds);
        updateVerticalDistanceMarker(newBounds);
        updateAreaMarker(newBounds);
        updateAOIStyle(newBounds);
    };

    const onDragNorthWestHandle = (e: LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, squareElement.instance.getBounds().getSouthEast());
        updateMarkersPosition(newBounds);
    };

    const onDragSouthEastHandle = (e: LeafletMouseEvent) => {
        const newBounds = new LatLngBounds(e.latlng, squareElement.instance.getBounds().getNorthWest());
        updateMarkersPosition(newBounds);
    };

    context.map.on('zoomend', () => {
        updateHorizontalDistanceMarker(squareElement.instance.getBounds());
        updateVerticalDistanceMarker(squareElement.instance.getBounds());
        updateMarkersPosition(squareElement.instance.getBounds());
    });

    northWestCornerHandle.instance.on('drag', onDragNorthWestHandle);
    southEastCornerHandle.instance.on('drag', onDragSouthEastHandle);

    northWestCornerHandle.instance.on('dragend', () => props.onAOIChange(squareElement.instance.getBounds()));
    southEastCornerHandle.instance.on('dragend', () => props.onAOIChange(squareElement.instance.getBounds()));

    squareElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.on('mousedown', (e: L.LeafletMouseEvent) => {
            const startLatLng = e.latlng;
            squareElement.instance.setBounds(new L.LatLngBounds(e.latlng, e.latlng));

            context.map.on('mousemove', (e: L.LeafletMouseEvent) => {
                const bounds = new L.LatLngBounds(startLatLng, e.latlng);
                squareElement.instance.setBounds(bounds);
                updateMarkersPosition(bounds);
                context.map.addLayer(horizontalDistanceMarker.instance);
                context.map.addLayer(verticalDistanceMarker.instance);
                context.map.addLayer(areaMarker.instance);
            });

            context.map.on('mouseup', () => {
                context.map.off('mousemove');
                context.map.off('mousedown');
                context.map.off('mouseup');
                context.map.dragging.enable();
                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

                const bounds = squareElement.instance.getBounds();
                updateMarkersPosition(bounds);
                props.onAOIChange(squareElement.instance.getBounds());
                context.map.addLayer(northWestCornerMarker.instance);
                context.map.addLayer(southEastCornerMarker.instance);

                context.map.addLayer(northWestCornerHandle.instance);
                context.map.addLayer(southEastCornerHandle.instance);
            });
        });
    });

    const handleRemove = () => {
        context.map.removeLayer(northWestCornerMarker.instance);
        context.map.removeLayer(southEastCornerMarker.instance);

        context.map.removeLayer(northWestCornerHandle.instance);
        context.map.removeLayer(southEastCornerHandle.instance);

        context.map.removeLayer(horizontalDistanceMarker.instance);
        context.map.removeLayer(verticalDistanceMarker.instance);
        context.map.removeLayer(areaMarker.instance);
    };
    squareElement.instance.on('remove', handleRemove);

    if (props.removeAOI) {
        handleRemove();
    }

    return squareElement;
};

const useAOIControl = createElementHook<L.Rectangle, AOIControlProps, LeafletContextInterface>(createControl);

interface SatelliteAOIControlProps {
    onAOIChange: (bounds: LatLngBounds) => void;
    aoiDisplayStyle: (bounds: LatLngBounds) => void;
    removeAOI?: boolean;
    aoiParamaters?: {
        maxArea: number;
        minArea: number;
        minWidth: number;
        minHeight: number;
        maxWidth: number;
        maxHeight: number;
    };
}

const SatelliteAOIControl = (props: SatelliteAOIControlProps) => {
    const { onAOIChange } = props;
    const context = useLeafletContext();
    const initialBoundingBox = new LatLngBounds(new LatLng(0, 0), new LatLng(0, 0));
    const [AOI, setAOI] = useState(initialBoundingBox);
    useEffect(() => {
        if (AOI) {
            onAOIChange(AOI);
        }
        // Disables the `onAOIChange` called when archival results are selected, otherwise they are removed from the map
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AOI]);

    const aoiControl = useAOIControl(
        {
            boundingBox: AOI,
            onAOIChange: setAOI,
            aoiParamaters: props.aoiParamaters,
            removeAOI: props.removeAOI,
            aoiDisplayStyle: props.aoiDisplayStyle,
        },
        context
    );

    useLayerLifecycle(aoiControl.current, context);

    return null;
};

export default SatelliteAOIControl;
