import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import ApiAnalytics from '../../../api/api-analytics';
import { DatesFromTo } from '../../../api/model-api';
import InputDateRange from '../../Shared/Filters/DateRange/input-date-range';
import { Card, ChartTitle, Col, FormGroup, Label, Row, Spinner } from '../../style';

const ChartPie = () => {
    const [searches, setSearches] = useState<any[]>([]);
    const [dates, setDates] = useState<DatesFromTo>();

    const refreshData = async () => {
        try {
            setSearches([]);
            const report = await ApiAnalytics.apiGetReportSatellite(dates);
            const data = [];
            report.intervals.forEach((value) => {
                let label = value.label;
                if (label === 'SuperView') {
                    label = 'Skymap 50';
                }
                data.push([label, value.total]);
            });
            data.unshift(['Satellite', 'Searches']);
            setSearches(data);
        } catch (err) {
            //no-empty
        }
    };

    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates]);

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange fromTo={dates} onChange={setDates} />
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {searches?.length === 0 ? (
                <Card height="664px">
                    <Spinner text="Loading Interaction Pie Chart" />
                </Card>
            ) : (
                <Card>
                    <ChartTitle>Product Interactions</ChartTitle>
                    <Chart
                        style={{
                            width: '100%',
                        }}
                        width={'100%'}
                        height={'600px'}
                        chartType="PieChart"
                        data={searches}
                        options={{
                            backgroundColor: 'transparent',
                            legend: {
                                position: 'labeled',
                                labeledValueText: 'both',
                                textStyle: {
                                    color: 'white',
                                },
                            },
                            textStyle: {
                                color: 'white',
                            },
                            chartArea: { width: '80%' },
                            hAxis: {
                                minValue: 0,
                                textStyle: {
                                    color: 'white',
                                },
                            },
                            vAxis: {
                                textStyle: {
                                    color: 'white',
                                },
                            },
                        }}
                    />
                </Card>
            )}
        </React.Fragment>
    );
};

export default ChartPie;
