import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectUser } from '../store/AppStore/selectors';
import store from '../store/store';
import { NavigationPermissionItem } from './navigation';
import { useLocation } from 'react-router';

interface NavigationItemProps {
    item: NavigationPermissionItem;
    badgeCount?: number;
}

const NavItem = (props: NavigationItemProps) => {
    const user = useSelector(selectUser);
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(props.item.route === location.pathname);
    }, [props.item.route, location]);

    const hasPermission = (toUseNavigationItem: NavigationPermissionItem): boolean => {
        return user.roles && user.roles.includes(toUseNavigationItem.requiredRole);
    };

    const handleOnClick = () => {
        window.scrollTo(0, 0);
        store.dispatch(push(props.item.route));
    };

    if (!hasPermission(props.item)) return <React.Fragment />;

    return (
        <StyledNavItem active={isActive} onClick={() => handleOnClick()}>
            {props.item.name}
            {props.badgeCount && props.badgeCount > 0 ? <Badge className="fa fa-bell">{props.badgeCount}</Badge> : null}
        </StyledNavItem>
    );
};

export default NavItem;

const Badge = styled.i`
    position: relative;
    padding: 2px;
    margin-left: 8px;
    border-radius: 50%;
    color: #eed309;
    font-size: 0.8rem;
`;

const StyledNavItem = styled.li<{ active: boolean }>`
    width: 100%;
    display: block;
    padding: 8px 8px 8px 12px;
    color: #ffffff;
    cursor: pointer;
    border-left: ${(props) => (props.active ? '5px solid #eed926' : '5px solid transparent')};

    &:hover {
        color: #c0c4c8;
    }
`;
