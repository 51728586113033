import React from 'react';
import styled from 'styled-components';

interface Props {
    children: React.ReactNode;
}

const ErrorPanel = (props: Props) => {
    return <Panel>{props.children}</Panel>;
};

export default ErrorPanel;

const Panel = styled.div`
    font-size: 1.5em;
    text-align: center;
    color: #dc3545;
`;
