import React, { useEffect, useState } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import {
    AttachedListingDTO,
    AttachedSTACCatalogDTO,
    AttachedWmsServerDTO,
    ManagedProfileFullDTO,
} from '../../../../api/api-managed-profiles';
import {
    Button,
    Col,
    Divider,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Subtitle,
    Table,
    TableWrapper,
} from '../../../style';
import store from '../../../../store/store';
import { push } from 'connected-react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import CopyBox from '../../../Shared/copy-box';
import TextInputWithClear from '../../../Shared/text-input-with-clear';
import paginationFactory from 'react-bootstrap-table2-paginator';

interface TMSServerLayersProps {
    profile: ManagedProfileFullDTO;
}

const COLS_LISTINGS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'listingType',
        text: 'Listing Type',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'processing',
        text: 'Processing',
        headerStyle: () => {
            return {
                width: '100px',
            };
        },
        formatter: (_, row: AttachedListingDTO) => {
            if (row.processing) {
                return <Checkmark className="fa fa-check" />;
            } else {
                return <React.Fragment />;
            }
        },
    },
    {
        dataField: 'public',
        text: 'Available in Soar',
        headerStyle: () => {
            return {
                width: '140px',
            };
        },
        formatter: (_, row: AttachedListingDTO) => {
            if (row.reviewSoar == 'APPROVED') {
                return <Checkmark className="fa fa-check" />;
            } else {
                return <React.Fragment />;
            }
        },
    },
];

const COLS_WMS_SERVERS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'standard',
        text: 'Standard',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'url',
        text: 'URL',
        headerStyle: () => {
            return { textAlign: 'center' };
        },
        style: () => {
            return { textAlign: 'center', width: 'auto', maxWidth: '250px', overflow: 'hidden' };
        },
        formatter: (_cell, row: AttachedWmsServerDTO) => {
            return <CopyBox onCopyMessage="Server URL copied to clipboard!">{row.url}</CopyBox>;
        },
    },
    {
        dataField: 'layersCount',
        text: 'Total layers',
    },
    {
        dataField: 'listings',
        text: 'Available in Soar',
        formatter: (_, row: AttachedWmsServerDTO) => row.listings?.length,
    },
];

const COLS_STAC_CATALOGS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'standard',
        text: 'Standard',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'catalogsCount',
        text: 'Total sub-catalogs',
    },
    {
        dataField: 'itemsCount',
        text: 'Total items',
    },
    {
        dataField: 'listings',
        text: 'Available in Soar',
        formatter: (_, row: AttachedSTACCatalogDTO) => row.listings?.length,
    },
];

const paginationOptions = {
    sizePerPageList: [
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
        {
            text: '100',
            value: 100,
        },
        {
            text: '1000',
            value: 1000,
        },
    ],
};

type SortBy = 'title' | 'id';
type SortOrder = 'asc' | 'desc';

const ProfileListings = ({ profile }: TMSServerLayersProps) => {
    const { listings, wmsServers, wmsOrphanedListings, stacCatalogs } = profile;
    const [textFilter, setTextFilter] = useState<string>('');
    const [sortBy, setSortBy] = useState<SortBy>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [listingsFiltered, setListingsFiltered] = useState<AttachedListingDTO[]>(listings);
    const [wmsServersFiltered, setWmsServersFiltered] = useState<AttachedWmsServerDTO[]>(wmsServers);
    const [wmsOrphanedListingsFiltered, setWmsOrphanedListingsFiltered] =
        useState<AttachedListingDTO[]>(wmsOrphanedListings);
    const [stacCatalogsFiltered, setStacCatalogsFiltered] = useState<AttachedSTACCatalogDTO[]>(stacCatalogs);

    useEffect(() => {
        const textFilterRegEx = new RegExp(textFilter, 'img');
        setListingsFiltered(
            listings
                .filter(
                    (listing) =>
                        textFilterRegEx.test(listing.title) ||
                        textFilterRegEx.test(listing.listingType) ||
                        textFilterRegEx.test(listing.id.toString())
                )
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
        setWmsServersFiltered(
            wmsServers
                .filter(
                    (wmsServer) =>
                        textFilterRegEx.test(wmsServer.title) ||
                        textFilterRegEx.test(wmsServer.url) ||
                        textFilterRegEx.test(wmsServer.standard) ||
                        textFilterRegEx.test(wmsServer.id.toString())
                )
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
        setWmsOrphanedListingsFiltered(
            wmsOrphanedListings
                .filter(
                    (listing) =>
                        textFilterRegEx.test(listing.title) ||
                        textFilterRegEx.test(listing.listingType) ||
                        textFilterRegEx.test(listing.id.toString())
                )
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
        setStacCatalogsFiltered(
            stacCatalogs
                .filter((catalog) => textFilterRegEx.test(catalog.title) || textFilterRegEx.test(catalog.id.toString()))
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
    }, [listings, wmsServers, stacCatalogs, wmsOrphanedListings, textFilter, sortBy, sortOrder]);

    const onClickRowWMS = {
        onClick: (_e, row: AttachedWmsServerDTO) => {
            store.dispatch(push(`/wms/${row.id}`));
        },
        onAuxClick: (_e, row: AttachedWmsServerDTO) => {
            const win = window.open(`/wms/${row.id}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    const onClickRowSTAC = {
        onClick: (_e, row: AttachedSTACCatalogDTO) => {
            store.dispatch(push(`/stac/${row.id}`));
        },
        onAuxClick: (_e, row: AttachedWmsServerDTO) => {
            const win = window.open(`/stac/${row.id}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    const onClickRowListing = {
        onClick: (_e, row: AttachedListingDTO) => {
            const siblings = listingsFiltered.map((l) => l.id).join(',');
            store.dispatch(push(`/profiles/${profile.user.userId}/listings/${row.id}?siblings=${siblings}`));
        },
        onAuxClick: (_e, row: AttachedListingDTO) => {
            const siblings = listingsFiltered.map((l) => l.id).join(',');
            const win = window.open(
                `/profiles/${profile.user.userId}/listings/${row.id}?siblings=${siblings}`,
                '_blank'
            );
            if (!win) {
                win.focus();
            }
        },
    };

    const onClickRowWmsOrphans = {
        onClick: (_e, row: AttachedListingDTO) => {
            const siblings = wmsOrphanedListingsFiltered.map((l) => l.id).join(',');
            store.dispatch(push(`/profiles/${profile.user.userId}/listings/${row.id}?siblings=${siblings}`));
        },
        onAuxClick: (_e, row: AttachedListingDTO) => {
            const siblings = wmsOrphanedListingsFiltered.map((l) => l.id).join(',');
            const win = window.open(
                `/profiles/${profile.user.userId}/listings/${row.id}?siblings=${siblings}`,
                '_blank'
            );
            if (!win) {
                win.focus();
            }
        },
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={8}>
                    <FormGroup>
                        <Label>Filter by ...</Label>
                        <TextInputWithClear value={textFilter} onChange={setTextFilter} placeholder="Search term ..." />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>Sort by</Label>
                        <Input
                            value={sortBy}
                            onChange={(e) => {
                                setSortBy(e.target.value);
                            }}
                            type="select"
                            name="sortBy"
                        >
                            <option value={'id'}>ID</option>
                            <option value={'title'}>Title</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>Sort order</Label>
                        <Input
                            value={sortOrder}
                            onChange={(e) => {
                                setSortOrder(e.target.value);
                            }}
                            type="select"
                            name="sortOrder"
                        >
                            <option value={'asc'}>Ascending</option>
                            <option value={'desc'}>Descending</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            {wmsServersFiltered.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Total of {wmsServers.length} WMS/WMTS servers</Subtitle>
                    <ToolkitProvider keyField="id" data={wmsServersFiltered} columns={COLS_WMS_SERVERS}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table
                                        {...props.baseProps}
                                        rowEvents={onClickRowWMS}
                                        pagination={paginationFactory(paginationOptions)}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                    <Divider />
                </React.Fragment>
            ) : null}

            {stacCatalogsFiltered.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Total of {stacCatalogs.length} STAC catalogs</Subtitle>
                    <ToolkitProvider keyField="id" data={stacCatalogsFiltered} columns={COLS_STAC_CATALOGS}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table
                                        {...props.baseProps}
                                        rowEvents={onClickRowSTAC}
                                        pagination={paginationFactory(paginationOptions)}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                    <Divider />
                </React.Fragment>
            ) : null}

            {listings.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Total of {listings.length} attached listings</Subtitle>
                    <ToolkitProvider keyField="id" data={listingsFiltered} columns={COLS_LISTINGS}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        rowEvents={onClickRowListing}
                                        pagination={paginationFactory(paginationOptions)}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}

            {wmsOrphanedListings.length > 0 ? (
                <React.Fragment>
                    <Subtitle>Total of {wmsOrphanedListings.length} orphaned WMS listings</Subtitle>
                    <ToolkitProvider keyField="id" data={wmsOrphanedListingsFiltered} columns={COLS_LISTINGS}>
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        rowEvents={onClickRowWmsOrphans}
                                        pagination={paginationFactory(paginationOptions)}
                                    />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default ProfileListings;

const Checkmark = styled.i`
    color: green;
`;
