import React, { useEffect, useState } from 'react';
import ApiUser from '../../api/api-user';
import { UserForAdminDTO, UserRole } from '../../api/model';
import { DatesFromTo } from '../../api/model-api';
import InputDateRange from '../Shared/Filters/DateRange/input-date-range';
import InputUserRole from '../Shared/Filters/input-user-role';
import { Card, Col, Container, FormGroup, Input, Label, Row, Spinner, Subtitle, Title } from '../style';
import ManageUsersTable from './manage-users-table';

const ManageUsers = () => {
    const [data, setData] = useState<UserForAdminDTO[]>([]);
    const [filtered, setFiltered] = useState<UserForAdminDTO[]>([]);
    const [userSearch, setUserSearch] = useState<string>('');
    const [role, setRole] = useState<UserRole>(undefined);
    const [dates, setDates] = useState<DatesFromTo>(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const refresh = async () => {
            setLoading(true);
            try {
                const res = await ApiUser.getUsers();
                setData(res);
            } catch (err) {
                alert(err);
            } finally {
                setLoading(false);
            }
        };

        refresh();
    }, []);

    useEffect(() => {
        const handleFilterByRole = async (role: UserRole) => {
            setLoading(true);
            try {
                const res = await ApiUser.getUsers(
                    undefined,
                    undefined,
                    role === UserRole.ALL_USERS ? undefined : role
                );
                setData(res);
            } catch (err) {
                alert(err);
            } finally {
                setLoading(false);
            }
        };
        if (role) {
            handleFilterByRole(role);
        }
    }, [role]);

    useEffect(() => {
        const from = dates?.from ? dates.from.getTime() / 1000 : undefined;
        const to = dates?.to ? dates.to.getTime() / 1000 : undefined;
        const fil = data
            .filter((u) => (from && to ? u.createdAt >= from && u.createdAt < to : true))
            .filter((u) =>
                userSearch?.length > 0
                    ? u.email?.toLocaleLowerCase().includes(userSearch) ||
                      u.name?.toLocaleLowerCase().includes(userSearch)
                    : true
            );
        setFiltered(fil);
    }, [data, userSearch, dates]);

    const onUpdated = (user: UserForAdminDTO) => {
        setData(data.map((u) => (u.id === user.id ? user : u)));
    };

    const onDeleted = (userId: string) => {
        setData(data.filter((u) => u.id !== userId));
    };

    return (
        <Container>
            <Title>Manage Users</Title>
            {loading ? (
                <React.Fragment>
                    <Card height="104px">
                        <Spinner text={'Loading Users'} />
                    </Card>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Card>
                        <Row>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="user-search">Search by Name or Email</Label>
                                    <Input
                                        title="Enter name or email"
                                        placeholder="Enter name or email"
                                        value={userSearch}
                                        onChange={(e) => setUserSearch(e.target.value?.toLocaleLowerCase())}
                                        name="user-search"
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="date-range">Filter by Join Date</Label>
                                    <InputDateRange fromTo={dates} onChange={setDates} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="user-role">Filter by Role</Label>
                                    <InputUserRole value={role} onChange={setRole} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card>
                    <Card>
                        <Subtitle>Table of Users</Subtitle>

                        <ManageUsersTable items={filtered} onUpdated={onUpdated} onDeleted={onDeleted} />
                    </Card>
                </React.Fragment>
            )}
        </Container>
    );
};
export default ManageUsers;
