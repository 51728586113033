import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import ApiListings from '../../api/api-listings';
import { ListingDTO, ReportedListingDTO } from '../../api/model';
import Loader from '../Shared/Loader/Loader';
import ButtonsPanel from '../Shared/buttons-panel';
import ErrorPanel from '../Shared/error-panel';
import ListingDetails from '../Shared/listing-details';
import { Container, Button } from '../style';
import ViewHelper from '../view-helper';
import ConfirmModal from '../Shared/confirm-modal';

interface Props {
    item: ReportedListingDTO;
    onClose: () => void;
    ignoreReport: (item: ReportedListingDTO) => void;
    notifyOwner: (item: ReportedListingDTO) => void;
    deleteMap: (item: ReportedListingDTO) => void;
}

const ReportedListingDetails = (props: Props) => {
    const { item, onClose, ignoreReport, notifyOwner, deleteMap } = props;
    const [listing, setListing] = useState<ListingDTO>(undefined);
    const [error, setError] = useState<string>(undefined);

    useEffect(() => {
        if (item) {
            ApiListings.getListing(item.listingId)
                .then((res) => {
                    setListing(res);
                })
                .catch((err) => setError(err.message));
        }
    }, [item]);

    return (
        <Container>
            <DescriptionTable>
                <Line
                    label="Reported at"
                    value={`${ViewHelper.formatDateFromISO(item.reportedAt)} (${ViewHelper.formatDateTimeDistanceToNow(
                        item.reportedAt
                    )})`}
                />
                <Line label="Reported by" value={`${item.reportedByEmail} (${item.countryCode})`} />
                <Line label="Reason" value={item.reason} />
                <Line label="Review" value={item.review} />
                <Line label="Reviewed at" value={ViewHelper.formatDateFromISO(item.reviewedAt)} />
                <Line label="Reviewed by" value={item.reviewedByEmail} />
            </DescriptionTable>
            {(!item.review || item.review === 'PENDING') && (
                <ButtonsPanel>
                    <Button onClick={() => ignoreReport(item)}>Ignore report</Button>
                    <Button onClick={() => notifyOwner(item)}>Notify owner</Button>
                    <ConfirmModal
                        title="Delete map"
                        message={'Are you sure to delete the map?'}
                        onConfirm={() => deleteMap(item)}
                        color="danger"
                    />
                    <Button onClick={onClose}>Close</Button>
                </ButtonsPanel>
            )}

            {listing && (
                <Row>
                    <Col xs={12}>
                        <ListingDetails listing={listing} />
                    </Col>
                </Row>
            )}
            {!listing && !error && <Loader text="Loading listing details" />}
            {!listing && error && <ErrorPanel>{error}</ErrorPanel>}
        </Container>
    );
};

export default ReportedListingDetails;

interface PropsLine {
    label?: string;
    value: string;
}

const Line = (props: PropsLine) => {
    const { value, label } = props;
    return (
        <TableRow>
            <TableCellHeader>{label}</TableCellHeader>
            <TableCell>{value}</TableCell>
        </TableRow>
    );
};

const DescriptionTable = styled.div`
    display: table;
    color: white;
`;

const TableRow = styled.div`
    display: table-row;
`;

const TableCellHeader = styled.div`
    display: table-cell;
    padding: 5px;
    font-weight: bold;
`;

const TableCell = styled.div`
    display: table-cell;
    padding: 5px;
`;
