import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, Spinner } from '../../../style';
import StyledModal from '../../../Shared/styled-modal';
import ApiSTAC, { STACCatalogFullDTO } from '../../../../api/api-stac';

interface TMSDeleteActionProps {
    stac: STACCatalogFullDTO;
    invalidateCatalog: () => void;
    children: React.ReactNode;
}

const STACDeleteAction = (props: TMSDeleteActionProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeletingCatalog, setIsDeletingCatalog] = useState(false);
    const toggleDeleteModal = () => {
        setIsDeleteModalOpen((prevState) => !prevState);
    };

    const handleClickDeleteCatalog = () => {
        setIsDeletingCatalog(true);
        ApiSTAC.deleteSTAC(props.stac.id)
            .then((_) => {
                alert('Catalog was deleted');
                props.invalidateCatalog();
            })
            .catch((err) => {
                alert('An error occurred while deleting this catalog: ' + err.message);
            });
    };

    return (
        <React.Fragment>
            <span onClick={() => setIsDeleteModalOpen(true)}>{props.children}</span>

            <StyledModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(false)}
                title="Are you sure you want to delete this catalog?"
                width="600px"
            >
                <React.Fragment>
                    <ModalWarningText>
                        Deleting this catalog will remove this catalog and all it's children!
                        <br />
                        Are you sure you want to delete this catalog?
                    </ModalWarningText>
                    <Divider />
                    <ActionButtonContainer>
                        <Button disabled={isDeletingCatalog} color="danger" onClick={() => handleClickDeleteCatalog()}>
                            {isDeletingCatalog ? (
                                <>
                                    <Spinner />
                                    Delete this catalog and children from Soar
                                </>
                            ) : (
                                <>Delete this catalog and children from Soar</>
                            )}
                        </Button>
                        <Button color="secondary" onClick={toggleDeleteModal}>
                            Go back
                        </Button>
                    </ActionButtonContainer>
                    <Divider />
                </React.Fragment>
            </StyledModal>
        </React.Fragment>
    );
};

export default STACDeleteAction;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const ModalWarningText = styled.p`
    margin: 18px;
    color: white;
    font-size: 16px;
`;
