import React, { useState } from 'react';
import SoarModal from '../Shared/soar-modal';
import { Button } from '../style';
import Search from '../Search';

interface Props {
    name: string;
    type: string;
    count: number;
}

const FeaturedAddItem = (props: Props) => {
    const [openSelect, setOpenSelect] = useState<boolean>(false);

    return (
        <React.Fragment>
            <Button onClick={() => setOpenSelect(!openSelect)}>{`ADD TO ${props.name}` || 'ADD'}</Button>
            {openSelect ? (
                <SoarModal
                    title={props.name}
                    isOpen={openSelect}
                    onClosed={() => setOpenSelect(!openSelect)}
                    width={'1200px'}
                >
                    <Search
                        isAddFeatured={true}
                        featuredName={props.name}
                        featuredType={props.type}
                        featuredCount={props.count}
                    />
                </SoarModal>
            ) : null}
        </React.Fragment>
    );
};

export default FeaturedAddItem;
