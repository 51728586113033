import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Badge, Col } from 'reactstrap';
import styled from 'styled-components';
import ApiWms, { OGCStandard, WMSServer } from '../../../api/api-wms';
import store from '../../../store/store';
import {
    Card,
    Container,
    Divider,
    Spinner,
    Subtitle,
    Table,
    TableWrapper,
    Title,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Button,
    InvisibleCard,
} from '../../style';
import WMSActions from './Server/wms-actions';
import ServerSpeedIcon from './Server/wms-server-speed-icon';
import CopyBox from '../../Shared/copy-box';

const WMS = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [wmsServers, setWmsServers] = useState<WMSServer[]>([]);
    const [filteredWmsServers, setFilteredWmsServers] = useState<WMSServer[]>([]);
    const [textFilter, setTextFilter] = useState<string>('');

    useEffect(() => {
        loadWMS();
    }, []);

    useEffect(() => {
        const textFilterRegEx = new RegExp(textFilter, 'img');

        setFilteredWmsServers(
            wmsServers.filter(
                (wmsServer) =>
                    textFilterRegEx.test(wmsServer.url) ||
                    textFilterRegEx.test(wmsServer.title) ||
                    textFilterRegEx.test(wmsServer.titleOvr)
            )
        );
    }, [wmsServers, textFilter]);

    const loadWMS = () => {
        setError(null);
        setLoading(true);
        setWmsServers([]);
        ApiWms.getWMS()
            .then((res) => {
                setWmsServers(res.sort((a, b) => a.id - b.id));
            })
            .catch((e) => {
                setError(e.message ?? 'Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onClickRow = {
        onClick: (e, row) => {
            const target = e.target as HTMLElement;
            if (target.classList.contains('dropdown') || target.tagName === 'BUTTON' || target.tagName === 'I') {
                // Don't open new route when clicking the action button
            } else {
                store.dispatch(push(`/wms/${row.id}`));
            }
        },
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: () => {
                return {
                    width: '40px',
                };
            },
        },
        {
            dataField: 'titleOvr',
            text: 'Server Name',
            style: () => {
                return { 'word-wrap': 'break-word', 'white-space': 'pre-wrap', 'max-width': '250px' };
            },
            formatter: (cell, row: WMSServer) => {
                return row.titleOvr || row.title;
            },
        },
        {
            dataField: 'url',
            text: 'URL',
            headerStyle: () => {
                return { 'text-align': 'center' };
            },
            style: () => {
                return { 'text-align': 'center', width: 'auto', 'max-width': '250px', overflow: 'hidden' };
            },
            formatter: (_cell, row: WMSServer) => {
                return <CopyBox onCopyMessage="Server URL copied to clipboard!">{row.url}</CopyBox>;
            },
        },
        {
            dataField: 'standard',
            text: 'Type',
            headerStyle: () => {
                return { 'text-align': 'center' };
            },
            style: () => {
                return { 'text-align': 'center' };
            },
            formatter: (_cell, row: WMSServer) => {
                const color = row.standard === OGCStandard.WMS ? 'primary' : 'secondary';
                return <Badge color={color}>{row.standard}</Badge>;
            },
        },
        {
            dataField: 'publicLayers',
            text: 'Public Layers',
            headerStyle: () => {
                return {
                    width: '120px',
                };
            },
        },
        {
            dataField: 'totalLayers',
            text: 'Total Layers',
            headerStyle: () => {
                return {
                    width: '120px',
                };
            },
        },
        {
            dataField: 'speedKbps',
            text: 'Speed',
            formatter: (_, row: WMSServer) => {
                return <ServerSpeedIcon speed={row.speedKbps} />;
            },
            headerStyle: () => {
                return {
                    width: '70px',
                };
            },
        },
        {
            dataField: 'id',
            text: 'Actions',
            headerStyle: () => {
                return {
                    width: '70px',
                };
            },
            formatter: (cell) => {
                const layer = filteredWmsServers.find((t) => t.id === cell);
                return <WMSActions wmsServer={layer} invalidateServer={() => loadWMS()} />;
            },
        },
    ];

    const renderServers = () => {
        if (loading) {
            return <Spinner />;
        }

        if (error) {
            return <Error>{error}</Error>;
        }

        if (filteredWmsServers.length === 0) {
            return <NoResults>No WMS/WMTS servers found.</NoResults>;
        }

        return (
            <ToolkitProvider
                keyField="id"
                data={filteredWmsServers}
                columns={columns}
                search={{ searchFormatted: true }}
                exportCSV={{
                    fileName: 'soar-satellite-orders.csv',
                }}
            >
                {(props) => (
                    <React.Fragment>
                        <WmsTableWrapper>
                            <Table {...props.baseProps} rowEvents={onClickRow} />
                        </WmsTableWrapper>
                    </React.Fragment>
                )}
            </ToolkitProvider>
        );
    };

    return (
        <Container>
            <Title>Manage WMS/WMTS</Title>

            <Divider />

            <Card>
                <Subtitle>Manage an existing WMS/WMTS server</Subtitle>

                <FormGroup>
                    <Label>Filter by Server Name/URL</Label>
                    <InputGroup>
                        <Input
                            value={textFilter}
                            placeholder="Search by title..."
                            onChange={(e) => setTextFilter(e.target.value)}
                        />
                        {textFilter && (
                            <ClearFilter color="link" onClick={() => setTextFilter('')}>
                                <i className="fa fa-times" />
                            </ClearFilter>
                        )}
                    </InputGroup>
                </FormGroup>

                {renderServers()}
            </Card>
        </Container>
    );
};

export default WMS;

const WmsTableWrapper = styled(TableWrapper)`
    .table tbody tr td {
        color: white;
    }

    .table tbody tr:hover td {
        font-weight: bold;
    }
`;

const ClearFilter = styled(Button)`
    background-color: white !important;
    border: none !important;
    color: black;

    :hover {
        background-color: white !important;
        border: none !important;
        box-shadow: none !important;
    }

    i {
        color: black;
    }
`;

const NoResults = styled.p`
    text-align: center;
    padding: 2em 0;
    font-style: italic;
    color: white;
`;

const Error = styled(NoResults)`
    color: red;
`;
