import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../style';
import config from '../../../../config';
import ApiSTAC, { STACCatalogDTO, STACCatalogFullDTO } from '../../../../api/api-stac';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { selectSTACChildren } from '../../../../store/ExternalMapServicesStore/selectors';
import store from '../../../../store/store';
import { actionAddUpdateSTACChildren } from '../../../../store/ExternalMapServicesStore/actions';
import { push } from 'connected-react-router';

interface StacCatalogChildVisiblityProps {
    catalog: STACCatalogFullDTO;
    onStacCatalogItemVisibilityUpdated: (id: number) => void;
}

const StacCatalogNavigation = ({ catalog, onStacCatalogItemVisibilityUpdated }: StacCatalogChildVisiblityProps) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const stacChildren = useSelector(selectSTACChildren);
    const [siblings, setSiblings] = useState<number[]>([]);

    useEffect(() => {
        const catSiblings = stacChildren[catalog.parentId];
        if (catSiblings) {
            setSiblings(catSiblings);
        } else if (catalog.parentId > 0) {
            ApiSTAC.getSTACCatalogChildren(catalog.parentId).then((children) => {
                const childrenIds = children.map((c) => c.id);
                setSiblings(childrenIds);
                dispatch(actionAddUpdateSTACChildren(catalog.parentId, childrenIds));
            });
        }
    }, [stacChildren, catalog.parentId, dispatch]);

    const handelCatalogAvailability = (availability: boolean) => {
        if (!catalog.previewUrl && availability) {
            alert('You must have a preview before making the layer publicly available');
            return;
        }

        setLoading(true);
        ApiSTAC.updateSTACCatalogVisibility(catalog.id, availability)
            .then((_) => {
                onStacCatalogItemVisibilityUpdated(catalog.id);
                toast.success(
                    `${catalog.titleOvr || catalog.title || catalog.id} is now ${availability ? 'public' : 'private'}`
                );
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOpenInSoar = () => {
        window.open(`${config.CLIENT_URL}/maps/${catalog.listingId}`);
    };

    const handleClickNextLayer = () => {
        const currentIndex = siblings.findIndex((t) => t === catalog.id);
        if (currentIndex < 0) return;
        if (currentIndex + 1 == siblings.length) {
            store.dispatch(push(`/stac/${siblings[0]}`));
        } else {
            store.dispatch(push(`/stac/${siblings[currentIndex + 1]}`));
        }
    };

    const handleClickPrevLayer = () => {
        const currentIndex = siblings.findIndex((t) => t === catalog.id);
        if (currentIndex < 0) return;
        if (currentIndex == 0) {
            store.dispatch(push(`/stac/${siblings[siblings.length - 1]}`));
        } else {
            store.dispatch(push(`/stac/${siblings[currentIndex - 1]}`));
        }
    };

    const handleClickUp = () => {
        if (catalog.parentId > 0) store.dispatch(push(`/stac/${catalog.parentId}`));
        else store.dispatch(push('/stac'));
    };

    const handleClickRoot = () => {
        console.log('clicked root');
        store.dispatch(push(`/stac/${catalog.rootId}`));
    };

    return (
        <Container>
            {catalog.public && (
                <React.Fragment>
                    <AvailableText>This catalog item is currently available on Soar</AvailableText>
                    <Button disabled={loading} onClick={() => handelCatalogAvailability(false)}>
                        Turn it off
                    </Button>
                    <Button color="link" onClick={() => handleOpenInSoar()}>
                        Open in Soar
                    </Button>
                </React.Fragment>
            )}
            {!catalog.public && catalog.mosaic && (
                <React.Fragment>
                    <NotAvailableText>This catalog item is not available on Soar</NotAvailableText>
                    <Button disabled={loading} onClick={() => handelCatalogAvailability(true)}>
                        Turn it on
                    </Button>
                </React.Fragment>
            )}

            <ContainerButtons>
                <Button onClick={handleClickUp}>
                    <i className="fa fa-arrow-up" /> Up
                </Button>
                {catalog.rootId > 0 && <Button onClick={handleClickRoot}>Root</Button>}
                {siblings.length > 0 && (
                    <React.Fragment>
                        <Button onClick={handleClickPrevLayer} title="Previous catalog sibling">
                            Prev <i className="fa fa-arrow-left" />
                        </Button>
                        <Button onClick={handleClickNextLayer}>
                            Next <i className="fa fa-arrow-right" title="Next catalog sibling" />
                        </Button>
                    </React.Fragment>
                )}
            </ContainerButtons>
        </Container>
    );
};

export default StacCatalogNavigation;

const Container = styled.div`
    padding-top: 8px;
    display: inline-block;
`;

const Text = styled.span`
    font-weight: bolder;
    margin-right: 20px;
    font-size: 1.2rem;
    vertical-align: sub;
`;

const AvailableText = styled(Text)`
    color: #58a758;
`;

const NotAvailableText = styled(Text)`
    color: #fa4646;
`;

const ContainerButtons = styled.div`
    margin-right: 20px;
    float: right;
`;
