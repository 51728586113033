import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer, { AppState } from './root-reducer';
import { history } from './history';

const routeMiddleware = routerMiddleware(history);

const enhancer = compose(applyMiddleware(routeMiddleware), applyMiddleware(ReduxThunk));

const store: Store<AppState> = createStore(rootReducer, {}, enhancer);

export default store;
