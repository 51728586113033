import ApiReportedListings from '../../api/api-reported-listings';
import { ReportedListingDTO } from '../../api/model';
import { AppState } from '../root-reducer';
import { actionTypes as at } from './constants';
export const actionRefreshReportedListings = (includeReviewed: boolean) => {
    return async (dispatch, getState) => {
        dispatch({ type: at.APP_REPORTED_LISTINGS_LOADING, payload: true });
        try {
            const reported = await ApiReportedListings.getReportedListings(includeReviewed);
            dispatch({
                type: at.APP_REPORTED_LISTINGS_UPDATED, payload: {
                    listings: reported,
                    includeReviewed: includeReviewed
                }
            });
        } catch (err) {
            dispatch({ type: at.APP_REPORTED_LISTINGS_ERROR, payload: err.toString() });
        } finally {
            dispatch({ type: at.APP_REPORTED_LISTINGS_LOADING, payload: false });
        }
    };
};

export const actionReportedListingUpdated = (item: ReportedListingDTO) => {
    return async (dispatch, getState) => {
        const appState: AppState = getState();
        const reported = appState.reportedListingsStore.reportedListings;
        if (reported) {
            const updated = reported.map(r => r.id === item.id ? item : r);
            dispatch({
                type: at.APP_REPORTED_LISTINGS_UPDATED, payload: {
                    listings: updated,
                    includeReviewed: appState.reportedListingsStore.includeReviewed
                }
            });
        }
    };
}

