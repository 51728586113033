import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import ApiReports from '../../../api/api-reports';
import { ReportDTO } from '../../../api/model';
import { DatesFromTo, ReportInterval } from '../../../api/model-api';
import InputDateRange from '../../Shared/Filters/DateRange/input-date-range';
import InputReportInterval from '../../Shared/Filters/input-report-interval';
import { Card, Col, FormGroup, Input, Label, Row, Spinner } from '../../style';

const CommentsBarChart = () => {
    const [interval, setInterval] = useState<ReportInterval>(ReportInterval.MONTH);
    const [report, setReport] = useState<ReportDTO>(undefined);
    const [searches, setSearches] = useState<any[]>([]);
    const [dates, setDates] = useState<DatesFromTo>();
    const [cumulative, setCumulative] = useState('true');

    useEffect(() => {
        const refresh = async () => {
            try {
                const res = await ApiReports.getCommentsReportByInterval(interval);
                setReport(res);
            } catch (err) {
                // setError(err.toString());
            }
        };
        refresh();
    }, [interval]);

    useEffect(() => {
        if (report) {
            let data = [];
            if (cumulative === 'true') {
                let accumulator = 0;
                report.intervals.forEach((value) => {
                    accumulator = accumulator + value.total;
                    data.push([value.label, accumulator]);
                });
            } else {
                report.intervals.forEach((value) => {
                    data.push([value.label, value.total]);
                });
            }
            if (dates?.from && dates?.to) {
                const dateFrom = formatISO(dates.from, { representation: 'date' });
                const dateTo = formatISO(dates.to, { representation: 'date' });
                data = data.filter((d) => d[0] >= dateFrom && d[0] < dateTo);
            }
            if (data.length > 180) {
                data = data.slice(-180);
            }
            data.unshift(['Date', 'Comments']);
            setSearches(data);
        }
    }, [report, dates, cumulative]);

    const onIntervalChange = (interval: ReportInterval) => {
        setInterval(interval);
    };

    if (!searches || !report) {
        return (
            <React.Fragment>
                <Card height="104px">
                    <Spinner />
                </Card>
                <Card height="622px">
                    <Spinner />
                </Card>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="interval">Filter by Interval</Label>
                            <InputReportInterval value={interval} onChange={onIntervalChange} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange fromTo={dates} onChange={setDates} interval={interval} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="data-type">Filter by Frequency</Label>
                            <Input
                                title="Data type"
                                value={cumulative}
                                onChange={(e) => setCumulative(e.target.value)}
                                type="select"
                                name="cumulative"
                            >
                                <option value={'true'}>Cumulative</option>
                                <option value={'false'}>Discrete</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            <Card>
                <Chart
                    style={{
                        width: '100%',
                    }}
                    width={'100%'}
                    height={'600px'}
                    chartType="ColumnChart"
                    data={searches}
                    options={{
                        backgroundColor: 'transparent',
                        legend: 'none',
                        colors: ['#00A2FF'],
                        textStyle: {
                            color: 'white',
                        },
                        chartArea: { width: '80%' },
                        hAxis: {
                            minValue: 0,
                            textStyle: {
                                color: 'white',
                            },
                        },
                        vAxis: {
                            textStyle: {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Card>
        </React.Fragment>
    );
};

export default CommentsBarChart;
