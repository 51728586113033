import React, { ClipboardEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from '../style';

const inputMaxValues = {
    MAX_TAG_LENGTH: 100,
    MAX_TAG_COUNT: 20,
};

interface TagInputFieldProps {
    onTagInput: (tags: string[]) => void;
    tags: string[];
    name?: string;
    disabled?: boolean;
}

const TagInputField = ({ tags, onTagInput, name, disabled }: TagInputFieldProps) => {
    const [tagInput, setTagInput] = useState<string>('');

    useEffect(() => {
        if (tags && tags.length < inputMaxValues.MAX_TAG_COUNT) {
            onTagInput(tags);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags]);

    const removeTagAt = (index: number) => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        onTagInput(newTags);
    };

    const addTagItem = (tag: string) => {
        if (tag.length < inputMaxValues.MAX_TAG_LENGTH) {
            setTagInput(tag.replace(',', ''));
        }
    };

    const addTag = (tag: string) => {
        if (
            !tags.includes(tag) &&
            tag.length < inputMaxValues.MAX_TAG_LENGTH &&
            tags.length < inputMaxValues.MAX_TAG_COUNT
        ) {
            onTagInput([...tags, tag]);
        }
    };

    const inputKeyDown = (e) => {
        const val = `#${e.target.value.replace('#', '').trim()}`;
        if (e.key === 'Enter' && val) {
            addTag(val);
            setTagInput('');
        } else if (e.key === ',' && val) {
            const input = `${val.replace(',', '')}`;
            addTag(input);
            setTagInput('');
        } else if (e.key === '#' && val) {
            const input = `#${val.replace('#', '').trim()}`;
            if (input !== '#') {
                addTag(input);
                setTagInput('');
            }
        } else if (e.key === 'Backspace' && tagInput === '') {
            removeTagAt(tags.length - 1);
        }
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { clipboardData } = e;
        const pastedText = clipboardData.getData('text');
        const removeSpaces = pastedText.replace(/\s/g, '').replace(/#/g, '').split(',');
        const removeDuplicates = removeSpaces.filter((item, index) => removeSpaces.indexOf(item) === index);
        const addHash = removeDuplicates.map((i) => '#' + i);
        onTagInput([...tags, ...addHash]);
    };

    return (
        <Container>
            <TagList>
                {tags &&
                    tags.map((tag, i) => (
                        <TagItem key={i}>
                            <ItemTag>{tag}</ItemTag>
                            <TagRemoveButton type="button" onClick={() => removeTagAt(i)}>
                                <svg
                                    height="14"
                                    width="14"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                    focusable="false"
                                    fill="rgba(33,37,41,1)"
                                >
                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                </svg>
                            </TagRemoveButton>
                        </TagItem>
                    ))}
                <TagInputItem>
                    <TagInput
                        disabled={disabled}
                        type="text"
                        placeholder={
                            tags.length >= inputMaxValues.MAX_TAG_COUNT
                                ? `${inputMaxValues.MAX_TAG_COUNT} tag limit`
                                : tags.length > 0
                                ? ``
                                : `Separate tags by pressing enter or using a comma`
                        }
                        onKeyDown={inputKeyDown}
                        onChange={(e) => addTagItem(e.target.value)}
                        value={tagInput}
                        isMaximumTags={tags.length >= inputMaxValues.MAX_TAG_COUNT ? true : false}
                        onPaste={(e) => handlePaste(e)}
                    />
                </TagInputItem>
            </TagList>
        </Container>
    );
};

export default TagInputField;

const Container = styled.div`
    display: block;
    width: 100%;
    min-height: 38px;
    padding: 3px 3px 3px 6px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const TagList = styled.ul`
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 32px;
    list-style: none;
`;

const TagItem = styled.li`
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
`;

const ItemTag = styled.div`
    padding: 4px;
    word-break: break-all;
`;

const TagRemoveButton = styled.button`
    color: rgba(33, 37, 41, 1);
    background-color: transparent;
    align-items: center;
    border-radius: 2px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;

    .svg {
        margin-top: -1px;
    }

    :hover {
        background-color: #ffbdad;
        color: #de350b;
        cursor: pointer;
    }
`;

const TagInputItem = styled.li`
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
`;

interface TagInputProps {
    isMaximumTags: boolean;
}

const TagInput = styled(Input)<TagInputProps>`
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    outline: none;
    -webkit-text-fill-color: ${(props) => (props.isMaximumTags ? 'rgb(255 0 0 / 80%);' : '')};
    color: rgba(0, 0, 0, 0.6);
`;
