import React from 'react';
import { useEffect, useState } from 'react';
import ApiOrdersSupplier from '../../../api/api-orders-supplier';
import ViewHelper from '../../view-helper';
import SupplierControls from './supplier-controls';
import SupplierOrderDetails from './supplier-order-details';
import { OrderForSupplierDTO } from '../../../api/model-orders';
import ErrorPanel from '../../Shared/error-panel';
import { Card, Col, Container, Row, Spinner, Subtitle, Title } from '../../style';
import OrderStatusBadge from '../order-status-badge';

interface Props {
    match: any;
}

const SupplierOrder = (props: Props) => {
    const [order, setOrder] = useState<OrderForSupplierDTO>(undefined);
    const [error, setError] = useState<string>(undefined);

    const id: number = props.match.params.id;

    const fetch = async () => {
        try {
            const order = await ApiOrdersSupplier.getOrderById(id);
            setOrder(order);
        } catch (err) {
            setError(`Requested order [${id}] could not be retrieved. ${err.message}`);
        }
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error)
        return (
            <Container>
                <Card>
                    <ErrorPanel>{error}</ErrorPanel>
                </Card>
            </Container>
        );

    if (!order)
        return (
            <Container>
                <Spinner text={'Fetching order details from server'} />
            </Container>
        );

    return (
        <Container>
            <Card>
                <React.Fragment>
                    <Title large centered>
                        {order.orderNumber}
                    </Title>
                    <Title centered>
                        <OrderStatusBadge status={order.status} />
                    </Title>
                    <Row>
                        <Col md={{ size: 4, offset: 3 }}>
                            <Subtitle>{`Order ID: ${order.id}`}</Subtitle>
                        </Col>
                        <Col md={4}>
                            <Subtitle>{`Product Code ${ViewHelper.formatSupplierAndProductOption(
                                order.supplierCode,
                                order.productOption
                            )}`}</Subtitle>
                        </Col>
                    </Row>
                </React.Fragment>
            </Card>
            <SupplierOrderDetails order={order} invalidate={fetch} />
            <SupplierControls order={order} invalidate={fetch} onOrderUpdated={(t) => setOrder(t)} />
        </Container>
    );
};

export default SupplierOrder;
