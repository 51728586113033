import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';
import ApiAnalytics from '../../../../api/api-analytics';
import { ReportDTO } from '../../../../api/model';
import { DatesFromTo, ProductName, Provider, ReportInterval } from '../../../../api/model-api';
import InputDateRange, { DEFAULT_DATE_RANGES, LAST_30_DAYS } from '../../../Shared/Filters/DateRange/input-date-range';
import InputProvider from '../../../Shared/Filters/input-provider';
import InputReportInterval from '../../../Shared/Filters/input-report-interval';
import { Button, ButtonWrapper, Card, ChartTitle, Col, FormGroup, Input, Label, Row, Spinner } from '../../../style';

interface Filter {
    provider?: Provider;
    satellite?: ProductName;
    type?: ReportInterval;
    dates: DatesFromTo;
    cumulative: boolean;
}

const DEFAULT_FILTER: Filter = {
    provider: undefined,
    satellite: undefined,
    type: ReportInterval.DAY,
    dates: LAST_30_DAYS,
    cumulative: true,
};

const ChartsInterval = () => {
    const [chartType, setChartType] = useState<GoogleChartWrapperChartType>('ColumnChart'); //LineChart

    const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER);

    const [report, setReport] = useState<ReportDTO>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const [searches, setSearches] = useState<any[]>([]);

    useEffect(() => {
        if (report) {
            let data = [];
            if (filter.cumulative === true) {
                let accumulator = 0;
                report.intervals.forEach((value) => {
                    accumulator = accumulator + value.total;
                    data.push([value.label, accumulator]);
                });
            } else {
                report.intervals.forEach((value) => {
                    data.push([value.label, value.total]);
                });
            }
            if (filter.dates?.from && filter.dates?.to) {
                let dateFrom = formatISO(filter.dates.from, { representation: 'date' });
                let dateTo = formatISO(filter.dates.to, { representation: 'date' });
                // labels for months are in format 'YYYY-MM' so we need to slice them to filter by date work correctly
                if (filter.type === 'MONTH') {
                    dateFrom = dateFrom.slice(0, 7);
                    dateTo = dateTo.slice(0, 7);
                }
                data = data.filter((d) => d[0] >= dateFrom && d[0] <= dateTo);
            }
            data.unshift(['Date', 'Searches']);
            setSearches(data);
        }
    }, [report, filter.cumulative, filter.dates, filter.type]);

    useEffect(() => {
        const handleFilterRefresh = async () => {
            try {
                setLoading(true);
                const dto = await ApiAnalytics.apiGetReport(filter.type, filter.provider, filter.satellite);
                setReport(dto);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        handleFilterRefresh();
    }, [filter.type, filter.provider, filter.satellite]);

    const onIntervalChange = (interval: ReportInterval) => {
        setFilter({ ...filter, type: interval });
    };

    const onProviderChange = (provider?: Provider, satellite?: ProductName) => {
        setFilter({ ...filter, provider, satellite });
    };

    const onDatesChange = (dates: DatesFromTo) => {
        setFilter({ ...filter, dates });
    };

    const onCumulativeChange = (e: any) => {
        setFilter({ ...filter, cumulative: e.target.value === 'true' });
    };

    const exportToCSV = () => {
        const rows = searches.map((row) => row.join(',')).join('\n');
        const csvContent = `data:text/csv;charset=utf-8,${rows}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'chart_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="interval">Filter by Interval</Label>
                            <InputReportInterval disabled={loading} value={filter.type} onChange={onIntervalChange} />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="date-range">Filter by Dates</Label>
                            <InputDateRange
                                disabled={loading}
                                fromTo={filter.dates}
                                onChange={onDatesChange}
                                interval={filter.type}
                                selectedDate={DEFAULT_DATE_RANGES.LAST_30_DAYS}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <InputProvider
                                disabled={loading}
                                provider={filter.provider}
                                product={filter.satellite}
                                onChange={onProviderChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="frequency">Filter by Frequency</Label>
                            <Input
                                disabled={loading}
                                title="Data type"
                                value={filter.cumulative}
                                onChange={onCumulativeChange}
                                type="select"
                                name="cumulative"
                            >
                                <option value={'true'}>Cumulative</option>
                                <option value={'false'}>Discrete</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="chart-type">Change Chart Type</Label>
                            <Input
                                disabled={loading}
                                title="Chart type"
                                value={chartType}
                                onChange={(e) => setChartType(e.target.value as GoogleChartWrapperChartType)}
                                type="select"
                                name="chart-type"
                            >
                                <option value={'ColumnChart'}>Columns</option>
                                <option value={'LineChart'}>Line</option>
                                <option value={'Table'}>Table</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            {(loading || !searches || !report) && (
                <Card height="600px">
                    <Spinner text="Loading Interactions" />
                </Card>
            )}
            {!(loading || !searches || !report) && searches?.length == 1 && (
                <Card height="600px">
                    <div>no data</div>
                </Card>
            )}
            {!(loading || !searches || !report) && searches?.length > 1 && (
                <Card>
                    <Chart
                        style={{
                            width: '100%',
                        }}
                        width={'100%'}
                        height={'600px'}
                        chartType={chartType}
                        data={searches}
                        options={{
                            backgroundColor: 'transparent',
                            legend: 'none',
                            colors: ['#00A2FF'],
                            textStyle: {
                                color: 'white',
                            },
                            chartArea: { width: '80%' },
                            hAxis: {
                                minValue: 0,
                                textStyle: {
                                    color: 'white',
                                },
                            },
                            vAxis: {
                                textStyle: {
                                    color: 'white',
                                },
                            },
                        }}
                    />
                    <ButtonWrapper>
                        <Button color="primary" onClick={exportToCSV}>
                            <i className="fa fa-download" />
                            &nbsp;Export as CSV
                        </Button>
                    </ButtonWrapper>
                </Card>
            )}
        </React.Fragment>
    );
};

export default ChartsInterval;
