import styled from 'styled-components';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ApiStac, { STACCatalogFullDTO } from '../../../api/api-stac';
import store from '../../../store/store';
import { Card, Container, Divider, Spinner, Subtitle, Table, TableWrapper, Title } from '../../style';

const STAC = () => {
    const [stacCatalogs, setStacCatalogs] = useState<STACCatalogFullDTO[]>([]);

    useEffect(() => {
        loadSTACCatalogs();
    }, []);

    const loadSTACCatalogs = () => {
        setStacCatalogs([]);
        ApiStac.getSTACRootCatalogs().then((res) => {
            setStacCatalogs(res.sort((a, b) => a.id - b.id));
        });
    };

    const onClickRow = {
        onClick: (e, row) => {
            const target = e.target as HTMLElement;
            if (target.classList.contains('dropdown') || target.tagName === 'BUTTON' || target.tagName === 'I') {
                // Don't open new route when clicking the action button
            } else {
                store.dispatch(push(`/stac/${row.id}`));
            }
        },
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerStyle: () => {
                return {
                    width: '40px',
                };
            },
        },
        {
            dataField: 'title',
            text: 'Catalog Title',
        },
        {
            dataField: 'totalChildren',
            text: 'Children',
            headerStyle: () => {
                return {
                    width: '120px',
                };
            },
        },
    ];

    return (
        <Container>
            <Title>Manage STAC Catalogs</Title>

            <Divider />

            {!stacCatalogs || stacCatalogs.length === 0 ? (
                <Card>
                    <Subtitle>Manage an existing STAC Catalog</Subtitle>
                    <Spinner />
                </Card>
            ) : null}

            {stacCatalogs && stacCatalogs.length > 0 ? (
                <Card>
                    <Subtitle>Manage an existing STAC Catalog</Subtitle>

                    <ToolkitProvider
                        keyField="id"
                        data={stacCatalogs}
                        columns={columns}
                        search={{ searchFormatted: true }}
                        exportCSV={{
                            fileName: 'soar-stac-thing.csv',
                        }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <StacTableWrapper>
                                    <Table {...props.baseProps} rowEvents={onClickRow} />
                                </StacTableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </Card>
            ) : null}
        </Container>
    );
};

export default STAC;

const StacTableWrapper = styled(TableWrapper)`
    .table tbody tr td {
        color: white;
    }

    .table tbody tr:hover td {
        font-weight: bold;
    }
`;
