import React, { useState } from 'react';
import Chart from 'react-google-charts';
import { ListingDTO } from '../../../api/model';
import FormattedDetailsTable, { Line } from '../../Shared/formatted-details-table';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from '../../style';
import ViewHelper from '../../view-helper';
import ManageUploadsTable from './manage-uploads-table';

interface Props {
    listings: ListingDTO[];
}

const ManageViewListings = (props: Props) => {
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    if (!props.listings) {
        return <React.Fragment />;
    }

    const groupBy = (xs, f) => {
        return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    };

    const listingsByType =
        props.listings?.length && groupBy(props.listings, (listing: ListingDTO) => listing.listingType);
    const firstUpload = props.listings?.length && props.listings[props.listings.length - 1].createdAt;
    const lastUpload = props.listings?.length && props.listings[0].createdAt;

    const imagesListingStatus =
        listingsByType['IMAGE']?.length && groupBy(listingsByType['IMAGE'], (listing: ListingDTO) => listing.review);
    const mapListingStatus =
        listingsByType['TILE_LAYER']?.length &&
        groupBy(listingsByType['TILE_LAYER'], (listing: ListingDTO) => listing.review);

    const chartData = [
        ['Image Type', 'Totals'],
        ['MAPS', listingsByType['TILE_LAYER']?.length],
        ['IMAGES', listingsByType['IMAGE']?.length],
    ];

    return (
        <React.Fragment>
            <FormattedDetailsTable>
                <Line label={'Total Uploads'} value={props.listings.length.toString()} />
                {listingsByType['TILE_LAYER'] ? (
                    <Line
                        label={'Map Uploads'}
                        value={
                            <React.Fragment>
                                {listingsByType['TILE_LAYER'].length.toString()}
                                <span>
                                    {`Approved: ${
                                        mapListingStatus['APPROVED']?.length
                                            ? mapListingStatus['APPROVED'].length.toString()
                                            : '0'
                                    } ,`}
                                    {`Pending: ${
                                        mapListingStatus['PENDING']?.length
                                            ? mapListingStatus['PENDING'].length.toString()
                                            : '0'
                                    } ,`}
                                    {`Rejected: ${
                                        mapListingStatus['REJECTED']?.length
                                            ? mapListingStatus['REJECTED'].length.toString()
                                            : '0'
                                    }, `}
                                    {mapListingStatus['undefined']?.length
                                        ? `Unknown: ${mapListingStatus['undefined']?.length}`
                                        : ''}
                                </span>
                            </React.Fragment>
                        }
                    />
                ) : null}
                {listingsByType['IMAGE'] ? (
                    <Line
                        label={'Image Uploads'}
                        value={
                            <React.Fragment>
                                {listingsByType['IMAGE'].length.toString()}
                                <span>
                                    {`Approved: ${
                                        imagesListingStatus['APPROVED']?.length
                                            ? imagesListingStatus['APPROVED'].length.toString()
                                            : '0'
                                    } ,`}
                                    {`Pending: ${
                                        imagesListingStatus['PENDING']?.length
                                            ? imagesListingStatus['PENDING'].length.toString()
                                            : '0'
                                    } ,`}
                                    {`Rejected: ${
                                        imagesListingStatus['REJECTED']?.length
                                            ? imagesListingStatus['REJECTED'].length.toString()
                                            : '0'
                                    }, `}
                                    {imagesListingStatus['undefined']?.length
                                        ? `Unknown: ${imagesListingStatus['undefined']?.length}`
                                        : ''}
                                </span>
                            </React.Fragment>
                        }
                    />
                ) : null}
                {listingsByType['NOT_SUPPORTED'] ? (
                    <Line
                        label={'Failed Uploads'}
                        value={<React.Fragment>{listingsByType['NOT_SUPPORTED'].length.toString()}</React.Fragment>}
                    />
                ) : null}
            </FormattedDetailsTable>
            {listingsByType && chartData ? (
                <Chart
                    style={{
                        width: '100%',
                    }}
                    width={'100%'}
                    height={'100%'}
                    chartType="PieChart"
                    data={chartData}
                    options={{
                        backgroundColor: 'transparent',
                        legend: {
                            position: 'labeled',
                            labeledValueText: 'both',
                            textStyle: {
                                color: 'white',
                            },
                        },
                        textStyle: {
                            color: 'white',
                        },
                        chartArea: { width: '80%' },
                    }}
                />
            ) : null}
            <FormattedDetailsTable>
                <Line label={'First Upload'} value={ViewHelper.formatDateWithTime(firstUpload)} />
                <Line label={'Recent Upload'} value={ViewHelper.formatDateWithTime(lastUpload)} />
            </FormattedDetailsTable>
            {/* @ts-ignore */}
            <Accordion flush open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId="1">Upload History</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <ManageUploadsTable listings={props.listings} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </React.Fragment>
    );
};

export default ManageViewListings;
