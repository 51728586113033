import React, { useState } from 'react';
import { useEffect } from 'react';
import ApiReports from '../../../api/api-reports';
import { ReportDTO } from '../../../api/model';
import { ReportInterval } from '../../../api/model-api';
import { Card, Col, Row, Spinner, Statistic, Subtitle } from '../../style';

const CommentsOverview = () => {
    const [report, setReport] = useState<ReportDTO>(undefined);

    useEffect(() => {
        const fetch = async () => {
            const r = await ApiReports.getCommentsReportByInterval(ReportInterval.DAY);
            setReport(r);
        };
        fetch();
    }, []);

    if (!report)
        return (
            <Card height="130px">
                <Spinner />
            </Card>
        );

    const yesterdayTotal = report.intervals[report.intervals.length - 1].total;
    const last30Days = report.intervals.length > 30 ? report.intervals.slice(-30) : report.intervals;
    const last30DaysTotal = last30Days.map((i) => i.total).reduce((a, b) => a + b, 0);

    return (
        <Row>
            <Col>
                <Card title="Total number of new comments yesterday">
                    <Subtitle>Last 24 hours</Subtitle>
                    <Statistic>{yesterdayTotal.toLocaleString()}</Statistic>
                </Card>
            </Col>

            <Col>
                <Card title="Total new comments in last 30 days">
                    <Subtitle>Last 30 days</Subtitle>
                    <Statistic>{last30DaysTotal.toLocaleString()}</Statistic>
                </Card>
            </Col>

            <Col>
                <Card title="Total number of comments">
                    <Subtitle>Total Comments</Subtitle>
                    <Statistic>{report.total.toLocaleString()}</Statistic>
                </Card>
            </Col>
        </Row>
    );
};

export default CommentsOverview;
