import React, { useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CommentDTO } from '../../../api/model';
import { Button, Panel, Table, TableWrapper } from '../../style';
import ViewHelper from '../../view-helper';
import CommentTableDetails from './comment-table-details';

interface Props {
    items: CommentDTO[];
    onDeleteClick: (commentId: string) => void;
    onUserClick: (userSearch: string) => void;
}

const CommentsTable = (props: Props) => {
    const { items } = props;
    const [expanded, setExpanded] = useState<string[]>([]);

    const handleOnExpand = (row, isExpand, _rowIndex, _e) => {
        if (isExpand) {
            setExpanded([...expanded, row.id]);
        } else {
            setExpanded(expanded.filter((x) => x !== row.id));
        }
    };

    const expandRow = {
        renderer: (row, _rowIndex) => {
            return <CommentTableDetails comment={row} />;
        },
        expanded: expanded,
        onExpand: handleOnExpand,
        showExpandColumn: true,
        expandByColumnOnly: true,
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'updatedAt',
            text: 'Updated',
            formatter: (_cell: number, row: CommentDTO) =>
                ViewHelper.formatDateWithTime(row.updatedAt || row.createdAt),
        },
        {
            dataField: 'text',
            text: 'Comment',
            formatter: (cell: string) => cell,
        },
        {
            dataField: 'userName',
            text: 'User Name',
            formatter: (cell: string) => cell,
        },
        {
            dataField: 'userEmail',
            text: 'User Email',
            formatter: (cell: string) => (
                <Button
                    color="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onUserClick(cell);
                    }}
                >
                    {cell}
                </Button>
            ),
        },
        {
            dataField: 'vote',
            text: 'Votes',
            sort: true,
            formatter: (cell: string) => cell || 0,
        },
        {
            dataField: 'id',
            text: '',
            formatter: (cell: string) => (
                <Button color="link" class="danger" onClick={() => props.onDeleteClick(cell)}>
                    Delete
                </Button>
            ),
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return <Button onClick={handleClick}>EXPORT CSV</Button>;
    };

    return (
        <ToolkitProvider
            keyField="id"
            data={items}
            columns={columns}
            exportCSV={{
                fileName: `comments_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
            }}
        >
            {(props) => (
                <React.Fragment>
                    <TableWrapper>
                        <Table {...props.baseProps} pagination={paginationFactory()} expandRow={expandRow} />
                    </TableWrapper>
                    <Panel>
                        <MyExportCSV {...props.csvProps} />
                    </Panel>
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
};

export default CommentsTable;
