import React, { useEffect, useRef, useState } from 'react';
import { GeoJSON, LayersControl, TileLayer, WMSTileLayer } from 'react-leaflet';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { CRSDefs } from '../../../../api/api-wms';
import { ListingDTO } from '../../../../api/model';
import GeoUtil from '../../../../lib/geo-util';
import BoundingBoxControl, {
    BoundingBoxControlEvent,
} from '../../../MapView/Annotations/BoundingBoxControl/leaflet-bbox-control';
import LeafletScreenshotControl, {
    ScreenshotControlCompleteEvent,
    ScreenshotControlStartEvent,
} from '../../../MapView/Annotations/ScreenShotControl/leaflet-screenshot-control';
import LeafletMap from '../../../MapView/leaflet-map';
import OpacitySlider from '../../../Shared/opacity-slider';
import LeafletBaseMaps from '../../../Shared/leaflet-basemaps';
import WMTSTileLayer from '../../WMS/Layer/wmts-tile';

interface ProfileListingMapProps {
    listing: ListingDTO;
    onGeneratePreview?: (previewBase64: string) => void;
    onUsePreviewBoundingBox?: (boundingBox: string) => void;
    setMapZoomLevel?: (zoomLevel: number) => void;
    fullWidth?: boolean;
    srs?: string;
    tms?: boolean;
}

const DEFAULT_BBOX_WKT = 'POLYGON ((-180 -90, 180 -90, 180 90, -180 90, -180 -90))';

const ProfileListingMap = (props: ProfileListingMapProps) => {
    const { listing } = props;
    const leafletMapRef = useRef(null);

    const [previewLoading, setPreviewLoading] = useState(false);
    const [hasSetMapEventHandlers, setHasSetMapEventHandlers] = useState(false);
    const [mapReady, setMapReady] = useState(false);
    const [opacity, setOpacity] = useState(1.0);
    const [polygonOpacity, setPolygonOpacity] = useState(1);

    const { onGeneratePreview, onUsePreviewBoundingBox } = props;

    useEffect(() => {
        const layerBBox = props.listing.bboxWKT || DEFAULT_BBOX_WKT;

        if (leafletMapRef?.current && layerBBox && !hasSetMapEventHandlers) {
            setHasSetMapEventHandlers(true);
            const leafletMap = leafletMapRef.current;
            const bounds = GeoUtil.latLngBoundsFromPolygonWKT(layerBBox);
            leafletMap.fitBounds(bounds);

            leafletMap.on(ScreenshotControlStartEvent, () => {
                setPreviewLoading(true);
            });

            leafletMap.on(ScreenshotControlCompleteEvent, (e) => {
                setPreviewLoading(false);
                if (e.error && e.error === 'error') {
                    alert(
                        'Generating a screenshot failed possibly due to CORS or something outside our control. You can manually upload a screenshot :)'
                    );
                    return;
                }
                onGeneratePreview(e.dataUrl);
            });

            leafletMap.on(BoundingBoxControlEvent, (e) => {
                const { boundingBox } = e;
                onUsePreviewBoundingBox(boundingBox);
                alert('Bounding box value updated to that of map preview');
            });
        }
        // we need the leafletMapRef.current to trigger the event but ts complains that it is not needed which is wrong..
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leafletMapRef?.current, props.listing, onGeneratePreview, onUsePreviewBoundingBox, hasSetMapEventHandlers]);

    const handleMapReady = () => {
        requestAnimationFrame(() => {
            setMapReady(true);
        });
    };

    useEffect(() => {
        if (mapReady) {
            const map = leafletMapRef?.current;
            const handleZoomEnd = () => {
                if (props.setMapZoomLevel) {
                    props.setMapZoomLevel(map.getZoom() || 28);
                }
            };
            map.on('zoomend', handleZoomEnd);

            return () => {
                map.off('zoomend', handleZoomEnd);
            };
        }
        return undefined;
    }, [props, mapReady]);

    return (
        <div>
            <MapContainer fullWidth={props.fullWidth}>
                {previewLoading ? (
                    <PreviewLoadingContainer>
                        <BigSpinner />
                    </PreviewLoadingContainer>
                ) : null}

                <LeafletMap
                    mapRef={leafletMapRef}
                    worldZoom={1}
                    zoomControl={true}
                    fullscreenControl={true}
                    mapReady={() => handleMapReady()}
                >
                    {listing.geometryGeoJson && (
                        <GeoJSON
                            data={JSON.parse(listing.geometryGeoJson as string)}
                            style={{ fillOpacity: 0, color: 'red', opacity: polygonOpacity }}
                        />
                    )}
                    {listing.listingType == 'WMS' ? (
                        <WMSTileLayer
                            key={listing.id}
                            url={listing.serverUrl}
                            layers={listing.layerName}
                            transparent={true}
                            opacity={opacity}
                            format={listing.layerImageFormat}
                            zIndex={1000}
                            crs={CRSDefs[props.srs] ?? null}
                            version={listing.layerVersion}
                        />
                    ) : listing.listingType == 'WMTS' ? (
                        <WMTSTileLayer
                            key={listing.id}
                            url={listing.serverUrl}
                            layer={listing.layerName}
                            tileMatrixSet={listing.tileMatrixSet?.identifier}
                            matrixIds={listing.tileMatrixSet?.matrices}
                            // resourceUrl={listing.resourceUrl}
                            style={listing.style}
                            // wmtsType={listing.wmtsType}
                            opacity={opacity}
                            zIndex={1000}
                            crs={CRSDefs[props.srs] ?? null}
                        />
                    ) : listing.listingType == 'COG' ? (
                        <TileLayer
                            key={listing.title}
                            url={listing.tileUrl}
                            tms={false}
                            opacity={opacity}
                            zIndex={1000}
                            maxZoom={28}
                            minZoom={listing.minZoom}
                            minNativeZoom={listing.minZoom}
                        />
                    ) : listing.tileUrl?.length > 0 ? (
                        <TileLayer
                            key={listing.title}
                            url={listing.tileUrl}
                            tms={props.tms ?? true}
                            opacity={opacity}
                            zIndex={1000}
                            maxZoom={28}
                        />
                    ) : null}

                    <LayersControl position="topright">
                        <LeafletBaseMaps />
                        {mapReady && leafletMapRef?.current ? (
                            <LeafletScreenshotControl
                                position="bottomleft"
                                buttonText="Use this map view as a preview"
                                width={leafletMapRef.current.getSize().x}
                                height={leafletMapRef.current.getSize().y}
                            />
                        ) : null}

                        {mapReady && leafletMapRef?.current ? (
                            <BoundingBoxControl
                                position="bottomright"
                                buttonText="Use this bounding box"
                                width={leafletMapRef.current.getSize().x}
                                height={leafletMapRef.current.getSize().y}
                            />
                        ) : null}
                    </LayersControl>
                </LeafletMap>
            </MapContainer>

            <PreviewOpacitySlider title={'Transparency'} opacity={opacity} onChange={setOpacity} />
            {listing.geometryGeoJson && (
                <PreviewOpacitySlider
                    title={'Polygon Transparency'}
                    opacity={polygonOpacity}
                    onChange={setPolygonOpacity}
                />
            )}
        </div>
    );
};

export default ProfileListingMap;

const MapContainer = styled.div<{ fullWidth?: boolean }>`
    width: ${(props) => (props.fullWidth ? '100%' : '43.33vw')};
    height: 43.33vw;
    position: relative;
`;

const PreviewLoadingContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
`;

const BigSpinner = styled(Spinner)`
    width: 120px;
    height: 120px;
    position: absolute;
    color: #eed926;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
`;

const PreviewOpacitySlider = styled(OpacitySlider)`
    width: 100%;
    padding: 20px auto;
`;
