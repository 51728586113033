import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import ApiAdmin from '../../api/api-admin';
import { DmtTaskDTO } from '../../api/model';
import DotsText from '../Shared/DotsText';
import TasksTable from './dmt-tasks-table';
import './index.css';

const Tasks = () => {
    const [rawTasks, setRawTasks] = useState<DmtTaskDTO[]>(undefined);
    const [tasksError, setTasksError] = useState<Error | undefined>(undefined);

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        setRawTasks([]);
        setTasksError(undefined);
        try {
            const tasks = await ApiAdmin.dmtGetTasks();
            setRawTasks(tasks);
            setTasksError(undefined);
        } catch (err) {
            setTasksError(err);
            setRawTasks(undefined);
        }
    };

    return (
        <Container>
            <h1>DMT Projects</h1>
            {tasksError && <p className="error">{tasksError.message}</p>}
            {rawTasks && <TasksTable items={rawTasks} refreshTasks={() => fetchTasks()} />}
            {!rawTasks && <DotsText text={'Loading'} />}
        </Container>
    );
};

export default Tasks;
