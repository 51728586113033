import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionRefreshReportedListings } from '../../store/ReportedListingsStore/actions';
import { selectIncludeReviewed } from '../../store/ReportedListingsStore/selectors';
import { Card, Col, FormGroup, Input, Row } from '../style';

const Filter = () => {
    const dispatch = useDispatch();

    const includeReviewed = useSelector(selectIncludeReviewed);

    const onChange = (e) => {
        dispatch(actionRefreshReportedListings(e.target.value === 'true'));
    };

    return (
        <Card>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Input value={includeReviewed ? 'true' : 'false'} onChange={onChange} type="select">
                            <option value="false">Only pending reports</option>
                            <option value="true">Include reviewed reports</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
        </Card>
    );
};

export default Filter;
