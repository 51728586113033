import React, { useState } from 'react';
import ApiTms from '../../../../api/api-tms';
import styled from 'styled-components';
import { Button, Divider, Spinner } from '../../../style';
import StyledModal from '../../../Shared/styled-modal';
import store from '../../../../store/store';
import { push } from 'connected-react-router';

interface TMSDeleteActionProps {
    serverId: number;
    layerId: number;
    children: React.ReactNode;
}

const TMSLayerDeleteAction = (props: TMSDeleteActionProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeletingLayer, setIsDeletingLayer] = useState(false);
    const toggleDeleteModal = () => {
        setIsDeleteModalOpen((prevState) => !prevState);
    };

    const handleClickDeleteLayer = () => {
        setIsDeletingLayer(true);
        ApiTms.deleteTMSLayer(props.serverId, props.layerId)
            .then((_) => {
                alert('Server was deleted');
                store.dispatch(push(`/tms/${props.serverId}`));
            })
            .catch((err) => {
                alert('An error occurred while deleting this server: ' + err.message);
            });
    };

    return (
        <React.Fragment>
            <span onClick={() => setIsDeleteModalOpen(true)}>{props.children}</span>

            <StyledModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(false)}
                title="Are you sure you want to delete this layer?"
                width="600px"
            >
                <React.Fragment>
                    <ModalWarningText>Are you sure you want to delete this layer?</ModalWarningText>
                    <Divider />
                    <ActionButtonContainer>
                        <Button disabled={isDeletingLayer} color="danger" onClick={() => handleClickDeleteLayer()}>
                            {isDeletingLayer ? (
                                <>
                                    <Spinner />
                                    Delete this layer from Soar
                                </>
                            ) : (
                                <>Delete this layer from Soar</>
                            )}
                        </Button>
                        <Button color="secondary" onClick={toggleDeleteModal}>
                            Go back
                        </Button>
                    </ActionButtonContainer>
                    <Divider />
                </React.Fragment>
            </StyledModal>
        </React.Fragment>
    );
};

export default TMSLayerDeleteAction;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const ModalWarningText = styled.p`
    margin: 18px;
    color: white;
    font-size: 16px;
`;
