import React, { useEffect, useState } from 'react';
import { ProductDTO } from '../../api/model-orders';
import { FormGroup, Input, Label } from '../style';

interface NewOrderSelectProductProps {
    products: ProductDTO[];
    handleSelectProduct: (product: ProductDTO | undefined) => void;
    selectedProduct?: ProductDTO;
}

const NewOrderSelectProduct = (props: NewOrderSelectProductProps) => {
    const { products, handleSelectProduct } = props;
    const [selectedProduct, setSelectedProduct] = useState<ProductDTO | undefined>(undefined);

    useEffect(() => {
        setSelectedProduct(props.selectedProduct);
    }, [props.selectedProduct]);

    return (
        <FormGroup>
            <Label for="supplier">Product</Label>
            <Input
                type="select"
                name="product"
                value={selectedProduct ? selectedProduct.name : undefined}
                onChange={(e) => {
                    const selected: ProductDTO | undefined = products.find((t) => t.name === e.target.value);
                    setSelectedProduct(selected);
                    handleSelectProduct(selected);
                }}
            >
                <option value={undefined} selected={products === undefined}>
                    Select a product
                </option>
                {products.map((t) => {
                    return (
                        <option id={String(t.code)} value={t.name} selected={selectedProduct?.name === t.name}>
                            {t.name}
                        </option>
                    );
                })}
            </Input>
        </FormGroup>
    );
};

export default NewOrderSelectProduct;
