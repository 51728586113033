import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import ApiManagedProfiles, { ManagedProfileBody, ManagedProfileFullDTO } from '../../../../api/api-managed-profiles';
import NotificationUtil from '../../../../lib/notification-util';
import { Button, Card, Col, Row } from '../../../style';
import ProfileEditDetails from './profile-edit-details';

interface ProfileDetailsProps {
    profile: ManagedProfileFullDTO;
    onUpdate: (profile: ManagedProfileFullDTO) => void;
}

const ProfileDetails = (props: ProfileDetailsProps) => {
    const { profile } = props;

    const [userDetails, setUserDetails] = useState<ManagedProfileBody>();
    const [profileChanged, setProfileChanged] = useState(false);
    const [profileDetailsBusy, setProfileDetailsBusy] = useState(false);

    useEffect(() => {
        if (userDetails) {
            //todo: check if the user details have changed
            setProfileChanged(true);
        }
    }, [userDetails]);

    const handleSave = async () => {
        setProfileDetailsBusy(true);
        try {
            await ApiManagedProfiles.updateProfile(profile.user.userId, userDetails);
            NotificationUtil.showSuccessMessage('Profile Updated!');
        } finally {
            setProfileDetailsBusy(false);
        }
    };

    const memoizedSetUserDetails = useCallback(
        (details) => {
            setUserDetails(details);
        },
        [setUserDetails]
    );

    return (
        <React.Fragment>
            <Card>
                <ProfileEditDetails user={profile.user} onChange={memoizedSetUserDetails} />
                <Row>
                    <Col>
                        <ProfileDetailsControls>
                            <SaveButton onClick={handleSave} disabled={!profileChanged || profileDetailsBusy}>
                                Save Changes
                            </SaveButton>
                        </ProfileDetailsControls>
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    );
};

export default ProfileDetails;

const ProfileDetailsControls = styled.div`
    text-align: center;
`;
const SaveButton = styled(Button)``;
