import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { SoarReportDTO } from '../../../api/model';

interface CumulativeUsersBarChartProps {
    report: SoarReportDTO;
    width?: string;
    height?: string;
    backgroundColor?: string;
    style?: React.CSSProperties;
}

const CumulativeUsersChartBar: React.FC<CumulativeUsersBarChartProps> = (props) => {
    const { report } = props;
    const [usersData, setUsersData] = useState<any[]>([]);

    useEffect(() => {
        if (report && report.usersByMonth) {
            let data = [];
            let accumulator = 0;
            report.usersByMonth.intervals.forEach((value) => {
                accumulator = accumulator + value.total;
                data.push([value.label, accumulator]);
            });
            if (data.length > 180) {
                data = data.slice(-180);
            }
            data.unshift(['Date', 'Registrations']);
            setUsersData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.report]);

    return (
        <div className="soar-report-graph-container">
            <Chart
                style={{ marginLeft: '10px', marginTop: '-50px' }}
                width={props.width ? props.width : '100%'}
                height={props.height ? props.height : '600px'}
                chartType="BarChart"
                data={usersData}
                options={{
                    legend: 'none',
                    orientation: 'horizontal',
                    colors: ['#eed926'],
                    backgroundColor: props.backgroundColor ? props.backgroundColor : null,
                    textStyle: {
                        color: 'white',
                    },
                    chartArea: { width: '90%' },
                    hAxis: {
                        minValue: 0,
                        textStyle: {
                            color: 'white',
                        },
                    },
                    vAxis: {
                        textStyle: {
                            color: 'white',
                        },
                    },
                }}
                // For tests
                rootProps={{ 'data-testid': '1' }}
            />
        </div>
    );
};

export default CumulativeUsersChartBar;
