import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import './leaflet-screenshot-control.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
const domtoimage = require('dom-to-image');

export const ScreenshotControlStartEvent = 'screenshot-control-start';
export const ScreenshotControlCompleteEvent = 'screenshot-control-complete';

function LeafletScreenshotControl(props) {
    const map = useMap();

    useEffect(() => {
        if (!map) return;

        const ScreenshotControl = L.Control.extend({
            onAdd: function () {
                const containerClassName = props.controlContainerClassName || 'leaflet-control-screenshot leaflet-bar leaflet-control';
                const container = L.DomUtil.create('div', containerClassName);

                const btnClass = props.controlButtonClassName || 'leaflet-control-screenshot-button';
                const link = L.DomUtil.create('a', btnClass, container);
                link.title = props.title || 'Take screenshot of map';
                link.innerHTML = props.buttonText || 'Take screenshot';
                L.DomEvent.on(link, 'click', this._printMap, this);
                L.DomEvent.disableClickPropagation(container);

                return container;
            },
            _printMap: function () {
                map.fire(ScreenshotControlStartEvent);
                const mapContainer = map.getContainer();

                const controlContainer = document.getElementsByClassName("leaflet-control-container")[0];
                controlContainer.style.display = 'none';

                domtoimage.toPng(mapContainer, {
                    width: parseInt(props.width || 256),
                    height: parseInt(props.height || 256)
                }).then(function (dataUrl) {
                    map.fire(ScreenshotControlCompleteEvent, { dataUrl: dataUrl });
                    controlContainer.style.display = 'block'; // Show controls again
                }).catch(function (error) {
                    console.error('Screenshot operation failed', error);
                    map.fire(ScreenshotControlCompleteEvent, { error: 'error' });
                    controlContainer.style.display = 'block'; // Show controls again
                });
            }
        });

        const screenshotControl = new ScreenshotControl({ position: props.position || 'topleft' });
        map.addControl(screenshotControl);

        return () => {
            map.removeControl(screenshotControl);
        };
    }, [map, props.buttonText, props.controlButtonClassName, props.controlContainerClassName, props.height, props.position, props.title, props.width])

    return null;
}

export default LeafletScreenshotControl;
