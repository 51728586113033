import React, { useEffect, useState } from 'react';
import ApiUser from '../../../api/api-user';
import { UserDTO, UserForAdminDTO } from '../../../api/model';
import ButtonsPanel from '../../Shared/buttons-panel';
import SoarModal from '../../Shared/soar-modal';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Card,
    ConfirmButtonContainer,
    ConfirmModalFooter,
    ConfirmModalText,
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Spinner,
} from '../../style';
import UserManagementCard from './user-management-card';
import styled from 'styled-components';
import { toast } from 'react-toastify';

interface Props {
    user: UserForAdminDTO;
    onDeleted: (userId: string) => void;
}

const ManageUserDetailsAdmin = (props: Props) => {
    const [user, setUser] = useState<UserDTO | undefined>(undefined);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isHeroUser, setIsHeroUser] = useState<boolean>(false);
    const [isModerator, setIsModerator] = useState<boolean>(false);

    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const refreshUpdatedUser = async () => {
        const res = await ApiUser.getUserById(props.user.id);
        if (res) {
            setUser(res);
            toast.success('Updated Permissions');
        } else {
            toast.success('Something has gone wrong, please try again');
        }
        setLoading(false);
    };

    useEffect(() => {
        const refreshUser = async () => {
            const res = await ApiUser.getUserById(props.user.id);
            setUser(res);
            setIsHeroUser(res.reputable);
            setIsModerator(res.communityModerator);
        };
        if (props.user) {
            refreshUser();
        } else {
            setUser(undefined);
        }
        setLoading(false);
    }, [props.user, props.user.id]);

    const applyChanges = async () => {
        setLoading(true);
        await ApiUser.saveUserPrivileges(user.userId, isHeroUser, isModerator);
        refreshUpdatedUser();
    };

    const onDelete = async () => {
        setLoading(true);
        try {
            await ApiUser.deleteUser(user.userId);
            props.onDeleted(user.userId);
        } catch (err) {
            alert(err);
        }
        setLoading(false);
        toggleDelete();
    };

    const toggleDelete = () => setShowDeleteDialog(!showDeleteDialog);

    return (
        <React.Fragment>
            <UserManagementCard>
                <Accordion flush open={open} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">Admin Control</AccordionHeader>
                        <AccordionBody accordionId="1" style={{ 'padding-top': '8px' }}>
                            {!user ? (
                                <Card height="200px">
                                    <Spinner />
                                </Card>
                            ) : (
                                <React.Fragment>
                                    <Row>
                                        <Col>
                                            <FormGroup switch>
                                                <Input
                                                    id="heroUserSwitch"
                                                    type="switch"
                                                    checked={isHeroUser}
                                                    onChange={() => setIsHeroUser(!isHeroUser)}
                                                />
                                                <SwitchLabel for="heroUserSwitch" check>
                                                    🏆 Hero user
                                                </SwitchLabel>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup switch>
                                                <Input
                                                    id="moderatorSwitch"
                                                    type="switch"
                                                    checked={isModerator}
                                                    onChange={() => setIsModerator(!isModerator)}
                                                />
                                                <SwitchLabel for="moderatorSwitch" check>
                                                    😎 Community moderator
                                                </SwitchLabel>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <AdminButtonPanel>
                                        <Button disabled={loading} onClick={applyChanges}>
                                            Apply changes
                                        </Button>
                                        <Button disabled={loading} onClick={toggleDelete} color="danger">
                                            Delete
                                        </Button>
                                    </AdminButtonPanel>
                                </React.Fragment>
                            )}
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </UserManagementCard>
            {showDeleteDialog && (
                <SoarModal isOpen={true} title="Delete User" onClosed={() => setShowDeleteDialog(false)}>
                    <ConfirmModalText>{`Are you sure to delete user ${user.email}?`}</ConfirmModalText>
                    <ConfirmModalFooter>
                        <ConfirmButtonContainer>
                            <Button color="danger" onClick={onDelete}>
                                Delete
                            </Button>
                            <Button color="warning" onClick={toggleDelete}>
                                Cancel
                            </Button>
                        </ConfirmButtonContainer>
                    </ConfirmModalFooter>
                </SoarModal>
            )}
        </React.Fragment>
    );
};

export default ManageUserDetailsAdmin;

const AdminButtonPanel = styled(ButtonsPanel)`
    margin-top: 0px;
`;

const SwitchLabel = styled(Label)`
    color: white;
    font-weight: bold;
`;
