import React from 'react';
import styled from 'styled-components';
import { Button } from '../style';

interface Props {
    faIcon: string;
    onClick: () => void;
    title?: string;
    text?: string;
    color?: string;
    fontSize?: string;
}

const IconButton = (props: Props) => {
    const { faIcon, onClick, text, title, color, fontSize } = props;
    return (
        <StyledIconButton title={title} onClick={onClick} color="link" iconColor={color} fontSize={fontSize}>
            <i className={`fa ${faIcon}`} />
            {text}
        </StyledIconButton>
    );
};

export default IconButton;

interface ButtonProps {
    iconColor?: string;
    fontSize?: string;
}

const StyledIconButton = styled<ButtonProps>(Button)`
    padding: 0px;
    margin: 0px 5px;
    color: ${(props) => (props.iconColor ? props.iconColor : null)};
    font-size: ${(props) => props.fontSize && props.fontSize};
`;
