import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ApiUser from '../../../api/api-user';
import { Button, Card, Col, Container, Input, InputGroup, Label, Row, Subtitle, Title } from '../../style';
import ReputableUsersTable from './reputable-users-table';

const ReputableUsers = () => {
    const [newReputableUserEmailAddress, setNewReputableUserEmailAddress] = useState('');

    const handleAddNewReputableUser = () => {
        // Basic check for email address
        if (!newReputableUserEmailAddress?.includes('@' && '.')) {
            toast.error('Please enter a valid email address');
            return;
        }

        ApiUser.getUserByEmail(newReputableUserEmailAddress)
            .then((user) => {
                ApiUser.makeReputable(user.id)
                    .then((_) => {
                        alert('User is now reputable');
                        window.location.reload(); // I'm too lazy for proper invalidation
                    })
                    .catch((err) => {
                        alert(err);
                    });
            })
            .catch((err) => {
                alert(err);
                setNewReputableUserEmailAddress('');
            });
    };

    return (
        <Container>
            <Title>Manage Reputable Users</Title>
            <Card>
                <Row>
                    <Col md={6}>
                        <Label for="email">Add new Reputable User</Label>
                        <InputGroup>
                            <Input
                                name="email"
                                type="text"
                                placeholder="Enter a valid admin user email"
                                value={newReputableUserEmailAddress}
                                onChange={(e) => setNewReputableUserEmailAddress(e.target.value)}
                            />
                            <Button onClick={() => handleAddNewReputableUser()}>Add new Reputable User</Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Card>

            <Card>
                <Subtitle>Reputable Users</Subtitle>
                <ReputableUsersTable />
            </Card>
        </Container>
    );
};

export default ReputableUsers;
