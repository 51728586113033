import Cookies from 'universal-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import CognitoUtil from './cognito-util';

interface JwtPayloadUser extends JwtPayload {
    email: string;
}

export default class UserHelper {
    private static cookies = new Cookies();
    private static COOKIE_MAX_AGE = 31536000; // 1 year
    private static COOKIE_ID_TOKEN = 'soar-admin-id-token-v2';
    private static COOKIE_REFRESH_TOKEN = 'soar-admin-refresh-token-v2';

    public static saveIdToken(idToken: string) {
        this.cookies.set(this.COOKIE_ID_TOKEN, idToken, {
            path: '/',
            maxAge: this.COOKIE_MAX_AGE,
            sameSite: 'strict',
            secure: true,
        });
    }

    public static saveRefreshToken(refreshToken: string) {
        this.cookies.set(this.COOKIE_REFRESH_TOKEN, refreshToken, {
            path: '/',
            maxAge: this.COOKIE_MAX_AGE,
            sameSite: 'strict',
            secure: true,
        });
    }

    public static hasValidIdToken(): boolean {
        return this.validateAndGetIdToken() ? true : false;
    }

    public static validateAndGetIdToken(): string {
        const idToken = this.cookies.get(this.COOKIE_ID_TOKEN);

        if (idToken) {
            try {
                const now = new Date().getTime();
                const decoded = jwtDecode<JwtPayloadUser>(idToken);

                if (now > decoded.exp * 1000) {
                    console.log('Resetting ID token due to expired JWT');
                    return undefined;
                }
            } catch (err) {
                console.log('Resetting ID token due to error decoding');
                this.resetIdToken();
                return undefined;
            }
        }
        return idToken;
    }

    public static getRefreshToken(): string {
        return this.cookies.get(this.COOKIE_REFRESH_TOKEN);
    }

    public static async refreshAuth(): Promise<string> {
        const refreshToken = this.cookies.get(this.COOKIE_REFRESH_TOKEN);

        if (refreshToken) {
            try {
                const refreshAuthResponse = await CognitoUtil.refreshToken(refreshToken);

                this.saveIdToken(refreshAuthResponse.IdToken);

                return refreshAuthResponse.IdToken;
            } catch (err) {
                console.log('Refreshing token error');

                this.resetIdToken();
                this.resetRefreshToken();
            }
        }
        return undefined;
    }

    public static resetIdToken(): any {
        this.cookies.remove(this.COOKIE_ID_TOKEN, { path: '/' });
    }

    public static resetRefreshToken(): any {
        this.cookies.remove(this.COOKIE_REFRESH_TOKEN, { path: '/' });
    }

    // quick hack to show date filter for specified users and not break styling for Amir
    static SHOW_FILTER_USERS_EMAILS = ['neil@soar.earth', 'marek@takor.com.au'];
    static showUserDateFilter(): boolean {
        const idToken = this.cookies.get(this.COOKIE_ID_TOKEN);
        try {
            const decoded = jwtDecode<JwtPayloadUser>(idToken);
            return this.SHOW_FILTER_USERS_EMAILS.includes(decoded.email);
        } catch (err) {
            //no-empty
        }
        return false;
    }

    public static formatTags = (tags) => {
        if (!tags || !tags[0]) {
            return [];
        }
        return tags.map((tag) => {
            return `#${tag.replace('#', '')}`;
        });
    };
}
