import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import store from '../../../../store/store';
import {
    Button,
    Divider,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner,
    Subtitle,
    Table,
    TableWrapper,
} from '../../../style';
import ApiSTAC, { STACCatalogDTO, STACCatalogFullDTO } from '../../../../api/api-stac';
import { useDispatch } from 'react-redux';
import { actionAddUpdateSTACChildren } from '../../../../store/ExternalMapServicesStore/actions';

interface StacCatalogChildrenProps {
    stac: STACCatalogFullDTO;
}

const StacCatalogChildren = (props: StacCatalogChildrenProps) => {
    const dispatch = useDispatch();

    const [isLoadingCatalogChildren, setIsLoadingCatalogChildren] = useState<boolean>(false);
    const [catalogs, setCatalogs] = useState<STACCatalogDTO[]>([]);
    const [activeCatalogs, setActiveCatalogs] = useState<STACCatalogDTO[]>([]);
    const [inactiveCatalogs, setInactiveCatalogs] = useState<STACCatalogDTO[]>([]);
    const [filteredActiveCatalogs, setFilteredActiveCatalogs] = useState<STACCatalogDTO[]>([]);
    const [filteredInactiveCatalogs, setFilteredInactiveCatalogs] = useState<STACCatalogDTO[]>([]);
    const [titleFilter, setTitleFilter] = useState<string>('');

    useEffect(() => {
        setIsLoadingCatalogChildren(true);
        ApiSTAC.getSTACCatalogChildren(props.stac.id)
            .then((catalogsRaw) => {
                setCatalogs(catalogsRaw.sort((a, b) => (a.title > b.title ? 1 : -1)));
            })
            .catch((err) => {
                toast.error('Failed to get STAC Catalogs', err);
            })
            .finally(() => {
                setIsLoadingCatalogChildren(false);
            });
    }, [props.stac]);

    useEffect(() => {
        dispatch(
            actionAddUpdateSTACChildren(
                props.stac.id,
                catalogs.map((t) => t.id)
            )
        );
    }, [catalogs, props.stac, dispatch]);

    useEffect(() => {
        setInactiveCatalogs(catalogs.filter((t) => t.public === false));
        setActiveCatalogs(catalogs.filter((t) => t.public === true));
        setFilteredActiveCatalogs(catalogs.filter((t) => t.public === true));
        setFilteredInactiveCatalogs(catalogs.filter((t) => t.public === false));
    }, [catalogs]);

    useEffect(() => {
        const filterCatalogs = (catalogs: STACCatalogDTO[]) => {
            if (!titleFilter || titleFilter === 'Select Filter') {
                return catalogs;
            }
            const lowerCaseFilter = titleFilter.toLowerCase();
            return catalogs.filter((catalog) => catalog.title?.toLowerCase().includes(lowerCaseFilter));
        };

        setFilteredActiveCatalogs(filterCatalogs(activeCatalogs));
        setFilteredInactiveCatalogs(filterCatalogs(inactiveCatalogs));
    }, [activeCatalogs, inactiveCatalogs, titleFilter]);

    const sortableHeader = (column) => {
        return (
            <React.Fragment>
                {column.text}
                <SortIcon className="fa fa-sort" />
            </React.Fragment>
        );
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            headerFormatter: sortableHeader,
            headerStyle: () => {
                return {
                    width: '40px',
                };
            },
        },
        {
            dataField: 'title',
            text: 'Catalog Item title',
            sort: true,
            headerFormatter: sortableHeader,
            formatter: (_, row: STACCatalogFullDTO) => {
                return <span key={row.titleOvr || row.title}>{row.titleOvr || row.title}</span>;
            },
        },
        {
            dataField: 'totalChildren',
            text: 'Children',
            formatter: (_, row: STACCatalogFullDTO) => {
                return row.totalChildren;
            },
        },
        {
            dataField: 'totalItems',
            text: 'Items',
            sort: true,
            headerFormatter: sortableHeader,
            formatter: (_, row: STACCatalogFullDTO) => {
                return row.totalItems;
            },
        },
        {
            dataField: 'mosaic',
            text: 'Mosaic',
            sort: true,
            headerFormatter: sortableHeader,
            formatter: (_, row: STACCatalogFullDTO) => {
                if (row.mosaic) {
                    return <Checkmark className="fa fa-check" />;
                } else {
                    return <React.Fragment />;
                }
            },
        },
        {
            dataField: 'public',
            text: 'Available in Soar',
            headerStyle: () => {
                return {
                    width: '140px',
                };
            },
            formatter: (_, row: STACCatalogFullDTO) => {
                if (row.public) {
                    return <Checkmark className="fa fa-check" />;
                } else {
                    return <React.Fragment />;
                }
            },
        },
    ];

    const prepareCatalogChildLink = (child: STACCatalogDTO): string => {
        return `/stac/${child.id}`;
    };

    const onClickRow = {
        onClick: (_e, row: STACCatalogDTO) => {
            if (row) store.dispatch(push(prepareCatalogChildLink(row)));
        },
        onAuxClick: (_e, row: STACCatalogDTO) => {
            const win = window.open(prepareCatalogChildLink(row), '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    if (isLoadingCatalogChildren) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <Row>
                <FormGroup>
                    <Label>Filter by Title</Label>
                    <InputGroup>
                        <Input
                            value={titleFilter}
                            placeholder="Search by title..."
                            onChange={(e) => setTitleFilter(e.target.value)}
                        />
                        {titleFilter && (
                            <ClearFilter color="link" onClick={() => setTitleFilter('')}>
                                <i className="fa fa-times" />
                            </ClearFilter>
                        )}
                    </InputGroup>
                </FormGroup>
            </Row>
            {activeCatalogs.length > 0 ? (
                <React.Fragment>
                    <Subtitle>
                        Catalog items available in Soar ({activeCatalogs.length} total, displaying{' '}
                        {filteredActiveCatalogs.length})
                    </Subtitle>
                    <ToolkitProvider
                        keyField="id"
                        data={filteredActiveCatalogs}
                        columns={columns}
                        filterFactory={filterFactory()}
                        search={{ searchFormatted: true }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table {...props.baseProps} rowEvents={onClickRow} />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}

            {activeCatalogs.length > 0 && inactiveCatalogs.length > 0 ? <Divider /> : null}

            {inactiveCatalogs.length > 0 ? (
                <React.Fragment>
                    <Subtitle>
                        Catalog items not available in Soar ({inactiveCatalogs.length} total), displaying{' '}
                        {filteredInactiveCatalogs.length}
                    </Subtitle>
                    <ToolkitProvider
                        keyField="id"
                        data={filteredInactiveCatalogs}
                        columns={columns}
                        filterFactory={filterFactory()}
                        search={{ searchFormatted: true }}
                    >
                        {(props) => (
                            <React.Fragment>
                                <TableWrapper>
                                    <Table {...props.baseProps} rowEvents={onClickRow} />
                                </TableWrapper>
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default StacCatalogChildren;

const Checkmark = styled.i`
    color: green;
`;

const ClearFilter = styled(Button)`
    background-color: white !important;
    border: none !important;
    color: black;

    :hover {
        background-color: white !important;
        border: none !important;
        box-shadow: none !important;
    }

    i {
        color: black;
    }
`;

const SortIcon = styled.i`
    margin-left: 5px;
    vertical-align: middle;
    color: rgba(255, 255, 255, 0.87);
`;
