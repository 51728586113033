import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Input } from '../style';

interface NewTrackerProps {
    trackerCode: string;
    onReset: () => void;
}

// TODO this does not seem to work?
const NewTracker = (props: NewTrackerProps) => {
    const DEFAULT_URI = 'https://soar.earth';

    const [URI, setURI] = useState(DEFAULT_URI);

    const generateTrackerURI = (): string => {
        if (URI.includes('?')) {
            return `${URI}&t=${props.trackerCode}`;
        }

        return `${URI}?t=${props.trackerCode}`;
    };

    const handleCopyTracker = () => {
        const textField = document.createElement('textarea');
        textField.innerText = generateTrackerURI();
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        alert('Copied to clipboard');
    };

    const handleSetCustomURI = (value: string) => {
        if (value.length === 0) {
            setURI(DEFAULT_URI);
        } else {
            setURI(value);
        }
    };

    return (
        <React.Fragment>
            <Title>You successfully created a new Campaign Tracker Code</Title>
            <FlexDiv>
                <TrackerCode onClick={handleCopyTracker}>{generateTrackerURI()}</TrackerCode>
            </FlexDiv>

            <SubTitle>Or use a different URL</SubTitle>

            <FlexDiv>
                <CustomUriInput
                    value={URI}
                    onChange={(e) => handleSetCustomURI(e.target.value as string)}
                    type="text"
                    name="custom-uri"
                    placeholder="Use your own URL"
                />
            </FlexDiv>

            <FlexDiv>
                <Button onClick={() => props.onReset()}>Start again</Button>
            </FlexDiv>
        </React.Fragment>
    );
};

export default NewTracker;

const Title = styled.h2`
    flex-grow: 1;
    margin: 20px auto;
`;

const SubTitle = styled.h4`
    margin: 20px auto;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 10px 0 20px 0;
    align-items: flex-end;

    @media (min-width: 768px) {
        flex-direction: row;
        & :not(:first-child) {
            margin-left: 10px;
        }
    }

    .form-control {
        width: auto;
    }
`;

const TrackerCode = styled.span`
    font-weight: bolder;
    color: green;
    font-size: 1.4rem;
    cursor: copy;
    padding: 18px;
    border: 1px dotted black;
`;

const CustomUriInput = styled(Input)`
    margin-top: 10px;
    min-width: 50%;

    @media (min-width: 768px) {
        margin-top: 0px;
    }
`;
