import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TMSLayer, TMSServer } from '../../../api/api-tms';
import { WMSLayer, WMSServer } from '../../../api/api-wms';
import { Subtitle, Table, TableWrapper } from '../../style';
import { STACCatalogFullDTO, STACCatalogDTO, STACItemFullDTO } from '../../../api/api-stac';

interface LayerDetailsProps {
    title: string;
    data: TMSLayer | WMSLayer | TMSServer | WMSServer | STACCatalogFullDTO | STACCatalogDTO | STACItemFullDTO;
}

const MapServicesDetailsTable = (props: LayerDetailsProps) => {
    const data = Object.entries(props.data).map((dict) => {
        if (dict[0] === 'tileMatrixSet') {
            return { keys: dict[0], values: dict[1].identifier };
        }
        return { keys: dict[0], values: dict[1] };
    });
    const columns = [
        {
            dataField: 'keys',
            text: 'Key',
        },
        {
            dataField: 'values',
            text: 'Value',
        },
    ];

    return (
        <React.Fragment>
            <Subtitle>{props.title}</Subtitle>
            <TableWrapper>
                <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={columns}
                    search={{ searchFormatted: true }}
                    exportCSV={{
                        fileName: `soar-${props.title}-metadata.csv`,
                    }}
                >
                    {(props) => (
                        <React.Fragment>
                            <TableWrapper>
                                <Table {...props.baseProps} />
                            </TableWrapper>
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </TableWrapper>
        </React.Fragment>
    );
};

export default MapServicesDetailsTable;
