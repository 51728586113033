import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { LayersControl, TileLayer, WMSTileLayer, GeoJSON } from 'react-leaflet';
import { Spinner } from 'reactstrap';
import GeoUtil from '../../../../lib/geo-util';
import BoundingBoxControl, {
    BoundingBoxControlEvent,
} from '../../../MapView/Annotations/BoundingBoxControl/leaflet-bbox-control';
import LeafletScreenshotControl, {
    ScreenshotControlStartEvent,
    ScreenshotControlCompleteEvent,
} from '../../../MapView/Annotations/ScreenShotControl/leaflet-screenshot-control';
import LeafletMap from '../../../MapView/leaflet-map';
import OpacitySlider from '../../../Shared/opacity-slider';
import LeafletBaseMaps from '../../../Shared/leaflet-basemaps';
import { WMSLayer, CRSDefs } from '../../../../api/api-wms';
import { TMSLayer } from '../../../../api/api-tms';
import { Subtitle } from '../../../style';
import { STACCatalogFullDTO, STACItemFullDTO } from '../../../../api/api-stac';
import WMTSTileLayer from '../../WMS/Layer/wmts-tile';

interface MapServicesLayerPreviewMapProps {
    layer: WMSLayer | TMSLayer | STACCatalogFullDTO | STACItemFullDTO;
    onGeneratePreview?: (previewBase64: string) => void;
    onUsePreviewBoundingBox?: (boundingBox: string) => void;
    setMapZoomLevel?: (zoomLevel: number) => void;
    isTMS?: boolean;
    isWMS?: boolean;
    isStac?: boolean;
    fullWidth?: boolean;
}

const DEFAULT_BBOX_WKT = 'POLYGON ((-180 -90, 180 -90, 180 90, -180 90, -180 -90))';

const MapServicesLayerPreviewMap = (props: MapServicesLayerPreviewMapProps) => {
    const leafletMapRef = useRef(null);

    const [previewLoading, setPreviewLoading] = useState(false);
    const [hasSetMapEventHandlers, setHasSetMapEventHandlers] = useState(false);
    const [mapReady, setMapReady] = useState(false);
    const [opacity, setOpacity] = useState(1.0);
    const [polygonOpacity, setPolygonOpacity] = useState(1);

    const { onGeneratePreview, onUsePreviewBoundingBox } = props;

    const isWMSLayer = (layer: TMSLayer | WMSLayer | STACCatalogFullDTO | STACItemFullDTO): layer is WMSLayer => {
        return (layer as WMSLayer) !== undefined && props.isWMS;
    };

    useEffect(() => {
        const layerBBox = isWMSLayer(props.layer)
            ? props.layer.boundingBoxWKTOvr || props.layer.boundingBoxWKT
            : props.layer.boundingBoxWKT || DEFAULT_BBOX_WKT;

        if (leafletMapRef?.current && layerBBox && !hasSetMapEventHandlers) {
            setHasSetMapEventHandlers(true);
            const leafletMap = leafletMapRef.current;
            const bounds = GeoUtil.latLngBoundsFromPolygonWKT(layerBBox);
            leafletMap.fitBounds(bounds);

            leafletMap.on(ScreenshotControlStartEvent, () => {
                setPreviewLoading(true);
            });

            leafletMap.on(ScreenshotControlCompleteEvent, (e) => {
                setPreviewLoading(false);
                if (e.error && e.error === 'error') {
                    alert(
                        'Generating a screenshot failed possibly due to CORS or something outside our control. You can manually upload a screenshot :)'
                    );
                    return;
                }
                onGeneratePreview(e.dataUrl);
            });

            leafletMap.on(BoundingBoxControlEvent, (e) => {
                const { boundingBox } = e;
                onUsePreviewBoundingBox(boundingBox);
                alert('Bounding box value updated to that of map preview');
            });
        }
        // we need the leafletMapRef.current to trigger the event but ts complains that it is not needed which is wrong..
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leafletMapRef?.current, props.layer, onGeneratePreview, onUsePreviewBoundingBox, hasSetMapEventHandlers]);

    const handleMapReady = () => {
        requestAnimationFrame(() => {
            setMapReady(true);
        });
    };

    useEffect(() => {
        if (mapReady) {
            const map = leafletMapRef?.current;
            const handleZoomEnd = () => {
                if (props.setMapZoomLevel) {
                    props.setMapZoomLevel(map.getZoom() || 28);
                }
            };
            map.on('zoomend', handleZoomEnd);

            return () => {
                map.off('zoomend', handleZoomEnd);
            };
        }
        return undefined;
    }, [props, mapReady]);

    const renderLayer = (layer) => {
        if (props.isWMS && (layer as WMSLayer) && layer.standard === 'WMS') {
            return displayWMSLayer(layer);
        } else if (props.isWMS && (layer as WMSLayer) && layer.standard === 'WMTS') {
            return displayWMTSLayer(layer);
        } else if (props.isTMS && (layer as TMSLayer)) {
            return displayTMSLayer(layer);
        } else if (props.isStac && (layer as STACCatalogFullDTO)) {
            return displayStacCatalogItem(layer);
        } else {
            return displayDefaultLayer(layer);
        }
    };

    const displayDefaultLayer = (layer) => {
        return <TileLayer key={layer.title} url={layer.url} tms={false} opacity={opacity} zIndex={1000} maxZoom={28} />;
    };

    const displayStacCatalogItem = (layer: STACCatalogFullDTO) => {
        const minZoom = layer.minZoomOvr || layer.minZoom;
        return (
            <React.Fragment>
                {layer.geometryGeoJson && (
                    <GeoJSON
                        data={JSON.parse(layer.geometryGeoJson as string)}
                        style={{ fillOpacity: 0, color: 'red', opacity: polygonOpacity }}
                    />
                )}
                <TileLayer
                    key={layer.title}
                    url={layer.url || layer.mosaicLayerUrl}
                    tms={props.isTMS || false}
                    opacity={opacity}
                    zIndex={1000}
                    maxZoom={28}
                    minZoom={minZoom}
                    minNativeZoom={minZoom}
                />
            </React.Fragment>
        );
    };

    const displayTMSLayer = (layer: TMSLayer) => {
        return (
            <TileLayer
                key={layer.title}
                url={layer.url}
                tms={props.isTMS || false}
                opacity={opacity}
                zIndex={1000}
                maxZoom={28}
            />
        );
    };

    const displayWMSLayer = (layer: WMSLayer) => {
        const srs = layer.srsOvr || layer.srs;
        const crs = CRSDefs[srs] ?? null;

        return (
            <WMSTileLayer
                key={layer.name}
                url={layer.url}
                layers={layer.name}
                transparent={true}
                opacity={opacity}
                format={layer.imageFormat}
                zIndex={1000}
                crs={crs}
                version={layer.version}
            />
        );
    };

    const displayWMTSLayer = (layer: WMSLayer) => {
        return (
            <WMTSTileLayer
                key={layer.name}
                url={layer.url}
                layer={layer.name}
                tileMatrixSet={layer.tileMatrixSet?.identifier}
                matrixIds={layer.tileMatrixSet?.matrices}
                resourceUrl={layer.resourceUrl}
                style={layer.style}
                wmtsType={layer.wmtsType}
                opacity={opacity}
                zIndex={1000}
                crs={layer.srsOvr ?? layer.srsOvr}
            />
        );
    };

    return (
        <React.Fragment>
            <Subtitle>Preview</Subtitle>
            <MapContainer fullWidth={props.fullWidth}>
                {previewLoading ? (
                    <PreviewLoadingContainer>
                        <BigSpinner />
                    </PreviewLoadingContainer>
                ) : null}

                <LeafletMap
                    mapRef={leafletMapRef}
                    worldZoom={1}
                    zoomControl={true}
                    fullscreenControl={true}
                    mapReady={() => handleMapReady()}
                >
                    {renderLayer(props.layer)}

                    <LayersControl position="topright">
                        <LeafletBaseMaps />
                        {mapReady && leafletMapRef?.current ? (
                            <LeafletScreenshotControl
                                position="bottomleft"
                                buttonText="Use this map view as a preview"
                                width={leafletMapRef.current.getSize().x}
                                height={leafletMapRef.current.getSize().y}
                            />
                        ) : null}

                        {!props.isStac && mapReady && leafletMapRef?.current ? (
                            <BoundingBoxControl
                                position="bottomright"
                                buttonText="Use this bounding box"
                                width={leafletMapRef.current.getSize().x}
                                height={leafletMapRef.current.getSize().y}
                            />
                        ) : null}
                    </LayersControl>
                </LeafletMap>
            </MapContainer>
            {!(props.layer as STACCatalogFullDTO)?.geometryGeoJson ? (
                <PreviewOpacitySlider opacity={opacity} onChange={setOpacity} />
            ) : (
                <React.Fragment>
                    <PreviewOpacitySlider title={'STAC Transparency'} opacity={opacity} onChange={setOpacity} />
                    <PreviewOpacitySlider
                        title={'Polygon Transparency'}
                        opacity={polygonOpacity}
                        onChange={setPolygonOpacity}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MapServicesLayerPreviewMap;

const MapContainer = styled.div<{ fullWidth?: boolean }>`
    width: ${(props) => (props.fullWidth ? '100%' : '43.33vw')};
    height: 43.33vw;
    position: relative;
`;

const PreviewLoadingContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
`;

const BigSpinner = styled(Spinner)`
    width: 120px;
    height: 120px;
    position: absolute;
    color: #eed926;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
`;

const PreviewOpacitySlider = styled(OpacitySlider)`
    width: 100%;
    padding: 20px auto;
`;
