import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { HashTagsDTO } from '../../../api/model';
import { Button, ButtonWrapper, Panel, Table, TableWrapper } from '../../style';
import ViewHelper from '../../view-helper';

interface Props {
    items: HashTagsDTO[];
}

const HashtagTable = (props: Props) => {
    const { items } = props;

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'tag',
            text: 'Tag',
            sort: true,
            formatter: (cell) => (cell ? cell : ''),
        },
        {
            dataField: 'count',
            text: 'Occurrences',
            sort: true,
            formatter: (cell) => cell.toLocaleString(),
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <ButtonWrapper>
                <Button color="primary" onClick={handleClick}>
                    EXPORT CSV
                </Button>
            </ButtonWrapper>
        );
    };

    return (
        <ToolkitProvider
            keyField="id"
            data={items}
            columns={columns}
            exportCSV={{
                fileName: `hashtags_by_occurrences_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
            }}
        >
            {(props) => (
                <>
                    <TableWrapper>
                        <Table {...props.baseProps} pagination={paginationFactory()} />
                    </TableWrapper>
                    <Panel>
                        <MyExportCSV {...props.csvProps} />
                    </Panel>
                </>
            )}
        </ToolkitProvider>
    );
};

export default HashtagTable;
