import React, { useState } from 'react';
import { SupplierDTO } from '../../api/model-orders';
import { FormGroup, Input, Label } from '../style';

interface NewOrderSelectSupplierProps {
    suppliers: SupplierDTO[];
    handleSelectSupplier: (supplier: SupplierDTO | undefined) => void;
    selectedSupplier?: SupplierDTO;
}

const NewOrderSelectSupplier = (props: NewOrderSelectSupplierProps) => {
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierDTO | undefined>(props.selectedSupplier);
    const { suppliers, handleSelectSupplier } = props;

    return (
        <FormGroup>
            <Label for="supplier">Supplier</Label>
            <Input
                type="select"
                name="supplier"
                id="supplierId"
                value={selectedSupplier ? selectedSupplier.name : undefined}
                onChange={(e) => {
                    const selected: SupplierDTO | undefined = suppliers.find((t) => t.name === e.target.value);
                    setSelectedSupplier(selected);
                    handleSelectSupplier(selected);
                }}
            >
                <option value={undefined} selected={selectedSupplier === undefined}>
                    Select a supplier
                </option>
                {suppliers &&
                    suppliers.map((t) => {
                        return (
                            <option id={String(t.code)} value={t.name}>
                                {t.name}
                            </option>
                        );
                    })}
            </Input>
        </FormGroup>
    );
};

export default NewOrderSelectSupplier;
