import React from 'react';
import styled from 'styled-components';
import { Row } from '../../../style';
import TMSDeleteAction from './tms-server-delete-action';
import { TMSServer } from '../../../../api/api-tms';

interface TMSActionsProps {
    tmsServer: TMSServer;
    invalidateServer: () => void;
}

const TMSActions = (props: TMSActionsProps) => {
    return (
        <Row>
            <ActionButtonContainer>
                <TMSDeleteAction tms={props.tmsServer} invalidateServer={props.invalidateServer}>
                    <ActionButton title="Delete server from Soar" className="danger">
                        <ActionButtonIcon className="fa fa-trash" />
                    </ActionButton>
                </TMSDeleteAction>
            </ActionButtonContainer>
        </Row>
    );
};

export default TMSActions;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const ActionButton = styled.button`
    background: none !important;
    border: none;
    padding: 2px;
    margin: 0 4px;
    color: #eed926;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;

    &:hover {
        font-size: 16px;
        padding: 0;
    }

    &.danger {
        color: #dc3545;
    }
`;

const ActionButtonIcon = styled.i``;
