import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import SoarModal from '../../../Shared/soar-modal';
import UploadDropzone from '../../../Shared/Upload/upload-dropzone';
import { ConfirmModalFooter } from '../../../style';

interface Props {
    legendUrl?: string;
    onSelectUploadLegend: (file: File) => void;
}

const MapServicesLayerEditLegend = (props: Props) => {
    const [showUploadLegendModal, setShowUploadLegendModal] = useState<boolean>(false);
    const [preview, setPreview] = useState<string>(undefined);

    useEffect(() => {
        if (props.legendUrl) {
            setPreview(props.legendUrl);
        }

        return () => {
            setPreview(undefined);
        };
    }, [props.legendUrl]);

    const handleEditLegend = (file: File) => {
        setShowUploadLegendModal(false);
        props.onSelectUploadLegend(file);
    };

    const PreviewImage = () => {
        if (preview) {
            return (
                <React.Fragment>
                    <LegendPreview src={preview} />
                    <LegendUploadText>Replace this image</LegendUploadText>
                    <LegendUploadText>Click to select a file or drag and drop</LegendUploadText>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <LegendUploadText>Add an image</LegendUploadText>
                <LegendUploadText>Click to select a file or drag and drop</LegendUploadText>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <LegendEditIcon
                className={`fa fa-edit`}
                onClick={() => setShowUploadLegendModal(!showUploadLegendModal)}
                title="Add or Update Legend"
            />
            <SoarModal
                title={'Add or Update Legend'}
                isOpen={showUploadLegendModal}
                onClosed={() => setShowUploadLegendModal(false)}
                width="600px"
            >
                <UploadDropzone
                    onSelectFiles={(files: File[]) => {
                        if (files.length !== 1) {
                            alert('Select a single file');
                        } else {
                            handleEditLegend(files[0]);
                        }
                    }}
                >
                    <PreviewImage />
                </UploadDropzone>
                <ConfirmModalFooter>
                    <Button color="warning" onClick={() => setShowUploadLegendModal(false)}>
                        Cancel
                    </Button>
                </ConfirmModalFooter>
            </SoarModal>
        </React.Fragment>
    );
};

export default MapServicesLayerEditLegend;

const LegendEditIcon = styled.i`
    color: #eed926;
    font-size: 20px;
    cursor: pointer;
    margin-top: 2px;
`;

const LegendPreview = styled.img`
    width: inherit !important;
    cursor: pointer;
`;

const LegendUploadText = styled.p`
    color: rgba(255, 255, 255, 0.87) !important;
    font-weight: normal;
    font-size: 18px !important;
    cursor: pointer;
`;
