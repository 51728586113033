import { useEffect } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import './leaflet-bbox-control.css';
import GeoUtil from '../../../../lib/geo-util';

/**
 * The Leaflet DOM event when the button is clicked
 */
export const BoundingBoxControlEvent = 'bounding-box-control-event'

const LeafletBoundingBoxControl = (props) => {
    const map = useMap();

    useEffect(() => {
        const boundingBoxControl = L.control.boundingBoxControl(props);
        map.addControl(boundingBoxControl);

        return () => {
            map.removeControl(boundingBoxControl);
        };
    }, [map, props]);

    return null;
};

L.Control.BoundingBoxControl = L.Control.extend({
    options: {
        controlContainerClassName: 'leaflet-control-bbox leaflet-bar leaflet-control',
        controlButtonClassName: 'leaflet-control-bbox-button',
    },

    onAdd: function(map) {
        this._map = map;
        const containerClassName = this.options.controlContainerClassName;
        const container = L.DomUtil.create('div', containerClassName);

        const btnClass = this.options.controlButtonClassName;
        this.link = L.DomUtil.create('a', btnClass, container);
        this.link.innerHTML = this.options.buttonText;
        L.DomEvent.on(this.link, 'click', this.broadcastBoundingBox, this);

        L.DomEvent.disableClickPropagation(container);
        return container;
    },

    broadcastBoundingBox: function () {
        const bbox = this._map.getBounds();
        const boundingBox = GeoUtil.latLngBoundsToWKT(bbox);
        this._map.fire(BoundingBoxControlEvent, { boundingBox });
    },
});

L.control.boundingBoxControl = function(options) {
    return new L.Control.BoundingBoxControl(options);
};

export default LeafletBoundingBoxControl;
