import React from 'react';
import { ListingDTO } from '../../api/model';
import { Card, Col, Row, Subtitle } from '../style';
import styled from 'styled-components';
import { actionUpdateFeatured } from '../../store/AppStore/actions';
import { useDispatch } from 'react-redux';

interface Props {
    listing: ListingDTO;
    type: string;
}

const FeaturedPreviewCard = ({ listing, type }: Props) => {
    const dispatch = useDispatch();
    const removeFeatured = (listingId: number) =>
        dispatch(actionUpdateFeatured(listingId, type, listing.featured, false));
    return (
        <FeaturedCard>
            <FeaturedPreviewImage src={listing.previewUrl} alt={listing.title} />
            <Col>
                <FeaturedPreviewTitle>
                    {listing.title} <span>by {listing.userName}</span>
                    <FeaturePreviewType>{listing.listingType}</FeaturePreviewType>
                </FeaturedPreviewTitle>
                <FeaturedPreviewRow>
                    <FeaturedPreviewText>{`Likes: ${listing.totalLikes.toLocaleString()} | Views: ${listing.totalViews.toLocaleString()} | Comments: ${listing.totalComments.toLocaleString()}`}</FeaturedPreviewText>
                    <FeaturedPreviewText>
                        {listing.categories?.map((t, idx) => {
                            return <span key={`tag_id_${idx}`}>{t}</span>;
                        })}
                    </FeaturedPreviewText>
                </FeaturedPreviewRow>
            </Col>
            <FeaturedDeleteIcon
                className={`fa fa-trash`}
                onClick={() => removeFeatured(listing.id)}
                title={`Remove ${listing.title}`}
            />
        </FeaturedCard>
    );
};

export default FeaturedPreviewCard;

const FeaturedCard = styled(Card)`
    display: flex;
    flex-direction: row;
    padding: 0;
    border: 1px solid rgba(116, 116, 116, 0.3);
    margin: 10px;
`;

const FeaturedPreviewImage = styled.img`
    background-color: rgb(23 26 29);
    width: 150px;
`;

const FeaturedPreviewText = styled(Subtitle)`
    text-align: left;
    line-height: 20px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.87);
    margin: 10px 0px;

    span {
        border: 1px solid rgb(255 255 255 / 30%);
        padding: 2px;
        border-radius: 4px;
        margin: 2px;
    }
`;

const FeaturedPreviewTitle = styled(Subtitle)`
    color: #eed926;
    text-align: left;
    border-bottom: 1px solid rgba(116, 116, 116, 0.3);
    padding: 10px 0px;
    line-height: normal;
    word-break: break-all;

    span {
        color: rgba(255, 255, 255, 0.87);
        font-size: 14px;
        font-style: italic;
    }
`;

const FeaturedDeleteIcon = styled.i`
    color: #dc3545;
    font-size: 20px;
    cursor: pointer;
    right: 10px;
    bottom: 10px;
    position: absolute;
`;

const FeaturedPreviewRow = styled(Row)`
    margin-left: 5px;
`;

const FeaturePreviewType = styled.div`
    float: right;
    border: 1px solid rgb(255 255 255 / 30%);
    padding: 2px 5px;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 8px;
    color: rgb(255 255 255 / 30%);
`;
