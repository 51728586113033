import React from 'react';
import styled from 'styled-components';
import UploadDropzone from '../../../Shared/Upload/upload-dropzone';

interface ListingPreviewImageProps {
    previewUrl?: string;
    base64Preview?: string;
    onPreviewUpdated: (base64Preview: string) => void;
}

const ListingPreviewImage = ({ previewUrl, base64Preview, onPreviewUpdated }: ListingPreviewImageProps) => {
    return (
        <PreviewContainer>
            <UploadDropzone
                onSelectFiles={(files: File[]) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        onPreviewUpdated(reader.result as any);
                    };
                    reader.readAsDataURL(files[0]);
                }}
            >
                {base64Preview ? <PreviewImage src={base64Preview} /> : null}

                {previewUrl && !base64Preview ? <PreviewImage src={previewUrl} /> : null}

                {!previewUrl && !base64Preview ? <UploadPreviewText>Drag and drop preview</UploadPreviewText> : null}
            </UploadDropzone>
        </PreviewContainer>
    );
};

export default ListingPreviewImage;

const PreviewContainer = styled.div`
    width: 256px;
    height: 256px;
    cursor: pointer;

    .choose-file {
        padding: initial;
    }

    .choose-file img {
        width: 100%;
        padding: initial;
    }
`;

const PreviewImage = styled.img`
    width: 256px;
    height: 256px;
`;

const UploadPreviewText = styled.p`
    padding: 78px 0 !important;
`;

const LoadingPreviewImage = styled.img`
    width: 256px;
    height: 256px;
    filter: blur(8px);
`;
