import React, { useState } from 'react';
import { Button, Divider, Input, Label, Row, Spinner } from '../../../style';
import StyledModal from '../../../Shared/styled-modal';
import styled from 'styled-components';
import ApiTms from '../../../../api/api-tms';
import store from '../../../../store/store';
import { push } from 'connected-react-router';

interface TMSLayerAddProps {
    tmsServerId: number;
}

const TMSLayerAdd = (props: TMSLayerAddProps) => {
    const [isAddLayerModalOpen, setAddLayerModalOpen] = useState(false);
    const [isAddingLayer, setIsAddingLayer] = useState(false);
    const [url, setUrl] = useState('');

    const handleAddNewLayer = () => {
        setIsAddingLayer(true);
        ApiTms.addTMSLayer(props.tmsServerId, url)
            .then((res) => {
                if (res) {
                    setUrl('');
                    setAddLayerModalOpen(false);
                    store.dispatch(push(`/tms/${props.tmsServerId}/${res.id}`));
                }
            })
            .catch((err) => {
                alert('An error occurred while adding new layer: ' + err.message);
            })
            .finally(() => {
                setIsAddingLayer(false);
            });
    };

    return (
        <React.Fragment>
            <Button onClick={() => setAddLayerModalOpen(true)}>
                <i className="fa fa-plus-circle" /> Add TMS Layer to server
            </Button>

            <StyledModal
                isOpen={isAddLayerModalOpen}
                toggle={() => setAddLayerModalOpen(false)}
                title="Add TMS Layer to server"
                width="600px"
            >
                <AddLayerLabel>The url must contain the following parameters {'{z}/{x}/{y}'}</AddLayerLabel>
                <Divider />
                <Row style={{ flexDirection: 'row', margin: '0px 7px' }}>
                    <Input
                        type="text"
                        placeholder="Enter layer URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </Row>
                <ActionButtonContainer>
                    <Button disabled={isAddingLayer} onClick={() => handleAddNewLayer()}>
                        {isAddingLayer ? (
                            <>
                                <LoadingSpinner />
                                Add new layer
                            </>
                        ) : (
                            <>Add new layer</>
                        )}
                    </Button>
                    <Button color="warning" onClick={() => setAddLayerModalOpen(false)}>
                        Cancel
                    </Button>
                </ActionButtonContainer>
            </StyledModal>
        </React.Fragment>
    );
};

export default TMSLayerAdd;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0px;
`;

const LoadingSpinner = styled(Spinner)`
    margin-right: 8px;
    font-size: 10px;
    width: 15px;
    height: 15px;
`;

const AddLayerLabel = styled(Label)`
    font-weight: 200;
    font-size: 16px;
    margin: 10px 0px 0px 10px;
`;
