import { push } from 'connected-react-router';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import ApiWms, { OGCStandard } from '../../../../api/api-wms';
import store from '../../../../store/store';
import SoarModal from '../../../Shared/soar-modal';
import {
    Button,
    Col,
    ConfirmButtonContainer,
    ConfirmModalFooter,
    ErrorMessage,
    FormGroup,
    Input,
    Label,
    Spinner,
} from '../../../style';
import { SoarApiError } from '../../../../api/api';

interface WMSServerAddNewProps {
    buttonText?: string;
    userId?: string;
}

type ErrorMessageType = string | ReactNode | undefined;

const WMSServerAddNew = ({ buttonText, userId }: WMSServerAddNewProps) => {
    const [serverURL, setServerURL] = useState<string>('');
    const [standard, setStandard] = useState<OGCStandard>(OGCStandard.WMS);
    const [modal, setModal] = useState<boolean>(false);

    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [error, setError] = useState<ErrorMessageType>(undefined);

    const validateUrl = (url: string): boolean => {
        const res = url.match(
            //https://stackoverflow.com/a/49849482/646998
            // eslint-disable-next-line no-useless-escape
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res !== null;
    };

    const createServer = async () => {
        if (validateUrl(serverURL)) {
            setIsCreating(true);
            setError(undefined);
        } else {
            setError('Invalid URL');
            return;
        }

        try {
            const server = await ApiWms.createWMSServer(serverURL, standard, userId);
            await ApiWms.refreshWMSServer(server.id);
            store.dispatch(push(`/wms/${server.id}`));
        } catch (err) {
            const apiError = err as SoarApiError;
            console.log(apiError);
            let message: ErrorMessageType = 'Unexpected server error.';
            if (apiError.code === 'url-exists') {
                message = 'Server URL already exists.';
                //apiError.message = The url is already exist. Server id: 1
                // parse number from everything behind ":"
                const serverId = Number(apiError.message.split(': ')[1]);
                if (serverId && !isNaN(serverId)) {
                    message = (
                        <React.Fragment>
                            {message}{' '}
                            <ErrorLink href={`/wms/${serverId}`} onClick={handleExistingClick}>
                                Click here to open existing server
                            </ErrorLink>
                        </React.Fragment>
                    );
                }
            }
            setError(message);
        } finally {
            setIsCreating(false);
        }
    };

    const handleExistingClick = (e) => {
        e.preventDefault();

        const link = e.target as HTMLAnchorElement;

        store.dispatch(push(link.pathname));
    };

    return (
        <React.Fragment>
            <Button onClick={() => setModal(true)}>
                <i className="fa fa-plus-circle" /> {buttonText ?? 'Add New Server'}
            </Button>
            <SoarModal title="Add a new server" isOpen={modal} onClosed={() => setModal(false)} width="600px">
                <ErrorMessage>{error}</ErrorMessage>
                <FormGroup tag="fieldset">
                    <Label for="standard" xs={12}>
                        Type
                    </Label>
                    <FormGroup check inline>
                        <Input
                            name="standard"
                            type="radio"
                            required={true}
                            onChange={() => setStandard(OGCStandard.WMS)}
                            checked={standard === OGCStandard.WMS}
                        />
                        <Label check onClick={() => setStandard(OGCStandard.WMS)}>
                            WMS Server
                        </Label>
                    </FormGroup>
                    <FormGroup check inline style={{ marginLeft: '24px' }}>
                        <Input
                            name="standard"
                            type="radio"
                            onChange={() => setStandard(OGCStandard.WMTS)}
                            checked={standard === OGCStandard.WMTS}
                        />
                        <Label check onClick={() => setStandard(OGCStandard.WMTS)}>
                            WMTS Server
                        </Label>
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="url" xs={12}>
                        Server URL
                    </Label>
                    <Col xs={12}>
                        <Input name="url" id="url" value={serverURL} onChange={(e) => setServerURL(e.target.value)} />
                    </Col>
                </FormGroup>

                <ConfirmModalFooter margin="0px 15px 0px 0px">
                    <ConfirmButtonContainer>
                        <Button onClick={createServer}>{isCreating ? <Spinner /> : 'Create'}</Button>
                        <Button color="warning" onClick={() => setModal(false)}>
                            Cancel
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>
        </React.Fragment>
    );
};

export default WMSServerAddNew;

const ErrorLink = styled.a`
    color: #dc3545;
    text-decoration: underline;

    &:hover {
        color: red;
    }
`;
