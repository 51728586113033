import React from 'react';
import Config from '../../config';
import { Container, Title } from '../style';
import FeaturedTabs from './featured-tabs';

const Featured = () => {
    return (
        <Container>
            <Title>Manage featured : {Config.SUPER_MAP_DOMAIN}</Title>
            <FeaturedTabs />
        </Container>
    );
};

export default Featured;
