import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ApiWms, { WMSLayer, WMSServer } from '../../../../api/api-wms';
import {
    Card,
    Divider,
    Row,
    Spinner,
    Subtitle,
    Table,
    TableWrapper,
    FormGroup,
    Label,
    InputGroup,
    Input,
    Button,
} from '../../../style';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import store from '../../../../store/store';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

interface WMSServerLayersProps {
    wms: WMSServer;
}

const WMSServerLayers = (props: WMSServerLayersProps) => {
    const [isLoadingLayers, setIsLoadingLayers] = useState<boolean>(false);
    const [activeLayers, setActiveLayers] = useState<WMSLayer[]>([]);
    const [inactiveLayers, setInactiveLayers] = useState<WMSLayer[]>([]);
    const [filteredActiveLayers, setFilteredActiveLayers] = useState<WMSLayer[]>([]);
    const [filteredInactiveLayers, setFilteredInactiveLayers] = useState<WMSLayer[]>([]);
    const [textFilter, setTextFilter] = useState<string>('');

    useEffect(() => {
        setIsLoadingLayers(true);
        ApiWms.getWMSLayers(props.wms.id)
            .then((layers) => {
                setInactiveLayers(layers.filter((t) => t.public === false));
                setActiveLayers(layers.filter((t) => t.public === true));
            })
            .catch((err) => {
                toast.error('Failed to get wms layer', err);
            })
            .finally(() => {
                setIsLoadingLayers(false);
            });
    }, [props.wms]);

    useEffect(() => {
        const textRegEx = new RegExp(textFilter, 'img');

        setFilteredActiveLayers(
            activeLayers.filter((layer) => textRegEx.test(layer.title) || textRegEx.test(layer.titleOvr))
        );
        setFilteredInactiveLayers(
            inactiveLayers.filter((layer) => textRegEx.test(layer.title) || textRegEx.test(layer.titleOvr))
        );
    }, [activeLayers, inactiveLayers, textFilter]);

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            headerStyle: () => {
                return {
                    width: '40px',
                };
            },
        },
        {
            dataField: 'title',
            text: 'Layer Name',
            sort: false,
            formatter: (_, row: WMSLayer) => {
                return row.titleOvr || row.title;
            },
        },
        {
            dataField: 'public',
            text: 'Available in Soar',
            headerStyle: () => {
                return {
                    width: '140px',
                };
            },
            formatter: (_, row: WMSLayer) => {
                if (row.public) {
                    return <Checkmark className="fa fa-check" />;
                } else {
                    return <React.Fragment />;
                }
            },
        },
    ];

    const onClickRow = {
        onClick: (_e, row) => {
            store.dispatch(push(`/wms/${props.wms.id}/${row.id}`));
        },
        onAuxClick: (_e, row) => {
            const win = window.open(`/wms/${props.wms.id}/${row.id}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    if (isLoadingLayers) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <Row>
                <Card>
                    <FormGroup>
                        <Label>Filter by Title</Label>
                        <InputGroup>
                            <Input
                                value={textFilter}
                                placeholder="Search by title..."
                                onChange={(e) => setTextFilter(e.target.value)}
                            />
                            {textFilter && (
                                <ClearFilter color="link" onClick={() => setTextFilter('')}>
                                    <i className="fa fa-times" />
                                </ClearFilter>
                            )}
                        </InputGroup>
                    </FormGroup>
                    {filteredActiveLayers.length > 0 ? (
                        <React.Fragment>
                            <Subtitle>Layers available in Soar ({filteredActiveLayers.length} total)</Subtitle>
                            <ToolkitProvider
                                keyField="id"
                                data={filteredActiveLayers}
                                columns={columns}
                                filterFactory={filterFactory()}
                                search={{ searchFormatted: true }}
                            >
                                {(props) => (
                                    <React.Fragment>
                                        <WmsTableWrapper>
                                            <Table {...props.baseProps} rowEvents={onClickRow} />
                                        </WmsTableWrapper>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        </React.Fragment>
                    ) : null}

                    {filteredActiveLayers.length > 0 && filteredInactiveLayers.length > 0 ? <Divider /> : null}

                    {filteredInactiveLayers.length > 0 ? (
                        <React.Fragment>
                            <Subtitle>Layers not available in Soar ({filteredInactiveLayers.length} total)</Subtitle>
                            <ToolkitProvider
                                keyField="id"
                                data={filteredInactiveLayers}
                                columns={columns}
                                filterFactory={filterFactory()}
                                search={{ searchFormatted: true }}
                            >
                                {(props) => (
                                    <React.Fragment>
                                        <WmsTableWrapper>
                                            <Table {...props.baseProps} rowEvents={onClickRow} />
                                        </WmsTableWrapper>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        </React.Fragment>
                    ) : null}
                </Card>
            </Row>
        </React.Fragment>
    );
};

export default WMSServerLayers;

const Checkmark = styled.i`
    color: green;
`;

const WmsTableWrapper = styled(TableWrapper)`
    .table tbody tr td {
        color: white;
    }

    .table tbody tr:hover td {
        font-weight: bold;
    }
`;

const ClearFilter = styled(Button)`
    background-color: white !important;
    border: none !important;
    color: black;

    :hover {
        background-color: white !important;
        border: none !important;
        box-shadow: none !important;
    }

    i {
        color: black;
    }
`;
