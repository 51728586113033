import React, { useState } from 'react';
import styled from 'styled-components';
import UploadDropzone from '../../../Shared/Upload/upload-dropzone';
import { Button, ErrorMessage, Spinner, Subtitle } from '../../../style';
import { OssUploader } from '../../../../api/oss-uploader';
import SoarModal from '../../../Shared/soar-modal';
import ApiSTAC, { STACCatalogFullDTO } from '../../../../api/api-stac';
import MapServicesAvatarEdit from '../../MapServicesSharedUI/Layer/map-services-avatar-edit';

interface StacCatalogPreviewProps {
    stac: STACCatalogFullDTO;
    onSaveAvatar: () => void;
}

const StacCatalogPreview = (props: StacCatalogPreviewProps) => {
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState<Error | undefined>(undefined);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleUploadProfilePicture = (files: File[]) => {
        if (files.length !== 1) {
            alert('You must select one file');
            return;
        }

        setUploading(true);
        setUploadError(undefined);

        ApiSTAC.getSTACCatalogPreviewUploadCredentials(props.stac.id).then((res) => {
            const uploader = new OssUploader(res);
            uploader
                .multipartUpload(
                    files[0],
                    files[0].name,
                    (_) => {} // eslint-disable-line @typescript-eslint/no-empty-function
                )
                .then((_) => {
                    // Bit hacky but gives it time before refreshing so it displays
                    setTimeout(() => {
                        props.onSaveAvatar();
                    }, 2000);
                })
                .catch((err) => {
                    setUploadError(err);
                })
                .finally(() => {
                    setUploading(false);
                    props.onSaveAvatar();
                    setIsModalOpen(false);
                });
        });
    };

    if (uploading || !props.stac) {
        return (
            <AvatarPreviewContainer>
                <Spinner />
            </AvatarPreviewContainer>
        );
    }

    return (
        <React.Fragment>
            <Subtitle>Catalog's preview picture</Subtitle>
            {props.stac.previewUrl ? (
                <React.Fragment>
                    <AvatarPreviewContainer>
                        <Avatar src={props.stac.previewUrl} />
                    </AvatarPreviewContainer>
                    <ButtonContainer>
                        <Button onClick={() => setIsModalOpen(true)}>Update</Button>
                    </ButtonContainer>
                </React.Fragment>
            ) : (
                <UploadDropzoneContainer>
                    {uploadError ? <ErrorMessage>{uploadError.message}</ErrorMessage> : null}
                    <UploadDropzone
                        onSelectFiles={(files: File[]) => {
                            handleUploadProfilePicture(files);
                        }}
                    />
                </UploadDropzoneContainer>
            )}
            {isModalOpen ? (
                <SoarModal
                    title="Edit your Catalog Profile Picture"
                    width="450px"
                    onClosed={() => setIsModalOpen(false)}
                    isOpen={isModalOpen}
                >
                    <MapServicesAvatarEdit
                        id={props.stac.id}
                        avatarUrl={props.stac.previewUrl ? props.stac.previewUrl : ''}
                        handleUploadProfilePicture={handleUploadProfilePicture}
                    />
                    <ModalButtonContainer>
                        <Button onClick={() => setIsModalOpen(false)}>Cancel edit</Button>
                    </ModalButtonContainer>
                </SoarModal>
            ) : null}
        </React.Fragment>
    );
};

export default StacCatalogPreview;

const UploadDropzoneContainer = styled.div`
    margin: 22px auto 147px auto;
    padding: 39px;
    width: 280px;
    height: 280px;
    cursor: pointer;
    background-color: rgb(255 255 255 / 10%);
    border-radius: 500px;
    border: 2px solid #eed926;
`;

const AvatarPreviewContainer = styled.div`
    height: 370px;
    display: block;
`;

const Avatar = styled.img`
    width: 280px;
    height: 280px;
    display: block;
    margin: 0 auto;
    margin-top: 22px;
    border-radius: 500px;
    border: 2px solid #eed926;
`;

const ModalButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-top: 8px;
`;

const ButtonContainer = styled.div`
    margin-top: -40px;
    display: block;
    align-self: center;
`;
