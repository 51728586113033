import { formatDistance } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ApiComments from '../../../api/api-comments';
import { CommentDTO } from '../../../api/model';
import config from '../../../config';
import FormattedDetailsTable, { Line } from '../../Shared/formatted-details-table';
import { Card, Spinner } from '../../style';
import ViewHelper from '../../view-helper';

interface Props {
    comment: CommentDTO;
}

const CommentTableDetails = (props: Props) => {
    const [comment, setComment] = useState<CommentDTO>(undefined);

    useEffect(() => {
        const fetch = async () => {
            const res = await ApiComments.getComment(props.comment.id);
            setComment(res);
        };
        if (props.comment) {
            fetch();
        } else {
            setComment(undefined);
        }
    }, [props.comment]);

    if (!comment) {
        return (
            <Card height="200px">
                <Spinner title="Loading comments details" />
            </Card>
        );
    }

    return (
        <React.Fragment>
            <FormattedDetailsTable label="Comment details">
                <Line
                    label="Created at"
                    value={`${ViewHelper.formatDateWithTime(comment.createdAt)} (${formatDistance(
                        new Date(comment.createdAt * 1000),
                        new Date(),
                        { addSuffix: true }
                    )})`}
                />
                {comment.updatedAt && (
                    <Line
                        label="Updated at"
                        value={`${ViewHelper.formatDateWithTime(comment.updatedAt)} (${formatDistance(
                            new Date(comment.updatedAt * 1000),
                            new Date(),
                            { addSuffix: true }
                        )})`}
                    />
                )}
                <Line label="Created by" value={comment.userName} />
                <Line label="Email" value={comment.userEmail} />
                <Line label="Text" value={comment.text} />
                <Line
                    label="Listing tile"
                    value={
                        <a
                            href={`${config.CLIENT_URL}/maps/${comment.listingId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {comment.listingTitle}
                        </a>
                    }
                />
            </FormattedDetailsTable>
        </React.Fragment>
    );
};

export default CommentTableDetails;
