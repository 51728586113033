import { ContactUsDTO, ListingDTO, ReportedCommentDTO, UserDTO } from '../../api/model';
import { OrderForAdminDTO } from '../../api/model-orders';
import { AppState } from '../root-reducer';

export const selectUser = (state: AppState): UserDTO | undefined => state.appStore.user;
export const selectIsLoggedIn = (state: AppState): boolean | undefined => state.appStore.loggedIn;

export const selectFeatured = (state: AppState) => state.appStore.featured;
export const selectFeaturedError = (state: AppState) => state.appStore.featuredError;

export const selectContactUs = (state: AppState): ContactUsDTO[] | undefined => state.appStore.contactUsEnquiries;
export const selectContactUsLoading = (state: AppState): boolean => state.appStore.contactUsLoading;

export const selectSupermapQueue = (state: AppState): ListingDTO[] | undefined => state.appStore.supermapQueue;
export const selectSupermapQueueLength = (state: AppState): number => state.appStore.supermapQueueLength;
export const selectSupermapQueueLoading = (state: AppState): boolean => state.appStore.supermapQueueLoading;

export const selectOrders = (state: AppState): OrderForAdminDTO[] | undefined => state.appStore.orders;
export const selectOrdersLoading = (state: AppState): boolean => state.appStore.ordersLoading;

export const selectReportedComments = (state: AppState): ReportedCommentDTO[] | undefined => state.appStore.reportedComments;
export const selectReportedCommentsLoading = (state: AppState): boolean => state.appStore.reportedCommentsLoading;
export const selectReportedCommentsError = (state: AppState): string | undefined => state.appStore.reportedCommentsError;