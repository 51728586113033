import * as React from 'react';
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';
import { KeyboardHandle, SliderRail, Track } from './slider';
import styled from 'styled-components';
import { Button } from '../../../style';

interface HeatmapTimeControlState {
    values;
    play: boolean;
    playInterval: number;
    playDateStartString: string;
    playDateEndString: string;
}

interface HeatmapTimeControlProps {
    min: Date;
    max: Date;
    onStartDateChange?: (date: Date) => void;
    onEndDateChange?: (date: Date) => void;
    onStartDateUpdate?: (date: Date) => void;
    oneEndDateUpdate?: (date: Date) => void;
}

class HeatmapTimeControl extends React.Component<HeatmapTimeControlProps, HeatmapTimeControlState> {
    intervalFunction;

    constructor(props: HeatmapTimeControlProps) {
        super(props);
        this.state = {
            values: [0, 0],
            play: false,
            playInterval: 0,
            playDateStartString: '',
            playDateEndString: '',
        };

        this.startPlay = this.startPlay.bind(this);
        this.stopPlay = this.stopPlay.bind(this);
    }

    componentWillReceiveProps(nextProps: HeatmapTimeControlProps) {
        const propValues = [nextProps.min.getTime(), nextProps.max.getTime()];
        if (this.state.values[1] !== nextProps.max.getTime()) {
            this.setState(
                {
                    values: propValues,
                },
                () => {
                    if (nextProps.onStartDateChange) nextProps.onStartDateChange(nextProps.min);
                    if (nextProps.onEndDateChange) nextProps.onEndDateChange(nextProps.max);
                }
            );
        }
    }

    componentWillUnmount(): void {
        clearInterval(this.intervalFunction);
        this.state = {
            values: [0, 0],
            play: false,
            playInterval: 0,
            playDateStartString: '',
            playDateEndString: '',
        };
    }

    onStartChange(startDate: Date) {
        if (this.props.onStartDateChange) this.props.onStartDateChange(startDate);
    }

    onEndChange(endDate: Date) {
        if (this.props.onEndDateChange) this.props.onEndDateChange(endDate);
    }

    stopPlay() {
        clearInterval(this.intervalFunction);
        if (this.props.onStartDateChange) this.props.onStartDateChange(this.props.min);
        if (this.props.onEndDateChange) this.props.onEndDateChange(this.props.max);
        this.setState({
            play: false,
        });
    }

    startPlay() {
        const numberOfSegments = 200;
        const start = this.props.min;
        const end = this.props.max;
        const timeSlice = (end.getTime() - start.getTime()) / numberOfSegments;
        if (this.props.onStartDateChange) this.props.onStartDateChange(start);
        if (this.props.onEndDateChange) this.props.onEndDateChange(new Date(start.getTime() + timeSlice));

        this.setState({
            play: true,
        });

        this.intervalFunction = setInterval(() => {
            const timeSlice = (end.getTime() - start.getTime()) / numberOfSegments;
            const startTime = start.getTime() + timeSlice * this.state.playInterval;
            const endTime = start.getTime() + timeSlice * 2 * this.state.playInterval;
            if (this.props.onStartDateChange) this.props.onStartDateChange(new Date(startTime));

            if (endTime > this.props.max.getTime()) {
                this.setState({
                    playInterval: 0,
                });
            } else {
                this.setState({
                    playInterval: this.state.playInterval + 1,
                    playDateStartString: new Date(startTime).toDateString(),
                    playDateEndString: new Date(endTime).toDateString(),
                });
            }
            if (this.props.onEndDateChange) this.props.onEndDateChange(new Date(endTime));
        }, 200);
    }

    public render() {
        const domain: [number, number] = [this.props.min.getTime(), this.props.max.getTime()];

        return (
            <React.Fragment>
                {!this.state.play ? (
                    <React.Fragment>
                        <Button className="btn btn-small btn-play" onClick={this.startPlay}>
                            <i className="fa fa-play" />
                        </Button>
                        <div>
                            <HeatMapSlider
                                mode={3}
                                step={10}
                                domain={domain}
                                onUpdate={(e) => {
                                    if (this.props.onStartDateUpdate) this.props.onStartDateUpdate(new Date(e[0]));
                                    if (this.props.oneEndDateUpdate) this.props.oneEndDateUpdate(new Date(e[1]));
                                }}
                                onChange={(e) => {
                                    this.onStartChange(new Date(e[0]));
                                    this.onEndChange(new Date(e[1]));
                                }}
                                values={this.state.values}
                            >
                                <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
                                <Handles>
                                    {({ handles, getHandleProps }) => (
                                        <HandlesDiv>
                                            {handles.map((handle) => (
                                                <KeyboardHandle
                                                    key={handle.id}
                                                    handle={handle}
                                                    domain={domain}
                                                    getHandleProps={getHandleProps}
                                                />
                                            ))}
                                        </HandlesDiv>
                                    )}
                                </Handles>
                                <Tracks left={false} right={false}>
                                    {({ tracks, getTrackProps }) => (
                                        <React.Fragment>
                                            {tracks.map(({ id, source, target }) => (
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={getTrackProps}
                                                />
                                            ))}
                                        </React.Fragment>
                                    )}
                                </Tracks>
                            </HeatMapSlider>
                        </div>
                    </React.Fragment>
                ) : (
                    <HeatMapPlayContainer>
                        <Button className="btn btn-small btn-play" onClick={this.stopPlay}>
                            <i className="fa fa-stop" />
                        </Button>
                        <PlayString>
                            {this.state.playDateStartString} to {this.state.playDateEndString}
                        </PlayString>
                    </HeatMapPlayContainer>
                )}
            </React.Fragment>
        );
    }
}

export default HeatmapTimeControl;

const HandlesDiv = styled.div`
    & button {
        padding: 0 !important;
    }
`;

const HeatMapSlider = styled(Slider)`
    div:nth-child(1) {
        background-color: transparent !important;
    }

    div:nth-child(2) {
        background-color: White !important;
    }

    div:nth-child(3) {
        background-color: White !important;
        margin-top: -5px;
    }

    button {
        background-color: #eed926 !important;
    }
`;

const HeatMapPlayContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 0px;
`;

const PlayString = styled.div`
    color: white;
    font-size: 1.5rem;
    margin-top: -21px;
`;
