import React from 'react';
import ApiOrders from '../../api/api-orders';
import ApiOrdersSupplier from '../../api/api-orders-supplier';
import { LogType, OrderLogDTO } from '../../api/model-orders';
import FormattedDetailsTable from '../Shared/formatted-details-table';
import IconButton from '../Shared/icon-button';
import ViewHelper from '../view-helper';
import styled from 'styled-components';
import { toast } from 'react-toastify';

interface Props {
    orderId: number;
    logs: OrderLogDTO[];
    isAdmin?: boolean;
    title?: string;
}

const OrderLogs = (props: Props) => {
    const { orderId, logs, isAdmin } = props;

    const formatContent = (log: OrderLogDTO): string => {
        if (log.type === LogType.STATUS) {
            return `${ViewHelper.STATUS_LABELS[log.previousStatus]} -> ${ViewHelper.STATUS_LABELS[log.status]}`;
        } else return log.message;
    };

    const downloadAttachedFile = (logId: number) => {
        if (isAdmin) {
            ApiOrders.getOrderDownloadLinkLog(orderId, logId)
                .then((url) => {
                    window.open(url.presignedUrl, '_blank');
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        } else {
            ApiOrdersSupplier.getDownloadLinkLog(orderId, logId)
                .then((url) => {
                    window.open(url.presignedUrl, '_blank');
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        }
    };

    return (
        <FormattedDetailsTable label={props.title ? props.title : ''}>
            {logs &&
                logs
                    .sort((a, b) => b.created.localeCompare(a.created))
                    .map((log) => {
                        return (
                            <LogTable key={log.id}>
                                <LogTableItem width="100px" title={ViewHelper.formatDateTimeFromISO(log.created)}>
                                    {ViewHelper.formatDateFromISO(log.created)}
                                </LogTableItem>
                                {log.createdBy ? (
                                    <LogTableItem width="min-content">{log.createdBy}</LogTableItem>
                                ) : null}
                                <LogTableItem width="50%">{formatContent(log)}</LogTableItem>
                                <LogTableItem width="65px">
                                    {log.type}
                                    <span>
                                        {log.filename && (
                                            <IconButton
                                                onClick={() => downloadAttachedFile(log.id)}
                                                faIcon="fa-download"
                                            />
                                        )}
                                    </span>
                                </LogTableItem>
                            </LogTable>
                        );
                    })}
        </FormattedDetailsTable>
    );
};

export default OrderLogs;

interface LogTableItemProps {
    width: string;
}

const LogTable = styled.tr`
    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

const LogTableItem = styled.td<LogTableItemProps>`
    max-width: ${(props) => (props.width ? props.width : 'auto')};
    overflow-wrap: anywhere;

    span {
        float: right;
    }

    @media only screen and (max-width: 600px) {
        max-width: 100%;
        width: 100%;
    }
`;
