import Axios, { AxiosInstance } from 'axios';
import { LatLng, LatLngBounds } from 'leaflet';
import UserHelper from '../lib/user-helper';

export interface GdalMetadata {
    bands: any[];
    boundingBox: LatLngBounds;
    epsg: string;
    filename: string;
    fullPath: string;
    geotransform: any;
    maxZoom: number;
    minZoom: number;
    previewUrl: string;
    proj4: string;
    size: {
        x: number;
        y: number;
    };
    tileServerUrl: string;
    warnings: string[];
}

export default class ApiTileEngine {
    static axios: AxiosInstance;

    public static getMetadata(filename: string, serverUrl: string, useDisk: string): Promise<GdalMetadata> {
        return this.axios
            .get(`${serverUrl}/images/metadata?filename=${filename}&use_disk=${useDisk}`)
            .then((res) => {
                const data = res.data;
                const gdalResponse: GdalMetadata = { ...res.data };
                const ne: LatLng = new LatLng(data.boundingBox.northEast.lat, data.boundingBox.northEast.lng);
                const sw: LatLng = new LatLng(data.boundingBox.southWest.lat, data.boundingBox.southWest.lng);
                gdalResponse.boundingBox = new LatLngBounds(ne, sw);
                return gdalResponse;
            })
            .catch((err) => {
                throw err;
            });
    }

    public static _initialize() {
        this.axios = Axios.create();

        // Add a response interceptor
        this.axios.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error) {
                console.error('Interceptor:', error.config);
                let message = 'Unknown error';
                if (error.response) {
                    const statusCode = error.response.status;
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log('Interceptor:', error.response);
                    if (error.response.data && error.response.data.error) {
                        message = `${statusCode}: ${error.response.data.error}`;
                    } else if (error.response.data) {
                        message = `${statusCode}: ${error.response.data}`;
                    } else {
                        message = `${statusCode}: Unknown error`;
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    message = 'No response from server';
                } else {
                    // Something happened in setting up the request that triggered an Error
                    message = 'Error: ' + error.message;
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                console.error('Interceptor:', message);
                return Promise.reject(Error(message));
            }
        );

        this.axios.interceptors.request.use(
            function (config) {
                const idToken = UserHelper.validateAndGetIdToken();
                if (idToken) {
                    config.headers.Authorization = idToken;
                }
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );
    }
}

ApiTileEngine._initialize();
