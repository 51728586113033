/* Action types */
export const actionTypes = {
    APP_SAVE_USER_PROFILE: 'APP/SAVE_USER_PROFILE',
    APP_FEATURED_UPDATED: 'APP/FEATURED_UPDATED',
    APP_FEATURED_ERROR: 'APP/FEATURED_ERROR',

    APP_CONTACT_US_LOADING: 'APP/CONTACT_US_LOADING',
    APP_CONTACT_US_UPDATED: 'APP/CONTACT_US_UPDATED',
    APP_CONTACT_US_ERROR: 'APP/CONTACT_US_ERROR',

    APP_SUPERMAP_QUEUE_LOADING: 'APP/SUPERMAP_QUEUE_LOADING',
    APP_SUPERMAP_QUEUE_UPDATED: 'APP/SUPERMAP_QUEUE_UPDATED',
    APP_SUPERMAP_QUEUE_ERROR: 'APP/SUPERMAP_QUEUE_ERROR',

    APP_ORDERS_LOADING: 'APP/ORDERS_LOADING',
    APP_ORDERS_UPDATED: 'APP/ORDERS_UPDATED',
    APP_ORDERS_ERROR: 'APP/ORDERS_ERROR',

    APP_REPORTED_COMMENTS_LOADING: 'APP/REPORTED_COMMENTS_LOADING',
    APP_REPORTED_COMMENTS_UPDATED: 'APP/REPORTED_COMMENTS_UPDATED',
    APP_REPORTED_COMMENTS_ERROR: 'APP/REPORTED_COMMENTS_ERROR',
};
