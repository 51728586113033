import React, { useEffect, useRef, useState } from 'react';
import { FeatureGroup, LayersControl } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AnalyticsFilter, HeatmapResult } from '../../../../api/model';
import { DatesFromTo, ProductName, Provider } from '../../../../api/model-api';
import { actionRefreshAnalyticsHeatmap } from '../../../../store/ReportsStore/actions';
import { selectAnalyticsHeatmap, selectAnalyticsHeatmapFilter } from '../../../../store/ReportsStore/selectors';
import InputDateRange, { DEFAULT_DATE_RANGES } from '../../../Shared/Filters/DateRange/input-date-range';
import InputProvider from '../../../Shared/Filters/input-provider';
import BaseMaps from '../../../Shared/Maps/basemaps';
import { Card, Col, FormGroup, Input, Label, Row, Spinner } from '../../../style';
import HeatmapTimeControl from '../TimeSlider/heatmap-time-control';
import { HeatmapOverlay } from './heatmap-overlay';
import LeafletMap from '../../../MapView/leaflet-map';

const { Overlay } = LayersControl;

const WORLD_ZOOM = 3;

const AnalyticsMap = () => {
    const dispatch = useDispatch();
    const refresh = (filter: AnalyticsFilter) => dispatch(actionRefreshAnalyticsHeatmap(filter));
    const leafletMapRef = useRef(null);

    const filter = useSelector(selectAnalyticsHeatmapFilter);
    const heatmapResults = useSelector(selectAnalyticsHeatmap);

    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [heatmapResultsInRange, setHeatmapResultsInRange] = useState<HeatmapResult[]>([]);
    const [email, setEmail] = useState<string>('');
    const [isHeatmapVisible, setIsHeatmapVisible] = useState(true);

    useEffect(() => {
        if (!heatmapResults) return;

        if (!startDate && !endDate && heatmapResults && heatmapResults.length > 0) {
            const oldestHeatmapResult = heatmapResults[heatmapResults.length - 1];
            const newestHeatmapResult = heatmapResults[0];
            setStartDate(oldestHeatmapResult.date);
            setEndDate(newestHeatmapResult.date);
        }

        setHeatmapResultsInRange(heatmapResults.filter((r) => r.date < endDate && r.date > startDate));
    }, [heatmapResults, startDate, endDate]);

    const onProviderChange = (provider?: Provider, satellite?: ProductName) => {
        const copy: AnalyticsFilter = { ...filter };
        copy.provider = provider;
        copy.satellite = satellite;
        refresh(copy);
    };

    const onDatesChange = (dates: DatesFromTo) => {
        refresh({ ...filter, dates: dates });
    };

    const onSearchClick = () => {
        refresh({ ...filter, email: email });
    };

    useEffect(() => {
        if (leafletMapRef?.current) {
            const map = leafletMapRef.current;
            if (!map) return null;

            const onOverlayAdd = (e) => {
                if (e.name === 'Heatmap') {
                    setIsHeatmapVisible(true);
                }
            };

            const onOverlayRemove = (e) => {
                if (e.name === 'Heatmap') {
                    setIsHeatmapVisible(false);
                }
            };

            map.on('overlayadd', onOverlayAdd);
            map.on('overlayremove', onOverlayRemove);

            return () => {
                map.off('overlayadd', onOverlayAdd);
                map.off('overlayremove', onOverlayRemove);
            };
        }

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
        // TS is been a bit weird as this should be a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leafletMapRef?.current]);

    return (
        <React.Fragment>
            {filter && (
                <Card>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="date-range">Filter by Date:</Label>
                                <InputDateRange
                                    fromTo={filter.dates}
                                    onChange={onDatesChange}
                                    selectedDate={DEFAULT_DATE_RANGES.LAST_30_DAYS}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <InputProvider
                                    provider={filter.provider}
                                    product={filter.satellite}
                                    onChange={onProviderChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="email">Filter by Email:</Label>
                                <Input
                                    onBlur={onSearchClick}
                                    title="Search by email"
                                    placeholder="Search by email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    name="email"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            )}
            {!heatmapResults ? (
                <Card height="600px">
                    <Spinner text={'Loading Heatmap'} />
                </Card>
            ) : (
                <MapContainer md={{ size: 10 }}>
                    <MapView>
                        <LeafletMap
                            mapRef={leafletMapRef}
                            worldZoom={WORLD_ZOOM}
                            zoomControl={true}
                            fullscreenControl={true}
                        >
                            <BaseMaps>
                                <Overlay checked name="Heatmap">
                                    <FeatureGroup>
                                        {isHeatmapVisible && heatmapResultsInRange?.length && (
                                            <HeatmapOverlay heatmapData={heatmapResultsInRange} />
                                        )}
                                    </FeatureGroup>
                                </Overlay>
                            </BaseMaps>
                        </LeafletMap>
                    </MapView>
                    <MapControls>
                        {heatmapResults && heatmapResults[0] && (
                            <HeatmapTimeControl
                                min={heatmapResults[heatmapResults.length - 1].date}
                                max={heatmapResults[0].date}
                                onStartDateChange={(e) => setStartDate(e)}
                                onEndDateChange={(e) => setEndDate(e)}
                            />
                        )}
                    </MapControls>
                </MapContainer>
            )}
        </React.Fragment>
    );
};

export default AnalyticsMap;

const MapContainer = styled(Col)`
    position: absolute;
    height: calc(100% - 458px);
    right: 0px;
    bottom: 20px;
`;

const MapView = styled.div`
    height: 100%;
    width: 100%;
    padding: 0px 40px;
`;

const MapControls = styled.div`
    position: absolute;
    z-index: 1000;
    left: 115px;
    right: 115px;
    bottom: 20px;

    & .slider-ticks {
        color: white;
    }

    & .btn-play {
        position: absolute;
        left: -65px;
        top: -25px;
    }
`;
