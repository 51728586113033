import { push } from 'connected-react-router';
import React from 'react';
import styled from 'styled-components';
import { TMSLayer } from '../../../../api/api-tms';
import store from '../../../../store/store';
import { Button } from '../../../style';

interface TMSLayerVisibilityNavigationButtonsProps {
    tmsLayer: TMSLayer;
    nextLayer?: TMSLayer;
}

const TMSLayerVisibilityNavigationButtons = (props: TMSLayerVisibilityNavigationButtonsProps) => {
    const handleClickNextLayer = () => {
        if (props.nextLayer) {
            store.dispatch(push(`/tms/${props.nextLayer.serverId}/${props.nextLayer.id}`));
        }
    };

    const handleClickBackToList = () => {
        store.dispatch(push(`/tms/${props.tmsLayer.serverId}`));
    };

    return (
        <React.Fragment>
            {props.nextLayer ? (
                <ButtonRightAligned onClick={() => handleClickNextLayer()}>
                    Next layer in server <i className="fa fa-arrow-right" />
                </ButtonRightAligned>
            ) : null}
            <ButtonRightAligned onClick={() => handleClickBackToList()}>
                <i className="fa fa-arrow-left" /> Back to list
            </ButtonRightAligned>
        </React.Fragment>
    );
};

export default TMSLayerVisibilityNavigationButtons;

const ButtonRightAligned = styled(Button)`
    float: right;
`;
