import { ListingType } from '../api/model';
import { OrderPaymentDTO, OrderStatus, ProductOption, SupplierCode } from '../api/model-orders';
import { formatDistanceToNow } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const prettyBytes = require('pretty-bytes');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment');

export default class ViewHelper {
    //hack how to guess archive product but will be removed once proper ordering workflow implemented
    static isArchive(product: string): boolean {
        return product?.startsWith('A');
    }

    static sleep(ms: number): Promise<any> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    static formatDelayLabel(status: OrderStatus): string {
        switch (status) {
            case OrderStatus.REVIEW_SUPPLIER:
                return 'Review due';
            default:
                return 'Response due';
        }
    }

    static extractFilename(osskey: string): string {
        return osskey.split('/').pop();
    }

    static STATUS_LABELS = {
        DRAFT: 'Draft',
        REVIEW_SOAR: 'Review (Soar)',
        REVIEW_SUPPLIER: 'Review (supplier)',
        REJECTED: 'Rejected',
        COLLECTION: 'In collection',
        PROCESSING: 'Preparing data',
        QA_SOAR: 'Awaiting QA',
        QA_FAILED: 'QA failed',
        DELIVERED: 'Delivered',
    };

    static STATUS_LABELS_SUPPLIER = {
        REVIEW_SUPPLIER: 'Review (supplier)',
        COLLECTION: 'In collection',
        QA_SOAR: 'Awaiting QA',
        QA_FAILED: 'QA failed',
        DELIVERED: 'Delivered',
    };

    static STATUS_COLORS = {
        CREATED: '#007bff',
        REVIEW_SOAR: '#5c3e91',
        REVIEW_SUPPLIER: '#1cb0eb',
        REJECTED: '#CC0000',
        COLLECTION: '#6cb177',
        QA_SOAR: '#ec5e3c',
        QA_FAILED: '#17a2b8',
        DELIVERED: '#28a745',
    };

    static PRODUCT_OPTION_LABELS = {
        ARCHIVE: 'Archive',
        STANDARD: 'New collect',
        PRIORITY: 'Priority',
        EMERGENCY: 'Emergency',
        MONITORING: 'Monitoring',
        CUSTOM: 'Custom',
        UNKNOWN: 'Unknown',
    };

    static DELIVERY_FORMATS_LABELS = {
        NCC: 'NCC',
        MS_4_BAND: 'MS',
        FALSE_COLOR: 'False Color',
        RAW: 'Raw',
        OTHER: 'Other',
    };

    static PRODUCTS_LABELS = {
        A50: 'Archive 50cm',
        'A50-MS': 'Archive 50cm - multispectral',
        'A50-PANMS': 'Archive 50cm - PAN + multispectral',
        'A50-RAW': 'Archive 50cm - raw',
        'A50-ST': 'Archive 50cm - stereo',
        A75: 'Archive 75cm',
        'A75-MS': 'Archive 75cm - multispectral',
        'A75-PANMS': 'Archive 75cm - PAN + multispectral',
        'A75-RAW': 'Archive 75cm - raw',
        'A75-ST': 'Archive 75cm - stereo',
        A100: 'Archive 100cm',
        AN100: 'Archive 100cm - night image',
        NC50: 'New collect 50cm',
        'NC50-MS': 'New collect 50cm - multispectral',
        'NC50-PANMS': 'New collect 50cm - PAN + multispectral',
        'NC50-RAW': 'New collect 50cm - raw',
        'NC50-ST': 'New collect 50cm - stereo',
        NC75: 'New collect 75cm',
        'NC75-MS': 'New collect 75cm - multispectral',
        'NC75-PANMS': 'New collect 75cm - PAN + multispectral',
        'NC75-RAW': 'New collect 75cm - raw',
        'NC75-ST': 'New collect 75cm - stereo',
        NCN100: 'New collect 100cm - night image',
        OC: 'Other custom order',
    };

    static SUPPLIERS_LABELS = {
        SKYM50: 'SKYM50',
        CGSTL: 'CG Satellite',
    };

    static LISTINGS_LICENSES = {
        CC_BY_4_0: 'CC BY 4.0',
        CC_BY_NC_4_0: 'CC BY-NC 4.0',
        CC0_1_0: 'CC0 1.0',
    };

    static ANALYTICS_PROVIDER_NAMES = {
        '21AT': '21AT',
        CGSTL: 'CGSTL',
        SINERGISE: 'SINERGISE',
        SKYM50: 'SKYM50',
        SOAR: 'SOAR',
    };

    static ANALYTICS_PRODUCT_NAMES = {
        A50: 'A50',
        A50EXT: 'A50EXT',
        A75: 'A75',
        A80: 'A80',
        A100: 'A100',
        AN100: 'AN100',
        NC50: 'NC50',
        NC75: 'NC75',
        NC80: 'NC80',
        NC100: 'NC100',
        NCN100: 'NCN100',
        SEN_2_L1C: 'Sentinel-2 L1C',
        LAND_8_L1C: 'Landsat-8 L1C',
        SEN_1_GRD: 'Sentinel-1 GRD',
        MAP: 'Map',
        IMAGE: 'Image',
        ORDER: 'ORDER',
        TASK_MAP: 'Task map',
        EMBED_MAP: 'EmbdMap',
        MAP_DRAW: 'MapDraw',
        MAP_CANVA: 'MapCanva',
        WMS: 'WMS',
        WMTS: 'WMTS',
        COG: 'COG',
        EXTERNAL_TILE_LAYER: 'External tile layer',
    };

    static ANALYTICS_ACTION_LABELS = {
        SEARCH: 'Search',
        PREVIEW: 'Preview',
        VIEW: 'View',
        DOWNLOAD: 'Download',
        HISTORICAL: 'Historical',
        'B-A-PREVIEW': 'Preview A', //deprecated
        'B-A-SEARCH': 'Search', //deprecated
        'NT-A-PREVIEW': 'Preview NC', //deprecated
    };

    static formatArea(area: number, short?: boolean): string {
        if (!area || area === 0) {
            return '';
        }

        if (area < 1000) {
            return `${area}m²`;
        }

        if (area / 10000 / 1000000 < 1) {
            return `${(area / 1000000).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}km²`;
        }

        if (area / 10000 / 1000000 < 1000) {
            return `${(area / 10000 / 1000000).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}${short ? 'M' : ' million'} km²`;
        }

        return `${(area / 10000 / 1000000 / 1000).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}${short ? 'B' : ' billion'} km²`;
    }

    static formatAreaInKm(area: number, _short?: boolean): string {
        if (!area || area === 0) {
            return '0km²';
        }
        return `${area.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}km²`;
    }

    static formatBytes(size?: number): string {
        if (size) return prettyBytes(size);
        return '';
    }

    public static truncateMiddle(fullStr: string, strLen: number): string {
        if (!fullStr || fullStr.length <= strLen) {
            return fullStr;
        }

        const separator = '...';

        const sepLen = separator.length,
            charsToShow = strLen - sepLen,
            frontChars = Math.ceil(charsToShow / 2),
            backChars = Math.floor(charsToShow / 2);

        return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
    }

    public static isUserDefined(userAddress?: string): boolean {
        return userAddress !== undefined && userAddress.length > 0;
    }

    public static isOrderFullyPaid(payment: OrderPaymentDTO): boolean {
        return this.totalPaid(payment) >= payment.amount;
    }

    public static totalPaid(payment: OrderPaymentDTO): number {
        return (payment.paidByStripe || 0) + (payment.paidByVoucher || 0) + (payment.paidByOtherMethod || 0);
    }

    public static formatUSDPriceFromCents(value?: number): string {
        return this.formatPriceFromCents(value, 'USD');
    }

    public static formatPriceFromCents(value?: number, currency?: string): string {
        return `${currency === 'AUD' ? 'AU' : 'US'}$ ${this.priceFromCents(value)}`;
    }

    public static priceFromCents(value?: number): string {
        let v = '0';
        if (value) {
            v = (value / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        return v;
    }

    public static formatDateWithTime(value: number): string {
        const date = new Date(value * 1000);
        return `${date.toLocaleDateString('en-AU')} ${date.toLocaleTimeString('en-AU')}`;
    }

    public static formatDateWithTimeShort(value: number): string {
        const options = { year: 'numeric', month: 'short', day: 'numeric' } as any;
        const date = new Date(value * 1000);
        return `${date.toLocaleDateString(undefined, options)} ${date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        })}`;
    }

    public static formatDate(value?: number): string {
        if (value) {
            const date = new Date(value * 1000);
            return `${date.toLocaleDateString('en-AU')}`;
        }
        return '';
    }

    public static formatDateFromISO(value?: string): string {
        if (value) {
            //temp hack
            const date = moment(value).add({ hours: 8 }).toDate();
            return `${date.toLocaleDateString('en-AU')}`;
        }
        return '';
    }

    public static formatDateTimeFromISO(value?: string): string {
        if (value) {
            //temp hack
            const date = moment(value).add({ hours: 8 }).toDate();
            return `${date.toLocaleDateString('en-AU')} ${date.toLocaleTimeString('en-AU')}`;
        }
        return '';
    }

    public static formatDateTimeDistanceToNow(value?: string): string {
        if (value) {
            return formatDistanceToNow(new Date(value), { addSuffix: true });
        }
        return '';
    }

    public static formatPrice(price: number, priceUsd: number, currency: string): string {
        let result = ViewHelper.formatPriceFromCents(price, currency);
        if (currency !== 'USD') {
            result = `${result} (${ViewHelper.formatUSDPriceFromCents(priceUsd)})`;
        }
        return result;
    }

    public static formatDurationBetweenTimestamps(t1: number, t2: number): string {
        if (t1 === t2) {
            return '0s';
        }
        let ms;
        if (t1 < t2) {
            ms = moment(t2 * 1000).diff(moment(t1 * 1000));
        } else {
            ms = moment(t1 * 1000).diff(moment(t2 * 1000));
        }
        return this.formatDuration(ms);
    }

    public static formatDuration(ms: number): string {
        const duration = moment.duration(ms);
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        if (years > 0) {
            return `${years}years ${months}mon`;
        } else if (months > 0) {
            return `${months}mon ${days}d`;
        } else if (days > 0) {
            return `${days}d ${hours}h`;
        } else if (hours > 0) {
            return `${hours}h ${minutes}m`;
        } else if (minutes > 0) {
            return `${minutes}m ${seconds}s`;
        } else {
            return `${seconds}s`;
        }
    }

    public static formatSupplierAndProductOption(supplier: SupplierCode, option: ProductOption): string {
        return `${supplier} - ${option}`;
    }

    public static formatFilename(fullPath?: string): string {
        if (fullPath) {
            //eslint-disable-next-line
            return fullPath.replace(/^.*[\\\/]/, '');
        }
        return '';
    }

    public static replaceTileLayerTextWithMap(value: string): string {
        if (value === ListingType.TILE_LAYER) {
            return 'MAP';
        }
        return value;
    }
}
