import React from 'react';
import styled from 'styled-components';
import { Button, Input, InputGroup } from '../style';

interface FilterInputFieldProps {
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
}

const TextInputWithClear = ({ value, onChange, placeholder }: FilterInputFieldProps) => {
    return (
        <InputGroup>
            <Input value={value} placeholder={placeholder ?? 'Search'} onChange={(e) => onChange(e.target.value)} />
            {value && (
                <ClearFilter color="link" onClick={() => onChange('')}>
                    <i className="fa fa-times" />
                </ClearFilter>
            )}
        </InputGroup>
    );
};

export default TextInputWithClear;

const ClearFilter = styled(Button)`
    background-color: white !important;
    border: none !important;
    color: black;

    :hover {
        background-color: white !important;
        border: none !important;
        box-shadow: none !important;
    }

    i {
        color: black;
    }
`;
