import React, { useEffect, useState } from 'react';
import ReactDistortableImageOverlayMapLayer from './react-distortable-imageoverlay-maplayer';
import L, { LatLng } from 'leaflet';
import { useMap } from 'react-leaflet';

interface Props {
    corners: LatLng[];
    setCorners?: (corners: LatLng[]) => void;
    dataURL: string;
    mode?: string;
    opacity?: number;
}

const DistortableOverlay = ({ opacity, setCorners, mode, corners, dataURL }: Props) => {
    const map = useMap();
    const [updatedCorners, setUpdatedCorners] = useState<LatLng[]>([]);
    const mapCornersForDistortableOverlay = (corners: LatLng[]) => {
        return [corners[0], corners[1], corners[3], corners[2]];
    };

    const unMapCornersForDistortableOverlay = (corners: LatLng[]) => {
        return [corners[0], corners[1], corners[2], corners[3]];
    };

    useEffect(() => {
        if (!updatedCorners) {
            setUpdatedCorners(mapCornersForDistortableOverlay(corners));
        }
        // TODO works with but something says it may cook it or cause excessive re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateCorners = (corners: LatLng[]) => {
        setCorners && setCorners(unMapCornersForDistortableOverlay(corners));
        setUpdatedCorners(corners);
    };

    useEffect(() => {
        if (corners.length >= 4 && map) {
            requestAnimationFrame(() => {
                const bounds = L.latLngBounds([corners[0], corners[1], corners[2], corners[3]]).pad(0.05);
                map.fitBounds(bounds);
            });
        }
    }, [corners, map]);

    return (
        <ReactDistortableImageOverlayMapLayer
            url={dataURL}
            opacity={opacity}
            corners={corners}
            editMode={mode}
            onUpdate={(corners: LatLng[]) => handleUpdateCorners(corners)}
            map={map}
        />
    );
};

export default DistortableOverlay;
