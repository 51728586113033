import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { CFMonthlyStatDTO, MonthlyReportCFDTO } from '../../../api/model';
import ViewHelper from '../../view-helper';
import ReportTable from '../report-table';
import styled from 'styled-components';
import SoarModal from '../../Shared/soar-modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectCloudflareMonthlyReport } from '../../../store/ReportsStore/selectors';
import { actionRefreshCloudflareMonthlyReport } from '../../../store/ReportsStore/actions';
import { Card, Spinner } from '../../style';

const TABLE_COLUMNS_DETAILS: ColumnDescription[] = [
    {
        dataField: 'requests',
        text: 'Total requests',
        sort: true,
    },
    {
        dataField: 'bytes',
        text: 'Data transferred',
        sort: true,
        formatter: (cell) => ViewHelper.formatBytes(cell),
    },
];

const TrafficMonthlyTable = () => {
    const dispatch = useDispatch();
    const report = useSelector(selectCloudflareMonthlyReport);

    const [detail, setDetail] =
        useState<{ items: CFMonthlyStatDTO[]; columns: ColumnDescription[]; title: string }>(undefined);

    useEffect(() => {
        dispatch(actionRefreshCloudflareMonthlyReport());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelected = (items: CFMonthlyStatDTO[], columnTitle: string, dateLabel: string) => {
        const column: ColumnDescription = {
            dataField: 'label',
            text: columnTitle,
            sort: true,
        };
        setDetail({
            items: items,
            columns: [column, ...TABLE_COLUMNS_DETAILS],
            title: `Report by ${columnTitle} [${format(new Date(dateLabel), 'yyyy - MMM - dd')}]`,
        });
    };

    const tableColumns: ColumnDescription[] = [
        {
            dataField: 'dateLabel',
            text: 'Date',
            sort: true,
            formatter: (cell) => format(new Date(cell), 'MMM - yyyy'),
        },
        {
            dataField: 'uniques',
            text: 'Unique users',
            sort: true,
            formatter: (cell) => cell.toLocaleString(),
        },
        {
            dataField: 'sum.requests',
            text: 'Total requests',
            sort: true,
            formatter: (cell) => cell.toLocaleString(),
        },
        {
            dataField: 'sum.bytes',
            text: 'Data transferred',
            sort: true,
            formatter: (cell) => ViewHelper.formatBytes(cell),
        },
        {
            dataField: 'dateLabel',
            text: 'Detailed Report',
            csvExport: false,
            formatter: (cell, row: MonthlyReportCFDTO) => (
                <LinkButton outline onClick={() => onSelected(row.countries, 'Country', row.dateLabel)}>
                    By Country
                </LinkButton>
            ),
        },
    ];

    if (!report)
        return (
            <Card height="552px">
                <Spinner />
            </Card>
        );

    return (
        <React.Fragment>
            <Card>
                <ReportTable items={report} columns={tableColumns} keyField={'dateLabel'} csvExport />
            </Card>
            {detail && (
                <SoarModal isOpen={true} title={detail.title} onClosed={() => setDetail(undefined)}>
                    <Card>
                        <ReportTable
                            items={detail.items}
                            columns={detail.columns}
                            keyField={'label'}
                            defaultSorted={[{ dataField: 'requests', order: 'desc' }]}
                            csvExport
                        />
                    </Card>
                </SoarModal>
            )}
        </React.Fragment>
    );
};

export default TrafficMonthlyTable;

const LinkButton = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: #eed926;
`;
