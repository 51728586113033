import React, { useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { UserForAdminDTO } from '../../../api/model';
import SoarModal from '../../Shared/soar-modal';
import { Panel, Table, TableWrapper, Button } from '../../style';
import ViewHelper from '../../view-helper';
import styled from 'styled-components';

interface Props {
    items: UserForAdminDTO[];
    onRevoke: (userId: string) => void;
}

const ModeratorsTable = (props: Props) => {
    const [openRevokeModal, setOpenRevokeModal] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>(undefined);

    const { items } = props;

    const handleRevokeUser = (id: string) => {
        setUserId(id);
        setOpenRevokeModal(true);
    };

    const handleConfirmRevokeUserModeration = () => {
        props.onRevoke(userId);
        setOpenRevokeModal(false);
        setUserId(undefined);
    };

    const columns = [
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell: number) => ViewHelper.formatDate(cell),
        },
        {
            dataField: 'id',
            text: '',
            headerStyle: () => {
                return {
                    width: '180px',
                };
            },
            formatter: (cell: string) => (
                <Button color="link" className="danger" onClick={() => handleRevokeUser(cell)}>
                    Revoke Permission
                </Button>
            ),
        },
    ];

    return (
        <React.Fragment>
            <ToolkitProvider
                keyField="id"
                data={items}
                columns={columns}
                exportCSV={{
                    fileName: `moderators_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
                }}
            >
                {(props) => (
                    <TableWrapper>
                        <Table {...props.baseProps} pagination={paginationFactory()} />
                    </TableWrapper>
                )}
            </ToolkitProvider>

            {openRevokeModal && (
                <SoarModal
                    title={'Remove Moderator Privilege'}
                    isOpen={openRevokeModal}
                    onClosed={() => setOpenRevokeModal(false)}
                    width="600px"
                >
                    <RevokeNotice>Are you sure you want to remove the users privilege to manage comments?</RevokeNotice>
                    <Panel>
                        <Button color="warning" onClick={() => setOpenRevokeModal(false)}>
                            Cancel
                        </Button>
                        <Button color="danger" onClick={handleConfirmRevokeUserModeration}>
                            Revoke
                        </Button>
                    </Panel>
                </SoarModal>
            )}
        </React.Fragment>
    );
};

export default ModeratorsTable;

const RevokeNotice = styled.div`
    color: white;
    font-size: 16px;
    padding: 10px 0px;
`;
