import {
    AuthFlowType,
    AuthenticationResultType,
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    InitiateAuthCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider';
import config from '../config';

export default class CognitoUtil {
    private static cognitoClient = new CognitoIdentityProviderClient({ region: config.COGNITO_REGION });
    private static cognitoClientId = config.COGNITO_CLIENT_ID;

    public static async login(username: string, password: string): Promise<AuthenticationResultType> {
        const command = new InitiateAuthCommand({
            ClientId: this.cognitoClientId,
            AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password,
            },
        });

        const loginReponse: InitiateAuthCommandOutput = await this.cognitoClient.send(command);

        return loginReponse.AuthenticationResult;
    }

    public static async refreshToken(refreshToken: string): Promise<AuthenticationResultType> {
        const command = new InitiateAuthCommand({
            ClientId: this.cognitoClientId,
            AuthFlow: AuthFlowType.REFRESH_TOKEN,
            AuthParameters: {
                REFRESH_TOKEN: refreshToken,
            },
        });

        const refreshTokenReponse: InitiateAuthCommandOutput = await this.cognitoClient.send(command);

        return refreshTokenReponse.AuthenticationResult;
    }
}
