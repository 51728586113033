import React from 'react';
import { Card } from 'reactstrap';
import styled from 'styled-components';
import { OrderForAdminDTO } from '../../api/model-orders';
import ViewHelper from '../view-helper';
import OrderStatusBadge from './order-status-badge';

interface Props {
    order: OrderForAdminDTO;
}

const OrderCard: React.FC<Props> = (props) => {
    const { order } = props;

    const onClick = () => {
        window.open(`/orders/${order.id}`, '_blank');
    };

    const amountDue =
        order.payment.amount -
        (order.payment.paidByVoucher || 0) -
        (order.payment.paidByStripe || 0) -
        (order.payment.paidByOtherMethod || 0);
    const amountDueUSD =
        order.payment.amountUsd -
        (order.payment.paidByVoucherUsd || 0) -
        (order.payment.paidByStripeUsd || 0) -
        (order.payment.paidByOtherMethodUsd || 0);

    return (
        <CustomCard onClick={onClick}>
            <FlexDiv>
                {ViewHelper.formatDateFromISO(order.created)}
                <OrderStatusBadge status={order.status} />
            </FlexDiv>
            <FlexDiv>
                <span>{order.orderNumber}</span>
                <span>ID: {order.id}</span>
            </FlexDiv>
            <FlexDiv>
                <span>{ViewHelper.formatSupplierAndProductOption(order.supplierCode, order.productOption)}</span>
                <span>{order.deliveryFormat}</span>
            </FlexDiv>
            <FlexDiv>
                <span>{order.userEmail}</span>
                {amountDue > 0 ? (
                    <SpanPaymentDue>{ViewHelper.formatUSDPriceFromCents(amountDueUSD)}</SpanPaymentDue>
                ) : (
                    <span>FULLY PAID</span>
                )}
            </FlexDiv>
        </CustomCard>
    );
};

export default OrderCard;

const CustomCard = styled(Card)`
    color: #00a2ff;
    text-align: left;
`;

const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SpanPaymentDue = styled.span`
    font-weight: 800;
    color: #dc3545;
`;
