import React from 'react';
import { useState, useEffect } from 'react';
import ApiAdmin from '../../api/api-admin';
import Config from '../../config';
import {
    Button,
    ButtonWrapper,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
    Statistic,
    Subtitle,
    SubtitleHint,
    Title,
} from '../style';
import ErrorPanel from '../Shared/error-panel';
import ViewHelper from '../view-helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubdomainsReport } from '../../store/ReportsStore/selectors';
import { actionRefreshSubdomainsReport } from '../../store/ReportsStore/actions';
import SubdomainsTable from './subdomains-table';
import { toast } from 'react-toastify';

const CreateSubdomain = () => {
    const dispatch = useDispatch();
    const subdomainsReport = useSelector(selectSubdomainsReport);
    const [fetching, setFetching] = useState(false);
    const [subdomain, setSubdomain] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>(undefined);

    const createSubdomain = () => {
        if (!email || !subdomain) {
            toast.error('Please check the details and try again!');
            return;
        }
        setFetching(true);
        ApiAdmin.createSubdomain(email, `${subdomain}.${Config.CLIENT_DOMAIN}`)
            .then((_res) => {
                toast.success(`Subdomain created successfully (${subdomain})`);
            })
            .catch((err) => {
                setError(err.toString());
            })
            .finally(() => {
                setFetching(false);
                setSubdomain('');
                setEmail('');
                dispatch(actionRefreshSubdomainsReport());
            });
    };

    const deleteSubdomain = async (subdomainId: number) => {
        try {
            await ApiAdmin.deleteSubdomain(subdomainId);
            dispatch(actionRefreshSubdomainsReport());
        } catch (err) {
            toast.error(`Error deleting subdomain (${subdomainId}) ${err}`);
        }
    };

    useEffect(() => {
        if (!subdomainsReport) {
            dispatch(actionRefreshSubdomainsReport());
        }
    }, [dispatch, subdomainsReport]);

    useEffect(() => {
        if (email || subdomain) {
            setError('');
        }
    }, [email, subdomain]);

    if (!subdomainsReport) {
        return (
            <Container>
                <Title>Soar+ Management</Title>
                <React.Fragment>
                    <Card height="171px">
                        <Spinner />
                    </Card>
                    <Card height="268px">
                        <Spinner />
                    </Card>
                    <Card height="600px">
                        <Spinner />
                    </Card>
                </React.Fragment>
            </Container>
        );
    }

    const { domains } = subdomainsReport;
    const subMaps = domains?.reduce((acc, d) => acc + d.maps, 0);
    const subMapsSize = domains?.reduce((acc, d) => acc + d.mapsSize, 0);
    const subMapsArea = domains?.reduce((acc, d) => acc + d.mapsArea, 0);
    const subMapsRejected = domains?.reduce((acc, d) => acc + d.mapsRejected, 0);
    const subMapsSizeRejected = domains?.reduce((acc, d) => acc + d.mapsSizeRejected, 0);
    const subMapsAreaRejected = domains?.reduce((acc, d) => acc + d.mapsAreaRejected, 0);
    const subMapsRecentAddition = domains && domains.sort((d1, d2) => d2.createdAt - d1.createdAt)[0];

    return (
        <Container>
            <Title>Soar+ Management</Title>
            <Row>
                <Col xs={12} md={4}>
                    <Card title="Total number of registered soar+ domains">
                        <Subtitle>Soar+ Domains Registered</Subtitle>
                        <Statistic>{domains.length.toLocaleString()}</Statistic>
                        {subMapsRecentAddition ? (
                            <SubtitleHint>Newest {subMapsRecentAddition.name}</SubtitleHint>
                        ) : null}
                    </Card>
                </Col>

                <Col xs={12} md={4}>
                    <Card title="Total number of maps in soar+ domains">
                        <Subtitle>Maps in Soar+ Domains</Subtitle>
                        <Statistic>{subMaps.toLocaleString()}</Statistic>
                        <SubtitleHint>
                            {ViewHelper.formatBytes(subMapsSize)} {ViewHelper.formatArea(subMapsArea)}
                        </SubtitleHint>
                    </Card>
                </Col>

                <Col xs={12} md={4}>
                    <Card title="Total number of soar+ maps deleted">
                        <Subtitle>Soar+ Maps Deleted</Subtitle>
                        <Statistic>{subMapsRejected.toLocaleString()}</Statistic>
                        <SubtitleHint>
                            {ViewHelper.formatBytes(subMapsSizeRejected)} {ViewHelper.formatArea(subMapsAreaRejected)}
                        </SubtitleHint>
                    </Card>
                </Col>
            </Row>
            <Card>
                <Subtitle>Create a new Soar+ subdomain</Subtitle>
                <Form inline>
                    <FormGroup>
                        <Label for="idEmail">Owner's Email</Label>
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            name="email"
                            id="idEmail"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="idSubdomain">Subdomain</Label>
                        <Input
                            value={subdomain}
                            onChange={(e) => setSubdomain(e.target.value)}
                            type="text"
                            name="email"
                            id="idEmail"
                        />
                        <Label>.{Config.CLIENT_DOMAIN}</Label>
                    </FormGroup>
                    <FormGroup>
                        {error && <ErrorPanel>Creating Subdomain {error}</ErrorPanel>}
                        {fetching ? (
                            <Card>
                                <Spinner
                                    text={`Creating ${
                                        subdomain ? `${subdomain}.${Config.CLIENT_DOMAIN}` : 'subdomain'
                                    }`}
                                />
                            </Card>
                        ) : (
                            <ButtonWrapper>
                                <Button type="button" onClick={createSubdomain}>
                                    Create Subdomain
                                </Button>
                            </ButtonWrapper>
                        )}
                    </FormGroup>
                </Form>
            </Card>
            <Card>
                <SubdomainsTable items={domains} onDeleteClick={deleteSubdomain} />
            </Card>
        </Container>
    );
};

export default CreateSubdomain;
