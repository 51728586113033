import React from 'react';
import { ReportInterval } from '../../../api/model-api';
import { FormGroup, Input } from '../../style';

interface Props {
    onChange: (interval: ReportInterval) => void;
    value: ReportInterval;
    disabled?: boolean;
}

const InputReportInterval = (props: Props) => {
    return (
        <FormGroup>
            <Input
                title="Report Type"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value as any)}
                type="select"
                name="interval"
                disabled={props.disabled}
            >
                <option value={'DAY'}>Daily</option>
                <option value={'MONTH'}>Monthly</option>
            </Input>
        </FormGroup>
    );
};

export default InputReportInterval;
