import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { fetchUserProfileAction } from '../../store/AppStore/actions';
import UserHelper from '../../lib/user-helper';
import { Row, Card, Button, Title, Input, Label, Form, FormGroup, Spinner } from '../style';
import SynthGrid from '../SynthGrid';
import ErrorPanel from '../Shared/error-panel';

import CognitoUtil from '../../lib/cognito-util';

const Login = () => {
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [loginError, setLoginError] = useState<string | undefined>(undefined);
    const [loginSubmitting, setLoginSubmitting] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoginSubmitting(true);
        setLoginError(undefined);

        if (!email && !password) {
            setLoginError('Please enter your email and password');
            setLoginSubmitting(false);
            return;
        }

        if (!email) {
            setLoginError('Please check your email and try again');
            setLoginSubmitting(false);
            return;
        }

        if (!password) {
            setLoginError('Please check your password and try again');
            setLoginSubmitting(false);
            return;
        }

        CognitoUtil.login(email, password)
            .then((auth) => {
                setLoginSubmitting(false);
                UserHelper.saveIdToken(auth.IdToken);
                UserHelper.saveRefreshToken(auth.RefreshToken);
                dispatch(fetchUserProfileAction());
            })
            .catch((err) => {
                setLoginError(err.message);
                setLoginSubmitting(false);
            });
    };

    const handleEmail = (email: string) => {
        setLoginError(undefined);
        setEmail(email);
    };

    const handlePassword = (password: string) => {
        setLoginError(undefined);
        setPassword(password);
    };

    return (
        <SynthGrid>
            <Row>
                <Spacer height="10vh" />
                <Title large centered>
                    Welcome to the Soar Administrator Dashboard
                </Title>
                <Spacer height="5vh" />
                <>
                    <LoginCard>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    name="email"
                                    type="text"
                                    value={email}
                                    placeholder="Enter email"
                                    onChange={(e) => handleEmail(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input
                                    name="password"
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => handlePassword(e.target.value)}
                                />
                            </FormGroup>

                            {loginError && <ErrorPanel>{loginError}</ErrorPanel>}

                            <LoginButtonContainer>
                                <LoginButton disabled={loginSubmitting}>
                                    {loginSubmitting ? <Spinner size="sm" /> : 'Login'}
                                </LoginButton>
                            </LoginButtonContainer>
                        </Form>
                    </LoginCard>
                </>
            </Row>
        </SynthGrid>
    );
};

export default Login;

interface SpacerProps {
    height: string;
}

const Spacer = styled.div<SpacerProps>`
    height: ${(props) => props.height};
`;

const LoginCard = styled(Card)`
    border: 1px solid rgba(125, 125, 125, 0.3);
    width: 600px;
    margin: 0 auto;
`;

const LoginButton = styled(Button)`
    margin: 10px 5px;
    padding: 5px 20px;
    width: 90px;
`;

const LoginButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
