import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Label, FormGroup } from '../style';

export interface CheckOption {
    value: string;
    title: string;
    description?: string;
}

interface CheckOptionsProps {
    value?: CheckOption;
    options: CheckOption[];
    onChange: (value: CheckOption[], otherValue?: string) => void;
    otherValue?: string;
}

const CheckOptions = (props: CheckOptionsProps) => {
    const [selectedValues, setSelectedValues] = useState<CheckOption[]>([]);
    const [customFeedback, setCustomFeedback] = useState('');
    const [customFeedbackError, setCustomFeedbackError] = useState(false);

    const otherValue = props.otherValue ?? 'OTHER';

    const handleChange = (value: CheckOption) => {
        let updatedSelectedValues = selectedValues;
        if (selectedValues.find((selectedVal) => selectedVal.value === value.value)) {
            updatedSelectedValues = updatedSelectedValues.filter((selectedVal) => selectedVal.value !== value.value);
        } else {
            updatedSelectedValues.push(value);
        }
        setSelectedValues(updatedSelectedValues);

        props.onChange(updatedSelectedValues, customFeedback);
    };

    const handleCustomFeedback = (feedback) => {
        setCustomFeedbackError(feedback === '');
        setCustomFeedback(feedback);

        props.onChange(selectedValues, feedback);
    };

    return (
        <CheckOptionsContainer>
            {props.options.map((opt, k) => {
                if (opt.value === otherValue) {
                    return (
                        <CheckOptionsItem key={`Other`}>
                            <CheckOptionsItemInput
                                type="checkbox"
                                onClick={() => {
                                    setCustomFeedback('');
                                    setCustomFeedbackError(false);
                                    handleChange(opt);
                                }}
                            />
                            <CheckOptionsItemTitle>{opt.title}</CheckOptionsItemTitle>
                            <CheckOptionsItemFeedback>
                                <Label for="note">Feedback</Label>
                                <Input
                                    value={customFeedback}
                                    onChange={(e) => handleCustomFeedback(e.target.value)}
                                    type="textarea"
                                    rows={4}
                                />
                            </CheckOptionsItemFeedback>
                            {customFeedbackError && (
                                <CheckOptionsItemError>Custom feedback is missing</CheckOptionsItemError>
                            )}
                        </CheckOptionsItem>
                    );
                }

                return (
                    <CheckOptionsItem key={k}>
                        <CheckOptionsItemInput type="checkbox" onClick={() => handleChange(opt)} />
                        <CheckOptionsItemTitle>{opt.title}</CheckOptionsItemTitle>
                        {opt.description && (
                            <CheckOptionsItemDescription>{opt.description}</CheckOptionsItemDescription>
                        )}
                    </CheckOptionsItem>
                );
            })}
        </CheckOptionsContainer>
    );
};

export default CheckOptions;

const CheckOptionsContainer = styled.div``;
const CheckOptionsItemTitle = styled(Label)`
    font-weight: bold;
    color: #fff;
    margin-left: 8px;
`;
const CheckOptionsItemDescription = styled.blockquote`
    display: none;
    font-style: italic;
    color: rgba(255, 255, 255, 0.85);
    margin-left: 24px;
`;
const CheckOptionsItemFeedback = styled.div`
    display: none;
`;

const CheckOptionsItemError = styled.span`
    color: red;
    font-style: italic;
`;

const CheckOptionsItem = styled(FormGroup)`
    position: relative;
    padding: 8px;
    margin: 4px 0;
    border: 1px solid rgba(255, 255, 255, 0.35);
    border-radius: 8px;
`;

const CheckOptionsItemInput = styled(Input)`
    margin-top: 4px;

    &:not(:checked) ~ ${CheckOptionsItemTitle} {
        margin-bottom: 0;
    }

    &:checked
        ~ ${CheckOptionsItemDescription},
        &[checked]
        ~ ${CheckOptionsItemDescription},
        &:checked
        ~ ${CheckOptionsItemFeedback},
        &[checked]
        ~ ${CheckOptionsItemFeedback} {
        display: block;
    }
`;
