import React, { ReactNode } from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';
import Config from '../../../config';
const { BaseLayer } = LayersControl;

interface Props {
    children?: ReactNode;
}

const BaseMaps = (props: Props) => {
    return (
        <LayersControl position="topright">
            <BaseLayer name="Open Elevation">
                <TileLayer attribution="" url={Config.maps.OSM_TOPO} />
            </BaseLayer>
            <BaseLayer name="Google Satellite">
                <TileLayer attribution="" url={Config.maps.GOOGLE_SATELLITE} />
            </BaseLayer>
            <BaseLayer checked name="Google Hybrid">
                <TileLayer attribution="" url={Config.maps.GOOGLE_HYBRID} />
            </BaseLayer>
            <BaseLayer name="Google Street">
                <TileLayer attribution="" url={Config.maps.GOOGLE_STREET} />
            </BaseLayer>
            <BaseLayer name="OpenStreetMaps">
                <TileLayer attribution="" url={Config.maps.OSM_STREET} />
            </BaseLayer>
            <BaseLayer name="Dark Blank">
                <TileLayer attribution="" url="/assets/basemap/black-pixel.png" />
            </BaseLayer>
            <BaseLayer name="White Blank">
                <TileLayer attribution="" url="/assets/basemap/white-pixel.png" />
            </BaseLayer>

            {props.children}
        </LayersControl>
    );
};

export default BaseMaps;
