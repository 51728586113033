import React, { useEffect, useState } from 'react';
import ApiOrders from '../../api/api-orders';
import { OrderForAdminDTO } from '../../api/model-orders';
import OrderStatusBadge from './order-status-badge';
import Order from './order';
import ErrorPanel from '../Shared/error-panel';
import { Card, Col, Container, Row, Spinner, Title } from '../style';

interface Props {
    match: any;
}

const OrderSinglePage = (props: Props) => {
    const [order, setOrder] = useState<OrderForAdminDTO>(null);
    const [error, setError] = useState<string>(undefined);
    const id: number = props.match.params.id;

    useEffect(() => {
        fetchOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchOrder = async () => {
        try {
            const res = await ApiOrders.getOrderById(id);
            setOrder(res);
        } catch (err) {
            setError(err.toString());
        }
    };

    if (error)
        return (
            <Container>
                <ErrorPanel>{error}</ErrorPanel>
            </Container>
        );

    if (!order) {
        return (
            <Container>
                <Card height="122px">
                    <Spinner text="Loading Order" />
                </Card>
                <Row>
                    <Col>
                        <Card height="710px">
                            <Spinner text="Loading Order" />
                        </Card>
                    </Col>
                    <Col>
                        <Card height="710px">
                            <Spinner text="Loading Order" />
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container>
            <Card>
                <React.Fragment>
                    <Title large centered>
                        {order.orderNumber}
                    </Title>
                    <Title centered>
                        <OrderStatusBadge status={order.status} />
                    </Title>
                </React.Fragment>
            </Card>
            <Order order={order} invalidate={() => fetchOrder()}></Order>
        </Container>
    );
};

export default OrderSinglePage;
