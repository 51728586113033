import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { DrawProjectReportDTO } from '../../../api/model';
import { Card } from '../../style';
import ViewHelper from '../../view-helper';
import ReportTable from '../report-table';
import Config from '../../../config';
import styled from 'styled-components';

interface DrawProjectsTableProps {
    projects: DrawProjectReportDTO[];
}

const DrawProjectsTable = ({ projects }: DrawProjectsTableProps) => {
    const tableColumns: ColumnDescription[] = [
        {
            dataField: 'id',
            text: 'ID',
            csvText: 'id',
            sort: true,
            formatter: (value) => (
                <a href={`${Config.CLIENT_URL}/draw/${value}`} target="_blank" rel="noopener noreferrer">
                    {value}
                </a>
            ),
        },
        {
            dataField: 'title',
            text: 'Title',
            formatter: (value) => value,
            style: () => ({
                maxWidth: '500px',
                width: '400px',
                overflow: 'wrap',
            }),
            csvText: 'title',
            csvFormatter: (value) => value ?? '',
        },
        {
            dataField: 'public',
            text: 'Public',
            csvText: 'public',
            csvFormatter: (value) => value.toLocaleString().toUpperCase(),
        },
        {
            dataField: 'featureCount',
            text: '# Annotations',
            csvText: 'annotations',
            sort: true,
        },
        {
            dataField: 'pageCount',
            text: '# Pages',
            csvText: 'pages',
            sort: true,
        },
        {
            dataField: 'totalViews',
            text: '# Views',
            csvText: 'views',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
            formatter: (value) => ViewHelper.formatDateTimeFromISO(value),
            csvText: 'created_at',
            csvFormatter: (value) => ViewHelper.formatDateTimeFromISO(value) ?? '',
        },
        {
            dataField: 'email',
            text: 'Owner',
            csvText: 'email',
            sort: true,
        },
        {
            dataField: 'deletedAt',
            text: 'Deleted',
            csvText: 'deleted_at',
            csvFormatter: (value) => ViewHelper.formatDateTimeFromISO(value) ?? '',
            headerStyle: () => {
                return {
                    width: '70px',
                };
            },
            formatter: (_, row: DrawProjectReportDTO) => {
                if (row.deletedAt) {
                    return <Checkmark className="fa fa-ban" />;
                } else {
                    return <React.Fragment />;
                }
            },
            // formatter: (value) => <span title={ViewHelper.formatDateTimeFromISO(value)}>{value ? 'YES' : 'NO'}</span>,
        },
    ];

    return (
        <React.Fragment>
            <Card>
                <ReportTable items={projects} columns={tableColumns} keyField={'dateLabel'} csvExport />
            </Card>
        </React.Fragment>
    );
};

export default DrawProjectsTable;

const Checkmark = styled.i`
    color: red;
    text-align: center;
    width: 100%;
`;
