import React, { useEffect, useState } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import styled from 'styled-components';
import { OrderForAdminDTO, OrderStatus } from '../../api/model-orders';
import { Table, TableWrapper, Button } from '../style';
import ViewHelper from '../view-helper';
import OrderStatusBadge from './order-status-badge';

interface Props {
    items: OrderForAdminDTO[];
}

const OrdersTable = (props: Props) => {
    const [orders, setOrders] = useState(props.items);

    useEffect(() => {
        setOrders(props.items);
    }, [props.items]);

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            formatter: (cell) => (
                <a href={`/orders/${cell}`} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
        },
        {
            dataField: 'supplierCode',
            text: 'Supplier',
            sort: false,
        },
        {
            dataField: 'orderNumber',
            text: 'Order',
            sort: false,
        },
        {
            text: 'Status',
            dataField: 'status',
            sort: false,
            formatter: (cell) => {
                const status: OrderStatus = cell;
                return <OrderStatusBadge status={status} />;
            },
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell) => ViewHelper.formatDate(cell),
            csvFormatter: (cell) => ViewHelper.formatDateWithTime(cell),
        },
        {
            dataField: 'userEmail',
            text: 'Email',
            sort: false,
            formatter: (cell) => <span>{cell}</span>,
        },
        {
            dataField: 'domain',
            text: 'Subdomain',
            sort: false,
            formatter: (cell) => <span>{cell}</span>,
        },
        {
            dataField: 'productOption',
            formatter: (cell) => ViewHelper.PRODUCT_OPTION_LABELS[cell],
            text: 'Type',
        },
        {
            text: 'Format',
            dataField: 'deliveryFormat',
            formatter: (cell) => ViewHelper.DELIVERY_FORMATS_LABELS[cell],
            hidden: true,
        },
        {
            dataField: 'delayInSeconds',
            text: 'Delay',
            sort: false,
            formatter: (cell) => (cell && cell > 0 ? ViewHelper.formatDuration(cell * 1000) : ''),
            csvExport: false,
        },
        {
            dataField: 'areaInKm',
            text: 'Area',
            sort: true,
            formatter: (cell, row: OrderForAdminDTO) => `${row.archiveSelectedAreaInKm || row.areaInKm}km²`,
        },
        {
            dataField: 'amount',
            text: 'Total',
            sort: true,
            formatter: (_cell, row: OrderForAdminDTO) =>
                ViewHelper.formatPriceFromCents(row.payment.amount, row.payment.currency),
            csvFormatter: (cell) => ViewHelper.priceFromCents(cell),
        },
        {
            dataField: 'paidByVoucher',
            text: 'Discount',
            csvText: 'Discount',
            formatter: (cell, row: OrderForAdminDTO) =>
                cell ? (
                    <span title={row.payment.voucher}>{`${ViewHelper.formatPriceFromCents(
                        row.payment.paidByVoucher,
                        row.payment.currency
                    )}`}</span>
                ) : (
                    ''
                ),
            csvFormatter: (cell) => ViewHelper.priceFromCents(cell),
        },
        {
            dataField: 'paidByStripe',
            text: 'Paid',
            sort: true,
            formatter: (_cell, row: OrderForAdminDTO) => (ViewHelper.isOrderFullyPaid(row.payment) ? 'YES' : 'NO'),
            csvFormatter: (cell) => ViewHelper.priceFromCents(cell),
        },
        {
            dataField: 'paidByOther',
            hidden: true,
            text: 'Paid by other',
            sort: true,
            csvFormatter: (cell) => ViewHelper.priceFromCents(cell),
        },
        {
            dataField: 'currency',
            hidden: true,
            text: 'Currency',
        },
        {
            dataField: 'currencyRate',
            hidden: true,
            text: 'Currency Rate',
            csvFormatter: (cell) => (cell ? cell : ''),
        },
        {
            dataField: 'logs',
            hidden: true,
            text: 'Approved by provider',
            csvFormatter: (_cell, row: OrderForAdminDTO) => {
                const log = row.logs?.find((l) => l.status === OrderStatus.COLLECTION);
                if (log) {
                    return ViewHelper.formatDateTimeFromISO(log.created);
                }
                return '';
            },
        },
        {
            dataField: 'logs',
            hidden: true,
            text: 'Delivered',
            csvFormatter: (_cell, row: OrderForAdminDTO) => {
                const log = row.logs?.find((l) => l.status === OrderStatus.DELIVERED);
                if (log) {
                    return ViewHelper.formatDateTimeFromISO(log.created);
                }
                return '';
            },
        },
        {
            dataField: 'logs',
            hidden: true,
            text: 'Rejected',
            csvFormatter: (_cell, row: OrderForAdminDTO) => {
                const log = row.logs?.find((l) => l.status === OrderStatus.REJECTED);
                if (log) {
                    return ViewHelper.formatDateTimeFromISO(log.created);
                }
                return '';
            },
        },
    ];

    const rowEvent = {
        onClick: (_e, row) => {
            window.open(`/orders/${row.id}`, '_blank');
        },
    };

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <Button color="primary" onClick={handleClick}>
                <i className="fa fa-download" />
                &nbsp;Export as CSV
            </Button>
        );
    };

    return (
        <ToolkitProvider
            keyField="id"
            data={orders}
            columns={columns}
            search={{ searchFormatted: true }}
            exportCSV={{
                fileName: 'soar-satellite-orders.csv',
            }}
        >
            {(props) => (
                <React.Fragment>
                    <OrdersTableWrapper>
                        <Table {...props.baseProps} rowEvents={rowEvent} filter={filterFactory()} />
                    </OrdersTableWrapper>

                    <Panel>
                        <MyExportCSV {...props.csvProps}>Export CSV</MyExportCSV>
                    </Panel>
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
};

export default OrdersTable;

const OrdersTableWrapper = styled(TableWrapper)`
    span {
        word-break: break-word !important;
    }
`;

const Panel = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    & button {
        margin-right: 0px;

        :not(:first-child) {
            margin-left: 10px;
        }
    }
    & div {
        margin: 5px;
    }
    & a {
        margin: 5px;
    }
`;
