import React, { useEffect, useState } from 'react';
import { Card, Container, Subtitle, Title } from '../style';
import Reported from './data-reported/reported';
import Overview from './data/overview';

export enum DashboardType {
    Comments,
    Reported,
}

const Comments = () => {
    const [dashboard, setDashboard] = useState<DashboardType>(DashboardType.Comments);
    const [selectedUser, setSelectedUser] = useState<string>(undefined);

    useEffect(() => {
        if (dashboard !== DashboardType.Comments) {
            setSelectedUser(undefined);
        }
    }, [dashboard]);

    const onUserClick = (userEmail: string) => {
        setDashboard(DashboardType.Comments);
        setSelectedUser(userEmail);
    };

    return (
        <Container>
            <Title>Manage Comments</Title>
            <Card>
                <Subtitle>All Comments</Subtitle>
                <Overview selectedUser={selectedUser} />

            </Card>

            <Card>
                <Subtitle>Reported Comments</Subtitle>
                <Reported onUserClick={onUserClick} />
            </Card>
        </Container>
    );
};

export default Comments;

