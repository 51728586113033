import React from 'react';
import { push } from 'connected-react-router';
import store from '../../store/store';
import { FormRow } from './new-order';
import { OrderForAdminDTO } from '../../api/model-orders';
import { Button, Subtitle } from '../style';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { actionUpdateOrders } from '../../store/AppStore/actions';

interface NewOrderCompleteProps {
    orders: OrderForAdminDTO[];
}

const NewOrderComplete = ({ orders }: NewOrderCompleteProps) => {
    const dispatch = useDispatch();

    const handleNewOrder = () => {
        dispatch(actionUpdateOrders());
        store.dispatch(push('/orders'));
    };

    return (
        <React.Fragment>
            <FormRow>
                {orders.length > 1 ? (
                    <Subtitle>Orders {orders.map((o) => o.id).join(', ')} were successfully created</Subtitle>
                ) : orders.length === 1 ? (
                    <Subtitle>Order {orders[0].id} was successfully created</Subtitle>
                ) : (
                    <span>Sorry, No order was made.</span>
                )}
            </FormRow>
            <OrderCompleteButton onClick={handleNewOrder}>View Orders</OrderCompleteButton>
        </React.Fragment>
    );
};

export default NewOrderComplete;

const OrderCompleteButton = styled(Button)`
    margin: 20px auto;
`;
