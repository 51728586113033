import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ApiReports from '../../../api/api-reports';
import { ListingType, ReportListingsDTO } from '../../../api/model';
import { DatesFromTo, ReportInterval } from '../../../api/model-api';
import InputDateRange, { DEFAULT_DATE_RANGES, LAST_365_DAYS } from '../../Shared/Filters/DateRange/input-date-range';
import InputListingType from '../../Shared/Filters/input-listing-type';
import { ColumnDescription } from 'react-bootstrap-table-next';
import ReportTable from '../report-table';
import { Card, Row, Col, FormGroup, Label, Container, Title, Spinner } from '../../style';
import UploadersChart from './uploaders-chart';
import UploadsBarChart from './uploads-bar-chart';
import UploadsPieChart from './uploads-pie-chart';
import { format } from 'date-fns';
import styled from 'styled-components';

const TABLE_COLUMNS_LISTINGS: ColumnDescription[] = [
    {
        dataField: 'label',
        text: 'Date',
        sort: true,
        formatter: (cell) => (cell ? format(new Date(cell), 'dd-MMM-yyyy') : ''),
    },
    {
        dataField: 'approved',
        text: 'Approved',
        sort: true,
    },
    {
        dataField: 'rejected',
        text: 'Rejected',
        sort: true,
    },
    {
        dataField: 'pending',
        text: 'Pending',
        sort: true,
    },
];

interface TableItem {
    label: string;
    approved: number;
    rejected: number;
    pending: number;
}

const ListingsUploadData = () => {
    const [type, setType] = useState<ListingType>(ListingType.TILE_LAYER);
    const [report, setReport] = useState<ReportListingsDTO>(undefined);
    const [data, setData] = useState<TableItem[]>([]);
    const [dates, setDates] = useState<DatesFromTo>(LAST_365_DAYS);
    const [loading, setLoading] = useState(false);

    const refresh = async () => {
        setLoading(true);
        try {
            const res = await ApiReports.getListingsReportByInterval(ReportInterval.DAY);
            setReport(res);
        } catch (err) {
            // setError(err.toString());
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if (report) {
            const items: TableItem[] = [];

            const dateFrom = dates?.from ? formatISO(dates.from, { representation: 'date' }) : undefined;
            const dateTo = dates?.from ? formatISO(dates.to, { representation: 'date' }) : undefined;

            report.intervals.forEach((value) => {
                if ((!dateFrom || value.label >= dateFrom) && (!dateTo || value.label < dateTo)) {
                    if (type === ListingType.TILE_LAYER) {
                        let item: TableItem = {
                            label: value.label,
                            approved: value.mapApproved,
                            rejected: value.mapRejected,
                            pending: value.mapPending,
                        };
                        items.push(item);
                    } else if (type === ListingType.IMAGE) {
                        let item: TableItem = {
                            label: value.label,
                            approved: value.imageApproved,
                            rejected: value.imageRejected,
                            pending: value.imagePending,
                        };
                        items.push(item);
                    } else {
                        let item: TableItem = {
                            label: value.label,
                            approved: value.imageApproved + value.mapApproved,
                            rejected: value.imageRejected + value.mapRejected,
                            pending: value.imagePending + value.mapPending,
                        };
                        items.push(item);
                    }
                }
            });
            setData(items);
        }
    }, [report, dates, type]);

    if (loading) {
        return (
            <Container>
                <Title>Upload Report</Title>
                <Card height="124px">
                    <Spinner />
                </Card>

                <Card height="552px">
                    <Spinner />
                </Card>
            </Container>
        );
    }

    return (
        <Container>
            <Title>Upload Report</Title>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange
                                fromTo={dates}
                                onChange={setDates}
                                selectedDate={DEFAULT_DATE_RANGES.LAST_365_DAYS}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="data-type">Filter by Type</Label>
                            <InputListingType value={type} onChange={setType} />
                        </FormGroup>
                    </Col>
                    <FilterResultsLabel>
                        There are <span>{data.length.toLocaleString()}</span> results for given filter
                    </FilterResultsLabel>
                </Row>
            </Card>

            <ReportTable items={data.reverse()} columns={TABLE_COLUMNS_LISTINGS} keyField="label" csvExport />
            <UploadsBarChart />
            <UploadsPieChart />
            <UploadersChart />
        </Container>
    );
};

export default ListingsUploadData;

const FilterResultsLabel = styled(Label)`
    margin-bottom: 0px;
`;
