import React from 'react';
import styled from 'styled-components';
import UploadDropzone from '../../../Shared/Upload/upload-dropzone';

interface MapServicesLayerPreviewImageProps {
    previewUrl?: string;
    base64Preview?: string;
    isUploadingPreview: boolean;
    onSelectPreviewImage: (file: File) => void;
}

const MapServicesLayerPreviewImage = (props: MapServicesLayerPreviewImageProps) => {
    return (
        <PreviewContainer>
            <UploadDropzone
                onSelectFiles={(files: File[]) => {
                    if (files.length !== 1) {
                        alert('Select a single file');
                    } else {
                        props.onSelectPreviewImage(files[0]);
                    }
                }}
            >
                {props.base64Preview && props.isUploadingPreview ? (
                    <LoadingPreviewImage src={props.base64Preview} />
                ) : null}

                {props.base64Preview && !props.isUploadingPreview ? <PreviewImage src={props.base64Preview} /> : null}

                {props.previewUrl && !props.base64Preview && !props.isUploadingPreview ? (
                    <PreviewImage src={props.previewUrl} />
                ) : null}

                {!props.previewUrl && !props.base64Preview && !props.isUploadingPreview ? (
                    <UploadPreviewText>Drag and drop preview</UploadPreviewText>
                ) : null}
            </UploadDropzone>
        </PreviewContainer>
    );
};

export default MapServicesLayerPreviewImage;

const PreviewContainer = styled.div`
    width: 256px;
    height: 256px;
    cursor: pointer;

    .choose-file {
        padding: initial;
    }

    .choose-file img {
        width: 100%;
        padding: initial;
    }
`;

const PreviewImage = styled.img`
    width: 256px;
    height: 256px;
`;

const UploadPreviewText = styled.p`
    padding: 78px 0 !important;
`;

const LoadingPreviewImage = styled.img`
    width: 256px;
    height: 256px;
    filter: blur(8px);
`;
