import React from 'react';
import styled from 'styled-components';
import { Button, InputGroup } from '../style';

interface FilterInputFieldProps {
    value?: any;
    onClear: () => void;
    children: React.ReactNode;
}

const InputWithClearWrapper = ({ value, children, onClear }: FilterInputFieldProps) => {
    return (
        <InputGroup>
            {children}
            {value && (
                <ClearFilter color="link" onClick={onClear}>
                    <i className="fa fa-times" />
                </ClearFilter>
            )}
        </InputGroup>
    );
};

export default InputWithClearWrapper;

const ClearFilter = styled(Button)`
    background-color: white !important;
    border: none !important;
    color: black;

    :hover {
        background-color: white !important;
        border: none !important;
        box-shadow: none !important;
    }

    i {
        color: black;
    }
`;
