import React from 'react';
import { HeatmapResult } from '../../../../api/model';
import HeatmapLayer from '../../../MapView/Annotations/HeatMapOverlay/HeatMapOverlay';

interface HeatmapOverlayProps {
    heatmapData: HeatmapResult[];
}

export const HeatmapOverlay = (props: HeatmapOverlayProps) => {
    if (!props.heatmapData) {
        return <div />;
    }

    const updatedHeatMapData: [[any, any, any]] = [[0, 0, 0]];
    props.heatmapData.forEach((query) => {
        if (!query) return;
        if (!query.latlng) return;

        updatedHeatMapData.push([query.latlng.lat, query.latlng.lng, 5000.0]);
    });

    if (!updatedHeatMapData.length) {
        return <div />;
    }

    return (
        <HeatmapLayer
            points={updatedHeatMapData}
            longitudeExtractor={(m) => m[1]}
            latitudeExtractor={(m) => m[0]}
            intensityExtractor={(m) => parseFloat(m[2])}
            fitBoundsOnLoad={false}
            fitBoundsOnUpdate={false}
        />
    );
};
