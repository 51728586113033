import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ApiAdmin from '../../api/api-admin';
import { DmtTaskDTO } from '../../api/model';
import ViewHelper from '../view-helper';
import DmtTaskMap from './dmt-task-map';

interface Props {
    task: DmtTaskDTO;
    refreshTasks: () => void;
}

const DmtTask: React.FC<Props> = (props) => {
    const { task } = props;

    const refundTask = async () => {
        try {
            await ApiAdmin.dmtRefund(task.id);
            props.refreshTasks();
        } catch (err) {
            console.error(err);
        }
    };

    const approveTask = async () => {
        try {
            await ApiAdmin.dmtApprove(task.id);
            props.refreshTasks();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="task-container">
            <Row>
                <Col md={6}>
                    <Card outline>
                        <CardHeader>Details</CardHeader>
                        <CardBody>
                            <div className="task-detail-container">
                                <p className="task-detail">
                                    Company: <span>{task.company}</span>
                                </p>
                                <p className="task-detail">
                                    Country: <span>{task.country}</span>
                                </p>
                                <p className="task-detail">
                                    Customer: <span>{`${task.userEmail} on ${task.domain}`}</span>
                                </p>
                                <p className="task-detail">
                                    Paid by customer: <span>{ViewHelper.formatUSDPriceFromCents(task.totalPrice)}</span>
                                </p>
                                {task.voucher && (
                                    <p className="task-detail">
                                        Paid by voucher:{' '}
                                        <span>
                                            {ViewHelper.formatUSDPriceFromCents(task.voucherValueUsed)} ({task.voucher})
                                        </span>
                                    </p>
                                )}
                                {task.voucher && (
                                    <p className="task-detail">
                                        Total order value:{' '}
                                        <span>
                                            {ViewHelper.formatUSDPriceFromCents(
                                                task.totalPrice + task.voucherValueUsed
                                            )}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card outline>
                        <CardHeader>Project description</CardHeader>
                        <CardBody>
                            <div className="task-detail-container">
                                <p className="task-detail">
                                    Estimated area: <span>{ViewHelper.formatArea(task.metadata?.area)}</span>
                                </p>
                                <p className="task-detail">
                                    Total images: <span>{task.metadata?.totalImages}</span>
                                </p>
                                {task.errorCode && (
                                    <p className="task-detail">
                                        Error: <span>{task.errorCode}</span>
                                    </p>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card outline>
                        <div>Map placeholder</div>
                        <DmtTaskMap task={task} />
                    </Card>
                </Col>
            </Row>
            <Row className="task-controls-container">
                <Col md={12}>
                    {task.status === 'error' && (
                        <Button color="primary" onClick={refundTask}>
                            Refund task
                        </Button>
                    )}
                    {task.status === 'quality_check' && (
                        <Button color="primary" onClick={approveTask}>
                            Deliver to user
                        </Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default DmtTask;
