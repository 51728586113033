import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReportedListingDTO } from '../../api/model';
import SoarModal from '../Shared/soar-modal';
import { Card, Container, Title } from '../style';
import ReportedListingDetails from './reported-listing-details';
import ReportedListingsTable from './reported-listings-table';
import ApiReportedListings from '../../api/api-reported-listings';
import { selectError, selectLoading, selectReportedListings } from '../../store/ReportedListingsStore/selectors';
import { actionReportedListingUpdated } from '../../store/ReportedListingsStore/actions';
import Filter from './filter';

const ReportedListings = () => {
    const dispatch = useDispatch();
    const items = useSelector(selectReportedListings);
    const isLoading = useSelector(selectLoading);
    const error = useSelector(selectError);

    const [selected, setSelected] = useState<ReportedListingDTO>(undefined);

    const reportDetails = (report: ReportedListingDTO) => {
        setSelected(report);
    };

    const ignoreReport = (report: ReportedListingDTO) => {
        ApiReportedListings.ignoreReport(report.id).then((dto) => {
            dispatchUpdatedReport(report, dto);
        });
    };

    const notifyOwner = (report: ReportedListingDTO) => {
        ApiReportedListings.notifyOwner(report.id).then((dto) => {
            dispatchUpdatedReport(report, dto);
        });
    };

    const deleteMap = (report: ReportedListingDTO) => {
        ApiReportedListings.deleteMap(report.id).then((dto) => {
            dispatchUpdatedReport(report, dto);
        });
    };

    const dispatchUpdatedReport = (report: ReportedListingDTO, dto: ReportedListingDTO) => {
        dispatch(
            actionReportedListingUpdated({
                ...report,
                review: dto.review,
                reviewedAt: dto.reviewedAt,
                reviewedBy: dto.reviewedBy,
                reviewedByEmail: dto.reportedByEmail,
            })
        );
        setSelected(undefined);
    };

    const onDetailsClosed = () => {
        setSelected(undefined);
    };

    return (
        <>
            <Container>
                <Title>Manage Reported Maps</Title>
                <Filter />
                <Card>
                    {!isLoading && !items && !error ? <Card>All reports have been handled</Card> : null}
                    {!isLoading && error ? <Card>{error}</Card> : null}
                    {items && (
                        <ReportedListingsTable
                            items={items}
                            ignoreReport={ignoreReport}
                            reportDetails={reportDetails}
                            notifyOwner={notifyOwner}
                            deleteMap={deleteMap}
                        />
                    )}
                </Card>
            </Container>
            <SoarModal title="Reported listing details" isOpen={selected?.id > 0} onClosed={onDetailsClosed}>
                <ReportedListingDetails
                    item={selected}
                    onClose={onDetailsClosed}
                    notifyOwner={notifyOwner}
                    deleteMap={deleteMap}
                    ignoreReport={ignoreReport}
                />
            </SoarModal>
        </>
    );
};

export default ReportedListings;
