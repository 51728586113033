import { addHours, formatDistanceStrict } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ViewHelper from '../view-helper';

interface Props {
    start: string;
    end?: string;
}
const Clock = (props: Props) => {
    const { start, end } = props;

    const [duration, setDuration] = useState<string>('');
    const [from, setFrom] = useState<Date>(undefined);
    const [to, setTo] = useState<Date>(undefined);

    useEffect(() => {
        if (from && to) {
            setDuration(formatDistanceStrict(from, to, { addSuffix: true, roundingMethod: 'ceil' }));
        }
    }, [from, to]);

    useEffect(() => {
        setFrom(addHours(new Date(start), 8));
        if (end) {
            setTo(addHours(new Date(end), 8));
            return () => {
                //no-empty-function
            };
        } else {
            const interval = setInterval(() => {
                setCountUpClock();
            }, 1000);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCountUpClock = () => {
        setTo(new Date());
    };
    return <p title={ViewHelper.formatDateTimeFromISO(start)}>{`${duration}`}</p>;
};

export default Clock;
