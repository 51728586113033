import React, { useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ApiUser from '../../../api/api-user';
import { UserForAdminDTO } from '../../../api/model';
import { Button, Card, Spinner, Table, TableWrapper } from '../../style';
import ViewHelper from '../../view-helper';

const ReputableUsersTable = () => {
    const [users, setUsers] = useState<UserForAdminDTO[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        refreshUsers();
    }, []);

    const refreshUsers = () => {
        setLoading(true);
        setUsers([]);
        ApiUser.getUsers(0, 1000000)
            .then((users) => {
                setUsers(users.filter((t) => t.reputable === true));
            })
            .catch((err) => {
                alert(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleRevoke = (user) => {
        if (confirm('Are you sure you want to remove this reputable user?') === true) {
            ApiUser.unmakeReputable(user.id)
                .then((_) => {
                    alert('User is no longer reputable');
                    refreshUsers();
                })
                .catch((err) => {
                    alert(err);
                });
        }
    };

    const columns = [
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell: number) => ViewHelper.formatDate(cell),
        },
        {
            dataField: 'id',
            text: '',
            headerStyle: () => {
                return {
                    width: '180px',
                };
            },
            formatter: (cell: string, row: UserForAdminDTO) => (
                <Button color="link" className="danger" onClick={() => handleRevoke(row)}>
                    Revoke Reputation
                </Button>
            ),
        },
    ];

    if (loading) {
        return (
            <Card height="104px">
                <Spinner text={'Loading Users'} />
            </Card>
        );
    }

    return (
        <ToolkitProvider
            keyField="id"
            data={users}
            columns={columns}
            exportCSV={{
                fileName: `moderators_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
            }}
        >
            {(props) => (
                <TableWrapper>
                    <Table {...props.baseProps} pagination={paginationFactory()} />
                </TableWrapper>
            )}
        </ToolkitProvider>
    );
};

export default ReputableUsersTable;
