import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from '../style';
import styled from 'styled-components';

interface CreateTrackerProps {
    onCreateTracker: (trackerName: string, trackerLink: string) => void;
    isLoading: boolean;
}

const CreateTracker = (props: CreateTrackerProps) => {
    const [trackerName, setTrackerName] = useState('');
    const [trackerLink, setTrackerLink] = useState('https://soar.earth');
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setIsValid(!(trackerName.length < 4 || trackerLink.length < 1 || !trackerLink.includes('https://soar.earth')));
    }, [trackerName, trackerLink]);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Enter Tracker Name</Label>
                        <Input
                            value={trackerName}
                            onChange={(e) => setTrackerName(e.target.value as string)}
                            type="text"
                            name="search"
                            disabled={props.isLoading}
                            placeholder="Enter name for campaign tracker..."
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Enter Tracker Domain</Label>
                        <Input
                            value={trackerLink}
                            onChange={(e) => setTrackerLink(e.target.value as string)}
                            type="text"
                            name="search"
                            disabled={props.isLoading}
                            placeholder="Enter name for campaign tracker..."
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <TrackerFormGroup>
                        <Label>Create Campaign</Label>
                        <Button
                            disabled={props.isLoading || !isValid}
                            onClick={() => props.onCreateTracker(trackerName, trackerLink)}
                        >
                            {props.isLoading ? (
                                <i className="fa fa-spinner fa-spin" />
                            ) : (
                                <i className="fa fa-plus-circle" />
                            )}{' '}
                            New Campaign
                        </Button>
                    </TrackerFormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CreateTracker;

const TrackerFormGroup = styled(FormGroup)`
    display: flex;
    flex-direction: column;
`;
