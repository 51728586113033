import { format } from 'date-fns';
import React from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TrackerDTO } from '../../api/api-tracker';
import { Button, ButtonWrapper, Panel, Table, TableWrapper } from '../style';

interface TrackerTableProps {
    items: TrackerDTO[];
}

const TrackerTable = (props: TrackerTableProps) => {
    const handleCopyTracker = (trackerLink: string) => {
        const textField = document.createElement('textarea');
        textField.innerText = trackerLink;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        alert('Copied to clipboard');
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Tracker name',
            csvText: 'Tracker name',
            hidden: false,
        },
        {
            dataField: 'date',
            text: 'Date',
            csvText: 'Date',
            csvFormatter: (_cell, row: TrackerDTO) => format(row.created, 'yyyy-MM-dd'),
            formatter: (_cell, row: TrackerDTO) => format(row.created, 'yyyy-MM-dd'),
            hidden: false,
        },
        {
            dataField: 'code',
            text: 'Tracker code',
            csvText: 'Tracker code',
            csvFormatter: (_cell, row: TrackerDTO) => row.code,
            formatter: (_cell, row: TrackerDTO) => row.code,
        },
        {
            dataField: 'link',
            text: 'Link',
            csvText: 'Link',
            csvFormatter: (_cell, row: TrackerDTO) => row.link,
            formatter: (_cell, row: TrackerDTO) => {
                return (
                    <Button title={row.link} size="sm" color="link" onClick={() => handleCopyTracker(row.link)}>
                        Copy Link
                    </Button>
                );
            },
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <ButtonWrapper>
                <Button onClick={handleClick}>
                    <i className="fa fa-download" /> Export as CSV
                </Button>
            </ButtonWrapper>
        );
    };

    return (
        <ToolkitProvider
            keyField="id"
            data={props.items}
            columns={columns}
            search={{ searchFormatted: true }}
            exportCSV={{
                fileName: 'soar-satellite-tasks.csv',
            }}
        >
            {(props) => (
                <>
                    <TableWrapper>
                        <Table {...props.baseProps} filter={filterFactory()} />
                    </TableWrapper>
                    <Panel>
                        <MyExportCSV {...props.csvProps}>Export CSV</MyExportCSV>
                    </Panel>
                </>
            )}
        </ToolkitProvider>
    );
};

export default TrackerTable;
