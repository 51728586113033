import * as React from 'react';
import { useEffect, useState } from 'react';
import { ListingDTO } from '../../api/model';
import { Card, Spinner } from '../style';
import ErrorPanel from './error-panel';
import ListingDetails from './listing-details';
import ListingPreviewNotSupported from './listing-preview-not-supported';
import styled from 'styled-components';

interface Props {
    listing: ListingDTO;
    fullRes?: boolean;
}

const ListingPreview = (props: Props) => {
    const [listing, setListing] = useState<ListingDTO>(undefined);

    useEffect(() => {
        if (props.listing) {
            const l = { ...props.listing };
            setListing(l);
        }
    }, [props.listing]);

    if (!listing) {
        return (
            <LoadingCard>
                <Spinner text={'Loading'} />
            </LoadingCard>
        );
    }

    if (
        listing.listingType.toUpperCase() === 'IMAGE' ||
        listing.listingType.toUpperCase() === 'WMS' ||
        listing.listingType.toUpperCase() === 'WMTS' ||
        listing.listingType.toUpperCase() === 'TILE_LAYER' ||
        listing.listingType.toUpperCase() === 'COG' ||
        listing.listingType.toUpperCase() === 'EXTERNAL_TILE_LAYER'
    ) {
        return <ListingDetails listing={listing} allowEditing />;
    } else if (listing.listingType.toUpperCase() === 'NOT_SUPPORTED') {
        return <ListingPreviewNotSupported listing={listing} />;
    } else {
        return (
            <ErrorPanel>{`Listing type ${listing.listingType} does not have implemented view on admin portal.`}</ErrorPanel>
        );
    }
};

export default ListingPreview;

const LoadingCard = styled(Card)`
    height: 300px;
`;
