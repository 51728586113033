import React from 'react';
import { MapContainer as LeafletMapContainer } from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import BoxZoomControl from './Annotations/BoxZoom/box-zoom-control';
import { LatLngBoundsExpression } from 'leaflet';

const WORLD_ZOOM = 2.5;
const WORLD_CENTER: [number, number] = [34.5, 0];
const MAX_ZOOM_LEVEL = 28;
const MAX_BOUNDS = [
    [-90, -180],
    [90, 180],
] as LatLngBoundsExpression;

interface Props {
    mapRef?: React.RefObject<any>;
    children: React.ReactNode;
    worldZoom?: number;
    worldCenter?: [number, number];
    worldZoomMin?: number;
    maxBoundsViscosity?: number;
    zoomControl?: boolean;
    fullscreenControl?: boolean;
    mapReady?: () => void;
}

const LeafletMap = (props: Props) => {
    return (
        <LeafletMapContainer
            ref={props.mapRef}
            zoomSnap={0.01}
            maxBoundsViscosity={1.0}
            maxZoom={MAX_ZOOM_LEVEL}
            zoom={props.worldZoom || WORLD_ZOOM}
            center={props.worldCenter || WORLD_CENTER}
            minZoom={props.worldZoomMin || 0.5}
            className="leaflet-map"
            id="leaflet-map"
            zoomControl={props.zoomControl || true}
            whenReady={() => props.mapReady && props.mapReady()}
            maxBounds={MAX_BOUNDS}
        >
            <>
                {props.children}
                {props.fullscreenControl && <FullscreenControl position="topleft" />}
                {props.zoomControl && <BoxZoomControl position="topleft" />}
            </>
        </LeafletMapContainer>
    );
};

export default LeafletMap;
