import React from 'react';
import _ from 'lodash';
import L, { LatLng } from 'leaflet';
import simpleheat from 'simpleheat';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';

export type LeafletZoomEvent = {
    zoom: number;
    center: LatLng;
};

function isValid(num: number): boolean {
    return typeof num === 'number' && !isNaN(num);
}

function isValidLatLngArray(arr) {
    return arr.filter(isValid).length === arr.length;
}

function isInvalidLatLngArray(arr: Array<number>): boolean {
    return !isValidLatLngArray(arr);
}

function safeRemoveLayer(leafletMap, el): void {
    const { overlayPane } = leafletMap.getPanes();
    // @ts-ignore
    if (overlayPane && overlayPane.contains(el)) {
        // @ts-ignore
        overlayPane.removeChild(el);
    }
}

function shouldIgnoreLocation(loc: { lng: number; lat: number }): boolean {
    return !isValid(loc.lng) || !isValid(loc.lat);
}

type HeatmapLayerProps = {
    map: any;
    points: any[];
    longitudeExtractor: (item: any) => number;
    latitudeExtractor: (item: any) => number;
    intensityExtractor: (item: any) => number;
    fitBoundsOnLoad?: boolean;
    fitBoundsOnUpdate?: boolean;
    onStatsUpdate?: (stats: any) => void;
    max?: number;
    radius?: number;
    maxZoom?: number;
    minOpacity?: number;
    blur?: number;
    gradient?: object;
};

class HeatmapLayer extends React.Component<HeatmapLayerProps> {
    static propTypes = {
        points: PropTypes.array.isRequired,
        longitudeExtractor: PropTypes.func.isRequired,
        latitudeExtractor: PropTypes.func.isRequired,
        intensityExtractor: PropTypes.func.isRequired,
        fitBoundsOnLoad: PropTypes.bool,
        fitBoundsOnUpdate: PropTypes.bool,
        onStatsUpdate: PropTypes.func,
        max: PropTypes.number,
        radius: PropTypes.number,
        maxZoom: PropTypes.number,
        minOpacity: PropTypes.number,
        blur: PropTypes.number,
        gradient: PropTypes.object,
        map: PropTypes.object.isRequired,
    };

    private _el: HTMLCanvasElement | null = null;
    private _frame: number | null = null;

    _heatmap: any = null;
    leafletElement: any;

    createLeafletElement() {
        return null;
    }

    constructor(props) {
        super(props);
        // Create a canvas element here to ensure it's available before componentDidMount
        // This was annoying as it caught me out when using in the did mount lifecycle
        this._el = L.DomUtil.create('canvas', 'leaflet-heatmap-layer');
    }

    componentDidMount() {
        const { map } = this.props;
        if (!map) return;

        const canAnimate = map.options.zoomAnimation && L.Browser.any3d;
        const zoomClass = `leaflet-zoom-${canAnimate ? 'animated' : 'hide'}`;
        const mapSize = map.getSize();

        this._el.className = zoomClass;
        this._el.width = mapSize.x;
        this._el.height = mapSize.y;

        map.getPanes().overlayPane.appendChild(this._el);

        this._heatmap = simpleheat(this._el);
        this.reset();

        const Heatmap = L.Layer.extend({
            onAdd: () => {
                this.attachEvents();
                this.updateHeatmapProps(this.getHeatmapProps(this.props));
            },
            onRemove: (leafletMap) => {
                safeRemoveLayer(leafletMap, this._el);
            },
        });

        this.leafletElement = new Heatmap().addTo(map);
    }

    getMax(props) {
        return props.max || 3.0;
    }

    getRadius(props) {
        return props.radius || 30;
    }

    getMaxZoom(props) {
        return props.maxZoom || 18;
    }

    getMinOpacity(props) {
        return props.minOpacity || 0.01;
    }

    getBlur(props) {
        return props.blur || 15;
    }

    getHeatmapProps(props) {
        return {
            minOpacity: this.getMinOpacity(props),
            maxZoom: this.getMaxZoom(props),
            radius: this.getRadius(props),
            blur: this.getBlur(props),
            max: this.getMax(props),
            gradient: props.gradient,
        };
    }

    componentWillReceiveProps(nextProps: any): void {
        const currentProps = this.props;
        const nextHeatmapProps = this.getHeatmapProps(nextProps);

        this.updateHeatmapGradient(nextHeatmapProps.gradient);

        const hasRadiusUpdated = nextHeatmapProps.radius !== currentProps.radius;
        const hasBlurUpdated = nextHeatmapProps.blur !== currentProps.blur;

        if (hasRadiusUpdated || hasBlurUpdated) {
            this.updateHeatmapRadius(nextHeatmapProps.radius, nextHeatmapProps.blur);
        }

        if (nextHeatmapProps.max !== currentProps.max) {
            this.updateHeatmapMax(nextHeatmapProps.max);
        }
    }

    /**
     * Update various heatmap properties like radius, gradient, and max
     */
    updateHeatmapProps(props: any) {
        this.updateHeatmapRadius(props.radius, props.blur);
        this.updateHeatmapGradient(props.gradient);
        this.updateHeatmapMax(props.max);
    }

    /**
     * Update the heatmap's radius and blur (blur is optional)
     */
    updateHeatmapRadius(radius: number, blur?: number): void {
        if (!this._heatmap) {
            return;
        }
        if (radius) {
            this._heatmap.radius(radius, blur);
        }
    }

    /**
     * Update the heatmap's gradient
     */
    updateHeatmapGradient(gradient: any): void {
        if (!this._heatmap) {
            return;
        }
        if (gradient) {
            this._heatmap.gradient(gradient);
        }
    }

    /**
     * Update the heatmap's maximum
     */
    updateHeatmapMax(maximum: number): void {
        if (!this._heatmap) {
            return;
        }
        if (maximum) {
            this._heatmap.max(maximum);
        }
    }

    componentWillUnmount(): void {
        if (this.props.map && this._el) {
            this.props.map.removeLayer(this.leafletElement);
            safeRemoveLayer(this.props.map, this._el);
            this._el = null;
        }
    }

    fitBounds(): void {
        const points = this.props.points;
        const lngs = _.map(points, this.props.longitudeExtractor);
        const lats = _.map(points, this.props.latitudeExtractor);
        const ne = { lng: _.max(lngs), lat: _.max(lats) };
        const sw = { lng: _.min(lngs), lat: _.min(lats) };

        if (shouldIgnoreLocation(ne) || shouldIgnoreLocation(sw)) {
            return;
        }

        this.props.map.fitBounds(L.latLngBounds(L.latLng(sw), L.latLng(ne)));
    }

    componentDidUpdate(): void {
        this.props.map.invalidateSize();
        if (this.props.fitBoundsOnUpdate) {
            this.fitBounds();
        }
        if (this._el && this._heatmap) {
            this.reset();
        }
    }

    shouldComponentUpdate(): boolean {
        return true;
    }

    attachEvents(): void {
        const leafletMap = this.props.map;
        leafletMap.on('viewreset', () => this.reset());
        leafletMap.on('moveend', () => this.reset());
        if (leafletMap.options.zoomAnimation && L.Browser.any3d) {
            leafletMap.on('zoomanim', this._animateZoom, this);
        }
    }

    _animateZoom(e: LeafletZoomEvent): void {
        const scale = this.props.map.getZoomScale(e.zoom);
        const offset = this.props.map
            ._getCenterOffset(e.center)
            ._multiplyBy(-scale)
            .subtract(this.props.map._getMapPanePos());

        if (L.DomUtil.setTransform) {
            L.DomUtil.setTransform(this._el, offset, scale);
        } else {
            const transformString = `translate3d(${offset.x}px, ${offset.y}px, 0) scale(${scale})`;
            this._el.style[L.DomUtil.TRANSFORM] = transformString;
        }
    }

    reset(): void {
        if (!this._el) return;
        const topLeft = this.props.map.containerPointToLayerPoint([0, 0]);
        L.DomUtil.setPosition(this._el, topLeft);

        const size = this.props.map.getSize();

        if (this._heatmap._width !== size.x) {
            this._el.width = this._heatmap._width = size.x;
        }
        if (this._heatmap._height !== size.y) {
            this._el.height = this._heatmap._height = size.y;
        }

        if (this._heatmap && !this._frame && !this.props.map._animating) {
            this._frame = L.Util.requestAnimFrame(this.redraw, this);
        }

        this.redraw();
    }

    redraw(): void {
        const r = this._heatmap._r;
        const size = this.props.map.getSize();

        const maxIntensity = this.props.max === undefined ? 1 : this.getMax(this.props);

        const maxZoom = this.props.maxZoom === undefined ? this.props.map.getMaxZoom() : this.getMaxZoom(this.props);

        const v = 1 / Math.pow(2, Math.max(0, Math.min(maxZoom - this.props.map.getZoom(), 12)) / 2);

        const cellSize = r / 2;
        const panePos = this.props.map._getMapPanePos();
        const offsetX = panePos.x % cellSize;
        const offsetY = panePos.y % cellSize;
        const getLat = this.props.latitudeExtractor;
        const getLng = this.props.longitudeExtractor;
        const getIntensity = this.props.intensityExtractor;

        const inBounds = (p, bounds) => bounds.contains(p);

        const filterUndefined = (row) => _.filter(row, (c) => c !== undefined);

        const roundResults = (results) =>
            _.reduce(
                results,
                (result, row) =>
                    _.map(filterUndefined(row), (cell) => [
                        Math.round(cell[0]),
                        Math.round(cell[1]),
                        Math.min(cell[2], maxIntensity),
                        cell[3],
                    ]).concat(result),
                []
            );

        const accumulateInGrid = (points, leafletMap, bounds) =>
            _.reduce(
                points,
                (grid, point) => {
                    const latLng = [getLat(point), getLng(point)];
                    if (isInvalidLatLngArray(latLng)) {
                        //skip invalid points
                        return grid;
                    }

                    const p = leafletMap.latLngToContainerPoint(latLng);

                    if (!inBounds(p, bounds)) {
                        return grid;
                    }

                    const x = Math.floor((p.x - offsetX) / cellSize) + 2;
                    const y = Math.floor((p.y - offsetY) / cellSize) + 2;

                    grid[y] = grid[y] || [];
                    const cell = grid[y][x];

                    const alt = getIntensity(point);
                    const k = alt * v;

                    if (!cell) {
                        grid[y][x] = [p.x, p.y, k, 1];
                    } else {
                        cell[0] = (cell[0] * cell[2] + p.x * k) / (cell[2] + k);
                        cell[1] = (cell[1] * cell[2] + p.y * k) / (cell[2] + k);
                        cell[2] += k;
                        cell[3] += 1;
                    }

                    return grid;
                },
                []
            );

        const getBounds = () => new L.Bounds(L.point([-r, -r]), size.add([r, r]));

        const getDataForHeatmap = (points, leafletMap) =>
            roundResults(accumulateInGrid(points, leafletMap, getBounds()));

        const data = getDataForHeatmap(this.props.points, this.props.map);

        this._heatmap.clear();
        this._heatmap.data(data).draw(this.getMinOpacity(this.props));

        this._frame = null;

        if (this.props.onStatsUpdate && this.props.points && this.props.points.length > 0) {
            this.props.onStatsUpdate(
                _.reduce(
                    data,
                    (stats, point) => {
                        stats.max = point[3] > stats.max ? point[3] : stats.max;
                        stats.min = point[3] < stats.min ? point[3] : stats.min;
                        return stats;
                    },
                    { min: Infinity, max: -Infinity }
                )
            );
        }
    }

    render() {
        return null;
    }
}

function withMap(Component) {
    return function WrappedComponent(props) {
        const map = useMap();
        return <Component {...props} map={map} id="heat-map-overlay" />;
    };
}

export default withMap(HeatmapLayer);
