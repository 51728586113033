import * as React from 'react';

import Aux from '../../../hoc/auxilary';

interface ErrorBoundaryState {
    hasError: boolean;
    errorInfo?: any;
}

class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, errorInfo: undefined };
    }

    componentDidCatch(error: any, info: any) {
        this.setState({ hasError: true, errorInfo: info });
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Aux>
                    <h1>Something went wrong: {this.state.errorInfo}</h1>
                </Aux>
            );
        }

        return <Aux>{this.props.children}</Aux>;
    }
}

export default ErrorBoundary;
