import React, { useState } from 'react';
import styled from 'styled-components';
import SoarModal from '../../../Shared/soar-modal';
import { Button, Col, ConfirmModalFooter, Row, Subtitle, SubtitleHint } from '../../../style';

export type LayerType = 'WMS' | 'TMS';
interface Props {
    serverLegend?: string;
    legendCustom?: string;
    layerType?: LayerType;
    handleAddLegend: (url: string) => void;
}

const WMSLayerSelectExistingLegend = (props: Props) => {
    const [showAddLegendModal, setShowAddLegendModal] = useState<boolean>(false);

    const handleUpdateLegend = (url: string) => {
        props.handleAddLegend(url);
        setShowAddLegendModal(false);
    };

    return (
        <React.Fragment>
            <LegendEditIcon
                className={`fa fa-file`}
                onClick={() => setShowAddLegendModal(!showAddLegendModal)}
                title="Select an existing legend"
            />
            <SoarModal
                title={'Select a legend'}
                isOpen={showAddLegendModal}
                onClosed={() => setShowAddLegendModal(false)}
                width="600px"
            >
                <Subtitle>{`Click a legend to apply to ${props.layerType || `WMS`} layer`}</Subtitle>
                <Row>
                    {props.serverLegend ? (
                        <LegendPreviews>
                            <SubtitleHint>Server Image</SubtitleHint>
                            <LegendPreview
                                src={props.serverLegend}
                                onClick={() => handleUpdateLegend(props.serverLegend)}
                            />
                        </LegendPreviews>
                    ) : null}
                    {props.legendCustom ? (
                        <LegendPreviews>
                            <SubtitleHint>Existing Custom Image</SubtitleHint>
                            <LegendPreview
                                src={props.legendCustom}
                                onClick={() => handleUpdateLegend(props.legendCustom)}
                            />
                        </LegendPreviews>
                    ) : null}
                </Row>
                <ConfirmModalFooter>
                    <Button color="warning" onClick={() => setShowAddLegendModal(false)}>
                        Cancel
                    </Button>
                </ConfirmModalFooter>
            </SoarModal>
        </React.Fragment>
    );
};

export default WMSLayerSelectExistingLegend;

const LegendEditIcon = styled.i`
    color: #eed926;
    font-size: 16px;
    cursor: pointer;
    vertical-align: top;
    margin-top: 1px;
`;

const LegendPreviews = styled(Col)`
    text-align: center;
`;

const LegendPreview = styled.img`
    width: auto;
    max-width: 100%;
    height: fit-content;
    cursor: pointer;
`;
