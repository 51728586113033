import { push } from 'connected-react-router';
import React, { useState } from 'react';
import styled from 'styled-components';
import ApiManagedProfiles, { CreateAttachedListing } from '../../../../api/api-managed-profiles';
import { ListingType } from '../../../../api/model';
import store from '../../../../store/store';
import StyledModal from '../../../Shared/styled-modal';
import { Button, Divider, Input, Label, Row, Spinner } from '../../../style';

interface ExternalCogAddProps {
    userId: string;
}

const ProfileExternalCogAdd = ({ userId }: ExternalCogAddProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [url, setUrl] = useState('');

    const handleAddNew = () => {
        setIsAdding(true);
        const body: CreateAttachedListing = {
            listingType: ListingType.COG,
            metadata: {
                title: url,
            },
            url: url,
        };
        ApiManagedProfiles.createListing(userId, body)
            .then((res) => {
                store.dispatch(push(`/profiles/${userId}/listings/${res.id}`));
            })
            .catch((err) => {
                alert('An error occurred while adding new layer: ' + err.message);
            })
            .finally(() => {
                setIsAdding(false);
            });
    };

    return (
        <React.Fragment>
            <Button onClick={() => setModalOpen(true)}>
                <i className="fa fa-plus-circle" />
                Add external COG map
            </Button>

            <StyledModal
                isOpen={modalOpen}
                toggle={() => setModalOpen(false)}
                title="Add External COG Map"
                width="600px"
            >
                <AddLayerLabel>The url must point to publicly accessible Cloud optimized GeoTIFF</AddLayerLabel>
                <Divider />
                <Row style={{ flexDirection: 'row', margin: '0px 7px' }}>
                    <Input
                        type="text"
                        placeholder="Enter file URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </Row>
                <ActionButtonContainer>
                    <Button disabled={isAdding} onClick={() => handleAddNew()}>
                        {isAdding ? (
                            <>
                                <LoadingSpinner />
                                Add
                            </>
                        ) : (
                            <>Add</>
                        )}
                    </Button>
                    <Button color="warning" onClick={() => setModalOpen(false)}>
                        Cancel
                    </Button>
                </ActionButtonContainer>
            </StyledModal>
        </React.Fragment>
    );
};

export default ProfileExternalCogAdd;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0px;
`;

const LoadingSpinner = styled(Spinner)`
    margin-right: 8px;
    font-size: 10px;
    width: 15px;
    height: 15px;
`;

const AddLayerLabel = styled(Label)`
    font-weight: 200;
    font-size: 16px;
    margin: 10px 0px 0px 10px;
`;
