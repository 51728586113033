import ApiAnalytics from '../../api/api-analytics';
import ApiReports from '../../api/api-reports';
import { AnalyticsFilter, DailyReportCFFilter } from '../../api/model';
import { actionTypes as at } from './constants';

export const actionRefreshMainReport = () => {
    return async (dispatch, getState) => {
        try {
            const dto = await ApiReports.getMainReport();
            dispatch({
                type: at.REPORTS_MAIN_REPORT_UPDATED,
                payload: dto,
            });
        } catch (err) {
            dispatch({
                type: at.REPORTS_MAIN_REPORT_ERROR,
                payload: err,
            });
        }
    };
};

export const actionRefreshSoarReport = () => {
    return async (dispatch, getState) => {
        try {
            const dto = await ApiReports.getSoarReport();
            dispatch({
                type: at.REPORTS_SOAR_REPORT_UPDATED,
                payload: dto,
            });
        } catch (err) {
            dispatch({
                type: at.REPORTS_SOAR_REPORT_ERROR,
                payload: err,
            });
        }
    };
};

export const actionRefreshSubdomainsReport = () => {
    return async (dispatch, getState) => {
        try {
            const dto = await ApiReports.getSubdomainsReport();
            dispatch({
                type: at.REPORTS_SUBDOMAINS_REPORT_UPDATED,
                payload: dto,
            });
        } catch (err) {
            dispatch({
                type: at.REPORTS_SUBDOMAINS_REPORT_ERROR,
                payload: err,
            });
        }
    };
};

export const actionRefreshAnalyticsHeatmap = (filter: AnalyticsFilter) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.REPORTS_ANALYTICS_HEATMAP,
                payload: {
                    data: undefined,
                    filter: filter,
                },
            });

            const dto = await ApiAnalytics.apiGetHeatmap(filter);
            dispatch({
                type: at.REPORTS_ANALYTICS_HEATMAP,
                payload: {
                    data: dto,
                    filter: filter,
                },
            });
        } catch (err) {
            dispatch({
                type: at.REPORTS_ANALYTICS_HEATMAP_ERROR,
                payload: err,
            });
        }
    };
};

export const actionRefreshAnalyticsMonthlyReport = () => {
    return async (dispatch, getState) => {
        try {
            const list = await ApiAnalytics.apiGetAnalyticsMonthlyReport();
            dispatch({
                type: at.REPORTS_ANALYTICS_MONTHLY_REPORT,
                payload: list,
            });
        } catch (e) {
            dispatch({
                type: at.REPORTS_ANALYTICS_MONTHLY_REPORT_ERROR,
                payload: e,
            });
        }
    };
};

export const actionRefreshCloudflareDaily = (filter: DailyReportCFFilter) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: at.REPORTS_CLOUDFLARE_DAILY_REPORT,
                payload: {
                    data: undefined,
                    filter: filter,
                },
            });

            const dto = await ApiReports.getCFDailyReport(filter);
            dispatch({
                type: at.REPORTS_CLOUDFLARE_DAILY_REPORT,
                payload: {
                    data: dto,
                    filter: filter,
                },
            });
        } catch (err) {
            dispatch({
                type: at.REPORTS_CLOUDFLARE_DAILY_REPORT_ERROR,
                payload: err,
            });
        }
    };
};

export const actionRefreshCloudflareMonthlyReport = () => {
    return async (dispatch, getState) => {
        try {
            const list = await ApiReports.getCFMonthlyReport();
            dispatch({
                type: at.REPORTS_CLOUDFLARE_MONTHLY_REPORT,
                payload: list,
            });
        } catch (e) {
            dispatch({
                type: at.REPORTS_CLOUDFLARE_MONTHLY_REPORT_ERROR,
                payload: e,
            });
        }
    };
};
