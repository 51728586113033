import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    UncontrolledDropdown,
} from './style';

import UserHelper from '../lib/user-helper';
import RoutingHelper from '../routing-helper';
import { saveUserProfileAction } from '../store/AppStore/actions';
import { selectUser } from '../store/AppStore/selectors';

import store from '../store/store';

const NavigationBar = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const onClickLogout = () => {
        UserHelper.resetIdToken();
        UserHelper.resetRefreshToken();
        dispatch(saveUserProfileAction(undefined));
        store.dispatch(push('/'));
        window.location.reload();
    };

    const avatarUrl = user?.avatarUrl || '/assets/default_avatar.png';
    const profileName = user?.name || 'Soar User';

    return (
        <SoarNavbar dark color="dark" expand="md">
            <SoarBrand onClick={() => store.dispatch(push('/'))}>
                <LogoImg src="/assets/soar_logo.png" alt="Soar" />
            </SoarBrand>
            <SoarNav navbar>
                {user ? (
                    <React.Fragment>
                        <SoarUncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                <AvatarImg src={avatarUrl} />
                                {profileName}
                            </DropdownToggle>
                            <SoarNavDropdownMenu dark>
                                <DropdownItem onClick={onClickLogout}>Logout</DropdownItem>
                            </SoarNavDropdownMenu>
                        </SoarUncontrolledDropdown>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <SoarNavItem>
                            <Button onClick={() => RoutingHelper.login()}>Login / Register</Button>
                        </SoarNavItem>
                    </React.Fragment>
                )}
            </SoarNav>
        </SoarNavbar>
    );
};

export default withRouter(NavigationBar);

const LogoImg = styled.img`
    height: 30px;
`;

const AvatarImg = styled.img`
    margin-right: 5px;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    border: 1px solid white;
`;

const SoarBrand = styled(NavbarBrand)`
    margin: auto 18px;

    @media only screen and (max-width: 600px) {
        margin: auto 9px;
    }
`;

const SoarNav = styled(Nav)`
    justify-content: flex-end;
    flex-grow: 1;
    padding: 0;
`;

const SoarNavbar = styled(Navbar)`
    z-index: 99;
    min-height: 78px;
    box-shadow: 0px 0px 11px black;
    padding: 0;

    .container-fluid {
        padding: 0;
        margin: 0;
    }
`;

const SoarNavItem = styled(NavItem)`
    margin-top: 8px;
    padding: 0 8px;
    cursor: pointer;
`;

const SoarUncontrolledDropdown = styled(UncontrolledDropdown)`
    margin-top: 8px;
    padding: 0 8px;
    cursor: pointer;
`;

const SoarNavDropdownMenu = styled(DropdownMenu)`
    width: 92%;
`;
