import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiManagedProfiles, { CreateAttachedListing, CreatedListingDTO } from '../../../../api/api-managed-profiles';
import { ListingType, UploadCredentialsDTO } from '../../../../api/model';
import store from '../../../../store/store';
import StyledModal from '../../../Shared/styled-modal';
import { Button, Divider, Input, Label, Row, Spinner } from '../../../style';
import UploadSingleFile from '../../../Shared/upload-single-file';
import NotificationUtil from '../../../../lib/notification-util';

interface ProfileTileLayerAddProps {
    userId: string;
}

const ProfileTileLayerUpload = ({ userId }: ProfileTileLayerAddProps) => {
    const [isAddLayerModalOpen, setAddLayerModalOpen] = useState(false);
    const [isAddingLayer, setIsAddingLayer] = useState(false);
    const [title, setTitle] = useState('');
    const [filename, setFilename] = useState<string>(undefined);
    const [response, setResponse] = useState<CreatedListingDTO>(undefined);
    const [valid, setValid] = useState(false);

    const handleAddNewLayer = async () => {
        setIsAddingLayer(true);
        const body: CreateAttachedListing = {
            listingType: ListingType.TILE_LAYER,
            metadata: {
                title: title,
            },
            filename: filename,
        };
        try {
            const res = await ApiManagedProfiles.createListing(userId, body);
            setResponse(res);
        } catch (err) {
            setIsAddingLayer(false);
            NotificationUtil.showErrorNotificationApi(err);
            return;
        }
    };

    const complete = () => {
        store.dispatch(push(`/profiles/${userId}/listings/${response.id}`));
    };

    useEffect(() => {
        setValid(title?.length > 2 && !!filename);
    }, [title, filename]);

    return (
        <React.Fragment>
            <Button onClick={() => setAddLayerModalOpen(true)}>
                <i className="fa fa-plus-circle" />
                Upload TIFF or ECW file
            </Button>

            <StyledModal
                isOpen={isAddLayerModalOpen}
                toggle={() => setAddLayerModalOpen(false)}
                title="Upload TIFF or ECW file"
                width="600px"
            >
                <Divider />
                <Row style={{ flexDirection: 'row', margin: '0px 7px' }}>
                    <Input
                        type="text"
                        placeholder="Enter title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Row>
                <UploadSingleFile
                    uploadCredentials={response?.uploadDetails}
                    onFileSelected={(file: File) => setFilename(file.name)}
                    onUploadFinish={complete}
                />
                <ActionButtonContainer>
                    <Button disabled={isAddingLayer || !valid} onClick={() => handleAddNewLayer()}>
                        {isAddingLayer ? (
                            <>
                                <LoadingSpinner />
                                Add new layer
                            </>
                        ) : (
                            <>Add new layer</>
                        )}
                    </Button>
                    <Button color="warning" onClick={() => setAddLayerModalOpen(false)}>
                        Cancel
                    </Button>
                </ActionButtonContainer>
            </StyledModal>
        </React.Fragment>
    );
};

export default ProfileTileLayerUpload;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0px;
`;

const LoadingSpinner = styled(Spinner)`
    margin-right: 8px;
    font-size: 10px;
    width: 15px;
    height: 15px;
`;

const AddLayerLabel = styled(Label)`
    font-weight: 200;
    font-size: 16px;
    margin: 10px 0px 0px 10px;
`;
