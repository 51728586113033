import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { ReportedListingDTO } from '../../api/model';
import { Button, Table, TableWrapper } from '../style';
import ViewHelper from '../view-helper';
import ConfirmModal from '../Shared/confirm-modal';

interface ContactUsProps {
    items: ReportedListingDTO[];
    reportDetails: (item: ReportedListingDTO) => void;
    ignoreReport: (item: ReportedListingDTO) => void;
    notifyOwner: (item: ReportedListingDTO) => void;
    deleteMap: (item: ReportedListingDTO) => void;
}

const ReportedListingsTable = (props: ContactUsProps) => {
    const { items, reportDetails, ignoreReport, notifyOwner, deleteMap } = props;
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'reportedAt',
            text: 'Reported',
            sort: true,
            formatter: (cell) => <ReportedContainer>{ViewHelper.formatDateTimeDistanceToNow(cell)}</ReportedContainer>,
        },
        {
            dataField: 'reportedByEmail',
            text: 'Reported By',
            sort: true,
        },
        {
            dataField: 'listingTitle',
            text: 'Listing',
            sort: true,
        },
        {
            dataField: 'reason',
            text: 'Reason',
            sort: true,
        },
        {
            dataField: 'review',
            text: 'Review',
            formatter: (_cell, row: ReportedListingDTO) => (
                <QuickActionsContainer>
                    {!row.review || row.review === 'PENDING' ? (
                        <>
                            <Button
                                color="link"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    ignoreReport(row);
                                }}
                            >
                                Ignore
                            </Button>
                            <Button
                                color="link"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    notifyOwner(row);
                                }}
                            >
                                Notify
                            </Button>
                            <ConfirmModal
                                title="Delete"
                                message={'Are you sure to delete the map?'}
                                onConfirm={() => deleteMap(row)}
                                color="link"
                            />
                        </>
                    ) : (
                        <>{_cell}</>
                    )}
                </QuickActionsContainer>
            ),
        },
    ];

    const rowEvents = {
        onClick: (_e, row, _rowIndex) => {
            reportDetails(row);
        },
    };

    return (
        <React.Fragment>
            <ToolkitProvider keyField="id" data={items} columns={columns}>
                {(props) => (
                    <TableWrapper>
                        <Table {...props.baseProps} pagination={paginationFactory()} rowEvents={rowEvents} />
                    </TableWrapper>
                )}
            </ToolkitProvider>
        </React.Fragment>
    );
};

export default ReportedListingsTable;

const QuickActionsContainer = styled.div`
    width: 170px;
`;

const ReportedContainer = styled.div`
    width: 150px;
`;
