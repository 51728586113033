import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReviewItemDTO } from '../../api/model';
import { default as config } from '../../config';
import { actionUpdateSupermapQueue } from '../../store/AppStore/actions';
import {
    selectSupermapQueueLength,
    selectSupermapQueueLoading,
    selectSupermapQueue,
} from '../../store/AppStore/selectors';
import ErrorPanel from '../Shared/error-panel';
import ListingDetails from '../Shared/listing-details';
import { Card, Button, Subtitle, Container, Title, Spinner } from '../style';
import FormattedDetailsTable, { Line } from '../Shared/formatted-details-table';
import ReviewActions from './review-actions';
import Masonry from 'react-masonry-css';

const ReviewQueue = () => {
    const dispatch = useDispatch();
    const [processing, setProcessing] = useState<number>(0);
    const [reviewed, setReviewed] = useState<ReviewItemDTO[]>([]);

    const listingsInQueue = useSelector(selectSupermapQueue);
    const numberOfListingsInQueue = useSelector(selectSupermapQueueLength);
    const isLoading = useSelector(selectSupermapQueueLoading);

    const onNextClicked = async () => {
        if (processing > 0) return;
        setReviewed([]);
        const listingIdFrom =
            listingsInQueue?.length === config.UPLOAD_QUEUE_SIZE
                ? listingsInQueue[listingsInQueue.length - 1].id
                : undefined;
        fetchListings(listingIdFrom);
        window.scrollTo(0, 0);
    };

    const fetchListings = async (listingIdFrom?: number) => {
        dispatch(actionUpdateSupermapQueue(listingIdFrom));
    };

    if (isLoading) {
        return (
            <Container>
                <Title>Upload Queue</Title>
                <Card height="64px">
                    <Spinner text="Loading Upload Queue" />
                </Card>
            </Container>
        );
    }

    const breakpointColumnsObj = {
        default: 2,
        1300: 1,
    };

    const listings = listingsInQueue?.map((listing) => {
        const listingReviewed = reviewed.find((r) => r.id === listing.id);
        return (
            <div>
                <ReviewCard key={listing.filehash}>
                    <ListingDetails listing={listing} allowEditing={true} />
                    <React.Fragment>
                        {listingReviewed === undefined ? (
                            <ReviewActions
                                listing={listing}
                                onReviewStart={() => setProcessing((p) => p + 1)}
                                onReviewResult={(review) => {
                                    setProcessing((p) => p - 1);
                                    setReviewed((list) => [...list, review]);
                                }}
                            />
                        ) : (
                            <ReviewResults>
                                <FormattedDetailsTable label="Submission Results">
                                    <Line label="Review" value={<ReviewText>{listingReviewed.review}</ReviewText>} />
                                    {listingReviewed.feedback && (
                                        <Line
                                            label="Feedback"
                                            value={<ReviewText>{listingReviewed.feedback}</ReviewText>}
                                        />
                                    )}
                                </FormattedDetailsTable>
                                {listingReviewed.error && <ErrorPanel>ERROR: {listingReviewed.error}</ErrorPanel>}
                            </ReviewResults>
                        )}
                        {listingReviewed?.error && <ErrorPanel>{listingReviewed.error}</ErrorPanel>}
                    </React.Fragment>
                </ReviewCard>
            </div>
        );
    });

    return (
        <Container>
            <Title>Upload Queue</Title>
            <Card>
                <Subtitle>{`Items in Queue: ${numberOfListingsInQueue}`}</Subtitle>
            </Card>
            <MasonryComponent breakpointCols={breakpointColumnsObj} columnClassName="masonry-grid_column">
                {listings}
            </MasonryComponent>
            <NextContainer>
                <NextButton
                    title={
                        processing > 0
                            ? 'Please wait until all reviews are processed'
                            : 'Click to load next items in the queue'
                    }
                    disabled={processing > 0}
                    onClick={onNextClicked}
                >
                    Next
                </NextButton>
            </NextContainer>
        </Container>
    );
};

export default ReviewQueue;

const MasonryComponent = styled(Masonry)`
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;

    .masonry-grid_column {
        padding-left: 10px; /* gutter size */
    }
`;

const NextContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const NextButton = styled(Button)`
    width: 98%;
`;

const ReviewResults = styled.div`
    margin-top: 10px;

    div {
        margin: 0px 5px 0px 0px;
    }
`;

const ReviewText = styled.div`
    color: #eed926 !important;
`;

const ReviewCard = styled(Card)`
    border: 1px solid rgba(255, 255, 255, 0.1);
`;
