import React, { useEffect, useState } from 'react';
import TagInputField from '../../../Shared/tag-input-field';
import { FormGroup, Input, Label } from '../../../style';

import { ListingMetadata } from '../../../../api/api-managed-profiles';
import { ListingDTO } from '../../../../api/model';
import CategoriesInput, { filterPrimaryCategories } from '../../../Shared/categories-input-field';
import { MarkdownTextArea } from '../../../Shared/markdown-textarea';
import TemporalInput, { findTemporalCategory } from '../../../Shared/temporal-input-field';
import ViewHelper from '../../../view-helper';

interface ListingMetadataEditProps {
    listing: ListingDTO;
    onChange: (metatada: ListingMetadata, errors: string[]) => void;
}

const MAX_TITLE_LENGTH = 100;
const MIN_TITLE_LENGTH = 3;
const MAX_DESCRIPTION_LENGTH = 200;

const ListingMetadataEdit = ({ listing, onChange }: ListingMetadataEditProps) => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [categories, setCategories] = useState<string[]>([]);
    const [temporalCategory, setTemporalCategory] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [license, setLicense] = useState<string>('');
    const [showTemporalCategory, setShowTemporalCategory] = useState<boolean>(false);

    useEffect(() => {
        setTitle(listing.title);
        setDescription(listing.description);
        setTags(listing.tags || []);
        setLicense(listing.license);
        setCategories(filterPrimaryCategories(listing.categories) || []);
        setTemporalCategory(findTemporalCategory(listing.categories) || '');
        console.log('listing-metadata-edit: ', listing.tags, listing.categories);
    }, [listing]);

    useEffect(() => {
        setShowTemporalCategory(
            temporalCategory != undefined || listing.listingType === 'WMS' || listing.listingType === 'WMTS'
        );
    }, [listing.listingType, temporalCategory]);

    useEffect(() => {
        const errors = [];
        if (title?.length < MIN_TITLE_LENGTH)
            errors.push(`Please review the title, minimum characters ${MIN_TITLE_LENGTH}`);

        if (description?.length < MIN_TITLE_LENGTH)
            errors.push(`Please review the title, minimum characters ${MIN_TITLE_LENGTH}`);

        if (title?.length > MAX_TITLE_LENGTH)
            errors.push(`Please review the title, maximum characters ${MAX_TITLE_LENGTH}`);

        if (description?.split(' ')?.length > MAX_DESCRIPTION_LENGTH)
            errors.push(`Please review the description, maximum word count ${MAX_DESCRIPTION_LENGTH}`);

        if (!categories?.length || categories?.length > 3) errors.push('Please select between 1 - 3 categories');

        const selectedCategories = [...categories];
        if (temporalCategory) {
            selectedCategories.push(temporalCategory);
        }
        console.log('selectedCategories: ', selectedCategories, temporalCategory);
        const metadata: ListingMetadata = {
            title: title,
            description: description,
            categories: selectedCategories,
            tags: tags,
            license: license,
        };
        console.log('metadata: ', metadata, errors);
        onChange(metadata, errors);
    }, [title, description, categories, tags, license, temporalCategory, onChange]);

    return (
        <React.Fragment>
            <FormGroup>
                <Label for="title">Title</Label>
                <Input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormGroup>

            <FormGroup>
                <Label for="description">Description</Label>
                <MarkdownTextArea
                    value={description}
                    setInputValue={(value) => {
                        setDescription(value);
                    }}
                />
            </FormGroup>

            <FormGroup>
                <Label for="categories">Categories</Label>
                <CategoriesInput
                    values={categories}
                    onChange={(selectedCategories) => {
                        console.log('selectedCategories: ', selectedCategories);
                        setCategories(selectedCategories);
                    }}
                />
            </FormGroup>

            {showTemporalCategory && (
                <FormGroup>
                    <Label for="temporal-category">Temporal Category (Optional)</Label>
                    <TemporalInput value={temporalCategory} onChange={setTemporalCategory} />
                </FormGroup>
            )}

            <FormGroup>
                <Label for="tags">Tags (to help with search)</Label>
                <TagInputField tags={tags} onTagInput={(tags) => setTags(tags)} />
            </FormGroup>

            <FormGroup>
                <Label for="license">License</Label>
                <Input
                    value={license}
                    onChange={(e) => {
                        setLicense(e.target.value);
                    }}
                    type="select"
                    name="license"
                >
                    {<option value={''}>Choose license</option>}
                    {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        </React.Fragment>
    );
};

export default ListingMetadataEdit;
