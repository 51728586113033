import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import {
    actionRefreshReportedComments,
    actionUpdateContactUs,
    actionUpdateOrders,
    actionUpdateSupermapQueue,
    fetchUserProfileAction,
} from './store/AppStore/actions';
import { selectUser } from './store/AppStore/selectors';
import {
    actionRefreshAnalyticsHeatmap,
    actionRefreshMainReport,
    actionRefreshSoarReport,
    actionRefreshSubdomainsReport,
} from './store/ReportsStore/actions';

import { endOfDay, startOfDay, subDays } from 'date-fns';
import { AnalyticsFilter, Review, UserRole } from './api/model';
import NavigationBar from './components/navigation-bar';
import Routes from './routes';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login';
import Navigation from './components/navigation';
import { Spinner } from './components/style';
import UserHelper from './lib/user-helper';
import { actionRefreshReportedListings } from './store/ReportedListingsStore/actions';

const ConnectedRoot = () => {
    const [isValidToken, setIsValidToken] = useState(UserHelper.hasValidIdToken());

    const dispatch = useDispatch();

    const user = useSelector(selectUser);

    const isSingleOrder = location.pathname.includes('/orders/') && !location.pathname.includes('/create/');

    useEffect(() => {
        dispatch(fetchUserProfileAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const refreshAuth = async () => {
            await UserHelper.refreshAuth();

            setIsValidToken(UserHelper.hasValidIdToken());

            dispatch(fetchUserProfileAction());
        };

        const refreshToken = UserHelper.getRefreshToken();
        if (!isValidToken && refreshToken) {
            refreshAuth();
        }
    }, [isValidToken, dispatch]);

    useEffect(() => {
        if (user && !isValidToken) {
            setIsValidToken(UserHelper.hasValidIdToken());
        }
        // CL:  Logging to identify why it is sometimes asking for login when opening in a new tab
        console.log('isValidToken: ' + isValidToken);
        console.log('user: ' + JSON.stringify(user));
    }, [isValidToken, user]);

    useEffect(() => {
        if (!user || isSingleOrder) return;

        if (user.roles?.includes(UserRole.CONTACT_US)) {
            dispatch(actionUpdateContactUs(false));
        }

        if (user.roles?.includes(UserRole.SUPER_MAP)) {
            dispatch(actionUpdateSupermapQueue());
            dispatch(actionRefreshReportedListings(false));
        }

        if (user.roles?.includes(UserRole.ORDERS_ADMIN)) {
            dispatch(actionUpdateOrders());
        }

        if (user.roles?.includes(UserRole.REPORTS)) {
            dispatch(actionRefreshMainReport());
            dispatch(actionRefreshSoarReport());
            dispatch(actionRefreshSubdomainsReport());
        }

        if (user.roles?.includes(UserRole.ANALYTICS)) {
            const defaultMapAnalyticsFilter: AnalyticsFilter = {
                dates: { from: startOfDay(subDays(new Date(), 30)), to: endOfDay(endOfDay(new Date())) },
            };
            dispatch(actionRefreshAnalyticsHeatmap(defaultMapAnalyticsFilter));
        }
        if (user.roles?.includes(UserRole.COMMENTS)) {
            dispatch(actionRefreshReportedComments(Review.PENDING));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // No token send to login
    if (!isValidToken) {
        return (
            <PageContainer fluid>
                <NavigationBar />
                <ContentContainer fluid>
                    <Login />
                </ContentContainer>
            </PageContainer>
        );
    }

    // No user but token so loading screen
    if (!user) {
        return (
            <PageContainer fluid>
                <NavigationBar />
                <ContentContainer fluid>
                    <Spinner />
                </ContentContainer>
            </PageContainer>
        );
    }

    return (
        <React.Fragment>
            <NavigationBar />
            <PageContainer fluid>
                <Row>
                    <Col md={2} style={{ margin: 0, padding: 0 }}>
                        <NavigationContainer fluid>
                            <Navigation />
                        </NavigationContainer>
                    </Col>
                    <Col md={10} style={{ margin: 0, padding: 0 }}>
                        <ContentContainer fluid>
                            <Routes />
                        </ContentContainer>
                    </Col>
                </Row>
            </PageContainer>
            <ToastContainer />
        </React.Fragment>
    );
};

export default ConnectedRoot;

const PageContainer = styled(Container)`
    margin: 0;
    padding: 0;
    height: 100%;
`;

const ContentContainer = styled(Container)`
    min-height: calc(100vh - 78px);
    background: #191a1a;
    margin: 0;
    padding: 0;
    height: 100%;
`;

const NavigationContainer = styled(Container)`
    background: rgba(33, 37, 41, 1);
    min-height: calc(100vh - 78px);
    height: 100%;
    margin: 0;
    padding: 0;
`;
