import 'rsuite/dist/rsuite.css';
import './styles/leaflet-draw.css';
import './styles/leaflet.css';
import './styles/index.css';

import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-leaflet-fullscreen/styles.css';

import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ErrorBoundary from './components/Shared/ErrorBoundary/error-boundary';
import store from './store/store';
import { history } from './store/history';
import ConnectedRoot from './connected-root';

const Root = () => {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ConnectedRoot />
                </ConnectedRouter>
            </Provider>
        </ErrorBoundary>
    );
};

export default Root;
