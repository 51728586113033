import React, { ReactNode } from 'react';
import Config from '../../config';
import { LayersControl, TileLayer } from 'react-leaflet';

const { BaseLayer } = LayersControl;

interface Props {
    children?: ReactNode;
}

const GOOGLE_MAX_ZOOM = 28;
const GOOGLE_MAX_NATIVE_ZOOM = 20;

const OSM_MAX_ZOOM = 28;
const OSM_MAX_NATIVE_ZOOM = 18;

const STATIC_MAX_ZOOM = 28;
const STATIC_MAX_NATIVE_ZOOM = 20;

const LeafletBaseMaps = (props: Props) => {
    return (
        <React.Fragment>
            <BaseLayer name="White">
                <TileLayer url="/assets/white-tile.png" maxNativeZoom={STATIC_MAX_NATIVE_ZOOM} />
            </BaseLayer>
            <BaseLayer name="Light Gray">
                <TileLayer url="/assets/light-gray-tile.png" maxNativeZoom={STATIC_MAX_NATIVE_ZOOM} />
            </BaseLayer>
            <BaseLayer name="Dark Gray">
                <TileLayer url="/assets/dark-gray-tile.png" maxNativeZoom={STATIC_MAX_NATIVE_ZOOM} />
            </BaseLayer>
            <BaseLayer name="Slate Gray">
                <TileLayer url="/assets/slate-gray-tile.png" maxNativeZoom={STATIC_MAX_NATIVE_ZOOM} />
            </BaseLayer>
            <BaseLayer name="Black">
                <TileLayer
                    url="/assets/black-tile.png"
                    maxZoom={STATIC_MAX_ZOOM}
                    maxNativeZoom={STATIC_MAX_NATIVE_ZOOM}
                />
            </BaseLayer>
            <BaseLayer name="Google Hybrid" checked>
                <TileLayer
                    attribution=""
                    url={Config.maps.GOOGLE_HYBRID}
                    maxZoom={GOOGLE_MAX_ZOOM}
                    maxNativeZoom={GOOGLE_MAX_NATIVE_ZOOM}
                />
            </BaseLayer>
            <BaseLayer name="Google Satellite">
                <TileLayer
                    attribution=""
                    url={Config.maps.GOOGLE_SATELLITE}
                    maxZoom={GOOGLE_MAX_ZOOM}
                    maxNativeZoom={GOOGLE_MAX_NATIVE_ZOOM}
                />
            </BaseLayer>
            <BaseLayer name="Google Street">
                <TileLayer
                    attribution=""
                    url={Config.maps.GOOGLE_STREET}
                    maxZoom={GOOGLE_MAX_ZOOM}
                    maxNativeZoom={GOOGLE_MAX_NATIVE_ZOOM}
                />
            </BaseLayer>
            <BaseLayer name="OSM Street">
                <TileLayer
                    attribution=""
                    url={Config.maps.OSM_STREET}
                    maxZoom={OSM_MAX_ZOOM}
                    maxNativeZoom={OSM_MAX_NATIVE_ZOOM}
                />
            </BaseLayer>
            {props.children}
        </React.Fragment>
    );
};

export default LeafletBaseMaps;
