import React, { useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { CommentDTO, ReportedCommentDTO } from '../../../api/model';
import config from '../../../config';
import { Panel, Table, TableWrapper, Button } from '../../style';
import ViewHelper from '../../view-helper';
import ReportedDetails from '../Shared/reported-details';

interface Props {
    items: ReportedCommentDTO[];
    onDeleteClick: (reportId: string) => void;
    onIgnoreClick: (reportId: string) => void;
    onUserClick: (userEmail: string) => void;
}

const ReportedTable = (props: Props) => {
    const { items } = props;
    const [expanded, setExpanded] = useState<string[]>([]);

    const handleOnExpand = (row, isExpand, _rowIndex, _e) => {
        if (isExpand) {
            setExpanded([...expanded, row.id]);
        } else {
            setExpanded(expanded.filter((x) => x !== row.id));
        }
    };

    const expandRow = {
        renderer: (row, _rowIndex) => {
            const cr: ReportedCommentDTO = row;
            return <ReportedDetails report={cr} />;
        },
        expanded: expanded,
        onExpand: handleOnExpand,
        showExpandColumn: true,
        expandByColumnOnly: true,
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'comment',
            text: 'Updated',
            formatter: (cell: CommentDTO) => ViewHelper.formatDateWithTime(cell.updatedAt),
        },
        {
            dataField: 'comment',
            text: 'Comment',
            formatter: (cell: CommentDTO) => cell.text,
        },
        {
            dataField: 'comment',
            text: 'User',
            formatter: (cell: CommentDTO) => (
                <Button color="link" onClick={() => props.onUserClick(cell.userName.toLocaleLowerCase())}>
                    {cell.userName}
                </Button>
            ),
        },
        {
            dataField: 'comment',
            text: 'Votes',
            sort: true,
            formatter: (cell: CommentDTO) => cell.vote || 0,
        },
        {
            dataField: 'createdAt',
            text: 'Reported',
            sort: true,
            formatter: (cell) => ViewHelper.formatDateWithTime(cell),
        },
        {
            dataField: 'reason',
            text: 'Reason',
            sort: true,
            formatter: (cell) => <Span>{cell}</Span>,
        },
        {
            dataField: 'userName',
            text: 'Reported By',
            sort: true,
            formatter: (cell: string, row: ReportedCommentDTO) => (
                <a href={`${config.CLIENT_URL}/profile/${row.userId}`} target="_blank" rel="noopener noreferrer">
                    {cell}
                </a>
            ),
            csvFormatter: (cell) => (cell ? cell : ''),
        },
        {
            dataField: 'id',
            text: 'Ignore',
            formatter: (cell: string) => (
                <Button color="link" onClick={() => props.onIgnoreClick(cell)}>
                    Ignore
                </Button>
            ),
        },
        {
            dataField: 'id',
            text: 'Delete',
            formatter: (cell: string) => (
                <Button color="link" class="danger" onClick={() => props.onDeleteClick(cell)}>
                    Delete
                </Button>
            ),
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return <Button onClick={handleClick}>EXPORT CSV</Button>;
    };

    return (
        <ToolkitProvider
            keyField="id"
            data={items}
            columns={columns}
            exportCSV={{
                fileName: `reported_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
            }}
        >
            {(props) => (
                <React.Fragment>
                    <TableWrapper>
                        <Table {...props.baseProps} pagination={paginationFactory()} expandRow={expandRow} />
                    </TableWrapper>
                    <Panel>
                        <MyExportCSV {...props.csvProps} />
                    </Panel>
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
};

export default ReportedTable;
const Span = styled.div`
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
`;
