import { useSelector } from 'react-redux';
import { selectUser } from '../store/AppStore/selectors';
import React from 'react';

import styled from 'styled-components';
import { NavigationPermissionItem } from './navigation';
import NavItem from './navigation-item';

interface NavigationGroupProps {
    name: string;
    items: NavigationPermissionItem[];
}

const NavGroup = (props: NavigationGroupProps) => {
    const user = useSelector(selectUser);
    const requiredRoles = new Set(props.items.map((item) => item.requiredRole));
    const isGroupVisible = user.roles.filter((role) => requiredRoles.has(role)).length > 0;

    if (!isGroupVisible) return <React.Fragment />;

    return (
        <React.Fragment>
            <NavHeader>{props.name}</NavHeader>
            {props.items.map((navItem) => {
                return <NavItem item={navItem} badgeCount={navItem.badgeCount} key={navItem.name} />;
            })}
            <Spacer />
        </React.Fragment>
    );
};

export default NavGroup;

const NavHeader = styled.li`
    font-weight: bold;
    font-size: 1.1rem;
    color: white;
    margin: 0;
    padding: 14px 8px 12px 18px;
    border-top: 1px solid rgba(125, 125, 125, 0.3);

    // TODO Remove border of top of first item
    &:nth-child(first) {
        border-top: none;
    }
`;

const Spacer = styled.div`
    width: 100%;
    height: 20px;
`;
