import { actionTypes as at } from './constants';

export const actionAddUpdateSTACChildren = (catalogId: number, childrenIds: number[]) => ({
    type: at.EMS_ADD_UPDATE_STAC_CHILDREN,
    payload: {
        catalog: catalogId,
        children: childrenIds,
    },
});

export const actionAddUpdateSTACItems = (catalogId: number, itemsIds: number[]) => ({
    type: at.EMS_ADD_UPDATE_STAC_CHILDREN,
    payload: {
        catalog: catalogId,
        items: itemsIds,
    },
});
