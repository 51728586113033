import L, { LatLngBounds } from 'leaflet';
import * as React from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import Config from '../../config';
import GeoUtil from '../../lib/geo-util';
// import Control from 'react-leaflet-control';
import { useEffect, useRef, useState } from 'react';
import LeafletMap from '../MapView/leaflet-map';
import LeafletBaseMaps from '../Shared/leaflet-basemaps';

const { BaseLayer } = LayersControl;

export interface TileEngineLayer {
    title: string;
    opacity: number;
    checked: boolean;
    serverUrl: string;
    useCache: boolean;
    useDisk: string;
    filepath: string;
}

interface Props {
    token?: string;
    bounds?: LatLngBounds;
    layers?: TileEngineLayer[];
}

const TileEngineMap = (props: Props) => {
    const { layers, bounds, token } = props;

    const leafletMapRef = useRef(null);
    const [zoom, setZoom] = useState(0); // eslint-disable-line
    const [xy, setXy] = useState<{ x?: number; y?: number }>({}); // eslint-disable-line

    useEffect(() => {
        if (bounds && leafletMapRef && leafletMapRef.current) {
            const leafletMap = leafletMapRef.current;
            const geoJson = GeoUtil.latLngBoundsToGeoJSON(bounds);

            const locationLayer = L.geoJSON(geoJson, {
                style: { fillColor: '#000000', fillOpacity: 0 },
            }).addTo(leafletMap);
            leafletMap.fitBounds(locationLayer.getBounds());
        }
    }, [bounds, leafletMapRef]);

    useEffect(() => {
        if (leafletMapRef && leafletMapRef.current) {
            const leafletMap = leafletMapRef.current;

            leafletMap.on('zoomend', () => {
                setZoom(leafletMap.getZoom());
            });
            leafletMap.on('click', (e) => {
                setXy(GeoUtil.getTMSTileXY(e.latlng.lat, e.latlng.lng, leafletMap.getZoom()));
            });

            setZoom(leafletMap.getZoom());
        }
    }, [leafletMapRef]);

    return (
        <MapDiv>
            <LeafletMap mapRef={leafletMapRef} fullscreenControl={true}>
                {/* <Control position="topright">
                    <ControlPanelDiv>
                        <ControlDiv>
                            <span>Zoom:</span> {zoom}
                        </ControlDiv>
                        <ControlDiv>
                            <span>X:</span> {xy.x}
                        </ControlDiv>
                        <ControlDiv>
                            <span>Y:</span> {xy.y}
                        </ControlDiv>
                    </ControlPanelDiv>
                </Control> */}
                <LayersControl position="topright">
                    <LeafletBaseMaps />
                </LayersControl>
                {layers
                    ?.filter((l) => l.checked)
                    .map((l, i) => (
                        <TileLayer
                            key={`layer-map-${i}`}
                            zIndex={100}
                            tms={true}
                            attribution=""
                            url={`${l.serverUrl}/images/${l.filepath}/tile/{z}/{x}/{y}?use_disk=${
                                l.useDisk
                            }&use_cache=${l.useCache}${token ? `&access_token=${token}` : ''}`}
                            opacity={l.opacity}
                        />
                    ))}
            </LeafletMap>
        </MapDiv>
    );
};

export default TileEngineMap;

const MapDiv = styled.div`
    min-height: calc(100vh - 240px);
    height: 100%;
    width: 100%;

    & #satellite-aoi-button {
        display: none;
    }
`;

// const ControlPanelDiv = styled.div`
//     display: flex;
//     background-color: white;
//     opacity: 0.8;
//     font-size: 15px;
// `;

// const ControlDiv = styled.div`
//     margin: 5px;
//     span {
//         font-weight: 700;
//     }
// `;
