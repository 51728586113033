import React, { useState } from 'react';
import IconButton from '../Shared/icon-button';
import SoarModal from '../Shared/soar-modal';
import { Button, Col, ConfirmModalFooter, FormGroup, Input, Label } from '../style';

interface Props {
    title?: string;
    origValue: string;
    onConfirm: (value: string) => void;
}

const EditValueModal = (props: Props) => {
    const { title, origValue } = props;

    const [modal, setModal] = useState(false);
    const [value, setValue] = useState(origValue);

    const toggle = () => setModal(!modal);

    const onConfirm = () => {
        props.onConfirm(value);
        toggle();
    };
    const modalTitle = title ? title : 'Edit';
    return (
        <React.Fragment>
            <IconButton title={modalTitle} onClick={toggle} faIcon="fa-edit" />
            <SoarModal title={modalTitle} isOpen={modal} onClosed={() => setModal(false)} width="600px">
                <FormGroup>
                    <Label for="value">Enter new value</Label>
                    <Col>
                        <Input
                            type="text"
                            name="value"
                            id="valueId"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Col>
                </FormGroup>
                <ConfirmModalFooter margin="5px 0px">
                    <Button onClick={onConfirm}>Confirm</Button>
                    <Button color="warning" onClick={() => setModal(false)}>
                        Cancel
                    </Button>
                </ConfirmModalFooter>
            </SoarModal>
        </React.Fragment>
    );
};

export default EditValueModal;
