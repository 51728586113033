import React, { useEffect, useState } from 'react';
import ApiTracker, { TrackerDTO } from '../../api/api-tracker';
import { Card, Container, Divider, Subtitle, Title } from '../style';
import CreateTracker from './create-tracker';
import NewTracker from './new-tracker';
import TrackerTable from './tracker-table';

const Tracker = () => {
    const [loadingCreateNewTracker, setLoadingCreateNewTracker] = useState(false);
    const [newTrackerCode, setNewTrackerCode] = useState<string | undefined>(undefined);
    const [trackers, setTrackers] = useState<TrackerDTO[]>([]);

    useEffect(() => {
        ApiTracker.getTrackers().then((trackers) => {
            setTrackers(trackers);
        });
    }, []);

    const handleCreateTracker = (trackerName: string) => {
        setNewTrackerCode(undefined);
        setLoadingCreateNewTracker(true);
        ApiTracker.createTracker(trackerName)
            .then((tracker) => {
                setTrackers([tracker, ...trackers]);
                setLoadingCreateNewTracker(false);
            })
            .catch((_) => {
                //todo display error
            });
    };

    return (
        <Container>
            <Title>Create A New Campaign Tracker Code</Title>
            <Card>
                {newTrackerCode ? (
                    <NewTracker
                        trackerCode={newTrackerCode}
                        onReset={() => {
                            setNewTrackerCode(undefined);
                        }}
                    />
                ) : (
                    <CreateTracker onCreateTracker={handleCreateTracker} isLoading={loadingCreateNewTracker} />
                )}
            </Card>
            <Card>
                <Subtitle>Active Campaign Tracker Codes</Subtitle>
                <Divider />
                <TrackerTable items={trackers} />
            </Card>
        </Container>
    );
};

export default Tracker;
