import store from './store/store';
import { push } from 'connected-react-router';

export default class RoutingHelper {
    public static myProfile() {
        store.dispatch(push('/user'));
    }

    public static home() {
        store.dispatch(push('/'));
    }

    public static login() {
        store.dispatch(push('/login'));
    }

    public static reviews() {
        store.dispatch(push('/reviews'));
    }

    public static review() {
        store.dispatch(push('/review'));
    }

    public static featured() {
        store.dispatch(push('/featured'));
    }

    public static search() {
        store.dispatch(push('/search'));
    }

    public static dmt() {
        store.dispatch(push('/dmt'));
    }

    public static contactUs() {
        store.dispatch(push('/contact-us'));
    }

    public static analytics() {
        store.dispatch(push('/analytics'));
    }

    public static vouchers() {
        store.dispatch(push('/vouchers'));
    }

    public static reportsSubdomains() {
        store.dispatch(push('/reports/subdomains'));
    }

    public static reportsSoar() {
        store.dispatch(push('/reports/soar'));
    }

    public static payouts(id?: string) {
        if (id) {
            store.dispatch(push('/payouts/' + id));
        } else {
            store.dispatch(push('/payouts'));
        }
    }

    public static payoutsSkym(id?: string) {
        if (id) {
            store.dispatch(push('/payouts_skym/' + id));
        } else {
            store.dispatch(push('/payouts_skym'));
        }
    }

    public static users() {
        store.dispatch(push('/user'));
    }

    public static createSubdomain() {
        store.dispatch(push('/subdomains'));
    }

    public static navigateToPath(path: string) {
        store.dispatch(push(path));
    }
}
