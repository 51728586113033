import React, { useState } from 'react';
import { AnalyticsDTO } from '../../../api/model';
import FormattedDetailsTable, { Line } from '../../Shared/formatted-details-table';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from '../../style';
import UserManagementCard from './user-management-card';
import styled from 'styled-components';
import Chart from 'react-google-charts';
import { ManageUserDataSort } from './manage-user-helper';
import ManageUserAnalyticsTable from './manage-user-analytics-table';
import countryList from 'react-select-country-list';

interface Props {
    userAnalytics: AnalyticsDTO;
}

type AnalyticsActions = 'PREVIEW' | 'SEARCH' | 'VIEW' | 'DOWNLOAD';

const ManageUserAnalyticsAdmin = (props: Props) => {
    const totalInteractions = props.userAnalytics.total;
    const userAnalyticsData = props.userAnalytics.list;

    const [open, setOpen] = useState('');
    const [openNested, setOpenNested] = useState('');

    const toggle = (id) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const toggleNested = (id) => {
        if (openNested === id) {
            setOpenNested('');
        } else {
            setOpenNested(id);
        }
    };

    const analyticTypeByAction: AnalyticsActions =
        userAnalyticsData?.length && ManageUserDataSort.groupBy(userAnalyticsData, 'action');

    const chartData = [
        ['Action', 'Counts'],
        ['PREVIEW', analyticTypeByAction['PREVIEW']?.length],
        ['SEARCH', analyticTypeByAction['SEARCH']?.length],
        ['VIEW', analyticTypeByAction['VIEW']?.length],
        ['DOWNLOAD', analyticTypeByAction['DOWNLOAD']?.length],
    ];

    return (
        <React.Fragment>
            <UserManagementCard>
                <Accordion flush open={open} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">User Analytics</AccordionHeader>
                        <AccordionBody accordionId="1">
                            <ManagementTitle>Overview</ManagementTitle>
                            <FormattedDetailsTable>
                                <Line label={'Analytics Records'} value={totalInteractions.toLocaleString()} />
                                <Line
                                    label={'Countries Accessed From'}
                                    value={Array.from(
                                        new Set(
                                            userAnalyticsData.map((data) =>
                                                data.countryCode
                                                    ? `${countryList().getLabel(data.countryCode)} `
                                                    : 'Unknown '
                                            )
                                        )
                                    ).toString()}
                                />
                                <Line
                                    label={'Ip Address'}
                                    value={Array.from(
                                        new Set(
                                            userAnalyticsData.map((data) =>
                                                data.ipAddress ? `${data.ipAddress} ` : 'Unknown '
                                            )
                                        )
                                    ).toString()}
                                />
                            </FormattedDetailsTable>
                            <ManagementTitle>Habit</ManagementTitle>
                            <FormattedDetailsTable>
                                {analyticTypeByAction['PREVIEW']?.length ? (
                                    <Line
                                        label={'Preview'}
                                        value={analyticTypeByAction['PREVIEW'].length.toLocaleString()}
                                    />
                                ) : null}
                                {analyticTypeByAction['SEARCH']?.length ? (
                                    <Line
                                        label={'Search'}
                                        value={analyticTypeByAction['SEARCH'].length.toLocaleString()}
                                    />
                                ) : null}
                                {analyticTypeByAction['VIEW']?.length ? (
                                    <Line label={'View'} value={analyticTypeByAction['VIEW'].length.toLocaleString()} />
                                ) : null}
                                {analyticTypeByAction['DOWNLOAD']?.length ? (
                                    <Line
                                        label={'Download'}
                                        value={analyticTypeByAction['DOWNLOAD'].length.toLocaleString()}
                                    />
                                ) : null}
                            </FormattedDetailsTable>
                            {analyticTypeByAction && chartData ? (
                                <Chart
                                    style={{
                                        width: '100%',
                                        margin: '0 auto',
                                    }}
                                    width={'100%'}
                                    height={'100%'}
                                    chartType="PieChart"
                                    data={chartData}
                                    options={{
                                        backgroundColor: 'transparent',
                                        legend: {
                                            position: 'labeled',
                                            labeledValueText: 'both',
                                            textStyle: {
                                                color: 'white',
                                            },
                                        },
                                        textStyle: {
                                            color: 'white',
                                        },
                                        chartArea: { width: '80%' },
                                    }}
                                />
                            ) : null}
                            <Accordion open={openNested} toggle={toggleNested}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">Data Table</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <FormattedDetailsTable>
                                            <ManageUserAnalyticsTable items={userAnalyticsData} />
                                        </FormattedDetailsTable>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </UserManagementCard>
        </React.Fragment>
    );
};

export default ManageUserAnalyticsAdmin;

const ManagementTitle = styled.p`
    font-size: 18px;
    color: white;
    margin: 5px 5px -10px 5px;
`;
