import { format, formatISO, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import ApiReports from '../../../api/api-reports';
import { ReportDTO } from '../../../api/model';
import { DatesFromTo, ReportInterval } from '../../../api/model-api';
import InputDateRange from '../../Shared/Filters/DateRange/input-date-range';
import InputReportInterval from '../../Shared/Filters/input-report-interval';
import { Card, Col, FormGroup, Input, Label, Row, Spinner, ChartTitle } from '../../style';

const UserBarChart = () => {
    const [interval, setInterval] = useState<ReportInterval>(ReportInterval.MONTH);
    const [report, setReport] = useState<ReportDTO>(undefined);
    const [searches, setSearches] = useState<any[]>([]);
    const [dates, setDates] = useState<DatesFromTo>();
    const [cumulative, setCumulative] = useState('true');

    useEffect(() => {
        const refresh = async () => {
            try {
                const res = await ApiReports.getUsersReportByInterval(interval);
                setReport(res);
            } catch (err) {
                // setError(err.toString());
            }
        };
        refresh();
    }, [interval]);

    useEffect(() => {
        if (report) {
            const dateFrom = dates?.from ? formatISO(dates.from, { representation: 'date' }) : undefined;
            const dateTo = dates?.to ? formatISO(dates.to, { representation: 'date' }) : undefined;

            const filtered = report.intervals.filter(
                (item) => (!dateFrom || item.label >= dateFrom) && (!dateTo || item.label <= dateTo)
            );
            let accumulator = 0;
            if (dateFrom && cumulative === 'true') {
                accumulator = report.intervals
                    .filter((item) => item.label < dateFrom)
                    .reduce((total, item) => total + item.total, 0);
            }

            const data = [];
            const length = filtered.length;
            let step = 1;
            if (length > 450) {
                step = Math.ceil(length / 450);
            }

            filtered.forEach((item, i) => {
                accumulator = accumulator + item.total;
                const row: any[] =
                    interval === ReportInterval.DAY
                        ? [format(parseISO(item.label), 'dd MMM YYY'), accumulator]
                        : [format(parseISO(item.label), 'MMM YYY'), accumulator];
                if (i % step === 0 || i === length - 1) {
                    data.push(row);
                    if (cumulative === 'false') {
                        accumulator = 0;
                    }
                }
            });
            data.unshift(['Date', 'Registrations']);
            setSearches(data);
        }
    }, [report, dates, cumulative, interval]);

    const onIntervalChange = (interval: ReportInterval) => {
        setInterval(interval);
    };

    if (!searches || !report) {
        return (
            <React.Fragment>
                <Card height="104px">
                    <Spinner />
                </Card>
                <Card height="664px">
                    <Spinner />
                </Card>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="intervals">Filter by Interval</Label>
                            <InputReportInterval value={interval} onChange={onIntervalChange} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange fromTo={dates} onChange={setDates} interval={interval} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Frequency</Label>
                            <Input
                                title="Data type"
                                value={cumulative}
                                onChange={(e) => setCumulative(e.target.value)}
                                type="select"
                                name="cumulative"
                            >
                                <option value={'true'}>Cumulative</option>
                                <option value={'false'}>Discrete</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>

            <Card>
                <ChartTitle>User Registrations</ChartTitle>
                <Chart
                    style={{
                        width: '100%',
                    }}
                    width={'100%'}
                    height={'600px'}
                    chartType="ColumnChart"
                    data={searches}
                    options={{
                        backgroundColor: 'transparent',
                        legend: 'none',
                        colors: ['#00A2FF'],
                        textStyle: {
                            color: 'white',
                        },
                        chartArea: { width: '80%', height: '80%' },
                        hAxis: {
                            minValue: 0,
                            textStyle: {
                                color: 'white',
                            },
                        },
                        vAxis: {
                            textStyle: {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Card>
        </React.Fragment>
    );
};

export default UserBarChart;
