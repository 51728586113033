import React, { ReactNode } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

interface Props {
    title: string;
    isOpen: boolean;
    children: ReactNode;
    onClosed: () => void;
    width?: string;
}

const SoarModal: React.FC<Props> = (props: Props) => {
    const { title, isOpen, children, onClosed, width } = props;

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <StyledModal isOpen={isOpen} onClosed={onClosed} scrollable={true} width={width || '80vw'}>
                <ModalHeader toggle={onClosed}>{title}</ModalHeader>
                <ModalBody>{children}</ModalBody>
            </StyledModal>
        </>
    );
};

export default SoarModal;

interface StyledModalProps {
    width?: string;
}
const StyledModal = styled(Modal)<StyledModalProps>`
    min-width: 600px;
    max-width: ${(props) => props.width};

    .modal-content {
        background: rgba(33, 37, 41, 1);

        pre {
            color: white;
        }
    }

    .modal-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        h5 {
            color: white;
            font-weight: unset;
        }
    }

    .modal-header .btn-close {
        filter: invert(1) grayscale(100%) brightness(200%);
    }
`;
