import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { ListingCommentsDTO } from '../../../api/model';
import config from '../../../config';
import { Button, ButtonWrapper, Panel, Table, TableWrapper } from '../../style';
import ViewHelper from '../../view-helper';

interface Props {
    items: ListingCommentsDTO[];
}

const ListingsTable = (props: Props) => {
    const { items } = props;
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'listingTitle',
            text: 'Listing',
            sort: true,
            formatter: (cell) => (cell ? cell : ''),
        },
        {
            dataField: 'totalComments',
            text: 'Comments',
            sort: true,
            formatter: (cell) => cell.toLocaleString(),
        },
        {
            dataField: 'listingId',
            text: '',
            formatter: (cell) => (
                <a
                    href={`https://${config.SUPER_MAP_DOMAIN}?tileLayer=${cell}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    link
                </a>
            ),
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <ButtonWrapper>
                <Button onClick={handleClick}>EXPORT CSV</Button>
            </ButtonWrapper>
        );
    };

    return (
        <ToolkitProvider
            keyField="id"
            data={items}
            columns={columns}
            exportCSV={{
                fileName: `comments_by_listings_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
            }}
        >
            {(props) => (
                <>
                    <TableWrapper>
                        <Table {...props.baseProps} pagination={paginationFactory()} />
                    </TableWrapper>
                    <Panel>
                        <MyExportCSV {...props.csvProps} />
                    </Panel>
                </>
            )}
        </ToolkitProvider>
    );
};

export default ListingsTable;
