import { LatLng } from 'leaflet';
import React, { useMemo, useRef, useState } from 'react';
import { LayersControl } from 'react-leaflet';
import styled from 'styled-components';
import GeoUtil from '../../lib/geo-util';
import DistortableOverlay from '../MapView/Annotations/DistortableImageOverlay/DistortableOverlay';
import LeafletMap from '../MapView/leaflet-map';
import { Button } from '../style';
import LeafletBaseMaps from './leaflet-basemaps';
import OpacitySlider from './opacity-slider';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const L = require('leaflet');

interface Props {
    distoredGeometry: string;
    previewUrl?: string;
    editable: boolean;
    onCornersUpdated?: (corners: LatLng[]) => void;
}

type EditModeType = 'rotate' | 'distort' | 'translate' | 'scale' | 'none';

const ListingImageMap = (props: Props) => {
    const { distoredGeometry, previewUrl, editable, onCornersUpdated } = props;
    const [editMode, setEditMode] = useState<EditModeType>('none');
    const [opacity, setOpacity] = useState(1);

    const leafletMapRef = useRef(null);

    // Refer comment #4562 for more details
    // The translate is broken and seems to be due to leaflet versions we can get around it by adding the dom events here
    L.DomEvent.fakeStop = () => true;
    L.DomEvent.skipped = () => true;

    const distoredGeometryPoints = useMemo(() => {
        const points = GeoUtil.polygonFromPolygonWKT(distoredGeometry);
        const corners = [points[2], points[3], points[1], points[0]];
        return corners;
    }, [distoredGeometry]);

    return (
        <React.Fragment>
            {editable && (
                <div className="mb-2 d-flex">
                    <Button outline={editMode !== 'rotate'} size="sm" onClick={() => setEditMode('rotate')}>
                        <i className="fa fa-refresh"></i>
                        <span> Rotate</span>
                    </Button>
                    <Button outline={editMode !== 'distort'} size="sm" onClick={() => setEditMode('distort')}>
                        <i className="fa fa-object-group"></i>
                        <span> Distort</span>
                    </Button>
                    <Button outline={editMode !== 'translate'} size="sm" onClick={() => setEditMode('translate')}>
                        <i className="fa fa-arrows"></i>
                        <span> Translate</span>
                    </Button>
                    <Button outline={editMode !== 'scale'} size="sm" onClick={() => setEditMode('scale')}>
                        <i className="fa fa-expand"></i>
                        <span> Scale</span>
                    </Button>
                    <Button outline={editMode !== 'none'} size="sm" onClick={() => setEditMode('none')}>
                        <i className="fa fa-lock"></i>
                        <span> Lock</span>
                    </Button>
                </div>
            )}
            <SingleMapViewContainer className="single-feature-map">
                <LeafletMap mapRef={leafletMapRef} fullscreenControl={true}>
                    <LayersControl position="topright">
                        <LeafletBaseMaps />
                        {previewUrl && (
                            <DistortableOverlay
                                corners={distoredGeometryPoints}
                                setCorners={onCornersUpdated}
                                dataURL={previewUrl}
                                mode={editMode}
                                opacity={opacity}
                            />
                        )}
                    </LayersControl>
                </LeafletMap>
                <div style={{ height: '20px', width: '100%', margin: '0 auto' }}>
                    <OpacitySlider opacity={opacity} onChange={setOpacity} />
                </div>
            </SingleMapViewContainer>
        </React.Fragment>
    );
};

export default ListingImageMap;

const SingleMapViewContainer = styled.div`
    width: 100%;
    height: 400px;
    margin-bottom: 40px;

    span {
        color: black !important;
    }
`;
