import React, { ReactNode } from 'react';
import { Modal as ReactstrapModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';

interface StyledModalProps {
    title?: string | JSX.Element;
    isOpen: boolean;
    toggle?: () => void;
    width?: string;
    marginTop?: string;
    children?: ReactNode;
}

const StyledModal = (props: StyledModalProps) => {
    const { title, isOpen, toggle, width, marginTop, children } = props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} zIndex={100000} width={width} margin={marginTop}>
            <StyledHeader>
                {toggle && <StyledCloseIcon onClick={() => toggle()} />}
                {title}
            </StyledHeader>
            <StyledBody>{children}</StyledBody>
        </Modal>
    );
};

export default StyledModal;

const Modal = styled(ReactstrapModal)`
    width: ${(props) => (props.width ? props.width : '')};
    max-width: ${(props) => (props.width ? props.width : '500px')};
    margin-top: ${(props) =>
        props.margin ? props.margin : '100px'}; // this is set but possibly needs to be percent for mobile?
    border: 1px solid rgb(255 255 255 / 0.3);
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 6px;
    margin-bottom: 20px;

    div.modal-content {
        background-color: transparent !important;
        margin: 0 auto;
    }

    @media (max-width: 650px) {
        width: 100% !important;
        margin-top: 50px; // this is set but possibly needs to be percent for mobile?
    }
`;

const StyledHeader = styled(ModalHeader)`
    color: rgba(255, 255, 255, 0.6) !important;
    border-bottom: 1px solid rgb(255 255 255 / 0.3);

    h5 {
        color: inherit;
        font-size: 25px;
        width: 100%;
        text-align: left;

        span {
            color: white;
        }
        span.small {
            font-size: 20px;
        }
    }

    span {
        color: inherit;
        font-size: 25px;
    }
`;

const StyledBody = styled.div`
    color: inherit !important;

    span {
        color: #eed926;
    }
`;

const StyledCloseIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin-top: 10px;
    cursor: pointer;
    float: right;
`;

export const StyledModalBody = styled(ModalBody)`
    color: white;
    font-size: 16px;

    p {
        color: white !important;
    }
`;

export const StyledModalFooter = styled(ModalFooter) <{ borderTop?: boolean }>`
    border-top: ${(props) => (props.borderTop ? '1px solid rgb(255 255 255 / 0.3)' : 'none')};
    justify-content: right;
    padding-bottom: 20px;

    p {
        color: white;
    }
`;
