import * as React from 'react';
import ReactDOM from 'react-dom/client';

import Root from './root';
import Config from './config';
import HttpsRedirect from 'react-https-redirect';
import * as Sentry from '@sentry/browser';

if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
    Sentry.init({ dsn: Config.SENTRY_URL });
}

let rooted;
if (Config.HTTPS) {
    rooted = (
        <HttpsRedirect>
            <Root />
        </HttpsRedirect>
    );
} else {
    rooted = <Root />;
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(rooted);
