import { LatLng } from 'leaflet';
import ViewHelper from '../components/view-helper';
import GeoUtil from '../lib/geo-util';
import Api from './api';
import {
    AnalyticsDTO,
    AnalyticsFilter,
    ReportDTO,
    HeatmapResult,
    AnalyticsReportDTO,
    AnalyticsMapViewDTO,
} from './model';
import { DatesFromTo, ReportInterval, ProductName, Provider } from './model-api';

export default class ApiAnalytics extends Api {
    public static apiGetReport(
        type: ReportInterval,
        provider?: Provider,
        satellite?: ProductName,
        dates?: DatesFromTo
    ): Promise<ReportDTO> {
        const params = {
            type: type,
            provider: provider,
            satellite: satellite ? ViewHelper.ANALYTICS_PRODUCT_NAMES[satellite] : undefined,
            from: dates?.from ? Math.ceil(dates.from.getTime() / 1000) : undefined,
            to: dates?.to ? Math.ceil(dates.to.getTime() / 1000) : undefined,
        };

        return this.axios.get('/v1/admin/analytics/report', { params: params }).then((res) => {
            return res.data;
        });
    }

    public static apiGetReportSatellite(dates?: DatesFromTo): Promise<ReportDTO> {
        const params = {
            timeFrom: dates?.from ? Math.ceil(dates.from.getTime() / 1000) : undefined,
            timeTo: dates?.to ? Math.ceil(dates.to.getTime() / 1000) : undefined,
        };

        return this.axios.get('/v1/admin/analytics/report/satellite', { params: params ? params : '' }).then((res) => {
            return res.data;
        });
    }

    public static apiGetHeatmap(filter: AnalyticsFilter): Promise<HeatmapResult[]> {
        const params = convertFilterIntoParams(filter);
        const config = {
            params: params,
        };

        const promise = this.axios
            .get('/v1/admin/analytics/heatmap', config)
            .then((res) => {
                return res.data.analytics;
            })
            .then((data) => {
                return data.map((d) => {
                    return {
                        date: new Date(d[0] * 1000),
                        latlng: new LatLng(d[2], d[1]),
                    };
                });
            });
        return promise;
    }

    public static apiGetAnalytics(filter: AnalyticsFilter): Promise<AnalyticsDTO> {
        const params = convertFilterIntoParams(filter);
        const config = {
            params: params,
        };

        return this.axios.get('/v1/admin/analytics', config).then((res) => {
            return res.data;
        });
    }

    public static apiGetAnalyticsMonthlyReport(): Promise<AnalyticsReportDTO[]> {
        return this.axios.get('v1/admin/analytics/report/monthly').then((res) => {
            return res.data.list;
        });
    }

    public static apiGetUserAnalytics(userEmail: string): Promise<AnalyticsDTO> {
        const params = {
            email: userEmail,
        };

        return this.axios.get('/v1/admin/analytics', { params }).then((res) => {
            return res.data;
        });
    }

    public static apiGetAnalyticsMapViews(dates?: DatesFromTo): Promise<AnalyticsMapViewDTO> {
        const params = {
            timeFrom: dates?.from ? Math.ceil(dates.from.getTime() / 1000) : undefined,
            timeTo: dates?.to ? Math.ceil(dates.to.getTime() / 1000) : undefined,
        };
        return this.axios.get('v1/admin/analytics/map-views', { params: params ? params : '' }).then((res) => {
            return res.data;
        });
    }
}

function convertFilterIntoParams(filter: AnalyticsFilter) {
    const { dates, ipAddress, email, limit, offset, bbox, provider, satellite } = filter;
    const params = {
        timeFrom: dates?.from ? Math.ceil(dates.from.getTime() / 1000) : undefined,
        timeTo: dates?.to ? Math.ceil(dates.to.getTime() / 1000) : undefined,
        ipAddress,
        email: email?.length > 0 ? email : undefined,
        provider,
        satellite: satellite ? ViewHelper.ANALYTICS_PRODUCT_NAMES[satellite] : undefined,
        limit,
        offset,
    };

    if (bbox) {
        const wkt = GeoUtil.latLngBoundsToWKT(bbox);
        params['geometryWKT'] = wkt;
    }
    return params;
}
