import 'cropperjs/dist/cropper.css';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import UploadDropzone from '../../../Shared/Upload/upload-dropzone';
import { Button, Spinner } from '../../../style';
import SoarModal from '../../../Shared/soar-modal';

interface ProfileAvatarEditProps {
    avatarUrl?: string;
    updatedAvatar?: string;
    handleUploadProfilePicture: (avatarbase64: string) => void;
}

const FileLimit = 10485760; // in Bytes
const FileTypes = ['image/jpeg', 'image/png'];

const ProfileAvatarEdit = (props: ProfileAvatarEditProps) => {
    const [image, setImage] = useState<any>('');
    const [updatedAvatarUrl, setUpdatedAvatarUrl] = useState<any>();
    const [cropper, setCropper] = useState<Cropper>();

    useEffect(() => {
        setImage(props.updatedAvatar ?? props.avatarUrl ?? '');
    }, [props.avatarUrl, props.updatedAvatar]);

    const handleFileDropped = (files: File[]) => {
        if (files.length < 1) {
            toast.error(
                'Please upload at least one file as a Profile Avatar. We currently only support 10MB .JPG and .PNG files.'
            );
            return;
        }
        if (files.length > 1) {
            toast.error(
                'One file is only supported as a Profile Avatar. We currently only support 10MB .JPG and .PNG files.'
            );
            return;
        }

        if (files[0].size > FileLimit) {
            toast.error('File exceeds limit. We currently only support <10MB .JPG and .PNG files.');
            return;
        }

        if (!FileTypes.includes(files[0].type)) {
            toast.error('File type not supported. We currently only support <10MB .JPG and .PNG files.');
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setUpdatedAvatarUrl(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
    };

    const handleUpdateAvatar = async () => {
        if (typeof cropper !== 'undefined') {
            const avatarDataUrl = await cropper.getCroppedCanvas().toDataURL();
            props.handleUploadProfilePicture(avatarDataUrl);
            setUpdatedAvatarUrl(undefined);
        } else {
            toast.error('Upload unsuccessful. Please try again');
            return;
        }
    };

    const handleCancel = () => {
        setUpdatedAvatarUrl(undefined);
    };

    return (
        <React.Fragment>
            <AvatarContainer>
                <AvatarPreviewContainer id="upload-container">
                    {image && !updatedAvatarUrl ? (
                        <UploadDropzone onSelectFiles={(file) => handleFileDropped(file)}>
                            <AvatarPreviewImage img src={image} />
                        </UploadDropzone>
                    ) : null}
                </AvatarPreviewContainer>
            </AvatarContainer>
            {image && updatedAvatarUrl ? (
                <SoarModal title="Edit your Server Profile Picture" width="450px" onClosed={handleCancel} isOpen={true}>
                    <React.Fragment>
                        <Cropper
                            style={{ height: 400, width: '100%' }}
                            zoomTo={0.5}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={updatedAvatarUrl}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                        <React.Fragment>
                            {!updatedAvatarUrl ? <AvatarPreviewImage img src={image} /> : null}
                        </React.Fragment>
                        <AvatarPreviewButtonContainer>
                            <Button onClick={handleUpdateAvatar}>Finish cropping</Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </AvatarPreviewButtonContainer>
                    </React.Fragment>
                </SoarModal>
            ) : null}
        </React.Fragment>
    );
};

export default ProfileAvatarEdit;

const AvatarContainer = styled.div`
    position: relative;
    padding: 25px;
`;

const AvatarPreviewContainer = styled.div`
    cursor: default;
    position: absolute;

    :after {
        content: 'Choose file or drag it here';
        position: absolute;
        color: #fff;
        top: 14px;
        background: rgba(0, 0, 0, 0.67);
        border-radius: 500px;
        width: 266px;
        height: 266px;
        opacity: 0;
        padding: 120px 0px 0px 25px;
        font-size: 18px;
        margin-left: 2px;
        cursor: pointer;
    }

    :hover {
        cursor: pointer;
    }

    :hover:after {
        opacity: 1;
    }

    .choose-file {
        padding: 12px 0px !important;
    }

    .choose-file input {
        pointer-events: auto !important;
        z-index: 99999;
        cursor: pointer;
    }
`;

const AvatarPreviewImage = styled.img`
    width: 270px !important;
    height: 270px;
    padding: 0px !important;
    border-radius: 500px;
    display: block;
    margin: 0 auto;
    border: 2px solid #eed926;
`;

const AvatarPreviewButtonContainer = styled.div`
    display: flex;
    margin: 15px;
    justify-content: center;
`;
