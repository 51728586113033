import React from 'react';
import styled from 'styled-components';
import ApiWms, { WMSServer } from '../../../../api/api-wms';
import { Row } from '../../../style';
import WMSDeleteAction from './wms-delete-action';

interface WMSActionsProps {
    wmsServer: WMSServer;
    invalidateServer: () => void;
}

const WMSActions = (props: WMSActionsProps) => {
    const handleClickRefreshServer = () => {
        alert('We are about the refresh the remote server.  Please wait a moment');
        ApiWms.refreshWMSServer(props.wmsServer.id)
            .then((_) => {
                alert('The remote server has been refreshed');
                props.invalidateServer();
            })
            .catch((err) => {
                alert('There was a problem refreshing the remote server: ' + err.message);
            });
    };

    return (
        <React.Fragment>
            <Row>
                <ActionButtonContainer>
                    <ActionButton title="Refresh the remote server" onClick={handleClickRefreshServer}>
                        <ActionButtonIcon className="fa fa-refresh" />
                    </ActionButton>

                    <WMSDeleteAction wms={props.wmsServer} invalidateServer={props.invalidateServer}>
                        <ActionButton title="Delete server from Soar" className="danger">
                            <ActionButtonIcon className="fa fa-trash" />
                        </ActionButton>
                    </WMSDeleteAction>
                </ActionButtonContainer>
            </Row>
        </React.Fragment>
    );
};

export default WMSActions;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const ActionButton = styled.button`
    background: none !important;
    border: none;
    padding: 2px;
    margin: 0 4px;
    color: #eed926;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;

    &:hover {
        font-size: 16px;
        padding: 0;
    }

    &.danger {
        color: #dc3545;
    }
`;

const ActionButtonIcon = styled.i``;
