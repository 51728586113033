import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiOrders from '../../api/api-orders';
import { DeliveryFormat, OrderForAdminDTO } from '../../api/model-orders';
import { Button, Card as StyledCard, Col, Label, Row } from '../style';
import FormattedDetailsTable, { Line } from '../Shared/formatted-details-table';
import ViewHelper from '../view-helper';
import Clock from './clock';
import EditCurrencyValueModal from './edit-currency-value-modal';
import EditDeliveryFormatModal from './edit-delivery-format-modal';
import EditValueModal from './edit-value-modal';
import OrderClock from './order-clock';
import OrderControls from './order-controls';
import OrderFiles from './order-files';
import OrderLogs from './order-logs';
import OrderMap from './order-map';
import OrderMetadataForm from './order-metadata-form';
import OrderNotes from './order-notes';
import OrderTimeline from './order-timeline';

interface Props {
    order: OrderForAdminDTO;
    invalidate: () => void;
}

const Order = (props: Props) => {
    const { order } = props;
    const payment = order.payment;

    const updateInvoice = (newInvoiceUrl: string) => {
        ApiOrders.updateOrder({ id: order.id, invoiceUrl: newInvoiceUrl })
            .then((_res) => props.invalidate())
            .catch((err) => toast.error(err.message));
    };

    const updateDeliveryFormat = (format: DeliveryFormat, description?: string) => {
        ApiOrders.updateOrder({ id: order.id, deliveryFormat: format, deliveryFormatNote: description })
            .then((_res) => {
                props.invalidate();
            })
            .catch((err) => toast.error(err.message));
    };

    const onScenesUpdate = (scenes: string[]) => {
        ApiOrders.updateOrder({ id: order.id, archives: scenes.map((i) => String(i)) })
            .then((_res) => {
                props.invalidate();
            })
            .catch((err) => toast.error(err.message));
    };

    const onOtherPaymentUpdate = (amount: number) => {
        ApiOrders.updateOrder({ id: order.id, paidByOtherMethod: amount })
            .then((_res) => {
                props.invalidate();
            })
            .catch((err) => toast.error(err.message));
    };

    const handleViewAsProvider = () => {
        window.open(`/supplier/orders/${order.id}`, '_blank');
    };

    const amountDue =
        payment.amount - (payment.paidByVoucher || 0) - (payment.paidByStripe || 0) - (payment.paidByOtherMethod || 0);
    const amountDueUSD =
        payment.amountUsd -
        (payment.paidByVoucherUsd || 0) -
        (payment.paidByStripeUsd || 0) -
        (payment.paidByOtherMethodUsd || 0);

    return (
        <React.Fragment>
            <Row>
                <Col md={6}>
                    <Card>
                        <FormattedDetailsTable label={'User Information'}>
                            <Line label={'User Email'} value={order.userEmail} />
                            <Line label={'User Name'} value={order.userName} />
                            <Line label={'Domain'} value={order.domain} />
                            <Line label={'Company'} value={order.company} />
                            <Line label={'Country'} value={order.countryCode} />
                            <Line label={'Industry'} value={order.industry} />
                        </FormattedDetailsTable>
                    </Card>
                    <Card>
                        <FormattedDetailsTable
                            label={
                                <React.Fragment>
                                    Payment
                                    {amountDue > 0 && (
                                        <React.Fragment>
                                            {' '}
                                            amount due:{' '}
                                            <SpanPaymentDue>
                                                {ViewHelper.formatPrice(amountDue, amountDueUSD, payment.currency)}
                                            </SpanPaymentDue>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            }
                        >
                            <Line
                                label={'Price per km²'}
                                value={ViewHelper.formatPrice(
                                    payment.pricePerKm,
                                    payment.pricePerKmUsd,
                                    payment.currency
                                )}
                            />
                            <Line
                                label={'Total'}
                                value={ViewHelper.formatPrice(payment.amount, payment.amountUsd, payment.currency)}
                            />
                            <Line
                                label={'Tax'}
                                value={ViewHelper.formatPrice(payment.tax, payment.taxUsd, payment.currency)}
                            />
                            <Line
                                label={'Discount'}
                                value={`${ViewHelper.formatPrice(
                                    payment.paidByVoucher,
                                    payment.paidByVoucherUsd,
                                    payment.currency
                                )} ${payment.voucher ? ' (' + payment.voucher + ')' : ''}`}
                            />
                            <Line
                                label={'Other Payment'}
                                value={
                                    <React.Fragment>
                                        {ViewHelper.formatPrice(
                                            payment.paidByOtherMethod,
                                            payment.paidByOtherMethodUsd,
                                            payment.currency
                                        )}
                                        <span>
                                            <EditCurrencyValueModal
                                                amountInCents={payment.paidByOtherMethod}
                                                onConfirm={onOtherPaymentUpdate}
                                                label={'Other payment'}
                                            />
                                        </span>
                                    </React.Fragment>
                                }
                            />
                            <Line
                                label={'Stripe payment'}
                                value={ViewHelper.formatPrice(
                                    payment.paidByStripe,
                                    payment.paidByStripeUsd,
                                    payment.currency
                                )}
                            />
                            <Line label={'Paid at'} value={ViewHelper.formatDateTimeFromISO(payment.paidAt)} />
                            <Line label={'Refunded at'} value={ViewHelper.formatDateFromISO(payment.refundedAt)} />
                            <Line
                                label={'Invoice'}
                                value={
                                    <React.Fragment>
                                        {payment.invoiceUrl ? (
                                            <a href={payment.invoiceUrl} target="_blank" rel="noopener noreferrer">
                                                Display Invoice
                                            </a>
                                        ) : (
                                            'No invoice attached'
                                        )}
                                        <span>
                                            <EditValueModal
                                                title={'Link New Invoice'}
                                                origValue={payment.invoiceUrl}
                                                onConfirm={(value) => updateInvoice(value)}
                                            />
                                        </span>
                                    </React.Fragment>
                                }
                            />
                        </FormattedDetailsTable>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <FormattedDetailsTable label="Order Details">
                            <Line
                                label={'Order type'}
                                value={`${order.productCode} (${ViewHelper.formatSupplierAndProductOption(
                                    order.supplierCode,
                                    order.productOption
                                )})`}
                            />
                            <Line label={'Requested On'} value={ViewHelper.formatDateFromISO(order.requestedDate)} />
                            <Line label={'AOI Area'} value={ViewHelper.formatAreaInKm(order.areaInKm)} />
                            <Line
                                label={'Covered area'}
                                value={ViewHelper.formatAreaInKm(order.archiveSelectedAreaInKm)}
                            />
                            <Line label={'AOI countries'} value={order.aoiCountries?.join(', ')} />
                            <Line
                                label={'Delivery Format'}
                                value={
                                    <React.Fragment>
                                        {order.deliveryFormat}{' '}
                                        {order.deliveryFormatNote ? ' - ' + order.deliveryFormatNote : ''}
                                        <span>
                                            <EditDeliveryFormatModal
                                                format={order.deliveryFormat}
                                                note={order.deliveryFormatNote}
                                                onUpdate={updateDeliveryFormat}
                                            />
                                        </span>
                                    </React.Fragment>
                                }
                            />
                        </FormattedDetailsTable>
                    </Card>
                    <Card>
                        <FormattedDetailsTable label="Progress">
                            <Line label={'Order Created'} value={<OrderClock order={order} />} />
                            {order.dueBy && (
                                <Line
                                    label={`${ViewHelper.formatDelayLabel(order.status)}`}
                                    value={<Clock start={order.dueBy} />}
                                />
                            )}
                        </FormattedDetailsTable>
                        <Label>Order Progress Details</Label>
                        <OrderTimeline order={order} invalidate={props.invalidate} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <OrderMap
                            supplier={order.supplierCode}
                            product={order.productCode}
                            geometryWKT={order.geometryWKT}
                            orderId={order.id}
                            orderNumber={order.orderNumber}
                            selectedArchives={order.archives?.length > 0 && order.archives}
                            files={order.files}
                            base64Kml={order.base64Kml}
                            onSceneSelectionChange={onScenesUpdate}
                            downloadButtons
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <OrderNotes order={order} invalidate={props.invalidate} />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Card>
                        <OrderFiles order={order} invalidate={props.invalidate} isAdmin />
                    </Card>
                </Col>
            </Row>

            {order.files?.length > 0 && order.metadata && (
                <Row>
                    <Col>
                        <Card>
                            <OrderMetadataForm
                                orderId={order.id}
                                status={order.status}
                                metadata={order.metadata}
                                invalidate={props.invalidate}
                                isAdmin
                            />
                        </Card>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Card>
                        <OrderControls order={order} invalidate={props.invalidate} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <OrderLogs orderId={order.id} logs={order.logs} isAdmin title="Logs" />
                    </Card>
                </Col>
            </Row>
            <Button color="link" onClick={handleViewAsProvider} size={'sm'}>
                View As Provider
            </Button>
        </React.Fragment>
    );
};

export default Order;

const SpanPaymentDue = styled.span`
    font-weight: 800;
    color: #dc3545;
`;

const Card = styled(StyledCard)`
    div:first-child {
        margin: 0px !important;
    }
`;
