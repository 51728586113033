import React, { useEffect, useState } from 'react';
import { scaleSqrt } from 'd3-scale';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useDispatch, useSelector } from 'react-redux';
import { selectCloudflareMonthlyReport } from '../../../store/ReportsStore/selectors';
import { actionRefreshCloudflareMonthlyReport } from '../../../store/ReportsStore/actions';
import ViewHelper from '../../view-helper';
import ReactTooltip from 'react-tooltip';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const geoUrl = require('./world-50m-simplified.json');

const MIN_RGB = '#555234';
const MAX_RGB = '#eed926';

const MapChart = () => {
    const dispatch = useDispatch();
    const report = useSelector(selectCloudflareMonthlyReport);

    const [minCountryBytes, setMinCountryBytes] = useState(0);
    const [maxCountryBytes, setMaxCountryBytes] = useState(0);
    const [tooltipContent, setTooltipContent] = useState('');

    const colorScale = scaleSqrt().domain([minCountryBytes, maxCountryBytes]).range([MIN_RGB, MAX_RGB]);

    useEffect(() => {
        dispatch(actionRefreshCloudflareMonthlyReport());
    }, [dispatch]);

    useEffect(() => {
        if (report && report[0]) {
            const min = report[0].countries.reduce((prev, next) => {
                return prev.bytes < next.bytes ? prev : next;
            }).bytes;
            const max = report[0].countries.reduce((prev, next) => {
                return prev.bytes < next.bytes ? next : prev;
            }).bytes;
            setMinCountryBytes(min);
            setMaxCountryBytes(max);
        }
    }, [report]);

    return (
        <div data-tip="">
            <ComposableMap
                projection="geoMercator"
                projectionConfig={{
                    center: [0, 50],
                    rotate: [-10, 0, 0],
                    scale: 80,
                }}
                height="380"
            >
                {report && report[0] && (
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const countryReportData = report[0].countries.find(
                                    (t) => t.label === geo.properties.ISO_A2
                                );

                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={
                                            countryReportData && countryReportData.bytes
                                                ? colorScale(countryReportData.bytes)
                                                : MIN_RGB
                                        }
                                        onMouseEnter={() => {
                                            if (countryReportData && countryReportData.bytes) {
                                                const country = geographies.find(
                                                    (g) => g.properties.ISO_A2 === countryReportData.label
                                                ).properties.NAME;
                                                const humanReadableBytes = ViewHelper.formatBytes(
                                                    countryReportData.bytes
                                                );
                                                setTooltipContent(`${country}: ${humanReadableBytes}`);
                                            } else {
                                                setTooltipContent('');
                                            }
                                        }}
                                        onMouseLeave={() => {
                                            setTooltipContent('');
                                        }}
                                        style={{
                                            default: {
                                                stroke: 'transparent',
                                                strokeWidth: 0.5,
                                            },
                                            hover: {
                                                stroke: '#eed926',
                                                strokeWidth: 0.5,
                                                cursor: 'pointer',
                                            },
                                        }}
                                    />
                                );
                            })
                        }
                    </Geographies>
                )}
            </ComposableMap>
            <ReactTooltip>{tooltipContent}</ReactTooltip>
        </div>
    );
};

export default MapChart;
