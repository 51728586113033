import React, { useEffect, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { AnalyticsReportDTO, AnalyticsReportProductDTO } from '../../../api/model';
import { actionRefreshAnalyticsMonthlyReport } from '../../../store/ReportsStore/actions';
import { selectAnalyticsMonthlyReports } from '../../../store/ReportsStore/selectors';
import { Card, Col, FormGroup, Input, Label, Row, Spinner, Statistic, Subtitle } from '../../style';
import ReportTable from '../report-table';
import moment from 'moment';

const TABLE_COLUMNS: ColumnDescription[] = [
    {
        dataField: 'name',
        text: 'Product',
    },
    {
        dataField: 'total',
        text: 'Total',
        sort: true,
    },
];

const TABLE_COLUMNS_ACTIONS: ColumnDescription[] = [
    {
        dataField: 'name',
        text: 'Action',
    },
    {
        dataField: 'total',
        text: 'Total',
        sort: true,
    },
];

interface TableItem {
    name: string;
    total: number;
    product?: AnalyticsReportProductDTO;
}

const Products = () => {
    const dispatch = useDispatch();
    const reports = useSelector(selectAnalyticsMonthlyReports);

    const [selected, setSelected] = useState<AnalyticsReportDTO>(undefined);
    const [month, setMonth] = useState('');
    const [list, setList] = useState<TableItem[]>([]);

    useEffect(() => {
        if (reports === undefined) {
            dispatch(actionRefreshAnalyticsMonthlyReport());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (reports) {
            setMonth(reports[0].dateLabel);
        }
    }, [reports]);

    useEffect(() => {
        if (reports && month) {
            setSelected(reports.find((r) => r.dateLabel === month));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month]);

    useEffect(() => {
        if (selected) {
            const list = selected.products.map((p) => {
                const item: TableItem = {
                    name: `${p.name} - ${p.providerName}`,
                    total: Object.keys(p.actions)
                        .map((key) => p.actions[key] as number)
                        .reduce((accumulator, value) => accumulator.concat(value), [])
                        .reduce((acc, b) => acc + b, 0),
                    product: p,
                };
                return item;
            });
            setList(list);
        } else {
            setList([]);
        }
    }, [selected]);

    return (
        <React.Fragment>
            <Card>
                {!reports ? (
                    <Spinner />
                ) : (
                    <FormGroup>
                        <Label for="month-select">Select Month</Label>
                        <Input
                            title="Select month"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            type="select"
                            name="month"
                        >
                            {reports.map((r) => (
                                <option key={r.dateLabel} value={r.dateLabel}>
                                    {r.dateLabel}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                )}
            </Card>
            <Row>
                <Col>
                    <Card title="Total number of product searches">
                        <Subtitle>{`Total Product Searches for ${moment(month).format('MMMM')} ${moment(
                            month
                        ).year()}`}</Subtitle>
                        <Statistic>
                            {!list ? <Spinner /> : list.reduce((acc, b) => acc + b.total, 0).toLocaleString()}
                        </Statistic>
                    </Card>
                </Col>
            </Row>
            {!list || !reports ? (
                <Card height="600px">
                    <Spinner />
                </Card>
            ) : (
                <Card>
                    <ReportTable
                        items={list}
                        columns={TABLE_COLUMNS}
                        keyField="name"
                        defaultSorted={[{ dataField: 'total', order: 'desc' }]}
                        csvExport
                        expandRowRenderer={(row, _rowIndex) => {
                            const item: AnalyticsReportProductDTO = row.product;
                            const items = Object.keys(item.actions).map((key) => {
                                const i: TableItem = {
                                    name: key,
                                    total: item.actions[key],
                                };
                                return i;
                            });
                            return (
                                <ReportTable
                                    items={items}
                                    columns={TABLE_COLUMNS_ACTIONS}
                                    keyField="name"
                                    defaultSorted={[{ dataField: 'total', order: 'desc' }]}
                                />
                            );
                        }}
                    />
                </Card>
            )}
        </React.Fragment>
    );
};

export default Products;
