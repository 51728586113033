import React, { useEffect, useState } from 'react';
import { ProductName, Provider } from '../../../api/model-api';
import { FormGroup, Input, Label } from '../../style';
import ViewHelper from '../../view-helper';
import styled from 'styled-components';

interface Props {
    onChange: (provider?: Provider, satellite?: ProductName) => void;
    provider?: Provider;
    product?: ProductName;
    disabled?: boolean;
}

const PRODUCTS_ALL: ProductName[] = [
    'A100',
    'A80',
    'A75',
    'A50',
    'A50EXT',
    'AN100',
    'NCN100',
    'NC80',
    'NC75',
    'NC50',
    'SEN_2_L1C',
    'LAND_8_L1C',
    'SEN_1_GRD',
    'IMAGE',
    'MAP',
    'EMBED_MAP',
    'MAP_DRAW',
    'WMS',
    'WMTS',
    'COG',
    'EXTERNAL_TILE_LAYER',
];

const PRODUCTS_SKYM50: ProductName[] = ['A50', 'A50EXT', 'NC50'];
const PRODUCTS_CGSTL: ProductName[] = ['A100', 'A75', 'A50', 'AN100', 'NCN100', 'NC75', 'NC50'];
const PRODUCTS_21AT: ProductName[] = ['A80', 'NC80'];
const PRODUCTS_SOAR: ProductName[] = [
    'IMAGE',
    'MAP',
    'ORDER',
    'EMBED_MAP',
    'MAP_DRAW',
    'MAP_CANVA',
    'WMS',
    'WMTS',
    'COG',
    'EXTERNAL_TILE_LAYER',
];
const PRODUCTS_SINERGISE: ProductName[] = ['SEN_2_L1C', 'LAND_8_L1C', 'SEN_1_GRD'];

// needs to be automated and coordinated from backend with list of available suppliers and products
const InputProvider = (props: Props) => {
    const { provider, product: satellite } = props;
    const [productOptions, setProductOptions] = useState<ProductName[]>([]);

    useEffect(() => {
        switch (provider) {
            case 'SKYM50':
                setProductOptions(PRODUCTS_SKYM50);
                break;
            case 'SINERGISE':
                setProductOptions(PRODUCTS_SINERGISE);
                break;
            case 'SOAR':
                setProductOptions(PRODUCTS_SOAR);
                break;
            case '21AT':
                setProductOptions(PRODUCTS_21AT);
                break;
            case 'CGSTL':
                setProductOptions(PRODUCTS_CGSTL);
                break;
            default:
                setProductOptions(PRODUCTS_ALL);
        }
    }, [provider]);

    const onProviderChange = (value: string) => {
        props.onChange(value as Provider, undefined);
    };

    const onProductChange = (value: string) => {
        props.onChange(provider, value as ProductName);
    };

    return (
        <InputProviderContainer>
            <FormGroup>
                <Label for="provider">Filter by Provider</Label>
                <Input
                    disabled={props.disabled}
                    title="Satellite Provider"
                    value={provider || ''}
                    onChange={(e) => onProviderChange(e.target.value)}
                    type="select"
                    name="provider"
                >
                    <option value={''}>Select provider</option>
                    <option value={'SKYM50'}>SKYM50</option>
                    <option value={'CGSTL'}>CGSTL</option>
                    <option value={'21AT'}>21AT</option>
                    <option value={'SINERGISE'}>Synergise</option>
                    <option value={'SOAR'}>Soar (exclusive)</option>
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="provider">Filter by Product</Label>
                <Input
                    disabled={props.disabled}
                    title="Product"
                    value={satellite || ''}
                    onChange={(e) => onProductChange(e.target.value)}
                    type="select"
                    name="provider"
                >
                    <option value={''}>Select product</option>
                    {productOptions.map((s) => (
                        <option key={s} value={s}>
                            {ViewHelper.ANALYTICS_PRODUCT_NAMES[s]}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        </InputProviderContainer>
    );
};

export default InputProvider;

const InputProviderContainer = styled.div`
    display: flex;
    flex-direction: row;

    div {
        width: 100% !important;
    }
`;
