import React, { useEffect, useRef, useState } from 'react';
import ApiReports from '../../../api/api-reports';
import { ListingCommentsDTO } from '../../../api/model';
import { Card, Container, FormGroup, Input, Label, Spinner, Title } from '../../style';
import BarChart from './comments-bar-chart';
import ListingsTable from './comments-report-table';
import Overview from './comments-overview';

const CommentsReport = () => {
    const [data, setData] = useState<ListingCommentsDTO[]>([]);
    const [filtered, setFiltered] = useState<ListingCommentsDTO[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const messageRef = useRef(null);

    const refresh = async () => {
        setLoading(true);
        try {
            const res = await ApiReports.getCommentsByListings();
            setData(res);
            if (messageRef.current) {
                messageRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
            }
        } catch (err) {
            // setError(err.toString());
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        const fil = data.filter((d) =>
            searchTerm?.length > 0 ? d.listingTitle?.toLocaleLowerCase().includes(searchTerm) : true
        );
        setFiltered(fil);
    }, [data, searchTerm]);

    if (loading) {
        return (
            <Container>
                <Title>Comments Report</Title>
                <Card height="130px">
                    <Spinner />
                </Card>
                <Card height="552px">
                    <Spinner />
                </Card>
                <Card height="104px">
                    <Spinner />
                </Card>
            </Container>
        );
    }

    return (
        <Container>
            <Title>Comments Report</Title>
            <Overview />
            <Card>
                <FormGroup>
                    <Label for="comment-filter">Search by Name</Label>
                    <Input
                        title="Search by listing"
                        placeholder="Search by listing"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value?.toLocaleLowerCase())}
                        type="text"
                        name="comment-filter"
                    />
                </FormGroup>
            </Card>
            <Card>
                <ListingsTable items={filtered} />
            </Card>
            <BarChart />
        </Container>
    );
};
export default CommentsReport;
