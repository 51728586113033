import React, { useEffect, useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { LogType } from '../../api/model-orders';
import { OrderDTO, OrderLogDTO, OrderStatus } from '../../api/model-orders';
import { Table, TableWrapper } from '../style';
import ViewHelper from '../view-helper';
import styled from 'styled-components';

import moment from 'moment';

interface OrderTimelineProps {
    order: OrderDTO;
    invalidate: () => void;
}

interface StatusChange extends OrderLogDTO {
    duration: string;
}

const END_STATUSES = [OrderStatus.DELETED, OrderStatus.DELIVERED, OrderStatus.REJECTED];

const OrderTimeline = (props: OrderTimelineProps) => {
    const { order } = props;

    const [statusChanges, setStatusChanges] = useState<StatusChange[]>([]);

    useEffect(() => {
        const now = moment();
        const filteredLogs = order.logs
            .filter((l) => l.type === LogType.STATUS)
            .sort((a, b) => b.created.localeCompare(a.created));
        const changes = filteredLogs.map((log, index) => {
            const sc: StatusChange = log as StatusChange;
            let date = now;
            let timeFormatted = '';
            if (index + 1 < filteredLogs.length) {
                const nextLog = filteredLogs[index + 1];
                //temp hack before the value is fixed on server
                date = moment(nextLog.created).add({ hours: 8 });
            }
            if (!END_STATUSES.includes(log.status)) {
                const ms = date.diff(moment(log.created).add({ hours: 8 }));
                const duration = moment.duration(ms);
                if (duration.days() > 0) {
                    timeFormatted += duration.days() + 'd ';
                }
                if (duration.hours() > 0) {
                    timeFormatted += duration.hours() + 'h ';
                }
                if (duration.days() === 0 && duration.minutes() > 0) {
                    timeFormatted += duration.minutes() + 'm ';
                }
                if (duration.minutes() === 0) {
                    timeFormatted += duration.seconds() + 's';
                }
            }
            sc.duration = timeFormatted;
            return sc;
        });
        setStatusChanges(changes);
    }, [order]);

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell) => (cell ? ViewHelper.STATUS_LABELS[cell] : ''),
        },
        {
            dataField: 'created',
            text: 'TimeStamp',
            formatter: (cell) => (cell ? ViewHelper.formatDateTimeFromISO(cell) : ''),
        },
        {
            dataField: 'duration',
            text: 'Duration',
        },
    ];

    return (
        <ToolkitProvider keyField="id" data={statusChanges} columns={columns}>
            {(props) => (
                <React.Fragment>
                    <OrderTimelineTable>
                        <Table {...props.baseProps} />
                    </OrderTimelineTable>
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
};

export default OrderTimeline;

const OrderTimelineTable = styled(TableWrapper)`
    margin: 0px;

    .table thead th {
        color: #848c94;
    }
`;
