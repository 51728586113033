import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from './history';

import appStore from './AppStore/reducer';
import reportsStore from './ReportsStore/reducer';
import reportedListingsStore from './ReportedListingsStore/reducer';
import externalMapServicesStore from './ExternalMapServicesStore/reducer';

const rootReducer = combineReducers({
    router: connectRouter(history),
    appStore: appStore,
    reportsStore: reportsStore,
    reportedListingsStore: reportedListingsStore,
    externalMapServicesStore: externalMapServicesStore,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;

export interface Action {
    type: string;
    payload?: any;
}
