import * as Sentry from '@sentry/browser';
import React from 'react';
import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UploadCredentialsDTO } from '../../api/model';
import { OssUploader } from '../../api/oss-uploader';
import { Col, Container, Progress, Row } from '../style';
import ErrorPanel from './error-panel';
import UploadDropzone from './Upload/upload-dropzone';

const FILE_SIZE_10_GB = 10737418240;

interface Props {
    uploadCredentials?: UploadCredentialsDTO;
    onFileSelected?: (file: File) => void;
    onUploadFinish: () => void;
    maxFileLimit?: number;
}

const MESSAGE_BEFORE_LEAVE = 'Leave the page? Your image is still uploading.';

const UploadSingleFile = (props: Props) => {
    const { uploadCredentials, maxFileLimit } = props;
    const [file, setFile] = useState<File>(undefined);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState<number>(undefined);
    const [error, setError] = useState<string>(undefined);

    const supportedFile = (file: File): boolean => {
        if (file.size > (maxFileLimit || FILE_SIZE_10_GB)) {
            toast.error(file.name + ': File too big. Use FTP to upload it to the system.');
            return false;
        }
        return true;
    };

    const triggerUpload = async (file: File, credentials: UploadCredentialsDTO) => {
        const ossUploader = new OssUploader(credentials);
        window.onbeforeunload = () => MESSAGE_BEFORE_LEAVE;
        setUploading(true);
        try {
            await ossUploader.multipartUpload(file, file.name, (cpt) => {
                setUploaded(cpt);
            });
            window.onbeforeunload = null;
            props.onUploadFinish();
        } catch (err) {
            window.onbeforeunload = null;
            Sentry.captureException(err);
            setError(err.toString());
        }
    };

    const handleFilesSelected = async (selected: File[]) => {
        console.log('upload-single:fileSelected: ', selected);

        if (selected.length !== 1) {
            toast.error('Exactly 1 file must be selected');
        } else {
            const f = selected[0];
            if (supportedFile(f)) {
                if (props.onFileSelected) {
                    props.onFileSelected(f);
                }
                if (uploadCredentials) {
                    triggerUpload(f, uploadCredentials);
                }
                setFile(f);
            }
        }
    };
    useEffect(() => {
        return function cleanup() {
            window.onbeforeunload = null;
        };
    }, []);

    useEffect(() => {
        if (uploadCredentials && file) {
            triggerUpload(file, uploadCredentials);
        } else {
            setFile(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadCredentials]);

    return (
        <React.Fragment>
            <Prompt when={uploading} message={MESSAGE_BEFORE_LEAVE}></Prompt>

            <Container>
                <Row>
                    <Col md={12}>
                        {file ? <span>{file.name}</span> : <UploadDropzone onSelectFiles={handleFilesSelected} />}
                        <Progress className="admin-upload-item-progress" color="success" value={uploaded || 0} />
                    </Col>
                    {error && (
                        <Col md={12}>
                            <ErrorPanel>{error}</ErrorPanel>
                        </Col>
                    )}
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default UploadSingleFile;
