import React from 'react';
import styled from 'styled-components';

interface ServerSpeedIconProps {
    speed: number;
}

type SignalStrength =
    'no connection' |
    'one bar' |
    'two bar' |
    'three bar' |
    'four bar';

const signalStrengthForSpeed = (speed?: number): SignalStrength => {
    if (!speed) return 'no connection';
    if (speed < 50) return 'four bar';
    if (speed < 200) return 'three bar';
    if (speed < 1000) return 'two bar';
    return 'one bar';
}


const ServerSpeedIcon = (props: ServerSpeedIconProps) => {

    const oneBar = () => {
        return (
            <Container>
                <Icon src="/assets/signal-strength/signal-strength-1-bar.svg" title={`${props.speed}kbps`} />
            </Container>
        );
    }

    const twoBar = () => {
        return (
            <Container>
                <Icon src="/assets/signal-strength/signal-strength-2-bar.svg" title={`${props.speed}kbps`} />
            </Container>
        );
    }

    const threeBar = () => {
        return (
            <Container>
                <Icon src="/assets/signal-strength/signal-strength-3-bar.svg" title={`${props.speed}kbps`} />
            </Container>
        );
    }

    const fourBar = () => {
        return (
            <Container>
                <Icon src="/assets/signal-strength/signal-strength-4-bar.svg" title={`${props.speed}kbps`} />
            </Container>
        );
    };

    const noSignal = () => {
        return (
            <Container>
                <Icon src="/assets/signal-strength/signal-strength-no-signal.svg" title={`${props.speed}kbps`} />
            </Container>
        );
    };

    const signalStrength = signalStrengthForSpeed(props.speed);
    switch (signalStrength) {
        case 'one bar': return oneBar();
        case 'two bar': return twoBar();
        case 'three bar': return threeBar();
        case 'four bar': return fourBar();
        case 'no connection': return noSignal();
        default: return <React.Fragment />
    }
}

export default ServerSpeedIcon;

const Container = styled.div`

`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
`;