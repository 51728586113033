import React, { useState } from 'react';
import { ColumnDescription, SortOrder } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { Button, ButtonWrapper, Card, Panel, Table, TableWrapper } from '../style';
import ViewHelper from '../view-helper';

interface Props<T extends object = any, _K = number> {
    items: any[];
    columns: Array<ColumnDescription<T>>;
    keyField: string;
    defaultSorted?: [{ dataField: any; order: SortOrder }] | undefined;
    expandRowRenderer?: (row: any, rowIndex: any) => JSX.Element;
    csvExport?: boolean;
    csvFilename?: string;
}

const ReportTable = (props: Props) => {
    const { items, columns, expandRowRenderer, keyField, csvExport, csvFilename, defaultSorted } = props;
    let expandRow = undefined;

    const [expanded, setExpanded] = useState<string[]>([]);

    const handleOnExpand = (row, isExpand, _rowIndex, _e) => {
        const key = row[keyField];
        if (isExpand) {
            setExpanded([...expanded, key]);
        } else {
            setExpanded(expanded.filter((x) => x !== key));
        }
    };

    if (expandRowRenderer) {
        expandRow = {
            renderer: expandRowRenderer,
            expanded: expanded,
            onExpand: handleOnExpand,
            showExpandColumn: true,
            expandByColumnOnly: true,
        };
    }

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <ButtonWrapper>
                <Button onClick={handleClick}>Export CSV</Button>
            </ButtonWrapper>
        );
    };

    return (
        <Card>
            <ToolkitProvider
                keyField={keyField}
                data={items}
                columns={columns}
                exportCSV={{
                    fileName: `${csvFilename || 'export'}_${ViewHelper.formatDateWithTime(
                        new Date().getTime() / 1000
                    )}.csv`,
                }}
            >
                {(props) => (
                    <>
                        <TableWrapper>
                            <Table
                                {...props.baseProps}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory()}
                                expandRow={expandRow}
                            />
                        </TableWrapper>
                        {csvExport && (
                            <Panel>
                                <MyExportCSV {...props.csvProps} />
                            </Panel>
                        )}
                    </>
                )}
            </ToolkitProvider>
        </Card>
    );
};

export default ReportTable;
