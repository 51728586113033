import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiAdmin from '../../api/api-admin';
import ApiTileEngine, { GdalMetadata } from '../../api/api-tile-engine';
import GeoUtil from '../../lib/geo-util';
import GdalInfo from '../Shared/gdal-info';
import OpacitySlider from '../Shared/opacity-slider';
import { Button, Card, Col, FormGroup, Input, Label, Row, Spinner, Subtitle } from '../style';
import TileEngineMap, { TileEngineLayer } from './tile-engine-map';

export interface TileEngineFileOverviewProps {
    filepath?: string;
    onBackClick?: () => void;
}

const LOCAL_URL = 'http://localhost';
const TEST_URL = 'https://tile-test.soar.earth';
const PROD_URL = 'https://shared-tile.soar.earth';

interface GdalResponse {
    loading?: boolean;
    data?: GdalMetadata;
    error?: string;
}

const ImageryDetails = (props: TileEngineFileOverviewProps) => {
    const [filepath, setFilepath] = useState('');
    const [token, setToken] = useState<string>(undefined);
    const [useDisk, setUseDisk] = useState('false');

    const [gdalInfo, setGdalInfo] = useState<GdalResponse>({});
    const [layers, setLayers] = useState<TileEngineLayer[]>([]);
    const [serverUrl, setServerUrl] = useState(TEST_URL);

    useEffect(() => {
        if (props.filepath) {
            setFilepath(props.filepath);
        }
    }, [props.filepath]);

    useEffect(() => {
        if (gdalInfo.data) {
            const fetchToken = async () => {
                try {
                    const res = await ApiAdmin.getJWTToken(
                        filepath,
                        GeoUtil.latLngBoundsToWKT(gdalInfo.data.boundingBox),
                        2
                    );
                    setToken(res);
                } catch (err) {
                    console.error(err);
                }
            };
            fetchToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gdalInfo]);

    useEffect(() => {
        if (token) {
            const localHost: TileEngineLayer = {
                title: LOCAL_URL,
                opacity: 1,
                serverUrl: LOCAL_URL,
                filepath: filepath,
                useCache: false,
                useDisk: useDisk,
                checked: false,
            };
            const test: TileEngineLayer = {
                title: TEST_URL,
                opacity: 1,
                serverUrl: TEST_URL,
                filepath: filepath,
                useCache: false,
                useDisk: useDisk,
                checked: false,
            };
            const prod: TileEngineLayer = {
                title: PROD_URL,
                opacity: 1,
                serverUrl: PROD_URL,
                filepath: filepath,
                useCache: false,
                useDisk: useDisk,
                checked: false,
            };

            setLayers([localHost, test, prod]);
        } else {
            setLayers([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if (useDisk === 'true') {
            setServerUrl(LOCAL_URL);
        }
    }, [useDisk]);

    const refresh = async () => {
        setGdalInfo({ loading: true });
        setLayers([]);
        try {
            const gdalRes = await ApiTileEngine.getMetadata(filepath, serverUrl, useDisk);
            setGdalInfo({
                data: gdalRes,
            });
        } catch (err) {
            setGdalInfo({ error: err.toString() });
        }
    };

    const updateLayerOpacity = (opacity: number, index: number) => {
        const copy = [...layers];
        copy[index].opacity = opacity;
        setLayers(copy);
    };

    const updateLayerChecked = (checked: boolean, index: number) => {
        const copy = [...layers];
        copy[index].checked = checked;
        setLayers(copy);
    };

    const updateLayerCache = (checked: boolean, index: number) => {
        const copy = [...layers];
        copy[index].useCache = checked;
        setLayers(copy);
    };

    return (
        <React.Fragment>
            <Card>
                <Row>
                    {props.onBackClick && (
                        <Col md={1}>
                            <RefreshButton onClick={props.onBackClick}>Back</RefreshButton>
                        </Col>
                    )}
                    <Col>
                        <FormGroup>
                            <Label for="file-path">Enter file path</Label>
                            <Input
                                disabled={props.filepath !== undefined}
                                placeholder="Custom file path"
                                value={filepath}
                                onChange={(e) => setFilepath(e.target.value)}
                                type="text"
                                name="filepath"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="file-location">enter file location</Label>
                            <Input
                                title="File location"
                                value={useDisk}
                                onChange={(e) => setUseDisk(e.target.value)}
                                type="select"
                            >
                                <option value={'true'}>Use disk</option>
                                <option value={'false'}>Use OSS</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="type">Test Cluster</Label>
                            <Input
                                disabled={useDisk === 'true'}
                                title="Listing Type"
                                value={serverUrl}
                                onChange={(e) => setServerUrl(e.target.value)}
                                type="select"
                            >
                                <option value={PROD_URL}>Prod Cluster</option>
                                <option value={TEST_URL}>Test Cluster</option>
                                <option value={LOCAL_URL}>Localhost</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={1}>
                        <RefreshButton onClick={refresh}>Fetch</RefreshButton>
                    </Col>
                </Row>
            </Card>
            <Card>
                <Row>
                    <Col md={4}>
                        {layers.map((l, i) => (
                            <div key={`layer-${i}`}>
                                <Subtitle>{l.title}</Subtitle>
                                <LayerDetails>
                                    <FormGroup>
                                        <Label for="switch">Show on map</Label>
                                        <CheckboxInput
                                            id={`layer-${i}-map`}
                                            type="switch"
                                            checked={l.checked}
                                            onChange={(_e) => updateLayerChecked(!l.checked, i)}
                                            label="Show on map"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="use-cache">Use cache</Label>
                                        <CheckboxInput
                                            disabled={l.serverUrl === LOCAL_URL}
                                            id={`layer-${i}-cache`}
                                            type="switch"
                                            checked={l.useCache}
                                            onChange={(_e) => updateLayerCache(!l.useCache, i)}
                                            label="Use cache"
                                        />
                                    </FormGroup>
                                </LayerDetails>
                                <OpacitySliderContainer>
                                    <OpacitySlider
                                        title="Opacity"
                                        opacity={l.opacity}
                                        onChange={(opacity) => updateLayerOpacity(opacity, i)}
                                    />
                                </OpacitySliderContainer>
                            </div>
                        ))}
                        {gdalInfo.loading ? (
                            <Spinner text="Fetching metadata" />
                        ) : (
                            <GdalInfo info={gdalInfo.data} error={gdalInfo.error} />
                        )}
                    </Col>
                    <Col md={8}>
                        <TileEngineMap token={token} bounds={gdalInfo?.data?.boundingBox} layers={layers} />
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    );
};
export default ImageryDetails;

const RefreshButton = styled(Button)`
    margin-top: 28px;
`;

const LayerDetails = styled.div`
    margin: 0px 2px;
`;

const CheckboxInput = styled(Input)`
    margin: 3px 10px;
`;

const OpacitySliderContainer = styled.div`
    div {
        padding: 0px;
        margin-bottom: 15px;

        div {
            width: 75%;
        }
    }
`;
