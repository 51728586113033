import React, { useEffect, useRef } from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';
import * as Wellknown from 'wellknown';
import { DmtTaskDTO } from '../../api/model';
import BaseMaps from '../Shared/Maps/basemaps';
import LeafletMap from '../MapView/leaflet-map';
import L from 'leaflet';

const { Overlay } = LayersControl;

interface Props {
    task: DmtTaskDTO;
}

const DmtTaskMap: React.FC<Props> = (props) => {
    const { task } = props;

    const mapRef = useRef(null);

    const wkt = task.geometryWKT ? task.geometryWKT : task.metadata.coverageBoundingWKT;

    useEffect(() => {
        if (wkt && mapRef && mapRef.current) {
            const leafletMap = mapRef.current;
            const geoJson = Wellknown.parse(wkt);
            const locationLayer = L.geoJSON(geoJson, { style: { fillColor: '#000000', fillOpacity: 0 } }).addTo(
                leafletMap
            );
            leafletMap.fitBounds(locationLayer.getBounds());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task, mapRef]);

    return (
        <>
            <div className="map">
                <LeafletMap mapRef={mapRef} fullscreenControl={true}>
                    <BaseMaps>
                        {task.tileUrlOrtho && (
                            <Overlay name={'Ortho'} checked={true}>
                                <TileLayer tms={true} attribution="" url={task.tileUrlOrtho} />
                            </Overlay>
                        )}

                        {task.tileUrlDem && (
                            <Overlay name={'DEM'} checked={false}>
                                <TileLayer tms={true} attribution="" url={task.tileUrlDem} />
                            </Overlay>
                        )}

                        {/* 
                        can be extended to render calculated overlays here
                        {task.metadata && task.metadata.coverageLayersWKT && <Overlay name={'Calculated overlays'} checked={false}>

                        </Overlay>} 
                        */}
                    </BaseMaps>
                </LeafletMap>
            </div>
        </>
    );
};

export default DmtTaskMap;
