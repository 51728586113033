import { format, formatISO, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import ApiReports from '../../../api/api-reports';
import { ListingType, ReportListingsDTO, Review } from '../../../api/model';
import { DatesFromTo, ReportInterval } from '../../../api/model-api';
import InputDateRange, { LAST_365_DAYS, MONTHLY_DATE_RANGES } from '../../Shared/Filters/DateRange/input-date-range';
import InputListingType from '../../Shared/Filters/input-listing-type';
import InputReportInterval from '../../Shared/Filters/input-report-interval';
import InputReview from '../../Shared/Filters/input-review';
import { Card, ChartTitle, Col, FormGroup, Input, Label, Row, Spinner } from '../../style';

const UploadsBarChart = () => {
    const [interval, setInterval] = useState<ReportInterval>(ReportInterval.MONTH);
    const [type, setType] = useState<ListingType>(ListingType.TILE_LAYER);
    const [review, setReview] = useState<Review>(Review.APPROVED);
    const [report, setReport] = useState<ReportListingsDTO>(undefined);
    const [searches, setSearches] = useState<any[]>([]);
    const [dates, setDates] = useState<DatesFromTo>(LAST_365_DAYS);
    const [cumulative, setCumulative] = useState('true');

    useEffect(() => {
        const refresh = async () => {
            try {
                const res = await ApiReports.getListingsReportByInterval(interval);
                setReport(res);
            } catch (err) {
                // setError(err.toString());
            }
        };
        refresh();
    }, [interval]);

    useEffect(() => {
        if (report) {
            const dateFrom = dates?.from ? formatISO(dates.from, { representation: 'date' }) : undefined;
            const dateTo = dates?.to ? formatISO(dates.to, { representation: 'date' }) : undefined;

            const data = [];
            const length = report.intervals.filter(
                (item) => (!dateFrom || item.label >= dateFrom) && (!dateTo || item.label <= dateTo)
            ).length;
            let step = 1;
            if (length > 450) {
                step = Math.ceil(length / 450);
            }

            let accMaps = 0;
            let accImages = 0;
            let i = 0;
            report.intervals
                .filter((item) => !dateTo || item.label <= dateTo)
                .forEach((item) => {
                    if (cumulative === 'true' || i > 0) {
                        switch (review) {
                            case Review.APPROVED:
                                accMaps = accMaps + item.mapApproved;
                                accImages = accImages + item.imageApproved;
                                break;
                            case Review.REJECTED:
                                accMaps = accMaps + item.mapRejected;
                                accImages = accImages + item.imageRejected;
                                break;
                            case Review.PENDING:
                                accMaps = accMaps + item.mapPending;
                                accImages = accImages + item.imagePending;
                                break;
                            default:
                                accMaps = accMaps + (item.mapApproved + item.mapPending + item.mapRejected);
                                accImages = accImages + (item.imageApproved + item.imagePending + item.imageRejected);
                                break;
                        }
                    }

                    if (!dateFrom || item.label >= dateFrom) {
                        const row: any[] =
                            interval === ReportInterval.DAY
                                ? [format(parseISO(item.label), 'dd MMM YYY')]
                                : [format(parseISO(item.label), 'MMM YYY')];
                        if (!type || type === ListingType.TILE_LAYER) {
                            row.push(accMaps);
                        }
                        if (!type || type === ListingType.IMAGE) {
                            row.push(accImages);
                        }

                        if (i % step === 0 || i === length - 1) {
                            data.push(row);
                            if (cumulative === 'false') {
                                accMaps = 0;
                                accImages = 0;
                            }
                        }
                        i++;
                    }
                });

            const labelRow = ['Date'];
            if (!type || type === ListingType.TILE_LAYER) labelRow.push('Maps');
            if (!type || type === ListingType.IMAGE) labelRow.push('Images');
            data.unshift(labelRow);
            setSearches(data);
        }
    }, [report, dates, cumulative, type, review, interval]);

    if (!searches || !report) {
        return (
            <React.Fragment>
                <Card height="104px">
                    <Spinner />
                </Card>
                <Card height="664px">
                    <Spinner />
                </Card>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Card>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="intervals">Filter by Interval</Label>
                            <InputReportInterval value={interval} onChange={setInterval} />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for="type">Filter by Type</Label>
                            <InputListingType value={type} onChange={setType} />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for="status">Filter by Status</Label>
                            <InputReview value={review} onChange={setReview} />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for="date-range">Filter by Date</Label>
                            <InputDateRange
                                fromTo={dates}
                                onChange={setDates}
                                interval={interval}
                                selectedDate={MONTHLY_DATE_RANGES.LAST_12_MONTHS}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for="data-type">Filter by Frequency</Label>
                            <Input
                                title="Data type"
                                value={cumulative}
                                onChange={(e) => setCumulative(e.target.value)}
                                type="select"
                                name="cumulative"
                            >
                                <option value={'true'}>Cumulative</option>
                                <option value={'false'}>Discrete</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
            <Card>
                <ChartTitle>Uploads by Intervals</ChartTitle>
                <Chart
                    style={{
                        width: '100%',
                    }}
                    width={'100%'}
                    height={'600px'}
                    chartType="ColumnChart"
                    data={searches}
                    options={{
                        legend: {
                            position: 'top',
                            textStyle: { color: 'white' },
                        },
                        backgroundColor: 'transparent',
                        colors: ['#00A2FF', '#EED926'],
                        textStyle: {
                            color: 'white',
                        },
                        chartArea: { width: '80%' },
                        hAxis: {
                            minValue: 0,
                            textStyle: {
                                color: 'white',
                            },
                        },
                        vAxis: {
                            textStyle: {
                                color: 'white',
                            },
                        },
                        explorer: {
                            axis: 'horizontal',
                            keepInBounds: true,
                        },
                    }}
                />
            </Card>
        </React.Fragment>
    );
};

export default UploadsBarChart;
