import React, { useState } from 'react';
import IconButton from '../Shared/icon-button';
import SoarModal from '../Shared/soar-modal';
import {
    Button,
    Col,
    ConfirmButtonContainer,
    ConfirmModalFooter,
    FormGroup,
    Input,
    InvisibleCard,
    Label,
    SubtitleHint,
} from '../style';

interface Props {
    label: string;
    description?: string;
    amountInCents: number;
    currency?: string;
    onConfirm: (amountInCents: number) => void;
}

const EditCurrencyValueModal: React.FC<Props> = (props: Props) => {
    const { label, description, amountInCents } = props;

    const [modal, setModal] = useState(false);
    const [value, setValue] = useState(amountInCents ? amountInCents / 100 : 0);

    const toggle = () => setModal(!modal);

    const onConfirm = () => {
        props.onConfirm((value || 0) * 100);
        toggle();
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('edit-currenc: ', e);
        const v = e.target.valueAsNumber;
        if (v < 0) {
            setValue(0);
        } else {
            setValue(v);
        }
    };

    return (
        <>
            <IconButton title={label} onClick={toggle} faIcon="fa-edit" />
            <SoarModal isOpen={modal} title={label} onClosed={() => setModal(false)} width="600px">
                <InvisibleCard>
                    <FormGroup>
                        <Label for="value" xs={12}>
                            Enter new value
                        </Label>
                        <Col xs={12}>
                            <Input type="number" name="value" id="valueId" value={value} onChange={onChange} />
                            {description && <SubtitleHint>{description}</SubtitleHint>}
                        </Col>
                    </FormGroup>
                </InvisibleCard>
                <ConfirmModalFooter margin="0px 15px 0px 0px">
                    <ConfirmButtonContainer>
                        <Button onClick={onConfirm}>Confirm</Button>
                        <Button color="warning" onClick={toggle}>
                            Cancel
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>
        </>
    );
};

export default EditCurrencyValueModal;
