import React, { useEffect, useState } from 'react';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { AnalyticsItemDTO } from '../../../api/model';
import { DatesFromTo } from '../../../api/model-api';
import InputDateRange from '../../Shared/Filters/DateRange/input-date-range';
import { Button, ButtonWrapper, Col, FormGroup, Input, Label, Panel, Row, Table, TableWrapper } from '../../style';
import ViewHelper from '../../view-helper';

interface Props {
    items: AnalyticsItemDTO[];
}

const ManageUserAnalyticsTable = (props: Props) => {
    const [filteredItems, setFilteredItems] = useState<AnalyticsItemDTO[]>(props.items || []);
    const [rawData, setRawData] = useState<any[]>([]);
    const [dates, setDates] = useState<DatesFromTo>(undefined);

    const [productOption, setProductOption] = useState<any>(undefined);
    const [actionOption, setActionOption] = useState<any>(undefined);

    useEffect(() => {
        if (props.items) {
            setRawData(props.items);
        }
    }, [props.items]);

    useEffect(() => {
        let filtered: AnalyticsItemDTO[] = [];
        if (rawData) {
            const from = dates?.from ? dates.from.getTime() / 1000 : undefined;
            const to = dates?.to ? dates.to.getTime() / 1000 : undefined;

            filtered = rawData
                .filter((t) => (from ? t.createdAt >= from : true))
                .filter((t) => (to ? t.createdAt < to : true))
                .filter((t) =>
                    productOption ? t.product.toLocaleUpperCase() === productOption.toLocaleUpperCase() : true
                )
                .filter((t) => (actionOption ? t.action === actionOption.toLocaleUpperCase() : true));
        }
        setFilteredItems(filtered);
    }, [rawData, actionOption, productOption, dates]);

    const columns = [
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell: number) => ViewHelper.formatDate(cell),
            csvFormatter: (cell) => ViewHelper.formatDateWithTime(cell),
        },
        {
            dataField: 'provider',
            text: 'Provider',
            hidden: true,
        },
        {
            dataField: 'product',
            text: 'Product',
            hidden: true,
        },
        {
            dataField: 'satellite',
            text: 'Satellite',
            sort: true,
        },
        {
            dataField: 'action',
            text: 'action',
            sort: true,
        },
        {
            dataField: 'geometryWKT',
            text: 'Geometry',
            hidden: true,
        },
        {
            dataField: 'layers',
            text: 'Layers',
            hidden: true,
        },
        {
            dataField: 'url',
            text: 'Url',
            hidden: true,
        },
        {
            dataField: 'crs',
            text: 'CRS',
            hidden: true,
        },
        {
            dataField: 'referer',
            text: 'Referrer',
            hidden: true,
        },
        {
            dataField: 'id',
            text: 'ID',
            hidden: true,
        },
        {
            dataField: 'layers',
            text: 'Layers',
            // hidden: true,
        },
        {
            dataField: 'subdomain',
            text: 'Subdomain',
            hidden: true,
        },
    ];

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <ButtonWrapper>
                <Button color="primary" onClick={handleClick}>
                    Export CSV
                </Button>
            </ButtonWrapper>
        );
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="date-range">Filter by Date</Label>
                        <InputDateRange fromTo={dates} onChange={setDates} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="product-type">Filter by Type</Label>
                        <Input
                            value={productOption}
                            onChange={(e) => setProductOption(e.target.value as any)}
                            type="select"
                            name="product-type"
                        >
                            <option value={''}>Filter by Type (all)</option>
                            {Object.keys(ViewHelper.ANALYTICS_PRODUCT_NAMES).map((key) => (
                                <option value={key}>{ViewHelper.ANALYTICS_PRODUCT_NAMES[key]}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="action-type">Filter by Action</Label>
                        <Input
                            value={actionOption}
                            onChange={(e) => setActionOption(e.target.value as any)}
                            type="select"
                            name="action-type"
                        >
                            <option value={''}>Filter by Action (all)</option>
                            {Object.keys(ViewHelper.ANALYTICS_ACTION_LABELS).map((key) => (
                                <option value={key}>{ViewHelper.ANALYTICS_ACTION_LABELS[key]}</option>
                            ))}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <ToolkitProvider
                keyField="id"
                data={filteredItems}
                columns={columns}
                exportCSV={{
                    fileName: `soar_users_analytics_${ViewHelper.formatDateWithTime(new Date().getTime() / 1000)}.csv`,
                    onlyExportFiltered: true,
                    exportAll: false,
                }}
            >
                {(props) => (
                    <>
                        <TableWrapper>
                            <Table {...props.baseProps} pagination={paginationFactory()} filter={filterFactory()} />
                        </TableWrapper>
                        <Panel>
                            <MyExportCSV {...props.csvProps} />
                        </Panel>
                    </>
                )}
            </ToolkitProvider>
        </React.Fragment>
    );
};

export default ManageUserAnalyticsTable;
