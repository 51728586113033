import React, { useEffect, useState } from 'react';
import ApiOrders from '../../api/api-orders';
import {
    ArchiveDTO,
    CustomerDTO,
    OrderCalculatedPriceDTO,
    OrderForAdminDTO,
    ProductDTO,
    SupplierDTO,
} from '../../api/model-orders';
import EditCurrencyValueModal from '../Orders/edit-currency-value-modal';
import ViewHelper from '../view-helper';
import SelectAOI from './select-aoi';
import { Button, ButtonGroup, Col, Container, Row } from '../style';
import ErrorPanel from '../Shared/error-panel';
import FormattedDetailsTable, { Line } from '../Shared/formatted-details-table';
import styled from 'styled-components';

interface Props {
    supplier: SupplierDTO;
    product: ProductDTO;
    customer: CustomerDTO;
    geometryWKT?: string;
    archives?: ArchiveDTO[];
    onSubmit: (orders: OrderForAdminDTO[]) => void;
    onBack: () => void;
}

const NewOrderPreview = (props: Props) => {
    const { supplier, product, customer, archives, geometryWKT } = props;

    const [pricePerKm, setPricePerKm] = useState<number>(undefined);
    const [additionalTax, setAdditionalTax] = useState<number>(0);
    const [discount, setDiscount] = useState<number>(0);
    const [error, setError] = useState<string>(undefined);
    const [calculatedPrice, setCalculatedPrice] = useState<OrderCalculatedPriceDTO>(undefined);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (geometryWKT && supplier && product) {
            console.log('create-order:Update cost');
            const updateCostPreview = async () => {
                setSubmitting(true);
                try {
                    const res = await ApiOrders.getCalculatedPrice(
                        supplier.code,
                        product.code,
                        geometryWKT,
                        customer.countryCode,
                        archives.map((a) => a.id),
                        pricePerKm,
                        additionalTax,
                        discount
                    );
                    setCalculatedPrice(res);
                    setError(undefined);
                } catch (err) {
                    setError(err.message);
                    setCalculatedPrice(undefined);
                } finally {
                    setSubmitting(false);
                }
            };
            updateCostPreview();
        } else {
            setCalculatedPrice(undefined);
        }
    }, [customer, geometryWKT, archives, additionalTax, discount, supplier, product, pricePerKm]);

    const submitNewOrder = async () => {
        try {
            setSubmitting(true);
            const orders = await ApiOrders.createOrder(
                supplier.code,
                product.code,
                customer.deliveryFormatNote,
                geometryWKT,
                customer.email,
                customer.subdomain,
                customer.company,
                customer.industry,
                customer.countryCode,
                archives.map((a) => a.id),
                discount,
                pricePerKm,
                additionalTax
            );
            props.onSubmit(orders);
        } catch (err) {
            setSubmitting(false);
            setError(err.message);
        }
    };

    return (
        <Container>
            <React.Fragment>
                <FormattedDetailsTable label="User Information">
                    <Line label={'User Email'} value={customer.email} />
                    <Line label={'Domain'} value={customer.subdomain} />
                    <Line label={'Company'} value={customer.company} />
                    <Line label={'Country'} value={customer.countryCode} />
                    <Line label={'Industry'} value={customer.industry} />
                    <Line label={'Delivery note'} value={customer.deliveryFormatNote} />
                </FormattedDetailsTable>

                <FormattedDetailsTable label="Product Details">
                    <Line label={'Product'} value={product.name} />
                    <Line label={'Product code '} value={product.code} />
                    {archives.length > 0 && (
                        <React.Fragment>
                            {archives.map((archive, index) => (
                                <Line key={archive.id} label={`Scene ${index}`} value={archive.id} />
                            ))}
                        </React.Fragment>
                    )}
                    <Line label={'AOI Area'} value={ViewHelper.formatAreaInKm(calculatedPrice?.areaInKm)} />
                    <Line
                        label={'Covered area'}
                        value={ViewHelper.formatAreaInKm(calculatedPrice?.effectiveAreaInKm)}
                    />
                </FormattedDetailsTable>

                <FormattedDetailsTable label="Pricing">
                    <Line
                        label={'Price per km²'}
                        value={
                            <React.Fragment>
                                {ViewHelper.formatPriceFromCents(
                                    calculatedPrice?.pricePerKm || 0,
                                    calculatedPrice?.currency
                                )}
                                <span>
                                    <EditCurrencyValueModal
                                        label={'Price per km²'}
                                        amountInCents={calculatedPrice?.pricePerKm}
                                        onConfirm={setPricePerKm}
                                    />
                                </span>
                            </React.Fragment>
                        }
                    />
                    <Line
                        label={'Additional tax'}
                        value={
                            <React.Fragment>
                                {ViewHelper.formatPriceFromCents(additionalTax, calculatedPrice?.currency)}
                                <span>
                                    <EditCurrencyValueModal
                                        label={'Additional Tax'}
                                        amountInCents={additionalTax}
                                        onConfirm={setAdditionalTax}
                                    />
                                </span>
                            </React.Fragment>
                        }
                    />
                    <Line
                        label={'Discount'}
                        value={
                            <React.Fragment>
                                {ViewHelper.formatPriceFromCents(discount, calculatedPrice?.currency)}
                                <span>
                                    <EditCurrencyValueModal
                                        label={'Discount'}
                                        amountInCents={discount}
                                        onConfirm={setDiscount}
                                    />
                                </span>
                            </React.Fragment>
                        }
                    />
                    {calculatedPrice?.totalTax > 0 && (
                        <Line
                            label={'Calculated GST'}
                            value={ViewHelper.formatPriceFromCents(
                                calculatedPrice?.totalTax,
                                calculatedPrice?.currency
                            )}
                        />
                    )}
                    <Line
                        label={`Total Price ${calculatedPrice?.totalTax ? ' (incl. GST)' : ''}`}
                        value={
                            <>
                                {submitting ? (
                                    <i className="fa fa-spinner fa-spin" />
                                ) : (
                                    ViewHelper.formatPriceFromCents(
                                        calculatedPrice?.totalPrice,
                                        calculatedPrice?.currency
                                    )
                                )}
                            </>
                        }
                    />
                </FormattedDetailsTable>
            </React.Fragment>

            <Row>
                <Col>{error && <ErrorPanel>{error}</ErrorPanel>}</Col>
            </Row>

            <Row>
                <Col>
                    <SelectAOI product={product} selectedArchives={archives} geometryWKT={geometryWKT} />
                </Col>
            </Row>

            <Row>
                <NewOrderButtonGroup>
                    <NewOrderButton onClick={props.onBack}>
                        <span>
                            <i className="fa fa-chevron-left" />
                        </span>
                        <span>Back</span>
                    </NewOrderButton>
                    <NewOrderButton onClick={submitNewOrder} disabled={submitting || error} position="right">
                        {submitting ? (
                            <span>
                                <i className="fa fa-spinner fa-spin" />
                            </span>
                        ) : (
                            <span>Submit Order</span>
                        )}
                    </NewOrderButton>
                </NewOrderButtonGroup>
            </Row>
        </Container>
    );
};

export default NewOrderPreview;

const NewOrderButtonGroup = styled(ButtonGroup)`
    width: auto;
    margin: auto;
`;

const NewOrderButton = styled(Button)`
    margin: 35px auto;
    padding: 5px 25px;

    & span:not(:first-child) {
        margin-left: 7px;
        color: rgba(0, 0, 0, 0.87);
    }
`;
