import React from 'react';
import styled from 'styled-components';

interface Props {
    title?: string;
    handleOnClick?: () => void;
    children: any;
}

const UserManagementCard = (props: Props) => {
    if (!props.children) {
        return <React.Fragment />;
    }

    return (
        <ManagementCard onClick={props.handleOnClick ? props.handleOnClick : ''}>
            {props.title && <ManagementTitle>{props.title}</ManagementTitle>}
            {props.children}
        </ManagementCard>
    );
};

export default UserManagementCard;

interface ManagementCardProps {
    onClick?: boolean;
}

const ManagementCard = styled.div<ManagementCardProps>`
    border: 1px solid rgb(255 255 255 / 10%);
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
    background-color: rgb(0 0 0 / 20%);
    //TODO this is shit
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

const ManagementTitle = styled.p`
    font-size: 18px;
    color: white;
    margin: 5px 5px -10px 5px;
`;
