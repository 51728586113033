import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiTms, { TMSLayer } from '../../../../api/api-tms';
import { Button } from '../../../style';
import TMSLayerVisibilityNavigationButtons from './tms-layer-visibility-navigation-buttons';
import config from '../../../../config';

interface TMSLayerVisibilityProps {
    tmsLayer: TMSLayer;
    layers: TMSLayer[];
    onTMSLayerVisibilityUpdated: () => void;
}

const TMSLayerVisibility = (props: TMSLayerVisibilityProps) => {
    const [loading, setLoading] = useState(false);
    const [nextLayer, setNextLayer] = useState<TMSLayer | undefined>();

    useEffect(() => {
        const currentLayerIndex = props.layers.findIndex((t) => t.id === props.tmsLayer.id);
        if (props.layers.length > currentLayerIndex) {
            setNextLayer(props.layers[currentLayerIndex + 1]);
        }
    }, [props.tmsLayer, props.layers]);

    const handelLayerAvailability = (availability: boolean) => {
        if (!props.tmsLayer.previewUrl && availability) {
            alert('You must have a preview before making the layer publicly available');
            return;
        }

        setLoading(true);

        ApiTms.updateTMSLayerVisibility(props.tmsLayer.serverId, props.tmsLayer.id, availability)
            .then((_) => {
                props.onTMSLayerVisibilityUpdated();
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOpenInSoar = () => {
        window.open(`${config.CLIENT_URL}/maps/${props.tmsLayer.listingId}`);
    };

    if (props.tmsLayer.public) {
        return (
            <Container>
                <AvailableText>This layer is currently available on Soar</AvailableText>
                <Button disabled={loading} onClick={() => handelLayerAvailability(false)}>
                    Turn it off
                </Button>
                <Button color="link" onClick={() => handleOpenInSoar()}>
                    Open in Soar
                </Button>
                <TMSLayerVisibilityNavigationButtons tmsLayer={props.tmsLayer} nextLayer={nextLayer} />
            </Container>
        );
    } else {
        return (
            <Container>
                <NotAvailableText>This layer is not available on Soar</NotAvailableText>
                <Button disabled={loading} onClick={() => handelLayerAvailability(true)}>
                    Turn it on
                </Button>
                <TMSLayerVisibilityNavigationButtons tmsLayer={props.tmsLayer} nextLayer={nextLayer} />
            </Container>
        );
    }
};

export default TMSLayerVisibility;

const Container = styled.div`
    padding-top: 8px;
    display: inline-block;
`;

const Text = styled.span`
    font-weight: bolder;
    margin-right: 20px;
    font-size: 1.2rem;
    vertical-align: sub;
`;

const AvailableText = styled(Text)`
    color: #58a758;
`;

const NotAvailableText = styled(Text)`
    color: #fa4646;
`;
