import Api from './api';
import { UploadCredentialsDTO } from './model';

export interface STACCatalogDTO {
    id: number;
    rootId: number;
    parentId: number;
    listingId: number;
    userId: number;
    title: string;
    description: string;
    totalItems: number;
    totalChildren: number;
    public: boolean;
    mosaic: boolean;
}

export interface STACCatalogFullDTO {
    id: number;
    userId?: string;
    categories: string[];
    adminNotes: string;
    title: string;
    titleOvr: string;
    description: string;
    descriptionOvr: string;
    listingTitleStrategy: string;
    listingDescriptionStrategy: string;
    totalItems: number;
    totalChildren: number;
    stacUrl: string;
    rootId: number;
    parentId: number;
    listingId: number;
    geometryWKT: string;
    geometryGeoJson: string;
    boundingBoxWKT: string;
    boundingBoxWKTOvr: string;
    license: string;
    licenseOvr: string;
    keywords: string[];
    keywordsOvr: string[];
    minZoom: number;
    minZoomOvr: number;
    maxZoom: number;
    maxZoomOvr: number;
    mosaicLayerUrl: string;
    url?: string;
    previewUrl: string;
    legendUrl: string;
    public: boolean;
    mosaic: boolean;
}

export interface STACItemDTO {
    id: number;
    catalogId: number;
    listingId: number;
    title: string;
    description: string;
    public: boolean;
    map: boolean;
}

export interface STACItemFullDTO {
    id: number;
    catalogId: number;
    listingId: number;
    title: string;
    description: string;
    keywords: string[];
    minZoom: number;
    minZoomOvr: number;
    maxZoom: number;
    maxZoomOvr: number;
    tileUrl: string;
    url: string;
    previewUrl: string;
    categories: string[];
    legendUrl: string;
    adminNotes: string;
    public: boolean;
    map: boolean;
    stacUrl: string;
    boundingBoxWKT: string;
    license: string;
}

export interface BodySTACCatalogUpdate {
    adminNotes: string;
    titleOvr: string;
    descriptionOvr: string;
    categories: string[];
    keywordsOvr: string[];
    licenseOvr: string;
}

export interface BodySTACItemUpdate {
    title: string;
    description: string;
    keywords: string[];
    categories: string[];
    license: string;
    adminNotes: string;
}

export default class ApiSTAC extends Api {
    public static getSTACRootCatalogs(): Promise<STACCatalogFullDTO[]> {
        return this.axios.get(`/v1/admin/stac/roots`).then((res) => res.data);
    }

    public static getSTACCatalog(id: number): Promise<STACCatalogFullDTO | undefined> {
        return this.axios.get(`/v1/admin/stac/${id}`).then((res) => res.data);
    }

    // Unused for now
    // public static createSTAC(catalogUrl: string, data: any): Promise<STACCatalogFullDTO> {
    //     const body = {
    //         ...data,
    //     };
    //     return this.axios.post(`/v1/admin/stac/roots`, { url: catalogUrl }, body).then((res) => res.data);
    // }

    public static updateSTACCatalog(catalogId: number, data: BodySTACCatalogUpdate): Promise<STACCatalogFullDTO> {
        return this.axios.put(`/v1/admin/stac/${catalogId}`, data).then((res) => res.data);
    }

    public static deleteSTAC(catalogId: number): Promise<boolean> {
        return this.axios.delete(`/v1/admin/stac/${catalogId}`).then((res) => res.data);
    }

    public static getSTACCatalogPreviewUploadCredentials(catalogId: number): Promise<UploadCredentialsDTO> {
        return this.axios.get(`/v1/admin/stac/${catalogId}/preview`).then((res) => res.data);
    }

    public static updateSTACCatalogVisibility(catalogId: number, visible: boolean): Promise<STACItemFullDTO> {
        return this.axios.put(`/v1/admin/stac/${catalogId}/visibility`, { public: visible }).then((res) => res.data);
    }

    public static getSTACCatalogChildren(catalogId: number): Promise<STACCatalogDTO[]> {
        return this.axios.get(`/v1/admin/stac/${catalogId}/children`).then((res) => res.data);
    }

    public static getSTACCatalogItems(catalogId: number): Promise<STACItemDTO[]> {
        return this.axios.get(`/v1/admin/stac/${catalogId}/items`).then((res) => res.data);
    }

    public static getSTACCatalogItem(catalogId: number, itemId: number): Promise<STACItemFullDTO> {
        return this.axios.get(`/v1/admin/stac/${catalogId}/items/${itemId}`).then((res) => res.data);
    }

    public static updateSTACCatalogItem(catalogId: number, itemId: number, data: any): Promise<STACItemFullDTO> {
        return this.axios.put(`/v1/admin/stac/${catalogId}/items/${itemId}`, data).then((res) => res.data);
    }

    public static updateSTACCatalogItemVisibility(
        catalogId: number,
        itemId: number,
        visible: boolean
    ): Promise<STACItemFullDTO> {
        return this.axios
            .put(`/v1/admin/stac/${catalogId}/items/${itemId}/visibility`, { public: visible })
            .then((res) => res.data);
    }
}
