import { push } from 'connected-react-router';
import React from 'react';
import store from '../../store/store';
import { Card, Title, Button } from '../style';
import styled from 'styled-components';

const Suppliers = () => {
    return (
        <Card>
            <Title>Suppliers</Title>
            <SupplierButton onClick={() => store.dispatch(push('/suppliers/new-order'))}>New Order</SupplierButton>
        </Card>
    );
};

export default Suppliers;

export const SupplierButton = styled(Button)`
    margin: 35px auto;
    padding: 5px 25px;
`;
