import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiWms, { WMSServer as WMSServerDTO } from '../../../../api/api-wms';
import store from '../../../../store/store';
import { Button, ButtonGroup, Card, Col, Container, ErrorMessage, Row, Spinner, Subtitle, Title } from '../../../style';
import MapServicesDetailsTable from '../../MapServicesSharedUI/map-services-details-table';
import WMSServerEditDetails from './wms-server-edit-details';
import WMSServerLayers from './wms-server-layers';

const WMSServer = (props) => {
    const id: number = props.match.params.id;
    const [wms, setWms] = useState<WMSServerDTO | undefined>(undefined);
    const [wmsError, setWmsError] = useState<Error | undefined>(undefined);
    const [isDeletingServer, setIsDeletingServer] = useState<boolean>(false);

    const getWmsServer = (id: number) => {
        ApiWms.getWMSServer(id)
            .then((res) => {
                setWms(res);
            })
            .catch((err) => {
                setWmsError(err);
            });
    };

    const handleClickDeleteServer = () => {
        const confirmDelete = confirm('Are you really sure you want to do this?');
        if (confirmDelete) {
            setIsDeletingServer(true);
            ApiWms.deleteWMSServer(id)
                .then((_) => {
                    alert('Server was deleted');
                    store.dispatch(push(`/wms`));
                })
                .catch((err) => {
                    alert('An error occurred while deleting this server: ' + err.message);
                })
                .finally(() => {
                    setIsDeletingServer(false);
                });
        }
    };

    const updateWMSServer = (wmsTitle: string, license: string, adminNotes: string) => {
        setWms(undefined);
        ApiWms.updateWMSServerDetails(id, wmsTitle, license, adminNotes)
            .then((wmsServer) => setWms(wmsServer))
            .catch((err) => setWmsError(err));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getWmsServer(id);
    }, [id]);

    if (!wms && !wmsError) {
        return (
            <Container>
                <Spinner />
            </Container>
        );
    }

    return (
        <React.Fragment>
            <Container>
                <Title>Manage {wms?.standard} Server</Title>

                {wmsError ? (
                    <React.Fragment>
                        <Row>
                            <Col>
                                <Card>
                                    {isDeletingServer ? (
                                        <Spinner />
                                    ) : (
                                        <React.Fragment>
                                            <ErrorMessage>{wmsError.message}</ErrorMessage>
                                            <br />
                                            <ButtonPanel>
                                                <Button onClick={() => store.dispatch(push(`/wms`))}>
                                                    BACK TO SAFETY
                                                </Button>
                                                <Button color="danger" onClick={handleClickDeleteServer}>
                                                    DELETE SERVER AND ALL LAYERS
                                                </Button>
                                            </ButtonPanel>
                                        </React.Fragment>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Row>
                            <Col md={8}>
                                <Card>
                                    <WMSServerEditDetails
                                        wms={wms}
                                        onSaveWMSDetails={(wmsTitle: string, license: string, adminNotes: string) => {
                                            updateWMSServer(wmsTitle, license, adminNotes);
                                        }}
                                    />
                                </Card>
                            </Col>
                        </Row>

                        {wms.layers === 0 && wms.error ? (
                            <Row>
                                <Card>
                                    <Subtitle>WMS Layers</Subtitle>
                                    <ErrorMessage>{wms.error}</ErrorMessage>
                                </Card>
                            </Row>
                        ) : (
                            <WMSServerLayers wms={wms} />
                        )}

                        <Row>
                            <Card>
                                <MapServicesDetailsTable title={'Full server details'} data={wms} />
                            </Card>
                        </Row>
                    </React.Fragment>
                )}
            </Container>
        </React.Fragment>
    );
};

export default WMSServer;

const ButtonPanel = styled(ButtonGroup)`
    max-width: 500px;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0pc auto;
`;
