import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Technobabble: React.FC = () => {
    const [text, setText] = useState('');
    const [index, setIndex] = useState(0);

    const babble = [
        { text: 'Pre-caching values', duration: 800 },
        { text: 'Loading Sentinel receiver', duration: 1200 },
        { text: 'Establishing modular super-map', duration: 600 },
        { text: 'Filtering results', duration: 1900 },
        { text: 'Generating heatmap shaders', duration: 740 },
        { text: 'Optimizing dataset', duration: 300 },
        { text: 'Post processing', duration: 400 },
        { text: 'Motivating developers', duration: 150 },
        { text: 'Building quadtrees', duration: 820 },
        { text: 'Flushing data pipelines', duration: 300 },
        { text: 'Reticulating splines', duration: 200 },
        { text: 'Rendering overlays', duration: 440 },
    ];

    useEffect(() => {
        setText(babble[0].text);
        setTimeout(() => {
            setIndex(1);
        }, babble[0].duration);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (index < babble.length) {
            setText(babble[index].text);
            setTimeout(() => {
                setIndex(index + 1);
            }, babble[index].duration);
        } else {
            setIndex(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    return (
        <React.Fragment>
            <SynthGridLoadingBabble>{text}...</SynthGridLoadingBabble>
        </React.Fragment>
    );
};

export default Technobabble;

const SynthGridLoadingBabble = styled.span`
    position: relative;
    z-index: 4000;
    top: -155vh;
    margin: 0 auto;
    display: block;
    color: #00b2e2;
    font-size: 2.2rem;
    text-shadow: 1px 1px 10px #00b2e2, 1px 1px 10px #00b2e2;
`;
