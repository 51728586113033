import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../style';
import config from '../../../../config';
import ApiSTAC, { STACCatalogDTO, STACCatalogFullDTO, STACItemFullDTO } from '../../../../api/api-stac';
// import StacCatalogChildNavigationButtons from './stac-catalog-navigation-buttons';
import { toast } from 'react-toastify';
import store from '../../../../store/store';
import { push } from 'connected-react-router';
import { selectSTACItems } from '../../../../store/ExternalMapServicesStore/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { actionAddUpdateSTACItems } from '../../../../store/ExternalMapServicesStore/actions';

interface StacItemNavigationProps {
    item: STACItemFullDTO;
    onStacItemVisibilityUpdated: (id: number) => void;
}

const StacItemNavigation = ({ item, onStacItemVisibilityUpdated }: StacItemNavigationProps) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const stacItems = useSelector(selectSTACItems);
    const [siblings, setSiblings] = useState<number[]>([]);

    useEffect(() => {
        const itemSiblings = stacItems[item.catalogId];
        if (itemSiblings) {
            setSiblings(itemSiblings);
        } else if (item.catalogId > 0) {
            ApiSTAC.getSTACCatalogItems(item.catalogId).then((items) => {
                const itemsIds = items.map((c) => c.id);
                setSiblings(itemsIds);
                dispatch(actionAddUpdateSTACItems(item.catalogId, itemsIds));
            });
        }
    }, [stacItems, item.catalogId, dispatch]);

    const handelCatalogAvailability = (availability: boolean) => {
        if (!item.previewUrl && availability) {
            alert('You must have a preview before making the layer publicly available');
            return;
        }

        setLoading(true);
        ApiSTAC.updateSTACCatalogItemVisibility(item.catalogId, item.id, availability)
            .then((_) => {
                onStacItemVisibilityUpdated(item.id);
                toast.success(`${item.title || item.id} is now ${availability ? 'public' : 'private'}`);
            })
            .catch((err) => {
                alert(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOpenInSoar = () => {
        window.open(`${config.CLIENT_URL}/maps/${item.listingId}`);
    };

    const handleClickUp = () => {
        store.dispatch(push(`/stac/${item.catalogId}`));
    };

    const handleClickNextLayer = () => {
        const currentIndex = siblings.findIndex((t) => t === item.id);
        if (currentIndex < 0) return;
        if (currentIndex + 1 == siblings.length) {
            store.dispatch(push(`/stac/${item.catalogId}/${siblings[0]}`));
        } else {
            store.dispatch(push(`/stac/${item.catalogId}/${siblings[currentIndex + 1]}`));
        }
    };

    const handleClickPrevLayer = () => {
        const currentIndex = siblings.findIndex((t) => t === item.id);
        if (currentIndex < 0) return;
        if (currentIndex == 0) {
            store.dispatch(push(`/stac/${item.catalogId}/${siblings[siblings.length - 1]}`));
        } else {
            store.dispatch(push(`/stac/${item.catalogId}/${siblings[currentIndex - 1]}`));
        }
    };

    return (
        <Container>
            {item.public && (
                <React.Fragment>
                    <AvailableText>This catalog item is currently available on Soar</AvailableText>
                    <Button disabled={loading} onClick={() => handelCatalogAvailability(false)}>
                        Turn it off
                    </Button>
                    <Button color="link" onClick={() => handleOpenInSoar()}>
                        Open in Soar
                    </Button>
                </React.Fragment>
            )}
            {!item.public && item.map && (
                <React.Fragment>
                    <NotAvailableText>This catalog item is not available on Soar</NotAvailableText>
                    <Button disabled={loading} onClick={() => handelCatalogAvailability(true)}>
                        Turn it on
                    </Button>
                </React.Fragment>
            )}
            <ContainerButtons>
                <Button onClick={handleClickUp}>
                    <i className="fa fa-arrow-up" /> Up
                </Button>
                {siblings.length > 0 && (
                    <React.Fragment>
                        <Button onClick={handleClickPrevLayer} title="Previous catalog sibling">
                            Prev <i className="fa fa-arrow-left" />
                        </Button>
                        <Button onClick={handleClickNextLayer}>
                            Next <i className="fa fa-arrow-right" title="Next catalog sibling" />
                        </Button>
                    </React.Fragment>
                )}
            </ContainerButtons>
        </Container>
    );
};

export default StacItemNavigation;

const Container = styled.div`
    padding-top: 8px;
    display: inline-block;
`;

const Text = styled.span`
    font-weight: bolder;
    margin-right: 20px;
    font-size: 1.2rem;
    vertical-align: sub;
`;

const AvailableText = styled(Text)`
    color: #58a758;
`;

const NotAvailableText = styled(Text)`
    color: #fa4646;
`;

const ContainerButtons = styled.div`
    margin-right: 20px;
    float: right;
`;
