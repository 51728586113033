import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import * as Wellknown from 'wellknown';
import ApiTms, { TMSLayer, TMSLayer as TMSLayerDTO } from '../../../../api/api-tms';
import TagInputField from '../../../Shared/tag-input-field';
import {
    Button,
    Card,
    Col,
    Container,
    ErrorMessage,
    FormGroup,
    Input,
    Label,
    Multiselect,
    Row,
    Spinner,
    Subtitle,
    Title,
} from '../../../style';

import { toast } from 'react-toastify';
import { OssUploader } from '../../../../api/oss-uploader';
import ViewHelper from '../../../view-helper';
import TMSLayerVisibility from './tms-layer-visibility';
import TMSLayerDeleteAction from './tms-layer-delete-action';
import MapServicesLayerPreviewImage from '../../MapServicesSharedUI/Layer/map-services-layer-preview-image';
import MapServicesLayerPreviewMap from '../../MapServicesSharedUI/Layer/map-services-layer-preview-map';
import MapServicesLayerEditLegend from '../../MapServicesSharedUI/Layer/map-services-layer-edit-legend';
import MapServicesLayerDeleteLegend from '../../MapServicesSharedUI/Layer/map-services-layer-delete-legend';
import MapServicesDetailsTable from '../../MapServicesSharedUI/map-services-details-table';
import CategoriesInput, { filterPrimaryCategories } from '../../../Shared/categories-input-field';

interface MatchParams {
    serverId: string;
    layerId: string;
}

interface TMSLayerProps extends RouteComponentProps<MatchParams> {
    tmsLayer: TMSLayerDTO;
}

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 200;
const MAX_MAP_ZOOM_LEVEL = 28;

const TMSLayerPage = (props: TMSLayerProps) => {
    const serverId = Number(props.match.params.serverId);
    const layerId = Number(props.match.params.layerId);

    const [tmsLayer, setTmsLayer] = useState<TMSLayer>();
    const [tmsLayerError, setTmsLayerError] = useState<Error | undefined>();
    const [isUpdatingTMSLayer, setIsUpdatingTMSLayer] = useState(false);
    const [isUploadingPreview, setIsUploadingPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isReloadingMap, setIsReloadingMap] = useState(false);

    const [title, setTitle] = useState<string>();
    const [layerUrl, setLayerUrl] = useState<string>();
    const [isTMS, setIsTMS] = useState<boolean>(true);
    const [license, setLicense] = useState<string | undefined>();
    const [description, setDescription] = useState<string>();
    const [tags, setTags] = useState<string[]>([]);
    const [legendUrl, setLegendUrl] = useState<string>('');
    const [isLoadingLegend, setIsLoadingLegend] = useState<boolean>(false);
    const [primaryCategories, setPrimaryCategories] = useState<string[]>([]);
    const [adminNotes, setAdminNotes] = useState<string | undefined>(undefined);
    const [base64Preview, setBase64Preview] = useState<string | undefined>(undefined);
    const [boundingBox, setBoundingBox] = useState<string>('');
    const [maxZoomLevel, setMaxZoomLevel] = useState<number | undefined>(undefined);
    const [mapZoomLevel, setMapZoomLevel] = useState<number>(MAX_MAP_ZOOM_LEVEL);

    const [tmsLayers, setTmsLayers] = useState<TMSLayer[]>([]);

    const getTMSLayer = useCallback(async () => {
        setIsLoading(true);
        try {
            const layers = await ApiTms.getTMSLayers(serverId);
            setTmsLayers(layers);

            const layer = await ApiTms.getTMSLayer(serverId, layerId);
            if (!layer) {
                alert('Layer not found');
            }

            setTmsLayer(layer);

            setTitle(layer.title);
            setLayerUrl(layer.url);
            setIsTMS(layer.tms);
            setDescription(layer.description);
            setTags(layer.keywords);
            setLegendUrl(layer.legendUrl);
            setAdminNotes(layer.adminNotes);
            setBoundingBox(layer.boundingBoxWKT);
            setLicense(layer.license);
            setMaxZoomLevel(layer.maxZoomLevel);

            setPrimaryCategories(filterPrimaryCategories(layer.categories) || []);
        } catch (err) {
            setTmsLayerError(err);
        } finally {
            setIsLoading(false);
        }

        setIsLoading(false);
    }, [serverId, layerId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setTmsLayer(undefined);
        setTitle(undefined);
        setDescription(undefined);
        setTags(undefined);
        setBase64Preview(undefined);
        getTMSLayer();
    }, [serverId, layerId, getTMSLayer]);

    const handleUpdateTMSLayerDetails = () => {
        if (primaryCategories.length > 3) {
            toast.error('Please select between 1 - 3 categories');
            return;
        }
        if (title?.length > MAX_TITLE_LENGTH) {
            toast.error(`Please review the title, maximum characters ${MAX_TITLE_LENGTH}`);
            return;
        }

        if (description?.length && description?.split(' ')?.length > MAX_DESCRIPTION_LENGTH) {
            toast.error(`Please review the description, maximum word count ${MAX_DESCRIPTION_LENGTH}`);
            return;
        }

        if (!primaryCategories?.length || primaryCategories.length > 3) {
            toast.error('Please select between 1 - 3 categories');
            return;
        }

        const geometryWkt = boundingBox ? Wellknown.parse(boundingBox) : undefined;
        if (!geometryWkt) {
            toast.error('Invalid format for bounding box');
            return;
        }

        setIsUpdatingTMSLayer(true);
        ApiTms.updateTMSLayerDetails(
            serverId,
            layerId,
            primaryCategories,
            title,
            description,
            tags,
            legendUrl,
            boundingBox,
            adminNotes,
            layerUrl,
            isTMS,
            license,
            maxZoomLevel
        )
            .then((_) => {
                getTMSLayer();
            })
            .catch((err) => {
                setTmsLayerError(err);
            })
            .finally(() => {
                alert('Layer details updated');
                setIsUpdatingTMSLayer(false);
            });
    };

    const handleGeneratePreview = async (base64Preview: string) => {
        setBase64Preview(base64Preview);
        setIsUploadingPreview(true);
        try {
            // Convert file from base54 encoded string to a File object
            const filePromise = await fetch(base64Preview);
            const fileBlob = await filePromise.blob();
            const filename = `${serverId}-${layerId}-${Date.now().toString()}.png`;
            const file = new File([fileBlob], filename, { type: 'image/png' });

            // Upload file to OSS bucket
            const uploadCredentials = await ApiTms.getTMSLayerPreviewUploadCredentials(serverId, layerId);
            const uploader = new OssUploader(uploadCredentials);
            await uploader.multipartUpload(file, file.name, (_) => {}); // eslint-disable-line @typescript-eslint/no-empty-function

            alert('Preview image uploaded');
        } catch (err) {
            setTmsLayerError(err);
        } finally {
            setIsUploadingPreview(false);
        }
    };

    const handleValidateFileType = (file: File) => {
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        return validTypes.includes(file.type);
    };

    const handleSelectPreviewImage = async (file: File) => {
        if (!handleValidateFileType(file)) {
            alert('Please use a supported image type (jpg or png)');
            return;
        }

        try {
            const credentials = await ApiTms.getTMSLayerPreviewUploadCredentials(serverId, layerId);
            const uploader = new OssUploader(credentials);
            await uploader.multipartUpload(file, file.name, () => {}); // eslint-disable-line @typescript-eslint/no-empty-function

            alert('Preview image uploaded');
            getTMSLayer();
        } catch (err) {
            setTmsLayerError(err);
        }
    };

    const handleUploadLegend = async (file: File) => {
        if (!handleValidateFileType(file)) {
            alert('Please use a supported image type (jpg or png)');
            return;
        }

        setIsLoadingLegend(true);
        try {
            const credentials = await ApiTms.getTMSLegendUploadCredentials(serverId, layerId);
            const uploader = new OssUploader(credentials);
            await uploader
                .multipartUpload(file, file.name, (_) => {}) // eslint-disable-line @typescript-eslint/no-empty-function
                .then(async (res) => {
                    if (res) {
                        const legendCustomUrl = `https://short-preview.soar.earth/${res.name}`;
                        setLegendUrl(legendCustomUrl);
                    }
                });
        } catch (err) {
            setTmsLayerError(err);
        } finally {
            setIsLoadingLegend(false);
        }
    };

    const handleDeleteLegend = () => {
        setIsLoadingLegend(true);
        ApiTms.deleteTMSLayerLegend(serverId, layerId)
            .then((_) => {
                setLegendUrl(undefined);
            })
            .catch((err) => {
                alert('An error occurred while deleting the legend: ' + err.message);
            })
            .finally(() => {
                setIsLoadingLegend(false);
            });
    };

    const handleSwitchTMS = () => {
        setIsReloadingMap(true);
        setIsTMS(!isTMS);
        requestAnimationFrame(() => {
            setIsReloadingMap(false);
        });
    };

    const handleUpdateMap = () => {
        setIsReloadingMap(true);
        const updatedTmsLayer = {
            ...tmsLayer,
            boundingBoxWKT: boundingBox,
        };
        setTmsLayer(updatedTmsLayer);
        requestAnimationFrame(() => {
            setIsReloadingMap(false);
        });
    };

    if (tmsLayerError) {
        return (
            <Container>
                <Title>Manage TMS Layer</Title>
                <Row>
                    <Col md={12}>
                        <Card>
                            <ErrorMessage>{tmsLayerError.message}</ErrorMessage>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!tmsLayer) {
        return (
            <Container>
                <Spinner />
            </Container>
        );
    }

    return (
        <Container>
            <Title>Manage TMS Layer ({tmsLayer?.title || tmsLayer?.id})</Title>

            <Row>
                <Col md={12}>
                    <Card>
                        <TMSLayerVisibility
                            tmsLayer={tmsLayer}
                            layers={tmsLayers}
                            onTMSLayerVisibilityUpdated={() => getTMSLayer()}
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <Card>
                        <Subtitle> TMS Layer Details</Subtitle>

                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="title">Description</Label>
                            <Input
                                type="textarea"
                                rows="3"
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="category">Primary Category</Label>
                            <CategoriesInput
                                values={primaryCategories}
                                onChange={(selectedCategories) => {
                                    setPrimaryCategories(selectedCategories);
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <LegendLabel for="legend">
                                Legend
                                <span>
                                    <LegendControls>
                                        {legendUrl ? (
                                            <LegendItem>
                                                <MapServicesLayerDeleteLegend
                                                    handleConfirmDeleteLegend={() => handleDeleteLegend()}
                                                />
                                                <LineDivider>|</LineDivider>
                                            </LegendItem>
                                        ) : null}
                                        <LegendItem>
                                            <MapServicesLayerEditLegend
                                                onSelectUploadLegend={(file) => handleUploadLegend(file)}
                                                legendUrl={legendUrl}
                                            />
                                        </LegendItem>
                                    </LegendControls>
                                </span>
                            </LegendLabel>
                            {legendUrl && !isLoadingLegend ? <Legend src={legendUrl} /> : null}
                            {isLoadingLegend ? <Spinner /> : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="tags">Keywords (to help with search)</Label>
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <TagInputField tags={tags} onTagInput={(tags) => setTags(tags)} />
                            )}
                        </FormGroup>

                        <FormGroup>
                            <Label for="license">License</Label>
                            <Input
                                value={license}
                                onChange={(e) => {
                                    setLicense(e.target.value);
                                }}
                                type="select"
                                name="license"
                            >
                                {!license && <option>Choose license</option>}
                                {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                                    <option value={value}>{value}</option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup switch>
                            <TMSSwitch
                                id="tms-checkbox"
                                type="switch"
                                checked={isTMS}
                                onChange={() => {
                                    handleSwitchTMS();
                                }}
                            />
                            <Label for="tms-checkbox">{`TMS Layer: ${isTMS ? 'Yes' : 'No'}`}</Label>
                        </FormGroup>
                        <FormGroup>
                            <Label for="maxZoomLevel">Max Zoom Level (optional)</Label>
                            <MapZoomLevelInput>
                                <Input
                                    min={1}
                                    max={28}
                                    step={1}
                                    type="number"
                                    placeholder="Max zoom level"
                                    id="tms-maxZoomLevel"
                                    name="maxZoomLevel"
                                    title={`The zoom level the TMS displays to after the tiles ended.`}
                                    value={Math.round(maxZoomLevel)}
                                    onChange={(e) => setMaxZoomLevel(Math.round(Number(e.target.value)))}
                                />
                                <UpdateZoomButton
                                    title="Use the zoom level from the map"
                                    onClick={() => setMaxZoomLevel(Math.round(mapZoomLevel))}
                                >
                                    {`Use Zoom: ${Math.round(mapZoomLevel)}`}
                                </UpdateZoomButton>
                            </MapZoomLevelInput>
                        </FormGroup>
                        <FormGroup>
                            <Label for="adminNotes">Admin Notes</Label>
                            <Input
                                type="textarea"
                                rows="3"
                                name="adminNotes"
                                value={adminNotes}
                                onChange={(e) => setAdminNotes(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="preview">
                                {`Preview image ${
                                    base64Preview || tmsLayer.previewUrl ? ': Click preview to change' : ''
                                }`}
                            </Label>
                            <MapServicesLayerPreviewImage
                                isUploadingPreview={isUploadingPreview}
                                previewUrl={tmsLayer.previewUrl}
                                base64Preview={base64Preview}
                                onSelectPreviewImage={(file) => handleSelectPreviewImage(file)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="boundingBox">Bounding Box WKT</Label>
                            <Input
                                type="textarea"
                                name="boundingBox"
                                value={boundingBox}
                                onChange={(e) => setBoundingBox(e.target.value)}
                            />
                            <UpdateBoundsButton onClick={() => handleUpdateMap()}>View Bounds</UpdateBoundsButton>
                        </FormGroup>
                        <FormGroup>
                            <Button disabled={isUpdatingTMSLayer} onClick={() => handleUpdateTMSLayerDetails()}>
                                {isUpdatingTMSLayer ? <Spinner /> : null}
                                Save changes
                            </Button>
                        </FormGroup>
                    </Card>
                </Col>

                <Col md={7}>
                    <Card>
                        {isReloadingMap ? (
                            <Spinner />
                        ) : (
                            <MapServicesLayerPreviewMap
                                layer={tmsLayer}
                                onGeneratePreview={(base64: string) => {
                                    handleGeneratePreview(base64);
                                }}
                                onUsePreviewBoundingBox={(boundingBox: string) => {
                                    setBoundingBox(boundingBox);
                                }}
                                isTMS={isTMS}
                                setMapZoomLevel={setMapZoomLevel}
                            />
                        )}
                    </Card>
                    <Card>
                        <TMSLayerDeleteAction serverId={serverId} layerId={layerId}>
                            <Button color="danger">Delete Layer</Button>
                            <Label>
                                Warning: This action cannot be undone and the layer will need to be re-entered!
                            </Label>
                        </TMSLayerDeleteAction>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Card>
                        <MapServicesDetailsTable title={'Raw TMS Layer metadata'} data={tmsLayer} />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default TMSLayerPage;

const LegendLabel = styled(Label)`
    width: 100%;
    margin-bottom: 10px;

    span {
        float: right;
    }
`;

const Legend = styled.img`
    display: block;
    max-width: 100%;
`;

const LegendItem = styled.div`
    display: flex;
    flex-direction: row;
`;

const LineDivider = styled.div`
    margin: 0px 8px;
    margin-top: -3px;
    font-size: 20px;
    height: 20px;
`;

const LegendControls = styled.div`
    display: flex;
`;

const TMSSwitch = styled(Input)`
    margin: 0px 10px 15px 0px;
    height: 20px;
    width: 40px !important;
`;

const UpdateBoundsButton = styled(Button)`
    margin: 15px 0px;
`;

const UpdateZoomButton = styled(Button)`
    margin-left: 10px;
    margin-right: 0px;
    width: 100%;
`;

const MapZoomLevelInput = styled.div`
    display: flex;
    flex-direction: row;
`;
