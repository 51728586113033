import React, { useState } from 'react';
import { Button, Card, Container, Title } from '../../style';
import styled from 'styled-components';
import TrafficMonthlyTable from './traffic-monthly-table';
import TrafficDailyTable from './traffic-daily-table';

export enum DashboardType {
    Monthly,
    Daily,
}

const TrafficReports = () => {
    const [dashboard, setDashboard] = useState<DashboardType>(DashboardType.Daily);

    return (
        <Container>
            <Title>Traffic Report</Title>
            <Card>
                <ButtonContainer>
                    <Button
                        active={dashboard === DashboardType.Daily}
                        onClick={() => setDashboard(DashboardType.Daily)}
                    >
                        Daily Traffic
                    </Button>
                    <Button
                        active={dashboard === DashboardType.Monthly}
                        onClick={() => setDashboard(DashboardType.Monthly)}
                    >
                        Monthly Traffic
                    </Button>
                </ButtonContainer>
            </Card>
            <React.Fragment>
                {dashboard === DashboardType.Monthly ? <TrafficMonthlyTable /> : null}
                {dashboard === DashboardType.Daily ? <TrafficDailyTable /> : null}
            </React.Fragment>
        </Container>
    );
};

export default TrafficReports;

const ButtonContainer = styled.div`
    text-align: center;
    margin: 8px;

    button {
        margin: 8px;
    }
`;
