import styled from 'styled-components';
import {
    Container as ReactstrapContainer,
    Row as ReactstrapRow,
    Col as ReactstrapCol,
    Card as ReactstrapCard,
    CardHeader as ReactstrapCardHeader,
    Button as ReactstrapButton,
    ButtonGroup as ReactstrapButtonGroup,
    Form as ReactstrapForm,
    FormGroup as ReactstrapFormGroup,
    Input as ReactstrapInput,
    Label as ReactstrapLabel,
    Spinner as ReactstrapSpinner,
    InputGroup as ReactstrapInputGroup,
    Collapse as ReactStrapCollapse,
    DropdownItem as ReactStrapDropdownItem,
    DropdownMenu as ReactStrapDropdownMenu,
    DropdownToggle as ReactStrapDropdownToggle,
    Nav as ReactStrapNav,
    Navbar as ReactStrapNavBar,
    NavbarBrand as ReactrapBrand,
    NavItem as BootstrapNavItem,
    UncontrolledDropdown as BootstrapUncontrolledDropdown,
    Alert as BootstrapAlert,
    Accordion as BootstrapAccordion,
    AccordionBody as BootStrapAccordionBody,
    AccordionHeader as BootstrapAccordionHeader,
    AccordionItem as BootstrapAccordionItem,
    Progress as BootstrapProgress,
    NavLink as BootstrapNavLink,
    TabContent as BootstrapTabContent,
    TabPane as BootstrapTabPane,
    List as BootstrapList,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';

export const Container = styled(ReactstrapContainer)`
    padding: 20px 25px 25px 25px;
    max-width: 100%;
`;

export const Row = styled(ReactstrapRow)``;
export const Col = styled(ReactstrapCol)``;

export const Button = styled(ReactstrapButton)`
    background-color: #eed926;
    color: black;
    text-transform: uppercase;
    border-color: #eed926;
    margin-right: 8px;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
        background-color: #eeda26;
        color: black;
        border-color: #eed926;
        box-shadow: 0px 0px 0px 2px rgb(238 218 38 / 50%);
    }

    &.btn-secondary,
    &.btn.btn-secondary.active {
        background-color: #eed926;
        color: black;
        text-transform: uppercase;
        border-color: #eed926;
    }
    &.btn.btn-outline-secondary {
        background-color: transparent;
        color: #eed926;
        border-color: #eed926;
    }

    &.btn-danger {
        box-shadow: none;
    }

    &.btn-danger:hover {
        box-shadow: 0px 0px 0px 2px rgb(220 53 69 / 50%);
    }

    &.btn-danger,
    &.btn-danger:hover,
    &.btn-danger:focus,
    &.btn-danger:active {
        background-color: #dc3545;
        border-color: #dc3545;
        color: white;
    }

    &.btn-warning,
    &.btn-warning:hover,
    &.btn-warning:focus,
    &.btn-warning:active {
        background-color: rgb(0 0 0 / 30%);
        border-color: rgb(255 165 0);
        color: white;
    }

    &.btn-link {
        background-color: transparent;
        border-color: transparent;
        color: #eed926;
        text-transform: capitalize;
    }

    &.btn-danger:hover,
    &.btn-danger:focus,
    &.btn-danger:active {
        background-color: #dc3545;
        border-color: #dc3545;
    }
`;

export const ButtonGroup = styled(ReactstrapButtonGroup)``;

export const ButtonPanel = styled(ButtonGroup)`
    max-width: 500px;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0pc auto;
`;

interface CardProps {
    height?: string;
}

export const Card = styled(ReactstrapCard)<CardProps>`
    background: rgba(33, 37, 41, 1);
    margin: 12px;
    padding: 8px 12px 12px 12px;
    border-radius: 3px;
    min-height: ${(props) => props.height && props.height};
`;

export const InvisibleCard = styled(Card)`
    background: transparent;
    box-shadow: none;
    border: none;
`;

export const Divider = styled.div<{ large?: boolean }>`
    height: ${(props) => (props.large ? '44px' : '22px')};
`;

interface TitleProps {
    large?: boolean;
    centered?: boolean;
}

export const Title = styled.h2<TitleProps>`
    color: #eed926;
    font-size: ${(props) => (props.large ? '2.5rem' : '1.8rem')};
    margin: 0 0 0 12px;
    text-align: ${(props) => (props.centered ? 'center' : 'unset')};
`;

export const Subtitle = styled.h3`
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0 0 0 4px;
    text-align: center;
`;

export const SubtitleHint = styled(Subtitle)`
    font-size: 0.9rem;
    font-style: italic;
`;

export const ChartTitle = styled(Subtitle)`
    color: white;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
`;

export const Statistic = styled.p`
    color: #eed926;
    font-size: 1.8rem;
    text-align: center;
`;

export const ErrorMessage = styled.p`
    color: #dc3545;
    font-weight: bold;
`;

export const Form = styled(ReactstrapForm)``;

export const FormGroup = styled(ReactstrapFormGroup)`
    margin: 0 8px 0 0;
`;
export const InputGroup = styled(ReactstrapInputGroup)``;

export const Input = styled(ReactstrapInput)`
    margin: 0;
`;

export const Multiselect = styled(Select)`
    &.error {
        border: 2px solid #dc3545;
    }
`;

export const Label = styled(ReactstrapLabel)`
    color: #ffffff;
    white-space: nowrap;
    font-weight: bold;
`;

export const CardHeader = styled(ReactstrapCardHeader)`
    border-color: white;
`;

export const Spinner = styled(ReactstrapSpinner)``;

export const Table = styled(BootstrapTable)`
    thead th {
        color: white;
    }
`;

export const TableWrapper = styled.div`
    padding: 0;

    .table {
        border-color: rgba(116, 116, 116, 0.3);
    }

    .table thead {
    }

    .table thead th {
        color: white;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
    }

    .table tbody tr {
        th,
        td {
            color: #ffffff;
        }
    }

    .table tbody tr td a {
        color: #eed926;
    }

    .table tbody tr:hover td {
        cursor: pointer;
        color: white;
        background: rgba(0, 0, 0, 0.35);
    }

    .table tbody > :nth-child(odd) {
    }

    .table tbody tr td a {
        text-decoration: underline;
    }

    .react-bootstrap-table-pagination div:first-child {
        width: 75px;
    }

    .page-item .page-link {
        background-color: transparent;
        border-color: rgba(116, 116, 116, 0.3);
        color: white;
        text-decoration: none;
        height: 37px;
        font-size: 16px;
    }

    .page-link:focus {
        box-shadow: none;
    }

    .page-item .page-link:hover {
        background-color: rgba(255, 255, 255, 0.3);
        color: black;
    }

    .page-item.active .page-link {
        background-color: #eed926;
        color: black;
    }

    .btn-secondary:active:focus,
    .btn-secondary {
        border-color: rgba(116, 116, 116, 0.3);
        color: white;
        background-color: transparent;
        box-shadow: none;
    }

    .btn-link {
        padding: 0px;
        color: #eed926;

        &.danger {
            color: #dc3545;
        }
    }

    .btn-success {
        background-color: #eed926;
        color: black;
        text-transform: uppercase;
        border-color: #eed926;
    }

    .dropdown-menu {
        background: rgba(33, 37, 41, 1);
        border-color: rgba(116, 116, 116, 0.3);
    }

    .dropdown-menu .dropdown-item a {
        color: white;
    }

    .dropdown-menu .dropdown-item:hover {
        background-color: rgba(251, 251, 251, 0.3);
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-flow: row-reverse;
    margin-bottom: 10px;
`;

export const Panel = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    & button {
        :not(:first-child) {
            margin-left: 10px;
        }
    }
    & div {
        margin: 0px 10px 0px 0px;
        /* margin: 5px; */
    }
    & a {
        margin: 5px;
    }
`;

export const ConfirmModalText = styled.div`
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
`;

interface ConfirmModalFooterProps {
    margin?: string;
}

export const ConfirmModalFooter = styled.div<ConfirmModalFooterProps>`
    margin: ${(props) => (props.margin ? props.margin : '20px 15px 0px 0px')};
    float: right;
`;

export const ConfirmButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 190px;
    justify-content: space-between;
`;

export const Collapse = styled(ReactStrapCollapse)``;

export const DropdownItem = styled(ReactStrapDropdownItem)``;

export const DropdownMenu = styled(ReactStrapDropdownMenu)`
    width: 92%;
`;

export const DropdownToggle = styled(ReactStrapDropdownToggle)``;

export const Nav = styled(ReactStrapNav)``;

export const Navbar = styled(ReactStrapNavBar)``;

export const NavbarBrand = styled(ReactrapBrand)``;

export const NavItem = styled(BootstrapNavItem)``;

export const UncontrolledDropdown = styled(BootstrapUncontrolledDropdown)``;

export const Alert = styled(BootstrapAlert)``;

export const Accordion = styled(BootstrapAccordion)`
    margin: 10px;

    .accordion-button {
        background-color: transparent;
        padding: 0px 5px;
        color: white;
    }

    .accordion-button:focus {
        border-color: white;
        box-shadow: 0 0 0 1px rgb(255 255 255 / 10%);
    }

    .accordion-button::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(238 217 38)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }

    .accordion-body {
        padding: 0px !important;
    }
`;
export const AccordionBody = styled(BootStrapAccordionBody)`
    .accordion-body {
        padding: 0px !important;
    }
`;

export const AccordionHeader = styled(BootstrapAccordionHeader)``;
export const AccordionItem = styled(BootstrapAccordionItem)`
    background-color: transparent !important;
`;

export const Progress = styled(BootstrapProgress)``;

export const NavLink = styled(BootstrapNavLink)`
    background-color: transparent !important;
    color: #ffffff !important;
    border-color: rgba(116, 116, 116, 0.3) !important;
`;

export const TabContent = styled(BootstrapTabContent)``;

export const TabPane = styled(BootstrapTabPane)``;

export const List = styled(BootstrapList)`
    li {
        line-break: auto;
    }
`;
