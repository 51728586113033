import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import ApiOrders from '../../api/api-orders';
import ApiOrdersSupplier from '../../api/api-orders-supplier';
import { ArchiveSceneDTO, OrderMetadataDTO, OrderStatus } from '../../api/model-orders';
import ButtonsPanel from '../Shared/buttons-panel';
import ViewHelper from '../view-helper';
import { Button, Col, FormGroup, Input, Label, Panel, Row } from '../style';
import SoarModal from '../Shared/soar-modal';
import ErrorPanel from '../Shared/error-panel';
import FormattedDetailsTable, { Line } from '../Shared/formatted-details-table';

interface Props {
    orderId: number;
    metadata: OrderMetadataDTO;
    status: OrderStatus;
    invalidate: () => void;
    isAdmin?: boolean;
}

const STATUSES_FOR_UPLOAD = [OrderStatus.COLLECTION, OrderStatus.QA_SOAR, OrderStatus.QA_FAILED, OrderStatus.DELIVERED];

const formatSceneDescription = (s: ArchiveSceneDTO): string => {
    let dateString = '';
    if (s.acquisition?.length > 0) {
        dateString = ViewHelper.formatDateTimeFromISO(s.acquisition);
    }
    return `ID: ${s.sceneId}, DATE: ${dateString}, NADIR: ${s.nadirAngle}%`;
};

const OrderMetadataForm = (props: Props) => {
    const { orderId, metadata, status, isAdmin } = props;
    const [model, setModel] = useState<OrderMetadataDTO>(undefined);
    const [scenes, setScenes] = useState<ArchiveSceneDTO[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [edit, setEdit] = useState(false);

    const setModelFromProps = () => {
        setModel({ ...metadata });
        const scenes =
            metadata && metadata.scenes
                ? [...metadata.scenes]
                : [{ sceneId: undefined, nadirAngle: undefined, acquisition: undefined }];
        setScenes(scenes);
    };

    useEffect(() => {
        setModelFromProps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metadata]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const copy = { ...model, [e.target.name]: e.target.value };
        setModel(copy);
    };

    const handleSceneChange = (scene: ArchiveSceneDTO, idx: number) => {
        const copy = [...scenes];
        copy[idx] = scene;
        setScenes(copy);
    };

    const handleSceneDelete = (idx: number) => {
        const copy = [...scenes];
        copy.splice(idx, 1);
        setScenes(copy);
    };

    const handleSubmit = async () => {
        const arr = [];
        if (!model.imageColour || model.imageColour.length < 1) arr.push('Image colour required');
        if (model.deliveredInKm === undefined) arr.push('Delivered AOI (km²) required');
        if (model.cloud === undefined) arr.push('Cloud cover required');
        if (!model.projection || model.projection.length < 1) arr.push('Projection required');
        if (!model.datum || model.datum.length < 1) arr.push('Datum required');
        if (!model.pixelResolution || model.pixelResolution.length < 1) arr.push('Pixel resolution required');
        scenes.forEach((s, idx) => {
            if (s.nadirAngle === undefined) arr.push(`Scene [${idx + 1}] Nadir required`);
            if (s.acquisition === undefined) arr.push(`Scene [${idx + 1}] aqusition required`);
        });
        const copy = { ...model };
        copy.scenes = [...scenes];
        setErrors(arr);
        if (arr.length === 0) {
            if (isAdmin) {
                await ApiOrders.updateMetadata(orderId, copy);
            } else {
                await ApiOrdersSupplier.updateMetadata(orderId, copy);
            }
            props.invalidate();
            setEdit(false);
        }
    };

    const handleCancel = () => {
        setModelFromProps();
        setEdit(false);
    };

    if (!model) {
        return (
            <ErrorPanel>
                Uploaded file is still processing. Please wait a minute or two and try refresh the page.
            </ErrorPanel>
        );
    }

    return (
        <React.Fragment>
            <Row>
                <FormattedDetailsTable label={'Metadata'}>
                    <Line label="Image Colour" value={metadata.imageColour}></Line>
                    <Line label="Delivered AOI" value={ViewHelper.formatAreaInKm(metadata.deliveredInKm)}></Line>
                    <Line label="Cloud cover" value={metadata.cloud ? `${metadata.cloud}%` : 'Not Available'}></Line>
                    <Line label="Projection" value={metadata.projection}></Line>
                    <Line label="Datum" value={metadata.datum}></Line>
                    {metadata.pixelResolution && (
                        <Line label="Pixel Resolution" value={`${metadata.pixelResolution} cm`}></Line>
                    )}
                </FormattedDetailsTable>

                {scenes?.length ? (
                    <FormattedDetailsTable label={'Selected Scenes'}>
                        {scenes?.map((s, index) => {
                            if (!s.sceneId) return <></>;
                            return <Line key={s.sceneId} label={`Scene ${index}`} value={formatSceneDescription(s)} />;
                        })}
                    </FormattedDetailsTable>
                ) : null}
            </Row>
            <ButtonsPanel>
                {STATUSES_FOR_UPLOAD.includes(status) && (
                    <Button color="success" onClick={() => setEdit(true)}>
                        Update Metadata
                    </Button>
                )}
            </ButtonsPanel>

            <SoarModal title="Edit metadata" isOpen={edit} onClosed={() => setEdit(false)}>
                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="imageColour">Image Colour</Label>
                            <Input
                                type="text"
                                name="imageColour"
                                id="imageColour"
                                value={model.imageColour || ''}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="deliveredInKm">Delivered AOI (km²)</Label>
                            <Input
                                type="number"
                                name="deliveredInKm"
                                id="deliveredInKm"
                                value={model.deliveredInKm || ''}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="cloud">Cloud cover (%)</Label>
                            <Input type="number" name="cloud" id="cloud" value={model.cloud} onChange={handleChange} />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="projection">Projection</Label>
                            <Input
                                type="text"
                                name="projection"
                                id="projection"
                                value={model.projection || ''}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="datum">Datum</Label>
                            <Input
                                type="text"
                                name="datum"
                                id="datum"
                                value={model.datum || ''}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="pixelResolution">Pixel Resolution (cm)</Label>
                            <Input
                                type="number"
                                name="pixelResolution"
                                id="pixelResolution"
                                value={model.pixelResolution || ''}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                {scenes.map((val, idx) => (
                    <SceneInputs
                        key={`scene-${idx}`}
                        scene={scenes[idx]}
                        idx={idx}
                        onSceneChange={handleSceneChange}
                        onSceneDelete={handleSceneDelete}
                        total={scenes.length}
                    />
                ))}

                {errors.length > 0 && errors.map((e, idx) => <ErrorPanel key={`alert-${idx}`}>{e}</ErrorPanel>)}
                <Panel>
                    <Button
                        color="warning"
                        onClick={() =>
                            setScenes([
                                ...scenes,
                                { sceneId: undefined, nadirAngle: undefined, acquisition: undefined },
                            ])
                        }
                    >
                        Add additional scene
                    </Button>
                    <Button onClick={handleSubmit}>Save</Button>
                    <Button color="danger" onClick={handleCancel}>
                        Cancel
                    </Button>
                </Panel>
            </SoarModal>
        </React.Fragment>
    );
};

interface SceneInputProps {
    idx: number;
    scene: ArchiveSceneDTO;
    onSceneChange: (scene: ArchiveSceneDTO, idx: number) => void;
    onSceneDelete: (ids: number) => void;
    total: number;
}

const SceneInputs = (props: SceneInputProps) => {
    const { idx, scene, total } = props;
    const nadirId = `nadir-${idx}`;
    const sceneId = `scene-${idx}`;
    const dateId = `date-${idx}`;

    const handleSceneIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onSceneChange({ ...scene, sceneId: e.target.value }, idx);
    };

    const handleNadirChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onSceneChange({ ...scene, nadirAngle: e.target.valueAsNumber }, idx);
    };

    const handleDateChange = (date: Date) => {
        props.onSceneChange({ ...scene, acquisition: date.toISOString() }, idx);
    };

    const date = scene.acquisition ? new Date(scene.acquisition) : null;

    return (
        <Row>
            <Col md={4}>
                <FormGroup>
                    <Label for={sceneId}>SceneID</Label>
                    <Input
                        type="text"
                        value={scene.sceneId || ''}
                        name={sceneId}
                        id={sceneId}
                        onChange={handleSceneIdChange}
                    />
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup>
                    <Label for={nadirId}>Nadir</Label>
                    <Input
                        step={0.01}
                        type="number"
                        value={scene.nadirAngle || 0}
                        name={nadirId}
                        id={nadirId}
                        onChange={handleNadirChange}
                    />
                </FormGroup>
            </Col>
            <Col md={4}>
                <DatePickerWrapper>
                    <FormGroup>
                        <Label for={dateId}>Date</Label>
                        <DatePicker
                            selected={date}
                            onChange={handleDateChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="form-control"
                            popperPlacement="left"
                        />
                    </FormGroup>
                    {total > 0 && (
                        <DeleteButton color="danger" onClick={() => props.onSceneDelete(idx)}>
                            Remove
                        </DeleteButton>
                    )}
                </DatePickerWrapper>
            </Col>
        </Row>
    );
};

export default OrderMetadataForm;

const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const DeleteButton = styled(Button)`
    height: 38px;
    margin-top: 28px;
`;
