import React, { useEffect, useState } from 'react';
import ApiUser from '../../api/api-user';
import { UserDTO, VoucherDTO } from '../../api/model';
import FormattedDetailsTable, { Line } from '../Shared/formatted-details-table';
import SoarModal from '../Shared/soar-modal';
import { Title } from '../style';
import ViewHelper from '../view-helper';

interface Props {
    voucher: VoucherDTO;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

const ManageVoucherModal = (props: Props) => {
    const [user, setUser] = useState<UserDTO | undefined>(undefined);

    const handleFetchingUserDetails = async (id: any) => {
        await ApiUser.getUserById(id)
            .then((user) => setUser(user))
            .catch((error) => console.log('getting user by id error:', error));
    };

    useEffect(() => {
        if (props.voucher?.createdBy) {
            handleFetchingUserDetails(props.voucher?.createdBy);
        }
    }, [props.voucher]);

    if (!props.voucher) {
        return <React.Fragment />;
    }

    return (
        <SoarModal
            width="98vw"
            title={`Voucher Code: ${props.voucher.code}`}
            isOpen={props.isOpen}
            onClosed={() => props.setIsOpen(false)}
        >
            <FormattedDetailsTable label="Voucher Details">
                <Line label={'Type:'} value={props.voucher.type} />
                {user?.name && user.email ? (
                    <React.Fragment>
                        <Line label={'Created By:'} value={user.name} />
                        <Line label={'Creators Email:'} value={user.email} />
                    </React.Fragment>
                ) : null}
                <Line label={'Date Created:'} value={ViewHelper.formatDate(props.voucher.createdAt)} />
                {props.voucher.validFrom && props.voucher.validTo ? (
                    <Line
                        label={'Effective (From - To):'}
                        value={`${ViewHelper.formatDate(props.voucher.validFrom)} - ${ViewHelper.formatDate(
                            props.voucher.validTo
                        )}`}
                    />
                ) : null}
                {props.voucher.percentage ? (
                    <Line label={'Discount Percent:'} value={`${props.voucher.percentage} %`} />
                ) : null}
                {props.voucher.amount ? (
                    <Line label={'Discount (USD):'} value={ViewHelper.formatUSDPriceFromCents(props.voucher.amount)} />
                ) : null}
                {props.voucher.userMultiple ? (
                    <Line label={'Multi Use:'} value={props.voucher.userMultiple.toString()} />
                ) : null}
                {props.voucher.watermarkRequired ? (
                    <Line label={'Watermark Required:'} value={props.voucher.watermarkRequired.toString()} />
                ) : null}
                {props.voucher.tasksId.length > 0 ? (
                    <React.Fragment>
                        <Title>Usage</Title>
                        <Line label={'Order Number'} value={'Orders this voucher has been used on'} />
                    </React.Fragment>
                ) : null}
                {props.voucher.tasksId.length > 0 &&
                    props.voucher.tasksId.map((task, index) => {
                        return (
                            <Line
                                key={index}
                                label={task.toString()}
                                value={
                                    <a href={`/orders/${task.toString()}`} target="_blank" rel="noopener noreferrer">
                                        View Order
                                    </a>
                                }
                            />
                        );
                    })}
            </FormattedDetailsTable>
        </SoarModal>
    );
};

export default ManageVoucherModal;
