import React, { useState } from 'react';
import SelectAOI from './select-aoi';
import SelectAOIArchives from './select-aoi-archives';
import { ArchiveDTO, ProductDTO, SupplierDTO } from '../../api/model-orders';
import styled from 'styled-components';
import { Button, ButtonGroup, Col, Row } from '../style';

interface NewOrderAOIProps {
    handleSelectAOI: (geometryWKT: string) => void;
    product: ProductDTO;
    supplier: SupplierDTO;
    geometryWKT?: string; //this is an optional initial value
    selectedArchives?: ArchiveDTO[];
    onSelectArchives?: (archives: ArchiveDTO[]) => void;
    onBack: () => void;
}

const NewOrderAOI = (props: NewOrderAOIProps) => {
    const { product, supplier } = props;
    const [geometryWKT, setGeometryWKT] = useState<string | undefined>(props.geometryWKT);
    const [selectedArchives, setSelectedArchives] = useState<ArchiveDTO[]>(props.selectedArchives || []);
    const [AOIError, setAOIError] = useState<string>(undefined);

    return (
        <React.Fragment>
            <Row>
                <NewOrderCol>
                    <Row>
                        <SelectAOI
                            handleSelectAOI={(aoi) => {
                                setGeometryWKT(aoi);
                                props.handleSelectAOI(aoi);
                            }}
                            geometryWKT={props.geometryWKT}
                            product={product}
                            selectedArchives={selectedArchives}
                            onError={setAOIError}
                        />
                    </Row>

                    {product.option === 'ARCHIVE' && (
                        <Row>
                            <SelectAOIArchives
                                supplier={supplier.code}
                                product={product.code}
                                geometryWKT={geometryWKT}
                                selectedArchives={selectedArchives}
                                onSelectArchives={(archives) => {
                                    setSelectedArchives(archives);
                                }}
                            />
                        </Row>
                    )}

                    <Row>
                        <NewOrderButtonGroup>
                            <NewOrderButton onClick={props.onBack}>
                                <span>
                                    <i className="fa fa-chevron-left" />
                                </span>
                                <span>Back</span>
                            </NewOrderButton>
                            <NewOrderButton
                                onClick={() => props.onSelectArchives(selectedArchives)}
                                disabled={
                                    AOIError ||
                                    (product.option === 'ARCHIVE' && selectedArchives.length === 0) ||
                                    !geometryWKT
                                }
                            >
                                <span>Next</span>
                                <span>
                                    <i className="fa fa-chevron-right" />
                                </span>
                            </NewOrderButton>
                        </NewOrderButtonGroup>
                    </Row>
                </NewOrderCol>
            </Row>
        </React.Fragment>
    );
};

export default NewOrderAOI;

const NewOrderCol = styled(Col)`
    margin: 0px 30px;
`;

const NewOrderButtonGroup = styled(ButtonGroup)`
    width: auto;
    margin: auto;
`;

const NewOrderButton = styled(Button)`
    margin: 35px auto;
    padding: 5px 25px;

    & span:not(:first-child) {
        margin-left: 7px;
        color: rgba(0, 0, 0, 0.87);
    }
`;
