import './upload-dropzone.css';
import * as React from 'react';
import { Button } from 'reactstrap';
import Dropzone from 'react-dropzone';

interface UploadDropzoneProps {
    onSelectFiles(files: File[]);
    description?: string;
    children?: React.ReactNode;
}

const UploadDropzone: React.FC<UploadDropzoneProps> = (props) => {
    const activeDragStyle: React.CSSProperties = {
        background: '#a3a3a3',
        fontWeight: 'bolder',
    };

    return (
        <Dropzone onDrop={props.onSelectFiles} className="choose-file" activeStyle={activeDragStyle}>
            {props.children ?
                <React.Fragment>
                    {props.children}
                </React.Fragment>
                :
                <React.Fragment>
                    <p>Drag and drop your files here</p>
                    <p>or</p>
                    <Button className="btn btn-primary btn-choose-image">Choose Files</Button>
                </React.Fragment>
            }
        </Dropzone>
    );
};

export default UploadDropzone;
